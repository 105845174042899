import React, { useMemo } from 'react';

import { ProductName, ProductStatus } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import Label from '~/components/Label';

import { ProductWrapper } from './design';

import { COLORS } from '~/styles';

interface IProductItemProps {
  name: ProductName;
  status?: ProductStatus;
  selected?: boolean;
  onClick?: () => void;
}

const ProductItem = ({ name, status, selected, onClick }: IProductItemProps) => {
  const { i18n } = useLingui();

  const productData = useMemo(() => {
    switch (name) {
      case ProductName.CAREER:
        return {
          name: i18n._(t`Career`),
          icon: ICONS.CAREER,
          color: COLORS.CAREER,
        };
      case ProductName.PERFORMANCE:
        return {
          name: i18n._(t`Performance management`),
          icon: ICONS.CONVERSATIONS,
          color: COLORS.PERFORMANCE,
        };
      case ProductName.ENGAGEMENT:
        return {
          name: i18n._(t`Engagement surveys`),
          icon: ICONS.SURVEYS,
          color: COLORS.ENGAGEMENT,
        };
      case ProductName.TALENT:
        return {
          name: i18n._(t`Talent`),
          icon: ICONS.USERS,
          color: COLORS.TALENT,
        };
      case ProductName.LEARNING_AND_ONBOARDING:
        return {
          name: i18n._(t`Onboarding & Learning`),
          icon: ICONS.DEVELOPMENT,
          color: COLORS.LEARNING,
        };
      case ProductName.INTEGRATIONS:
        return {
          name: i18n._(t`Integrations`),
          icon: ICONS.INTEGRATION,
          color: COLORS.SUBTEXT,
        };
    }
  }, [i18n, name]);

  const statusData = useMemo(() => {
    switch (status) {
      case ProductStatus.TRIAL_ACTIVE:
        return {
          name: i18n._(t`Trial active`),
        };
      case ProductStatus.TRIAL_ENDED:
        return {
          name: i18n._(t`Trial ended`),
        };
    }
  }, [i18n, status]);

  return productData ? (
    <ProductWrapper selected={selected} onClick={onClick}>
      {productData.icon && (
        <Icon icon={productData.icon} size={ICON_SIZES.LARGE} color={productData.color} />
      )}
      <span className="name">{productData.name}</span>
      {statusData?.name && (
        <Label color={productData.color} backgroundByPercentage={10}>
          {statusData.name}
        </Label>
      )}
    </ProductWrapper>
  ) : (
    <></>
  );
};

export { ProductItem };
