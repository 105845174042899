import React from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Editor from '~/components/Editor';
import { TextArea } from '~/components/Text';
import { Header6Dark } from '~/components/UI/Typographics/headers';

import { COLORS } from '~/styles';

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    width: 712px;
  }
`;

const Header = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
  margin: 20px 0 12px 0;
  width: fit-content;
`;

const QuestionName = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${COLORS.TEXT_SECONDARY};
  border-radius: 3px;
  background-color: ${COLORS.BG_PAGE};
  padding: 8px;
  min-height: 69px;
  height: 69px;
  width: 100%;
  box-sizing: border-box;
`;

const StyledTextArea = styled(TextArea)`
  height: 48px;
  margin-bottom: 12px;
`;

const DescBlock = styled.div`
  margin-top: 24px;
  ${Header6Dark} {
    margin: 0 0 10px;
  }
`;

const GoalPlanQuestion = ({
  question,
  updateName,
  updateDescription,
  isShowErrors,
  isCollapsed,
}) => {
  const { i18n } = useLingui();

  return (
    <>
      <Row>
        <StyledTextArea
          value={question.name || ''}
          onChange={(e) => updateName(e.target.value)}
          placeholder={i18n._(t`Add your question…`)}
          $accent
          resize={true}
          rows="1"
          error={isShowErrors && !question.name}
        />
      </Row>
      <Row>
        <QuestionName>
          {question.type === QUESTION_TYPES.GOAL_BUSINESS_PLAN ? (
            <Trans>
              The participant is asked to plan new business goals or add existing business goals in
              the selected goal cycle for the created review.
            </Trans>
          ) : (
            <Trans>
              The participant is asked to plan new learning goals or add existing learning goals in
              the selected goal cycle for the created review.
            </Trans>
          )}
        </QuestionName>
      </Row>
      {!isCollapsed && (
        <DescBlock>
          <Header>
            <Trans>Description</Trans>
          </Header>
          <Editor
            value={question.description || ''}
            compact={true}
            small={true}
            onChange={updateDescription}
          />
        </DescBlock>
      )}
    </>
  );
};

export default React.memo(GoalPlanQuestion);
