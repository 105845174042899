import React from 'react';

import { SURVEY_QUESTION_TYPES } from '@learned/constants';
import styled from 'styled-components';

import { QuestionViewMultiple } from './components/Multiple';
import { QuestionViewSingle } from './components/Single';
import { QuestionViewSmileys } from './components/Smileys';
import { QuestionViewStars } from './components/Stars';
import { QuestionViewText } from './components/Text';
import { QuestionViewTrafficLights } from './components/TrafficLights';

import type { IQuestionForm } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

import type { ISurveyRating } from '@learned/types';

interface IQuestionView {
  defaultValues: IQuestionForm;
  languageState: ILanguageStateReturn;
  isPreloaded?: boolean;
  isPreselectedLang?: boolean;
  className?: string;
  error?: boolean;
  commentError?: boolean;
  isPreferredLanguage?: boolean;
  onChange?: (data: {
    answer?: ISurveyRating['answer'];
    comment?: ISurveyRating['comment'];
  }) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;

  & .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const QuestionView = ({
  languageState,
  defaultValues,
  isPreselectedLang = false,
  isPreferredLanguage = false,
  className,
  onChange,
  error,
  commentError,
}: IQuestionView) => {
  const { type } = defaultValues;
  const prepareQuestion = (type: SURVEY_QUESTION_TYPES) => {
    switch (type) {
      case SURVEY_QUESTION_TYPES.SINGLE:
        return (
          <QuestionViewSingle
            languageState={languageState}
            defaultValues={defaultValues}
            isPreselectedLang={isPreselectedLang}
            onChange={onChange}
            error={error}
            commentError={commentError}
            isPreferredLanguage={isPreferredLanguage}
          />
        );
      case SURVEY_QUESTION_TYPES.MULTIPLE:
        return (
          <QuestionViewMultiple
            languageState={languageState}
            defaultValues={defaultValues}
            isPreselectedLang={isPreselectedLang}
            onChange={onChange}
            error={error}
            commentError={commentError}
            isPreferredLanguage={isPreferredLanguage}
          />
        );
      case SURVEY_QUESTION_TYPES.SMILEYS:
        return (
          <QuestionViewSmileys
            languageState={languageState}
            defaultValues={defaultValues}
            isPreselectedLang={isPreselectedLang}
            onChange={onChange}
            error={error}
            commentError={commentError}
            isPreferredLanguage={isPreferredLanguage}
          />
        );
      case SURVEY_QUESTION_TYPES.TRAFFIC:
        return (
          <QuestionViewTrafficLights
            languageState={languageState}
            defaultValues={defaultValues}
            isPreselectedLang={isPreselectedLang}
            onChange={onChange}
            error={error}
            commentError={commentError}
            isPreferredLanguage={isPreferredLanguage}
          />
        );
      case SURVEY_QUESTION_TYPES.STARS:
        return (
          <QuestionViewStars
            languageState={languageState}
            defaultValues={defaultValues}
            isPreselectedLang={isPreselectedLang}
            onChange={onChange}
            error={error}
            commentError={commentError}
            isPreferredLanguage={isPreferredLanguage}
          />
        );
      case SURVEY_QUESTION_TYPES.TEXT:
        return (
          <QuestionViewText
            languageState={languageState}
            defaultValues={defaultValues}
            isPreselectedLang={isPreselectedLang}
            onChange={onChange}
            error={commentError}
            isPreferredLanguage={isPreferredLanguage}
          />
        );
      default:
        return null;
    }
  };

  return <Wrapper className={className}>{prepareQuestion(type)}</Wrapper>;
};

export { QuestionView };
