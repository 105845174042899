import React, { useState, useEffect } from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import styled from 'styled-components';

import Placeholder from '~/components/Placeholder';
import SelectJobProfileModal from '~/components/SelectJobProfileModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { ActiveRole, SectionBodyProps } from '~/pages/PassportPage/types';

import { SectionHeader } from './SectionHeader';
import { SectionList } from './SectionList';

import zeroState from '~/assets/no-result.webp';

import useBoolState from '~/hooks/useBoolState';
import { createCareerPlan, deleteCareerPlan, getUserCareerPlans } from '~/services/careerPlans';

const StateImg = styled.img`
  width: 75px;
  height: auto;
`;

const SectionRoles = ({ userId }: SectionBodyProps) => {
  const { i18n } = useLingui();
  const [roles, setRoles] = useState<ActiveRole[]>([]);
  const $loading = useBoolState(true);
  const $modal = useBoolState();

  useEffect(() => {
    const fetchData = async () => {
      const roles = await getUserCareerPlans(userId, { status: CAREER_PLAN_STATUSES.CURRENT }, [
        'jobProfile',
      ]);

      setRoles(
        Object.values(roles).map((role) => ({
          // @ts-ignore
          ...role,
          name: get(role, 'jobProfile.name'),
        })),
      );
      $loading.off();
    };
    fetchData();
    // eslint-disable-next-line
  }, [userId]);

  const addUserRole = async (selectedJobProfiles: ActiveRole[]) => {
    selectedJobProfiles.forEach(async (job) => {
      const newRole = await createCareerPlan(job.id, CAREER_PLAN_STATUSES.CURRENT, userId);
      setRoles([...roles, { ...newRole, name: get(newRole, 'jobProfile.name') }]);
    });
  };

  const deleteUserRole = async (roleId: string) => {
    deleteCareerPlan(roleId).then(() => setRoles(roles.filter((role) => role.id !== roleId)));
  };

  return (
    <div>
      <SectionHeader
        title={i18n._(t`Jobs`)}
        description={''}
        actionLabel={i18n._(t`Assign`)}
        actionOnClick={$modal.on}
      />
      <ShowSpinnerIfLoading loading={$loading.value}>
        <SectionList
          items={roles}
          itemActionLabel={i18n._(t`Remove`)}
          itemActionOnClick={deleteUserRole}
          placeholder={
            <Placeholder
              title={i18n._(t`No roles`)}
              subTitle={''}
              Icon={() => <StateImg alt="noTeams" src={zeroState} />}
            />
          }
        />
      </ShowSpinnerIfLoading>

      {$modal.value && (
        <SelectJobProfileModal
          title={i18n._(t`Assign to role profile`)}
          onModalClose={$modal.off}
          selectedUser={{ id: userId }}
          onSubmit={addUserRole}
        />
      )}
    </div>
  );
};

export { SectionRoles };
