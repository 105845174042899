import React from 'react';

import { IReviewTemplate } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useHistory } from 'react-router-dom';

import CheckBox from '~/components/CheckBox';
import IconMenu from '~/components/IconMenu';

import {
  MainRow,
  ConversationInfoWrap,
  ConversationInfoCol,
  ConversationInfo,
  Name,
  DateRow,
  MenuContainer,
  NumberOfQuestions,
} from './styling';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import convertToTimeString from '~/utils/convertToTimeString';

type TemplateRowProps = {
  template: IReviewTemplate & { talkingPoints: string[] };
  isConversationTemplate: boolean;
  isChecked: boolean;
  checkTemplate: (arg0: string) => void;
  hoverstate?: boolean;
  isInModal?: boolean;
  onDelete?: () => void;
  disableClick?: boolean;
  onDuplicate?: () => void;
};

const TemplateRow = ({
  template,
  isConversationTemplate,
  isChecked,
  hoverstate,
  checkTemplate,
  isInModal,
  onDelete,
  onDuplicate,
  disableClick,
}: TemplateRowProps) => {
  const { i18n } = useLingui();
  const history = useHistory();
  const numberOfQuestions = isConversationTemplate
    ? template.sections
        .map((i) => i.questions.length)
        .reduce((partialSum: number, a: number) => partialSum + a, 0)
    : template.talkingPoints.length;
  const menuItems = [
    isConversationTemplate &&
      onDuplicate && {
        label: i18n._(t`Duplicate`),
        action: onDuplicate,
      },
    onDelete && {
      label: i18n._(t`Delete`),
      action: onDelete,
    },
  ].filter((i) => i);

  const onItemClick = () => {
    if (isConversationTemplate) {
      history.push(
        routes.REVIEW_TEMPLATE_UPDATE.build(
          { companyId: undefined, teamId: undefined, role: ROLES.ADMIN },
          // @ts-ignore
          { reviewTemplateId: template.id, isBackPath: true },
        ),
      );
    } else {
      history.push(
        routes.CONVERSATION_TEMPLATE_UPDATE.build(
          { companyId: undefined, teamId: undefined, role: ROLES.ADMIN },
          // @ts-ignore
          { templateId: template.id, isBackPath: true },
        ),
      );
    }
  };
  return (
    <MainRow hoverstate={hoverstate}>
      <CheckBox size={20} onChange={checkTemplate} checked={isChecked} />
      <ConversationInfoWrap onClick={!disableClick ? onItemClick : undefined}>
        <ConversationInfoCol>
          <ConversationInfo disabled={disableClick}>
            <Name disabled={disableClick}>{template.name}</Name>
          </ConversationInfo>
          <NumberOfQuestions>
            {i18n._(t`Includes ${numberOfQuestions} questions`)}
          </NumberOfQuestions>
        </ConversationInfoCol>
      </ConversationInfoWrap>
      {!isInModal && (
        <>
          <DateRow wide>{convertToTimeString(template.meta.createdDate)}</DateRow>
          <DateRow wide>
            {convertToTimeString(template.meta.lastModifiedDate || template.meta.createdDate)}
          </DateRow>
          <MenuContainer>
            <IconMenu items={menuItems} />
          </MenuContainer>
        </>
      )}
    </MainRow>
  );
};

export { TemplateRow };
