import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import styled from 'styled-components';

import {
  AutocompleteFilterActivityCategories,
  AutocompleteFilterSkills,
} from '~/components/AutocompleteFilters';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import PaginationBar from '~/components/PaginationBar';
import Placeholder from '~/components/Placeholder';
import SearchSelectButton from '~/components/SearchSelectButton';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import { SearchField } from '~/components/Text';
import TextField from '~/components/TextField';

import ActivityItem from './ActivityItem';
import CreateActivityModule from './CreateActivityModule';
import SelectAll from './SelectAll';

import ActivitiesIcon from '~/assets/collections_bookmark.svg';

import { ACTIVITIES_LIBRARY_TYPE_FILTER } from '~/constants';
import { COLOR_PALETTE } from '~/styles';

import { ActivityModal } from '../ActivityModal';

const Content = styled.div`
  flex: 1;
  .libary-activity-modal {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    justify-content: flex-start;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const MarginRightWrapper = styled.div`
  margin-right: 8px;
`;

const StyledSearch = styled(SearchField)`
  margin-bottom: 8px;
  ${TextField} {
    border-radius: 6px;
    font-size: 14px;
    height: 40px;
  }
`;

const Filters = styled.div`
  margin-bottom: 24px;
`;

const FilterSkills = styled(AutocompleteFilterSkills)`
  width: 157px;
  height: 32px;
  margin-right: 8px;
`;

const FilterActivityCategories = styled(AutocompleteFilterActivityCategories)`
  width: 157px;
  height: 32px;
  margin-right: 8px;
`;

const FilterActivityTypes = styled(SearchSelectButton)`
  width: 157px;
  height: 32px;
  margin-right: 8px;
`;

const modalContentStyle = {
  flex: '1',
};

const scrollStyles = {
  height: 268,
};

// this component UI only
// use one of the next modal to add activities
// AddActivitiesFromCompanyLibraryModal
const AddActivitiesModal = ({
  activities,
  loading,
  onSubmit,
  onClose,
  isCreateActivityModule,
  filters,
  updateFilters,
  pagination,
  setPagination,
  total,
}) => {
  const [selectedActivities, setSelectedActivities] = useState({});
  const [viewActivity, setViewActivity] = useState(null);
  const { i18n } = useLingui();

  const onAdd = async () => {
    onSubmit(map(selectedActivities));
    onClose();
  };

  const onCheckActivity = (isSelected, activity) => {
    const newSelectedActivities = { ...selectedActivities };
    isSelected
      ? (newSelectedActivities[activity.id] = activity)
      : delete newSelectedActivities[activity.id];

    setSelectedActivities(newSelectedActivities);
  };

  const onPageChangeClick = ({ skip, index }) => {
    setPagination({
      ...pagination,
      skip,
      index,
    });
  };

  const size = values(selectedActivities).length;

  const typesList = map(ACTIVITIES_LIBRARY_TYPE_FILTER, (t) => ({
    id: t.id,
    label: t.label(i18n),
  }));

  return (
    <Modal
      title={i18n._(t`Add activity`)}
      onClose={onClose}
      centerModal
      minWidth={750}
      width={750}
      minHeight="660px"
      contentStyles={modalContentStyle}
      footerLeft={<Button label={i18n._(t`Cancel`)} type="primary-border" onClick={onClose} />}
      footerRight={
        <Row>
          {isCreateActivityModule && (
            <MarginRightWrapper>
              <CreateActivityModule />
            </MarginRightWrapper>
          )}
          <Button
            label={i18n._(t`Add ${size} activities`)}
            disabled={size === 0}
            type="primary"
            onClick={onAdd}
          />
        </Row>
      }
    >
      <Content>
        <Filters>
          <StyledSearch
            placeholder={i18n._(t`Search`)}
            value={filters?.search || ''}
            onChange={(e) => updateFilters({ search: e.target.value })}
          />
          <Row>
            <FilterSkills
              checkedList={filters?.skills || []}
              onChange={(skills) => updateFilters({ skills })}
            />
            <FilterActivityCategories
              checkedList={filters?.activityCategories || []}
              onChange={(activityCategories) => updateFilters({ activityCategories })}
            />
            <FilterActivityTypes
              checkedList={filters?.activityTypes || []}
              title={i18n._(t`Type`)}
              options={typesList}
              handleChange={(activityTypes) => updateFilters({ activityTypes })}
            />
          </Row>
        </Filters>
        <SelectAll
          items={activities}
          itemsSelected={selectedActivities}
          updateSelectedItems={(items) => setSelectedActivities(items)}
        />
        <ShowSpinnerIfLoading loading={loading}>
          {!isEmpty(activities) ? (
            <Scrollbars style={scrollStyles}>
              {activities.map((activity) => (
                <ActivityItem
                  key={activity.id}
                  activity={activity}
                  checked={!!selectedActivities[activity.id]}
                  onCheckActivity={onCheckActivity}
                  onClick={(activity) => setViewActivity(activity)}
                />
              ))}
            </Scrollbars>
          ) : (
            <Placeholder
              Icon={() => (
                <SvgIcon
                  url={ActivitiesIcon}
                  width="50px"
                  height="50px"
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              title={i18n._(t`No activities match your criteria`)}
              subTitle={i18n._(t`There are no learning activities available`)}
            />
          )}
          <PaginationBar
            pagination={pagination}
            changePagination={onPageChangeClick}
            count={total}
            noBorder
            noShadow
            showCount
          />
          {!!viewActivity && (
            <ActivityModal
              activityId={viewActivity.id}
              onClose={() => setViewActivity(null)}
              isReadOnly
            />
          )}
        </ShowSpinnerIfLoading>
      </Content>
    </Modal>
  );
};

AddActivitiesModal.propTypes = {
  activities: PropTypes.array,
  activityCategories: PropTypes.array,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isCreateActivityModule: PropTypes.bool,
  filters: PropTypes.object,
  updateFilters: PropTypes.func,
};

AddActivitiesModal.defaultProps = {
  filters: [],
  updateFilters: () => {},
};

export default AddActivitiesModal;
