import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import Tippy from '@tippyjs/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { confirm } from '~/components/ConfirmDialog';
import IconMenu from '~/components/IconMenu';
import JobProfilesToggleModal from '~/components/Modals/JobProfilesToggleModal';
import { ReviewCompleteInfo } from '~/components/Modals/ReviewCompleteInfo';
import NomineesModal from '~/components/NomineesModal';
import NomineesStatusesModal from '~/components/NomineesStatusesModal';
import { NominatePeopleAsParticipantModal } from '~/components/SelectedCoachesRow/components/NominatePeopleAsParticipantModal';
import Nominate from '~/components/SelectedCoachesRow/components/NomineesCoachesListModal';
import StatusIcon from '~/components/StatusIcon';
import AvatarGroup from '~/components/UI/AvatarGroup';
import { TableCol, TableRow } from '~/components/UI/Table';
import { UserAvatar } from '~/components/UserAvatar';

import { CONVERSATION_STATUSES, REQUEST_STATUSES, REVIEW_STAGES } from '~/constants';
import ROUTES from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getUsers } from '~/selectors/baseGetters';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import { isTaskStartDateInThePast } from '~/utils/isTaskStartDateInThePast';
import { getRequestsUsers } from '~/utils/requestsUtils';

const ConversationLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StatusWrap = styled.div`
  cursor: pointer;
`;

const tippyOpts = {
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
      },
    },
  ],
};

const todoRequestFilter = (r) =>
  [
    REQUEST_STATUSES.SHARED.key,
    REQUEST_STATUSES.ACCEPTED.key,
    REQUEST_STATUSES.CANCELLED.key,
  ].indexOf(r.status) === -1;

function getConversation(conversations) {
  const activeConvers = conversations.filter(
    (c) => [CONVERSATION_STATUSES.TODO, CONVERSATION_STATUSES.ACTIVE].indexOf(c.status) !== -1,
  );

  if (activeConvers.length > 0) {
    return activeConvers[activeConvers.length - 1];
  }
  return conversations[conversations.length - 1];
}

function getConversationBlock(conversations = []) {
  if (conversations.length > 0) {
    return (
      <StatusIcon
        noBg
        status="done"
        label={
          <ConversationLink
            to={ROUTES.CONVERSATION_SUPERVISION_USER.build(
              {},
              {
                conversationId: getConversation(conversations).id,
                isBackPath: true,
              },
            )}
          >
            <Trans>1-1 scheduled</Trans>
          </ConversationLink>
        }
      />
    );
  }
  // display nothing for old reviews
  // we don't have option to link review and conversation from review
  return <div />;
}

function getDateOfConversation(dateOfConversation, dateOfConversationEnd) {
  if (dateOfConversation && dateOfConversationEnd) {
    const tippyDate = `${convertToTimeString(
      dateOfConversation,
      TIME_FORMATS.CLASSIC_FULL,
    )} - ${convertToTimeString(dateOfConversationEnd, TIME_FORMATS.CLASSIC_NO_DATE)}`;
    return (
      <Tippy trigger="mouseenter" theme="light" popperOptions={tippyOpts} content={tippyDate}>
        <StatusWrap>
          <StatusIcon noBg status="done" label={`${convertToTimeString(dateOfConversation)}`} />
        </StatusWrap>
      </Tippy>
    );
  }
  return <StatusIcon noBg status="todo" label={<Trans>To schedule</Trans>} />;
}

function isConversationCompleted(userReview, usersToComplete) {
  const usersNotCompleted = usersToComplete.filter((userId) =>
    userReview.signatures.every((signature) => signature.userId !== userId),
  );
  return usersNotCompleted.length === 0;
}

function getCompleteBlock(userReview, usersToComplete) {
  const usersNotCompleted = usersToComplete.filter((userId) =>
    userReview.signatures.every((signature) => signature.userId !== userId),
  );

  return <AvatarGroup users={usersToComplete} size={26} usersGray={usersNotCompleted} />;
}

const UserReviewStatusItem = ({
  locked,
  userReview,
  isSelfReview,
  isUsersReview,
  isCoachesReview,
  onRequestsCreated,
  onRequestCancel,
  onFeedbackRemind,
  onDeleteSignatures,
  i18n,
  updateConversationCoaches,
  isLinkedConversations,
  digitalSign,
  isCompleteLogic,
  originalReview,
}) => {
  const users = useSelector(getUsers);
  const [nomineesModalType, setNomineesModalType] = useState();
  const [statusesModalType, setStatusesModalType] = useState();
  const $isRoleModal = useBoolState(false);
  const $isParticipantsModal = useBoolState(false);
  const allRequestsUsers = getRequestsUsers(userReview.requests);
  const todoRequestsUsers = getRequestsUsers(userReview.requests, todoRequestFilter);
  const $showConversationCoachModal = useBoolState(false);
  const $showLockedConversationCoachModal = useBoolState(false);
  const $showReviewCompletedInfoModal = useBoolState(false);
  const [conversationCoaches, setConversationCoaches] = useState([
    ...userReview.conversationCoaches,
  ]);
  const isLockedNominate = get(originalReview, `lockedStages[${REVIEW_STAGES.NOMINATE}]`, false);
  const isLockedFeedback = get(originalReview, `lockedStages[${REVIEW_STAGES.FEEDBACK}]`, false);
  const isLockedSignatures = !isEmpty(get(userReview, 'signatures', []));
  const isLocked = isLockedFeedback || isLockedSignatures;
  const usersToComplete = [userReview.createdFor, ...(userReview.conversationCoaches || [])];

  const saveConversationCoaches = () => {
    updateConversationCoaches(userReview.id, conversationCoaches);
  };

  return (
    <TableRow>
      <TableCol>
        <UserAvatar userId={userReview.createdFor} />
      </TableCol>
      {isSelfReview && (
        <TableCol>
          <StatusIcon noBg status={userReview.isSelfFeedbackProvided ? 'done' : 'todo'} />
        </TableCol>
      )}
      {isUsersReview && (
        <TableCol onClick={() => setStatusesModalType(NomineesStatusesModal.NOMINATE_TYPE.peer)}>
          {allRequestsUsers.peers.length > 0 ? (
            <AvatarGroup
              users={allRequestsUsers.users}
              emails={allRequestsUsers.outside}
              size={26}
              usersGray={todoRequestsUsers.peers}
            />
          ) : isCompleteLogic && isConversationCompleted(userReview, usersToComplete) ? (
            <StatusIcon noBg status="archived" />
          ) : (
            <StatusIcon noBg status="todo" />
          )}
        </TableCol>
      )}
      {isCoachesReview && (
        <>
          <TableCol onClick={() => setStatusesModalType(NomineesStatusesModal.NOMINATE_TYPE.coach)}>
            {allRequestsUsers.coaches.length > 0 ? (
              <AvatarGroup
                users={allRequestsUsers.coaches}
                size={26}
                usersGray={todoRequestsUsers.coaches}
              />
            ) : (
              <StatusIcon noBg status="todo" />
            )}
          </TableCol>
          <TableCol onClick={$showLockedConversationCoachModal.on}>
            {userReview.conversationCoaches.length > 0 ? (
              <AvatarGroup users={userReview.conversationCoaches} size={26} usersGray={[]} />
            ) : (
              <StatusIcon noBg status="todo" />
            )}
          </TableCol>
        </>
      )}
      <TableCol>
        {getDateOfConversation(userReview.dateOfConversation, userReview.dateOfConversationEnd)}
      </TableCol>
      {isLinkedConversations && (
        <TableCol>{getConversationBlock(userReview.conversations)}</TableCol>
      )}
      {isCompleteLogic && (
        <TableCol onClick={$showReviewCompletedInfoModal.on}>
          {getCompleteBlock(userReview, usersToComplete)}
        </TableCol>
      )}
      {!locked && (
        <TableCol>
          <IconMenu
            items={[
              userReview.jobProfiles
                ? {
                    label: i18n._(t`View enabled roles`),
                    action: $isRoleModal.on,
                  }
                : null,
              isUsersReview && !isLockedSignatures
                ? {
                    label: i18n._(t`Update nominated peers`),
                    action: () => setNomineesModalType(NomineesModal.NOMINATE_TYPE.peer),
                  }
                : null,
              isCoachesReview && !isLockedSignatures
                ? {
                    label: i18n._(t`Update nominated coaches`),
                    action: () => setNomineesModalType(NomineesModal.NOMINATE_TYPE.coach),
                  }
                : null,
              !isLockedSignatures
                ? {
                    label: i18n._(t`Update nominated participants`),
                    action: $showConversationCoachModal.on,
                  }
                : null,
              isLockedSignatures
                ? {
                    label: i18n._(t`Unlock conversation`),
                    action: async () => {
                      const textConfirm = i18n._(
                        t`Are you sure you want to unlock this conversation? This will allow everyone to make updates to the review. However, this will also require everyone to complete the report again.`,
                      );
                      if (await confirm(i18n, textConfirm)) {
                        await onDeleteSignatures(userReview.id);
                      }
                    },
                  }
                : null,
            ].filter((i) => i)}
          />
        </TableCol>
      )}

      {/* Nominate peers or coaches (edit)*/}
      {nomineesModalType && (
        <NomineesModal
          review={userReview}
          type={nomineesModalType}
          onClose={() => setNomineesModalType(null)}
          onRequestsCreated={onRequestsCreated}
          onRequestCancel={onRequestCancel}
          isDisabled={
            nomineesModalType === NomineesModal.NOMINATE_TYPE.peer &&
            (!isTaskStartDateInThePast(originalReview?.startDateNominate) ||
              isLocked ||
              isLockedNominate) // disable only is nominate peers start date in the future
          }
          isChooseYourself={true} // admin should be able to nominate yourself as peer or coach
        />
      )}
      {/* Nominate peers or coaches (view only)*/}
      {statusesModalType && (
        <NomineesStatusesModal
          review={userReview}
          type={statusesModalType}
          onClose={() => setStatusesModalType(null)}
          onFeedbackRemind={onFeedbackRemind}
        />
      )}
      {/* Nominate conversation coaches (edit) */}
      {$showConversationCoachModal.value && (
        <Nominate
          onClose={() => {
            setConversationCoaches([...userReview.conversationCoaches]);
            $showConversationCoachModal.off();
          }}
          coaches={conversationCoaches}
          coachesSelected={conversationCoaches}
          onSave={saveConversationCoaches}
          setCoachArray={(newCoaches) => {
            setConversationCoaches(newCoaches);
          }}
          setUsers={(conversationCoaches) => {
            setConversationCoaches(conversationCoaches);
            saveConversationCoaches();
            $showConversationCoachModal.off();
          }}
          removeCoach={(coach) => {
            setConversationCoaches(conversationCoaches.filter((i) => i !== coach));
          }}
          isParticipants
          changeModal={() => {
            $showConversationCoachModal.off();
            $isParticipantsModal.on();
          }}
        />
      )}
      {/* Nominate conversation coaches (view only)*/}
      {$showLockedConversationCoachModal.value && (
        <Nominate
          onClose={$showLockedConversationCoachModal.off}
          coaches={conversationCoaches}
          coachesSelected={conversationCoaches}
          onToggleCoach={false}
          title={i18n._(t`Selected participants`)}
        />
      )}
      {$isRoleModal.value && (
        <JobProfilesToggleModal
          items={userReview.jobProfiles}
          itemsSelected={userReview.jobProfiles.map((j) => j.id)}
          onClose={$isRoleModal.off}
          onToggle={() => {}} // to display toggle without action
          isReadOnly
        />
      )}
      {$isParticipantsModal.value && (
        <NominatePeopleAsParticipantModal
          selectedUser={users[userReview.createdFor]}
          onClose={() => {
            $showConversationCoachModal.on();
            $isParticipantsModal.off();
          }}
          setNewParticipants={(users) => {
            setConversationCoaches([...conversationCoaches, ...users]);
          }}
          selectedParticipants={conversationCoaches}
        />
      )}
      {$showReviewCompletedInfoModal.value && (
        <ReviewCompleteInfo
          onClose={$showReviewCompletedInfoModal.off}
          users={usersToComplete}
          signatures={userReview.signatures}
          digitalSign={digitalSign}
        />
      )}
    </TableRow>
  );
};

UserReviewStatusItem.propTypes = {
  isLinkedConversations: PropTypes.bool,
  originalReview: PropTypes.object,
};

export default React.memo(withI18n()(UserReviewStatusItem));
