import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import ActivityItem from './ActivityItem';
import GoalItem from './GoalItem';

import useBoolState from '~/hooks/useBoolState';
import { updateUserActivityProgress } from '~/services/userActivities';

import { TreeItemActivities, TreeItemRow, TreeItemWrapper } from '../design';

import type { IGoal, IActivity } from '@learned/types';

interface IProps {
  item: IGoal;
  activitiesReadonly: boolean;
  isIndividualTab: boolean;
  refreshGoals: () => void;
  isUpdateActivityProgress: boolean;
  isUpdateGoalProgress: boolean;
  isOpenAll: boolean;
  onGoalClick: (id: string) => void;
  level?: number;
  menuItems?: string[];
  isHideMenuItems?: boolean;
}

const GoalTreeItem = ({
  item,
  isOpenAll,
  refreshGoals,
  menuItems,
  isUpdateActivityProgress,
  isIndividualTab,
  onGoalClick,
  level,
  isHideMenuItems = false,
}: IProps) => {
  const $open = useBoolState(false);
  const isChildren = !isEmpty(item.children);
  const isActivities = !isEmpty(item.activities);

  useEffect(() => {
    isOpenAll ? $open.on() : $open.off();

    // eslint-disable-next-line
  }, [isOpenAll]);

  const updateActivity = async (id: string, data: any) => {
    await updateUserActivityProgress(id, data);
    await refreshGoals();
  };

  if (level && level > 3) {
    return null;
  }

  return (
    <TreeItemWrapper>
      <TreeItemRow>
        <GoalItem
          key={item.id}
          onGoalClick={onGoalClick}
          item={item}
          refreshGoals={refreshGoals}
          isShowCalcProgress
          isIndividualTab={isIndividualTab}
          handleCollapse={$open.toggle}
          isCollapsed={$open.value}
          menuItems={menuItems}
          isHideMenuItems={isHideMenuItems}
        />
      </TreeItemRow>
      {isActivities && $open.value && (
        <TreeItemActivities>
          {map(item.activities as IActivity[], (activity: IActivity) => (
            <ActivityItem
              key={activity.id}
              activity={activity}
              activitiesReadonly={!isUpdateActivityProgress}
              isGoalTree
              isOnClick={false}
              // @ts-ignore
              onUpdate={(data) => updateActivity(activity.id, data)}
            />
          ))}
        </TreeItemActivities>
      )}

      {isChildren &&
        $open.value &&
        sortBy(item.children, (child) => child.name.toLowerCase()).map((child) => (
          // @ts-ignore
          <GoalTreeItem
            key={child.id}
            item={child}
            isOpenAll={isOpenAll}
            level={level ? level + 1 : 1}
          />
        ))}
    </TreeItemWrapper>
  );
};

GoalTreeItem.menuItems = GoalItem.menuItems;

export default GoalTreeItem;
