import React, { Component } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Notification from '~/components/Notifications/Notification';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';

import crossIcon from '~/assets/close-icn.svg';
import NotificationIcon from '~/assets/ic-notifications-24-px.svg';

import { COLORS, COLOR_PALETTE } from '~/styles';

const NotificationWrapper = styled.div`
  width: 431px;
  height: 100%;
`;

const PlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Header = styled.div`
  cursor: default;
  display: flex;
  padding: 12px 16px;
  border-bottom: solid 1px ${COLORS.BORDER_HARD};
`;

const HeaderTitle = styled.div`
  color: ${COLORS.TEXT_BLACK};
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
`;

const NotificationCross = styled.div`
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

class NotificationsDropdown extends Component {
  static defaultProps = {
    notifications: [],
  };

  constructor(props) {
    super(props);
    this.onNotificationDeleted = this.onNotificationDeleted.bind(this);
  }

  onNotificationDeleted(notificationId) {
    if (this.props.onNotificationDeleted) {
      this.props.onNotificationDeleted(notificationId);
    }
  }

  render() {
    const { notifications, onClose, i18n, loading } = this.props;

    return (
      <>
        <Header>
          <HeaderTitle>
            <Trans>Notifications</Trans>
          </HeaderTitle>
          <NotificationCross onClick={onClose}>
            <SvgIcon width="12px" height="12px" url={crossIcon} />
          </NotificationCross>
        </Header>
        <Scrollbars hideTracksWhenNotNeeded style={{ width: '431px', height: '355px' }}>
          <ShowSpinnerIfLoading loading={loading}>
            <NotificationWrapper>
              {notifications.length > 0 ? (
                notifications.map((notification) => {
                  return (
                    <Notification
                      highlighted={!notification.isRead}
                      notification={notification}
                      key={notification.id}
                      onClose={onClose}
                      onDeleted={this.onNotificationDeleted}
                    />
                  );
                })
              ) : (
                <PlaceholderWrapper>
                  <Placeholder
                    Icon={() => (
                      <SvgIcon
                        url={NotificationIcon}
                        width={'50px'}
                        height={'50px'}
                        isDefaultColor
                        defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                      />
                    )}
                    title={i18n._(t`All done`)}
                    subTitle={i18n._(t`You have no notifications`)}
                  />
                </PlaceholderWrapper>
              )}
            </NotificationWrapper>
          </ShowSpinnerIfLoading>
        </Scrollbars>
      </>
    );
  }
}

export default withI18n()(withRouter(NotificationsDropdown));
