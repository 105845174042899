import React, { useEffect, useState } from 'react';

import {
  SURVEY_QUESTION_TYPES,
  SURVEY_RATING_STATUS,
  SURVEY_STATUS,
  SURVEY_TASK_STATUS,
} from '@learned/constants';
import { ISurvey, WithComments } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isNumber } from 'lodash';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import { useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button } from '~/components/Buttons/Button';
import { ButtonSize, ButtonVariant } from '~/components/Buttons/types';
import { DashboardHeader } from '~/components/DashboardHeader';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { StyledEditor } from '~/components/Editor/design';
import {
  ActionItemBlock,
  Actions,
  BackButton,
  SubmitButton,
} from '~/components/FillOutSurvey/design';
import { addValuesFromRatingsToQuestionForm } from '~/components/FillOutSurvey/utils';
import { ICONS } from '~/components/Icon';
import { IconOld } from '~/components/IconOld';
import { QuestionView } from '~/components/QuestionView';
import type { ISection } from '~/components/SideBar';
import { SECTION_TYPE } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TableOfContents } from '~/components/TableOfContents';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { SurveyOutro } from '~/pages/SurveyTaskView/components/outro';

import { resolver } from './validation';

import type { IQuestionForm } from '~/@types/question';
import { useAutoSaveState } from '~/hooks/useAutoSaveState';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { setSurveyRatings } from '~/services/surveyRatings';
import { changeSurveyTaskStatus } from '~/services/surveyTasks';
import { COLORS } from '~/styles';
import { turnSurveyQuestionIntoQuestionForm } from '~/utils/turnSurveyQuestionIntoQuestionForm';

import type { IMultiLangString, ISurveyQuestion, ISurveyRating } from '@learned/types';

const StyledQuestionView = styled(QuestionView)`
  max-width: 500px;
  width: 500px;
  ${StyledEditor} {
    .ql-container {
      background-color: ${COLORS.WHITE};
    }
    .ql-toolbar {
      background-color: ${COLORS.WHITE};
    }
  }
`;
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Start = styled.span`
  display: flex;
  justify-content: flex-start;
`;

const Center = styled.span`
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
`;

const End = styled.span`
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
`;

const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Header = styled.div`
  display: block;
  position: relative;
  flex-shrink: 0;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 118px 291px 64px 64px;
  gap: 43px;
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
`;

const PreviewBar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.WARNING};
  padding: 9px 32px;
`;

interface IFillOutSurveyProps {
  surveyTask: {
    id?: string;
    status?: SURVEY_TASK_STATUS;
    name: IMultiLangString;
    questions: ISurveyQuestion[];
    survey: ISurvey;
  };
  goBack: () => void;
  onEdit?: (questionId: string) => void;
  isPreview?: boolean;
  ratings?: ISurveyRating[];
}

const createInitialSections = (
  questions: ISurveyQuestion[],
  getMultiLangString: (multiLangString: Record<string, string>) => string,
  ratings: ISurveyRating[] | undefined,
): ISection[] => {
  return questions.map((question) => {
    const relevantRating = ratings?.find((rating) => rating.question === question.id);
    let missingAnswer = false;
    switch (question?.type) {
      case SURVEY_QUESTION_TYPES.TEXT:
        missingAnswer = isEmpty(relevantRating?.answer);
        break;
      case SURVEY_QUESTION_TYPES.SMILEYS:
      case SURVEY_QUESTION_TYPES.TRAFFIC:
      case SURVEY_QUESTION_TYPES.STARS:
      case SURVEY_QUESTION_TYPES.SINGLE:
        if (!isNumber(relevantRating?.answer)) {
          missingAnswer = true;
        }
        break;
      case SURVEY_QUESTION_TYPES.MULTIPLE:
        if (isEmpty(relevantRating?.answer)) {
          missingAnswer = true;
        }
        break;
    }

    let missingComment = false;
    if (
      (question?.data as WithComments)?.isCommentsObligated === true &&
      question?.type !== SURVEY_QUESTION_TYPES.TEXT &&
      isEmpty(relevantRating?.comment)
    ) {
      missingComment = true;
    }

    return {
      title: getMultiLangString(question.name),
      type: missingAnswer || missingComment ? undefined : SECTION_TYPE.DONE,
    };
  });
};

export interface AnswerSurveyForm {
  ratings: {
    ratingId?: string;
    company: string;
    user: string;
    survey: string;
    event: string;
    task: string;
    question: string;
    questionType: string;
    answer?: string | number | number[] | null;
    comment?: string;
    status: SURVEY_RATING_STATUS;
  }[];
  isPublishing: boolean;
}

const FillOutSurvey = ({ surveyTask, onEdit, goBack, isPreview, ratings }: IFillOutSurveyProps) => {
  const { i18n } = useLingui();
  const languageState = useLanguageState(true);
  const getMultiLangString = useMultiLangString();
  const { addToast } = useToasts();

  const firstUnansweredRating = findIndex(ratings, (rating) => {
    const relevantQuestion = surveyTask.questions.find(
      (question) => question.id === rating.question,
    );
    if (
      isEmpty(rating.comment) &&
      (relevantQuestion?.data as WithComments)?.isCommentsObligated === true
    ) {
      return true;
    }
    return rating.answer === null || rating.answer === undefined;
  });
  const sectionState = useSectionState(
    createInitialSections(surveyTask.questions, getMultiLangString, ratings),
    { currentSection: firstUnansweredRating === -1 ? 0 : firstUnansweredRating },
  );
  const { setLastSaveSuccess } = useAutoSaveState({
    errorMessage: i18n._(t`Please fill all obligated fields`),
  });
  const [showOutro, setShowOutro] = useState(false);
  const [questions, setQuestions] = useState<IQuestionForm[]>(
    surveyTask.questions.map((q) => {
      const rating = ratings?.find((rating) => rating.question === q.id);
      const questionForm = turnSurveyQuestionIntoQuestionForm(q, languageState);
      if (!rating) {
        return questionForm;
      }
      return addValuesFromRatingsToQuestionForm(rating, questionForm);
    }),
  );
  const { setValue, trigger, control, handleSubmit, formState } = useForm<AnswerSurveyForm>({
    mode: 'all',
    resolver,
    context: { surveyTask },
    defaultValues: {
      ratings: ratings?.map(({ id, ...rest }) => ({ ...rest, ratingId: id })),
      isPublishing: true,
    },
  });
  const errors = formState.errors;
  const { fields, update } = useFieldArray({
    name: 'ratings',
    control,
  });

  useEffect(() => {
    const ratingErrors = errors?.ratings;

    fields.forEach((_, index) => {
      const fieldError = ratingErrors?.[index];
      if (fieldError?.comment?.type !== undefined || fieldError?.answer?.type !== undefined) {
        sectionState.setErrorSection(index, true);
      } else {
        sectionState.setErrorSection(index, false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.ratings]);

  useEffect(() => {
    trigger('ratings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = async (data: Partial<ISurveyRating>, section: number) => {
    const newData = {
      ...fields[section],
      ...(data.answer !== undefined && { answer: data.answer }),
      ...(data.comment !== undefined && { comment: data.comment }),
    };
    await update(section, newData);
    setQuestions((oldQuestions) => {
      const newQuestions = [...oldQuestions];
      newQuestions[sectionState.currentSection] = addValuesFromRatingsToQuestionForm(
        newData,
        newQuestions[sectionState.currentSection],
      );
      return newQuestions;
    });
    await trigger('ratings');
  };

  const onSuccess = async (data: AnswerSurveyForm, isPublishing = false) => {
    const transformedData = data.ratings.map((rating) => ({
      id: rating.ratingId,
      company: rating.company,
      user: rating.user,
      survey: rating.survey,
      question: rating.question,
      task: rating.task,
      event: rating.event,
      answer: rating.answer,
      comment: rating.comment,
    }));
    setValue('isPublishing', true);
    try {
      const result = await setSurveyRatings(transformedData);
      const newSurveyTask = { ...surveyTask };
      if (surveyTask.status === SURVEY_TASK_STATUS.TODO) {
        const result = await changeSurveyTaskStatus(
          surveyTask.id as string,
          SURVEY_TASK_STATUS.DRAFT,
        );
        if (result.code === 200) {
          newSurveyTask.status = SURVEY_TASK_STATUS.DRAFT;
        }
      }

      if (
        isPublishing &&
        result.code === 200 &&
        newSurveyTask.status === SURVEY_TASK_STATUS.DRAFT
      ) {
        const result = await changeSurveyTaskStatus(
          surveyTask.id as string,
          SURVEY_TASK_STATUS.COMPLETED,
        );
        if (result.code === 200) {
          setLastSaveSuccess();
          addToast({ title: 'Answers published', type: TOAST_TYPES.SUCCESS });
          goBack();
          return;
        }
      }

      if (result.code === 200 && newSurveyTask.status === SURVEY_TASK_STATUS.COMPLETED) {
        setLastSaveSuccess();
        addToast({ title: 'Answers saved', type: TOAST_TYPES.INFO });
        goBack();
      }

      if (result.code === 200 && newSurveyTask.status === SURVEY_TASK_STATUS.DRAFT) {
        setLastSaveSuccess();
        addToast({ title: 'Answers saved as draft', type: TOAST_TYPES.INFO });
        goBack();
      }
    } catch (err: any) {
      // return to dashboard if the user has lost access to survey task
      if (err.response.status === 403) {
        goBack();
      }
    }
  };

  const onFail = () => {
    sectionState.setTriedToSubmit();
    addToast({
      title: i18n._(t`Warning`),
      subtitle: i18n._(t`Please fill in all obligated fields`),
      type: TOAST_TYPES.INFO,
    });
    setShowOutro(false);
    sectionState.goToFirstErrorSection();
    setValue('isPublishing', true);
  };

  const onPublish = async (e?: React.BaseSyntheticEvent) => {
    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, true);
      },
      () => onFail(),
    );

    return submit(e);
  };

  const onSave = async (e?: React.BaseSyntheticEvent) => {
    setValue('isPublishing', false);
    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, false);
      },
      () => onFail(),
    );

    return submit(e);
  };

  const hasError =
    sectionState.triedToSubmit &&
    errors?.ratings?.[sectionState.currentSection]?.answer?.type !== undefined;
  const hasCommentError =
    sectionState.triedToSubmit &&
    errors?.ratings?.[sectionState.currentSection]?.comment?.type !== undefined;

  const nextSection = () => {
    trigger();
    if (sectionState.currentSection !== sectionState.sections.length - 1) {
      sectionState.setCurrentSection(sectionState.currentSection + 1);
    } else {
      setShowOutro(true);
    }
  };

  const previousSection = () => {
    trigger();

    if (!showOutro) {
      sectionState.setCurrentSection(sectionState.currentSection - 1);
    }
    setShowOutro(false);
  };

  return (
    <>
      <Header>
        <DashboardHeader
          // @ts-ignore
          title={getMultiLangString(surveyTask.name)}
          onBack={goBack}
          height={'112px'}
          actions={
            <Actions>
              {isPreview && (
                <ActionItemBlock>
                  <MultiLangComponent isSingleSelect languageState={languageState} />
                </ActionItemBlock>
              )}
              {onEdit !== undefined &&
                surveyTask.questions[sectionState.currentSection] !== undefined && (
                  <ActionItemBlock>
                    <Button
                      label={i18n._(t`Edit question`)}
                      icon={ICONS.EDIT_PENCIL}
                      size={ButtonSize.MEDIUM}
                      variant={ButtonVariant.TEXT_PRIMARY}
                      onClick={() => onEdit(surveyTask.questions[sectionState.currentSection].id)}
                    />
                  </ActionItemBlock>
                )}
              {!isPreview && surveyTask.survey.status !== SURVEY_STATUS.ARCHIVED && (
                <ActionItemBlock>
                  <Button
                    label={
                      surveyTask.status === SURVEY_TASK_STATUS.COMPLETED
                        ? i18n._(t`Save`)
                        : i18n._(t`Save as draft`)
                    }
                    size={ButtonSize.MEDIUM}
                    variant={ButtonVariant.TEXT_PRIMARY}
                    onClick={onSave}
                  />
                </ActionItemBlock>
              )}
            </Actions>
          }
          subHeader={
            isPreview && (
              <PreviewBar>
                <Start />
                <Center>
                  <Trans>Preview (the most recent version)</Trans>
                </Center>
                <End>
                  <Trans>Your input will not be saved</Trans>
                </End>
              </PreviewBar>
            )
          }
        />
      </Header>
      <Content>
        <TableOfContents
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          hideErrorState={!sectionState.triedToSubmit}
          setCurrentSection={(i) => {
            trigger();
            sectionState.setCurrentSection(i);
            setShowOutro(false);
          }}
          noNextSection={showOutro}
          nextSection={nextSection}
          previousSection={previousSection}
        />
        <Wrapper>
          <QuestionContainer>
            {!showOutro && questions[sectionState.currentSection] !== undefined && (
              <>
                <StyledQuestionView
                  languageState={languageState}
                  defaultValues={questions[sectionState.currentSection]}
                  onChange={(data) => onChange(data, sectionState.currentSection)}
                  error={hasError}
                  commentError={hasCommentError}
                  isPreferredLanguage={!isPreview}
                  isPreselectedLang={isPreview}
                />
                <ButtonBar>
                  <BackButton
                    isHidden={sectionState.currentSection === 0}
                    type="button"
                    onClick={previousSection}
                  >
                    <IconOld name="ChevronBack" width={16} height={16} className="icon" />
                    <Trans>Back</Trans>
                  </BackButton>
                  <SubmitButton
                    isHidden={
                      isPreview && sectionState.currentSection === sectionState.sections.length - 1
                    }
                    type="button"
                    onClick={nextSection}
                  >
                    <Trans>Next</Trans>
                    <IconOld name="ChevronForward" width={16} height={16} className="icon" />
                  </SubmitButton>
                </ButtonBar>
              </>
            )}
            {showOutro && <SurveyOutro onSubmit={onPublish} surveyTask={surveyTask} />}
          </QuestionContainer>
        </Wrapper>
      </Content>
    </>
  );
};

export { FillOutSurvey };
