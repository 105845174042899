import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Item = styled.div`
  display: flex;
  align-items: center;
  min-height: 64px;
  width: 100%;
  padding: 14px 32px 14px 4px;
  box-sizing: border-box;

  &:last-child {
    border-bottom: none;
  }
`;

export const ItemNameText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 420px;
  color: ${(props) =>
    !props.$disabled && !props.$completed ? COLORS.TEXT_BLACK : COLORS.TEXT_SECONDARY};
  margin-left: 20px;

  ${(props) =>
    props.$completed && {
      textDecoration: 'line-through',
    }}
`;

export const ItemText = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${(props) => (props.$activeColor ? 'var(--company-color)' : COLORS.TEXT_SECONDARY)};
`;
