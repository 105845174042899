import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getSkillCategories() {
  return cloudRequest(serverRoutes.skillCategories.getSkillCategories);
}

export function updateSkillCategory(id, body) {
  return cloudRequest(serverRoutes.skillCategories.updateSkillCategory(id), {}, body);
}
