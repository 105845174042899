import React, { useRef } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import DropdownButton from '~/components/DropdownButton';
import ExpandMoreIcon from '~/components/Icons/ExpandMore';
import ReviewOptions from '~/components/ReviewOptions';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { ROLES, REVIEW_STAGES, REVIEW_REMINDERS } from '~/constants';
import routes from '~/constants/routes';
import { getSelectedRole } from '~/selectors/baseGetters';
import { sendReviewFeedbackReminder } from '~/services/notifications';
import {
  downloadUserReviewPDFReport,
  deleteReview,
  lockReviewStage,
  sendDigitalSignReminder,
} from '~/services/reviews';

const HeadingDropdown = ({
  review,
  updateReviewState,
  currentRole,
  goBack,
  openCoachesModal,
  setDefaultConversationCoaches,
  openConversationCoachModal,
}) => {
  const { i18n } = useLingui();
  const popoverRef = useRef();
  const { addToast } = useToasts();
  const role = useSelector(getSelectedRole);

  const closeOptionsModal = () => {
    popoverRef.current._tippy.hide();
  };

  const showToastMessage = (title, message) => {
    addToast({
      title,
      subtitle: message,
      type: TOAST_TYPES.INFO,
    });
  };

  const onReviewReminderSend = (reminderType) => {
    const newReview = {
      ...review,
      reminders: {
        ...review.reminders,
        [`${reminderType}`]: moment().format(),
      },
    };
    updateReviewState(newReview);
  };

  const sendNominatePeersReminder = async () => {
    await sendReviewFeedbackReminder(review.id, { isNominatePeers: true });
    await onReviewReminderSend(REVIEW_REMINDERS.NOMINATE_PEERS);
    closeOptionsModal();
    showToastMessage(
      i18n._(t`Reminder(s) send`),
      i18n._(t`A reminder has been sent to all employees whom have not nominated peers`),
    );
  };

  const sendPeerReviewReminder = async () => {
    await sendReviewFeedbackReminder(review.originalReview, { isPeers: true });
    await onReviewReminderSend(REVIEW_REMINDERS.PEER_REVIEW);
    closeOptionsModal();
    showToastMessage(
      i18n._(t`Reminder(s) send`),
      i18n._(
        t`A reminder has been sent to all employees whom have not completed their peer review.`,
      ),
    );
  };

  const sendSelfReviewReminder = async () => {
    await sendReviewFeedbackReminder(review.originalReview, { isSelf: true });
    await onReviewReminderSend(REVIEW_REMINDERS.SELF_REVIEW);
    closeOptionsModal();
    showToastMessage(
      i18n._(t`Reminder(s) send`),
      i18n._(
        t`A reminder has been sent to all employees whom have not completed their self review.`,
      ),
    );
  };

  const sendCoachReviewReminder = async () => {
    await sendReviewFeedbackReminder(review.originalReview, { isCoach: true });
    await onReviewReminderSend(REVIEW_REMINDERS.COACH_REVIEW);
    closeOptionsModal();
    showToastMessage(
      i18n._(t`Reminder(s) send`),
      i18n._(
        t`A reminder has been sent to all employees whom have not completed their coach review.`,
      ),
    );
  };

  const onSendDigitalSignReminder = async () => {
    await sendDigitalSignReminder(review.id);
    await onReviewReminderSend(REVIEW_REMINDERS.DIGITAL_SIGN);
    closeOptionsModal();
    showToastMessage(
      i18n._(t`Reminder(s) send`),
      i18n._(t`A reminder is sent to all participants that did not sign the report.`),
    );
  };

  const onReviewStageLocked = (stage, newValue) => {
    const newReview = {
      ...review,
      lockedStages: {
        ...review.lockedStages,
        [stage]: newValue,
      },
    };
    updateReviewState(newReview);
  };

  const toggleDisableReview = async () => {
    await lockReviewStage(
      review.originalReview,
      REVIEW_STAGES.NOMINATE,
      !review.lockedStages[REVIEW_STAGES.NOMINATE],
    );
    onReviewStageLocked(REVIEW_STAGES.NOMINATE, !review.lockedStages[REVIEW_STAGES.NOMINATE]);
  };

  const toggleLockReview = async () => {
    const currentValue = review.lockedStages[REVIEW_STAGES.FEEDBACK];
    const alertString = !currentValue
      ? i18n._(
          t`Are you sure you want to complete this stage and the review round? This will cancel all open feedback requests. This cannot be undone.`,
        )
      : i18n._(
          t`Are you sure you want to unlock this stage and the review round? All open feedback requests are currently canceled and will need to be sent again.`,
        );

    if (await confirm(i18n, alertString)) {
      const updatedReview = await lockReviewStage(
        review.originalReview,
        REVIEW_STAGES.FEEDBACK,
        !currentValue,
      );
      const newReview = {
        ...review,
        lockedStages: {
          ...review.lockedStages,
          [REVIEW_STAGES.FEEDBACK]: updatedReview.lockedStages[REVIEW_STAGES.FEEDBACK],
          [REVIEW_STAGES.NOMINATE]: updatedReview.lockedStages[REVIEW_STAGES.NOMINATE],
        },
      };
      updateReviewState(newReview);
    }
  };

  const handleExport = async () => {
    closeOptionsModal();
    showToastMessage(
      i18n._(t`Downloading review PDF`),
      i18n._(t`Generating the review in PDF. Please wait a few seconds.`),
    );

    await downloadUserReviewPDFReport(review.id, review.name);
  };

  const openUpdateReviewFlow = () => {
    closeOptionsModal();
    routes.REVIEW_UPDATE.go(
      { role },
      {
        reviewId: role === ROLES.USER || role === ROLES.COACH ? review.originalReview : review.id,
        isBackPath: true,
      },
    );
  };

  const deleteThisReview = async () => {
    if (
      await confirm(
        i18n,
        currentRole === ROLES.COACH
          ? i18n._(
              t`Are you sure you want to delete this ${review.name}? If you have assigned this review to multiple team members this will delete this ${review.name} for all. This action cannot be undone.`,
            )
          : i18n._(t`Are you sure you want to delete this review round? This cannot be undone.`),
      )
    ) {
      // we should delete original review with all sub reviews
      await deleteReview(review.originalReview);
      goBack();
    }
  };

  return (
    <DropdownButton
      maxWidth={526}
      popoverRef={popoverRef}
      content={
        <ReviewOptions
          sendNominatePeersReminder={sendNominatePeersReminder}
          sendSelfReviewReminder={sendSelfReviewReminder}
          sendPeerReviewReminder={sendPeerReviewReminder}
          sendCoachReviewReminder={sendCoachReviewReminder}
          sendDigitalSignReminder={onSendDigitalSignReminder}
          toggleDisableReview={toggleDisableReview}
          toggleLockReview={toggleLockReview}
          downloadPDF={handleExport}
          updateReview={openUpdateReviewFlow}
          deleteReview={() => {
            closeOptionsModal();
            deleteThisReview();
          }}
          updateCoaches={() => {
            closeOptionsModal();
            openCoachesModal();
          }}
          updateConversationCoaches={() => {
            closeOptionsModal();
            setDefaultConversationCoaches();
            openConversationCoachModal();
          }}
          review={review}
        />
      }
    >
      <Button
        label={
          <>
            {i18n._(t`Options`)}
            <ExpandMoreIcon fill="#fff" />
          </>
        }
      />
    </DropdownButton>
  );
};

export default HeadingDropdown;
