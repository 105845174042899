import React, { FunctionComponent } from 'react';

import { KPI_TYPES } from '@learned/constants';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import ArrowIcon from '~/components/Icons/Arrow';
import Avatar from '~/components/UI/Avatar';

import { COLOR_SET } from '~/styles';

import { IListItem } from '../types';

const TableRow = styled.tr`
  height: 64px;
  border-bottom: 1px solid #ecf1ff;

  & td {
    font-size: 14px;
    padding: 12px 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${COLOR_SET.TOTAL_BLACK};

    & .icon {
      margin-left: 10px;
      color: #29ccab;
    }

    & .rotate {
      rotate: 180deg;
      color: #ff5252;
    }

    &.isNull {
      color: #dcdcdc;
    }
  }
`;

const UserInfo = styled.td`
  font-size: 14px;
  cursor: pointer;
  color: ${COLOR_SET.TOTAL_BLACK};

  display: flex;
  align-items: center;
  & .name {
    margin-left: 20px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TippyName = styled.div`
  word-wrap: break-word;
  white-space: pre-wrap;
`;

interface IRow {
  item: IListItem;
  isSecondConversationVisible?: boolean;
}

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const Row: FunctionComponent<IRow> = ({ item, isSecondConversationVisible }) => {
  const name = item.user?.firstName
    ? `${item.user?.firstName} ${item.user?.lastName ?? ''}`
    : `${item.user.email}`;
  return (
    <TableRow>
      <UserInfo>
        {/* @ts-ignore */}
        <Avatar borderRadius="3px" showTooltip size={32} userId={item.user?.id} />
        <Tippy
          trigger="mouseenter"
          theme="light"
          popperOptions={tippyOpts}
          content={<TippyName>{name}</TippyName>}
        >
          <span className="name">{name}</span>
        </Tippy>
      </UserInfo>
      <td>{item?.name}</td>
      {Object.values(item.kpis).map(({ id, value, type }) => (
        <td key={id} className={value?.[0] ? '' : 'isNull'}>
          {value?.[0] || '-'}
          {value?.[0] && type === KPI_TYPES.SKILLS ? '%' : ''}
        </td>
      ))}
      {isSecondConversationVisible && (
        <>
          <td>{item?.secondReview?.name || '-'}</td>
          {Object.values(item.kpis).map(({ id, value, type }) => (
            <td key={`second-${id}`} className={value?.[1] ? '' : 'isNull'}>
              {value?.[1] ? `${value?.[1]}${type === KPI_TYPES.SKILLS ? '%' : ''}` : '-'}
              {value?.[1] &&
                (value?.[0] && value?.[0] === value?.[1] ? (
                  <></>
                ) : (
                  <>
                    {(value?.[0] && value?.[0] < value?.[1]) || !value?.[0] ? (
                      <ArrowIcon size={8} className="icon" />
                    ) : (
                      <ArrowIcon size={8} className="icon rotate" />
                    )}
                  </>
                ))}
            </td>
          ))}
        </>
      )}
    </TableRow>
  );
};

export { Row };
