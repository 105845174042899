import React, { useEffect, useState, MouseEventHandler } from 'react';

import {
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
  ENGAGEMENT_REPORT_CHART_TYPES,
  REPORT_TYPES,
  ROLES,
} from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui, withI18n } from '@lingui/react';
import qs from 'qs';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { Loader } from '~/components/Buttons/components/Loader';
import { DropdownIconState } from '~/components/Buttons/types';
import { TEngagementData as TGraphData } from '~/components/Graphs/constants';
import { HeaderTabs } from '~/components/HeaderTabs';
import { ICONS, Icon } from '~/components/Icon';
import { Input } from '~/components/Input';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import BaseLayout from '~/layouts/BaseLayout';

import { EngagementAIInsights } from './AIInsights';
import ControlPanel from './ControlPanel';
import {
  HeaderCtr,
  HeaderPlaceHolder,
  Heading,
  TabBody,
  NewReportModal,
  ModalInner,
  ModalTitle,
  CrossButtonCtr,
  ModalBody,
  ModalFooter,
  HeadingMenuCtr,
  LoaderCtr,
} from './LayoutStyles';
import Custom from './tabs/Custom';
import { EmptyCell, NormalCell, NormalCellCtr, NotAvailableCtr } from './tabs/CustomStyles';
import Overtime from './tabs/Overtime';
import Total from './tabs/Total';

import { ColumnPosition, IColumnTable } from '~/@types/table';
import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useOutsideClick } from '~/hooks/useOutsideClick';
import { usePagination } from '~/hooks/usePagination';
import { getSelectedRole } from '~/selectors/baseGetters';
import * as reportsService from '~/services/reports';
import {
  TSortingOrder,
  getEngagementCharts,
  getTabDetails,
  TTabResponse,
  getEngagementDetails,
  getFacets,
  TTotalGraphData,
  TGetEngagementDetailsPayload,
  downloadMatrixReport,
  downloadChartReport,
} from '~/services/reports';
import { finishGetCustomReports, startGetCustomReports } from '~/store/customReports/actions';
import { COLORS } from '~/styles';
import history from '~/utils/history';
import { toFixed } from '~/utils/math';
import * as reportsUtil from '~/utils/reports';

import { isFirst } from '../common';
import { Cell } from '../Components/Cell';
import SaveAsMenu from '../Components/SaveAsMenu';
import {
  MENU_SIZE,
  MONTH_OPTIONS,
  PAGINATION_PRIMARY_OPTIONS,
  PRIMARY_OPTIONS,
  SECONDARY_OPTIONS,
  MEASURE_OPTIONS,
  TPaginationProp,
} from '../options';
import {
  EOptions,
  TData,
  TColumns,
  TDropDownValues,
  TDataStruct,
  TOptions,
  TEngagementData,
  EExportOptions,
  EAction,
  TUpdateFnProps,
} from '../types';

type TOptionData = {
  id: string;
  local: {
    name: string;
    description?: string;
  };
}[];

type TLocal = 'nl_NL' | 'en_GB';

type TForm = {
  newName: string;
};

type TViewAs = ROLES.ADMIN | ROLES.COACH | ROLES.USER;

const Field = styled(Input)``;
export const EngagementReportContext = React.createContext<{
  themesOptions: TOptions[];
  learnedThemesOptions: TOptions[];
  monthSelected: string;
  primaryRowSelected: TOptions | null;
  viewAs: TViewAs;
  reportConfiguration: TTabResponse | null;
}>({
  monthSelected: MONTH_OPTIONS[0].key,
  primaryRowSelected: null,
  themesOptions: [],
  learnedThemesOptions: [],
  reportConfiguration: null,
  viewAs: ROLES.USER,
});

const EngagementReportPage = () => {
  const { i18n } = useLingui();
  const languageState = useLanguageState();
  const selectedLanguage = (languageState.companyPrimaryLanguage.locale || 'en_GB') as TLocal;
  const [noDataText, setNoDataText] = useState(
    i18n._(t`There is no data available yet. Please try again later.`),
  );
  const [noRetry, setNoRetry] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveAsLoading, setSaveAsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState<ENGAGEMENT_REPORT_CHART_TYPES | null>(null);
  const [enabledOptions, setEnabledOptions] = useState<EOptions[]>([]);
  const [enabledOptionKeys, setEnabledOptionKeys] = useState<EOptions[]>([]);
  const [exportOptionArr, setExportOptionArr] = useState<EExportOptions[]>([]);
  const [enabledTabs, setEnabledTabs] = useState<ENGAGEMENT_REPORT_CHART_TYPES[]>([]);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showAIInsightsModal, setShowAIInsightsModal] = useState(false);
  const [showSaveDropdown, setShowSaveDropdown] = useState(false);
  const [tabsData, setTabsData] = useState<TTabResponse | null>(null);
  const reportTitle = `${tabsData?.name[selectedLanguage] || i18n._(t`Engagement`)}`;

  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm<TForm>({
    mode: 'all',
    defaultValues: {
      newName: `${reportTitle}_copy`,
    },
  });

  const dispatch = useDispatch();
  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowSaveModal(false);
    setShowAIInsightsModal(false);
  });
  const menuRef = useOutsideClick<HTMLDivElement>(() => {
    setShowSaveDropdown(false);
  });
  const TEMP_TABS = [
    {
      label: (i18n: I18n) => i18n._(t`Graph`),
      key: ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_MONTH,
    },
    {
      label: (i18n: I18n) => i18n._(t`Bar chart`),
      key: ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION,
    },
    {
      label: (i18n: I18n) => i18n._(t`Table`),
      key: ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT__PIVOT,
    },
  ];
  const TABS = TEMP_TABS.filter((tab) => enabledTabs.includes(tab.key));

  const [primaryOptions, setPrimaryOptions] = useState<TOptions[]>([...PRIMARY_OPTIONS]);
  const [secondaryOptions, setSecondaryOptions] = useState<TOptions[]>([...SECONDARY_OPTIONS]);
  const [measureOptions, setMeasureOptions] = useState<TOptions[]>([...MEASURE_OPTIONS]);

  const [teamsOptions, setTeamsOptions] = useState<TOptions[]>([]);
  const [themesOptions, setThemesOptions] = useState<TOptions[]>([]);
  const [learnedThemesOptions, setLearnedThemesOptions] = useState<TOptions[]>([]);
  const [surveysOptions, setSurveysOptions] = useState<TOptions[]>([]);

  const [primaryRowSelected, setPrimaryRowSelected] = useState<TOptions | null>(null);
  const [secondaryRowSelected, setSecondaryRowSelected] = useState<TOptions | null>(null);
  const [measureRowSelected, setMeasureRowSelected] = useState<TOptions | null>(null);

  const [isHeatmapSelected, setIsHeatmapSelected] = useState(true);
  const [companyAverageSelected, setCompanyAverageSelected] = useState(false);
  const [teamAverageSelected, setTeamAverageSelected] = useState(false);
  const [industryBenchMarkSelected, setIndustryBenchMarkSelected] = useState(false);

  const [monthSelected, setMonthSelected] = useState<string>(MONTH_OPTIONS[0].key);
  const [themesOptionSelected, setThemesOptionSelected] = useState<TOptions[]>([]);
  const [teamsOptionSelected, setTeamsOptionSelected] = useState<TOptions[]>([]);
  const [surveysOptionSelected, setSurveysOptionSelected] = useState<TOptions[]>([]);

  const [timePrimaryData, setTimePrimaryData] = useState<TGraphData>([]);
  const [timeSecondaryData, setTimeSecondaryData] = useState<TGraphData>([]);
  const [timeTurneryData, setTimeTurneryData] = useState<TGraphData>([]);
  const [totalGraphData, setTotalGraphData] = useState<TTotalGraphData[]>([]);

  const [isSecondaryColumn, setIsSecondaryColumn] = useState(false);
  const [engagementData, setEngagementData] = useState<TEngagementData[]>([]);
  const [columns, setColumns] = useState<IColumnTable<TEngagementData>[]>([]);
  const { pagination, changePagination } = usePagination(PAGINATION_PRIMARY_OPTIONS[0].id);
  const [totalCount, setTotalCount] = useState(0);
  const [customSortedBy, setCustomSortedBy] = useState('');
  const [totalSortedBy, setTotalsSortedBy] = useState<string>('');
  const [showExportMenu, setShowExportMenu] = useState(false);

  const [totalGraphItems, setTotalGraphItems] = useState(0);

  const params: Record<string, string | undefined> = useParams();
  const currentRole = useSelector(getSelectedRole);
  const location = useLocation();

  const [customSearch, setCustomSearch] = useState('');
  const debouncedCustomSearch = useDebounce(customSearch, 1000);

  const [totalSearch, setTotalSearch] = useState('');
  const debouncedTotalSearch = useDebounce(totalSearch, 1000);

  const [tabInit, setTabInit] = useState(true);

  const { addToast } = useToasts();
  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { team, survey } = query;

  const tempDtObj = {
    primary: primaryRowSelected,
    secondary: secondaryRowSelected,
    measure: measureRowSelected,
    includeBenchmark: industryBenchMarkSelected,
    includeCompanyAverage: companyAverageSelected,
    includeTeamAverage: teamAverageSelected,
    isHeatmapSelected,
    themesOptionSelected: [...themesOptionSelected],
    teamsOptionSelected: [...teamsOptionSelected],
    surveysOptionSelected: [...surveysOptionSelected],
    monthSelected,
    totalSortedBy,
    customSortedBy,
    customSearch: debouncedCustomSearch,
    totalSearch: debouncedTotalSearch,
    skip: pagination.skip,
    limit: pagination.limit,
  };

  const resetSkip = {
    index: 1,
    skip: 0,
    limit: pagination.limit,
  };

  const dimensionSelected = {
    primary: primaryRowSelected,
    secondary: secondaryRowSelected,
    measure: measureRowSelected,
  };

  const lengthOfThemes = themesOptionSelected.length > 0 ? 1 : 0;
  const lengthOfTeams = teamsOptionSelected.length > 0 ? 1 : 0;
  const lengthOfSurveys = surveysOptionSelected.length > 0 ? 1 : 0;
  const filterCount = lengthOfThemes + lengthOfTeams + lengthOfSurveys;

  const filterChangeHandler = (OptionArr: TOptions[], firstKey: string, secondKey?: string) => {
    const filteredOptions = OptionArr.filter(
      (option) => option.key !== firstKey && option.key !== secondKey,
    );
    return filteredOptions;
  };

  const onPrimaryRowsOptionChange = (value: string) => {
    const selectedOption = primaryOptions.find((option) => option.key === value);
    if (selectedOption) {
      setPrimaryRowSelected(selectedOption);
      setSecondaryRowSelected(null);
      setMeasureRowSelected(null);
      setSecondaryOptions(filterChangeHandler(SECONDARY_OPTIONS, selectedOption.key));
      setMeasureOptions(filterChangeHandler(MEASURE_OPTIONS, selectedOption.key));
      setCustomSortedBy('');
      setTotalsSortedBy('');
      changePagination({ ...resetSkip });
      updateDataOnChange({
        primary: selectedOption,
        secondary: null,
        measure: null,
        customSortedBy: '',
        totalSortedBy: '',
        ...resetSkip,
      });
    }
  };

  const onSecondaryRowsOptionChange = (value: string) => {
    const selectedOption = secondaryOptions.find((option) => option.key === value);
    if (selectedOption) {
      setSecondaryRowSelected(selectedOption);
      setPrimaryOptions(filterChangeHandler(PRIMARY_OPTIONS, selectedOption.key));
      setMeasureOptions(filterChangeHandler(MEASURE_OPTIONS, selectedOption.key));
      setCustomSortedBy('');
      setTotalsSortedBy('');
      changePagination({
        ...resetSkip,
      });
      updateDataOnChange({
        secondary: selectedOption,
        customSortedBy: '',
        totalSortedBy: '',
        ...resetSkip,
      });
    }
  };

  const onMeasureRowsOptionChange = (value: string) => {
    const selectedOption = measureOptions.find((option) => option.key === value);
    if (selectedOption) {
      setMeasureRowSelected(selectedOption);
      setSecondaryRowSelected(null);
      setPrimaryOptions(filterChangeHandler(PRIMARY_OPTIONS, selectedOption.key));
      setSecondaryOptions(
        filterChangeHandler(PRIMARY_OPTIONS, selectedOption.key, primaryRowSelected?.key),
      );
      setCustomSortedBy('');
      setTotalsSortedBy('');
      changePagination({
        ...resetSkip,
      });
      updateDataOnChange({
        secondary: null,
        measure: selectedOption,
        customSortedBy: '',
        totalSortedBy: '',
        ...resetSkip,
      });
    }
  };

  const handleOptionInit = (opArr: TOptionData) => {
    const opTempArr = opArr.map((item) => ({
      key: item.id,
      title: (_i18n: I18n) => item.local.name,
      description: (_i18n: I18n) => item.local.description,
    }));
    return opTempArr;
  };

  const prepareColumn = (
    participationData: TColumns[],
    isHavingSecondary: boolean,
    dt: TDataStruct,
  ): IColumnTable<TEngagementData>[] => {
    const tempColumns = participationData.map((item) => {
      if (item.id === 'primaryDimension' || item.id === 'secondaryDimension') {
        return {
          id: item.id,
          name: `${item.name}`,
          accessor: `${item.id}`,
          renderCell: (
            cell: TEngagementData,
            onClick: MouseEventHandler<HTMLDivElement> | undefined,
          ) => {
            if (item.id === 'primaryDimension' && !cell.showPrimary) {
              return null;
            }
            const secondaryColumnName =
              cell.secondaryName && cell.secondaryName.trim().length > 0 ? cell.secondaryName : '';
            const displayName = item.id === 'primaryDimension' ? cell.name : secondaryColumnName;
            return (
              <NormalCellCtr
                className="cell"
                clickable={!!onClick}
                onClick={() => {
                  /* @ts-ignore */
                  onClick ? onClick(cell) : {};
                }}
              >
                <Tooltip tooltip={displayName} size={TOOLTIP_SIZES.BIG}>
                  <NormalCell
                    mWidth={
                      isHavingSecondary
                        ? `${MENU_SIZE.LEFT_HALF_WIDTH}px`
                        : `${MENU_SIZE.LEFT_FULL_WIDTH}px`
                    }
                  >
                    {displayName}
                  </NormalCell>
                </Tooltip>
              </NormalCellCtr>
            );
          },
          isFixed: true,
          centerAlign: true,
          position: ColumnPosition.LEFT,
          maxWidth: item.id === 'primaryDimension' ? '200px' : '100px',
          minWidth: '40px',
          padding: '7px',
          showHeaderTooltip: true,
          sortBy: {
            asc: {
              key: `${item.id}__asc`,
              label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
            },
            desc: {
              key: `${item.id}__desc`,
              label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
            },
          },
        };
      }
      return {
        id: item.id,
        name: `${item.name}`,
        accessor: `${item.id}`,
        renderCell: (row: TEngagementData) => {
          const cellValue = row.cells.find((c) => c.measure === item.id);
          if (!cellValue?.value) {
            if (item.id === 'benchmark') {
              return (
                <Tooltip tooltip={i18n._(t`Not enough data available, please check back later`)}>
                  <EmptyCell>
                    <NotAvailableCtr>{i18n._(t`N/A`)}</NotAvailableCtr>
                  </EmptyCell>
                </Tooltip>
              );
            }
            return (
              <Tooltip tooltip={i18n._(t`Reporting threshold has not been reached`)}>
                <EmptyCell>
                  <Icon className="incognito" icon={ICONS.INCOGNITO} fill={COLORS.PLACEHOLDERS} />
                </EmptyCell>
              </Tooltip>
            );
          }
          return (
            <Cell value={cellValue?.value || 0} noColor={!dt.isHeatmapSelected}>
              {cellValue?.value}%
            </Cell>
          );
        },
        isFixed: item.id === 'average' || item.id === 'benchmark',
        position: ColumnPosition.RIGHT,
        maxWidth: '20px',
        minWidth: '20px',
        padding: '7px',
        centerAlign: true,
        showHeaderTooltip: true,
        ...(!isHavingSecondary && {
          sortBy: {
            asc: {
              key: `${item.id}__desc`,
              label: (i18n: I18n) => i18n._(t`High to low`),
            },
            desc: {
              key: `${item.id}__asc`,
              label: (i18n: I18n) => i18n._(t`Low to high`),
            },
          },
        }),
      };
    });
    return tempColumns;
  };

  const getNoneUnselected = (val: string) => {
    if (val === 'primary_none' || val === 'secondary_none' || val === 'measure_none') {
      return '';
    }
    return val;
  };

  const getViewAs = (): TViewAs => {
    let viewAs = currentRole as TViewAs;
    if (
      location.pathname ===
      routes.REPORTS_COACH_TEAMS_ENGAGEMENT.build(
        // @ts-ignore
        { role: ROLES.USER },
      )
    ) {
      viewAs = ROLES.COACH;
    }
    return viewAs;
  };

  const VIEW_ROLE = getViewAs();

  const getTabCustomTabData = (reportName: string) => {
    const tempTabs = tabsData?.tabs.map((tab) => {
      if (tab.key === currentTab) {
        return {
          ...tab,
          ...(tab.primaryDimension && {
            primaryDimension: {
              ...tab.primaryDimension,
              value: primaryRowSelected?.key,
            },
          }),
          ...(tab.secondaryDimension && {
            secondaryDimension: {
              ...tab.secondaryDimension,
              value: secondaryRowSelected?.key,
            },
          }),
          ...(tab.measure && {
            measure: {
              ...tab.measure,
              value: measureRowSelected?.key,
            },
          }),
          filters: {
            ...(tab.filters.timeFrame && {
              timeFrame: {
                ...tab.filters.timeFrame,
                value: monthSelected,
              },
            }),
            ...(tab.filters.themes && {
              themes: {
                ...tab.filters.themes,
                value: [...themesOptionSelected.map((item) => item.key)],
              },
            }),
            ...(tab.filters.teams && {
              teams: {
                ...tab.filters.teams,
                value: [...teamsOptionSelected.map((item) => item.key)],
              },
            }),
            ...(tab.filters.surveys && {
              surveys: {
                ...tab.filters.surveys,
                value: [...surveysOptionSelected.map((item) => item.key)],
              },
            }),
          },
          options: {
            ...(tab.options.isHeatmapColored && {
              isHeatmapColored: {
                ...tab.options.isHeatmapColored,
                value: isHeatmapSelected,
              },
            }),
            ...(tab.options.includeCompanyAverage && {
              includeCompanyAverage: {
                ...tab.options.includeCompanyAverage,
                value: companyAverageSelected,
              },
            }),
            ...(tab.options.includeBenchmark && {
              includeBenchmark: {
                ...tab.options.includeBenchmark,
                value: industryBenchMarkSelected,
              },
            }),
            ...(tab.options.exportOptions && {
              exportOptions: tab.options.exportOptions,
            }),
          },
        };
      }
      return tab;
    });
    const payload = {
      ...tabsData,
      name: reportName,
      selectedTab: currentTab,
      sourceReport: tabsData?.id,
      tabs: [...(tempTabs || [])],
    };
    return payload;
  };

  const onCreateNewCustomReport = async () => {
    try {
      setSaveAsLoading(true);
      const reportName = watch('newName');
      const payload = getTabCustomTabData(reportName);
      if (tabsData?.id) {
        dispatch(startGetCustomReports());
        // TODO: fix the type
        await reportsService.createCustomReport(payload as unknown as TGetEngagementDetailsPayload);
        const { data } = await reportsService.getCustomReports();
        dispatch(finishGetCustomReports(data.reports));
        addToast({
          title: i18n._(t`Created Successfully`),
          subtitle: i18n._(t`Your report has been created.`),
          type: TOAST_TYPES.INFO,
        });
      }
    } catch (_error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    } finally {
      setShowSaveModal(false);
      setSaveAsLoading(false);
    }
  };

  const onUpdateCustomReport = async () => {
    try {
      setSaveLoading(true);
      const payload = getTabCustomTabData(reportTitle);
      if (tabsData?.id) {
        dispatch(startGetCustomReports());
        // TODO: fix the type
        await reportsService.updateCustomReport(
          payload as unknown as TGetEngagementDetailsPayload,
          tabsData?.id,
        );
        const { data } = await reportsService.getCustomReports();
        dispatch(finishGetCustomReports(data.reports));
        addToast({
          title: i18n._(t`Updated Successfully`),
          subtitle: i18n._(t`Your report has been updated.`),
          type: TOAST_TYPES.INFO,
        });
      }
    } catch (_error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    } finally {
      setSaveLoading(false);
    }
  };

  const onExportData = async (type: EExportOptions, from: ENGAGEMENT_REPORT_CHART_TYPES) => {
    addToast({
      title: type === EExportOptions.csv ? i18n._(t`Exporting CSV`) : i18n._(t`Exporting PDF`),
      subtitle:
        type === EExportOptions.csv
          ? i18n._(
              t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
            )
          : i18n._(
              t`Your PDF is being downloaded. This can take some time. It will download when it is ready.`,
            ),
      type: TOAST_TYPES.INFO,
    });
    if (from === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT__PIVOT) {
      // Custom => matrix
      updateDataOnChange(null, type === EExportOptions.csv ? EAction.exportCsv : EAction.exportPdf);
    } else if (from === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_MONTH) {
      // Overtime => charts
      updateDataOnChange(null, type === EExportOptions.csv ? EAction.exportCsv : EAction.exportPdf);
    } else if (from === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION) {
      // Totals => charts
      updateDataOnChange(null, type === EExportOptions.csv ? EAction.exportCsv : EAction.exportPdf);
    }
  };

  const getHeatmapData = async (action: EAction, dt: TDataStruct) => {
    try {
      if (action === EAction.getData) {
        setIsLoading(true);
        setEngagementData([]);
        setColumns([]);
        setIsSecondaryColumn(false);
        setTotalCount(0);
      }

      const isBenchmarkEnabled =
        dt?.primary?.key === 'theme' &&
        (dt?.secondary?.key === 'secondary_none' ||
          dt?.secondary?.key === 'primary_none' ||
          !dt?.secondary?.key) &&
        (dt?.measure?.key === 'month' ||
          dt?.measure?.key === 'quarter' ||
          dt?.measure?.key === 'year');

      const themeIds = dt.themesOptionSelected.map((item) => item.key);
      const teamIds = dt.teamsOptionSelected.map((item) => item.key);
      const surveysIds = dt.surveysOptionSelected.map((item) => item.key);
      const sorting = (dt.customSortedBy && dt.customSortedBy.split('__')) || [];
      const tempDataObj = {
        viewAs: getViewAs(),
        reportType: 'engagement',
        primaryDimension: getNoneUnselected(dt.primary?.key || ''),
        secondaryDimension: getNoneUnselected(dt.secondary?.key || ''),
        measure: getNoneUnselected(dt.measure?.key || ''),
        dateRange: reportsUtil.getDateForTimeFrame(dt.monthSelected || MONTH_OPTIONS[0].key),
        filters: {
          themes: themeIds,
          teams: teamIds,
          surveys: surveysIds,
          ...(dt.customSearch &&
            dt.customSearch.trim().length > 0 && { search: dt.customSearch || '' }),
        },
        sorting: {
          orderBy: sorting.length === 2 ? sorting[0] : '',
          order: sorting.length === 2 ? (sorting[1] as TSortingOrder) : ('' as TSortingOrder),
        },
        options: {
          includeCompanyAverage: !!dt.includeCompanyAverage,
          includeBenchmark: isBenchmarkEnabled && !!dt.includeBenchmark,
          ...(getViewAs() === ROLES.USER && { includeTeamAverage: !!dt.includeTeamAverage }),
        },
        pagination: {
          limit: dt.limit || PAGINATION_PRIMARY_OPTIONS[0].id,
          skip: dt.skip || 0,
        },
      };

      if (action === EAction.getData) {
        const engagementApiData = await getEngagementDetails(tempDataObj);
        const preData = engagementApiData.data as TData;
        if (!preData || !preData.rows || !preData.columns) {
          return;
        }
        const hasSecondaryColumn = preData.columns?.filter(
          (item) => item.id === 'secondaryDimension',
        );

        const engagementRows = preData.rows.map((item, i, arr) => ({
          ...item,
          showPrimary: hasSecondaryColumn.length === 0 ? true : isFirst(arr, i),
        }));

        const engagementDataArr = prepareColumn(preData.columns, hasSecondaryColumn.length > 0, dt);
        setIsSecondaryColumn(hasSecondaryColumn.length > 0);
        setEngagementData(engagementRows);
        setColumns(engagementDataArr);
        setTotalCount(preData.total || 0);
      } else {
        if (action === EAction.exportCsv) {
          await downloadMatrixReport(tempDataObj, 'csv', 'table');
        } else {
          await downloadMatrixReport(tempDataObj, 'pdf', 'table');
        }
      }
    } catch (_error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    } finally {
      if (action === EAction.getData) {
        setIsLoading(false);
      }
    }
  };

  const getChartData = async (
    dt: TDataStruct,
    type: ENGAGEMENT_REPORT_CHART_TYPES,
    action: EAction = EAction.getData,
  ) => {
    try {
      const themeIds = dt.themesOptionSelected.map((item) => item.key);
      const teamIds = dt.teamsOptionSelected.map((item) => item.key);
      const surveysIds = dt.surveysOptionSelected.map((item) => item.key);
      const sorting = dt.totalSortedBy?.split('__') || [];
      const tempPayloadObj = {
        viewAs: getViewAs(),
        reportType: REPORT_TYPES.ENGAGEMENT,
        chartType: type,
        ...(type === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION && {
          primaryDimension: (dt.primary?.key as ENGAGEMENT_REPORT_CHART_DIMENSIONS) || 'theme',
        }),
        ...(type === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION && {
          sorting: {
            orderBy: sorting.length === 2 ? sorting[0] : '',
            order: sorting.length === 2 ? (sorting[1] as TSortingOrder) : ('' as TSortingOrder),
          },
        }),
        filters: {
          themes: themeIds,
          teams: teamIds,
          surveys: surveysIds,
          ...(type === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION &&
            dt.totalSearch &&
            totalSearch.trim().length > 0 && { search: dt.totalSearch || '' }),
        },
        options: {
          includeCompanyAverage: !!dt.includeCompanyAverage,
          includeBenchmark: !!dt.includeBenchmark,
          ...(getViewAs() === ROLES.USER && { includeTeamAverage: !!dt.includeTeamAverage }),
        },
        dateRange: reportsUtil.getDateForTimeFrame(dt.monthSelected || MONTH_OPTIONS[0].key),
        pagination: {
          limit: dt.limit || PAGINATION_PRIMARY_OPTIONS[0].id,
          skip: dt.skip || 0,
        },
      };

      if (action === EAction.getData) {
        setIsLoading(true);
        setTotalGraphItems(0);
        const chartData = await getEngagementCharts(tempPayloadObj);
        if (type === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION) {
          setTotalGraphData(chartData?.data?.dimensionAverage || []);
          setTotalGraphItems(chartData?.data?.total || 0);
        } else if (type === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_MONTH) {
          const series = chartData?.data?.averageEngagement?.series || [];
          setTimePrimaryData(
            series.map((item) => ({
              key: item.month,
              value: toFixed(item.value || 0, 2),
              deviation: item.deviation || 0,
            })),
          );
          setTimeSecondaryData(
            series.map((item) => ({
              key: item.month,
              value: toFixed(item.benchmark || 0, 2),
              deviation: item.deviation || 0,
            })),
          );
          setTimeTurneryData(
            series.map((item) => ({
              key: item.month,
              value: toFixed(item.team || 0, 2),
              deviation: item.deviation || 0,
            })),
          );
        }
        setIsLoading(false);
      } else {
        const fileName =
          type === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_MONTH ? 'graph' : 'bar-chart';
        if (action === EAction.exportCsv) {
          await downloadChartReport(tempPayloadObj, 'csv', fileName);
        } else {
          await downloadChartReport(tempPayloadObj, 'pdf', fileName);
        }
      }
    } catch (_error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  const isCorrectDimensionSelected = (tData: TDataStruct) => {
    const pk = tData.primary?.key;
    const sk = tData.secondary?.key;
    const tk = tData.measure?.key;
    return !!(
      pk &&
      tk &&
      pk !== sk &&
      sk !== tk &&
      pk !== 'primary_none' &&
      pk !== 'secondary_none' &&
      tk !== 'measure_none'
    );
  };

  const updateDataOnChange = (value?: TUpdateFnProps | null, action: EAction = EAction.getData) => {
    setTabInit(true);
    if (currentTab === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_MONTH) {
      if (action === EAction.getData) {
        setTimePrimaryData([]);
        setTimeSecondaryData([]);
        setTimeTurneryData([]);
        setNoDataText(i18n._(t`There is no data available yet. Please try again later.`));
        setNoRetry(false);
      }
      getChartData({ ...tempDtObj, ...value }, currentTab, action);
    }
    if (currentTab === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION) {
      const tData = { ...tempDtObj, ...value };
      const pk = tData.primary?.key;
      if (pk && pk !== 'primary_none') {
        if (action === EAction.getData) {
          setNoDataText(i18n._(t`There is no data available yet. Please try again later.`));
          setNoRetry(false);
        }
        getChartData({ ...tempDtObj, ...value }, currentTab, action);
      } else {
        if (action === EAction.getData) {
          setNoDataText(i18n._(t`Please select a primary dimension.`));
          setTotalGraphData([]);
          setTotalGraphItems(0);
          setNoRetry(true);
        }
      }
    }
    if (currentTab === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT__PIVOT) {
      const tData = { ...tempDtObj, ...value };
      if (action === EAction.getData) {
        setEngagementData([]);
        setColumns([]);
      }
      if (isCorrectDimensionSelected(tData)) {
        setNoDataText(i18n._(t`There is no data available yet. Please try again later.`));
        setNoRetry(false);
        getHeatmapData(action, { ...tData });
      } else {
        let noDataText = i18n._(t`There is no data available yet. Please try again later.`);
        if (
          tData.primary?.key === 'primary_none' ||
          tData.primary?.key === 'secondary_none' ||
          !tData.primary?.key
        ) {
          noDataText = i18n._(t`Please select a primary dimension`);
          setNoRetry(true);
        } else if (tData.measure?.key === 'measure_none' || !tData.measure?.key) {
          noDataText = i18n._(t`Please select a measurement type`);
          setNoRetry(true);
        }
        setNoDataText(noDataText);
      }
    }
  };

  const optionChangeHandler = (type: EOptions, val?: TDropDownValues) => {
    if (type === EOptions.currentTab) {
      setCurrentTab(val as ENGAGEMENT_REPORT_CHART_TYPES);
      initData('tab', {
        teamsOptions,
        themesOptions,
        surveysOptions,
        tabsData,
        currentTab: val as ENGAGEMENT_REPORT_CHART_TYPES,
      });
      changePagination({
        index: 1,
        skip: 0,
        limit: PAGINATION_PRIMARY_OPTIONS[0].id,
      });
      setCustomSortedBy('');
      setTotalsSortedBy('');
      setTabInit(true);
      setCustomSearch('');
      setTotalSearch('');
    } else if (type === EOptions.primaryDimension) {
      if (val === null) {
        setPrimaryRowSelected(null);
        setSecondaryRowSelected(null);
        setMeasureRowSelected(null);
        setPrimaryOptions([...PRIMARY_OPTIONS]);
        setSecondaryOptions([...SECONDARY_OPTIONS]);
        setMeasureOptions([...MEASURE_OPTIONS]);
        return;
      }
      onPrimaryRowsOptionChange(val as string);
    } else if (type === EOptions.secondaryDimension) {
      onSecondaryRowsOptionChange(val as string);
    } else if (type === EOptions.measure) {
      onMeasureRowsOptionChange(val as string);
      // Filters -->
    } else if (type === EOptions.timeFrame) {
      setMonthSelected(val as string);
      updateDataOnChange({ monthSelected: val as string, ...resetSkip });
    } else if (type === EOptions.themes) {
      setThemesOptionSelected(val as TOptions[]);
      updateDataOnChange({ themesOptionSelected: val as TOptions[], ...resetSkip });
    } else if (type === EOptions.teams) {
      setTeamsOptionSelected(val as TOptions[]);
      updateDataOnChange({ teamsOptionSelected: val as TOptions[], ...resetSkip });
    } else if (type === EOptions.surveys) {
      setSurveysOptionSelected(val as TOptions[]);
      updateDataOnChange({ surveysOptionSelected: val as TOptions[], ...resetSkip });
      // Options -->
    } else if (type === EOptions.includeCompanyAverage) {
      const temp_companyAverageSelected = !companyAverageSelected;
      setCompanyAverageSelected(temp_companyAverageSelected);
      updateDataOnChange({ includeCompanyAverage: temp_companyAverageSelected, ...resetSkip });
    } else if (type === EOptions.includeBenchmark) {
      const temp_industryBenchMarkSelected = !industryBenchMarkSelected;
      setIndustryBenchMarkSelected(temp_industryBenchMarkSelected);
      updateDataOnChange({ includeBenchmark: temp_industryBenchMarkSelected, ...resetSkip });
    } else if (type === EOptions.isHeatmapColored) {
      const temp_heatmapSelected = !isHeatmapSelected;
      setIsHeatmapSelected(temp_heatmapSelected);
      updateDataOnChange({ isHeatmapSelected: temp_heatmapSelected, ...resetSkip });
    } else if (type === EOptions.reset) {
      setTeamsOptionSelected([]);
      setThemesOptionSelected([]);
      setSurveysOptionSelected([]);
      updateDataOnChange({
        themesOptionSelected: [],
        teamsOptionSelected: [],
        surveysOptionSelected: [],
        ...resetSkip,
      });
    } else if (type === EOptions.includeTeamAverage) {
      const temp_teamAverageSelected = !teamAverageSelected;
      setTeamAverageSelected(temp_teamAverageSelected);
      updateDataOnChange({ includeTeamAverage: temp_teamAverageSelected, ...resetSkip });
    } else if (type === EOptions.totalSortedBy) {
      setTotalsSortedBy(val as string);
      updateDataOnChange({ totalSortedBy: val as string, ...resetSkip });
    } else if (type === EOptions.customSortedBy) {
      setCustomSortedBy(val as string);
      updateDataOnChange({ customSortedBy: val as string, ...resetSkip });
    } else if (type === EOptions.totalSearch) {
      setTotalSearch(val as string);
      setTabInit(false);
    } else if (type === EOptions.customSearch) {
      setCustomSearch(val as string);
      setTabInit(false);
    } else if (type === EOptions.fetchDataAgain) {
      updateDataOnChange({ ...resetSkip });
    }
  };

  type TInitDtObj = {
    teamsOptions: TOptions[];
    themesOptions: TOptions[];
    surveysOptions: TOptions[];
    tabsData: TTabResponse | null;
    currentTab: ENGAGEMENT_REPORT_CHART_TYPES | null;
  } | null;
  const initData = async (type: 'init' | 'tab', tempDt: TInitDtObj = null) => {
    try {
      setIsLoading(true);
      let tempTabsData = null;
      let tempThemesOptions = [] as TOptions[];
      let tempTeamsOptions = [] as TOptions[];
      let tempSurveysOptions = [] as TOptions[];
      if (type === 'init') {
        const tabApiData = await getTabDetails(params.reportId ?? 'default', getViewAs());
        const [surveysApiData, themeApiData, teamApiData, learnedThemesApiData] = await Promise.all(
          [
            getFacets(['survey'], getViewAs()),
            getFacets(['theme'], getViewAs()),
            getFacets(['team'], getViewAs()),
            getFacets(['_learned_theme'], getViewAs()),
          ],
        );
        tempTeamsOptions = handleOptionInit(teamApiData?.data?.teams || []);
        tempThemesOptions = handleOptionInit(themeApiData?.data?.themes || []);
        tempSurveysOptions = handleOptionInit(surveysApiData?.data?.surveys || []);
        setLearnedThemesOptions(handleOptionInit(learnedThemesApiData?.data?.learnedThemes || []));

        tempTabsData = tabApiData.data;
      } else {
        tempTeamsOptions = tempDt?.teamsOptions || [];
        tempThemesOptions = tempDt?.themesOptions || [];
        tempSurveysOptions = tempDt?.surveysOptions || [];
        tempTabsData = tempDt?.tabsData || null;
      }

      let tempCurrentTab =
        tempTabsData?.selectedTab || ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_MONTH;
      if (type === 'tab') {
        tempCurrentTab = tempDt?.currentTab || ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_MONTH;
      }

      const selectedTabData = tempTabsData?.tabs.find((tab) => tab.key === tempCurrentTab);
      const selectedFilterData = selectedTabData?.filters || {};
      const selectedOptData = selectedTabData?.options || {};
      const enabledFilterArr = Object.entries(selectedFilterData).filter(
        ([_, value]) => value.isEnabled,
      );
      const enabledOptionArr = Object.entries(selectedOptData).filter(
        ([_, value]) => value?.isEnabled,
      );
      const enabledFilterKeys = enabledFilterArr.map(([key]) => key as EOptions);
      const enabledOptionKeys = enabledOptionArr.map(([key]) => key as EOptions);

      const initiatePrimary = PRIMARY_OPTIONS.filter((option) => option.key === 'theme');
      const initiateMeasure = MEASURE_OPTIONS.filter((option) => option.key === 'month');

      const tempPrimaryRowSelected =
        PRIMARY_OPTIONS.filter(
          (option) => option.key === selectedTabData?.primaryDimension?.value,
        )[0] ||
        initiatePrimary[0] ||
        null;

      const tempSecondaryRowSelected =
        SECONDARY_OPTIONS.filter(
          (option) => option.key === selectedTabData?.secondaryDimension?.value,
        )[0] || null;

      const tempMeasureRowSelected =
        MEASURE_OPTIONS.filter((option) => option.key === selectedTabData?.measure?.value)[0] ||
        initiateMeasure[0] ||
        null;

      const tempCompanyAverageSelected = selectedOptData?.includeCompanyAverage?.value || false;
      const tempIndustryBenchMarkSelected = selectedOptData?.includeBenchmark?.value || false;
      const tempIsHeatmapSelected = selectedOptData?.isHeatmapColored?.value || false;
      const tempTeamAverageSelected = selectedOptData?.includeTeamAverage?.value || false;

      const timeFrameEnabled = enabledFilterArr.filter(([key]) => key === 'timeFrame');
      const tempMonthSelected =
        timeFrameEnabled.length > 0 ? `${timeFrameEnabled[0][1].value}` : MONTH_OPTIONS[0].key;

      const tempThemesOptionSelected =
        tempThemesOptions.filter((item) => selectedFilterData?.themes?.value.includes(item.key)) ||
        [];
      const tempTeamsOptionSelected =
        tempTeamsOptions.filter((item) => {
          if (team) {
            return item.key === team;
          }
          return selectedFilterData?.teams?.value.includes(item.key);
        }) || [];

      const tempSurveysOptionSelected =
        tempSurveysOptions.filter((item) => {
          if (survey) {
            return item.key === survey;
          } else {
            return selectedFilterData?.surveys?.value.includes(item.key);
          }
        }) || [];

      setTabsData(tempTabsData);
      setCurrentTab(tempCurrentTab);
      setEnabledTabs(
        tempTabsData?.tabs.map((val) => val.key as ENGAGEMENT_REPORT_CHART_TYPES) || [],
      );
      setThemesOptions(tempThemesOptions);
      setSurveysOptions(tempSurveysOptions);
      setTeamsOptions(tempTeamsOptions);
      setEnabledOptions([
        ...enabledFilterKeys,
        ...enabledOptionKeys,
        ...(selectedTabData?.primaryDimension?.isEnabled ? [EOptions.primaryDimension] : []),
        ...(selectedTabData?.secondaryDimension?.isEnabled ? [EOptions.secondaryDimension] : []),
        ...(selectedTabData?.measure?.isEnabled ? [EOptions.measure] : []),
        ...(selectedOptData?.exportOptions?.isEnabled ? [EOptions.exportOptions] : []),
      ]);
      setEnabledOptionKeys([
        ...(Object.keys(selectedOptData) as EOptions[]),
        ...(Object.keys(selectedFilterData) as EOptions[]),
        ...(selectedTabData?.primaryDimension ? [EOptions.primaryDimension] : []),
        ...(selectedTabData?.secondaryDimension ? [EOptions.secondaryDimension] : []),
        ...(selectedTabData?.measure ? [EOptions.measure] : []),
        ...(selectedOptData?.exportOptions ? [EOptions.exportOptions] : []),
      ]);
      setPrimaryRowSelected(tempPrimaryRowSelected);
      setSecondaryRowSelected(tempSecondaryRowSelected);
      setMeasureRowSelected(tempMeasureRowSelected);
      setPrimaryOptions(filterChangeHandler(PRIMARY_OPTIONS, tempMeasureRowSelected?.key || ''));
      setMeasureOptions(filterChangeHandler(MEASURE_OPTIONS, tempPrimaryRowSelected?.key || ''));
      setSecondaryOptions(
        filterChangeHandler(
          SECONDARY_OPTIONS,
          tempPrimaryRowSelected?.key || '',
          tempMeasureRowSelected?.key || '',
        ),
      );
      setCompanyAverageSelected(tempCompanyAverageSelected);
      setTeamAverageSelected(tempTeamAverageSelected);
      setIndustryBenchMarkSelected(tempIndustryBenchMarkSelected);
      setIsHeatmapSelected(tempIsHeatmapSelected);
      setMonthSelected(tempMonthSelected);
      setThemesOptionSelected(tempThemesOptionSelected);
      setTeamsOptionSelected(tempTeamsOptionSelected);
      setSurveysOptionSelected(tempSurveysOptionSelected);
      setExportOptionArr((selectedOptData?.exportOptions?.value || []) as EExportOptions[]);

      const tempObj = {
        primary: tempPrimaryRowSelected,
        secondary: tempSecondaryRowSelected,
        measure: tempMeasureRowSelected,
        includeCompanyAverage: tempCompanyAverageSelected,
        includeTeamAverage: tempTeamAverageSelected,
        includeBenchmark: tempIndustryBenchMarkSelected,
        isHeatmapSelected: tempIsHeatmapSelected,
        monthSelected: tempMonthSelected,
        themesOptionSelected: tempThemesOptionSelected,
        teamsOptionSelected: tempTeamsOptionSelected,
        surveysOptionSelected: tempSurveysOptionSelected,
        totalSortedBy: '',
        customSortedBy: '',
        customSearch: '',
        totalSearch: '',
        skip: 0,
        limit: PAGINATION_PRIMARY_OPTIONS[0].id,
      };
      if (
        tempCurrentTab === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_MONTH ||
        tempCurrentTab === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION
      ) {
        getChartData(tempObj, tempCurrentTab); // overtime or total
      } else if (tempCurrentTab === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT__PIVOT) {
        getHeatmapData(EAction.getData, tempObj); // custom
      }
    } catch (_error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initData('init');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.reportId, team, survey]);
  // debouncedTotalSearch
  useEffect(() => {
    if (currentTab === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT__PIVOT && !tabInit) {
      updateDataOnChange({ customSearch: debouncedCustomSearch, ...resetSkip });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCustomSearch]);

  useEffect(() => {
    if (currentTab === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION && !tabInit) {
      updateDataOnChange({ totalSearch: debouncedTotalSearch, ...resetSkip });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTotalSearch]);

  const handlePagination = (
    paginationData: TPaginationProp,
    type: ENGAGEMENT_REPORT_CHART_TYPES,
  ) => {
    if (paginationData.limit !== pagination.limit) {
      changePagination({
        ...paginationData,
        skip: 0,
        index: 1,
      });
      if (type === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION) {
        updateDataOnChange({
          skip: paginationData?.skip || 0,
          limit: paginationData?.limit || PAGINATION_PRIMARY_OPTIONS[0].id,
        });
      } else if (type === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT__PIVOT) {
        updateDataOnChange({
          skip: 0,
          limit: paginationData?.limit || PAGINATION_PRIMARY_OPTIONS[0].id,
        });
      }
    } else {
      changePagination(paginationData);
      if (type === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION) {
        updateDataOnChange({
          skip: paginationData?.skip || 0,
          limit: paginationData?.limit || PAGINATION_PRIMARY_OPTIONS[0].id,
        });
      } else if (type === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT__PIVOT) {
        updateDataOnChange({
          skip: paginationData.skip,
          limit: paginationData.limit,
        });
      }
    }
  };

  const getTabBody = () => {
    if (currentTab === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_MONTH) {
      return (
        <Overtime
          isLoading={isLoading}
          onShow={() => setShowMenu((val) => !val)}
          noDataText={noDataText}
          companyAverageSelected={companyAverageSelected}
          teamAverageSelected={teamAverageSelected}
          timePrimaryData={timePrimaryData}
          timeSecondaryData={timeSecondaryData}
          timeTurneryData={timeTurneryData}
          onExportData={onExportData}
          enabledOptions={enabledOptions}
          enabledOptionKeys={enabledOptionKeys}
          exportOptionArr={exportOptionArr}
          industryBenchMarkSelected={industryBenchMarkSelected}
          optionChangeHandler={optionChangeHandler}
          viewRole={VIEW_ROLE}
          noRetry={noRetry}
        />
      );
    } else if (currentTab === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT__PIVOT) {
      return (
        <Custom
          pagination={pagination}
          handlePagination={handlePagination}
          onExportData={onExportData}
          isLoading={isLoading}
          isSecondaryColumn={isSecondaryColumn}
          engagementData={engagementData}
          columns={columns}
          totalCount={totalCount}
          selectedSortedOption={customSortedBy}
          showExportMenu={showExportMenu}
          noDataText={noDataText}
          companyAverageSelected={companyAverageSelected}
          industryBenchMarkSelected={industryBenchMarkSelected}
          onShow={() => setShowMenu((val) => !val)}
          optionChangeHandler={optionChangeHandler}
          searchValue={customSearch}
          setShowExportMenu={setShowExportMenu}
          enabledOptions={enabledOptions}
          enabledOptionKeys={enabledOptionKeys}
          exportOptionArr={exportOptionArr}
          noRetry={noRetry}
          dimensionSelected={dimensionSelected}
        />
      );
    } else if (currentTab === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION) {
      return (
        <Total
          viewRole={VIEW_ROLE}
          totalCount={totalGraphItems}
          handlePagination={handlePagination}
          pagination={pagination}
          isLoading={isLoading}
          noDataText={noDataText}
          data={totalGraphData}
          onShow={() => setShowMenu((val) => !val)}
          onExportData={onExportData}
          showExportMenu={showExportMenu}
          totalSearch={totalSearch}
          setShowExportMenu={setShowExportMenu}
          optionChangeHandler={optionChangeHandler}
          sortedBy={totalSortedBy}
          enabledOptions={enabledOptions}
          enabledOptionKeys={enabledOptionKeys}
          exportOptionArr={exportOptionArr}
          activatedDimensions={{
            includeTeamAverage: teamAverageSelected,
            includeCompanyAverage: companyAverageSelected,
          }}
          noRetry={noRetry}
        />
      );
    }
    return (
      <LoaderCtr>
        <Loader />
      </LoaderCtr>
    );
  };

  return (
    <EngagementReportContext.Provider
      value={{
        themesOptions,
        monthSelected,
        primaryRowSelected,
        viewAs: VIEW_ROLE,
        reportConfiguration: tabsData,
        learnedThemesOptions,
      }}
    >
      <BaseLayout maxWidth={'1270px'} noMarginTop>
        {currentTab ? (
          <HeaderCtr isFull={!showMenu}>
            <Heading>
              {reportTitle}
              <HeadingMenuCtr>
                {getViewAs() === ROLES.ADMIN && (
                  <>
                    {tabsData?.isDefault && (
                      <Button
                        icon={ICONS.LUCA}
                        label={i18n._(t`Summary`)}
                        variant={ButtonVariant.SECONDARY}
                        isLoading={saveAsLoading || saveLoading}
                        onClick={() => {
                          setShowAIInsightsModal(true);
                        }}
                        size={ButtonSize.MEDIUM}
                      />
                    )}
                    <Button
                      icon={ICONS.SAVE}
                      label={`${i18n._(t`Save view`)}`}
                      variant={ButtonVariant.SECONDARY}
                      isLoading={saveAsLoading || saveLoading}
                      onClick={() => {
                        setShowSaveDropdown(true);
                      }}
                      size={ButtonSize.MEDIUM}
                      dropDownIcon={
                        showSaveDropdown ? DropdownIconState.UP : DropdownIconState.DOWN
                      }
                    />
                  </>
                )}
                {showSaveDropdown && (
                  <SaveAsMenu
                    isDefault={!!tabsData?.isCustomizable}
                    onMenuChange={(type: 'save' | 'update') => {
                      if (type === 'save') {
                        setValue('newName', `${reportTitle}_copy`);
                        setShowSaveModal(true);
                      } else {
                        onUpdateCustomReport();
                      }
                      setShowSaveDropdown(false);
                    }}
                    reference={menuRef}
                  />
                )}
              </HeadingMenuCtr>
            </Heading>
            <HeaderTabs
              selectedTab={currentTab}
              tabs={TABS}
              isDisabled={isLoading}
              handleChangeTab={(key) => {
                optionChangeHandler(EOptions.currentTab, key);
              }}
              defaultBackground={COLORS.WHITE}
              borderRadius="6px"
              extraPadding="20px"
            />
          </HeaderCtr>
        ) : (
          <HeaderPlaceHolder />
        )}
        <TabBody isFull={!showMenu}>{getTabBody()}</TabBody>
        <ControlPanel
          isLoading={isLoading}
          showMenu={showMenu}
          onShow={(val) => setShowMenu(val)}
          optionChangeHandler={optionChangeHandler}
          industryBenchMarkSelected={industryBenchMarkSelected}
          companyAverageSelected={companyAverageSelected}
          teamAverageSelected={teamAverageSelected}
          isHeatmapSelected={isHeatmapSelected}
          primaryOptions={primaryOptions}
          dimensionSelected={dimensionSelected}
          secondaryOptions={secondaryOptions}
          measureOptions={measureOptions}
          themesOptions={themesOptions}
          surveysOptions={surveysOptions}
          surveysOptionSelected={surveysOptionSelected}
          themesOptionSelected={themesOptionSelected}
          teamsOptions={teamsOptions}
          teamsOptionSelected={teamsOptionSelected}
          filterCount={filterCount}
          monthSelected={monthSelected}
          enabledOptions={enabledOptions}
          enabledOptionKeys={enabledOptionKeys}
          currentTab={currentTab}
          viewRole={VIEW_ROLE}
        />
      </BaseLayout>
      {showSaveModal && (
        <NewReportModal>
          <ModalInner ref={baseRef}>
            <ModalTitle>
              <ModalTitle>{i18n._(t`Create custom report`)}</ModalTitle>
              <CrossButtonCtr onClick={() => setShowSaveModal(false)}>
                <Icon size={12} icon={ICONS.CLOSE} />
              </CrossButtonCtr>
            </ModalTitle>
            <ModalBody>
              <Field
                type="text"
                height="38px"
                label={i18n._(t`Name`)}
                placeholder={i18n._(t`Enter a valid Name`)}
                register={register('newName', {
                  required: { value: true, message: i18n._(t`Name is required`) },
                  maxLength: {
                    value: 50,
                    message: i18n._(t`Exceeds maximum length of 50 characters`),
                  },
                  minLength: {
                    value: 3,
                    message: i18n._(t`Requires a minimum of 3 characters`),
                  },
                  pattern: {
                    value: /^[^\s]+(?:$|.*[^\s]+$)/,
                    message: i18n._(t`Leading or trailing whitespace not allowed`),
                  },
                })}
                error={errors.newName?.message}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                label={`${i18n._(t`Cancel`)}`}
                variant={ButtonVariant.SECONDARY}
                onClick={() => setShowSaveModal(false)}
                size={ButtonSize.MEDIUM}
              />
              <Button
                label={`${i18n._(t`Save`)}`}
                variant={ButtonVariant.PRIMARY}
                isLoading={saveAsLoading}
                onClick={() => {
                  onCreateNewCustomReport();
                }}
                size={ButtonSize.MEDIUM}
              />
            </ModalFooter>
          </ModalInner>
        </NewReportModal>
      )}
      {showAIInsightsModal && tabsData && (
        <EngagementAIInsights
          tabsData={tabsData}
          onClose={setShowAIInsightsModal}
          baseRef={baseRef}
        />
      )}
    </EngagementReportContext.Provider>
  );
};

export default withI18n()(EngagementReportPage);
