import React from 'react';

import { detect } from 'detect-browser';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';

import BrowserNotSupported from '~/pages/BrowserNotSupported';
import MobileOutsideFeedbackNotAvailiable from '~/pages/Errors/MobileOutsideFeedbackNotAvailiable';
import MobileNotSupported from '~/pages/MobileNotSupported';

import { BROWSERS_SUPPORTED } from '~/constants';
import routes from '~/constants/routes';
import { getCurrentCompanyId } from '~/selectors/baseGetters';

const SupportCheck = ({ children }) => {
  const browser = detect();
  const location = useLocation();
  const currentCompanyId = useSelector(getCurrentCompanyId);

  let isBrowserSupported;

  try {
    isBrowserSupported = Object.values(BROWSERS_SUPPORTED).some(
      (browserSupported) =>
        browser &&
        (Array.isArray(browserSupported.key)
          ? browserSupported.key.includes(browser.name)
          : browser.name === browserSupported.key),
    );
  } catch (_e) {
    // Object.values is not supported
    isBrowserSupported = false;
  }

  // isMobile check mobile and tablet
  if (isMobile) {
    const isOutsideFeedbackPage = matchPath(location.pathname, {
      path: routes.REVIEW_GIVE_FEEDBACK_OUTSIDE.build({ companyId: currentCompanyId }),
    });
    if (isOutsideFeedbackPage) {
      return <MobileOutsideFeedbackNotAvailiable />;
    }

    return <MobileNotSupported />;
  }

  return isBrowserSupported ? children : <BrowserNotSupported />;
};

export default SupportCheck;
