import React from 'react';

import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';

import CheckedIcon from '~/assets/checked-icon-grey.svg';
import CloseIcon from '~/assets/close-icn.svg';

import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE, COLORS } from '~/styles';

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 7px 7px 0;
  padding: 4px 8px;
  border-radius: 3px;
  background: ${(props) => (props.$isActive ? COLORS.HOVER_ACTIVE : COLORS.BG_PAGE)};
  cursor: pointer;
`;

const ItemName = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: center;
  color: ${(props) => (props.$isActive ? COLOR_PALETTE.DARK_GRAY : COLOR_PALETTE.GRAY_MIDDLE)};
  margin-right: 8px;
`;

const IconWrapper = styled.div`
  width: 12px;
`;

const Toggler = ({ item, onClick, isDisabled = false, isTooltipDisabled = false }) => {
  const $isHover = useBoolState();
  const { i18n } = useLingui();
  const isActive = $isHover.value || item.isEnabled;

  return (
    <Tooltip tooltip={item.tooltip} maxWidth={272} disabled={isTooltipDisabled}>
      <ItemWrapper
        key={item.key}
        $isActive={isActive}
        onMouseEnter={isDisabled ? undefined : $isHover.on}
        onMouseLeave={isDisabled ? undefined : $isHover.off}
        onClick={isDisabled ? undefined : () => onClick(item.key, !item.isEnabled)}
      >
        <ItemName $isActive={isActive}>
          {typeof item.label === 'string' ? item.label : item.label(i18n)}
        </ItemName>
        <IconWrapper>
          {isActive && (
            <SvgIcon
              url={
                item.isEnabled
                  ? $isHover.value
                    ? CloseIcon
                    : CheckedIcon
                  : $isHover.value
                  ? CheckedIcon
                  : CloseIcon
              }
              width="12px"
              height="12px"
            />
          )}
        </IconWrapper>
      </ItemWrapper>
    </Tooltip>
  );
};

export default Toggler;
