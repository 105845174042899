import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import getLang from '~/selectors/getLang';

const SkillName = ({ skill = {} }) => {
  const locale = useSelector(getLang);

  let name = skill.name || '';
  if (locale === 'nl' && skill.nameNL && skill.nameNL !== '') {
    name = skill.nameNL;
  }
  return name;
};

SkillName.propTypes = {
  skill: PropTypes.object,
};

export default SkillName;
