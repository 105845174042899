import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import JobProfilesToggleModal from '~/components/Modals/JobProfilesToggleModal';
import Tooltip from '~/components/Tooltip';

import ProfileBox from './ProfileBox';

import useBoolState from '~/hooks/useBoolState';
import * as currentReviewActions from '~/store/currentReview/actions';
import { COLORS } from '~/styles';

const List = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
`;

const Error = styled.div`
  color: ${COLORS.ERROR};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ReadOnly = styled.div``;

const ProfileList = ({ user, review, isError, isReadOnly }) => {
  const $isRoleModal = useBoolState(false);
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const items = user.jobProfiles;
  const itemsSelected = get(review, `jobProfilesPerUser.${user.id}`, []);

  const onToggleJobProfile = (userId, jobProfileId, value) => {
    const update = review.jobProfilesPerUser || {};
    let updatePerUser = update[userId] || [];
    if (value) {
      updatePerUser.push(jobProfileId);
    } else {
      updatePerUser = updatePerUser.filter((id) => id !== jobProfileId);
    }

    update[userId] = updatePerUser;

    dispatch(
      currentReviewActions.updateCurrentReview({
        jobProfilesPerUser: update,
      }),
    );
  };

  if (isReadOnly) {
    return (
      <Row>
        <ReadOnly>{i18n._(t`You can view enabled role in the review overview page`)}</ReadOnly>
      </Row>
    );
  }

  return (
    <List onClick={$isRoleModal.on}>
      {!isEmpty(items) ? (
        user.jobProfiles.map((p) => (
          <ProfileBox
            key={p.id}
            name={p.name}
            error={(p.skills || []).length === 0}
            isEnabled={itemsSelected.includes(p.id)}
            errorNotSelected={isError && isEmpty(itemsSelected)}
          />
        ))
      ) : (
        <Tooltip
          maxWidth="300px"
          tooltip={
            <Trans>
              This participant does not have a role profile. Each participant requires to have a
              role profile with skills in order to create this review. Save this review and go to
              settings - roles in order to add this participant to a role.
            </Trans>
          }
        >
          <Error>
            <Trans>No role profile</Trans>
          </Error>
        </Tooltip>
      )}
      {$isRoleModal.value && (
        <JobProfilesToggleModal
          items={items}
          itemsSelected={itemsSelected}
          onToggle={(jobProfile, value) => onToggleJobProfile(user.id, jobProfile, value)}
          onClose={$isRoleModal.off}
        />
      )}
    </List>
  );
};

ProfileList.PropTypes = {
  user: PropTypes.object,
  review: PropTypes.object,
  isError: PropTypes.bool,
};

export default React.memo(ProfileList);
