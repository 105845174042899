import React, { memo } from 'react';

import { SIGNATURE_STATUSES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getSignatureStatus } from '~/components/NewReviewReport/sections/DigitallySign/helpers';
import { TemplateCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';
import SvgIcon from '~/components/SvgIcon';
import { Header3, Header6 } from '~/components/UI/Typographics/headers';
import LockedHint from '~/pages/ReviewUserPage/components/LockedHint';

import TasksTimelineDateItem from './TasksTimelineDateItem';
import UserDigitallySignItem from './UserDigitallySignItem';
import UserNominatePeerItem from './UserNominatePeerItem';
import UserPlanConversationItem from './UserPlanConversationItem';
import UserRequestItem from './UserRequestItem';
import UserSelfReviewItem from './UserSelfReviewItem';
import ViewReportItem from './ViewReportItem';

import TodoListIcon from '~/assets/to-do-list.svg';
import toggleCloseIcon from '~/assets/toggle-close.svg';
import toggleOpenIcon from '~/assets/toggle-open.svg';

import { REVIEW_STAGES, ROLES, REQUEST_STATUSES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE, COLORS, COLOR_SET } from '~/styles';
import { checkHasReviewCompleteLogic } from '~/utils/checkHasReviewCompleteLogic';
import { isTaskStartDateInThePast } from '~/utils/isTaskStartDateInThePast';

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColoredBlock = styled.div`
  background-image: linear-gradient(64deg, #e1d9f8 31%, #f6f3ff 79%);
  width: 58px;
  min-width: 58px;
  height: 72px;
  border-top-left-radius: 3px;
  ${(props) => !props.$open && 'border-bottom-left-radius: 3px;'}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 24px 12px 14px;
  width: 100%;
  align-items: center;
`;

const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  outline: none;
  cursor: pointer;
`;

const HeaderText = styled.div`
  ${Header3} {
    margin: 0;
    line-height: normal;
  }
  ${Header6} {
    color: ${COLORS.TEXT_SECONDARY};
    margin: 0;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.51;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  flex: 1;
`;

const TimelineRow = styled.div`
  display: flex;
  align-items: center;
`;

const TimelineWrapper = styled.div`
  position: relative;
  margin-left: 24px;
  margin-top: 12px;
  margin-bottom: 18px;
`;

const TIMELINE_WIDTH = 1;
const TIMELINE_CIRCLE_TOP = 24;
const TIMELINE_CIRCLE_BOTTOM = 64 - TIMELINE_CIRCLE_TOP; // 72 row height

const Timeline = styled.div`
  position: absolute;
  width: ${TIMELINE_WIDTH}px;
  background-color: ${COLOR_PALETTE.GRAY_MIDDLE};
  top: ${TIMELINE_CIRCLE_TOP}px;
  bottom: ${TIMELINE_CIRCLE_BOTTOM}px;
  left: 12px;
`;

const TodoTabWrapper = styled.div``;

const TodoSection = ({
  review,
  currentRole,
  currentUserId,
  requestsCoaches,
  requestsPeers,
  requestsIncoming,
  giveFeedback,
  openPlanEvalModal,
  onSignStart,
  userName,
  users,
  goToReport,
  openNominatePeersModal,
  isCoach,
}) => {
  // order of items: nominate, self, peer reviews, add as talking point, coach reviews
  const { i18n } = useLingui();
  const $isOpen = useBoolState();
  const isLockedNominate = get(review, `lockedStages[${REVIEW_STAGES.NOMINATE}]`, false);
  const isLockedFeedback = get(review, `lockedStages[${REVIEW_STAGES.FEEDBACK}]`, false);
  const isLockedSignatures = !isEmpty(get(review, 'signatures'));
  const isLocked = isLockedFeedback || isLockedSignatures;
  const isUserOwner = currentRole === ROLES.USER && review.createdFor === currentUserId;

  // complete task logic
  const createdDate = get(review, 'metaOriginal.createdDate');
  const isDigitalSign = review.digitalSign;
  const isCompleteTask = checkHasReviewCompleteLogic(isDigitalSign, createdDate);

  const hintLockedSignatures = i18n._(
    t`This review report has been signed by (some of) the conversation participants. As a result your review cant no longer be created/updated.`,
  );
  const hintLockedNominate = i18n._(
    t`It is no longer possible to nominate peers or update your nominees. Contact your HR manager for support.`,
  );
  const hintLockedFeedback = i18n._(
    t`It is no longer possible to give or update feedback. Contact your HR manager for support.`,
  );
  const hintLockedSetDate = i18n._(
    t`It is no longer possible to set date and time. Contact your HR manager for support.`,
  );
  const hintTaskAvailability = (startDate) => {
    const date = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    return i18n._(t`This task becomes available at ${date}`);
  };

  const getUserName = (userId) => {
    return users[userId] ? users[userId]?.firstName || users[userId]?.email || '' : '';
  };

  return (
    <TemplateCardWithFooter>
      <CardContent>
        <HeaderSection onClick={() => ($isOpen.value ? $isOpen.off() : $isOpen.on())}>
          <ColoredBlock $open={$isOpen.value}>
            <SvgIcon
              url={TodoListIcon}
              width={'22px'}
              height={'22px'}
              isDefaultColor
              defaultColor={COLOR_SET.MIDNICHT_BLUE}
            />
          </ColoredBlock>
          <HeaderContent>
            <HeaderText>
              <Header3>
                <Trans>Tasks</Trans>
              </Header3>
              <HeaderTitle>
                <Header6>
                  <Trans>An overview of all tasks for this conversation</Trans>
                </Header6>
              </HeaderTitle>
            </HeaderText>
            <div>
              <SvgIcon
                width="24px"
                height="24px"
                url={$isOpen.value ? toggleOpenIcon : toggleCloseIcon}
              />
            </div>
          </HeaderContent>
        </HeaderSection>
        {$isOpen.value && (
          <TodoTabWrapper>
            <TimelineWrapper>
              {!isEmpty(review) && review.isUsersReview && (
                <TimelineRow>
                  <TasksTimelineDateItem
                    isDone={!isEmpty(requestsPeers)}
                    itemDate={review.startDateNominate}
                  />
                  <UserNominatePeerItem
                    review={review}
                    currentUserId={currentUserId}
                    requestsPeers={requestsPeers}
                    isCoach={isCoach}
                    isDisabled={!isUserOwner}
                    isLocked={isLockedNominate || isLocked}
                    openNominatePeersModal={openNominatePeersModal}
                    lockedHint={
                      <LockedHint
                        tooltip={isLockedNominate ? hintLockedNominate : hintLockedSignatures}
                      />
                    }
                    isActionDisabled={!isTaskStartDateInThePast(review?.startDateNominate)}
                    actionTooltip={
                      !isTaskStartDateInThePast(review?.startDateNominate) &&
                      hintTaskAvailability(review?.startDateNominate)
                    }
                  />
                </TimelineRow>
              )}

              {!isEmpty(review) && review.isSelfReview && (
                <TimelineRow>
                  <TasksTimelineDateItem
                    isDone={review.isSelfFeedbackProvided}
                    itemDate={review.startDateSelfReview}
                  />
                  <UserSelfReviewItem
                    currentRole={currentRole}
                    review={review}
                    isDisabled={!isUserOwner}
                    isLocked={isLocked}
                    lockedHint={
                      <LockedHint
                        tooltip={isLockedFeedback ? hintLockedFeedback : hintLockedSignatures}
                      />
                    }
                    isActionDisabled={!isTaskStartDateInThePast(review?.startDateSelfReview)}
                    actionTooltip={
                      !isTaskStartDateInThePast(review?.startDateSelfReview)
                        ? hintTaskAvailability(review?.startDateSelfReview)
                        : undefined
                    }
                  />
                </TimelineRow>
              )}

              {!isEmpty(requestsIncoming) &&
                requestsIncoming.map((request) => (
                  <TimelineRow key={request.id}>
                    <TasksTimelineDateItem
                      isDone={request.status === REQUEST_STATUSES.SHARED.key}
                      itemDate={review.startDateUsersRequest}
                    />
                    <UserRequestItem
                      request={request}
                      isDisabled={!(currentRole === ROLES.USER && request.toUser === currentUserId)}
                      isLocked={isLocked}
                      deadline={request.deadline}
                      isCoach={false}
                      userName={getUserName(request.fromUser)}
                      giveFeedback={() => giveFeedback(request)}
                      lockedHint={
                        <LockedHint
                          tooltip={isLockedFeedback ? hintLockedFeedback : hintLockedSignatures}
                        />
                      }
                      isActionDisabled={!isTaskStartDateInThePast(review?.startDateUsersRequest)}
                      actionTooltip={
                        !isTaskStartDateInThePast(review?.startDateUsersRequest) &&
                        hintTaskAvailability(review?.startDateUsersRequest)
                      }
                    />
                  </TimelineRow>
                ))}

              {!isEmpty(requestsCoaches) &&
                requestsCoaches.map((request) => (
                  <TimelineRow key={request.id}>
                    <TasksTimelineDateItem
                      isDone={request.status === REQUEST_STATUSES.SHARED.key}
                      itemDate={review.startDateCoachesRequest}
                    />
                    <UserRequestItem
                      request={request}
                      userName={userName}
                      isDisabled={
                        !(
                          [ROLES.USER, ROLES.COACH].includes(currentRole) &&
                          request.toUser === currentUserId
                        )
                      }
                      isLocked={isLocked}
                      deadline={request.deadline}
                      isCoach={true}
                      giveFeedback={() => giveFeedback(request)}
                      lockedHint={
                        <LockedHint
                          tooltip={isLockedFeedback ? hintLockedFeedback : hintLockedSignatures}
                        />
                      }
                      isActionDisabled={!isTaskStartDateInThePast(review?.startDateCoachesRequest)}
                      actionTooltip={
                        !isTaskStartDateInThePast(review?.startDateCoachesRequest) &&
                        hintTaskAvailability(review?.startDateCoachesRequest)
                      }
                    />
                  </TimelineRow>
                ))}

              {!isEmpty(review.conversationCoaches) && (
                <TimelineRow>
                  <TasksTimelineDateItem
                    isDone={review.conversationCoaches && review.dateOfConversationEnd}
                    itemDate={review.startDateOfPlanConversation}
                  />
                  <UserPlanConversationItem
                    key={'plan-conversation'}
                    coaches={review.conversationCoaches}
                    startDate={review.dateOfConversation}
                    endDate={review.dateOfConversationEnd}
                    isActionDisabled={
                      !isTaskStartDateInThePast(review?.startDateOfPlanConversation)
                    }
                    isDisabled={
                      currentRole === ROLES.ADMIN ||
                      ([ROLES.USER, ROLES.COACH].includes(currentRole) &&
                        !get(review, 'conversationCoaches', []).includes(currentUserId))
                    }
                    deadline={review.deadlineSelfReview}
                    isLocked={isLocked}
                    onAction={openPlanEvalModal}
                    actionTooltip={
                      !isTaskStartDateInThePast(review?.startDateOfPlanConversation) &&
                      hintTaskAvailability(review?.startDateOfPlanConversation)
                    }
                    lockedHint={
                      <LockedHint
                        tooltip={isLockedFeedback ? hintLockedSetDate : hintLockedSignatures}
                      />
                    }
                  />
                </TimelineRow>
              )}
              <TimelineRow>
                <TasksTimelineDateItem
                  isViewReport
                  isDone={review.dateOfConversationEnd}
                  itemDate={review.dateOfConversation}
                />
                <ViewReportItem review={review} goToReport={goToReport} />
              </TimelineRow>
              {!isEmpty(review) &&
                isCompleteTask &&
                [review.createdFor, ...get(review, 'conversationCoaches', [])].map(
                  (userId, index) => {
                    const isCurrentUser = currentUserId === userId;
                    const isSignUserOwner =
                      isCurrentUser && currentRole === ROLES.USER && review.createdFor === userId;
                    const isSignCoachOwner =
                      isCurrentUser &&
                      [ROLES.USER, ROLES.COACH].includes(currentRole) &&
                      get(review, 'conversationCoaches', []).includes(userId);
                    const signStatus = getSignatureStatus(index ? ROLES.COACH : ROLES.USER, {
                      signatures: review.signatures,
                      conversationCoaches: review.conversationCoaches,
                      userId,
                    });
                    return (
                      <TimelineRow key={userId}>
                        <TasksTimelineDateItem
                          isDone={signStatus === SIGNATURE_STATUSES.SIGNED}
                          itemDate={review.startDateToSign}
                        />
                        <UserDigitallySignItem
                          view={index ? ROLES.COACH : ROLES.USER}
                          userId={userId}
                          currentRole={currentRole}
                          review={review}
                          isDisabled={!isSignUserOwner && !isSignCoachOwner}
                          isLocked={isLockedFeedback}
                          onSignStart={onSignStart}
                          isUserOwner={isUserOwner}
                          userName={userName}
                          lockedHint={
                            <LockedHint
                              tooltip={i18n._(
                                t`It is no longer possible to sign review. Contact your HR manager for support.`,
                              )}
                            />
                          }
                          isActionDisabled={!isTaskStartDateInThePast(review?.startDateToSign)}
                          actionTooltip={
                            !isTaskStartDateInThePast(review?.startDateToSign) &&
                            hintTaskAvailability(review?.startDateToSign)
                          }
                        />
                      </TimelineRow>
                    );
                  },
                )}
              <Timeline />
            </TimelineWrapper>
          </TodoTabWrapper>
        )}
      </CardContent>
    </TemplateCardWithFooter>
  );
};

TodoSection.propTypes = {
  requestsCoaches: PropTypes.arrayOf(PropTypes.object),
  requestsPeers: PropTypes.arrayOf(PropTypes.object),
  requestsIncoming: PropTypes.arrayOf(PropTypes.object),
  giveFeedback: PropTypes.func,
  onReviewUpdated: PropTypes.func,
  openPlanEvalModal: PropTypes.func,
  onSignStart: PropTypes.func,
};

export default memo(TodoSection);
