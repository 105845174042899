import React, { useEffect, useState } from 'react';

import { QUESTION_TYPES, KPI_TYPES, KPI_NONE } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniqBy';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import Button from '~/components/Button';
import CreateQuestionModal from '~/components/CreateQuestionModal';
import NameCard from '~/components/SetupTools/NameCard';
import ToolBox from '~/components/SetupTools/ToolBox';
import ToolboxRow from '~/components/SetupTools/ToolboxRow';
import Switch from '~/components/Switch';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Tooltip from '~/components/Tooltip';
import ExternalInstructions from '~/components/UI/ExternalInstructions';
import SetupLayout from '~/layouts/SetupLayout';
import TemplatePreviewModal from '~/pages/ReviewTemplatePreview/components/TemplatePreviewModal';
import { validateTemplate } from '~/pages/ReviewTemplateSetup/helpers';

import AdjustRatingScaleModal from './components/AdjustRatingScaleModal';
import ConfirmModal from './components/ConfirmSubmitModal';
import Section from './components/Section';
import SignSection from './components/SignSection';
import useTemplateState from './reviewTemplateHook';

import { INSTRUCTIONS } from '~/constants/instructions';
import useBoolState from '~/hooks/useBoolState';
import { createSkill } from '~/services/skills';
import { COLOR_SET } from '~/styles';
import createQuestionName from '~/utils/createQuestionName';
import getInstructionUrl from '~/utils/getInstructionUrl';

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NoTemplatePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const SectionCol = styled.div`
  margin-bottom: 24px;
`;

const SettingText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_SET.TOTAL_BLACK};
`;

// structures
const createSkillQuestionStructure = (i18n, { skills } = {}) => ({
  type: QUESTION_TYPES.SKILL,
  name: createQuestionName({
    type: QUESTION_TYPES.SKILL,
    selectedSkills: skills,
    i18n,
  }),
  skills: skills.map((s) => s.id),
  isSkippable: true,
  isCommentObligated: true,
});

function ReviewTemplateSetup({
  match: {
    params: { reviewTemplateId },
  },
}) {
  const location = useLocation();
  const history = useHistory();
  const { isSuperAdmin } = location.state || false;
  const { i18n } = useLingui();
  const $isCreateQuestionModal = useBoolState(false);
  const $isRateScaleModal = useBoolState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const $template = useTemplateState(reviewTemplateId, isSuperAdmin);
  const skillLabels = useSelector((state) => state.companySettings.skillLabels);
  const { addToast } = useToasts();
  const $isShowPreviewModal = useBoolState(false);
  const $isPreventingNavigation = useBoolState(true);
  const $isSaveDisabled = useBoolState(false);
  const $isConfirmModal = useBoolState(false);
  const isTemplate = !reviewTemplateId || $template.templateName;

  useEffect(() => {
    const setupBeforeUnloadListener = (ev) => {
      ev.preventDefault();
      ev.returnValue = ''; // for Chrome
    };
    // add warning on refresh page
    window.addEventListener('beforeunload', setupBeforeUnloadListener);

    return () => window.removeEventListener('beforeunload', setupBeforeUnloadListener);
    // eslint-disable-next-line
  }, []);

  const mainToolboxItems = [
    {
      label: i18n._(t`Section`),
      text: i18n._(t`Add a section to measure an additional kpi`),
      action: $template.createNewSection,
    },
  ].filter((i) => i);

  const isNoActiveSection = isEmpty($template.activeSectionId);
  const activeKpi = $template.kpis.find((kpi) => kpi.id === $template.activeSectionKpi);
  const isNoneKpi = activeKpi?.type === KPI_NONE.type && activeKpi?.id === KPI_NONE.id;
  const isSkillsKpi = activeKpi?.type === KPI_TYPES.SKILLS;

  const subToolboxItems = [
    {
      label: i18n._(t`Custom question`),
      text: i18n._(t`Formulate custom questions as input`),
      action: $template.addCustomQuestion,
      hoverText: i18n._(t`Not available for sections with KPI: skill coverage.`),
      disabled: isNoActiveSection || isSkillsKpi, // any kpi, except SKILLS
    },
    {
      label: i18n._(t`Role profile skills`),
      text: i18n._(t`Import the skills of the participants role profile(s)`),
      action: $template.addJobProfileQuestion,
      hoverText: i18n._(t`Available only for sections with KPI: skill coverage or none.`),
      // none or SKILLS kpi only
      // on once in template
      disabled:
        isNoActiveSection ||
        $template.isQuestionExist(QUESTION_TYPES.JOB_PROFILE) ||
        (!isSkillsKpi && !isNoneKpi),
    },
    !isSuperAdmin && {
      label: i18n._(t`Custom skills`),
      text: i18n._(t`Select skills manually as input for the evaluation`),
      hoverText: i18n._(t`Available only for sections with KPI: none.`),
      action: $isCreateQuestionModal.on,
      // only none kpi
      disabled: isNoActiveSection || $template.isQuestionExist(QUESTION_TYPES.SKILL) || !isNoneKpi,
    },
    {
      label: i18n._(t`Evaluate Business goals`),
      text: i18n._(t`Import the business goals of the participant`),
      action: $template.addEvalBussGoalQuestion,
      // any kpi, except SKILLS
      disabled:
        isNoActiveSection ||
        $template.isQuestionExist(QUESTION_TYPES.GOAL_BUSINESS_EVAL) ||
        isSkillsKpi,
    },
    {
      label: i18n._(t`Plan business goals`),
      text: i18n._(t`Ask to participant to plan new business goals`),
      action: $template.addPlanBussGoalQuestion,
      // any kpi, except SKILLS
      disabled:
        isNoActiveSection ||
        $template.isQuestionExist(QUESTION_TYPES.GOAL_BUSINESS_PLAN) ||
        isSkillsKpi,
    },
    {
      label: i18n._(t`Evaluate Learning goals `),
      text: i18n._(t`Import the Learning goals of the participant`),
      action: $template.addEvalLearnGoalQuestion,
      // any kpi, except SKILLS
      disabled:
        isNoActiveSection ||
        $template.isQuestionExist(QUESTION_TYPES.GOAL_LEARNING_EVAL) ||
        isSkillsKpi,
    },
    {
      label: i18n._(t`Plan Learning goals `),
      text: i18n._(t`Ask the participant to plan new learning goals`),
      action: $template.addPlanLearnGoalQuestion,
      // any kpi, except SKILLS
      disabled:
        isNoActiveSection ||
        $template.isQuestionExist(QUESTION_TYPES.GOAL_LEARNING_PLAN) ||
        isSkillsKpi,
    },
  ].filter((i) => i);

  const settingsItems = (
    <ToolboxRow>
      <Tooltip
        tooltip={i18n._(
          t`By enabling calibrate coaches have the option to share their input with other coaches and admin's without the participant having access to the input.`,
        )}
      >
        <SettingText>
          <Trans>Calibration</Trans>
        </SettingText>
      </Tooltip>
      <Switch onChange={$template.toggleIsCalibrate} checked={$template.isCalibrate} />
    </ToolboxRow>
  );

  const onSubmit = async () => {
    $isSaveDisabled.on();
    const {
      isValid,
      isFieldsFilled,
      isEmptySections,
      isSectionWithoutQuestions,
      isQuestionWithDuplicateLabels,
      isQuestionWithEmptyLabels,
      isSectionWithoutReviewType,
      isQuestionWithoutTitle,
    } = validateTemplate($template);

    if (!isValid) {
      $template.isShowErrors.on();

      const toasts = [];
      if (!isFieldsFilled) {
        toasts.push({
          title: i18n._(t`Not completed`),
          subtitle: i18n._(t`Please fill all required fields before save.`),
        });
      }

      if (isEmptySections) {
        toasts.push({
          title: i18n._(t`Not completed`),
          subtitle: i18n._(t`Please add min 1 section in template`),
        });
      }

      if (isSectionWithoutQuestions) {
        toasts.push({
          title: i18n._(t`Not completed`),
          subtitle: i18n._(t`Please add min 1 question to section`),
        });
      }

      if (isQuestionWithoutTitle) {
        toasts.push({
          title: i18n._(t`Not completed`),
          subtitle: i18n._(
            t`Please make sure you have types a question in all custom question boxes`,
          ),
        });
      }

      if (isSectionWithoutReviewType) {
        toasts.push({
          title: i18n._(t`Not completed`),
          subtitle: i18n._(t`Please make sure you have enabled min 1 self/peer/coach in section`),
        });
      }

      if (isQuestionWithDuplicateLabels) {
        toasts.push({
          title: i18n._(t`Not completed`),
          subtitle: i18n._(t`Skill labels cannot have the same name`),
        });
      }

      if (isQuestionWithEmptyLabels) {
        toasts.push({
          title: i18n._(t`Not completed`),
          subtitle: i18n._(t`Skill labels names cannot be empty`),
        });
      }

      toasts.forEach((toast) => {
        addToast({ ...toast, type: TOAST_TYPES.ERROR });
      });
      $isSaveDisabled.off();
      return false;
    } else {
      // turn off errors
      if ($template.isShowErrors.value) {
        $template.isShowErrors.off();
      }
      $isPreventingNavigation.off();
      !reviewTemplateId
        ? await $template.createReviewTemplate(isSuperAdmin)
        : await $template.updateTemplate(isSuperAdmin);
    }
    return true;
  };

  const handleDrag = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.type === 'section' && result.source.index !== result.destination.index) {
      $template.switchSections(result.source.index, result.destination.index);
    }
    if (result.type === 'question') {
      if (result.source.droppableId !== result.destination.droppableId) {
        // disallow move question between sections
        return;
      }
      $template.switchQuestions(
        { section: result.source.droppableId, index: result.source.index },
        { section: result.destination.droppableId, index: result.destination.index },
      );
    }
  };

  const openQuestionModal = (question, sectionId) => {
    $isCreateQuestionModal.on();
    setSelectedQuestion({
      sectionId,
      id: question.id,
      skills: question.skills,
    });
  };

  const openRateScaleModal = (sectionId) => {
    $isRateScaleModal.on();
    $template.updateActiveSectionId(sectionId);
  };

  // template can have only one section with types with KPI
  const existingSectionKpis = uniq($template.sections.map((section) => section.kpi));
  const availableSectionKpis = $template.kpis.filter(
    (kpi) =>
      // NONE sections - max amount 99
      // other sections types - only 1 could be
      (kpi.id === KPI_NONE.id &&
        existingSectionKpis.filter((kpiId) => kpiId === KPI_NONE.id).length < 100) ||
      !existingSectionKpis.includes(kpi.id),
  );
  const activeSection =
    $template.activeSectionId && $template.sections.find((s) => s.id === $template.activeSectionId);

  const goBack = () => {
    history.goBack();
  };

  const preview = async () => {
    $isShowPreviewModal.on();
  };

  return (
    <>
      <Prompt
        message={i18n._(
          t`Are you sure? If you dont save your template all your changes will be lost.`,
        )}
        when={$isPreventingNavigation.value}
      />
      <SetupLayout
        title={i18n._(t`Templates`)}
        onClose={goBack}
        width="1070px"
        loading={$template.isFetching}
        actions={
          <ActionsWrapper>
            <ExternalInstructions
              instructions={i18n._(t`How templates work`)}
              instructionsUrl={getInstructionUrl(INSTRUCTIONS.BUILDING_REVIEWS_TEMPLATES)}
            />
            <Button
              label={i18n._(t`Close`)}
              type="primary-border"
              width={83}
              marginLeft={12}
              onClick={goBack}
            />
            {isTemplate && (
              <>
                <Button label={i18n._(t`Preview`)} type="primary-border" onClick={preview} />
                <Button
                  label={i18n._(reviewTemplateId ? t`Update` : t`Save`)}
                  type="primary"
                  width={88}
                  onClick={!$isSaveDisabled.value ? $isConfirmModal.on : undefined}
                  disabled={$isSaveDisabled.value}
                />
              </>
            )}
          </ActionsWrapper>
        }
      >
        {isTemplate ? (
          <ToolBox
            splitToolbox={true}
            mainItems={mainToolboxItems}
            subSectionHeader={i18n._(t`Questions`)}
            subItems={subToolboxItems}
            settingsSectionHeader={i18n._(t`Settings`)}
            settingsItems={settingsItems}
          >
            <NameCard
              title={i18n._(t`Create conversation template`)}
              placeholder={i18n._(t`Example: Quarterly performance review`)}
              name={$template.templateName || ''}
              updateName={(name) => $template.setTemplate({ name })}
              updateDescription={(description) => $template.setTemplate({ description })}
              description={$template.templateDescription || ''}
              isShowErrors={$template.isShowErrors.value}
              descriptionTooltip={i18n._(
                t`The description is used as template for the introduction email. You can update this text while creating your review.`,
              )}
              emailBody={false}
              onClick={() => {
                $template.resetActiveSection();
              }}
            />
            <DragDropContext onDragEnd={handleDrag}>
              <Droppable type="section" droppableId="sections">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {$template.sections.map((section, idx) => (
                      <Draggable key={section.id} draggableId={section.id} index={idx}>
                        {(provided) => (
                          <SectionCol ref={provided.innerRef} {...provided.draggableProps}>
                            <Section
                              key={section.id}
                              $template={$template}
                              section={section}
                              dragProps={provided.dragHandleProps}
                              isActive={$template.activeSectionId === section.id}
                              activeQuestionId={
                                $template.activeSectionId === section.id
                                  ? $template.activeQuestionId
                                  : null
                              }
                              sectionIndex={idx + 1}
                              openQuestionModal={openQuestionModal}
                              openRateScaleModal={openRateScaleModal}
                              availableSectionKpis={availableSectionKpis.filter(
                                (kpi) => kpi.id !== section.kpi,
                              )}
                              skillLabels={skillLabels}
                            />
                          </SectionCol>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <SignSection
              digitalSign={$template.digitalSign}
              onToggle={$template.toggleSignSection}
              updateIsAgreeSeen={$template.setIsAgreeSeen}
              updateIsAgreeContent={$template.setIsAgreeContent}
            />
          </ToolBox>
        ) : (
          <NoTemplatePlaceholder>{i18n._(t`The template is not found`)}</NoTemplatePlaceholder>
        )}
      </SetupLayout>
      {$isCreateQuestionModal.value && (
        <CreateQuestionModal
          type={QUESTION_TYPES.SKILL}
          onClose={$isCreateQuestionModal.off}
          onSubmit={async ({ skills, question }) => {
            const mappedSkills = await Promise.all(
              skills.map(async (skill) => {
                if (!skill.isNew) {
                  return skill;
                }

                // If the skill is new, create it and its focus areas
                return await createSkill({
                  skill: {
                    definition: skill.definition,
                    name: skill.name,
                    source: skill.source,
                    levelsEnabled: skill.levelsEnabled,
                    categories: skill.categories,
                    aliases: skill.aliases,
                  },
                  focusAreas: skill.focusAreas,
                });
              }),
            );

            if (isEmpty(selectedQuestion)) {
              // create question
              $template.addSkillQuestion(
                createSkillQuestionStructure(i18n, { skills: mappedSkills }),
              );
              $isCreateQuestionModal.off();
            } else {
              // update question
              $template.updateQuestion(
                createSkillQuestionStructure(i18n, { skills: mappedSkills }),
                question.id,
                question.sectionId,
              );
              $isCreateQuestionModal.off();
              setSelectedQuestion(null);
            }
          }}
          question={selectedQuestion}
        />
      )}
      {$isRateScaleModal.value && (
        <AdjustRatingScaleModal
          onClose={$isRateScaleModal.off}
          providedScaleLabels={activeSection.scaleLabels}
          onSubmit={$template.updateSectionScaleLabels}
          providedIsSkippable={activeSection.isSkippable}
        />
      )}
      {$isShowPreviewModal.value && (
        <TemplatePreviewModal onClose={$isShowPreviewModal.off} template={$template} />
      )}
      {$isConfirmModal.value && <ConfirmModal onClose={$isConfirmModal.off} onSubmit={onSubmit} />}
    </>
  );
}

export default ReviewTemplateSetup;
