import React, { ChangeEvent, ReactNode, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button } from '~/components/Buttons/Button';
import { ButtonSize, ButtonVariant } from '~/components/Buttons/types';
import { ICONS, Icon } from '~/components/Icon';
import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

import type { ITableListProps } from '../../types';

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 350px;
`;

const LeftSideActions = styled.div`
  display: flex;
`;

const RightSideActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 10px 0 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${COLORS.WHITE};
  padding: 20px 32px;
  border-radius: 0 10px 0 0;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${COLORS.TEXT_MAIN};
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
`;

const StyledFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  background-color: ${COLORS.WHITE};
`;

const StyledButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledSpan = styled.span`
  color: ${COLORS.COMPANY};
`;

interface ITopProps {
  actionButton?: ITableListProps['actionButton'];
  secondaryButton?: ITableListProps['secondaryButton'];
  thirdButton?: ITableListProps['thirdButton'];
  fourthButton?: ReactNode;
  filters: {
    search: string;
    setSearch: (value: string) => void;
    resetFilters?: () => void;
    filterCount?: number;
  };
  filterComponents?: ReactNode;
  isToggleHideFilterVisible?: boolean;
  resetFilters?: () => void;
  className?: string;
}

const Top = ({
  actionButton,
  secondaryButton,
  thirdButton,
  fourthButton,
  filters,
  filterComponents,
  isToggleHideFilterVisible,
  resetFilters,
  className,
}: ITopProps) => {
  const { i18n } = useLingui();
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  return (
    <Wrapper className={className}>
      <HeaderWrapper>
        <LeftSideActions>
          {filters && (
            <SearchFieldWrapper
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                filters.setSearch(e.currentTarget.value)
              }
              value={filters.search}
              placeholder={i18n._(t`Search...`)}
              style={{
                width: '350px',
                borderRadius: '10rem',
                fontSize: '14px',
                fontWeight: 600,
              }}
            />
          )}
        </LeftSideActions>
        <RightSideActions>
          {isToggleHideFilterVisible ? (
            <StyledButton onClick={() => setIsFiltersVisible((prevState) => !prevState)}>
              <Icon icon={isFiltersVisible ? ICONS.HIDE : ICONS.SHOW} />
              {i18n._(isFiltersVisible ? t`Hide filters` : t`Show filters`)}
              <StyledSpan>{filters.filterCount && `(${filters.filterCount})`}</StyledSpan>
            </StyledButton>
          ) : (
            <StyledFiltersWrapper>{filterComponents}</StyledFiltersWrapper>
          )}
          <StyledButtons>
            {fourthButton}
            {thirdButton && (
              <Button
                label={thirdButton.label}
                onClick={thirdButton.onClick}
                icon={thirdButton.icon}
                variant={thirdButton.variant || ButtonVariant.SECONDARY}
                size={ButtonSize.MEDIUM}
              />
            )}
            {secondaryButton && (
              <Button
                label={secondaryButton.label}
                onClick={secondaryButton.onClick}
                icon={secondaryButton.icon}
                variant={secondaryButton.variant || ButtonVariant.SECONDARY}
                size={ButtonSize.MEDIUM}
                isLoading={secondaryButton.isLoading}
                disabled={secondaryButton.disabled}
              />
            )}
            {actionButton && (
              <Button
                label={actionButton.label}
                onClick={actionButton.onClick}
                icon={actionButton.icon}
                variant={actionButton.variant || ButtonVariant.PRIMARY}
                size={ButtonSize.MEDIUM}
              />
            )}
          </StyledButtons>
        </RightSideActions>
      </HeaderWrapper>
      {isToggleHideFilterVisible && filterComponents && isFiltersVisible && (
        <StyledFiltersWrapper>
          {filterComponents}{' '}
          {!!resetFilters && (
            <Button
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`Reset all filters`)}
              onClick={() => {
                resetFilters();
                setIsFiltersVisible(false);
              }}
              icon={ICONS.CLOSE}
            />
          )}
        </StyledFiltersWrapper>
      )}
    </Wrapper>
  );
};

export { Top };
