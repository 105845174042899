import React, { Component } from 'react';

import { QUESTION_TYPES, CAREER_PLAN_STATUSES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import SelectSkills from '~/components/SelectSkillsModal/SelectSkills';

import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getCareerPlans } from '~/services/careerPlans';
import { getJobProfiles } from '~/services/jobProfiles';
import { getSkills } from '~/services/skills';
import * as currentReviewActions from '~/store/currentReview/actions';
import { COLOR_SET } from '~/styles';
import createQuestionName from '~/utils/createQuestionName';

const HeaderActions = styled.div`
  display: flex;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  color: #3b3b3b;
`;

const Block = styled.div`
  margin-top: 40px;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #3b3b3b;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const Hint = styled.div`
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  color: #70747f;
`;

const CustomQuestionWrapper = styled.div`
  padding: 16px 0 48px;
`;

const Flag = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const FlagCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${(props) => (props.isChecked ? COLOR_SET.BLUE : '#f27171')};
  margin-right: 16px;
`;

const FlagLabel = styled.div`
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #70747f;
`;
const SkillRequirementLegend = styled.div`
  width: 457px;
  height: 16px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: ${COLOR_SET.BLUE};
  margin-top: 16px;
  text-transform: uppercase;
`;

const INITIAL_STATE = {
  loading: true,
  name: '',
  selectedSkills: [],
  jobProfiles: {},
  careerPlans: {},
};

class CreateQuestionModal extends Component {
  constructor(props) {
    super(props);
    const state = {
      ...INITIAL_STATE,
    };

    if (!isEmpty(props.question)) {
      state.name = props.question.name;
      state.selectedSkills = props.question.skills || [];
    }

    const selectedSkills = get(props, 'review.skills');
    if (!isEmpty(selectedSkills)) {
      state.selectedSkills = selectedSkills;
    }

    this.state = state;
  }

  static propTypes = {
    type: PropTypes.string,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func, // optional
    question: PropTypes.object,
  };

  static defaultProps = {
    type: QUESTION_TYPES.CUSTOM,
    onClose: () => {},
  };

  async componentDidMount() {
    const { type } = this.props;
    const { selectedSkills } = this.state;
    const state = {};

    if (type === QUESTION_TYPES.JOB_PROFILE) {
      const [careerPlans, jobProfiles] = await Promise.all([
        getCareerPlans({ status: CAREER_PLAN_STATUSES.CURRENT }),
        getJobProfiles(),
      ]);
      state.careerPlans = careerPlans;
      state.jobProfiles = jobProfiles;
    }

    // this part is needed when selectedSkill is array with ids
    if (type === QUESTION_TYPES.SKILL && !isEmpty(selectedSkills)) {
      const skills = await getSkills({ enabledSkillsOnly: true, hideDeleted: true });
      state.selectedSkills = selectedSkills
        .map((s) => (s.id ? s : skills[s] ? skills[s] : null))
        .filter((s) => s);
    }

    state.loading = false;
    this.setState(state);
  }

  closeModal = () => {
    this.setState({ ...INITIAL_STATE });
    this.props.onClose();
  };

  handleChange = (e) => {
    // e.preventDefault();
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    });
  };

  createQuestion = () => {
    const { i18n, dispatch, user, type, currentCompany } = this.props;
    const { name, selectedSkills } = this.state;

    dispatch(
      currentReviewActions.createCurrentReviewQuestion({
        question: {
          type,
          company: currentCompany.id,
          createdFor: user.id,
          createdBy: user.id,
          name: createQuestionName({
            i18n,
            type,
            name,
            selectedSkills,
          }),
        },
        selectedSkills,
      }),
    );

    this.closeModal();
  };

  updateQuestion = async () => {
    const { i18n, dispatch, question } = this.props;
    const { name, selectedSkills } = this.state;

    dispatch(
      currentReviewActions.updateCurrentReviewQuestion({
        question,
        name: createQuestionName({
          i18n,
          type: question.type,
          name,
          selectedSkills,
        }),
        selectedSkills,
      }),
    );

    this.closeModal();
  };

  onSubmit = async () => {
    const { question, onSubmit } = this.props;
    const { selectedSkills } = this.state;
    if (onSubmit) {
      this.setState({ loading: true });
      return onSubmit({ skills: selectedSkills, question });
    }
    return isEmpty(question) ? this.createQuestion() : this.updateQuestion();
  };

  getModalHeight = () => {
    const { type } = this.props;
    let height = '600px';
    switch (type) {
      case QUESTION_TYPES.CUSTOM:
        height = '305px';
        break;
      case QUESTION_TYPES.SKILL:
        height = '648px';
        break;
      case QUESTION_TYPES.JOB_PROFILE:
        height = '534px';
        break;
      default:
        break;
    }
    return height;
  };

  getTitle = () => {
    const { i18n, type } = this.props;
    let title = '';
    switch (type) {
      case QUESTION_TYPES.CUSTOM:
        title = i18n._(t`Create a question`);
        break;
      case QUESTION_TYPES.SKILL:
        title = i18n._(t`Generate questions based on manually selected skills`);
        break;
      case QUESTION_TYPES.JOB_PROFILE:
        title = i18n._(t`Generate questions based on skills in job profiles`);
        break;
      default:
        break;
    }
    return title;
  };

  isDisabled = () => {
    const { type } = this.props;
    const { name, selectedSkills } = this.state;

    let isDisabled = false;
    switch (type) {
      case QUESTION_TYPES.CUSTOM:
        isDisabled = isEmpty(name);
        break;
      case QUESTION_TYPES.SKILL:
        isDisabled = isEmpty(selectedSkills);
        break;
      case QUESTION_TYPES.JOB_PROFILE:
        break;
      default:
        break;
    }
    return isDisabled;
  };

  toggleSkill = (skill) => (e) => {
    e.stopPropagation();
    const { selectedSkills } = this.state;

    // update selectedSkills
    let newSelectedSkills = selectedSkills ? [...selectedSkills] : [];
    let index = newSelectedSkills.map((u) => u.id).indexOf(skill.id);
    if (index !== -1) {
      newSelectedSkills = newSelectedSkills.filter((_, i) => i !== index);
    } else {
      newSelectedSkills.push(skill);
    }

    this.setState({
      selectedSkills: newSelectedSkills,
    });
  };

  renderCustomQuestion = () => {
    const { i18n } = this.props;
    const { name } = this.state;

    return (
      <CustomQuestionWrapper>
        <Label>
          <Trans>Formulate the question</Trans>
        </Label>
        <input
          name="name"
          className="input"
          value={name}
          onChange={this.handleChange}
          placeholder={i18n._(t`Example: Does this person contribute to the company culture?`)}
        />
      </CustomQuestionWrapper>
    );
  };

  renderSkillQuestion = () => {
    const { selectedSkills, loading } = this.state;

    return (
      <CustomQuestionWrapper>
        <Hint>
          <Trans>
            Automatically generate questions based on manually selected skills. The default question
            used is: “How did this employee perform on [skill name]”?
          </Trans>
        </Hint>
        <SkillRequirementLegend>
          <Trans>Select at least 1 skill to add as a question.</Trans>
        </SkillRequirementLegend>
        {!loading && (
          <SelectSkills
            selectedSkills={selectedSkills}
            toggleSkill={this.toggleSkill}
            withoutLevels={true}
            isIncludeSuggestedSkills
            isShowPreloadedSkills
          />
        )}
      </CustomQuestionWrapper>
    );
  };

  renderJobProfileQuestion = () => {
    const { i18n, review } = this.props;
    const { careerPlans, jobProfiles } = this.state;

    let isAllUserLinkedJobProfiles = false;
    let isAllJobProfilesHaveSkills = false;

    if (!isEmpty(review.users) && !isEmpty(careerPlans)) {
      isAllUserLinkedJobProfiles = true;
      isAllJobProfilesHaveSkills = true;
      let isUserWithCP = false;

      review.users.forEach((user) => {
        const userId = user.id || user;

        const userCareerPlans = Object.values(careerPlans).filter(
          (plan) => plan.createdFor === userId,
        );

        if (userCareerPlans.length === 0) {
          isAllUserLinkedJobProfiles = false;
        } else {
          isUserWithCP = true;
          userCareerPlans.forEach((plan) => {
            if (
              !isEmpty(jobProfiles[plan.jobProfile]) &&
              isEmpty(jobProfiles[plan.jobProfile].skills)
            ) {
              isAllJobProfilesHaveSkills = false;
            }
          });
        }
      });

      // if all users without CP then have skills can't be true.
      if (!isUserWithCP) {
        isAllJobProfilesHaveSkills = false;
      }
    }

    const flags = [
      {
        key: 0,
        label: i18n._(t`All selected participants have been assigned a job profile`),
        isChecked: isAllUserLinkedJobProfiles,
      },
      {
        key: 1,
        label: i18n._(t`All connected job profiles have skills`),
        isChecked: isAllJobProfilesHaveSkills,
      },
    ];

    return (
      <CustomQuestionWrapper>
        <Hint>
          <Trans>
            Automatically generate questions based on the skills in the employee’s job profile. The
            default question used is: “How did this employee perform on [skill name]”?
          </Trans>
        </Hint>
        <Block>
          <Label>
            <Trans>Enable job profiles*</Trans>
          </Label>
        </Block>
        <Block>
          <Label>
            <Trans>Health check</Trans>
          </Label>
          {flags.map((flag) => (
            <Flag key={flag.key}>
              <FlagCircle isChecked={flag.isChecked} />
              <FlagLabel>{flag.label}</FlagLabel>
            </Flag>
          ))}
        </Block>
      </CustomQuestionWrapper>
    );
  };

  render() {
    const { loading } = this.state;
    const { i18n, type } = this.props;

    return (
      <Modal
        className="new-team-modal"
        title={<Title>{this.getTitle()}</Title>}
        onClose={this.closeModal}
        width={625}
        minWidth={625}
        minHeight={this.getModalHeight()}
        contentStyles={{ background: '#fff', height: '100%', padding: '0px 16px' }}
        headerStyles={{ padding: '0 16px', borderBottom: 'solid 0.5px #ecedf0' }}
        footerRight={
          <HeaderActions>
            <Button
              styles={{ marginLeft: '10px' }}
              label={i18n._(t`Save`)}
              disabled={this.isDisabled()}
              type="primary-border"
              onClick={this.onSubmit}
              loading={loading}
            />
          </HeaderActions>
        }
      >
        {type === QUESTION_TYPES.CUSTOM && this.renderCustomQuestion()}
        {type === QUESTION_TYPES.SKILL && this.renderSkillQuestion()}
        {type === QUESTION_TYPES.JOB_PROFILE && this.renderJobProfileQuestion()}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    review: state.currentReview,
    currentCompany: getCurrentCompany(state),
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(CreateQuestionModal)));
