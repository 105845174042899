import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import DashboardBoxHeader from '~/components/AdminDashboardBoxes/DashboardBoxHeader';
import DashboardBoxPlaceholder from '~/components/AdminDashboardBoxes/DashboardBoxPlaceholder';
import ConversationsStatistics from '~/components/ConversationsStatistics';

import conversationIcon from '~/assets/main-menu-icons/chat-1.svg';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { getUserConversationsForGraph } from '~/services/userConversations';

const Wrapper = styled.div``;

const StyledConversationsStatistics = styled(ConversationsStatistics)`
  margin: 16px 0;
  position: relative;
  height: 100%;
  width: ${(props) => props.$width};
`;

const ConversationModuleBox = ({ i18n, width = '100%' }) => {
  const history = useHistory();
  const [conversationsForGraph, setConversationsForGraph] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      const currentDate = new Date();
      const graphConversationsData = await getUserConversationsForGraph({
        startDate: new Date(currentDate.getFullYear(), currentDate.getMonth() - 5).toISOString(),
        endDate: currentDate,
      });
      setConversationsForGraph(graphConversationsData.groupedConversations);
    };
    fetch();
  }, []);

  const onClick = () => {
    history.push(routes.CONVERSATION_REPORT.build({ role: ROLES.ADMIN }));
  };

  return (
    <Wrapper>
      <DashboardBoxHeader
        title={i18n._(t`1-1 conversations`)}
        subtitle={i18n._(t`Keep track of the 1-1 conversations conducted each month`)}
        onClick={onClick}
      />
      {!isEmpty(conversationsForGraph) ? (
        <StyledConversationsStatistics graphData={conversationsForGraph} $width={width} />
      ) : (
        <DashboardBoxPlaceholder
          title={i18n._(t`No data available yet`)}
          subtitle={i18n._(
            t`There are no 1-1 conversations planned yet. Start using 1-1’s to stimulate a continuous dialogue within your company.`,
          )}
          icon={conversationIcon}
        />
      )}
    </Wrapper>
  );
};

export default ConversationModuleBox;
