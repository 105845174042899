import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ICONS } from '~/components/Icon';
import Notifications from '~/components/Notifications/Notifications';

import PrimaryMenuItem from './PrimaryMenuItem';

import { CONNECTION_STATUSES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { useWindowDimensions } from '~/hooks/useWindowDimensions';
import { getIsMenuCollapsed } from '~/selectors/auth';
import {
  getSelectedRole,
  getUser,
  getTeams,
  getCurrentCompanyId,
  getSelectedTeam,
} from '~/selectors/baseGetters';
import { updateUserProfile } from '~/store/auth/actions';

import CollapseMenuItem from '../CollapseMenuItem';
import CompanySwitchMenu from '../CompanySwitchMenu';
import { MenuBlocks, MenuFooter, MenuTopSection, Menu, MenuDivider } from '../styles/design';
import { autoCollapseMenu } from '../utils/shouldAutoCloseMenu';

import type { IMenuItem } from '../types';
import type { IConnection } from '@learned/types';
import type { I18n } from '@lingui/core';

interface PrimaryMenuProps {
  mainMenuBlocks: IMenuItem[];
  footerMenuWithURL: IMenuItem[];
  accountMenuWithUrl: IMenuItem[];
  isSecondaryMenuOpen: boolean;
  numberOfUnreadNotifications: number;
  onReadAllNotifications: () => void;
  isUserHasAdminRole: boolean;
}

const PrimaryMenu: React.FC<PrimaryMenuProps> = ({
  mainMenuBlocks,
  footerMenuWithURL,
  accountMenuWithUrl,
  isSecondaryMenuOpen,
  numberOfUnreadNotifications,
  onReadAllNotifications,
  isUserHasAdminRole,
}) => {
  const $isNotificationsOpen = useBoolState(false);
  const dispatch = useDispatch();
  const selectedRole = useSelector(getSelectedRole);
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const selectedTeam = useSelector(getSelectedTeam);
  const user = useSelector(getUser);
  const teams = useSelector(getTeams);
  const isMenuCollapsedSettings = useSelector(getIsMenuCollapsed);
  const [isAutoCollapse, setIsAutoCollapse] = useState(false);
  const [isManualCollapse, setIsManualCollapse] = useState(isMenuCollapsedSettings);
  const [isManualClick, setIsManualClick] = useState(false);
  const location = useLocation();

  const { height } = useWindowDimensions();
  const isSmallScreenHeight = height < 625;

  // Collapse logic
  // if secondary is open primary menu should be collapsed until user manually click expand button
  // if user clicks on another primary menu item with secondary menu -> collapse primary menu

  const isMenuCollapsed = isManualClick
    ? isManualCollapse
    : isAutoCollapse || isMenuCollapsedSettings;

  useEffect(() => {
    const isPageToAutoCloseMenu = autoCollapseMenu({
      role: selectedRole,
      companyId: currentCompanyId,
      teamId: selectedTeam,
      userId: user.id,
      teams,
      // @ts-ignore
      location,
      isSecondaryMenuOpen,
    });

    setIsAutoCollapse(isPageToAutoCloseMenu);
    setIsManualCollapse(isMenuCollapsedSettings); // reset manual collapse to settings value
    setIsManualClick(false); // reset manual click

    // eslint-disable-next-line
  }, [location]);

  const toggleCollapseMenu = () => {
    dispatch(updateUserProfile({ isMenuCollapsed: !isMenuCollapsed }));
    setIsManualClick(true);
    setIsManualCollapse(!isMenuCollapsed);
  };

  const openNotifications = async () => {
    $isNotificationsOpen.on();
    await onReadAllNotifications();
  };

  const checkUserConnections = () => {
    const activeUserConnections =
      !isEmpty(user) &&
      user.connections &&
      user.connections.filter((c: IConnection) => {
        // @ts-ignore
        return c && (!c.dateTo || c.dateTo === '') && c.status !== CONNECTION_STATUSES.INACTIVE.key;
      });
    return !isEmpty(activeUserConnections);
  };

  const isActiveUserConnections = checkUserConnections();
  const isShowCompanySwitcher = !isEmpty(user) && isActiveUserConnections;

  const footerMenuBlocks = [
    ...footerMenuWithURL,
    {
      title: (i18n: I18n) => i18n._(t`Notifications`),
      icon: ICONS.NOTIFICATIONS,
      onClick: openNotifications,
      children: [],
      numberOfNotifications: numberOfUnreadNotifications,
      key: 'notifications',
    },
    ...accountMenuWithUrl,
  ].filter((i) => i);

  return (
    <Menu $isMenuCollapsed={isMenuCollapsed}>
      <MenuTopSection>
        {isShowCompanySwitcher && (
          <CompanySwitchMenu
            isMenuCollapsed={isMenuCollapsed}
            isUserHasAdminRole={isUserHasAdminRole}
          />
        )}
        {!isEmpty(mainMenuBlocks) && (
          <MenuBlocks>
            {mainMenuBlocks.map((item, key) => (
              <PrimaryMenuItem
                key={key}
                item={item}
                isMenuCollapsed={isMenuCollapsed}
                isSmallScreenHeight={isSmallScreenHeight}
              />
            ))}
            <MenuDivider />
          </MenuBlocks>
        )}
      </MenuTopSection>
      <MenuFooter>
        {!isEmpty(footerMenuBlocks) && (
          <MenuBlocks>
            {footerMenuBlocks.map((item, key) => (
              <PrimaryMenuItem
                key={key}
                // @ts-ignore
                item={item}
                isMenuCollapsed={isMenuCollapsed}
                isSmallScreenHeight={isSmallScreenHeight}
              />
            ))}
            <CollapseMenuItem onClick={toggleCollapseMenu} isMenuCollapsed={isMenuCollapsed} />
          </MenuBlocks>
        )}
      </MenuFooter>
      {$isNotificationsOpen.value && <Notifications onClose={$isNotificationsOpen.off} />}
    </Menu>
  );
};

export default React.memo(PrimaryMenu);
