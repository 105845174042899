import { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, QUESTION_TYPES, KPI_TYPES, KPI_NONE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import nanoId from 'nanoid';
import { useHistory } from 'react-router';

import { confirm } from '~/components/ConfirmDialog';

import {
  changeQuestionOrderInSections,
  createJobProfileQuestion,
  createNewSection,
  fillSectionsByIds,
  insertQuestionIntoSection,
  isQuestionTypeExist,
  patchSectionQuestion,
  prepareSections,
  removeQuestionFromSection,
} from './helpers';
import useSignState from './hookModules/useSignState';

import useBoolState from '~/hooks/useBoolState';
import { getKpis } from '~/services/kpis';
import {
  createTemplate,
  createTemplateSuperAdmin,
  getTemplate,
  getTemplateSuperAdmin,
  updateTemplate,
  updateTemplateSuperAdmin,
} from '~/services/reviewTemplates';
import createQuestionName from '~/utils/createQuestionName';
import reorderArray from '~/utils/reorderArray';

// hooks modules

const SCALE_LABELS = [
  'Far below expected',
  'Below expected',
  'As expected',
  'Above expected',
  'Far above expected',
];

const useTemplateState = (reviewTemplateId, isSuperAdmin = false) => {
  const { i18n } = useLingui();
  const history = useHistory();
  const [templateName, setName] = useState('');
  const [templateDescription, setDescription] = useState('');
  const [sections, setSections] = useState([]);
  const [activeSectionId, setActiveSectionId] = useState(null);
  const [activeSectionKpi, setActiveSectionKpi] = useState(KPI_NONE); // kpi object
  const [showActiveSection, setShowActiveSection] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [isCalibrate, setIsCalibrate] = useState(true); // Auto enable calibrate in review template. Only for new templates.
  const [activeQuestionId, setActiveQuestionId] = useState(null);
  const $fetching = useBoolState(true);
  const isShowErrors = useBoolState(false);
  const $signModule = useSignState();
  const [kpis, setKpis] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // fetch kpi always
      let kpis = [KPI_NONE];
      if (!isSuperAdmin) {
        const response = await getKpis({ filters: { isDeleted: false } });
        kpis = [...kpis, ...response.data[API_RETURN_FIELDS.KPIS]];
      }
      setKpis(kpis);

      // fetch review template (if id exist)
      try {
        if (reviewTemplateId) {
          const template = isSuperAdmin
            ? await getTemplateSuperAdmin(reviewTemplateId)
            : await getTemplate(reviewTemplateId);

          setName(template.name);
          setDescription(template.description);
          setIsCalibrate(template.isCalibrate);
          const sections = fillSectionsByIds(template.sections);
          setSections(sections);
          setActiveSectionId(sections[0].id);
          const kpi = (sections[0].kpi && kpis[sections[0].kpi]) || KPI_NONE;
          setActiveSectionKpi(kpi);
          $signModule.initSignSection(get(template, 'digitalSign'));
        }
      } finally {
        $fetching.off();
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, [reviewTemplateId]);

  const toggleIsCalibrate = () => {
    setIsCalibrate((prev) => !prev);
  };

  const updateActiveSectionId = (id) => {
    if (id !== activeSectionId) {
      setActiveQuestionId(null);
    }

    setActiveSectionId(id);
  };

  const resetActiveSection = () => {
    setActiveSectionId(null);
    setActiveSectionKpi(null);
  };

  const toggleAdvancedOptions = (sectionId) => {
    setActiveSectionId(sectionId);
    setShowAdvancedOptions((prev) => !prev);
  };

  const resetActiveQuestion = () => {
    setActiveQuestionId(null);
  };

  const closeSection = () => {
    setShowAdvancedOptions(false);
    setShowActiveSection(false);
    setActiveSectionId(null);
    setActiveSectionKpi(null);
    setActiveQuestionId(null);
  };

  const openSection = (sectionId) => {
    if (!sectionId) {
      return;
    }
    if (sectionId !== activeSectionId) {
      setShowAdvancedOptions(false);
    }
    setShowActiveSection(true);
    setActiveSectionId(sectionId);
    updateActiveSectionType(sectionId);
    const newActiveSection = sections.find((s) => s.id === sectionId);
    setActiveQuestionId(newActiveSection?.questions?.[0]?.id ?? null);
  };

  const updateActiveSectionType = (sectionId) => {
    const newActiveSectionType = sections.find((s) => s.id === sectionId);
    setActiveSectionKpi(newActiveSectionType.kpi);
  };

  const updateActiveQuestionId = (questionId, sectionId) => {
    if (sectionId !== activeSectionId) {
      setActiveSectionId(sectionId);
      updateActiveSectionType(sectionId);
    }
    if (activeQuestionId !== questionId) {
      setActiveQuestionId(questionId);
    }
  };

  const addQuestionToActiveSection = (question) => {
    const newSections = sections.map((section) => {
      const isNoneKpi = section.type === KPI_NONE.type && section.kpi === KPI_NONE.id;
      if (section.id === activeSectionId) {
        const newQuestion = { ...question, id: nanoId() };
        const isPlanQuestion =
          [QUESTION_TYPES.GOAL_LEARNING_PLAN, QUESTION_TYPES.GOAL_BUSINESS_PLAN].indexOf(
            question.type,
          ) !== -1;

        if (
          isNoneKpi &&
          [
            QUESTION_TYPES.CUSTOM,
            QUESTION_TYPES.GOAL_LEARNING_EVAL,
            QUESTION_TYPES.GOAL_BUSINESS_EVAL,
          ].indexOf(question.type) !== -1
        ) {
          newQuestion.scaleLabels = question.scaleLabels || SCALE_LABELS;
          newQuestion.isSkippable = true;
          newQuestion.isCommentObligated = true;
        }

        setActiveQuestionId(newQuestion.id);
        const sectionWithNewQuestion = insertQuestionIntoSection(section, newQuestion);

        if (!isPlanQuestion) {
          return sectionWithNewQuestion;
        }

        return {
          ...sectionWithNewQuestion,
          isUsersReview: false,
        };
      }

      return section;
    });
    setSections(newSections);
  };

  const deleteTemplateSection = (id) => {
    const updatedSections = filter(sections, (s) => s.id !== id);
    setSections(updatedSections);
    updateActiveSectionId(isEmpty(updatedSections) ? null : updatedSections[0].id);
    setActiveSectionKpi(isEmpty(updatedSections) ? null : updatedSections[0].kpi);
  };

  const changeTemplateSectionType = (id, kpi) => {
    const isSkillsKpi = kpi.type === KPI_TYPES.SKILLS;
    const isNoneKpi = kpi.id === KPI_NONE.id;

    const updatedSections = sections.map((s) => {
      if (s.id === id) {
        // exclude skills kpi and none
        if (!isSkillsKpi && !isNoneKpi) {
          s.scaleLabels = SCALE_LABELS;
          s.isSkippable = true;
        } else {
          if (Object.hasOwnProperty.call(s, 'isSkippable')) {
            delete s.isSkippable;
          }
          if (Object.hasOwnProperty.call(s, 'scaleLabels')) {
            delete s.scaleLabels;
          }
        }
        // for skill coverage add job profile question automatically
        let questions = isSkillsKpi ? [createJobProfileQuestion(i18n)] : [];

        return {
          ...s,
          type: kpi.type,
          kpi: kpi.id,
          questions,
        };
      }
      return s;
    });
    setSections(updatedSections);
    updateActiveSectionId(id);
    setActiveSectionKpi(kpi.id);
  };

  const createNewTemplate = () => ({
    name: templateName,
    description: templateDescription,
    isCalibrate,
    sections: prepareSections(sections),
    digitalSign: $signModule.digitalSign,
  });

  return {
    templateName,
    templateDescription,
    setTemplate: (data) => {
      if (Object.hasOwnProperty.call(data, 'name')) {
        setName(data.name);
      }
      if (Object.hasOwnProperty.call(data, 'description')) {
        setDescription(data.description);
      }
    },
    switchSections: (sIndex, dIndex) => {
      setSections(reorderArray(sections || [], sIndex, dIndex));
    },
    switchQuestions: (source, destination) => {
      const newSections = changeQuestionOrderInSections(
        sections,
        source.section,
        destination.section,
        source.index,
        destination.index,
      );

      if (newSections !== sections) {
        setSections(newSections);
      }
    },
    createReviewTemplate: async (isSuperAdmin) => {
      const newTemplate = createNewTemplate();
      if (isSuperAdmin) {
        await createTemplateSuperAdmin(newTemplate);
        history.goBack();
      } else {
        await createTemplate(newTemplate);
        history.goBack();
      }
    },
    updateTemplate: async (isSuperAdmin) => {
      const newTemplate = createNewTemplate();
      if (isSuperAdmin) {
        await updateTemplateSuperAdmin(reviewTemplateId, newTemplate);
        history.goBack();
      } else {
        await updateTemplate(reviewTemplateId, newTemplate);
        history.goBack();
      }
    },
    sections,
    createNewSection: () => {
      const newSection = createNewSection();
      setSections(sections.concat([newSection]));
      updateActiveSectionId(newSection.id);
      setShowAdvancedOptions(true);
      setActiveSectionKpi(KPI_NONE.id);
    },
    deleteSection: async (id) => {
      const sectionToDelete = find(sections, (s) => s.id === id);
      if (!isEmpty(sectionToDelete.questions)) {
        if (
          await confirm(
            i18n,
            i18n._(
              t`Are you sure you want to delete this section? This will also delete all questions in this section.`,
            ),
          )
        ) {
          deleteTemplateSection(id);
        }
      } else {
        deleteTemplateSection(id);
      }
    },
    changeSectionType: async (id, kpi) => {
      const sectionToUpdate = find(sections, (s) => s.id === id);
      if (!isEmpty(sectionToUpdate.questions)) {
        if (
          await confirm(
            i18n,
            i18n._(
              t`Are you sure you want to change this type? This will also delete all questions in this section.`,
            ),
          )
        ) {
          changeTemplateSectionType(id, kpi);
        }
      } else {
        changeTemplateSectionType(id, kpi);
      }
    },
    setSectionName: (id, name, _idx) => {
      const updatedSections = sections.map((s) => {
        if (s.id === id) {
          return {
            ...s,
            name,
          };
        }
        return s;
      });
      setSections(updatedSections);
      updateActiveSectionId(id);
      updateActiveSectionType(id);
    },
    setSectionDescription: (id, description, _idx) => {
      const updatedSections = sections.map((s) => {
        if (s.id === id) {
          return {
            ...s,
            description,
          };
        }
        return s;
      });
      setSections(updatedSections);
      updateActiveSectionId(id);
      updateActiveSectionType(id);
    },
    setSectionTheme: (id, theme, _idx) => {
      const updatedSections = sections.map((s) => {
        if (s.id === id) {
          return {
            ...s,
            theme,
          };
        }
        return s;
      });
      setSections(updatedSections);
      updateActiveSectionId(id);
      updateActiveSectionType(id);
    },
    updateSectionKey: (sectionId, key, value) => {
      const updatedSections = sections.map((s) => {
        if (s.id === sectionId) {
          return {
            ...s,
            [key]: value,
          };
        }
        return s;
      });
      setSections(updatedSections);
      updateActiveSectionId(sectionId);
      updateActiveSectionType(sectionId);
    },
    addCustomQuestion: () => {
      addQuestionToActiveSection({
        type: QUESTION_TYPES.CUSTOM,
        name: '',
        description: '',
        hideRating: false,
        isCommentObligated: false,
      });
    },
    copyCustomQuestion: (question, sectionId) => {
      updateActiveSectionId(sectionId);
      addQuestionToActiveSection(question);
    },
    addJobProfileQuestion: () => {
      addQuestionToActiveSection({
        type: QUESTION_TYPES.JOB_PROFILE,
        name: createQuestionName({
          type: QUESTION_TYPES.JOB_PROFILE,
          i18n,
        }),
        isSkippable: true,
        isCommentObligated: true,
        skillCategories: [],
      });
    },
    addSkillQuestion: (newQuestion) => {
      addQuestionToActiveSection(newQuestion);
    },
    addEvalLearnGoalQuestion: () => {
      addQuestionToActiveSection({
        type: QUESTION_TYPES.GOAL_LEARNING_EVAL,
        name: '',
        hideRating: false,
        isCommentObligated: true,
        isAverageQuestionEnabled: true,
      });
    },
    addEvalBussGoalQuestion: () => {
      addQuestionToActiveSection({
        type: QUESTION_TYPES.GOAL_BUSINESS_EVAL,
        name: '',
        hideRating: false,
        isCommentObligated: true,
        isAverageQuestionEnabled: true,
      });
    },
    addPlanBussGoalQuestion: () => {
      addQuestionToActiveSection({
        type: QUESTION_TYPES.GOAL_BUSINESS_PLAN,
        name: '',
        description: '',
      });
    },
    addPlanLearnGoalQuestion: () => {
      addQuestionToActiveSection({
        type: QUESTION_TYPES.GOAL_LEARNING_PLAN,
        name: '',
        description: '',
      });
    },
    updateQuestion: (data, questionId, sectionId) => {
      updateActiveSectionId(sectionId);
      updateActiveSectionType(sectionId);
      setActiveQuestionId(questionId);
      setSections(
        sections.map((s) => {
          if (sectionId === s.id) {
            return patchSectionQuestion(s, questionId, (q) => ({
              ...q,
              ...data,
            }));
          }
          return s;
        }),
      );
    },
    deleteQuestion: (questionId, sectionId) => {
      updateActiveSectionId(sectionId);
      updateActiveSectionType(sectionId);
      setActiveQuestionId(null);
      setSections(
        sections.map((s) => {
          if (s.id === sectionId) {
            return removeQuestionFromSection(s, questionId);
          }
          return s;
        }),
      );
    },
    updateSectionScaleLabels: ({ scaleLabels, isSkippable }) => {
      setSections(
        sections.map((s) => {
          if (activeSectionId === s.id) {
            return {
              ...s,
              scaleLabels,
              isSkippable,
            };
          }
          return s;
        }),
      );
    },
    deleteScaleLabel: ({ labelIndex, questionId, sectionId }) => {
      updateActiveSectionId(sectionId);
      updateActiveSectionType(sectionId);
      setActiveQuestionId(questionId);
      setSections(
        sections.map((s) => {
          if (sectionId === s.id) {
            return patchSectionQuestion(s, questionId, (q) => ({
              ...q,
              scaleLabels: q.scaleLabels.filter((_l, i) => i !== labelIndex),
            }));
          }

          return s;
        }),
      );
    },
    updateScaleLabel: ({ value, labelIndex, questionId, sectionId }) => {
      updateActiveSectionId(sectionId);
      updateActiveSectionType(sectionId);
      setActiveQuestionId(questionId);
      setSections(
        sections.map((s) => {
          if (sectionId === s.id) {
            return patchSectionQuestion(s, questionId, (q) => ({
              ...q,
              scaleLabels: q.scaleLabels.map((label, i) => (i === labelIndex ? value : label)),
            }));
          }
          return s;
        }),
      );
    },
    addScaleLabel: (questionId, sectionId) => {
      updateActiveSectionId(sectionId);
      updateActiveSectionType(sectionId);
      setActiveQuestionId(questionId);
      setSections(
        sections.map((s) => {
          if (sectionId === s.id) {
            return patchSectionQuestion(s, questionId, (q) => ({
              ...q,
              scaleLabels: [...q.scaleLabels, ''],
            }));
          }
          return s;
        }),
      );
    },
    toggleSkillCategories: (questionId, sectionId, categories) => {
      updateActiveSectionId(sectionId);
      updateActiveSectionType(sectionId);
      setActiveQuestionId(questionId);
      setSections(
        sections.map((s) => {
          if (sectionId === s.id) {
            return patchSectionQuestion(s, questionId, (q) => ({
              ...q,
              skillCategories: categories,
            }));
          }
          return s;
        }),
      );
    },
    toggleIsCommentObligated: (questionId, sectionId) => {
      updateActiveSectionId(sectionId);
      updateActiveSectionType(sectionId);
      setActiveQuestionId(questionId);
      setSections(
        sections.map((s) => {
          if (sectionId === s.id) {
            return patchSectionQuestion(s, questionId, (q) => ({
              ...q,
              isCommentObligated: !q.isCommentObligated,
            }));
          }
          return s;
        }),
      );
    },
    toggleIsAverageQuestionEnabled: (questionId, sectionId) => {
      updateActiveSectionId(sectionId);
      updateActiveSectionType(sectionId);
      setActiveQuestionId(questionId);
      setSections(
        sections.map((s) => {
          if (sectionId === s.id) {
            return patchSectionQuestion(s, questionId, (q) => ({
              ...q,
              isAverageQuestionEnabled: !q.isAverageQuestionEnabled,
            }));
          }
          return s;
        }),
      );
    },
    toggleIsSkippable: (questionId, sectionId) => {
      updateActiveSectionId(sectionId);
      updateActiveSectionType(sectionId);
      setActiveQuestionId(questionId);
      setSections(
        sections.map((s) => {
          if (sectionId === s.id) {
            return patchSectionQuestion(s, questionId, (q) => ({
              ...q,
              isSkippable: !q.isSkippable,
            }));
          }
          return s;
        }),
      );
    },
    activeSectionId,
    activeSectionKpi,
    activeQuestionId,
    showActiveSection,
    setActiveSectionId,
    showAdvancedOptions,
    updateActiveSectionId,
    updateActiveQuestionId,
    setActiveQuestionId,
    resetActiveSection,
    isQuestionExist: (type) => isQuestionTypeExist(sections, type),
    isShowErrors,
    toggleAdvancedOptions,
    resetActiveQuestion,
    openSection,
    closeSection,
    isCalibrate,
    toggleIsCalibrate,
    ...$signModule,
    kpis,
    isFetching: $fetching.value,
  };
};

export default useTemplateState;
