import React from 'react';

import GoalActivityItem from './GoalActivityItem';

import useBoolState from '~/hooks/useBoolState';

import { ActivityItemWrapper } from '../../design';

import type { IActivity } from '@learned/types';

interface IProps {
  activity: IActivity;
  activitiesReadonly: boolean;
  isGoalTree: boolean;
  isOnClick: boolean;
  onUpdatedActivity: (id: string, data: any) => void;
}

const ActivityItem = ({ activity, isOnClick }: IProps) => {
  const $updateProgressModal = useBoolState(false);

  return (
    <ActivityItemWrapper>
      <GoalActivityItem
        activity={activity}
        onClick={isOnClick ? $updateProgressModal.on : undefined}
        isStatusSection={true}
      />
    </ActivityItemWrapper>
  );
};

ActivityItem.defaultProps = {
  activitiesReadonly: true,
};

export default React.memo(ActivityItem);
