import React, { useEffect, useState, Fragment, useMemo } from 'react';

import { API_RETURN_FIELDS, SURVEY_STATUS, SURVEY_TASK_STATUS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { Button } from '~/components/Buttons/Button';
import { ButtonVariant, ButtonSize } from '~/components/Buttons/types';
import DashboardHeader from '~/components/DashboardHeader';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TabSlider } from '~/components/TabSlider';
import type { ITabSliderItem } from '~/components/TabSlider/types';
import { ActionItemBlock, Actions } from '~/pages/UserSurveyDashboard/design';

import { NoAccess } from './components/NoAccess';
import { Setup } from './components/Setup';
import { UserSurveyResults } from './tabs/Results';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { useFromQuery } from '~/hooks/useFromQuery';
import { checkModuleSurvey } from '~/selectors/baseGetters';
import { getUserSurvey } from '~/services/userSurveys';

import type { IUserSurveyPopulated } from '@learned/types';

export const TABS_SLIDER_ENUM = {
  SET_UP: 'setup',
  RESULTS: 'results',
};

const UserSurveyDashboard = () => {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const history = useHistory();
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const params: Record<string, string | undefined> = useParams();
  const [userSurvey, setUserSurvey] = useState<IUserSurveyPopulated>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isModuleSurveyEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleSurveyEnabled, history]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = async () => {
    try {
      if (params?.userSurveyId) {
        setIsLoading(true);
        const { data } = await getUserSurvey(params.userSurveyId);
        setUserSurvey(data[API_RETURN_FIELDS.USER_SURVEY]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const surveyTaskLabel = useMemo(() => {
    switch (userSurvey?.status) {
      case SURVEY_TASK_STATUS.TODO: {
        return i18n._(t`Start`);
      }
      case SURVEY_TASK_STATUS.DRAFT: {
        return i18n._(t`Continue`);
      }
      case SURVEY_TASK_STATUS.COMPLETED: {
        return i18n._(t`Edit survey`);
      }
    }
    // eslint-disable-next-line
  }, [userSurvey]);

  const isShowRedirectToAnswersButton =
    userSurvey &&
    userSurvey?.survey &&
    userSurvey?.survey?.status !== SURVEY_STATUS.ARCHIVED &&
    userSurvey?.status !== SURVEY_TASK_STATUS.ARCHIVED;

  const TAB_SLIDER_ITEMS: ITabSliderItem[] = [
    {
      key: TABS_SLIDER_ENUM.SET_UP,
      label: t`Set-up`,
      content: userSurvey ? (
        <Setup
          userSurvey={userSurvey}
          buttonLabel={surveyTaskLabel}
          isShowRedirectToAnswersButton={isShowRedirectToAnswersButton}
        />
      ) : (
        <></>
      ),
    },
    {
      key: TABS_SLIDER_ENUM.RESULTS,
      label: t`Results`,
      content: userSurvey ? <UserSurveyResults userSurvey={userSurvey} /> : <></>,
    },
  ];

  return (
    <Fragment>
      <DashboardHeader
        title={<Trans>Survey’s</Trans>}
        onBack={async () => {
          goBack();
        }}
        actions={
          <Actions>
            {isShowRedirectToAnswersButton && (
              <ActionItemBlock>
                <Button
                  label={surveyTaskLabel || ''}
                  size={ButtonSize.MEDIUM}
                  variant={ButtonVariant.TEXT_PRIMARY}
                  isLoading={isLoading}
                  onClick={() => {
                    routes.SURVEY_TASK.go(
                      { role: ROLES.USER },
                      { surveyTaskId: userSurvey.surveyTaskId, isBackPath: true },
                    );
                  }}
                />
              </ActionItemBlock>
            )}
          </Actions>
        }
      />
      {/* @ts-ignore */}
      <ShowSpinnerIfLoading loading={isLoading}>
        {isModuleSurveyEnabled && userSurvey && <TabSlider items={TAB_SLIDER_ITEMS} />}
        {isModuleSurveyEnabled && !userSurvey && <NoAccess />}
      </ShowSpinnerIfLoading>
    </Fragment>
  );
};

export { UserSurveyDashboard };
