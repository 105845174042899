import React from 'react';

import { QUESTION_TYPES, KPI_NONE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { useToasts, TOAST_TYPES } from '~/components/Toast';
import QuestionCard from '~/pages/ReviewTemplateSetup/components/QuestionCard';
import SectionCard from '~/pages/ReviewTemplateSetup/components/SectionCard';

import { REVIEW_SECTION_THEMES_STYLES } from '~/constants/reviews';

const Container = styled.div`
  // min-height: 40px;
`;

const DragContainer = styled.div`
  margin-bottom: 16px;
`;

const Section = ({
  section,
  $template,
  isActive,
  sectionIndex,
  activeQuestionId,
  openQuestionModal,
  dragProps,
  availableSectionKpis,
  openRateScaleModal,
  skillLabels,
}) => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();

  const kpisOptions = availableSectionKpis.map((kpi) => {
    return {
      label: kpi.name,
      action: () => $template.changeSectionType(section.id, kpi),
    };
  });

  const sectionThemeOptions = Object.values(REVIEW_SECTION_THEMES_STYLES).map((theme) => {
    return {
      label: theme.title(i18n),
      action: () => $template.setSectionTheme(section.id, theme.key, sectionIndex),
    };
  });

  const getThemeLabel = () => {
    const selectedTheme = Object.values(REVIEW_SECTION_THEMES_STYLES).find(
      (t) => t.key === section.theme,
    );
    return selectedTheme.title(i18n);
  };

  const isShowErrors = $template.isShowErrors.value;
  const kpi =
    $template.kpis.find((kpi) => kpi.id === section.kpi && !kpi.isDeleted) ||
    $template.kpis.find((kpi) => kpi.id === null);

  const isNoneKpi = section.type === KPI_NONE.type && section.kpi === KPI_NONE.id;

  return (
    <>
      <SectionCard
        isActiv={isActive}
        changeActiveSection={() => $template.updateActiveSectionId(section.id)}
        section={section}
        $template={$template}
        kpi={kpi}
        themeLabel={getThemeLabel()}
        sectionIndex={sectionIndex}
        sectionThemeOptions={sectionThemeOptions}
        dragProps={dragProps}
        kpisOptions={kpisOptions}
        openRateScaleModal={() => openRateScaleModal(section.id)}
        isShowErrors={isShowErrors}
      />
      {isActive && (
        <Droppable type="question" droppableId={section.id} ignoreContainerClipping>
          {(provided) => (
            <Container {...provided.droppableProps} ref={provided.innerRef}>
              {(section.questions || []).map((question, idx) => {
                return (
                  <Draggable key={question.id} draggableId={question.id} index={idx}>
                    {(provided) => (
                      <DragContainer ref={provided.innerRef} {...provided.draggableProps}>
                        <QuestionCard
                          key={question.id}
                          dragProps={provided.dragHandleProps}
                          question={question}
                          handleQuestionRating={(hideRating) =>
                            $template.updateQuestion({ hideRating }, question.id, section.id)
                          }
                          updateName={(name) =>
                            $template.updateQuestion({ name }, question.id, section.id)
                          }
                          updateDescription={(desc) =>
                            $template.updateQuestion({ description: desc }, question.id, section.id)
                          }
                          isNoneSectionType={isNoneKpi}
                          sectionScaleLabels={section.scaleLabels}
                          isActive={activeQuestionId === question.id}
                          deleteQuestion={() => $template.deleteQuestion(question.id, section.id)}
                          updateQuestion={() => openQuestionModal(question, section.id)}
                          changeActiveQuestion={() =>
                            $template.updateActiveQuestionId(question.id, section.id)
                          }
                          resetActiveQuestion={() => $template.resetActiveQuestion()}
                          deleteScaleLabel={(labelIndex) =>
                            $template.deleteScaleLabel({
                              labelIndex,
                              questionId: question.id,
                              sectionId: section.id,
                            })
                          }
                          isCommentObligated={question.isCommentObligated}
                          isAverageQuestionEnabled={question.isAverageQuestionEnabled}
                          toggleSkillCategories={(categories) =>
                            $template.toggleSkillCategories(question.id, section.id, categories)
                          }
                          toggleIsCommentObligated={() =>
                            $template.toggleIsCommentObligated(question.id, section.id)
                          }
                          toggleIsAverageQuestionEnabled={() =>
                            $template.toggleIsAverageQuestionEnabled(question.id, section.id)
                          }
                          isSkippable={question.isSkippable}
                          toggleIsSkippable={() =>
                            $template.toggleIsSkippable(question.id, section.id)
                          }
                          addScaleLabel={() => $template.addScaleLabel(question.id, section.id)}
                          updateScaleLabel={(value, labelIndex) =>
                            $template.updateScaleLabel({
                              value,
                              labelIndex,
                              questionId: question.id,
                              sectionId: section.id,
                            })
                          }
                          skillLabels={skillLabels}
                          isShowErrors={isShowErrors}
                          copyQuestion={
                            question.type === QUESTION_TYPES.CUSTOM
                              ? () => {
                                  addToast({
                                    title: i18n._(t`Question has been copied`),
                                    subtitle: i18n._(
                                      t`You can find the newly added question at the bottom of the section.`,
                                    ),
                                    type: TOAST_TYPES.INFO,
                                  });
                                  $template.copyCustomQuestion(question, section.id);
                                }
                              : undefined
                          }
                          copyTooltip={
                            question.type === QUESTION_TYPES.CUSTOM && i18n._(t`Copy this question`)
                          }
                        />
                      </DragContainer>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Container>
          )}
        </Droppable>
      )}
    </>
  );
};

Section.propTypes = {
  $template: PropTypes.object,
};

export default React.memo(Section);
