import React, { useState } from 'react';

import { ACTIVITY_PROGRESS_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import propTypes from 'prop-types';

import Button from '~/components/Button';
import { EditLibraryActivityModal } from '~/components/Modals/EditLibraryActivityModal';

import useBoolState from '~/hooks/useBoolState';

const NEW_ACTIVITY_DEFAULT = {};

const CreateActivityModule = ({ onActivityCreated }) => {
  const { i18n } = useLingui();
  const $isEditLibraryActivityModal = useBoolState(false);
  const [newActivity, setNewActivity] = useState(NEW_ACTIVITY_DEFAULT);

  const openEditLibraryActivityModal = () => {
    const { currentRole, user, selectedCompany } = this.props;

    const newActivity = {
      isAvailableInUserLibrary: true,
      isRateAvailable: true,
      createdInRole: currentRole,
      createdBy: user.id,
      company: selectedCompany,
      progressType: ACTIVITY_PROGRESS_TYPES.IS_DONE,
      progressDetails: {
        min: null,
        max: null,
        currency: null,
      },
    };

    setNewActivity(newActivity);
    $isEditLibraryActivityModal.on();
  };

  const hideEditLibraryActivityModal = () => {
    $isEditLibraryActivityModal.off();
    setNewActivity(NEW_ACTIVITY_DEFAULT);
  };

  const onSaveActivity = (activity) => {
    if (onActivityCreated) {
      onActivityCreated(activity);
    }
    hideEditLibraryActivityModal();
  };

  return (
    <>
      <Button
        label={i18n._(t`Create new activity`)}
        onClick={openEditLibraryActivityModal}
        type="primary-border"
      />

      {$isEditLibraryActivityModal.value && (
        <EditLibraryActivityModal
          currentActivity={newActivity}
          onHideModal={hideEditLibraryActivityModal}
          onSaveActivity={onSaveActivity}
        />
      )}
    </>
  );
};

CreateActivityModule.propTypes = {
  onActivityCreated: propTypes.func,
};

export default CreateActivityModule;
