import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import IconButton from '~/components/IconButton';
import DeleteIcon from '~/components/Icons/Delete';
import { TextArea } from '~/components/Text';

import { COLORS } from '~/styles';

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

const StyledTextField = styled(TextArea)`
  height: 48px;
  border: solid 1px #ccced5;
  width: 660px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  outline: none;
  color: ${COLORS.TEXT_SECONDARY};
`;

const FocusArea = ({ name = '', level, onDelete, onUpdateFocusArea }) => {
  const [text, setText] = useState(name);

  useEffect(() => {
    setText(name);
  }, [name]);

  const handleDelete = () => onDelete(level);

  const handleChange = (e) => setText(e.target.value);

  const onBlur = (e) => e.target.value !== name && onUpdateFocusArea(text);

  return (
    <Box>
      <StyledTextField value={text} onChange={handleChange} onBlur={onBlur} />
      <IconButton size={24} onClick={handleDelete} noBorder disabled={!text}>
        <DeleteIcon size={24} />
      </IconButton>
    </Box>
  );
};

export default FocusArea;
