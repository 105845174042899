import { MissingAverageRatingReason } from '@learned/constants';
import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { ICONS } from '~/components/Icon';

import type { I18n } from '@lingui/core';

const isValueAllowed = (
  value: number | null,
  selectedItems: string[],
): number | null | undefined => {
  if (value === null || value === undefined) {
    return value;
  }
  if (value >= 80) {
    if (!selectedItems.includes('80-100')) {
      value = null;
    }
  } else if (value >= 60) {
    if (!selectedItems.includes('60-79')) {
      value = null;
    }
  } else if (value >= 40) {
    if (!selectedItems.includes('40-59')) {
      value = null;
    }
  } else if (value >= 20) {
    if (!selectedItems.includes('20-39')) {
      value = null;
    }
  } else if (value >= 0) {
    if (!selectedItems.includes('0-19')) {
      value = null;
    }
  } else {
    value = null;
  }

  return value;
};

const checkEmptyData = <T>({
  data,
  column,
  field,
  skipColumns,
  reportingThreshold,
  i18n,
}: {
  data: T[];
  column: string;
  field: string;
  skipColumns: string[];
  reportingThreshold: number;
  i18n: I18n;
}) => {
  if (!isEmpty(data)) {
    let isEmpty = true;

    map(data, (row) => {
      // @ts-ignore
      map(row?.[column], (item) => {
        // @ts-ignore
        if (!skipColumns.includes(item?.[field]) && item.value && item?.value?.[0] !== null) {
          isEmpty = false;
        }
      });
    });

    if (isEmpty) {
      // @ts-ignore
      switch (data[0]?.[column]?.[0]?.value?.[0]) {
        case MissingAverageRatingReason.THRESHOLD_NOT_MET:
          return {
            title: i18n._(
              t`Threshold not met yet. Needs a minimum of ${reportingThreshold} answers`,
            ),
            icon: ICONS.LOCK,
            isVisible: true,
          };
        default:
        case MissingAverageRatingReason.NOT_SHARED:
          return {
            title: i18n._(t`Results are not yet shared`),
            icon: ICONS.TIME,
            isVisible: true,
          };
      }
    }
  }
};

export { isValueAllowed, checkEmptyData };
