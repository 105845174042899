import React from 'react';

import { Trans } from '@lingui/macro';
import moment from 'moment/moment';
import styled from 'styled-components';

import ConversationForTimeline from '~/components/Icons/ConversationForTimeline';
import RoundCheckBoxDeselected from '~/components/Icons/RoundCheckBoxDeselected';
import RoundCheckBoxSelected from '~/components/Icons/RoundCheckBoxSelected';

import DateIcon from '~/assets/calendar-1@3x.png';

import { COLOR_PALETTE } from '~/styles';

const StartDate = styled.div`
  min-width: 32px;
  max-width: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 11px;
  margin-left: 12px;
`;

const StartMonth = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const StartYear = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const TimelineItem = styled.div`
  align-items: center;
  display: flex;
  z-index: 10;

  img {
    margin-left: 2px;
  }
`;

const TasksTimelineDateItem = ({ isDone, itemDate, isViewReport }) => (
  <TimelineItem>
    {isViewReport ? (
      <ConversationForTimeline />
    ) : isDone ? (
      <RoundCheckBoxSelected />
    ) : (
      <RoundCheckBoxDeselected />
    )}
    <StartDate>
      {itemDate && (
        <>
          <StartMonth>{moment(itemDate).format('DD')}</StartMonth>
          <StartYear>{moment(itemDate).format('MMM')}</StartYear>
        </>
      )}
      {isViewReport && !itemDate && (
        <img src={DateIcon} alt={<Trans>No date</Trans>} height="24px" width="24px" />
      )}
    </StartDate>
  </TimelineItem>
);

export default TasksTimelineDateItem;
