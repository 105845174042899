import React, { useState } from 'react';

import { ProductName, ProductStatus } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import Modal from '~/components/Modal';
import Switch from '~/components/Switch';
import { dateDiff } from '~/pages/CompanySubscriptions';

import { PRODUCTS_WITH_NAMES_UI } from '~/constants/products';
import { getCompanySuperAdmin, updateAddons, updateSuperAdminProducts } from '~/services/companies';
import { COLORS } from '~/styles';
import getQuickStartTotalProgress from '~/utils/getQuickStartTotalProgress';

const TrialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.WHITE};
`;

const TrialRow = styled.div`
  display: flex;
  align-items: center;
`;

const TrialText = styled.div`
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
  margin-right: 16px;
`;

const SectionName = styled.div``;

const ModuleRow = styled.div`
  display: flex;
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.WHITE};
  align-items: center;
`;

const RowText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLORS.TEXT_MAIN};
  flex: 1;
`;

const StyledDropdown = styled(Dropdown)`
  margin-left: 16px;
`;

const CompanyModulesModal = ({
  i18n,
  onClose,
  modalCompany,
  setAllCompanies,
  allCompanies,
  quickStartProgress,
}) => {
  const [currentCompany, setCurrentCompany] = useState({ ...modalCompany });
  const [companyProducts, setCompanyProducts] = useState({ ...modalCompany.products });

  const save = async () => {
    const updates = [];

    // update addons
    if (!isEqual(currentCompany.addons, modalCompany.addons)) {
      updates.push(updateAddons(modalCompany.id, currentCompany.addons));
    }

    // update products
    if (!isEqual(currentCompany.products, companyProducts)) {
      let productsToUpdate = {};
      for (const key in companyProducts) {
        if (
          currentCompany.products[key].status !== companyProducts[key].status ||
          currentCompany.products[key].lastStatusChanged !== companyProducts[key].lastStatusChanged
        ) {
          productsToUpdate[key] = {
            status: companyProducts[key].status,
          };
        }
      }
      updates.push(updateSuperAdminProducts(modalCompany.id, productsToUpdate));
    }

    await Promise.all(updates);
    const updatedCompany = await getCompanySuperAdmin(modalCompany.id);

    const qs = Object.values(quickStartProgress).find(
      (quickStart) => modalCompany.id === quickStart.company,
    );

    if (qs) {
      updatedCompany.quickStartProgress = getQuickStartTotalProgress(qs);
    }

    setAllCompanies({ ...allCompanies, [updatedCompany.id]: updatedCompany });
    onClose();
  };

  const onResetProduct = (product) => {
    setCompanyProducts((prevState) => ({
      ...prevState,
      [product]: {
        ...companyProducts[product],
        status: ProductStatus.TRIAL_ACTIVE,
        lastStatusChanged: new Date(),
      },
    }));
  };

  const onChangeProductStatus = (newStatus, product) => {
    setCompanyProducts((prevState) => ({
      ...prevState,
      [product]: {
        ...(companyProducts[product] ? companyProducts[product] : {}),
        status: newStatus,
        lastStatusChanged: new Date(),
      },
    }));
  };

  const toggleAddonsField = (fieldName) => {
    setCurrentCompany({
      ...currentCompany,
      addons: {
        ...currentCompany.addons,
        [fieldName]: !currentCompany.addons?.[fieldName],
      },
    });
  };

  const products = PRODUCTS_WITH_NAMES_UI.map((product) => {
    const companyProduct = companyProducts[product.key];
    return {
      ...product,
      status: companyProduct?.status || ProductStatus.TRIAL_NOT_STARTED,
      daysToEnd: dateDiff(companyProduct?.lastStatusChanged),
      onReset: () => onResetProduct(product.key),
      onStatusChange: (newStatus) => onChangeProductStatus(newStatus, product.key),
    };
  });

  const addons = [
    {
      name: (i18n) => i18n._(t`Talent`),
      isEnabled: currentCompany.addons?.[ProductName.TALENT],
      onChange: () => toggleAddonsField(ProductName.TALENT),
    },
    {
      name: (i18n) => i18n._(t`Onboard & Learn`),
      isEnabled: currentCompany.addons?.[ProductName.LEARNING_AND_ONBOARDING],
      onChange: () => toggleAddonsField(ProductName.LEARNING_AND_ONBOARDING),
    },
  ];

  return (
    <Modal
      onClose={onClose}
      title={i18n._(t`${modalCompany.name} - Manage modules`)}
      footerRight={<Button label={i18n._(t`Save`)} type="primary" onClick={save} />}
    >
      {/* PRODUCTS */}
      <SectionName>
        <Trans>Products</Trans>
      </SectionName>
      {products.map((product) => (
        <>
          <TrialContainer key={product.key}>
            <TrialRow>
              <RowText>{product.name(i18n)}</RowText>
            </TrialRow>
            <TrialRow>
              {product.status === ProductStatus.TRIAL_ACTIVE && (
                <TrialText>{i18n._(t`${product.daysToEnd} days left`)}</TrialText>
              )}
              {[ProductStatus.TRIAL_ACTIVE, ProductStatus.TRIAL_ENDED].includes(product.status) && (
                <Button
                  variant={ButtonVariant.SECONDARY}
                  size={ButtonSize.MEDIUM}
                  label={i18n._(t`Reset`)}
                  onClick={product.onReset}
                />
              )}
              <StyledDropdown
                items={Object.values(ProductStatus)}
                selectedItem={product.status}
                placeholder={i18n._(t`Status`)}
                onChange={product.onStatusChange}
                isSingleSelect
              />
            </TrialRow>
          </TrialContainer>
        </>
      ))}

      {/* ADDONS */}
      <SectionName>
        <Trans>Addons (if disabled - company does not see the product)</Trans>
      </SectionName>
      {addons.map((addon, index) => (
        <>
          <ModuleRow key={index}>
            <RowText>
              <Trans>{addon.name(i18n)}</Trans>
            </RowText>
            <Switch checked={addon.isEnabled} onChange={addon.onChange} />
          </ModuleRow>
        </>
      ))}
    </Modal>
  );
};

export default withI18n()(CompanyModulesModal);
