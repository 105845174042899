import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { ICONS, Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

import { EExportOptions } from '../types';

const Container = styled.div`
  position: absolute;
  right: 0;
  bottom: -2px;
  transform: translateY(100%);
  min-width: 300px;
  z-index: 100;
  cursor: pointer;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  background: green;
  padding: 14px;
  border-radius: 8px;
  background: ${COLORS.WHITE};
  box-shadow: 0 2px 5px 0 rgba(30, 8, 99, 0.21);
  gap: 16px;
  &:hover {
    background: ${COLORS.INACTIVE};
  }
`;

const IconCtr = styled.div<{ type: string }>`
  color: ${COLORS.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-image: ${({ type }) =>
    type === 'csv'
      ? 'linear-gradient(45deg, #3c0bff 1%, #7e5eff 98%)'
      : 'linear-gradient(45deg, #ff4444 1%, #fc7777 98%)'};
  border-radius: 8px;
  & > svg {
    box-sizing: border-box;
    width: 14px;
  }
`;

const TextCtr = styled.button`
  all: unset;
  font-size: 12px;
`;

const Topic = styled.div`
  color: ${COLORS.ICONS_PRIMARY};
  font-weight: 800;
`;

const Description = styled.div`
  color: ${COLORS.SUBTEXT};
`;

type TProps = {
  onMenuChange: (tye: EExportOptions) => void;
  reference: React.RefObject<HTMLDivElement>;
  exportOptionArr: EExportOptions[];
};
function ExportDropDown({ onMenuChange, reference, exportOptionArr }: TProps): JSX.Element | null {
  const { i18n } = useLingui();
  if (exportOptionArr.length === 0) {
    return null;
  }
  return (
    <Container ref={reference}>
      {exportOptionArr.includes(EExportOptions.csv) && (
        <MenuItem>
          <IconCtr type="csv">
            <Icon icon={ICONS.CSV} fill={COLORS.WHITE} />
          </IconCtr>
          <TextCtr onClick={() => onMenuChange(EExportOptions.csv)}>
            <Topic>{i18n._(t`Export as CSV`)}</Topic>
            <Description>{i18n._(t`Ready to save or print`)}</Description>
          </TextCtr>
        </MenuItem>
      )}
      {exportOptionArr.includes(EExportOptions.pdf) && (
        <MenuItem>
          <IconCtr type="pdf">
            <Icon icon={ICONS.PDF} fill={COLORS.WHITE} />
          </IconCtr>
          <TextCtr onClick={() => onMenuChange(EExportOptions.pdf)}>
            <Topic>{i18n._(t`Export as PDF`)}</Topic>
            <Description>{i18n._(t`Ready to save or print`)}</Description>
          </TextCtr>
        </MenuItem>
      )}
    </Container>
  );
}

export default ExportDropDown;
