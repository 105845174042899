import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Dialog from '~/components/Dialog';
import Divider from '~/components/UI/Divider';

import { COLORS } from '~/styles';

import { Button, ButtonVariant } from '../Buttons';

const HeaderRight = styled.div`
  display: flex;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  margin: 0;
  overflow-y: auto;
  border-bottom-left-radius: ${(props) => (props.$hideFooter ? '10px' : 'initial')};
  border-bottom-right-radius: ${(props) => (props.$hideFooter ? '10px' : 'initial')};
`;

const ModalFooter = styled.div`
  padding: 16px;
  display: flex;
  box-sizing: border-box;
  justify-content: ${(props) => (props.$isLeftRight ? 'space-between' : 'flex-end')};
  align-items: center;
  min-height: 72px;
  height: 72px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: auto;
  z-index: 10;
  position: relative;

  .error {
    left: 48px;
  }

  &_full {
    justify-content: space-between;
  }
`;

const ModalFooterCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Header = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 64px;
  padding: 12px 24px 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_BLACK};
  z-index: 10;
  .modal_header_steps {
    position: absolute;
    right: 70px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Description = styled.div`
  padding: 0 0 16px 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_SECONDARY};
`;

class Modal extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.any,
    greyDescription: PropTypes.any,
    footerLeft: PropTypes.node,
    footerRight: PropTypes.node,
    footerCenter: PropTypes.node,
    headerSteps: PropTypes.node,
    onClose: PropTypes.func,
    borderRadius: PropTypes.number,
    width: PropTypes.number,
    minWidth: PropTypes.number,
    minHeight: PropTypes.string,
    hideFooter: PropTypes.bool,
    isHideHeader: PropTypes.bool,
    marginTop: PropTypes.string,
    headerStyles: PropTypes.object,
    greyDescriptionStyles: PropTypes.object,
    contentStyles: PropTypes.object,
    footerStyles: PropTypes.object,
    headerActions: PropTypes.func,
    hideHeaderClose: PropTypes.bool,
    isCloseOnOutsideClick: PropTypes.bool,
    showDivider: PropTypes.bool,
    boxShadow: PropTypes.string,
    backgroundColor: PropTypes.string,
    ignoreModalStyle: PropTypes.bool,
    disableClose: PropTypes.bool,
  };

  static defaultProps = {
    hideHeaderClose: false,
    isHideHeader: false,
    isCloseOnOutsideClick: false,
    showDivider: true,
    footerCenter: false,
    footerLeft: false,
    ignoreModalStyle: false,
    disableClose: false,
  };

  render() {
    const {
      onClose,
      title,
      greyDescription,
      greyDescriptionStyles,
      className,
      hideFooter,
      isHideHeader,
      marginTop,
      contentStyles,
      headerStyles,
      headerActions,
      headerSteps,
      hideHeaderClose,
      borderRadius = 4,
      width,
      minWidth,
      minHeight,
      height,
      maxHeight,
      children,
      isCloseOnOutsideClick,
      showDivider,
      footerLeft,
      footerCenter,
      footerRight,
      footerStyles,
      boxShadow,
      dividerColor,
      centerModal,
      backgroundColor,
      ignoreModalStyle,
      disableClose,
    } = this.props;

    return (
      <Dialog
        className={className}
        onClose={onClose}
        width={width}
        minWidth={minWidth}
        minHeight={minHeight}
        height={height}
        maxHeight={maxHeight}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        isCloseOnOutsideClick={isCloseOnOutsideClick}
        marginTop={marginTop}
        centerModal={centerModal}
        backgroundColor={backgroundColor}
        ignoreModalStyle={ignoreModalStyle}
      >
        {!isHideHeader && (
          <Header style={headerStyles}>
            {title}
            <HeaderRight>
              {headerActions && <div>{headerActions()}</div>}
              <div className="modal_header-steps">{headerSteps}</div>
            </HeaderRight>
            {!hideHeaderClose && (
              <Button onClick={onClose} variant={ButtonVariant.CLOSE} disabled={disableClose} />
            )}
          </Header>
        )}
        {greyDescription && (
          <Description style={greyDescriptionStyles}>{greyDescription}</Description>
        )}
        {showDivider && <Divider $color={dividerColor} />}
        <ModalContent style={contentStyles} $hideFooter={hideFooter}>
          {children}
        </ModalContent>
        {showDivider && <Divider $color={dividerColor} />}
        {!hideFooter && (
          <Fragment>
            <ModalFooter
              $isLeftRight={!footerCenter && footerLeft && footerRight}
              style={footerStyles}
            >
              <div>{footerLeft}</div>
              <div>{footerRight}</div>
              <ModalFooterCenter>{footerCenter}</ModalFooterCenter>
            </ModalFooter>
          </Fragment>
        )}
      </Dialog>
    );
  }
}

export default Modal;
