import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import Switch from '~/components/Switch';
import { Block, Text, Title } from '~/pages/ReviewTemplateSetup/components/StyledComponents';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;

  .rc-switch {
    margin-right: 0;
  }
`;

function AverageQuestionSetup({ toggleIsAverageQuestionEnabled, isAverageQuestionEnabled }) {
  return (
    <Block>
      <Row>
        <Title>
          <Trans>Average Question</Trans>
        </Title>
        <Switch onChange={toggleIsAverageQuestionEnabled} checked={isAverageQuestionEnabled} />
      </Row>
      <Text>
        <Trans>
          Allow to manually set the average business goal performance. The question also shows if
          participant does not have any goals.
        </Trans>
      </Text>
    </Block>
  );
}

export { AverageQuestionSetup };
