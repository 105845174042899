import React, { useEffect } from 'react';

import { ROLES, SIGNATURE_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import AvatarCard from '~/components/UI/AvatarCard';
import LockedHint from '~/pages/ReviewUserPage/components/LockedHint';

import SignatureBlock from './SignatureBlock';
import SignaturePlaceholder from './SignaturePlaceholder';
import SignModal from './SignModal';

import useBoolState from '~/hooks/useBoolState';
import { getSelectedRole, getUser } from '~/selectors/baseGetters';
import getAllUsers from '~/selectors/getAllUsers';
import { COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const SignCardWrapper = styled.div`
  border: 1px solid ${COLORS.BG_PAGE};
  border-radius: 6px;
`;

const SignHeader = styled.div`
  background: ${COLORS.BG_PAGE};
  padding: 13px 15px;
  display: flex;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const SignatureButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
`;

const SignCard = ({
  view,
  userId,
  digitalSign,
  userSignature,
  status,
  onSign,
  isDigitalSignModal,
  onCloseDigitalSignModal,
  pdfView,
  isLocked,
  isActionDisabled,
  actionTooltip,
}) => {
  const { i18n } = useLingui();
  const currentRole = useSelector(getSelectedRole);
  const viewer = useSelector(getUser);
  const allUsers = useSelector(getAllUsers);
  const $isSignModal = useBoolState(isDigitalSignModal || false);

  useEffect(() => {
    if (isDigitalSignModal) {
      $isSignModal.on();
    }
  }, [isDigitalSignModal, $isSignModal]);

  const user = allUsers[userId];
  const userFullName = getUserFullName(user);
  const isOwner = userId === viewer.id && [ROLES.USER, ROLES.COACH].includes(currentRole);
  const isUserViewer = view === ROLES.USER && isOwner;

  const onModalClose = () => {
    $isSignModal.off();
    if (isDigitalSignModal && onCloseDigitalSignModal) {
      onCloseDigitalSignModal();
    }
  };

  const getSignatureBlock = (labels, isUser) => {
    switch (status) {
      case SIGNATURE_STATUSES.SIGNED:
        return renderSignature();
      case SIGNATURE_STATUSES.WAITING:
        return isUser && isOwner && !pdfView ? (
          renderSignature(true)
        ) : (
          <SignaturePlaceholder subTitle={labels.subTitle[status]} />
        );
      case SIGNATURE_STATUSES.ACTIVE:
        return isOwner && !pdfView ? (
          renderSignature()
        ) : (
          <SignaturePlaceholder subTitle={labels.subTitle[status]} />
        );
      default:
        return null;
    }
  };

  const getCoachSignatureBlock = () => {
    const labels = {
      subTitle: {
        [SIGNATURE_STATUSES.WAITING]: i18n._(
          t`${userFullName} has not completed the conversation.`,
        ),
        [SIGNATURE_STATUSES.ACTIVE]: i18n._(t`${userFullName} has not completed the conversation.`),
      },
    };

    return getSignatureBlock(labels);
  };

  const getUserSignatureBlock = () => {
    const labels = {
      subTitle: {
        [SIGNATURE_STATUSES.WAITING]: isUserViewer
          ? i18n._(
              t`You can complete your conversation after all the participants have completed it.`,
            )
          : i18n._(t`${userFullName} waiting for participants to complete the conversation.`),
        [SIGNATURE_STATUSES.ACTIVE]: i18n._(t`${userFullName} has not completed the conversation.`),
      },
    };

    return getSignatureBlock(labels, true);
  };

  const renderSignature = (isUserWaiting) => {
    return userSignature ? (
      <SignatureBlock view={view} digitalSign={digitalSign} userSignature={userSignature} />
    ) : (
      <SignatureButton>
        <Button
          label={i18n._(t`Complete`)}
          onClick={$isSignModal.on}
          disabled={isLocked || isUserWaiting || isActionDisabled}
          styles={{
            height: '48px',
            minWidth: '112px',
          }}
          tooltip={
            (isUserWaiting &&
              i18n._(
                t`You can complete your conversation when all the participants have completed the conversation.`,
              )) ||
            (isActionDisabled && actionTooltip)
          }
        />
      </SignatureButton>
    );
  };

  return (
    <SignCardWrapper>
      <SignHeader>
        <AvatarCard userId={userId} type={AvatarCard.TYPES.MEDIUM} />
        {isLocked && (
          <LockedHint
            tooltip={i18n._(
              t`It is no longer possible to complete the conversation. Contact your HR manager for support.`,
            )}
          />
        )}
      </SignHeader>
      <div>
        {view === ROLES.COACH && getCoachSignatureBlock()}
        {view === ROLES.USER && getUserSignatureBlock()}
      </div>
      {$isSignModal.value && !userSignature && isOwner && (
        <SignModal
          view={view}
          userId={userId}
          onClose={onModalClose}
          onSubmit={onSign}
          digitalSign={digitalSign}
        />
      )}
    </SignCardWrapper>
  );
};

SignCard.propTypes = {
  view: PropTypes.oneOf([ROLES.USER, ROLES.COACH]),
  userId: PropTypes.string,
  digitalSign: PropTypes.shape({
    isAgreeSeen: PropTypes.bool,
    isAgreeContent: PropTypes.bool,
    isUserComment: PropTypes.bool,
    isCoachComment: PropTypes.bool,
  }),
  userSignature: PropTypes.shape({
    role: PropTypes.oneOf([ROLES.USER, ROLES.COACH]),
    userId: PropTypes.string,
    signature: PropTypes.string,
    isSeen: PropTypes.bool,
    isAgree: PropTypes.bool,
    comment: PropTypes.string,
    signatureDate: PropTypes.string,
  }),
  status: PropTypes.oneOf(Object.values(SIGNATURE_STATUSES)),
  onSign: PropTypes.func,
  isLocked: PropTypes.bool,
};

export default SignCard;
