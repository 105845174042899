import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import SelectSkills from '~/components/SelectSkillsModal/SelectSkills';

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  color: #3b3b3b;
`;

const INITIAL_STATE = {
  loading: false,
  selectedSkills: [],
  selectedLevels: {},
};

class AddSkillModalNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedSkills: props.selectedSkills || [],
      selectedLevels: props.selectedLevels || {},
    };
  }

  static propTypes = {
    selectedSkills: PropTypes.array,
    selectedLevels: PropTypes.object,
    isIncludeSuggestedSkills: PropTypes.bool,
    isShowPreloadedSkills: PropTypes.bool,
    isCategoriesFilter: PropTypes.bool,
    preSelectedCategories: PropTypes.array,
  };

  UNSAFE_componentWillReceiveProps = (props) => {
    const { selectedSkills } = this.state;
    const updateState = {};

    if (isEmpty(selectedSkills) && !isEmpty(props.reviewSkills)) {
      updateState.selectedSkills = props.reviewSkills;
    }

    if (!isEmpty(updateState)) {
      this.setState({ ...updateState });
    }
  };

  closeModal = () => {
    this.props.onModalClose();
    this.setState({ ...INITIAL_STATE });
  };

  addSkillsToReview = async () => {
    const { onAddSkill } = this.props;
    const { selectedSkills, selectedLevels } = this.state;
    this.setState({ loading: true });
    if (onAddSkill) {
      await onAddSkill({ selectedSkills, selectedSkillsLevels: selectedLevels });
    }
    this.setState({ loading: false });
    this.closeModal();
  };

  toggleSkill =
    (skill) =>
    (e, { level, isUpdate } = {}) => {
      e.stopPropagation();
      const { selectedSkills, selectedLevels } = this.state;

      if (isUpdate) {
        // update level in selectedSkill
        let newSelectedLevels = selectedLevels ? { ...selectedLevels } : {};
        newSelectedLevels[skill.id] = level;
        this.setState({
          selectedLevels: newSelectedLevels,
        });
      } else {
        // update selectedSkills
        let newSelectedSkills = selectedSkills ? [...selectedSkills] : [];
        let newSelectedLevels = selectedLevels ? { ...selectedLevels } : {};
        let index = newSelectedSkills.map((u) => u.id).indexOf(skill.id);
        if (index !== -1) {
          newSelectedSkills = newSelectedSkills.filter((_, i) => i !== index);
          delete newSelectedLevels[skill.id];
        } else {
          newSelectedSkills.push(skill);
          newSelectedLevels[skill.id] = level;
        }
        this.setState({
          selectedSkills: newSelectedSkills,
          selectedLevels: newSelectedLevels,
        });
      }
    };

  render() {
    const {
      i18n,
      canSaveEmpty,
      withoutLevels,
      hiddenSkills,
      preSelectedSkills,
      isIncludeSuggestedSkills,
      isShowPreloadedSkills,
      isHidePremiumSkillSet,
      isCategoriesFilter = true,
      preSelectedCategories,
    } = this.props;
    const { selectedSkills, loading, selectedLevels } = this.state;

    return (
      <Modal
        title={
          <Title>
            <Trans>Add skills</Trans>
          </Title>
        }
        onClose={this.closeModal}
        width={625}
        minWidth={625}
        minHeight="648px"
        height="648px"
        headerStyles={{ padding: '0px 20px' }}
        contentStyles={{ flex: 1, padding: '16px 20px' }}
        footerRight={
          <HeaderActions>
            <Button
              styles={{ marginLeft: '10px' }}
              label={i18n._(t`Save`)}
              type="primary-border"
              loading={loading}
              disabled={isEmpty(selectedSkills) && !canSaveEmpty}
              onClick={this.addSkillsToReview}
            />
          </HeaderActions>
        }
      >
        <SelectSkills
          selectedSkills={selectedSkills}
          preSelectedCategories={preSelectedCategories}
          hiddenSkills={hiddenSkills}
          selectedLevels={selectedLevels}
          toggleSkill={this.toggleSkill}
          withoutLevels={withoutLevels}
          preSelectedSkills={preSelectedSkills}
          isIncludeSuggestedSkills={isIncludeSuggestedSkills}
          isShowPreloadedSkills={isShowPreloadedSkills}
          isSkillSetFilter
          isCategoriesFilter={isCategoriesFilter}
          isHidePremiumSkillSet={isHidePremiumSkillSet}
        />
      </Modal>
    );
  }
}

export default withI18n()(AddSkillModalNew);
