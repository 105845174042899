import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  SURVEY_TEMPLATE_STATUSES,
  SURVEY_TEMPLATES_SORT_OPTIONS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TableList } from '~/components/TableList';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { CreationInProcessModal } from '~/pages/Surveys/creationInProcessModal';
import { TEMPLATES_COLUMNS } from '~/pages/Surveys/TemplatesOverview/templateColumns';

import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import {
  getSurveyTemplates,
  createSurveyTemplate,
  deleteSurveyTemplates,
} from '~/services/surveyTemplates';

import type { ISurveyTemplate } from '@learned/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

type IFilter = {
  search: string;
  status: SURVEY_TEMPLATE_STATUSES[] | [];
  sortBy: SURVEY_TEMPLATES_SORT_OPTIONS;
};

const initialFilters = {
  search: '',
  status: [],
  sortBy: SURVEY_TEMPLATES_SORT_OPTIONS.CREATED_NEW_OLD,
};

const TemplatesOverview = () => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const [surveyTemplates, setSurveyTemplates] = useState<ISurveyTemplate[] | []>([]);
  const [totalCount, setTotalCount] = useState(0);
  const { pagination, changePagination, resetPagination } = usePagination(10);
  const [currentFilters, setCurrentFilters] = useState<IFilter>(initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateTemplateLoading, setIsCreateTemplateLoading] = useState(false);
  const [selectedSurveyTemplates, setSelectedSurveyTemplates] = useState<string[]>([]);
  const [isDeletingTemplates, setIsDeletingTemplates] = useState(false);
  const debCurrentFilters = useDebounce(currentFilters, 300);

  const fetchTemplates = async (
    currentFilters: IFilter,
    pagination: { limit: number; skip: number; index: number },
  ) => {
    setIsLoading(true);
    const { data } = await getSurveyTemplates(
      {
        search: isEmpty(currentFilters.search) ? undefined : currentFilters.search,
      },
      {
        sortBy: currentFilters.sortBy,
        skip: pagination.skip,
        limit: pagination.limit,
      },
    );

    setSurveyTemplates(data[API_RETURN_FIELDS.SURVEY_TEMPLATES]);
    setTotalCount(data[API_RETURN_FIELDS.TOTAL]);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTemplates(currentFilters, pagination);

    // eslint-disable-next-line
  }, [pagination.limit, pagination.skip, debCurrentFilters]);

  const isAllSelected = surveyTemplates.every((template) => {
    return selectedSurveyTemplates.includes(template.id);
  });

  const onSelectAll = () => {
    const selectedTemplates = isAllSelected ? [] : surveyTemplates.map((template) => template.id);
    setSelectedSurveyTemplates(selectedTemplates);
  };

  const onSelectTemplate = (selectedTemplateId: string) => {
    const isSelected = selectedSurveyTemplates.includes(selectedTemplateId);
    setSelectedSurveyTemplates(
      isSelected
        ? selectedSurveyTemplates.filter((templateId) => templateId !== selectedTemplateId)
        : [...selectedSurveyTemplates, selectedTemplateId],
    );
  };

  const createMenuItems = (item: ISurveyTemplate) => {
    return [
      {
        label: i18n._(t`Edit`),
        action: async () => {
          const isConfirmed =
            item.status === SURVEY_TEMPLATE_STATUSES.draft ||
            (await confirm({
              type: CONFIRMATION_MODAL_TYPE.WARNING,
              title: i18n._(t`Be aware`),
              description: i18n._(
                t`Changing this template will affect all surveys that use this template`,
              ),
            }));
          if (isConfirmed) {
            routes.SURVEY_TEMPLATE_UPDATE.go({}, { surveyTemplateId: item.id, isBackPath: true });
          }
        },
        icon: ICONS.EDIT_PENCIL,
      },
      {
        label: i18n._(t`Delete`),
        action: async () => {
          const isConfirmed = await confirm({
            type: CONFIRMATION_MODAL_TYPE.DELETE,
            title: i18n._(t`Delete template?`),
            description: i18n._(
              t`Deleting this template will delete it from any survey that uses this template. This will require you to select a new template in these surveys. Deleting a template cannot be undone.`,
            ),
          });
          if (isConfirmed) {
            await deleteTemplate(item.id);
            addToast({ title: i18n._(t`Template deleted`), type: TOAST_TYPES.INFO });
          }
        },
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ];
  };

  const deleteTemplate = async (surveyTemplateId: string) => {
    setIsLoading(true);
    const result = await deleteSurveyTemplates([surveyTemplateId]);
    if (result.code === 200) {
      await fetchTemplates(currentFilters, { skip: 0, limit: pagination.limit, index: 1 });
      setSelectedSurveyTemplates([]);
    }
    resetPagination();
  };

  const deleteTemplates = async () => {
    setIsLoading(true);
    resetPagination();

    try {
      const result = await deleteSurveyTemplates(selectedSurveyTemplates);
      if (result.code === 200) {
        await fetchTemplates(currentFilters, { skip: 0, limit: pagination.limit, index: 1 });
        setSelectedSurveyTemplates([]);
        setIsDeletingTemplates(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createTemplate = async () => {
    setIsCreateTemplateLoading(true);
    const { data } = await createSurveyTemplate();
    setIsCreateTemplateLoading(false);

    if (!!data[API_RETURN_FIELDS.SURVEY_TEMPLATE] && data[API_RETURN_FIELDS.SURVEY_TEMPLATE]?.id) {
      routes.SURVEY_TEMPLATE_UPDATE.go(
        {},
        {
          surveyTemplateId: data[API_RETURN_FIELDS.SURVEY_TEMPLATE].id,
          isBackPath: true,
          query: { isCreatingNew: true },
        },
      );
    }
  };

  const actionButton = {
    label: t`Create new`,
    onClick: () => createTemplate(),
  };

  const filters = {
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value });
      resetPagination();
    },
  };

  const multiSelect = {
    checkedCount: selectedSurveyTemplates.length,
    onCheckAll: onSelectAll,
    onSelectItem: (template: ISurveyTemplate) => onSelectTemplate(template.id),
    isItemChecked: (template: ISurveyTemplate) => selectedSurveyTemplates.includes(template.id),
    isAllChecked: isAllSelected,
    onDelete: () => setIsDeletingTemplates(true),
  };

  const onNameClick = {
    column: 'name',
    onClick: (template: ISurveyTemplate) =>
      routes.SURVEY_TEMPLATE_VIEW.go({}, { surveyTemplateId: template.id, isBackPath: true }),
  };

  return (
    <Wrapper>
      {isCreateTemplateLoading && (
        <CreationInProcessModal title={i18n._(t`Creating a new template…`)} />
      )}
      <TableList
        data={surveyTemplates}
        columns={TEMPLATES_COLUMNS}
        onColClick={onNameClick}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: SURVEY_TEMPLATES_SORT_OPTIONS) =>
            setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        isDraftStatusVisible
        menuProps={{
          createMenuItems,
          isMenuVisible: true,
        }}
        multiSelectProps={{ isMultiSelectVisible: true, multiSelect }}
        paginationProps={{
          pagination,
          changePagination,
          totalCount,
        }}
        isLoading={isLoading}
        placeholderProps={{
          noResultText: i18n._(t`No templates found`),
          emptyStateText: i18n._(t`No templates yet… Let’s create one!`),
        }}
        actionButton={actionButton}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length || !!currentFilters.status.length,
          isToggleHideFilterVisible: false,
        }}
      />
      {isDeletingTemplates && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          title={i18n._(t`Delete templates?`)}
          description={i18n._(
            t`Deleting these templates will delete them from any survey that uses them as a template. This will require you to select a new template in these surveys. Deleting templates cannot be undone.`,
          )}
          onClose={() => setIsDeletingTemplates(false)}
          onSubmit={deleteTemplates}
        />
      )}
    </Wrapper>
  );
};

export { TemplatesOverview };
