import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Card, { ETypes, TScores, TChartData } from '~/components/Reports/DashboardCharts/Card';
import { CardWrapper } from '~/components/Reports/DashboardCharts/CardStyles';
import BaseLayout from '~/layouts/BaseLayout';
import { QuestionsTab } from '~/pages/SurveyDashboard/components/Results/QuestionsTab';

import routes from '~/constants/routes';
import { getUser, checkModuleSurvey } from '~/selectors/baseGetters';
import { getSurveyResultsThemesChart } from '~/services/surveys';
import { COLORS } from '~/styles';

import type { ISurvey } from '@learned/types';

interface SurveyResultProps {
  survey: ISurvey;
}

const HeaderCardWrapper = styled.div`
  margin-bottom: 20px;
`;

const HeaderViewReportWrapper = styled.div`
  margin-bottom: 20px;
  button {
    margin-left: auto;
  }
`;

export const TABS_ENUM = {
  TEAMS: 'teams',
  THEMES: 'themes',
  QUESTIONS: 'questions',
};

const SurveyResults = ({ survey }: SurveyResultProps) => {
  const user = useSelector(getUser);
  const { i18n } = useLingui();
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState<{
    average: number;
    series: TChartData[];
    themeRank: TScores;
  }>();

  const fetchData = async () => {
    if (survey.id && isModuleSurveyEnabled) {
      setIsLoading(true);

      const { data } = await getSurveyResultsThemesChart(survey.id);
      if (data && data[API_RETURN_FIELDS.SURVEY_RESULTS_THEMES]) {
        setChartData({
          themeRank: {
            bestScore: data[API_RETURN_FIELDS.SURVEY_RESULTS_THEMES].themeRank.top,
            lowestScore: data[API_RETURN_FIELDS.SURVEY_RESULTS_THEMES].themeRank.bottom,
          },
          average: data[API_RETURN_FIELDS.SURVEY_RESULTS_THEMES].averageSeries.average,
          series: data[API_RETURN_FIELDS.SURVEY_RESULTS_THEMES].averageSeries.series.map(
            (s: { date: string; value: number }) => ({ key: s.date, value: s.value }),
          ),
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <BaseLayout smallWidth>
      {user.isAdmin && (
        <HeaderCardWrapper>
          <HeaderViewReportWrapper>
            <Button
              variant={ButtonVariant.NAVIGATION_PRIMARY}
              label={i18n._(t`View Report`)}
              size={ButtonSize.MEDIUM}
              onClick={() => {
                routes.REPORTS_ADMIN_ENGAGEMENT.go(
                  // @ts-ignore
                  { role: ROLES.ADMIN },
                  { query: { survey: survey.id } },
                );
              }}
            />
          </HeaderViewReportWrapper>
          <CardWrapper>
            <Card
              title={i18n._(t`Average engagement`)}
              chartData={chartData?.series}
              average={chartData?.average}
              isLoading={isLoading}
            />
            <Card
              title={i18n._(t`Best scoring themes`)}
              type={ETypes.BAR_CHART}
              data={chartData?.themeRank?.bestScore}
              isLoading={isLoading}
            />
            <Card
              title={i18n._(t`Lowest scoring themes`)}
              type={ETypes.BAR_CHART}
              data={chartData?.themeRank?.lowestScore}
              oppositeGradient
              bColor={COLORS.CONFIRMATION_MODAL_DELETE}
              isLoading={isLoading}
            />
          </CardWrapper>
        </HeaderCardWrapper>
      )}
      <QuestionsTab />
    </BaseLayout>
  );
};

export { SurveyResults };
