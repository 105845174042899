import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import SkillReportPage from '~/pages/AdminReports/SkillReportPage';
import SkillsReportPage from '~/pages/AdminReports/SkillsReportPage';
import SkillSetsOverview from '~/pages/SkillSetsOverview';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('skills', {
  routesPrivate: {
    user: [],
    coach: [],
    admin: [
      <PrivateRoute
        key={routes.SKILLS.routePath(ROLES.ADMIN)}
        exact
        path={routes.SKILLS.routePath(ROLES.ADMIN)}
        component={SkillSetsOverview}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.SKILLS_REPORT.routePath(ROLES.ADMIN)}
        exact
        path={routes.SKILLS_REPORT.routePath(ROLES.ADMIN)}
        component={() => <SkillsReportPage isLatest={false} />}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.SKILL_REPORT.routePath(ROLES.ADMIN)}
        exact
        path={routes.SKILL_REPORT.routePath(ROLES.ADMIN)}
        component={() => <SkillReportPage isLatest={false} />}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
