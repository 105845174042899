import React from 'react';

import styled from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

import { SIDEBAR_ITEMS, SIDEBAR_ITEM_TYPES } from '../constants';

const IconCirle = styled.div<{
  colors: string;
  isMirrored?: boolean;
}>`
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.08);
  background-image: ${({ colors }) => `linear-gradient(to bottom, ${colors})`};
  transform: ${({ isMirrored }) => (isMirrored ? 'scaleX(-1)' : '')};
`;

const IconWrap = styled.div`
  display: flex;
  width: 40px;
`;

const SidebarRowIcon = ({ type }: { type: SIDEBAR_ITEM_TYPES }) => {
  const item = Object.values(SIDEBAR_ITEMS).find((i) => i.key === type);

  return (
    <IconWrap>
      {/* @ts-ignore */}
      {item?.icon ? (
        <IconCirle
          // @ts-ignore
          colors={item?.iconColors}
          isMirrored={item.key === SIDEBAR_ITEM_TYPES.NEXT_STEPS}
        >
          {/* @ts-ignore */}
          <Icon icon={item?.icon} size={item?.iconSize} color={COLORS.WHITE} />
        </IconCirle>
      ) : null}
    </IconWrap>
  );
};

export { SidebarRowIcon };
