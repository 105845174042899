import { ROLES } from '~/constants';
import { pageDashboard } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'user-surveys';
const defaultUserSurveyId = ':userSurveyId';

export const USER_SURVEY_DASHBOARD = new LinkConstructor(
  [ROLES.USER],
  ({ userSurveyId = defaultUserSurveyId }) => pageDashboard(routeName, { id: userSurveyId }),
);
