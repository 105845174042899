import React from 'react';

import OpenRoute from '~/components/OpenRoute';
import PrivateRoute from '~/components/PrivateRoute';
import LoginLayout from '~/layouts/LoginLayout';
import ReviewAdminPage from '~/pages/ReviewAdminPage';
import ReviewGiveFeedback from '~/pages/ReviewGiveFeedback';
import ReviewGiveFeedbackOutside from '~/pages/ReviewGiveFeedbackOutside';
import ReviewGiveFeedbackSelf from '~/pages/ReviewGiveFeedbackSelf';
import ReviewReportPDF from '~/pages/ReviewReportPDF';
import ReviewRequestReport from '~/pages/ReviewRequestReport';
import ReviewSetup from '~/pages/ReviewSetup';
import ReviewUserPage from '~/pages/ReviewUserPage';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('reviews', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.REVIEW.routePath(ROLES.USER)}
        exact
        path={routes.REVIEW.routePath(ROLES.USER)}
        component={ReviewUserPage}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.REVIEW_GIVE_FEEDBACK.routePath(ROLES.USER)}
        path={routes.REVIEW_GIVE_FEEDBACK.routePath(ROLES.USER)}
        component={ReviewGiveFeedback}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.REVIEW_GIVE_FEEDBACK_SELF.routePath(ROLES.USER)}
        exact
        path={routes.REVIEW_GIVE_FEEDBACK_SELF.routePath(ROLES.USER)}
        component={ReviewGiveFeedbackSelf}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.REVIEWS_INCOMING_REPORT.routePath(ROLES.USER)}
        exact
        path={routes.REVIEWS_INCOMING_REPORT.routePath(ROLES.USER)}
        component={ReviewRequestReport}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.REVIEW_CREATE.routePath(ROLES.USER)}
        exact
        path={routes.REVIEW_CREATE.routePath(ROLES.USER)}
        component={ReviewSetup}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.REVIEW_UPDATE.routePath(ROLES.USER)}
        exact
        path={routes.REVIEW_UPDATE.routePath(ROLES.USER)}
        component={ReviewSetup}
        allowedRoles={[ROLES.USER]}
      />,
    ],
    coach: [
      <PrivateRoute
        key={routes.REVIEW.routePath(ROLES.COACH)}
        exact
        path={routes.REVIEW.routePath(ROLES.COACH)}
        component={ReviewUserPage}
        allowedRoles={[ROLES.COACH]}
      />,
      <PrivateRoute
        key={routes.REVIEW_CREATE.routePath(ROLES.COACH)}
        exact
        path={routes.REVIEW_CREATE.routePath(ROLES.COACH)}
        component={ReviewSetup}
        allowedRoles={[ROLES.COACH]}
      />,
      <PrivateRoute
        key={routes.REVIEW_UPDATE.routePath(ROLES.COACH)}
        exact
        path={routes.REVIEW_UPDATE.routePath(ROLES.COACH)}
        component={ReviewSetup}
        allowedRoles={[ROLES.COACH]}
      />,
    ],
    admin: [
      <PrivateRoute
        key={routes.REVIEW.routePath(ROLES.ADMIN)}
        exact
        path={routes.REVIEW.routePath(ROLES.ADMIN)}
        component={ReviewAdminPage}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.REVIEW_CREATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.REVIEW_CREATE.routePath(ROLES.ADMIN)}
        component={ReviewSetup}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.REVIEW_UPDATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.REVIEW_UPDATE.routePath(ROLES.ADMIN)}
        component={ReviewSetup}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.REVIEW_SUPERVISION_USER.routePath(ROLES.ADMIN)}
        exact
        path={routes.REVIEW_SUPERVISION_USER.routePath(ROLES.ADMIN)}
        component={ReviewUserPage}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [
    <OpenRoute
      key={routes.REVIEW_GENERATE_PDF.routePath()}
      exact
      path={routes.REVIEW_GENERATE_PDF.routePath()}
      component={ReviewReportPDF}
    />,
    <OpenRoute
      key={routes.REVIEW_GIVE_FEEDBACK_OUTSIDE.routePath()}
      exact
      path={routes.REVIEW_GIVE_FEEDBACK_OUTSIDE.routePath()}
      layout={LoginLayout}
      hideHeader={true} // prop for layout
      component={ReviewGiveFeedbackOutside}
    />,
  ],
});
