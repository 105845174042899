import { ROLES } from '~/constants';
import {
  pageDashboard,
  pageOutside,
  pageCreate,
  pageUpdate,
  pageSupervisionUser,
} from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'reviews';

// overview
// for REVIEWS overview page we use route
// history.push(routes.CONVERSATIONS.build(
//         { role: ROLES.USER },
//         { hash: 'development-conversation_cycle' },
//       ))

// dashboard
export const REVIEW = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ reviewId = ':reviewId' }) => pageDashboard(routeName, { id: reviewId }),
);

// give feedback
export const REVIEW_GIVE_FEEDBACK = new LinkConstructor(
  [ROLES.USER],
  ({ reviewId = ':reviewId', requestId = ':requestId' }) =>
    `${pageDashboard(routeName, { id: reviewId })}/request/${requestId}/give-feedback`,
);
export const REVIEW_GIVE_FEEDBACK_SELF = new LinkConstructor(
  [ROLES.USER],
  ({ reviewId = ':reviewId' }) =>
    `${pageDashboard(routeName, { id: reviewId })}/give-feedback-self`,
);
export const REVIEW_GIVE_FEEDBACK_OUTSIDE = new LinkConstructor(
  [],
  () => `${pageOutside(routeName)}/give-feedback-outside`,
);

// create pdf
export const REVIEW_GENERATE_PDF = new LinkConstructor(
  [],
  ({ reviewId = ':reviewId', tokenId = ':token' }) =>
    `${pageOutside(routeName)}/pdf/${reviewId}/${tokenId}`,
);

// create
export const REVIEW_CREATE = new LinkConstructor([ROLES.USER, ROLES.COACH, ROLES.ADMIN], () =>
  pageCreate(routeName),
);

// update
export const REVIEW_UPDATE = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ reviewId = ':reviewId' }) => pageUpdate(routeName, { id: reviewId }),
);

// incoming requests
export const REVIEWS_INCOMING_REQUESTS = new LinkConstructor(
  [ROLES.USER],
  () => `${routeName}-incoming-requests`,
);
export const REVIEWS_INCOMING_REPORT = new LinkConstructor(
  [ROLES.USER],
  ({ requestId = ':requestId' }) => `${routeName}-incoming-requests/${requestId}/report`,
);

// supervision
export const REVIEW_SUPERVISION_USER = new LinkConstructor(
  [ROLES.COACH, ROLES.ADMIN],
  ({ reviewId = ':reviewId' }) => pageSupervisionUser(routeName, { id: reviewId }),
);
