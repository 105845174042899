import React, { ChangeEvent, useState } from 'react';

import { ENGAGEMENT_REPORT_CHART_TYPES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { Loader } from '~/components/Buttons/components/Loader';
import { DropdownIconState } from '~/components/Buttons/types';
import { ICONS, Icon } from '~/components/Icon';
import ChevronIcon from '~/components/Icons/Chevron';
import PaginationBar from '~/components/PaginationBar';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import {
  MainCtr,
  TableCtr,
  LeftCtr,
  Line,
  DataRaw,
  DataCtr,
  Numbers,
  NumberLineBorder,
  ItemNr,
  DataCtrBackground,
  RowTitle,
  RightCtr,
  SearchFieldWrapper,
  SearchCtr,
  FilterBtnCtr,
  FilterCtr,
  LoaderCtr,
  DropDownCtr,
  PrimaryDimension,
  PrimaryDimensionHolder,
  SecondaryDimension,
  SortIcons,
  SortCtr,
  SortRow,
  DataMultiCtr,
  DataMultiCtrBackground,
  DataMultiOuterCtr,
  PaginationCtr,
  NoDataTextContainer,
} from './TotalStyle';

import { useOutsideClick } from '~/hooks/useOutsideClick';
import { TTotalGraphData } from '~/services/reports';
import { COLORS } from '~/styles';
import { toFixed } from '~/utils/math';

import ExportDropDown from '../../Components/ExportDropDown';
import ReFetchBtn from '../../Components/RefetchBtn';
import { TPaginationProp } from '../../options';
import { EExportOptions, EOptions, TDropDownValues } from '../../types';
import { InformationModal } from '../InformationModal';

type TProps = {
  onShow: () => void;
  setShowExportMenu: (value: boolean) => void;
  optionChangeHandler: (type: EOptions, val?: TDropDownValues) => void;
  totalSearch: string;
  showExportMenu: boolean;
  data: TTotalGraphData[];
  pagination: TPaginationProp;
  noDataText: string;
  isLoading: boolean;
  sortedBy: string | null;
  enabledOptions: EOptions[];
  enabledOptionKeys: EOptions[];
  exportOptionArr: EExportOptions[];
  viewRole: ROLES;
  onExportData: (type: EExportOptions, from: ENGAGEMENT_REPORT_CHART_TYPES) => void;
  activatedDimensions: {
    includeTeamAverage: boolean;
    includeCompanyAverage: boolean;
  };
  handlePagination: (value: TPaginationProp, type: ENGAGEMENT_REPORT_CHART_TYPES) => void;
  totalCount: number;
  noRetry: boolean;
};

const BAR_HEIGHT = 30;
const BAR_MAX_HEIGHT = 40;

function Total({
  onShow,
  setShowExportMenu,
  optionChangeHandler,
  totalSearch,
  showExportMenu,
  onExportData,
  data,
  noDataText,
  isLoading,
  sortedBy,
  enabledOptionKeys,
  enabledOptions,
  exportOptionArr,
  handlePagination,
  totalCount,
  pagination,
  noRetry,
}: TProps): JSX.Element {
  const { i18n } = useLingui();
  const [activeRow, setActiveRow] = useState<null | number>(null);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [informationModalData, setInformationModalData] = useState<TTotalGraphData | null>(null);

  enum ESort {
    primary = 'primary',
    secondary = 'secondary',
  }
  const [showSortedMenu, setShowSortedMenu] = useState<ESort | null>(null);
  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    const newPagination = {
      ...pagination,
      skip,
      index,
    };
    handlePagination(newPagination, ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION);
  };

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    const newPagination = {
      skip: 0,
      index: 1,
      limit,
    };
    handlePagination(newPagination, ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION);
  };

  const sortBy = {
    primary: {
      asc: {
        key: 'primary__asc',
        label: `${i18n._(t`A-Z Alphabetic`)}`,
      },
      desc: {
        key: 'primary__desc',
        label: `${i18n._(t`Z-A Alphabetic`)}`,
      },
    },
    secondary: {
      asc: {
        key: 'secondary__asc',
        label: `${i18n._(t`Low to high`)}`,
      },
      desc: {
        key: 'secondary__desc',
        label: `${i18n._(t`High to low`)}`,
      },
    },
  };

  const primaryRef = useOutsideClick<HTMLDivElement>(() => {
    setShowSortedMenu(null);
  });

  const barSize = 20;
  const gaps = Array.from({ length: 100 / barSize + 1 }, (_, i) => i * barSize);
  const barLines = () => {
    return gaps.map((i) => <Line key={`line-${i + 1}`} leftAlign={`${i}%`} />);
  };

  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowExportMenu(false);
    setShowInformationModal(false);
  });

  const numberLine = () => {
    return gaps.map((i) => (
      <Numbers key={`line-${i + 1}`} leftAlign={`${i}%`}>
        {i}
      </Numbers>
    ));
  };

  const getSortMenu = () => {
    return (
      <SortCtr ref={primaryRef}>
        {showSortedMenu &&
          Object.values(sortBy[showSortedMenu]).map((item, i) => (
            <SortRow
              key={`sort-${i + 1}`}
              onClick={(e) => {
                e.stopPropagation();
                optionChangeHandler(EOptions.totalSortedBy, item.key);
                setShowSortedMenu(null);
              }}
            >
              {item.key.includes('asc') ? (
                <ChevronIcon
                  size={12}
                  className="icon"
                  width="12px"
                  height="15px"
                  color={
                    sortedBy?.includes(`${showSortedMenu}__asc`)
                      ? COLORS.COMPANY
                      : COLORS.PLACEHOLDERS
                  }
                />
              ) : (
                <ChevronIcon
                  size={12}
                  className="icon rotate"
                  width="12px"
                  height="15px"
                  color={
                    sortedBy?.includes(`${showSortedMenu}__desc`)
                      ? COLORS.COMPANY
                      : COLORS.PLACEHOLDERS
                  }
                />
              )}
              {item.label}
            </SortRow>
          ))}
      </SortCtr>
    );
  };

  const getMultiComponent = (item: TTotalGraphData, isActive: boolean) => {
    if (item.benchmark || item.team) {
      return (
        <DataMultiOuterCtr mHeight={`${BAR_MAX_HEIGHT}px`}>
          {!!item?.value && (
            <DataMultiCtr
              barWidth={`${toFixed(item.value || 0, 2)}%`}
              deviation={toFixed(item.deviation || 0, 2)}
              isActive={isActive}
              value={`${toFixed(item.value || 0, 2)}%`}
            >
              <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.CONFIRMATION_MODAL_INFO} />
            </DataMultiCtr>
          )}
          {!!item?.team && (
            <DataMultiCtr
              barWidth={`${toFixed(item.team || 0, 2)}%`}
              deviation={toFixed(item.deviation || 0, 2)}
              isActive={isActive}
              value={`${toFixed(item.team || 0, 2)}%`}
            >
              <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.ACCENT_WARNING} />
            </DataMultiCtr>
          )}
          {!!item?.benchmark && (
            <DataMultiCtr
              barWidth={`${toFixed(item.benchmark || 0, 2)}%`}
              deviation={toFixed(item.deviation || 0, 2)}
              isActive={isActive}
              value={`${toFixed(item.benchmark || 0, 2)}%`}
            >
              <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.COMPANY} />
            </DataMultiCtr>
          )}
        </DataMultiOuterCtr>
      );
    }
    return (
      <DataCtr
        barWidth={`${toFixed(item.value, 2)}%`}
        mHeight={`${BAR_HEIGHT}px`}
        isActive={isActive}
        deviation={toFixed(item.deviation || 0, 2)}
      >
        <DataCtrBackground isActive={isActive} />
        <ItemNr isActive={isActive}>{toFixed(item.value, 2)}%</ItemNr>
      </DataCtr>
    );
  };

  if (!isLoading && data.length === 0) {
    return (
      <MainCtr>
        <LoaderCtr>
          <NoDataTextContainer>{noDataText}</NoDataTextContainer>
          {!noRetry && <ReFetchBtn optionChangeHandler={optionChangeHandler} />}
        </LoaderCtr>
      </MainCtr>
    );
  }
  return (
    <MainCtr>
      <FilterCtr>
        <FilterBtnCtr>
          <SearchCtr>
            <SearchFieldWrapper
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                optionChangeHandler(EOptions.totalSearch, e.currentTarget.value);
              }}
              value={totalSearch}
              placeholder={i18n._(t`Search...`)}
            />
          </SearchCtr>
          {enabledOptionKeys.includes(EOptions.exportOptions) && exportOptionArr.length > 0 && (
            <DropDownCtr>
              <Button
                icon={ICONS.EXPORT}
                label={`${i18n._(t`Export`)}`}
                disabled={!enabledOptions.includes(EOptions.exportOptions)}
                variant={ButtonVariant.SECONDARY}
                onClick={() => setShowExportMenu(true)}
                size={ButtonSize.MEDIUM}
                dropDownIcon={showExportMenu ? DropdownIconState.UP : DropdownIconState.DOWN}
              />

              {showExportMenu && (
                <ExportDropDown
                  onMenuChange={(type) => {
                    onExportData(type, ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION);
                    setShowExportMenu(false);
                  }}
                  reference={baseRef}
                  exportOptionArr={exportOptionArr}
                />
              )}
            </DropDownCtr>
          )}
          <Icon icon={ICONS.EXPAND} color={COLORS.INACTIVE} onClick={onShow} className="icon" />
        </FilterBtnCtr>
      </FilterCtr>
      {isLoading ? (
        <LoaderCtr>
          <Loader />
        </LoaderCtr>
      ) : (
        <>
          <PrimaryDimensionHolder />
          <TableCtr>
            <LeftCtr>
              <PrimaryDimension
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSortedMenu(ESort.primary);
                }}
              >
                {i18n._(t`Primary dimension`)}{' '}
                <SortIcons>
                  <Icon
                    icon={ICONS.SORT_UP}
                    width={9}
                    height={7}
                    color={
                      sortedBy?.includes('primary__asc') ? COLORS.COMPANY : COLORS.PLACEHOLDERS
                    }
                  />
                  <Icon
                    icon={ICONS.SORT_DOWN}
                    width={9}
                    height={7}
                    color={
                      sortedBy?.includes('primary__desc') ? COLORS.COMPANY : COLORS.PLACEHOLDERS
                    }
                  />
                </SortIcons>
                {showSortedMenu === ESort.primary && getSortMenu()}
              </PrimaryDimension>
              {data.map((item, i) => (
                <RowTitle
                  key={`data-${i + 1}`}
                  isActive={activeRow === i}
                  onMouseEnter={() => setActiveRow(i)}
                  onMouseLeave={() => setActiveRow(null)}
                  isClickable={!!item.id}
                  onClick={() => {
                    item.id && setShowInformationModal(true);
                    setInformationModalData(item);
                  }}
                  mHeight={Array.isArray(item.value) ? `${BAR_MAX_HEIGHT}px` : `${BAR_HEIGHT}px`}
                >
                  <Tooltip tooltip={i18n._(t`${item.name}`)} size={TOOLTIP_SIZES.BIG}>
                    <div>{i18n._(t`${item.name}`)}</div>
                  </Tooltip>
                </RowTitle>
              ))}
            </LeftCtr>
            <RightCtr>
              <SecondaryDimension
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSortedMenu(ESort.secondary);
                }}
              >
                {i18n._(t`Average`)}
                <SortIcons>
                  <Icon
                    icon={ICONS.SORT_UP}
                    width={9}
                    height={7}
                    color={
                      sortedBy?.includes('secondary__asc') ? COLORS.COMPANY : COLORS.PLACEHOLDERS
                    }
                  />
                  <Icon
                    icon={ICONS.SORT_DOWN}
                    width={9}
                    height={7}
                    color={
                      sortedBy?.includes('secondary__desc') ? COLORS.COMPANY : COLORS.PLACEHOLDERS
                    }
                  />
                </SortIcons>
                {showSortedMenu === ESort.secondary && getSortMenu()}
              </SecondaryDimension>
              {data.map((item, i) => (
                <DataRaw
                  key={`data-${i + 1}`}
                  onMouseEnter={() => setActiveRow(i)}
                  onMouseLeave={() => setActiveRow(null)}
                  isActive={activeRow === i}
                  mHeight={Array.isArray(item.value) ? `${BAR_MAX_HEIGHT}px` : `${BAR_HEIGHT}px`}
                >
                  {getMultiComponent(item, activeRow === i)}
                </DataRaw>
              ))}
              {numberLine()}
              {barLines()}
              <NumberLineBorder />
            </RightCtr>
          </TableCtr>
          <PaginationCtr>
            <PaginationBar
              pagination={pagination}
              changePagination={onPageChangeClick}
              changePageSize={handleChangeItemsPerPage}
              count={totalCount}
              noShadow
              noBorder
              noTopBorder
              showCount
            />
          </PaginationCtr>
        </>
      )}
      {showInformationModal && informationModalData && (
        <InformationModal
          data={informationModalData}
          onClose={setShowInformationModal}
          baseRef={baseRef}
        />
      )}
    </MainCtr>
  );
}

export default Total;
