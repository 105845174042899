import { useCallback, useMemo } from 'react';

import { IUser } from '@learned/types';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { getUser } from '~/selectors/baseGetters';

function useUserCalendarIntegration() {
  const user: IUser & {
    showIntegrationQuestion: boolean;
    integrations?: { isRevoked: boolean; isDeleted: boolean }[];
  } = useSelector(getUser);

  const calendarIntegration = useMemo(
    () =>
      user?.integrations?.find((integration) => !integration.isRevoked && !integration.isDeleted),
    [user],
  );
  const hasCalendarIntegration = !isEmpty(calendarIntegration);
  const goToCalendarIntegrations = useCallback(() => {
    routes.PROFILE_SETTINGS.go(
      { role: ROLES.USER },
      { isBackPath: true, query: { scrollToCalendars: true } },
    );
  }, []);
  const showIntegrationQuestion = user.showIntegrationQuestion;

  return {
    hasCalendarIntegration,
    showIntegrationQuestion,
    goToCalendarIntegrations,
    calendarIntegration,
  };
}

export { useUserCalendarIntegration };
