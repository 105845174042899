import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import AdjustRatingScaleBlock from './AdjustRatingScaleBlock';

import useBoolState from '~/hooks/useBoolState';
import getDuplicatesInArray from '~/utils/getDuplicatesInArray';

function AdjustRatingScaleModal({
  onClose,
  i18n,
  onSubmit,
  providedScaleLabels,
  providedIsSkippable,
}) {
  const [scaleLabels, setScaleLabels] = useState(providedScaleLabels);
  const [isSkippable, setIsSkippable] = useState(providedIsSkippable);
  const { addToast } = useToasts();
  const $isShowErrors = useBoolState(false);

  const addScaleLabel = () => {
    const updatedScaleLabels = [...scaleLabels, ''];
    setScaleLabels(updatedScaleLabels);
  };

  const updateScaleLabel = (value, labelIndex) => {
    const updatedScaleLabels = scaleLabels.map((label, i) => (i === labelIndex ? value : label));
    setScaleLabels(updatedScaleLabels);
  };

  const deleteScaleLabel = (labelIndex) => {
    const updatedScaleLabels = scaleLabels.filter((_l, i) => i !== labelIndex);
    setScaleLabels(updatedScaleLabels);
  };

  const toggleIsSkippable = () => {
    setIsSkippable(!isSkippable);
  };

  const showToast = (title, message, type = TOAST_TYPES.ERROR) => {
    addToast({ title, subtitle: message, type });
  };

  const checkErrors = () => {
    const duplicates = getDuplicatesInArray(scaleLabels);
    const emptyName = scaleLabels.some((c) => isEmpty(c));

    if (duplicates.length > 0 || emptyName) {
      $isShowErrors.on();
      if (duplicates.length > 0) {
        showToast(i18n._(t`Not completed`), i18n._(t`Scale labels cannot have the same name`));
      }
      if (emptyName) {
        showToast(i18n._(t`Not completed`), i18n._(t`Scale labels cannot be empty`));
      }
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    const hasErrors = checkErrors();
    if (!hasErrors) {
      onSubmit({ scaleLabels, isSkippable });
      onClose();
    }
  };

  return (
    <Modal
      title={i18n._(t`Adjust rating scale`)}
      onClose={onClose}
      width={650}
      minWidth={500}
      minHeight="654px"
      contentStyles={{
        background: '#fff',
        height: '100%',
        padding: '12px 23px 16px',
        minHeight: '440px',
      }}
      headerStyles={{ padding: '0 23px' }}
      footerRight={
        <Button
          width={83}
          height={48}
          label={i18n._(t`Save`)}
          type="primary"
          onClick={handleSubmit}
        />
      }
      footerStyles={{ height: '95px', paddingRight: '23px' }}
    >
      <AdjustRatingScaleBlock
        scaleLabels={scaleLabels}
        onDelete={deleteScaleLabel}
        addScaleLabel={addScaleLabel}
        onChange={updateScaleLabel}
        toggleIsSkippable={toggleIsSkippable}
        isSkippable={isSkippable}
        isShowErrors={$isShowErrors.value}
      />
    </Modal>
  );
}

export default withI18n()(AdjustRatingScaleModal);
