import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import StarIcon from '~/components/Icons/Star';
import StarBorderIcon from '~/components/Icons/StarBorder';

import { COLOR_SET, COLORS } from '~/styles';

const StarLine = styled.div`
  display: flex;
  & > svg {
    margin-right: ${(props) => props.size / 8}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

// When the simple mode boolean is passed, the Stars are either filled or not.
// Bordered stars and lighter stars are not used then. The expValue parameter is ignored.
const Stars = ({ value, expValue, className, size, numberOfStars = 5, mainColor, expColor }) => (
  <StarLine className={className} size={size}>
    {[...Array(numberOfStars).keys()].map((n) => {
      const current = n + 1;
      const filled = Math.max(value, expValue || 0) >= current;
      const Icon = filled ? StarIcon : StarBorderIcon;
      let color = mainColor || 'var(--company-color)';
      let isLight = false;
      if (typeof expValue !== 'undefined') {
        if (expValue > value ? current > value : current < value) {
          color = expColor || COLORS.BG_PAGE;
          isLight = true;
        }
      }
      if (!filled) {
        color = COLOR_SET.LIGHT_GREY;
      }

      return (
        <Icon
          size={size}
          key={n}
          fill={color}
          className={classNames('star', { filled, light: isLight })}
        />
      );
    })}
  </StarLine>
);

Stars.defaultProps = {
  size: 24,
  value: 0,
};

export default Stars;
