import React from 'react';

import CareerCard from './Cards/CareerCard';
import CoachesCard from './Cards/CoachesCard';
import { ConversationCard } from './Cards/ConversationCard';
import { NextStepsCard } from './Cards/NextStepsCard';
import { TeamsCard } from './Cards/TeamsCard';

type DashBoardSideBarProps = {
  isModuleReviewsEnabled: boolean;
  isModuleCareerEnabled: boolean;
  isModuleMeetingsEnabled: boolean;
};

const UserDashboardSidebar = ({
  isModuleCareerEnabled,
  isModuleMeetingsEnabled,
  isModuleReviewsEnabled,
}: DashBoardSideBarProps) => {
  return (
    <div>
      {isModuleReviewsEnabled && <ConversationCard />}
      {(isModuleMeetingsEnabled || isModuleReviewsEnabled) && <NextStepsCard />}
      {isModuleCareerEnabled && <CareerCard />}
      <CoachesCard />
      <TeamsCard />
    </div>
  );
};

export { UserDashboardSidebar };
