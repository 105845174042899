import React, { PureComponent } from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SkillName from '~/components/Skill/SkillName';
import Stars from '~/components/Stars';
import StepStatus from '~/components/StepStatus';

import { COLORS } from '~/styles';

const Badge = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

const ClicableBadge = styled(Badge)`
  font-size: 16px;
  color: var(--company-color);
  cursor: pointer;
`;

const NameColumn = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.TEXT_PRIMARY_2};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 240px;
  flex: 1;

  :hover {
    cursor: pointer;
  }
`;

const Row = styled.div`
  height: 56px;
  border-radius: 1px;
  background-color: #f6f8fc;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1.1fr;
  align-items: center;
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
`;

const StarsColumn = styled.div`
  width: 100%;
  display: flex;
  flex: 200px 0 0;
  align-items: center;
`;

const StatusColumn = styled.div`
  min-width: 193px;
  display: flex;
  align-items: center;
`;

const StyledStepStatus = styled(StepStatus)`
  margin-right: 4px;
`;

const Table = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${COLORS.TEXT_PRIMARY_2};
  margin-bottom: 16px;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1.1fr;
`;

const TableHeaderItem = styled.p`
  text-transform: uppercase;
  font-size: 12px;
`;

class SkillsTable extends PureComponent {
  static propTypes = {
    readOnly: PropTypes.bool,
    onSkillClick: PropTypes.func,
    jobProfile: PropTypes.object,
    onOpenPassport: PropTypes.func,
  };

  renderMatchedSkillRow = (skill) => {
    const { onOpenPassport, user, jobProfile, onSkillClick, readOnly } = this.props;
    const userSkillLevel = user.skillsLevels[skill.id];

    return (
      <Row key={skill.id}>
        <NameColumn onClick={() => !readOnly && onSkillClick(skill)}>
          <SkillName skill={skill} />
        </NameColumn>
        <StarsColumn>
          <Stars
            numberOfStars={skill.maxLevel}
            value={jobProfile.skillsLevels[skill.id]}
            size={20}
          />
        </StarsColumn>
        <StarsColumn>
          <Stars numberOfStars={skill.maxLevel} value={userSkillLevel} size={20} />
        </StarsColumn>
        <StatusColumn>
          <StyledStepStatus status="done" />
          <ClicableBadge onClick={readOnly ? onOpenPassport : () => {}}>
            <Trans>Match</Trans>
          </ClicableBadge>
        </StatusColumn>
      </Row>
    );
  };

  renderHigherSkillRow = (skill) => {
    const { updateLevel, user, jobProfile, onSkillClick, readOnly } = this.props;
    const userSkillLevel = (user.skillsLevels && user.skillsLevels[skill.id]) || 0;

    return (
      <Row key={skill.id}>
        <NameColumn onClick={() => !readOnly && onSkillClick(skill)}>
          <SkillName skill={skill} />
        </NameColumn>
        <StarsColumn>
          <Stars
            numberOfStars={skill.maxLevel}
            value={jobProfile.skillsLevels[skill.id]}
            size={20}
          />
        </StarsColumn>
        <StarsColumn>
          <Stars numberOfStars={skill.maxLevel} value={userSkillLevel} size={20} />
        </StarsColumn>
        <StatusColumn>
          {!readOnly && (
            <ClicableBadge onClick={() => updateLevel(skill.id)}>
              <Trans>Update level</Trans>
            </ClicableBadge>
          )}
        </StatusColumn>
      </Row>
    );
  };

  renderNotAddedSkillRow = (skill) => {
    const { jobProfile, addToPassport, onSkillClick, readOnly } = this.props;

    const indexOfLevel = skill.numberOfEnabledLevels.indexOf(
      Number(jobProfile.skillsLevels[skill.id]),
    );

    return (
      <Row key={skill.id}>
        <NameColumn onClick={() => !readOnly && onSkillClick(skill)}>
          <SkillName skill={skill} />
        </NameColumn>
        <StarsColumn>
          <Stars numberOfStars={skill.maxLevel} value={indexOfLevel + 1} size={20} />
        </StarsColumn>
        <StarsColumn>
          <Stars numberOfStars={skill.maxLevel} value={0} size={20} />
        </StarsColumn>
        <StatusColumn>
          {!readOnly && (
            <ClicableBadge onClick={() => addToPassport(skill.id)}>
              <Trans>Add to passport</Trans>
            </ClicableBadge>
          )}
        </StatusColumn>
      </Row>
    );
  };

  render() {
    const { user, jobProfile } = this.props;
    let matchedSkills = [];
    let higherSkills = [];
    let notAddedSkills = [];
    if (!isEmpty(jobProfile.skills)) {
      jobProfile.skills.forEach((skill) => {
        // old logic, we don't have skillsLevels any more
        if ((user.skills || []).includes(skill.id)) {
          if (
            user.skillsLevels &&
            user.skillsLevels[skill.id] &&
            user.skillsLevels[skill.id] >= jobProfile.skillsLevels[skill.id]
          ) {
            matchedSkills.push(skill);
          } else {
            higherSkills.push(skill);
          }
        } else {
          notAddedSkills.push(skill);
        }
      });
    }

    return (
      <>
        {!isEmpty(matchedSkills) && (
          <>
            <Title>
              <Trans>Skills that match</Trans>
            </Title>
            <TableHeader>
              <TableHeaderItem>
                <Trans>Skill</Trans>
              </TableHeaderItem>
              <TableHeaderItem>
                <Trans>Required level</Trans>
              </TableHeaderItem>
              <TableHeaderItem>
                <Trans>Your level</Trans>
              </TableHeaderItem>
            </TableHeader>
            <Table>{matchedSkills.map(this.renderMatchedSkillRow)}</Table>
          </>
        )}
        {!isEmpty(higherSkills) && (
          <>
            <Title>
              <Trans>Skills that require a higher level</Trans>
            </Title>
            <TableHeader>
              <TableHeaderItem>
                <Trans>Skill</Trans>
              </TableHeaderItem>
              <TableHeaderItem>
                <Trans>Required level</Trans>
              </TableHeaderItem>
              <TableHeaderItem>
                <Trans>Your level</Trans>
              </TableHeaderItem>
            </TableHeader>
            <Table>{higherSkills.map(this.renderHigherSkillRow)}</Table>
          </>
        )}
        {!isEmpty(notAddedSkills) && (
          <>
            <Title>
              <Trans>Skills that you do not have in your profile</Trans>
            </Title>
            <TableHeader>
              <TableHeaderItem>
                <Trans>Skill</Trans>
              </TableHeaderItem>
              <TableHeaderItem>
                <Trans>Required level</Trans>
              </TableHeaderItem>
              <TableHeaderItem>
                <Trans>Your level</Trans>
              </TableHeaderItem>
            </TableHeader>
            <Table>{notAddedSkills.map(this.renderNotAddedSkillRow)}</Table>
          </>
        )}
      </>
    );
  }
}

export default withI18n()(SkillsTable);
