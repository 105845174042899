import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Placeholder from '~/components/Placeholder';
import SelectTeamModal from '~/components/SelectTeamModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SectionBodyProps, Team } from '~/pages/PassportPage/types';

import { SectionHeader } from './SectionHeader';
import { SectionList } from './SectionList';

import zeroState from '~/assets/no-result.webp';

import useBoolState from '~/hooks/useBoolState';
import { removeMemberFromTeam, addMembersToTeam } from '~/store/teams/actions';
import getUserTeams from '~/utils/getUserTeams';

const StateImg = styled.img`
  width: 75px;
  height: auto;
`;

const SectionUserTeams = ({ userId, teamsState: teams, fetching }: SectionBodyProps) => {
  const { i18n } = useLingui();
  const $modal = useBoolState();
  const $loading = useBoolState();
  const dispatch = useDispatch();

  const addUserToTeam = async (selectedTeams: Team[]) => {
    $loading.on();
    selectedTeams.forEach(async (team) => {
      await dispatch(addMembersToTeam(team.id, [userId]));
    });
    $loading.off();
    $modal.off();
  };

  const removeUserFromTeam = async (teamId: string) => {
    $loading.on();
    await dispatch(removeMemberFromTeam(teamId, userId));
    $loading.off();
  };

  const userTeams = teams ? getUserTeams(userId, Object.values(teams)) : [];
  return (
    <div>
      <SectionHeader
        title={i18n._(t`Teams`)}
        description={''}
        actionLabel={i18n._(t`Assign`)}
        actionOnClick={$modal.on}
      />
      <ShowSpinnerIfLoading loading={fetching || false}>
        <SectionList
          items={userTeams}
          itemActionLabel={i18n._(t`Remove`)}
          itemActionOnClick={removeUserFromTeam}
          itemActionLoading={$loading.value}
          placeholder={
            <Placeholder
              title={i18n._(t`No teams`)}
              subTitle={''}
              Icon={() => <StateImg alt="noTeams" src={zeroState} />}
            />
          }
        />
      </ShowSpinnerIfLoading>

      {$modal.value && (
        <SelectTeamModal
          // @ts-ignore
          teams={teams}
          title={i18n._(t`Assign to team`)}
          onModalClose={$modal.off}
          onSubmit={addUserToTeam}
          selectedUser={{ id: userId }}
        />
      )}
    </div>
  );
};

export { SectionUserTeams };
