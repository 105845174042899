import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import DeleteIcon from '~/components/Icons/Delete';
import PlusIcon from '~/components/Icons/Plus';
import SvgIcon from '~/components/SvgIcon';
import { TextField } from '~/components/Text';

import MenuIcon from '~/assets/menu.svg';

import { SUGGESTIONS_SKILL_CATEGORY } from '~/constants/skills';
import { COLORS, COLOR_PALETTE } from '~/styles';
import { reorder } from '~/utils/reorder';

const Block = styled.div`
  width: 625px;
  box-sizing: border-box;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  color: ${COLORS.TEXT_SECONDARY};
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  .rc-switch {
    margin-right: 0;
  }
`;

const StyledTextField = styled(TextField)`
  height: 48px;
  width: 323px;
  line-height: 1.5;
  font-stretch: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const OrderIcon = styled(SvgIcon)`
  margin-right: 40px;
`;

const DefaultLabelText = styled.div`
  font-size: 14px;
  line-height: 1.71;
  width: 150px;
  margin-right: 40px;

  font-stretch: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const DeleteIconWrapper = styled(DeleteIcon)`
  cursor: ${(props) => (props.$isDeleteForbidden ? 'no-drop' : 'pointer')};
  margin-right: 12px;
  margin-left: 22px;
`;

const SkillCategoriesList = ({ skillCategories, i18n, onChange, addUpdatedCategory, errors }) => {
  const addSkillCategory = () => {
    const newCategory = {
      name: 'Custom',
      isDefault: false,
      type: null,
    };

    onChange([...skillCategories, newCategory]);
  };

  const updateSkillCategory = (categoryIndex, name) => {
    const newSkillCategories = skillCategories.map((item, index) => {
      if (categoryIndex === index) {
        item.name = name;
        if (item.id) {
          addUpdatedCategory(item.id);
        }
      }
      return item;
    });
    onChange(newSkillCategories);
  };

  const removeSkillCategory = async (i) => {
    const textConfirm = i18n._(
      t`Are you sure you want to delete this category. This will remove this category from all customized skills. Once saved this cannot be undone.`,
    );

    if (await confirm(i18n, textConfirm)) {
      const updatedCategories = skillCategories.filter((_c, idx) => i !== idx);
      onChange(updatedCategories);
    }
  };

  const handleDrag = async (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedSkillCategories = reorder(
      skillCategories,
      result.source.index,
      result.destination.index,
    );

    onChange(reorderedSkillCategories);
  };

  return (
    <Block>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="skillCategories-droppable">
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {skillCategories.map((category, i) => (
                  <Draggable key={i.toString()} draggableId={i.toString()} index={i}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Row>
                          <Label>
                            <OrderIcon
                              width="20px"
                              height="20px"
                              url={MenuIcon}
                              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                              isDefaultColor
                            />
                            <DefaultLabelText>
                              {Object.values(SUGGESTIONS_SKILL_CATEGORY)
                                .find((skillCategory) => skillCategory.id === category.categoryId)
                                ?.name(i18n)}
                            </DefaultLabelText>
                            <StyledTextField
                              value={category.name || ''}
                              onChange={(e) => updateSkillCategory(i, e.target.value)}
                              error={errors.includes(category.id)}
                            />
                          </Label>
                          {!category.isDefault && (
                            <div
                              onClick={() => removeSkillCategory(i)}
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => removeSkillCategory(i)}
                            >
                              <DeleteIconWrapper size={24} fill={COLOR_PALETTE.GRAY_MIDDLE} />
                            </div>
                          )}
                        </Row>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <Button
        type="link-primary"
        label={i18n._(t`skill category`)}
        iconLeft={<PlusIcon size={24} />}
        onClick={addSkillCategory}
        styles={{
          paddingLeft: 0,
          fontSize: '16px',
          lineHeight: '1.38',
          marginBottom: '27px',
          marginLeft: '245px',
        }}
      />
    </Block>
  );
};

SkillCategoriesList.propTypes = {
  skillCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      isDefault: PropTypes.bool.isRequired,
      type: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  addUpdatedCategory: PropTypes.func.isRequired,
  errors: PropTypes.array,
};

SkillCategoriesList.defaultProps = {
  onChange: () => {},
  addUpdatedCategory: () => {},
  errors: [],
};

export default withI18n()(SkillCategoriesList);
