import styled from 'styled-components';

import { COLORS } from '~/styles';

export const CompanyBlockWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  margin-bottom: 32px;
`;

export const CompanyBlockItem = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: center;
  outline-color: transparent;
  width: 46px;
  height: 46px;
  border-radius: 6px;
  box-sizing: border-box;
`;

export const MenuBlocks = styled.div`
  margin: 0 10px;
`;

export const MenuFooter = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const MenuTopSection = styled(MenuFooter)``;

export const Menu = styled.div<{ $isMenuCollapsed: boolean }>`
  width: ${({ $isMenuCollapsed }) => ($isMenuCollapsed ? '66px' : '257px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  border-right: 1px solid ${COLORS.BORDERS};
  background-color: ${COLORS.BG_PAGE};
  box-sizing: border-box;
  padding-top: 32px;
  overflow: auto;
`;

// secondary menu

export const MenuDivider = styled.div<{ isNoMargins?: boolean }>`
  width: 100%;
  height: 1.5px;
  background: ${COLORS.BORDERS};
  margin: ${({ isNoMargins }) => (isNoMargins ? '0 0' : '16px 0')};
`;

export const SecondaryMenuTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  overflow: hidden;
  color: ${COLORS.TEXT_MAIN};
  display: flex;
  align-items: center;
  margin: 24px 0 8px 8px;
  height: 36px;
  box-sizing: border-box;
`;

export const SecondaryMenuSubTitle = styled.div`
  color: ${COLORS.SUBTEXT};
  font-size: 12px;
  margin: 24px 0 8px 8px;
`;

export const SecondaryMenuWrapper = styled.div<{
  isMenuWithIcons?: boolean;
}>`
  background-color: ${COLORS.BG_PAGE};
  min-width: ${({ isMenuWithIcons }) => (isMenuWithIcons ? '257px' : '204px')};
  max-width: ${({ isMenuWithIcons }) => (isMenuWithIcons ? '257px' : '204px')};
  overflow: auto;
  border-right: 1px solid ${COLORS.BORDERS};
  padding: 17px 10px 0;
  box-sizing: border-box;
`;
