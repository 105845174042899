import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { API_RETURN_FIELDS, KPI_NONE, KPI_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import sumBy from 'lodash/sumBy';
import union from 'lodash/union';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  AutocompleteFilterMembers,
  AutocompleteFilterReviews,
  AutocompleteFilterTeams,
  AutocompleteFilterRoles,
} from '~/components/AutocompleteFilters';
import Button from '~/components/Button';
import { KpiDropdown } from '~/components/NineGridBox/kpiDropdown';
import SvgIcon from '~/components/SvgIcon';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Tooltip from '~/components/Tooltip';
import Avatar from '~/components/UI/Avatar';
import BoxWithShadow from '~/components/UI/BoxWithShadow';
import UpdateNinegridLabelsModal from '~/components/UpdateNinegridLabelsModal';

import { ListComponent } from './components/ListComponent';

import gridViewIcon from '~/assets/grid-view.svg';
import listViewIcon from '~/assets/list-view.svg';
import ninegridIcon from '~/assets/ninegrid.svg';

import { SORT_DIRECTION } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import getAllUsers from '~/selectors/getAllUsers';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getKpis } from '~/services/kpis';
import { downloadNinegridCSV, getFilteredReview } from '~/services/reviews';
import { setCompanyLabels } from '~/store/companies/actions';
import { COLOR_PALETTE, COLORS } from '~/styles';
import BrowserStorage from '~/utils/browserStorage';

import CollapseIcon from '../Icons/Collapse';
import ExportIcon from '../Icons/Export';
import HandPointingIcon from '../Icons/HandPointing';
import Pencil2Icon from '../Icons/Pencil2';
import Placeholder from '../Placeholder';

export const ListViews = {
  GRID: 'grid',
  LIST: 'list',
};

const NINE_GRID_HEIGHT = 500;

const BoxWrapper = styled(BoxWithShadow)`
  display: flex;
  border-color: transparent;
  align-items: stretch;
  border-radius: 10px;
`;

const Sidebar = styled.div`
  padding: 24px 22px 33px;
  border-right: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  max-width: 204px;
  box-sizing: border-box;
  position: relative;
  min-height: 624px;
  transition: all ease-in 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      transition: all ease-in 0.3s;
      justify-content: flex-end;

      & .content {
        display: flex;
        flex: 1;
      }
    `}

  & .top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  & .content {
    flex: 1;
  }

  & .collapseButton {
    display: flex;
    align-items: flex-end;
  }
`;

const Main = styled.div`
  width: 100%;
  overflow-x: auto;

  & .placeholderNoData {
    margin: 184px auto;
    width: 270px;
    color: ${COLOR_PALETTE.GRAY_MIDDLE};
  }

  & .placeholderEmpty {
    margin: 184px auto;
    width: 255px;
    color: ${COLOR_PALETTE.DARK_GRAY};
  }

  & .grid {
    padding: 24px 48px 24px 24px;
  }

  & .list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

const Title = styled.div`
  font-size: 14px;
  line-height: -0.16px;
  color: black;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-bottom: 60px;

  ${({ isRotated }) =>
    !isRotated &&
    css`
      position: absolute;
      bottom: 52px;
      transform-origin: 0 0;
      margin-bottom: 0px;
      transform: rotate(-90deg) translate(0);
      min-width: 200px;
    `}
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  margin-bottom: 23px;
  width: 100%;
`;

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  & .title {
    font-size: 14px;
    color: ${COLOR_PALETTE.BLACK};
    margin-bottom: -2px;
  }
  margin-bottom: 24px;
`;

const NineGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const YAxis = styled.div`
  display: block;
  width: ${({ width }) => width ?? '66px'};
`;

const Rotation = styled.div`
  transform-origin: ${({ transformOrigin }) => transformOrigin || '0 0'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transform: ${({ rotate, translate }) =>
    `rotate(${rotate ?? -90}deg) translate(${translate ?? -100}%)`};
  margin-left: ${({ marginLeft }) => marginLeft ?? 'unset'};
  width: ${NINE_GRID_HEIGHT}px;
  height: 66px;
`;

const XAxis = styled.div`
  height: 72px;
  width: calc(100% - 66px);
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 26px;
`;

const GraphTitle = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${({ color }) => (color ? color : 'black')};
`;

const GraphLabels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const GraphLabel = styled.div`
  display: flex;
  justify-content: ${(props) => props.$justifyContent};
  align-items: center;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-transform: uppercase;
`;

const NineGridContainerContainer = styled.div`
  position: relative;
  flex: 1;
  height: ${NINE_GRID_HEIGHT}px;
  display: flex;
  flex-direction: column-reverse;
`;

const StyledAutocompleteFilterReviews = styled(AutocompleteFilterReviews)`
  ${(props) => props.disabled && 'cursor: not-allowed;'}
  height: 32px;
  border-color: ${(props) => props.$error && 'red'};
`;

const StyledAutocompleteFilterTeams = styled(AutocompleteFilterTeams)`
  height: 32px;
`;

const StyledAutocompleteFilterMembers = styled(AutocompleteFilterMembers)`
  height: 32px;
`;

const StyledAutocompleteFilterRoles = styled(AutocompleteFilterRoles)`
  height: 32px;
`;

const ExtraCounter = styled.div`
  position: absolute;
  left: min(calc(${(props) => props.$left}%), calc(100% - 28px));
  bottom: min(calc(${(props) => props.$bottom}%), calc(100% - 28px));
  z-index: 2;
`;

const AvatarContainer = styled.div`
  position: absolute;
  visibility: ${(props) => (!props.$left && !props.$bottom ? 'hidden' : 'visible')};
  left: min(calc(${(props) => props.$left}%), calc(100% - 28px));
  bottom: min(calc(${(props) => props.$bottom}%), calc(100% - 28px));
  z-index: 2;
  filter: ${(props) => props.$blur && 'blur(4px)'};
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${({ borderColor }) => borderColor || 'unset'};
`;

const SecondReviewAvatarContainer = styled(AvatarContainer)`
  z-index: 1;
`;

const Chip = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: ${({ background }) => background || 'unset'};
  color: ${COLOR_PALETTE.WHITE};
  position: absolute;
  right: -10px;
  top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const GridColumn = styled.div`
  flex: 1;
  padding: 12px;
  background-color: ${(props) => (props.$index % 2 ? 'white' : COLORS.BG_PAGE)};
  font-size: 14px;
  max-width: 300px;
  font-weight: 600;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const SquareName = styled.div`
  width: 100%;
  cursor: default;

  & > small {
    margin-left: 5px;
  }
`;

const ApplyButton = styled(Button)`
  width: 100%;
  height: 48px;
  margin-bottom: 22px;
`;

const ActionButton = styled(Button)`
  margin-right: 24px;
`;

const CollapseButton = styled.button`
  color: var(--company-color);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
  margin: 0px;
  cursor: pointer;

  ${({ isRotated }) =>
    !isRotated &&
    css`
      width: fit-content;
      & svg {
        rotate: 180deg;
      }
    `}
`;

const NineGridBox = ({ i18n }) => {
  const xAxisRef = useRef();
  const yAxisRef = useRef();
  const history = useHistory();

  const storage = new BrowserStorage('ninegridBox');

  const [selectedReview, setSelectedReview] = useState(undefined);
  const [loadedFromParam, setLoadedFromParam] = useState(0);
  const [secondSelectedReview, setSecondSelectedReview] = useState(undefined);
  const [reviewWithData, setReviewWithData] = useState(undefined);
  const [secondReviewWithData, setSecondReviewWithData] = useState(undefined);
  const [xKPI, setXKPI] = useState('');
  const [yKPI, setYKPI] = useState('');
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [hovered, setHovered] = useState('');
  const { addToast } = useToasts();
  const [kpis, setKpis] = useState([]);
  const [isXKPIError, setIsXKPIError] = useState(false);
  const [isYKPIError, setIsYKPIError] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [reviewIds, setReviewsForCSV] = useState([]);
  const [KPIs, setKPIForCSV] = useState([]);
  const $showUpdateLabelsModal = useBoolState(false);
  const { ninegridLabels: labels } = useSelector(getCurrentCompany);
  const users = useSelector(getAllUsers);

  const dispatch = useDispatch();

  const [view, setView] = useState(ListViews.GRID);

  const preparedFilters = useMemo(() => {
    let members = selectedMembers.map((member) => member.id);
    selectedTeams.forEach((team) => {
      members = union(members, team.members);
    });
    let jobProfiles = selectedRoles.map((role) => role.id);

    const filters = {};

    if (!isEmpty(members)) {
      filters.users = members;
    }

    if (!isEmpty(jobProfiles)) {
      filters.jobProfiles = jobProfiles;
    }
    return size(filters) ? filters : 0;
  }, [selectedMembers, selectedRoles, selectedTeams]);

  const onApplyButton = () => {
    if (!xKPI && !yKPI) {
      addToast({
        title: i18n._(t`Please select Values for X & Y axis`),
        type: TOAST_TYPES.ERROR,
      });
      setIsXKPIError(true);
      setIsYKPIError(true);
    } else if (xKPI && !yKPI) {
      addToast({
        title: i18n._(t`Please select Value for Y axis`),
        type: TOAST_TYPES.ERROR,
      });
      setIsXKPIError(false);
      setIsYKPIError(true);
    } else if (!xKPI && yKPI) {
      addToast({
        title: i18n._(t`Please select Value for X axis`),
        type: TOAST_TYPES.ERROR,
      });
      setIsXKPIError(true);
      setIsYKPIError(false);
    } else {
      setIsXKPIError(false);
      setIsYKPIError(false);
      fetchReview();
    }
  };

  const fetchReview = async () => {
    if (!selectedReview || !xKPI || !yKPI) {
      return;
    }

    setIsLoading(true);
    const [review, secondReview] = await Promise.all([
      getFilteredReview(
        selectedReview.id,
        {
          isSubReviews: true,
          isAvg: true,
          isSubReviewsTotalCount: true,
          isWithCalibrateRatings: true,
        },
        {
          filters: preparedFilters,
          // sort is obligated, pagination does not work without sort
          sort: { field: 'name', direction: SORT_DIRECTION.ASC },
          KPIs: [xKPI.id, yKPI.id],
        },
      ),
      secondSelectedReview &&
        getFilteredReview(
          secondSelectedReview.id,
          {
            isSubReviews: true,
            isAvg: true,
            isSubReviewsTotalCount: true,
            isWithCalibrateRatings: true,
          },
          {
            filters: preparedFilters,
            KPIs: [xKPI.id, yKPI.id],
          },
        ),
    ]);
    setSecondReviewWithData(secondReview);
    setReviewWithData(review);
    fillReview(review);
    const reviewsForCSV = [];
    if (review) {
      reviewsForCSV.push(review.id);
    }
    if (secondReview) {
      reviewsForCSV.push(secondReview.id);
    }
    setReviewsForCSV(reviewsForCSV);
    constructURL(review, secondReview);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!secondSelectedReview) {
      setSecondReviewWithData(undefined);
    }
  }, [secondSelectedReview]);

  const constructURL = (review, secondReview) => {
    storage.setItem(
      'ninegridData',
      JSON.stringify({
        review: {
          id: review.id,
          name: review.name,
        },
        ...(secondReview && {
          secondReview: {
            id: secondReview.id,
            name: secondReview.name,
          },
        }),
        xKPI,
        yKPI,
        selectedTeams: selectedTeams.map((team) => ({
          id: team.id,
          name: team.name,
          members: team.members,
        })),
        selectedMembers: selectedMembers.map((member) => ({
          id: member.id,
          name: member.name,
        })),
        selectedRoles: selectedRoles.map((role) => ({
          id: role.id,
          name: role.name,
        })),
      }),
    );
    let url = history.location.pathname + `?loadreview=${!!selectedReview}`;
    history.replace(url);
  };

  // init load
  useEffect(() => {
    // fetch company kpis
    const fetchKpis = async () => {
      const response = await getKpis({ filters: { isDeleted: false } });

      if (response) {
        setKpis(response.data[API_RETURN_FIELDS.KPIS]);
      }
    };
    fetchKpis();

    const params = new URLSearchParams(history.location.search);
    if (params.get('loadreview') === 'true') {
      const ninegridData = JSON.parse(storage.getItem('ninegridData'));
      fillReview(ninegridData.review);
      fillSecondReview(ninegridData.secondReview);
      setXKPI(ninegridData.xKPI);
      setYKPI(ninegridData.yKPI);
      setSelectedTeams(ninegridData.selectedTeams);
      setSelectedMembers(ninegridData.selectedMembers);
      setSelectedRoles(ninegridData.selectedRoles);
      setLoadedFromParam(loadedFromParam + 1);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loadedFromParam <= 1) {
      fetchReview();
    }
    // eslint-disable-next-line
  }, [loadedFromParam]);

  useEffect(() => {
    setKPIForCSV([xKPI, yKPI].filter((i) => i));
  }, [setKPIForCSV, xKPI, yKPI]);

  const getError = () => {
    if (
      reviewWithData &&
      selectedReview &&
      reviewWithData.id === selectedReview.id &&
      xKPI &&
      yKPI
    ) {
      const xKPIs = reviewWithData?.template?.sections.filter((section) => section.kpi === xKPI.id);
      const yKPIs = reviewWithData?.template?.sections.filter((section) => section.kpi === yKPI.id);
      return isEmpty(xKPIs) && isEmpty(yKPIs);
    }
    return false;
  };

  const getGridColumns = (rowIndex) => {
    const columns = [];
    for (let i = 0; i < 3; i += 1) {
      const { value, percentage } = matrixArea[i][rowIndex];
      columns.push(
        <GridColumn $index={rowIndex + i}>
          <Tooltip
            disabled={!labels[i * 3 + rowIndex].description}
            tooltip={labels[i * 3 + rowIndex].description.replace(/<\/?[^>]+(>|$)/g, '')}
          >
            <SquareName>
              {labels[i * 3 + rowIndex].label}
              <small>{value > 0 && i18n._(t`(${value} people | ${percentage.toFixed(1)}%)`)}</small>
            </SquareName>
          </Tooltip>
        </GridColumn>,
      );
    }
    return columns;
  };

  const getGridRows = () => {
    const rows = [];
    for (let i = 0; i < 3; i += 1) {
      rows.push(<GridRow>{getGridColumns(i)}</GridRow>);
    }
    return rows;
  };

  const fillReview = (review) => {
    if (review) {
      setSelectedReview({
        ...review,
        name: `A. ${review.name}`,
      });
    } else {
      setSelectedReview(undefined);
    }
  };

  const fillSecondReview = (review) => {
    if (review) {
      setSecondSelectedReview({
        ...review,
        name: `B. ${review.name}`,
      });
    } else {
      setSecondSelectedReview(undefined);
    }
  };

  const selectSecondReview = (review) => {
    if (review && selectedReview && review.id === selectedReview.id) {
      addToast({
        title: i18n._(t`Attention`),
        subtitle: i18n._(t`You can't select the same review for both review 1 and review 2`),
        type: TOAST_TYPES.ERROR,
      });
      return;
    }
    if (review) {
      review?.template?.sections.forEach((section) => {
        if (
          section.type !== KPI_NONE.type &&
          !selectedReview?.template?.sections.filter((s) => s.type === section.type)
        ) {
          addToast({
            title: i18n._(t`Attention`),
            subtitle: i18n._(t`Second review must have same KPI as first review`),
            type: TOAST_TYPES.INFO,
          });
          return;
        }
      });
    }
    fillSecondReview(review);
  };

  const navigateToProfile = (userId) => {
    const backPath = encodeURIComponent(history.location.pathname + history.location.search);
    history.push(
      routes.USER_PUBLIC_SKILL_PASSPORT.build(
        {},
        { userId, isBackPath: true, hash: 'reviews', backPathDefault: backPath },
      ),
    );
  };

  const getValueSkills = (values) => {
    if (values === 0 || (values && values[0] === 0) || !values) {
      return 0;
    }
    if (values <= 100) {
      return values - 50;
    }
    if (values <= 200) {
      return (values - 100) / 2 + 50;
    }
    return 100;
  };

  const getValueOther = (values) => {
    return values ? (values[0] / values[1]) * 100 : 0;
  };

  const getValue = useCallback(
    (userReview, kpi) =>
      kpi.type === KPI_TYPES.SKILLS
        ? getValueSkills(userReview.avg[kpi.id])
        : getValueOther(userReview.avg[kpi.id]),
    [],
  );

  const getPureValue = useCallback((userReview, kpi) => userReview.avg[kpi.id]?.[0], []);

  const prepareIndex = (value) => {
    if (value >= 100) {
      return 2;
    }

    return Math.trunc(value / (100 / 3));
  };

  const findIndexes = useCallback((x, y) => [prepareIndex(x), prepareIndex(y)], []);

  const matrixArea = useMemo(() => {
    let matrix = [];

    // 1. make a 2D array with default value
    for (let i = 0; i < 3; i++) {
      matrix[i] = [];
      for (let j = 0; j < 3; j++) {
        matrix?.[i]?.push({ value: 0, percentage: 0, users: [] });
      }
    }

    // 2. count users and append numbers of firstReview
    if (xKPI && yKPI && reviewWithData) {
      reviewWithData?.subReviews.map((userReview) => {
        const [x, y] = findIndexes(getValue(userReview, xKPI), getValue(userReview, yKPI));
        const userId = userReview.createdFor;
        const originalReviewId = userReview.originalReview;

        matrix[x][y].value += 1;

        // keep userId and originalReviewId of first review
        if (
          !matrix?.[x]?.[y]?.users.find(
            (item) => item.userId === userId && originalReviewId === originalReviewId,
          )
        ) {
          matrix?.[x]?.[y]?.users.push({
            userId,
            originalReviewId,
          });
        }
      });
    }

    // 3. count users and append numbers of secondReview
    if (xKPI && yKPI && secondReviewWithData) {
      secondReviewWithData?.subReviews.map((userReview) => {
        const [x, y] = findIndexes(getValue(userReview, xKPI), getValue(userReview, yKPI));
        matrix[x][y].value += 1;

        const userId = userReview.createdFor;
        const originalReviewId = userReview.originalReview;

        matrix[x][y].value += 1;

        // keep userId and originalReviewId of first review
        if (
          !matrix?.[x]?.[y]?.users.find(
            (item) => item.userId === userId && originalReviewId === originalReviewId,
          )
        ) {
          matrix?.[x]?.[y]?.users.push({
            userId,
            originalReviewId,
          });
        }
      });
    }

    // 4. calculate the total number of users
    const total = matrix.reduce((previous, current) => {
      return previous + sumBy(current, 'value');
    }, 0);

    // 5. append correct percentage for each block of matrix
    matrix = matrix.map((row) => {
      return row.map((item) => ({
        ...item,
        percentage: (item.value / total) * 100,
      }));
    });

    return matrix;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(
      reviewWithData?.subReviews,
      secondReviewWithData?.subReviews,
      xKPI,
      yKPI,
      isLoading,
    ),
  ]);

  // hide kpi selected in X-axis
  // hide kpi selected in Y-axis
  const kpiItems = kpis.filter((kpi) => ![xKPI.id, yKPI.id].includes(kpi.id));

  const toggleSidebar = () => setIsSidebarOpen((prevState) => !prevState);
  const exportCSV = async () => {
    await downloadNinegridCSV({
      reviewIds,
      KPIs: KPIs.map((kpi) => kpi.id),
      jobProfiles: preparedFilters.jobProfiles || [],
      users: preparedFilters?.users || [],
    });
  };

  const update = async (labels) => {
    await dispatch(setCompanyLabels(labels));
  };

  const prepareData = (review) => {
    const reviews = {};

    if (!review) {
      return null;
    }

    review?.subReviews.forEach(({ id, createdFor, ...rest }) => {
      reviews[createdFor] = {
        id,
        user: users?.[createdFor],
        name: reviewWithData?.name,
        kpis: {
          [xKPI.id]: {
            ...xKPI,
            value: [getPureValue(rest, xKPI)],
          },
          [yKPI.id]: {
            ...yKPI,
            value: [getPureValue(rest, yKPI)],
          },
        },
      };
    });

    return reviews;
  };

  const items = useMemo(() => {
    let result = prepareData(reviewWithData);
    const secondReviewUsers = prepareData(secondReviewWithData);

    if (!result) {
      return [];
    }

    if (secondReviewUsers) {
      Object.values(secondReviewUsers)?.forEach((review) => {
        if (review?.user?.id && !result[review?.user?.id]) {
          result[review.user?.id] = {
            ...review,
            secondReview: {
              name: secondReviewWithData.name,
            },
            kpis: {
              [xKPI.id]: {
                ...xKPI,
                value: [null, review?.kpis[xKPI.id].value[0]],
              },
              [yKPI.id]: {
                ...yKPI,
                value: [null, review?.kpis[yKPI.id].value[0]],
              },
            },
          };
        } else {
          const tempReview = result[review.user?.id];
          result[review.user?.id] = {
            ...review,
            secondReview: {
              name: secondReviewWithData.name,
            },
            kpis: {
              ...tempReview.kpis,
              [xKPI.id]: {
                ...xKPI,
                value: [tempReview?.kpis[xKPI.id].value[0], review?.kpis[xKPI.id].value[0]],
              },
              [yKPI.id]: {
                ...yKPI,
                value: [tempReview?.kpis[yKPI.id].value[0], review?.kpis[yKPI.id].value[0]],
              },
            },
          };
        }
      });
    }

    return result ? Object.values(result) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify({
      reviewWithData,
      xKPI,
      yKPI,
      secondReviewWithData,
      selectedRoles,
      selectedTeams,
      selectedMembers,
      matrixArea,
    }),
  ]);

  const getPositions = useCallback(
    (userReview, xKPI, yKPI) => {
      return getPositionValue(
        userReview.createdFor,
        userReview.originalReview,
        getGridPosition(userReview.avg[xKPI.id], xKPI.type),
        getGridPosition(userReview.avg[yKPI.id], yKPI.type),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matrixArea],
  );

  // calculate correct position for avatars
  const getPositionValue = (userId, originalReviewId, x, y) => {
    const index = matrixArea?.[x]?.[y]?.users.findIndex(
      (item) => item.userId === userId && originalReviewId === originalReviewId,
    );

    if (index < 0) {
      return { x: null, y: null };
    }

    const maxColumn = 5;
    const margin = 1.5;
    const gapBetweenAvatars = 6.5;
    const rowHeight = 6;

    // first row
    if (index < maxColumn) {
      return {
        x: x * 33 + gapBetweenAvatars * index + margin,
        y: y * 33 + rowHeight * 2 + 3,
      };
    }

    // second row
    if (index < maxColumn * 2) {
      return {
        x: x * 33 + gapBetweenAvatars * (index - maxColumn) + margin,
        y: y * 33 + rowHeight,
        // show the length of remaining avatars instead of avatar
        extraCounter:
          matrixArea?.[x]?.[y]?.users?.length > maxColumn * 2 && index + 1 === maxColumn * 2
            ? `+${matrixArea?.[x]?.[y]?.users?.length + 1 - maxColumn * 2}`
            : null,
      };
    }

    return {
      x: null,
      y: null,
    };
  };

  const getGridPosition = (values, kpiType) => {
    if (kpiType === KPI_TYPES.SKILLS) {
      return findCorrectSpot(getValueSkills(values) / 100);
    } else {
      return findCorrectSpot(values[0] / values[1]);
    }
  };

  // find the correct spot, value should be between 0 and 1
  const findCorrectSpot = (value) => {
    const FIRST_BORDER = 1 / 3;
    const SECOND_BORDER = (1 / 3) * 2;
    const THIRD_BORDER = (1 / 3) * 3;

    if (value >= 0 && value <= FIRST_BORDER) {
      return 0;
    }

    if (value > FIRST_BORDER && value <= SECOND_BORDER) {
      return 1;
    }

    if (value > SECOND_BORDER && value <= THIRD_BORDER) {
      return 2;
    }
  };

  return (
    <BoxWrapper>
      <Sidebar isOpen={isSidebarOpen}>
        <div className="content">
          <Title isRotated={isSidebarOpen}>
            <Trans>Set-up your 9-grid</Trans>
          </Title>
          {isSidebarOpen && (
            <div className="top">
              <FiltersWrapper>
                <Dropdown>
                  <span className="title">
                    <Trans>1. Conversation(s)</Trans>
                  </span>
                  <StyledAutocompleteFilterReviews
                    showTooltip
                    checkedList={selectedReview ? [selectedReview] : []}
                    onChange={(reviews) => {
                      setReviewWithData();
                      if (reviews.length === 0) {
                        fillSecondReview(undefined);
                      }
                      fillReview(reviews.slice(-1)[0]);
                    }}
                    $error={getError()}
                    selectedItemBackground="linear-gradient(to left, #e253e8, #c129cc);"
                  />
                  <StyledAutocompleteFilterReviews
                    showTooltip
                    disabled={!selectedReview}
                    checkedList={secondSelectedReview ? [secondSelectedReview] : []}
                    onChange={(reviews) => {
                      if (!selectedReview) {
                        return;
                      }
                      const newReview = reviews.slice(-1)[0];
                      selectSecondReview(newReview);
                    }}
                    selectedItemBackground="linear-gradient(to left, #95aeff, #5c76ff);"
                  />
                </Dropdown>
                <Dropdown>
                  <span className="title">
                    <Trans>2. Values X & Y axis</Trans>
                  </span>
                  <KpiDropdown
                    kpi={xKPI}
                    items={kpiItems}
                    popoverRef={xAxisRef}
                    placeholder={i18n._(t`KPI X-axis`)}
                    isError={getError() || isXKPIError}
                    onClick={(value) => {
                      setReviewWithData();
                      setSecondReviewWithData();
                      setXKPI(value);
                      setIsXKPIError(false);
                    }}
                  />
                  <KpiDropdown
                    kpi={yKPI}
                    items={kpiItems}
                    popoverRef={yAxisRef}
                    placeholder={i18n._(t`KPI Y-axis`)}
                    isError={getError() || isYKPIError}
                    onClick={(value) => {
                      setReviewWithData();
                      setSecondReviewWithData();
                      setYKPI(value);
                      setIsYKPIError(false);
                    }}
                  />
                </Dropdown>
                <Dropdown>
                  <span className="title">
                    <Trans>3. Team / member</Trans>
                  </span>
                  <StyledAutocompleteFilterTeams
                    checkedList={selectedTeams}
                    onChange={setSelectedTeams}
                  />
                  <StyledAutocompleteFilterMembers
                    checkedList={selectedMembers}
                    onChange={setSelectedMembers}
                  />
                  <StyledAutocompleteFilterRoles
                    checkedList={selectedRoles}
                    onChange={setSelectedRoles}
                  />
                </Dropdown>
              </FiltersWrapper>
              <ApplyButton
                disabled={!selectedReview}
                label={i18n._(t`Apply`)}
                onClick={onApplyButton}
                loading={isLoading}
              />
            </div>
          )}
        </div>
        <CollapseButton
          className="collapseButton"
          onClick={toggleSidebar}
          isRotated={isSidebarOpen}
        >
          <CollapseIcon width={20} height={16} />
        </CollapseButton>
      </Sidebar>
      <Main>
        <Header>
          <ActionButton
            type={Button.types.shadow}
            label={i18n._(t`Edit labels`)}
            iconLeft={<Pencil2Icon size={20} />}
            onClick={$showUpdateLabelsModal.on}
          />
          <ActionButton
            type={Button.types.shadow}
            label={i18n._(t`Export csv`)}
            disabled={isEmpty(items)}
            iconLeft={<ExportIcon size={20} />}
            onClick={exportCSV}
          />
          <button
            style={{ cursor: 'pointer', marginRight: '4px', padding: 0 }}
            onClick={() => setView(ListViews.LIST)}
          >
            <SvgIcon
              width="32px"
              height="32px"
              url={listViewIcon}
              isDefaultColor={view !== ListViews.LIST}
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          </button>
          <button
            style={{ cursor: 'pointer', margin: 0, padding: 0 }}
            onClick={() => setView(ListViews.GRID)}
          >
            <SvgIcon
              width="32px"
              height="32px"
              url={gridViewIcon}
              isDefaultColor={view !== ListViews.GRID}
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          </button>
        </Header>
        {view === ListViews.GRID &&
          (isEmpty(items) && selectedReview && xKPI && yKPI ? (
            <Placeholder
              className="placeholderNoData"
              Icon={() => (
                <SvgIcon
                  width="32px"
                  height="32px"
                  url={ninegridIcon}
                  isDefaultColor={true}
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              subTitle={i18n._(t`No data available within this selection.`)}
            />
          ) : (
            <div className="grid">
              {reviewWithData && xKPI && yKPI && !getError() ? (
                <NineGridWrapper>
                  <ContentWrapper>
                    <YAxis>
                      <Rotation>
                        <GraphTitle>
                          <Trans>{yKPI.name}</Trans>
                        </GraphTitle>
                        <GraphLabels>
                          <GraphLabel $justifyContent="flex-start">Low</GraphLabel>
                          <GraphLabel $justifyContent="center">Medium</GraphLabel>
                          <GraphLabel $justifyContent="flex-end">High</GraphLabel>
                        </GraphLabels>
                      </Rotation>
                    </YAxis>
                    <NineGridContainerContainer>
                      {getGridRows()}
                      {xKPI &&
                        yKPI &&
                        reviewWithData &&
                        reviewWithData.subReviews.map((user) => {
                          const { x, y, extraCounter } = getPositions(user, xKPI, yKPI);
                          return extraCounter ? (
                            <ExtraCounter
                              key={user.createdFor}
                              $left={x}
                              $bottom={y}
                              borderColor="transparent"
                            >
                              {extraCounter}
                            </ExtraCounter>
                          ) : (
                            reviewWithData?.users.includes(user.createdFor) && (
                              <AvatarContainer
                                onMouseEnter={() => setHovered(user.createdFor)}
                                onMouseLeave={() => setHovered('')}
                                onClick={() => {
                                  navigateToProfile(user.createdFor);
                                }}
                                key={user.createdFor}
                                $left={x}
                                $bottom={y}
                                $blur={hovered && hovered !== user.createdFor}
                                borderColor="#c129cc"
                              >
                                <Chip background="#c129cc">A</Chip>
                                <Avatar
                                  borderRadius="2px"
                                  showTooltip
                                  size={28}
                                  userId={user.createdFor}
                                />
                              </AvatarContainer>
                            )
                          );
                        })}
                      {xKPI &&
                        yKPI &&
                        secondReviewWithData &&
                        secondReviewWithData.subReviews.map((user) => {
                          const { x, y, extraCounter } = getPositions(user, xKPI, yKPI);
                          return extraCounter ? (
                            <ExtraCounter
                              key={user.createdFor}
                              $left={x}
                              $bottom={y}
                              borderColor="transparent"
                            >
                              {extraCounter}
                            </ExtraCounter>
                          ) : (
                            <SecondReviewAvatarContainer
                              onMouseEnter={() => setHovered(user.createdFor)}
                              onMouseLeave={() => setHovered('')}
                              key={user.userId}
                              $left={x}
                              $blur={hovered && hovered !== user.createdFor}
                              $bottom={y}
                              borderColor="#5c76ff"
                            >
                              <Chip background="#5c76ff">B</Chip>
                              <Avatar
                                borderRadius="2px"
                                showTooltip
                                size={28}
                                userId={user.createdFor}
                              />
                            </SecondReviewAvatarContainer>
                          );
                        })}
                    </NineGridContainerContainer>
                    <YAxis width={0}>
                      <Rotation
                        rotate={90}
                        translate={0}
                        marginLeft="-20px"
                        transformOrigin="20px 20px"
                      >
                        <GraphLabels>
                          <GraphLabel $justifyContent="flex-start">High</GraphLabel>
                          <GraphLabel $justifyContent="center">Medium</GraphLabel>
                          <GraphLabel $justifyContent="flex-end">Low</GraphLabel>
                        </GraphLabels>
                      </Rotation>
                    </YAxis>
                  </ContentWrapper>
                  <XAxis>
                    <GraphLabels>
                      <GraphLabel $justifyContent="flex-start">
                        <Trans>Low</Trans>
                      </GraphLabel>
                      <GraphLabel $justifyContent="center">
                        <Trans>Medium</Trans>
                      </GraphLabel>
                      <GraphLabel $justifyContent="flex-end">
                        <Trans>High</Trans>
                      </GraphLabel>
                    </GraphLabels>
                    <GraphTitle>
                      <Trans>{xKPI.name}</Trans>
                    </GraphTitle>
                  </XAxis>
                </NineGridWrapper>
              ) : (
                <Placeholder
                  className="placeholderEmpty"
                  Icon={() => <HandPointingIcon size={32} />}
                  subTitle={i18n._(
                    t`Select conversation(s), values and members to set up your 9-grid.`,
                  )}
                />
              )}
            </div>
          ))}
        {view === ListViews.LIST &&
          (isEmpty(items) && selectedReview && xKPI && yKPI ? (
            <Placeholder
              className="placeholderNoData"
              Icon={() => (
                <SvgIcon
                  width="32px"
                  height="32px"
                  url={ninegridIcon}
                  isDefaultColor={true}
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              subTitle={i18n._(t`No data available within this selection.`)}
            />
          ) : (
            <div className="list">
              <ListComponent
                items={items}
                kpiColumns={[xKPI, yKPI]}
                isSecondConversationVisible={!!secondReviewWithData}
                isLoading={isLoading}
              />
            </div>
          ))}
      </Main>
      {$showUpdateLabelsModal.value && (
        <UpdateNinegridLabelsModal
          i18n={i18n}
          title={i18n._(t`Edit 9-grid labels`)}
          onClose={$showUpdateLabelsModal.off}
          labels={labels}
          update={update}
        />
      )}
    </BoxWrapper>
  );
};

export default NineGridBox;
