import React from 'react';

import { KPI_TYPES, KPI_NONE } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Editor from '~/components/Editor';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import SetupCard from '~/components/SetupTools/SetupCard';
import SvgIcon from '~/components/SvgIcon';
import { TextArea } from '~/components/Text';
import Tooltip from '~/components/Tooltip';
import ActionsPopover from '~/components/UI/ActionsPopover';
import { Header7, Header6, Header6Dark } from '~/components/UI/Typographics/headers';

import ReviewTypesToggler from './ReviewTypesToggler';

import DownArrowIcon from '~/assets/ic-keyboard-arrow-down.svg';
import UpArrowIcon from '~/assets/ic-keyboard-arrow-up.svg';

import { useScrollEffect } from '~/hooks/useScrollEffect';
import { COLOR_PALETTE, COLOR_SET, COLORS } from '~/styles';

const ActionsPopoverWrapper = styled(ActionsPopover)`
  width: 300px;
  display: flex;
  align-items: flex-start;
`;

const KPIWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const KPIInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const RatingScaleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UpdateRatingScale = styled.span`
  font-size: 14px;
  font-weight: 600;
  ${(props) => props.$active && 'color: var(--company-color);'}
  ${(props) => props.$active && 'cursor: pointer;'}
`;

const ActionsTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 300px;
  height: 48px;
  border-radius: 3px;
  padding: 15px;
  border: solid 1px ${COLORS.BORDER_HARD};

  h6 {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  input {
    width: 712px;
  }
`;

const OptionsRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
  color: ${COLOR_SET.MIDNICHT_BLUE};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  input {
    width: 712px;
  }
`;

const SpacedRow = styled(Row)`
  justify-content: space-between;
`;

const StyledTextArea = styled(TextArea)`
  height: 48px;
`;

const TopLabel = styled.div`
  width: 145px;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 24px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${COLOR_SET.MIDNICHT_BLUE};
  outline: none;
  cursor: pointer;

  h6 {
    color: ${COLOR_PALETTE.WHITE};
    margin: 0;
    text-transform: uppercase;
  }
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin-left: 5px;
`;

const DescBlock = styled.div`
  margin-top: 24px;
  ${Header6Dark} {
    margin: 0 0 10px;
  }
`;

const Header = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: black;
  margin: 20px 0 12px 0;
  width: fit-content;
`;

const Clickable = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const ScrollElement = styled.div`
  scroll-margin-top: 110px;
`;

const getScaleLabelText = (scaleLabels, i18n) => {
  return scaleLabels.length >= 2
    ? `${scaleLabels[0]} - ${scaleLabels[scaleLabels.length - 1]} (${scaleLabels.length} ${i18n._(
        t`levels`,
      )}) `
    : '';
};

const SectionCard = ({
  section,
  $template,
  sectionIndex,
  isActiv,
  changeActiveSection,
  dragProps,
  kpisOptions,
  kpi,
  openRateScaleModal,
  isShowErrors,
  sectionThemeOptions,
  themeLabel,
}) => {
  const { i18n } = useLingui();
  const { ref, scrollToRef } = useScrollEffect();
  const isNoneKpi = section.type === KPI_NONE.type && section.kpi === KPI_NONE.id;

  const handleChangeSection = () => {
    changeActiveSection();
    handleScrollToRef();
  };

  const handleChangeAdvancedOptions = () => {
    $template.toggleAdvancedOptions(section.id);
    handleScrollToRef();
  };

  const handleScrollToRef = () => {
    // Only scroll to section when the section is not active already
    if (!isActiv) {
      scrollToRef();
    }
  };

  return (
    <ScrollElement ref={ref}>
      <TopLabel
        $isActiv={isActiv}
        onClick={handleChangeSection}
        role="button"
        tabIndex={0}
        onKeyDown={handleChangeSection}
        {...dragProps}
      >
        <Header7>
          <Trans>Section</Trans> {sectionIndex}/{$template.sections.length}
        </Header7>
      </TopLabel>
      <SetupCard
        removeTopLeftRadius={true}
        isSelected={isActiv}
        error={isShowErrors}
        handleDelete={() => $template.deleteSection(section.id)}
        hideFooter={!($template.showAdvancedOptions && isActiv)}
        i18n={i18n}
        tooltip={i18n._(t`Delete this section and all questions in this section.`)}
      >
        <SpacedRow>
          <Header>{i18n._(t`Name`)}*</Header>
          <Clickable>
            <SvgIcon
              isDefaultColor={!isActiv}
              defaultColor={COLOR_SET.MIDNICHT_BLUE}
              onClick={() => {
                if (isActiv) {
                  $template.closeSection();
                } else {
                  $template.openSection(section.id);
                  handleScrollToRef();
                }
              }}
              url={isActiv ? UpArrowIcon : DownArrowIcon}
            />
          </Clickable>
        </SpacedRow>
        <SpacedRow>
          <StyledTextArea
            value={section.name || ''}
            onChange={(e) => $template.setSectionName(section.id, e.target.value, sectionIndex)}
            onFocus={handleChangeSection}
            placeholder={i18n._(t`Name the section…`)}
            $accent
            resize={true}
            error={isShowErrors && !section.name}
            rows="1"
          />
        </SpacedRow>
        <OptionsRow>
          <Clickable onClick={handleChangeAdvancedOptions}>
            <Trans>Advanced options</Trans>
            <StyledSvgIcon
              isDefaultColor
              defaultColor={COLOR_SET.MIDNICHT_BLUE}
              url={!($template.showAdvancedOptions && isActiv) ? DownArrowIcon : UpArrowIcon}
            />
          </Clickable>
        </OptionsRow>
        {$template.showAdvancedOptions && isActiv && (
          <>
            <DescBlock>
              <Header>
                <Trans>Description</Trans>
              </Header>
              <Editor
                value={section.description || ''}
                compact={true}
                small={true}
                onChange={(value) =>
                  $template.setSectionDescription(section.id, value, sectionIndex)
                }
              />
            </DescBlock>
            <Header>
              <Tooltip
                tooltip={i18n._(t`Select who needs to fill out the questions in this section.`)}
              >
                <div style={{ width: 'fit-content' }}>
                  {'90/180/360° '}
                  <Trans>Review</Trans>
                </div>
              </Tooltip>
            </Header>
            <ReviewTypesToggler
              section={section}
              onClick={(key, value) => $template.updateSectionKey(section.id, key, value)}
              isShowErrors={isShowErrors}
            />
            <KPIWrapper>
              <KPIInnerWrapper>
                <Header>
                  <Tooltip
                    tooltip={i18n._(
                      t`KPI's can be used to calculate the average rating for the questions in the section. In addition, KPI's are used to plot the participants in the 9-grid.`,
                    )}
                  >
                    <div style={{ width: 'fit-content' }}>
                      <Trans>Reporting (KPI)</Trans>
                    </div>
                  </Tooltip>
                </Header>
                <ActionsPopoverWrapper
                  items={kpisOptions}
                  isRows
                  placement="bottom"
                  maxWidth={300}
                  minWidth={300}
                  height="40px"
                >
                  <ActionsTextWrapper>
                    <Header6>{kpi.name}</Header6>
                    <Icon
                      icon={ICONS.DROPDOWN}
                      size={ICON_SIZES.SMALL}
                      color="var(--company-color)"
                    />
                  </ActionsTextWrapper>
                </ActionsPopoverWrapper>
              </KPIInnerWrapper>
              <KPIInnerWrapper>
                <Header>
                  <Tooltip
                    tooltip={i18n._(
                      t`Make sections stand out with a theme color and icon. Preview your result in the review preview.`,
                    )}
                  >
                    <div style={{ width: 'fit-content' }}>
                      <Trans>Theme</Trans>
                    </div>
                  </Tooltip>
                </Header>
                <ActionsPopoverWrapper
                  items={sectionThemeOptions}
                  isRows
                  placement="bottom"
                  maxWidth={300}
                  minWidth={300}
                  height="40px"
                >
                  <ActionsTextWrapper>
                    <Header6>{themeLabel}</Header6>
                    <Icon
                      icon={ICONS.DROPDOWN}
                      size={ICON_SIZES.SMALL}
                      color="var(--company-color)"
                    />
                  </ActionsTextWrapper>
                </ActionsPopoverWrapper>
              </KPIInnerWrapper>
            </KPIWrapper>
            <KPIWrapper>
              <KPIInnerWrapper>
                {!isNoneKpi ? (
                  <>
                    <Tooltip
                      tooltip={i18n._(t`The rating scale used for the questions in this section.`)}
                    >
                      <Header>
                        <Trans>Rating scale</Trans>
                      </Header>
                    </Tooltip>
                    <RatingScaleWrapper>
                      <span>
                        {section.type !== KPI_TYPES.SKILLS &&
                          getScaleLabelText(section.scaleLabels, i18n)}
                        <UpdateRatingScale
                          onClick={
                            section.type !== KPI_TYPES.SKILLS ? openRateScaleModal : undefined
                          }
                          $active={section.type !== KPI_TYPES.SKILLS}
                        >
                          {section.type === KPI_TYPES.SKILLS
                            ? i18n._(t`Adjust rating scale in settings`)
                            : i18n._(t`Update`)}
                        </UpdateRatingScale>
                      </span>
                    </RatingScaleWrapper>
                  </>
                ) : null}
              </KPIInnerWrapper>
            </KPIWrapper>
          </>
        )}
      </SetupCard>
    </ScrollElement>
  );
};

SectionCard.propTypes = {
  section: PropTypes.object,
  $template: PropTypes.object,
  changeActiveSection: PropTypes.func,
  changeEditSection: PropTypes.func,
  updateName: PropTypes.func,
  updateDescription: PropTypes.func,
  kpi: PropTypes.object,
};

export default React.memo(SectionCard);
