import React from 'react';

import { Dropdown } from '~/components/Dropdown';

import { getCompanyUsers } from '~/services/users';
import getUserFullName from '~/utils/getUserFullName';

import type { IUser } from '@learned/types';

interface IMembersFilterProps {
  selectedItems?: IUser[];
  onChange: (items: IUser[]) => void;
  isPermissionsCheck?: boolean;
  placeholder?: string;
}

const LIMIT = 20;

const AutocompleteFilterMembers = React.memo((props: IMembersFilterProps) => {
  const [items, setItems] = React.useState<IUser[]>([]);
  const [selectedItems, setSelectedItems] = React.useState<IUser[]>(props.selectedItems || []);

  const fetchUsers = async (search: string) => {
    const { data } = await getCompanyUsers({
      limit: LIMIT,
      search,
    });

    // @ts-ignore
    const users = Object.values(data.users).map((item: IUser) => ({
      ...item,
      key: item.id,
      name: getUserFullName(item),
    }));
    setItems(users);
  };

  const handleOnChange = (items: IUser[]) => {
    setSelectedItems(items);
    props.onChange(items);
  };

  return (
    <Dropdown
      items={items}
      isSearchable
      selectedItems={selectedItems}
      onChange={handleOnChange}
      isSingleSelect={false}
      // @ts-ignore
      stringifyItem={(item: IUser) => item.name}
      // @ts-ignore
      placeholder={props.placeholder}
      onSearchChange={fetchUsers}
    />
  );
});

export { AutocompleteFilterMembers };
