import React from 'react';

import styled from 'styled-components';

import { MultiSelectCheckBox } from './MultiSelectCheckBox';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{ color: string | undefined; isVisible?: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'inline-flex' : 'none')};
  align-items: center;
  cursor: pointer;

  &:hover {
    display: inline-flex;
  }

  svg {
    min-width: 20px;
    min-height: 20px;
    stroke: ${(props) => (props.color ? props.color : COLORS.GRAY_MIDDLE)};
  }
`;

interface CheckBoxProps {
  isSelected?: boolean;
  onChange?: () => void;
  color?: string;
  isHovered?: boolean;
}

export const CheckBox = ({
  onChange,
  isSelected = false,
  color,
  isHovered = false,
}: CheckBoxProps) => {
  return (
    <Wrapper onClick={onChange} color={color} isVisible={isSelected || isHovered}>
      <MultiSelectCheckBox selected={isSelected} color={color} />
    </Wrapper>
  );
};
