import React, { useEffect, useRef, useState } from 'react';

import { Trans } from '@lingui/macro';
import Tippy from '@tippyjs/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import SearchList from './SearchList';

import useBoolState from '~/hooks/useBoolState';
import useDataStore from '~/hooks/useDataStore';
import useDebounce from '~/hooks/useDebounce';
import { COLORS, COLOR_PALETTE } from '~/styles';

const Box = styled.div`
  height: 32px;
  border-radius: 6px;
  border: solid 1px ${(props) => (props.$error ? COLORS.ERROR : COLORS.BORDER_LIGHT)};
  background-color: ${COLOR_PALETTE.WHITE};
  color: ${COLOR_PALETTE.DARK_GRAY};
  min-width: 150px;
  padding: ${({ isSelected }) => (isSelected ? '0 8px 0 4px' : '8px')};
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SelectedItem = styled.div`
  background: ${({ background }) => (background ? background : COLORS.BG_PAGE)};
  color: ${({ background }) => (background ? 'white' : COLOR_PALETTE.DARK_GRAY)};
  padding: 1px 8px;
  border-radius: 4px;
  margin-right: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PoperWrap = styled.div`
  outline: none;
`;

const CountNotice = styled.span`
  font-size: 12px;
  color: var(--company-color);
`;

const ChevronIcon = styled(Icon)`
  margin-left: auto;
  min-width: 12px;
  min-height: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  margin-right: 8px;
`;

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const AutocompleteFilter = ({
  fetch,
  checkedList = [],
  onChange,
  labelProperty = 'name',
  labelPropertyReserve = 'name',
  placeholder,
  className,
  maxCount = 2,
  alphabeticalList = false,
  styles,
  disabled = false,
  dataMapping,
  searchPlaceholder,
  selectWidth,
  isDeleteDisabled,
  disableSearch = false,
  error,
  isSingleSelect = false,
  showTooltip = false,
  isSearchIcon = false,
  lastItem,
  isClosed,
  selectedItemBackground = null,
}) => {
  const ref = useRef();
  const $open = useBoolState();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);
  const $data = useDataStore({ fetch, startState: { loading: true } });

  useEffect(() => {
    if (isClosed) {
      $open.off();
    }
  }, [isClosed, $open]);

  useEffect(() => {
    if ($open.value) {
      $data.fetch(debouncedSearch);
    }
    // eslint-disable-next-line
  }, [debouncedSearch, $open.value]);

  const handleCheck = (option) => {
    onChange(isSingleSelect ? option : [...checkedList, option]);
  };

  const handleUncheck = (option) => {
    onChange(isSingleSelect ? null : checkedList.filter((i) => i.id !== option.id));
  };

  const listItems = dataMapping ? map($data.items, dataMapping) : $data.items;

  const renderPopover = () => {
    return $open.value ? (
      <PoperWrap tabIndex="0">
        <SearchList
          width={selectWidth}
          list={listItems}
          loading={$data.loading}
          checkedList={checkedList}
          onCheck={handleCheck}
          onUncheck={!isDeleteDisabled && handleUncheck}
          labelProperty={labelProperty}
          labelPropertyReserve={labelPropertyReserve}
          search={search}
          onChangeSearch={setSearch}
          alphabeticalList={alphabeticalList}
          searchPlaceholder={searchPlaceholder}
          disableSearch={disableSearch}
          showTooltip={showTooltip}
          lastItem={lastItem}
        />
      </PoperWrap>
    ) : null;
  };

  return (
    <>
      <Box
        onClick={!disabled ? $open.on : undefined}
        ref={ref}
        className={className}
        style={styles}
        $error={error}
        isSelected={!!checkedList.length}
      >
        {isSearchIcon && (
          <IconWrapper>
            <Icon icon={ICONS.SEARCH} color={COLORS.BORDER} />
          </IconWrapper>
        )}
        {checkedList.length === 0 && (placeholder || <Trans>Type to Search</Trans>)}
        {checkedList.slice(0, maxCount).map((item) => (
          <Tippy
            key={item.id}
            trigger="mouseenter"
            theme="light"
            popperOptions={tippyOpts}
            disabled={!showTooltip}
            content={
              typeof labelProperty === 'function'
                ? labelProperty(item)
                : item[labelProperty] || item[labelPropertyReserve]
            }
          >
            <SelectedItem key={item.id} background={selectedItemBackground}>
              {typeof labelProperty === 'function'
                ? labelProperty(item)
                : item[labelProperty] || item[labelPropertyReserve]}
            </SelectedItem>
          </Tippy>
        ))}
        {checkedList.length > maxCount && (
          <CountNotice>+{checkedList.length - maxCount}</CountNotice>
        )}
        {!disabled && (
          <ChevronIcon
            icon={ICONS.DROPDOWN}
            color={isEmpty(checkedList) ? COLORS.SUBTEXT : COLORS.COMPANY}
            size={ICON_SIZES.SMALL}
          />
        )}
      </Box>
      <Tippy
        visible
        placement="bottom-start"
        onClickOutside={$open.off}
        interactive
        maxWidth={650}
        offset={[0, 3]}
        reference={ref}
        render={renderPopover}
      />
    </>
  );
};

export const AutocompleteFilterTypes = {
  fetch: PropTypes.func,
  checkedList: PropTypes.array,
  onChange: PropTypes.func,
  labelProperty: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  placeholder: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  maxCount: PropTypes.number,
  alphabeticalList: PropTypes.bool,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  isDeleteDisabled: PropTypes.bool,
  labelPropertyReserve: PropTypes.string,
  disableSearch: PropTypes.bool,
  isSingleSelect: PropTypes.bool,
};
AutocompleteFilter.propTypes = AutocompleteFilterTypes;

export default React.memo(AutocompleteFilter);
