import React from 'react';

import { ROLES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import * as PropTypes from 'prop-types';

import Button from '~/components/Button';
import Modal from '~/components/Modal';

import UserItem from './UserItem';

function ReviewCompleteInfo({ users, signatures, digitalSign, onClose }) {
  const { i18n } = useLingui();

  return (
    <Modal
      title={<Trans>Complete status</Trans>}
      onClose={onClose}
      width={625}
      contentStyles={{ padding: 0 }}
      footerRight={<Button label={i18n._(t`Close`)} type="primary" onClick={onClose} />}
    >
      {users.map((userId) => {
        const signature = (signatures || []).find((signature) => signature.userId === userId);

        // only for users
        const isShowAgree = signature?.role === ROLES.USER && digitalSign?.isAgreeContent;
        const isAgree = signature?.isAgree;
        return (
          <UserItem
            key={userId}
            userId={userId}
            dateOfComplete={signature?.signatureDate}
            isCompleted={Boolean(signature)}
            isShowAgree={isShowAgree}
            isAgree={isAgree}
          />
        );
      })}
    </Modal>
  );
}

ReviewCompleteInfo.propTypes = {
  user: PropTypes.object,
  coaches: PropTypes.array,
  coachesSelected: PropTypes.array,
  onClose: PropTypes.func,
  onToggleCoach: PropTypes.func,
  onSave: PropTypes.func,
};

export { ReviewCompleteInfo };
