import React, { useEffect, useState } from 'react';

import { ROLES } from '@learned/constants';
import { Plural, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import IconButton from '~/components/IconButton';
import PaginationBar from '~/components/PaginationBar';
import Placeholder from '~/components/Placeholder';
import SearchButton from '~/components/SearchButton';
import SelectUsersModal from '~/components/SelectUsersModal';
import AvatarCard from '~/components/UI/AvatarCard';
import Divider from '~/components/UI/Divider';
import Table, { TableCol, TableRow } from '~/components/UI/Table';
import { Header4Dark } from '~/components/UI/Typographics/headers';

import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import useSearchState from '~/hooks/useSearchState';
import { getSelectedRole } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const PaddingFixer = styled.div`
  margin-top: 18px;
  padding: 0 15px;
`;

const StyledHeading3 = styled(Header4Dark)`
  margin: 8px 0;
`;

const UserActions = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PAGE_SIZE = 30;

const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };

const UserListPicker = ({
  title,
  users,
  selectedUsers,
  disabled,
  buttonLabel,
  modalLabel,
  onSubmit,
  className,
  showActions,
  loading,
  error,
  showButton,
  single,
  placeholder,
  noPlaceholder,
  additionalCols,
  isLimitUserCol,
}) => {
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [userList, setUserList] = useState([]);
  const [usersPaginated, setUsersPaginated] = useState([]);

  const { i18n } = useLingui();
  const modal = useBoolState(false);
  const search = useSearchState();
  const debouncedSearch = useDebounce(search.value, 300);
  const currentRole = useSelector(getSelectedRole);

  function handleSubmit(users) {
    onSubmit(users);
    modal.off();
  }

  const isCoach = currentRole === ROLES.COACH;

  useEffect(() => {
    setUserList(
      sortBy(
        (selectedUsers || []).filter((user) => {
          const userFullName = getUserFullName(user);
          user.userFullName = userFullName.toLowerCase();
          return search.has(userFullName);
        }),
        ['userFullName'],
      ),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, debouncedSearch]);

  // Reset pagination after editing filter
  useEffect(() => {
    if (!isEqual(DEFAULT_PAGINATION, pagination)) {
      setPagination(DEFAULT_PAGINATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffect(() => {
    setUsersPaginated(userList.slice(pagination.skip, pagination.index * pagination.limit));
  }, [pagination, userList]);

  const renderActions = (user) => (
    <UserActions>
      <IconButton
        onClick={() => {
          onSubmit([user], { isDelete: true });
          refreshPagination();
        }}
        size={24}
        noBorder
        color="none"
      >
        <Icon icon={ICONS.DELETE_BIN} size={ICON_SIZES.LARGE} color={COLORS.COMPANY} />
      </IconButton>
    </UserActions>
  );

  // To refresh the page data and number after removing all the items in a page
  const refreshPagination = () => {
    const { limit, index, skip } = pagination;
    if (index !== 1 && usersPaginated.length <= 1) {
      setPagination({ limit, index: index - 1, skip: skip - limit });
    }
  };

  const onPageChangeClick = ({ skip, limit, index }) => {
    setPagination({ skip, limit, index });
  };

  const readOnly = disabled || !showActions;
  const columns = [
    { title: t`Participant`, width: isLimitUserCol ? '300px' : '' },
    ...additionalCols.map((c) => ({
      title: c.title,
      width: c.width,
      tippy: c.tippy,
      node: c.node,
      tooltipProps: c.tooltipProps,
    })),
    !readOnly && { title: '', width: '50px' },
  ].filter(Boolean);

  return (
    <div className={className}>
      {!isCoach && (
        <PaddingFixer>
          <Header>
            <StyledHeading3>
              {title || (
                <Plural value={userList.length} one="# participant" other="# participants" />
              )}
            </StyledHeading3>
            <SearchButton value={search.value} onChange={search.setForInput} />
          </Header>
        </PaddingFixer>
      )}
      <Divider />
      <Table
        cols={columns}
        loading={loading}
        items={usersPaginated}
        textPlaceholder={
          noPlaceholder ? (
            ''
          ) : (
            <Placeholder
              title={i18n._(t`No participants`)}
              subTitle={
                placeholder || i18n._(t`You have not added any participants to the conversation`)
              }
              Icon={() => <Icon icon={ICONS.USERS} width={50} height={50} color={COLORS.BG_PAGE} />}
              isError={error}
            />
          )
        }
        renderRow={(u) => {
          return (
            <TableRow key={u.id}>
              <TableCol>
                <AvatarCard userId={u.id || u} isEmailStyles />
              </TableCol>
              {additionalCols.map(({ render }) => render(u))}
              {!readOnly && <TableCol>{renderActions(u)}</TableCol>}
            </TableRow>
          );
        }}
      />

      {userList.length > PAGE_SIZE && (
        <>
          <Divider />
          <PaginationBar
            pagination={pagination}
            changePagination={onPageChangeClick}
            count={userList.length}
            noBorder
            showCount
          />
        </>
      )}

      {showButton && (
        <>
          <Divider />
          <PaddingFixer>
            <Button
              type="primary-border-wide"
              onClick={modal.on}
              disabled={disabled || (single && selectedUsers.length > 0)}
              loading={loading}
              label={buttonLabel || `+ ${i18n._(t`Participants`)}`}
            />
          </PaddingFixer>
        </>
      )}
      {modal.value && (
        <SelectUsersModal
          singleChoice={single}
          title={modalLabel || i18n._(t`Select participants`)}
          items={users.filter((u) => selectedUsers.map((u) => u.id).indexOf(u.id) === -1)}
          onModalClose={modal.off}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

UserListPicker.propTypes = {
  showActions: PropTypes.bool,
  single: PropTypes.bool,
  noPlaceholder: PropTypes.bool,
  disabled: PropTypes.bool,
};

UserListPicker.defaultProps = {
  selectedUsers: [],
  users: [],
  showActions: true,
  single: false,
  noPlaceholder: false,
  disabled: false,
  isLimitUserCol: false,
  additionalCols: [],
};

export default React.memo(UserListPicker);
