import React from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';
import IconButton from '~/components/IconButton';
import DeleteIcon from '~/components/Icons/Delete';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import Switch from '~/components/Switch';
import AvatarCard from '~/components/UI/AvatarCard';

import { COLOR_PALETTE } from '~/styles';

const RequestBox = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
`;

const AvatarColumn = styled(AvatarCard)`
  flex: 1;
`;

const ActionColumns = styled.div`
  flex: 0 0 24px;
`;

const TrashWrapper = styled.div`
  cursor: pointer;
  margin-right: 12px;
`;

const NomineesCoachItem = ({
  coachId,
  onToggle,
  onDelete,
  value,
  isDisabled,
  isParticipants,
  isDeleteVisible = true,
} = {}) => {
  const { i18n } = useLingui();
  const deleteCoach = async () => {
    if (
      await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Are you sure you want to delete this participant?`),
      })
    ) {
      onToggle(false);
    }
  };
  return (
    <RequestBox key={coachId}>
      <AvatarColumn userId={coachId} type={AvatarCard.TYPES.LARGE} />
      <ActionColumns>
        {onToggle && !isParticipants && (
          <Switch onChange={onToggle} checked={value} disabled={isDisabled} />
        )}
        {!isDisabled && onDelete && isDeleteVisible && (
          <ActionColumns>
            <IconButton
              onClick={onDelete}
              fill={false}
              color={COLOR_PALETTE.GRAY_MIDDLE}
              noBorder
              size={24}
            >
              <DeleteIcon size={20} />
            </IconButton>
          </ActionColumns>
        )}
        {!isDisabled && isParticipants && isDeleteVisible && (
          <TrashWrapper onClick={deleteCoach}>
            <Icon icon={ICONS.DELETE_BIN} height={20} width={20} />
          </TrashWrapper>
        )}
      </ActionColumns>
    </RequestBox>
  );
};

NomineesCoachItem.propTypes = {
  coachId: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};

export default React.memo(NomineesCoachItem);
