import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import RatingScale from '~/components/RatingScale';
import { Body14 } from '~/components/UI/Typographics/text';

import SkillFocusAreas from './SkillFocusAreas';

import { COLOR_PALETTE, COLORS } from '~/styles';

const RatingBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const CheckBoxBlock = styled.div`
  display: flex;
  margin-left: 20px;
`;

export const CheckBoxLabel = styled(Body14)`
  margin-left: 11px;
  line-height: 1.71;
`;

const FocusWrap = styled.div`
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 12px;
  min-height: 120px;
  max-height: 150px;
  overflow: auto;
  background: ${COLORS.BG_PAGE};
  padding: ${(props) => (props.$isFA ? '0' : '12px 22px 12px 17px')};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  display: flex;
  justify-content: ${(props) => (props.$isFA ? 'left' : 'center')};
  align-items: ${(props) => (props.$isFA ? 'top' : 'center')};
  border-radius: 3px;
`;

const RatingRow = ({
  rate,
  scale,
  scaleLabels,
  onChange,
  isSkipped,
  changeSkipped,
  isSkippable = true,
  isSingleHover = false,
  skillFocusAreas = [],
  hideFocusAreas = true,
  expectedLevel,
  disabled = false,
}) => {
  const [hoverRate, setHoverRate] = useState(null);
  const indexLabel = scale.indexOf(hoverRate || rate);

  const focusAreas = skillFocusAreas.filter((fa) => Number(fa.level) === Number(hoverRate || rate));

  return (
    <>
      <RatingBox>
        <RatingScale
          selectedRate={rate}
          expectedRate={expectedLevel}
          scale={scale}
          scaleLabels={scaleLabels}
          onChange={onChange}
          isBig
          readonly={isSkipped}
          onChangeHoverLevel={!disabled && setHoverRate}
          isSingleHover={isSingleHover}
          disabled={disabled}
        />
        {isSkippable && (
          <CheckBoxBlock>
            <CheckBox disabled={disabled} checked={isSkipped} onChange={changeSkipped} size={24} />
            <CheckBoxLabel>
              <Trans>N/A</Trans>
            </CheckBoxLabel>
          </CheckBoxBlock>
        )}
      </RatingBox>
      {!hideFocusAreas && (
        <FocusWrap $isFA={focusAreas.length > 0}>
          {focusAreas.length > 0 ? (
            <SkillFocusAreas focusAreas={focusAreas} skillLabel={scaleLabels[indexLabel]} />
          ) : (
            <Trans>The expected focus areas for the selected level level will show here</Trans>
          )}
        </FocusWrap>
      )}
    </>
  );
};

export default React.memo(RatingRow);
