import React, { ReactNode, useEffect, useState } from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import PaginationBar from '~/components/PaginationBar';
import SvgIcon from '~/components/SvgIcon';
import { Table } from '~/components/Table';
import { TablePlaceholder } from '~/components/TablePlaceholder';
import UserDevelopmentCycleModal from '~/components/UserDevelopmentCycleModal';
import { CalendarDropdown, DateOption } from '~/pages/Conversations/components/CalendarDropdown';
import { TableHeader } from '~/pages/Conversations/components/TableHeader';

import { COLUMNS, SORT_OPTIONS } from './columns';

import EyeSlashIcon from '~/assets/eye-slash.svg';
import EyeIcon from '~/assets/eye.svg';

import { CONVERSATION_COLLECTION_TYPES } from '~/constants';
import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import { getSelectedRole } from '~/selectors/baseGetters';
import { getConversationsAndReviews } from '~/services/userConversations';
import { getConversationPath } from '~/utils/userConversationsUtils';

import type { IReview, IUser } from '@learned/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

const FilterMargin = styled.div`
  margin-right: 10px;
`;

const StyledButton = styled.div<{ primary?: boolean; noBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 32px;
  border-radius: 16px;
  ${(props) => !props.noBorder && 'box-shadow: 0 2px 4px 0 rgba(145, 157, 165, 0.12);'}
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  margin-left: 8px;
  ${(props) => {
    if (props.primary) {
      return 'background-color: var(--company-color); color: white';
    } else {
      return 'background-color: white, color: var(--company-color);';
    }
  }}
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

const FiltersWrapper = styled.div`
  padding: 0px 32px 20px 32px;
  background: white;
  display: flex;
  flex-direction: row;
`;

const FilterWrapper = styled.div`
  margin-left: 16px;
`;

export interface IType {
  id: string;
  key: string;
  name: string;
  translated: (i18n: any) => ReactNode;
}

type IFilter = {
  isShowFilters: boolean;
  search: string;
  sortBy: SORT_OPTIONS;
  selectedDateOption: DateOption | undefined;
};

const initialFilters = {
  isShowFilters: false,
  search: '',
  statuses: [],
  types: [],
  sortBy: SORT_OPTIONS.NAME_A_Z,
  selectedDateOption: undefined,
};

const LS_KEY = 'LS_DEVELOPMENT_MEETINGS_USER_PUBLIC';

const Meetings = ({ user }: { user: IUser }) => {
  const { i18n } = useLingui();
  const params = useParams();
  const userId = get(params, 'userId') || user.id;

  const history = useHistory();
  const selectedRole = useSelector(getSelectedRole);
  const [items, setItems] = useState<IReview[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const { pagination, changePagination, resetPagination } = usePagination(10);
  const [currentFilters, setCurrentFilters] = useState<IFilter>(initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewYearModal, setIsPreviewYearModal] = useState(false);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch

  const fetchData = async () => {
    setIsLoading(true);

    const {
      conversations: items,
      total,
      upcoming,
    } = await getConversationsAndReviews({
      search: currentFilters.search || null,

      // filters
      ...(currentFilters.selectedDateOption && {
        startDate: currentFilters.selectedDateOption.fromDate,
      }),
      ...(currentFilters.selectedDateOption && {
        endDate: currentFilters.selectedDateOption.toDate,
      }),

      // requirements
      // when date is selected  use range filter to include past events also
      tabsType: currentFilters.selectedDateOption ? 'range' : 'upcoming',
      collectionType: CONVERSATION_COLLECTION_TYPES.CONVERSATION.key,
      userId,

      // options
      skip: pagination.skip,
      limit: pagination.limit,
      order: currentFilters.sortBy,
    });

    setItems(Object.values(items));
    // when there is a date selected we should base on the total as it could have past events also
    setTotalCount(currentFilters.selectedDateOption ? total : upcoming);
    setIsLoading(false);
  };

  // first render
  useEffect(() => {
    // get filters from localStorage
    const localStorageData = localStorage.getItem(LS_KEY);
    const isLocalStorageData = !isEmpty(localStorageData);

    if (isLocalStorageData) {
      const parsedData = JSON.parse(localStorageData as string);

      setCurrentFilters(parsedData);

      // set pagination from localstorage
      if (parsedData.pagination) {
        changePagination(parsedData.pagination);
      }
    }

    // eslint-disable-next-line
  }, []);

  // change filters fetch
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, [userId, pagination.limit, pagination.skip, JSON.stringify(debCurrentFilters)]);

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    const newPagination = {
      ...pagination,
      limit,
    };
    onPaginationChange(newPagination);
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    const newPagination = {
      ...pagination,
      skip,
      index,
    };
    onPaginationChange(newPagination);
  };

  // admin can not create meetings
  // only coach or user
  const actionButton =
    selectedRole === ROLES.ADMIN
      ? undefined
      : {
          label: t`Create meeting`,
          onClick: () => {
            history.push(
              routes.CONVERSATION_CREATE.build(
                undefined,
                // @ts-ignore
                {
                  isBackPath: true,
                  // @ts-ignore
                  hash: 'meetings',
                  query: { users: [userId] },
                },
              ),
            );
          },
        };

  const onCurrentFiltersChange = (newFilters: IFilter) => {
    setCurrentFilters(newFilters);

    // store in localstorage
    localStorage.setItem(LS_KEY, JSON.stringify(newFilters));
  };

  const onPaginationChange = (newPagination: typeof pagination) => {
    changePagination(newPagination);

    // store in localstorage
    localStorage.setItem(LS_KEY, JSON.stringify({ ...currentFilters, pagination: newPagination }));
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) => {
      const newFilters = { ...currentFilters, search: value };
      onCurrentFiltersChange(newFilters);
      resetPagination();
    },

    // @ts-ignore
    onChangeFilter: (key, value) => {
      const newFilters = { ...currentFilters, [key]: value };
      onCurrentFiltersChange(newFilters);

      // do not reset pagination for keys
      if (!['isShowFilters'].includes(key)) {
        resetPagination();
      }
    },
    resetFilters: () => onCurrentFiltersChange(initialFilters),
    selectedDateOption: currentFilters.selectedDateOption,
  };

  const onItemClick = {
    column: 'name',
    onClick: (item: any) => {
      const path = getConversationPath({
        conversation: item,
        selectedRole,
        userId: user.id,
        user,
        teams: undefined,
      });
      history.push(path as string);
    },
  };

  return (
    <Wrapper>
      <TableHeader filters={filters} actionButton={actionButton}>
        <FilterMargin>
          <StyledButton
            onClick={() => filters.onChangeFilter('isShowFilters', !filters.isShowFilters)}
            noBorder
          >
            <IconContainer>
              <SvgIcon
                width="20px"
                height="20px"
                url={filters.isShowFilters ? EyeSlashIcon : EyeIcon}
              />
            </IconContainer>
            {i18n._(filters.isShowFilters ? t`Hide filters` : t`Show filters`)}
          </StyledButton>
        </FilterMargin>
      </TableHeader>

      {filters.isShowFilters && (
        <FiltersWrapper>
          <CalendarDropdown
            disabled={false}
            selectedItem={filters.selectedDateOption}
            // @ts-ignore
            setSelectedItem={(value: IType[]) =>
              filters.onChangeFilter('selectedDateOption', value)
            }
            filterOptions={[2, 1, 5, 6, 9]}
          />
          <FilterWrapper>
            <Button
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`Reset all filters`)}
              onClick={filters.resetFilters}
              icon={ICONS.CLOSE}
            />
          </FilterWrapper>
        </FiltersWrapper>
      )}

      <Table
        data={items}
        columns={COLUMNS}
        sortBy={currentFilters.sortBy}
        setSortBy={(sortBy: SORT_OPTIONS) => onCurrentFiltersChange({ ...currentFilters, sortBy })}
        showMenu
        showDraftStatus
        onColClick={onItemClick}
      />
      {isEmpty(items) && (
        <TablePlaceholder
          isLoading={isLoading}
          isFiltered={!!currentFilters.search.length}
          noResultText={i18n._(t`No meetings found`)}
          emptyStateText={i18n._(t`No meetings yet… Let’s create one!`)}
        />
      )}
      <PaginationBar
        pagination={pagination}
        changePagination={onPageChangeClick}
        changePageSize={handleChangeItemsPerPage}
        count={totalCount}
        noShadow
        noBorder
        noTopBorder
        showCount
      />
      {isPreviewYearModal && (
        <UserDevelopmentCycleModal onClose={() => setIsPreviewYearModal(false)} />
      )}
    </Wrapper>
  );
};

export { Meetings };
