import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Placeholder from '~/components/Placeholder';
import SelectTeamModal from '~/components/SelectTeamModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SectionBodyProps, Team } from '~/pages/PassportPage/types';

import { SectionHeader } from './SectionHeader';
import { SectionList } from './SectionList';

import zeroState from '~/assets/no-result.webp';

import useBoolState from '~/hooks/useBoolState';
import {
  addCoaches as addCoachesToTeam,
  removeCoachFromTeam as removeCoach,
} from '~/store/teams/actions';
import getCoachTeams from '~/utils/getCoachTeams';

const StateImg = styled.img`
  width: 75px;
  height: auto;
`;

const SectionCoachTeams = ({ userId, teamsState: teams, fetching }: SectionBodyProps) => {
  const { i18n } = useLingui();
  const $modal = useBoolState();
  const $loading = useBoolState();
  const dispatch = useDispatch();

  const addCoachToTeam = async (selectedTeams: Team[]) => {
    $loading.on();
    selectedTeams.forEach(async (team) => {
      await dispatch(addCoachesToTeam(team.id, [userId]));
    });
    $loading.off();
    $modal.off();
  };

  const removeCoachFromTeam = async (teamId: string) => {
    $loading.on();
    await dispatch(removeCoach(teamId, userId));
    $loading.off();
  };

  const userTeams = teams ? getCoachTeams(userId, Object.values(teams)) : [];
  return (
    <div>
      <SectionHeader
        title={i18n._(t`Coach of teams`)}
        description={''}
        actionLabel={i18n._(t`Assign`)}
        actionOnClick={$modal.on}
      />
      <ShowSpinnerIfLoading loading={fetching || false}>
        <SectionList
          items={userTeams}
          itemActionLabel={i18n._(t`Remove`)}
          itemActionOnClick={removeCoachFromTeam}
          itemActionLoading={$loading.value}
          placeholder={
            <Placeholder
              title={i18n._(t`No teams`)}
              subTitle={''}
              Icon={() => <StateImg alt="noTeams" src={zeroState} />}
            />
          }
        />
      </ShowSpinnerIfLoading>

      {$modal.value && (
        <SelectTeamModal
          // @ts-ignore
          teams={teams}
          title={i18n._(t`Make coach of team`)}
          onModalClose={$modal.off}
          onSubmit={addCoachToTeam}
          selectedUser={{ id: userId }}
        />
      )}
    </div>
  );
};

export { SectionCoachTeams };
