import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Wrapper = styled.div`
  padding: 16px 8px 16px 20px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  width: 100%;
  box-sizing: border-box;
  display: flex;
`;

const IconBlock = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
`;

const TextBlock = styled.div``;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-bottom: 6px;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const Tip = () => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <IconBlock>
        <Icon icon={ICONS.NOTIFICATIONS} width={33} height={33} color={COLORS.ICONS_SECONDARY} />
      </IconBlock>
      <TextBlock>
        <Title>{i18n._(t`Notifications`)}</Title>
        <Description>
          {i18n._(
            t`Participants will receive automatic (email) notifications at the start date, 7 days before the deadline and at the day of the deadline.`,
          )}
        </Description>
      </TextBlock>
    </Wrapper>
  );
};

export { Tip };
