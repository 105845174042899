import React from 'react';

import { KPI_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { confirm } from '~/components/ConfirmDialog';
import Hint from '~/components/Hint';
import IconButton from '~/components/IconButton';
import DeleteIcon from '~/components/Icons/Delete';
import { TextField } from '~/components/Text';

import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const IconSection = styled.div`
  width: 20px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = ({ kpi, onDelete, onUpdate, isError }) => {
  const { i18n } = useLingui();

  const onDeleteKPI = async () => {
    const textConfirm = i18n._(
      t`Are you sure? You will not be able to analyse this KPI in reports or select this KPI in review templates. This action cannot be undone.`,
    );

    if (await confirm(i18n, textConfirm)) {
      onDelete(kpi.id);
    }
  };

  const getDefaultKPIName = (defaultType) => {
    const names = {
      [KPI_TYPES.SKILLS]: i18n._(t`skill coverage`),
      [KPI_TYPES.POTENTIAL]: i18n._(t`potential`),
      [KPI_TYPES.PERFORMANCE]: i18n._(t`performance`),
    };

    return names[defaultType] || '';
  };

  return (
    <Wrapper>
      <TextField
        type="text"
        name="name"
        value={kpi.name}
        onChange={(e) => onUpdate(kpi.id, e.target.value)}
        error={isError}
      />

      <IconSection>
        {kpi.type ? (
          <Hint>
            <div>
              {i18n._(
                t`Default KPI: "${getDefaultKPIName(
                  kpi.type,
                )}". It is not possible to delete default KPI`,
              )}
            </div>
          </Hint>
        ) : (
          <IconButton
            size={24}
            onClick={onDeleteKPI}
            noBorder
            colorIcon={COLOR_PALETTE.GRAY_MIDDLE}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </IconSection>
    </Wrapper>
  );
};

Row.propTypes = {
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  isError: PropTypes.bool,
};

Row.defaultProps = {
  onDelete: () => {},
  onUpdate: () => {},
  isError: false,
};

export { Row };
