import React from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import SetupCard from '~/components/SetupTools/SetupCard';
import SvgIcon from '~/components/SvgIcon';

import CustomQuestion from './CustomQuestion';
import GoalEvalQuestion from './GoalEvalQuestion';
import GoalPlanQuestion from './GoalPlanQuestion';
import JobProfileQuestion from './JobProfileQuestion';
import SkillQuestion from './SkillQuestion';

import DownArrowIcon from '~/assets/ic-keyboard-arrow-down.svg';
import UpArrowIcon from '~/assets/ic-keyboard-arrow-up.svg';

import { useScrollEffect } from '~/hooks/useScrollEffect';
import { COLOR_SET } from '~/styles';

const HEADER_TITLE = {
  [QUESTION_TYPES.CUSTOM]: t`Question`,
  [QUESTION_TYPES.SKILL]: t`Custom skills`,
  [QUESTION_TYPES.JOB_PROFILE]: t`Role profile skills`,
  [QUESTION_TYPES.GOAL_BUSINESS_EVAL]: t`Evaluate Business Goals`,
  [QUESTION_TYPES.GOAL_LEARNING_EVAL]: t`Evaluate Learning Goals`,
  [QUESTION_TYPES.GOAL_BUSINESS_PLAN]: t`Plan business goals`,
  [QUESTION_TYPES.GOAL_LEARNING_PLAN]: t`Plan Learning goals`,
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${COLOR_SET.BLACK};
  margin-bottom: 18px;
`;

const Clickable = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

function QuestionByType({
  question,
  updateName,
  updateDescription,
  handleQuestionRating,
  isNoneSectionType,
  sectionScaleLabels,
  deleteScaleLabel,
  updateScaleLabel,
  addScaleLabel,
  isSkippable,
  toggleIsSkippable,
  isCommentObligated,
  toggleIsCommentObligated,
  toggleIsAverageQuestionEnabled,
  isAverageQuestionEnabled,
  skillLabels,
  isShowErrors,
  toggleSkillCategories,
  isCollapsed,
}) {
  switch (question.type) {
    case QUESTION_TYPES.CUSTOM: {
      return (
        <CustomQuestion
          question={question}
          updateName={updateName}
          updateDescription={updateDescription}
          handleQuestionRating={handleQuestionRating}
          isNoneSectionType={isNoneSectionType}
          sectionScaleLabels={sectionScaleLabels}
          deleteScaleLabel={deleteScaleLabel}
          updateScaleLabel={updateScaleLabel}
          addScaleLabel={addScaleLabel}
          isSkippable={isSkippable}
          toggleIsSkippable={toggleIsSkippable}
          isShowErrors={isShowErrors}
          isCommentObligated={isCommentObligated}
          toggleIsCommentObligated={toggleIsCommentObligated}
          isCollapsed={isCollapsed}
        />
      );
    }
    case QUESTION_TYPES.JOB_PROFILE: {
      return (
        <JobProfileQuestion
          question={question}
          skillLabels={skillLabels}
          isSkippable={isSkippable}
          toggleIsSkippable={toggleIsSkippable}
          isCommentObligated={isCommentObligated}
          toggleIsCommentObligated={toggleIsCommentObligated}
          toggleSkillCategories={toggleSkillCategories}
          isCollapsed={isCollapsed}
        />
      );
    }
    case QUESTION_TYPES.GOAL_LEARNING_EVAL:
    case QUESTION_TYPES.GOAL_BUSINESS_EVAL: {
      return (
        <GoalEvalQuestion
          question={question}
          skillLabels={skillLabels}
          isSkippable={isSkippable}
          toggleIsSkippable={toggleIsSkippable}
          isCommentObligated={isCommentObligated}
          toggleIsCommentObligated={toggleIsCommentObligated}
          updateName={updateName}
          isShowErrors={isShowErrors}
          deleteScaleLabel={deleteScaleLabel}
          updateScaleLabel={updateScaleLabel}
          sectionScaleLabels={sectionScaleLabels}
          addScaleLabel={addScaleLabel}
          isNoneSectionType={isNoneSectionType}
          isCollapsed={isCollapsed}
          toggleIsAverageQuestionEnabled={toggleIsAverageQuestionEnabled}
          isAverageQuestionEnabled={isAverageQuestionEnabled}
        />
      );
    }
    case QUESTION_TYPES.GOAL_LEARNING_PLAN:
    case QUESTION_TYPES.GOAL_BUSINESS_PLAN: {
      return (
        <GoalPlanQuestion
          question={question}
          updateName={updateName}
          updateDescription={updateDescription}
          isShowErrors={isShowErrors}
          isCollapsed={isCollapsed}
        />
      );
    }
    case QUESTION_TYPES.SKILL: {
      return (
        <SkillQuestion
          question={question}
          skillLabels={skillLabels}
          isSkippable={isSkippable}
          toggleIsSkippable={toggleIsSkippable}
          isCommentObligated={isCommentObligated}
          toggleIsCommentObligated={toggleIsCommentObligated}
          isCollapsed={isCollapsed}
        />
      );
    }
  }
}

const ScrollElement = styled.div`
  scroll-margin-top: 110px;
`;

const QuestionCard = (props) => {
  const {
    question,
    deleteQuestion,
    updateQuestion,
    isActive,
    changeActiveQuestion,
    resetActiveQuestion,
    dragProps,
    copyQuestion,
    copyTooltip,
  } = props;
  const { i18n } = useLingui();
  const { ref, scrollToRef } = useScrollEffect();

  const handleChangeActiveQuestion = () => {
    changeActiveQuestion();

    // Only scroll to question when the question is not active already
    if (!isActive) {
      scrollToRef();
    }
  };

  return (
    <ScrollElement ref={ref}>
      <SetupCard
        isSelected={isActive}
        onClick={handleChangeActiveQuestion}
        dragProps={dragProps}
        handleUpdate={question.type === QUESTION_TYPES.SKILL ? updateQuestion : null}
        handleDelete={deleteQuestion}
        copyItem={copyQuestion}
        copyTooltip={copyTooltip}
        hideFooter={!isActive}
      >
        <Header>
          {i18n._(HEADER_TITLE[question.type])}
          <Clickable>
            <SvgIcon
              onClick={() => (isActive ? resetActiveQuestion() : handleChangeActiveQuestion())}
              url={!isActive ? UpArrowIcon : DownArrowIcon}
            />
          </Clickable>
        </Header>
        {QuestionByType({ ...props, isCollapsed: !isActive })}
      </SetupCard>
    </ScrollElement>
  );
};

export default React.memo(QuestionCard);
