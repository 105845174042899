import { pageOverview, pageUpdate } from '~/constants/routes/utils/helpers';

const routeName = 'superadmin';

// overview

export const SUPERADMIN_COMPANY_DASHBOARD = {
  url: `/${pageOverview(routeName)}/companies`,
};

export const SUPERADMIN_COMPANY_MEMBERS = {
  url: `/${pageOverview(routeName)}/company-members`,
};

export const SUPERADMIN_OVERVIEW = {
  url: `/${pageOverview(routeName)}/overview`,
};

export const SUPERADMIN_TEMPLATES_REVIEW = {
  url: `/${pageOverview(routeName)}/templates/review`,
};

export const SUPERADMIN_TEMPLATE_REVIEW_CREATE = {
  url: `/${pageOverview(routeName)}/templates/review-create`,
};

export const SUPERADMIN_TEMPLATE_REVIEW_UPDATE = {
  url: ({ reviewTemplateId = ':reviewTemplateId' }) =>
    pageUpdate(SUPERADMIN_TEMPLATES_REVIEW.url, { id: reviewTemplateId }),
};

export const SUPERADMIN_REQUESTS = {
  url: `/${pageOverview(routeName)}/requests`,
};
