import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import AvatarGroup from '~/components/UI/AvatarGroup';

import { Item, ItemNameText } from './CommonStyledComponents';

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 110px;
`;

const ItemWrapper = styled(Item)`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ViewReportItem = ({ goToReport, review }) => {
  const { i18n } = useLingui();
  return (
    <ItemWrapper>
      <Row>
        {review && review.conversationCoaches && (
          <AvatarGroup maxUsersToShow={5} size={28} users={review.conversationCoaches || []} />
        )}
        <ItemNameText>
          <Trans>Discuss the report</Trans>
        </ItemNameText>
      </Row>
      <Actions>
        {' '}
        <Button
          type={Button.types.linkPrimary}
          onClick={goToReport}
          label={i18n._(t`View`)}
          styles={{ minWidth: '83px', padding: 0 }}
        />
      </Actions>
    </ItemWrapper>
  );
};

export default ViewReportItem;
