import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import PlusIcon from '~/components/Icons/Plus';

const Box = styled.div`
  margin-top: 8px;
`;

class NewFocusArea extends Component {
  static propTypes = {
    level: PropTypes.number,
    onCreate: PropTypes.func,
  };

  static defaultProps = {
    level: 1,
    onCreate: () => {},
  };

  handleSubmit = () => {
    this.props.onCreate({ name: '', level: this.props.level });
  };

  render() {
    const { i18n } = this.props;

    return (
      <Box>
        <Button
          type="link-primary"
          label={i18n._(t`Add focus area`)}
          iconLeft={<PlusIcon size={20} />}
          onClick={this.handleSubmit}
          styles={{ paddingLeft: 0, lineHeight: '1.71' }}
        />
      </Box>
    );
  }
}

export default withI18n()(NewFocusArea);
