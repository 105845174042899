import type { ReactNode, MouseEventHandler } from 'react';

import type { I18n } from '@lingui/core';

export interface ITableConfig<T> {
  columns: IColumnTable<T>[];
}

export enum ColumnPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface IColumnTable<T = unknown> {
  name: string | ((i18n: I18n) => void);
  accessor?: string;
  type?: 'text';
  minWidth?: string;
  maxWidth?: string;
  centerAlign?: boolean;
  sortBy?: {
    asc: {
      key: string;
      label: string | Function;
    };
    desc: {
      key: string;
      label: string | Function;
    };
  };
  renderCell?: (
    data: T,
    onClick?: MouseEventHandler<HTMLDivElement> | undefined,
    isHovered?: boolean | undefined,
    isSelected?: boolean | undefined,
  ) => ReactNode;
  isFixed?: boolean;
  position?: ColumnPosition;
  padding?: string;
  showHeaderTooltip?: boolean;
}
