import React, { useState } from 'react';

import { ENGAGEMENT_REPORT_CHART_TYPES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { Loader } from '~/components/Buttons/components/Loader';
import { DropdownIconState } from '~/components/Buttons/types';
import { TEngagementData } from '~/components/Graphs/constants';
import TimeGraph, { ELabelType, ILineGraphPoint } from '~/components/Graphs/custom/LineGraph';
import { ICONS, Icon } from '~/components/Icon';

import {
  OvertimeCtr,
  OvertimeHeader,
  DropDownCtr,
  BodyCtr,
  NoDataTextContainer,
} from './OvertimeStyles';

import { useOutsideClick } from '~/hooks/useOutsideClick';
import { COLORS, PRIMARY_COLOR } from '~/styles';

import ExportDropDown from '../../Components/ExportDropDown';
import ReFetchBtn from '../../Components/RefetchBtn';
import { EExportOptions, EOptions, TDropDownValues } from '../../types';

type TProps = {
  onShow: () => void;
  noDataText: string;
  isLoading: boolean;
  timePrimaryData: TEngagementData;
  timeSecondaryData: TEngagementData;
  timeTurneryData: TEngagementData;
  onExportData: (type: EExportOptions, from: ENGAGEMENT_REPORT_CHART_TYPES) => void;
  enabledOptions: EOptions[];
  enabledOptionKeys: EOptions[];
  exportOptionArr: EExportOptions[];
  companyAverageSelected: boolean;
  teamAverageSelected: boolean;
  industryBenchMarkSelected: boolean;
  optionChangeHandler: (type: EOptions, val?: TDropDownValues) => void;
  viewRole: ROLES;
  noRetry: boolean;
};

function Overtime({
  onShow,
  noDataText,
  isLoading,
  timePrimaryData,
  timeSecondaryData,
  timeTurneryData,
  enabledOptionKeys,
  enabledOptions,
  onExportData,
  exportOptionArr,
  companyAverageSelected,
  teamAverageSelected,
  industryBenchMarkSelected,
  optionChangeHandler,
  viewRole,
  noRetry,
}: TProps): JSX.Element {
  const { i18n } = useLingui();
  const [showExportMenu, setShowExportMenu] = useState(false);

  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowExportMenu(false);
  });

  if (!isLoading && timePrimaryData.length === 0) {
    return (
      <OvertimeCtr>
        <BodyCtr>
          <NoDataTextContainer>{noDataText}</NoDataTextContainer>
          {!noRetry && <ReFetchBtn optionChangeHandler={optionChangeHandler} />}
        </BodyCtr>
      </OvertimeCtr>
    );
  }

  const getLabelText = () => {
    if (viewRole === ROLES.ADMIN) {
      return `${i18n._(t`Company Average`)}`;
    } else if (viewRole === ROLES.COACH) {
      return `${i18n._(t`Team Average`)}`;
    }
    return `${i18n._(t`Your engagement`)}`;
  };

  const timeData: ILineGraphPoint[] = [
    {
      data: timePrimaryData || [],
      colors: [PRIMARY_COLOR, COLORS.WHITE],
      label: getLabelText(),
      type: ELabelType.FILL,
      key: 'primary',
    },
    ...(teamAverageSelected
      ? [
          {
            data: timeTurneryData || [],
            colors: [COLORS.PERFORMANCE],
            label: `${i18n._(t`Team`)}`,
            type: ELabelType.BORDER,
            key: 'team',
          },
        ]
      : []),
    ...(companyAverageSelected
      ? [
          {
            data: timeSecondaryData || [],
            colors: [COLORS.CONFIRMATION_MODAL_SUCCESS],
            label: `${i18n._(t`Company`)}`,
            type: ELabelType.BORDER,
            key: 'company',
          },
        ]
      : []),
    ...(industryBenchMarkSelected
      ? [
          {
            data: timeSecondaryData || [],
            colors: [COLORS.SUBTEXT],
            label: `${i18n._(t`Benchmark`)}`,
            type: ELabelType.BORDER,
            key: 'benchmark',
          },
        ]
      : []),
  ];

  return (
    <OvertimeCtr>
      {isLoading ? (
        <BodyCtr>
          <Loader />
        </BodyCtr>
      ) : (
        <>
          <OvertimeHeader>
            {enabledOptionKeys.includes(EOptions.exportOptions) && exportOptionArr.length > 0 && (
              <DropDownCtr>
                <Button
                  icon={ICONS.EXPORT}
                  disabled={!enabledOptions.includes(EOptions.exportOptions)}
                  label={`${i18n._(t`Export`)}`}
                  variant={ButtonVariant.SECONDARY}
                  onClick={() => setShowExportMenu(true)}
                  size={ButtonSize.MEDIUM}
                  dropDownIcon={showExportMenu ? DropdownIconState.UP : DropdownIconState.DOWN}
                />
                {showExportMenu && (
                  <ExportDropDown
                    onMenuChange={(type) => {
                      onExportData(type, ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_MONTH);
                      setShowExportMenu(false);
                    }}
                    reference={baseRef}
                    exportOptionArr={exportOptionArr}
                  />
                )}
              </DropDownCtr>
            )}
            <Icon icon={ICONS.EXPAND} color={COLORS.INACTIVE} onClick={onShow} className="icon" />
          </OvertimeHeader>
          <TimeGraph timeData={timeData} height={478} stepSize={20} colorRatio={1} />
        </>
      )}
    </OvertimeCtr>
  );
}

export default Overtime;
