import React, { useState, useEffect } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import CategoryItem from './CategoryItem';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getSelectedRole } from '~/selectors/baseGetters';
import { getJobProfileRelevanciesProgress } from '~/services/jobProfiles';
import { COLOR_SET } from '~/styles';

const Wrapper = styled.div`
  margin: 24px;
  padding: 20px 30px 20px 17px;
  border-radius: 6px;
  border: solid 1px #dae4f7;
  background-color: #ebf1fe;
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ColumnOne = styled.div``;
const ColumnTwo = styled.div``;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  color: ${COLOR_SET.BLACK};
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_SET.DARK_GRAY};
  margin-bottom: 12px;
  margin-top: 9px;
`;

const LinkStyled = styled(Link)`
  color: var(--company-color);
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  cursor: pointer;
`;

const Categories = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.$cols}, 1fr);
  grid-column-gap: 30px;
`;

const LearningSection = ({ jobProfileId, userId } = {}) => {
  const { i18n } = useLingui();
  const $loading = useBoolState(true);
  const [relevancies, setRelevancies] = useState([]);
  const selectedRole = useSelector(getSelectedRole);

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        $loading.on();
        const response = await getJobProfileRelevanciesProgress(jobProfileId, userId);

        if (isMounted && response.data[API_RETURN_FIELDS.ROLE_RELEVANCIES]) {
          setRelevancies(response.data[API_RETURN_FIELDS.ROLE_RELEVANCIES]);
        }
      } catch (_e) {
        setRelevancies([]);
      } finally {
        $loading.off();
      }
    };

    if (jobProfileId && userId) {
      fetch();
    }

    return () => (isMounted = false);

    // eslint-disable-next-line
  }, [jobProfileId, userId]);

  const getUserLibraryLink = () => {
    return routes.ONBOARD_AND_LEARN.build(
      {},
      {
        query: {
          roleId: jobProfileId,
        },
        hash: 'all',
        isBackPath: true,
      },
    );
  };
  const totalActivities = relevancies.reduce(
    (previousValue, currentValue) => previousValue + (currentValue?.activitiesTotal ?? 0),
    0,
  );

  if (totalActivities === 0) {
    return <></>;
  }

  return (
    <Wrapper>
      <Title>{i18n._(t`Learning`)}</Title>
      <Columns>
        <ColumnOne>
          <Description>
            {i18n._(t`Relevant learning activities for this role and your progress`)}
          </Description>
          {selectedRole === ROLES.USER && ( // only for user Career page, coach does not have access to User Library
            <LinkStyled to={getUserLibraryLink()}>
              <Trans>View library</Trans>
            </LinkStyled>
          )}
        </ColumnOne>
        <ColumnTwo>
          <ShowSpinnerIfLoading
            loading={$loading.value}
            styles={{ width: '178px', height: '50px' }}
          >
            {!isEmpty(relevancies) && (
              <Categories $cols={relevancies.length > 1 ? 2 : 1}>
                {relevancies.map((item) => (
                  <CategoryItem key={item.name} roleId={jobProfileId} {...item} />
                ))}
              </Categories>
            )}
          </ShowSpinnerIfLoading>
        </ColumnTwo>
      </Columns>
    </Wrapper>
  );
};

export default LearningSection;
