import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import CompanyAvatar from '~/components/CompanyAvatar';
import Avatar from '~/components/UI/Avatar';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.background};

  ${(props) =>
    props.$isPositionFixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
    `}
`;

const Block = styled.div`
  align-items: center;
  color: #4d4d4d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 400px;
`;

const CompanyIcons = styled.div`
  display: flex;

  & > * {
    margin: 0 4px;
  }
`;

const Title = styled.h1`
  color: ${COLORS.TEXT_BLACK};
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin: 24px;
`;

const Description = styled.p`
  color: ${COLORS.TEXT_SECONDARY};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0 16px;
`;

const GiveFeedbackLocked = ({
  title,
  description,
  logoUrl,
  createdFor,
  background,
  isPositionFixed,
}) => {
  return (
    <Wrapper $background={background} $isPositionFixed={isPositionFixed}>
      <Block>
        <CompanyIcons>
          <CompanyAvatar logoUrl={logoUrl} size={56} />
          <Avatar userId={createdFor} size={56} showTooltip={false} />
        </CompanyIcons>
        <Title>{title || <Trans>Thanks for your feedback</Trans>}</Title>
        <Description>
          {description || <Trans>You can no longer fill out this feedback request</Trans>}
        </Description>
      </Block>
    </Wrapper>
  );
};

GiveFeedbackLocked.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  logoUrl: PropTypes.string,
  createdFor: PropTypes.string,
  background: PropTypes.string,
  isPositionFixed: PropTypes.bool,
};

GiveFeedbackLocked.defaultProps = {
  background: COLOR_PALETTE.WHITE,
  isPositionFixed: false,
};

export { GiveFeedbackLocked };
