import { useEffect, useState } from 'react';

import uniqBy from 'lodash/uniqBy';

import { getUserTeams } from '~/services/teams';

export default function useMemberLinkTeam(userId) {
  const [memberLinkTeam, setMemberLinkTeam] = useState({});

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      const teamsWithMembers = await getUserTeams(userId, { isCoach: true });

      const members = uniqBy(
        Object.values(teamsWithMembers).reduce((arr, team) => arr.concat(team.members), []),
      );

      const memberLinkTeam = {};
      members.forEach((memberId) => {
        const firstMemberTeam = Object.values(teamsWithMembers).find((team) =>
          (team.members || []).includes(memberId),
        );
        memberLinkTeam[memberId] = firstMemberTeam.id;
      });

      if (isMounted) {
        setMemberLinkTeam(memberLinkTeam);
      }
    };

    fetchData();

    return () => (isMounted = false);
    // eslint-disable-next-line
  }, []);

  return {
    memberLinkTeam,
  };
}
