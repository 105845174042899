import styled from 'styled-components';

import { ButtonPrimary } from '~/components/Button';

import { COLORS } from '~/styles';

export const ActionsWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionItemBlock = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  min-width: 130px;
  height: 20px;
  border-left: solid 1.2px ${COLORS.BORDERS};
  justify-content: center;
`;

export const SubmitButton = styled(ButtonPrimary)`
  height: 32px;
  min-width: 80px;
  padding: 0 10px 0 14px;
  border-radius: 100px;
  font-size: 12px;
  & .icon {
    margin-left: 4px;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.BLACK};
  padding-bottom: 4px;
`;
