import React from 'react';

import { Trans } from '@lingui/macro';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import PeopleIcon from '~/components/Icons/People';
import Modal from '~/components/Modal';
import Placeholder from '~/components/Placeholder';

import RequestItem from './RequestItem';

import { REQUEST_STATUSES, REQUEST_TYPES } from '~/constants';
import { getUsers } from '~/selectors/baseGetters';
import getUserFullName from '~/utils/getUserFullName';

const NOMINATE_TYPE = {
  peer: 'peer',
  coach: 'coach',
};

function NomineesStatusesModal({ review, onClose, type, onFeedbackRemind }) {
  const users = useSelector(getUsers);
  const isPeer = type === NOMINATE_TYPE.peer;
  const requests = (review.requests || []).filter(
    (r) => r.status !== REQUEST_STATUSES.CANCELLED.key,
  );
  const requestToShow = isPeer
    ? requests.filter(
        (r) =>
          [REQUEST_TYPES.FEEDBACK_FROM_PEER, REQUEST_TYPES.FEEDBACK_FROM_OUTSIDE_PEER].indexOf(
            r.type,
          ) !== -1,
      )
    : requests.filter((r) => r.type === REQUEST_TYPES.FEEDBACK_FROM_COACH);

  const userName = getUserFullName(users[review.createdFor]);

  return (
    <Modal
      title={
        isPeer ? (
          <Trans>Peer reviews for {userName}</Trans>
        ) : (
          <Trans>Coach reviews for {userName}</Trans>
        )
      }
      onClose={onClose}
      width={625}
      hideFooter
    >
      {requestToShow.length === 0 && (
        <Placeholder
          title={<Trans>No people nominated</Trans>}
          subTitle={<Trans>There is no person nominated</Trans>}
          Icon={PeopleIcon}
        />
      )}
      {requestToShow.map((req) => (
        <RequestItem key={req.id} request={req} onReminderClick={onFeedbackRemind} />
      ))}
    </Modal>
  );
}

NomineesStatusesModal.propTypes = {
  type: PropTypes.oneOf([NOMINATE_TYPE.peer, NOMINATE_TYPE.coach]).isRequired,
  review: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onFeedbackRemind: PropTypes.func.isRequired,
};

const Nominate = React.memo(NomineesStatusesModal);
Nominate.NOMINATE_TYPE = NOMINATE_TYPE;
export default Nominate;
