import React, { Fragment, useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { HeaderTabs } from '~/components/HeaderTabs';
import OverviewHeading from '~/components/OverviewHeading';
import BaseLayout from '~/layouts/BaseLayout';

import { SurveysOverview } from './SurveysOverview';
import { TemplatesOverview } from './TemplatesOverview';
import { ThemesOverview } from './ThemesOverview';
import { UserSurveysOverview } from './UserSurveysOverview';

import { INSTRUCTIONS } from '~/constants/instructions';
import routes from '~/constants/routes';
import { getUser, checkModuleSurvey } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

import type { I18n } from '@lingui/core';

const HeaderWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: fit-content;
  border-radius: 6px 6px 0 0;
`;

export const TABS_ENUM = {
  PERSONAL: 'personal',
  ORGANISATION: 'organisation',
  TEMPLATES: 'templates',
  THEMES: 'themes',
};

const Surveys = () => {
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const history = useHistory();
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const [currentTab, setCurrentTab] = useState(
    // @ts-ignore
    TABS_ENUM[window.location.hash.substring(1).toUpperCase()] || TABS_ENUM.PERSONAL,
  );

  const TABS = [
    {
      label: (i18n: I18n) => i18n._(t`Personal`),
      key: TABS_ENUM.PERSONAL,
    },
    {
      label: (i18n: I18n) => i18n._(t`Organisation`),
      key: TABS_ENUM.ORGANISATION,
    },
    {
      label: (i18n: I18n) => i18n._(t`Templates`),
      key: TABS_ENUM.TEMPLATES,
    },
    {
      label: (i18n: I18n) => i18n._(t`Themes`),
      key: TABS_ENUM.THEMES,
    },
  ];

  const tabs = user.isAdmin ? TABS : TABS.filter((t) => t.key === TABS_ENUM.PERSONAL);

  const handleChangeTab = (key: string) => {
    window.location.hash = key;
    setCurrentTab(key);
  };

  useEffect(() => {
    if (!isModuleSurveyEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleSurveyEnabled, history]);

  return (
    <Fragment>
      {/* @ts-ignore */}
      <OverviewHeading
        title={i18n._(t`Surveys`)}
        description={i18n._(t`Here goes the sub title of surveys`)} // TODO: update text
        instructions={i18n._(t`How surveys work`)}
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.HOW_SURVEYS_WORK)}
        smallWidth
      />
      {/* @ts-ignore */}
      <BaseLayout smallWidth>
        {user.isAdmin && (
          <HeaderWrapper>
            <HeaderTabs tabs={tabs} selectedTab={currentTab} handleChangeTab={handleChangeTab} />
          </HeaderWrapper>
        )}
        {currentTab === TABS_ENUM.PERSONAL && isModuleSurveyEnabled && <UserSurveysOverview />}
        {user.isAdmin && isModuleSurveyEnabled && currentTab === TABS_ENUM.THEMES && (
          <ThemesOverview />
        )}
        {user.isAdmin && isModuleSurveyEnabled && currentTab === TABS_ENUM.TEMPLATES && (
          <TemplatesOverview />
        )}
        {user.isAdmin && isModuleSurveyEnabled && currentTab === TABS_ENUM.ORGANISATION && (
          <SurveysOverview />
        )}
      </BaseLayout>
    </Fragment>
  );
};

export { Surveys };
