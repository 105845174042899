import styled from 'styled-components';

import { COLORS } from '~/styles';

const Divider = styled.div`
  width: ${(props) => (props.$verticalBorder ? 'calc(100% - 2px)' : '100%')};
  height: 1.5px;
  margin-bottom: ${(props) => props.$marginBottom};
  background: ${(props) => props.$color || COLORS.BG_PAGE};
  border-left ${(props) => (props.$verticalBorder ? '1px solid ' + props.$verticalBorder : null)};
  border-right ${(props) => (props.$verticalBorder ? '1px solid ' + props.$verticalBorder : null)};
`;

export default Divider;
