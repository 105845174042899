import React, { useState, useEffect, useRef } from 'react';

import { LUCA_INSIGHT_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';

import EngagementReportAIMonthChart from './monthChart';
import EngagementReportAIThemeChart from './themeChart';

import { getUser } from '~/selectors/baseGetters';
import { TTabResponse } from '~/services/reports';
import * as reportsService from '~/services/reports';
import { COLORS } from '~/styles';

import {
  CrossButtonCtr,
  AIInsightsModal,
  AIInsightsModalInner,
  AIInsightsModalTitle,
  AIInsightsModalFooter,
  AIInsightsModalBody,
  AIInsightsModalTitleLeft,
  AIInsightsModalTitleRight,
} from '../LayoutStyles';

const Wrapper = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
`;

const Content = styled.p`
  white-space: pre-line;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
`;

const Disclaimer = styled.p`
  font-size: 14px;
  font-style: italic;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
  padding-bottom: 25px;
  border-bottom: solid 1px ${COLORS.BORDERS};
`;

const SectionTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  color: ${COLORS.TEXT_MAIN};
`;

const GraphicContent = styled.div`
  pointer-events: none;
  > div {
    padding: 32px;
    margin: 0px;
    min-height: unset;
    > div {
      > div:first-child {
        display: none;
      }
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  gap: 10px;
`;

const LoadingContainerIcon = styled.div`
  flex-basis: 30%;
  text-align: right;

  svg {
    width: 100px;
    height: 100px;
  }
`;

const LoadingContainerGreeting = styled.div`
  flex-basis: 70%;
  p:nth-of-type(1) {
    font-size: 18px;
    font-weight: 600;
    color: black;
  }
  p:nth-of-type(2) {
    font-size: 16px;
    color: ${COLORS.TEXT_MAIN};
  }
  p:nth-of-type(3) {
    font-size: 14px;
    color: ${COLORS.COMPANY};
  }
  p:nth-of-type(4) {
    font-size: 14px;
    color: ${COLORS.COMPANY};
    overflow: hidden;
    white-space: nowrap;
    animation: typing 2.5s steps(50, end) forwards;
    animation-iteration-count: infinite; /* This makes the animation loop */
    animation-delay: 2s; /* This adds a 2-second pause between each loop */
    width: 0;
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 50%;
    }
  }
`;

const EngagementAIInsights = ({
  baseRef,
  tabsData,
  onClose,
}: {
  baseRef: React.RefObject<HTMLDivElement>;
  tabsData: TTabResponse;
  onClose(v: boolean): void;
}) => {
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const refreshInterval = useRef<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [insight, setInsight] = useState({
    status: LUCA_INSIGHT_STATUS.PENDING,
    responses: { content1: '', content2: '', content3: '', content4: '' },
  });

  useEffect(() => {
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }

    // retry fetching while the insight status is PENDING
    if (isLoading) {
      refreshInterval.current = window.setInterval(async () => {
        const { data } = await reportsService.getAIInsight('engagement', 'admin');

        if (
          data.status === LUCA_INSIGHT_STATUS.READY ||
          data.status === LUCA_INSIGHT_STATUS.FAILED ||
          data.status === LUCA_INSIGHT_STATUS.NO_DATA
        ) {
          setIsLoading(false);
          setInsight(data);
        }
      }, 5000);
    }

    return () => {
      clearInterval(refreshInterval.current);
    };
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [tabsData.id]);

  let monthChart: JSX.Element = <></>;
  let themeChart: JSX.Element = <></>;

  if (tabsData && tabsData.isDefault) {
    monthChart = <EngagementReportAIMonthChart tabsData={tabsData} />;
  }
  if (tabsData && tabsData.isDefault) {
    themeChart = <EngagementReportAIThemeChart tabsData={tabsData} />;
  }

  const loadingView = (
    <AIInsightsModal>
      <AIInsightsModalInner ref={baseRef}>
        <AIInsightsModalTitle>
          <AIInsightsModalTitleLeft>
            <></>
          </AIInsightsModalTitleLeft>
          <AIInsightsModalTitleRight>
            <CrossButtonCtr
              onClick={() => {
                onClose(false);
              }}
            >
              <Icon size={ICON_SIZES.LARGE} icon={ICONS.CLOSE} />
            </CrossButtonCtr>
          </AIInsightsModalTitleRight>
        </AIInsightsModalTitle>
        <AIInsightsModalBody>
          <Wrapper>
            <LoadingContainer>
              <LoadingContainerIcon>
                <Icon icon={ICONS.LUCA} color={COLORS.COMPANY} size={ICON_SIZES.LARGE} />
              </LoadingContainerIcon>
              <LoadingContainerGreeting>
                <p>Hi {user.firstName}!</p>
                <p>
                  {i18n._(t`My name is Luca. I am your AI powered assistant. I am here to help you crunch the
              numbers, analyze your results and provide you with tips based on best practices`)}
                </p>
                <p>
                  ({i18n._(t`I am going as fast as I can, but it can take a couple of minutes`)})
                </p>
                {insight.status === LUCA_INSIGHT_STATUS.FAILED ? (
                  <p />
                ) : (
                  <p>{i18n._(t`Writing`)} ...</p>
                )}
                {insight.status === LUCA_INSIGHT_STATUS.FAILED ? (
                  <p>
                    {i18n._(t`Afraid Luca could not come up with suggestions at the moment, please try again
                later ...`)}
                  </p>
                ) : (
                  <p />
                )}
              </LoadingContainerGreeting>
            </LoadingContainer>
          </Wrapper>
        </AIInsightsModalBody>
        <AIInsightsModalFooter>
          <Button
            label={`${i18n._(t`Close`)}`}
            variant={ButtonVariant.SECONDARY}
            onClick={() => onClose(false)}
            size={ButtonSize.MEDIUM}
          />
        </AIInsightsModalFooter>
      </AIInsightsModalInner>
    </AIInsightsModal>
  );

  return insight.status === LUCA_INSIGHT_STATUS.PENDING ||
    insight.status === LUCA_INSIGHT_STATUS.FAILED ? (
    loadingView
  ) : (
    <AIInsightsModal>
      <AIInsightsModalInner ref={baseRef}>
        <AIInsightsModalTitle>
          <AIInsightsModalTitleLeft>
            {i18n._(t`Summary & Tips`)}{' '}
            <Icon icon={ICONS.LUCA} color={COLORS.COMPANY} size={ICON_SIZES.LARGE} />
          </AIInsightsModalTitleLeft>
          <AIInsightsModalTitleRight>
            {/* <Button
              label={`${i18n._(t`Download PDF`)}`}
              variant={ButtonVariant.SECONDARY}
              onClick={() => {console.log('Download PDF'); handlePrint();}}
              size={ButtonSize.MEDIUM}
            /> */}
            <CrossButtonCtr
              onClick={() => {
                onClose(false);
              }}
            >
              <Icon size={ICON_SIZES.LARGE} icon={ICONS.CLOSE} />
            </CrossButtonCtr>
          </AIInsightsModalTitleRight>
        </AIInsightsModalTitle>
        <AIInsightsModalBody>
          <Wrapper>
            {insight.status === LUCA_INSIGHT_STATUS.READY && (
              <>
                <Content>
                  {i18n._(
                    t`In this report, you will discover my detailed analysis of employee engagement within your company. The initial section presents an overview of engagement trends over time. Following that, the second section delves into various themes that have positively and negatively influenced overall engagement levels. The third section examines the engagement levels across different teams, identifying those with higher or lower engagement. Finally, the report culminates with a synthesis of all insights, outlined as actionable recommendations.`,
                  )}
                </Content>

                <SectionTitle>{i18n._(t`The engagement over time`)}</SectionTitle>
                <Content>{insight.responses.content1}</Content>
                <GraphicContent>{monthChart}</GraphicContent>

                <SectionTitle>{i18n._(t`Top and bottom themes`)}</SectionTitle>
                <Content>{insight.responses.content2}</Content>
                <GraphicContent>{themeChart}</GraphicContent>

                <SectionTitle>{i18n._(t`Engagement per team`)}</SectionTitle>
                <Content>{insight.responses.content3}</Content>

                <SectionTitle>{i18n._(t`Recommended actions`)}</SectionTitle>
                <Content>{insight.responses.content4}</Content>

                <Disclaimer>
                  {i18n._(
                    t`Legal Disclaimer: The information provided herein has been produced by an independently developed Generative Pre-trained Transformer (GPT) model. While efforts have been made to ensure the accuracy of the data generated by this artificial intelligence (AI) system, it is important to acknowledge that inaccuracies may occur. Please be advised that the personal data submitted for processing by this AI model is not utilized for the purpose of training or enhancing the capabilities of the GPT model.`,
                  )}
                </Disclaimer>
              </>
            )}
            {insight.status === LUCA_INSIGHT_STATUS.NO_DATA && (
              <>
                <Content>
                  {i18n._(
                    t`There is no data to analyse yet. Start your first survey with Learned!`,
                  )}
                </Content>
                <Content>
                  {i18n._(
                    t`When the results are available, I will share my detailed analysis of employee engagement within your company and provide you with actionable recommendations.`,
                  )}
                </Content>
              </>
            )}
          </Wrapper>
        </AIInsightsModalBody>
        <AIInsightsModalFooter>
          <Button
            label={`${i18n._(t`Close`)}`}
            variant={ButtonVariant.SECONDARY}
            onClick={() => onClose(false)}
            size={ButtonSize.MEDIUM}
          />
        </AIInsightsModalFooter>
      </AIInsightsModalInner>
    </AIInsightsModal>
  );
};

export { EngagementAIInsights };
