import React from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { Header6Dark } from '~/components/UI/Typographics/headers';

import CommentMandatorySetup from './CommentMandatorySetup';
import IsApplicableSwitcher from './IsApplicableSwitcher';
import Ratings from './Ratings';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionName = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${COLORS.TEXT_SECONDARY};
  border-radius: 3px;
  background-color: ${COLORS.BG_PAGE};
  padding: 8px;
  min-height: 69px;
  width: 100%;
  box-sizing: border-box;

  li {
    margin-left: 21px;
    font-weight: 600;
  }
`;

const SkillLabel = styled.div`
  display: flex;
  border-radius: 3px;
  border: solid 1px ${COLORS.BORDER_LIGHT};
  max-height: 48px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  padding: 11px 14px;
  color: ${COLORS.TEXT_SECONDARY};
  font-weight: 600;
  box-sizing: border-box;
`;

const QuestionExample = styled(Header6Dark)`
  display: flex;
  align-items: center;
  margin: 0 0 12px;
  padding-left: 18px;
  border-radius: 3px;
  height: 48px;
  min-height: 48px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${COLOR_PALETTE.WHITE};
  border: 1px solid ${COLORS.BG_PAGE};
`;

const SkillQuestion = ({
  question,
  i18n,
  skillLabels,
  toggleIsSkippable,
  isSkippable,
  isCommentObligated,
  toggleIsCommentObligated,
  isCollapsed,
}) => {
  const sprintIndex = question.name.indexOf(':');
  const splitName = [question.name.slice(0, sprintIndex), question.name.slice(sprintIndex + 1)];
  const skillsNames = splitName[1].split(', ');
  return (
    <>
      <Row>
        <Column>
          <QuestionExample>{i18n._(t`How did you perform on [skill name]?`)}</QuestionExample>
          <QuestionName>
            <Trans>A separate question will be generated for the following skills: </Trans>
            {!isEmpty(skillsNames) && skillsNames.map((n, i) => <li key={i}>{n}</li>)}
          </QuestionName>
        </Column>
      </Row>
      {!isCollapsed && (
        <>
          <Ratings>
            {skillLabels.map((label) => (
              <SkillLabel key={label}>{label}</SkillLabel>
            ))}

            <IsApplicableSwitcher onChange={toggleIsSkippable} value={isSkippable} />
          </Ratings>
          <CommentMandatorySetup
            isObligated={isCommentObligated}
            onChange={toggleIsCommentObligated}
          />
        </>
      )}
    </>
  );
};

export default React.memo(withI18n()(SkillQuestion));
