import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { COLORS } from '~/styles';
import sanitizeHtml from '~/utils/sanitize';

const Text = styled.div`
  p {
    margin: 0;
    font-size: 14px;
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
  }
  li {
    padding: 0;
    font-size: 14px;
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
  }
  ul {
    padding: 12px 0 0 0;
    list-style-position: inside;
    margin: 0 0 0 12px;
  }
  ol {
    padding: 12px 0 0 0;
    list-style-position: inside;
    margin: 0 0 0 12px;

    + ul {
      padding-left: 24px;
    }
  }

  color: ${COLORS.TEXT_BLACK};
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  ${({ color }) => color && `color: ${color}`}
`;

const RickTextView = ({ html, isHtmlWithoutStyles, className, ...props }) => {
  return (
    <Text
      className={className}
      dangerouslySetInnerHTML={{ __html: sanitizeHtml(html, isHtmlWithoutStyles) }}
      {...props}
    />
  );
};

RickTextView.propTypes = {
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  isHtmlWithoutStyles: PropTypes.bool,
};

export default React.memo(RickTextView);
