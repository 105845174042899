import React from 'react';

import { Trans } from '@lingui/macro';

import { NameContainer, OverflowText } from '~/components/UserAvatar/design';

function Name({
  name,
  isAnonymous,
  isDeactivated,
  isDeleted,
  anonymityIndex,
  customColor,
}: {
  name: string;
  isAnonymous?: boolean;
  isDeleted?: boolean;
  isDeactivated?: boolean;
  anonymityIndex?: number;
  customColor?: string;
}) {
  return (
    <NameContainer $isDeactivated={isDeactivated} $isDeleted={isDeleted} customColor={customColor}>
      <OverflowText>
        {!isAnonymous && !isDeleted && name}
        {isAnonymous && !isDeleted && <Trans>Participant {anonymityIndex}</Trans>}
        {isDeleted && <Trans>Deleted user</Trans>}
      </OverflowText>
    </NameContainer>
  );
}

export { Name };
