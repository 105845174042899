import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import zipArray from '~/utils/zipArray';

export default function (users, team) {
  if (isEmpty(team)) {
    return {};
  }

  return zipArray(filter(users, (user) => (team.members || []).includes(user.id)));
}
