import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import ManageSkillsModal from '~/components/ManageSkillsModal';
import { ProfileSettingsBlock } from '~/components/ProfileSettingsComponents/MainDashboard/ProfileSettingsBlock';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import getLang from '~/selectors/getLang';
import { getCompanySkillCategories } from '~/services/companySettings';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkills } from '~/services/skills';
import { getUserSkillsOrder, updateUserSkillsOrder } from '~/services/users';
import { updateUserProfile } from '~/store/auth/actions';
import { COLORS } from '~/styles';
import getSkillName from '~/utils/getSkillName';

import { SectionHeader } from '../SectionHeader';
interface ISkill {
  definitionNL?: string;
  nameNL?: string;
  source?: string;
  name?: string;
  definition?: string;
  meta?: {
    createdDate?: string;
    lastModifiedDate?: string;
  };
  levelsEnabled?: boolean[];
  categories?: string[];
  id: string;
}

interface ICategory {
  name?: string;
  isDefault?: boolean;
  type?: string;
  categoryId?: string;
  company?: string;
  meta?: {
    createdDate?: string;
    lastModifiedDate?: string;
  };
  id: string;
}

const SkillWrapper = styled.div`
  margin-top: 16px;
  padding-bottom: 8px;
`;

const CategoryName = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
`;

const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
  margin-top: 8px;
`;

const Skill = styled.div`
  padding: 8px 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  background-color: ${COLORS.BG_LIST};
  border-radius: 18px;
  margin-bottom: 8px;
`;

const SkillsBlock = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const lang = useSelector(getLang);
  const company = useSelector(getCurrentCompany);
  const $loading = useBoolState(true);
  const $manageSkillsModal = useBoolState();

  const [populatedSkills, setPopulatedSkills] = useState({});
  const [orderedCategories, setOrderedCategories] = useState([]);
  const [userSkillsOrder, setUserSkillsOrder] = useState({});
  const [anotherCompaniesSkillsIds, setAnotherCompaniesSkillsIds] = useState([]);
  const [refreshLocalState, setRefreshLocalState] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      $loading.on();
      const [retrievedSkills, companySkillCategories, skillCategories, skillsOrder] =
        await Promise.all([
          getSkills(),
          getCompanySkillCategories(company.id),
          getSkillCategories(),
          getUserSkillsOrder(user.id),
        ]);
      const categoryOrder = companySkillCategories.map(
        (category: string) => skillCategories[category],
      );
      setOrderedCategories(categoryOrder);
      setUserSkillsOrder(skillsOrder.data);
      setPopulatedSkills(retrievedSkills);

      const anotherCompaniesSkillsIds = (user.skills || []).filter(
        (skillId: string) => !retrievedSkills[skillId],
      );

      setAnotherCompaniesSkillsIds(anotherCompaniesSkillsIds);
      setRefreshLocalState(false);
      $loading.off();
    };

    if (refreshLocalState) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [company.id, user.id, user.skills, refreshLocalState]);

  // @ts-ignore
  const onSave = async ({ skills: newSkillIds, skillsHidden, skillsOrder }) => {
    $loading.on();

    const data = {
      skills: (anotherCompaniesSkillsIds || []).concat(newSkillIds),
      skillsHidden,
    };

    await dispatch(updateUserProfile(data));
    await updateUserSkillsOrder(user.id, skillsOrder);

    setRefreshLocalState(true);
    $loading.off();
    $manageSkillsModal.off();
  };

  const getPopulatedUserSkills = () => {
    const populatedUserSkills = {};
    if (!isEmpty(userSkillsOrder)) {
      for (const category in userSkillsOrder) {
        if ({}.hasOwnProperty.call(userSkillsOrder, category)) {
          // @ts-ignore
          populatedUserSkills[category] = (userSkillsOrder as string[])[category]
            // @ts-ignore
            .map((skillId: string) => (populatedSkills as ISkill[])[skillId])
            .filter(Boolean);
        }
      }
    }
    return populatedUserSkills;
  };

  const renderSkill = (skillId: string) => {
    // @ts-ignore
    const skill = (populatedSkills as ISkill[])[skillId];
    if (!skill) {
      return null;
    }
    return <Skill key={skill.id}>{getSkillName(skill, lang)}</Skill>;
  };

  const renderCategory = (category: ICategory, index: number) => {
    // @ts-ignore
    const categorySkills = (((userSkillsOrder as string[])[category.id] || []) as string[]).filter(
      (skillId: string) => !(user?.skillsHidden || []).includes(skillId),
    );

    return categorySkills && categorySkills.length > 0 ? (
      <SkillWrapper key={index}>
        <CategoryName>{category.name}</CategoryName>
        <Skills>{categorySkills.map(renderSkill)}</Skills>
      </SkillWrapper>
    ) : null;
  };

  const Header = (
    <SectionHeader title={i18n._(t`Skills`)} isEditable={true} onEdit={$manageSkillsModal.on} />
  );

  const Content = (
    <ShowSpinnerIfLoading loading={$loading.value}>
      {orderedCategories.map(renderCategory)}
    </ShowSpinnerIfLoading>
  );

  return (
    <>
      <ProfileSettingsBlock header={Header} content={Content} />

      {$manageSkillsModal.value && (
        <ManageSkillsModal
          currentManualAddedSkills={user?.skills || []}
          populatedSkills={populatedSkills}
          onClose={$manageSkillsModal.off}
          onSave={onSave}
          loading={$loading.value}
          currentSkillsHidden={user?.skillsHidden || []}
          orderedCategories={orderedCategories}
          populatedUserSkills={getPopulatedUserSkills()}
        />
      )}
    </>
  );
};

export { SkillsBlock };
