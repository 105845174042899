import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import GiveFeedback from '~/components/GiveFeedback';
import { GiveFeedbackLocked } from '~/components/GiveFeedbackLocked';
import SetupLayout from '~/layouts/SetupLayout';

import { RATING_TYPES, REVIEW_STAGES } from '~/constants';
import getAppThemeUrl from '~/selectors/getAppThemeUrl';
import { getReview } from '~/services/reviews';
import * as appActions from '~/store/app/actions';
import * as currentRequestActions from '~/store/currentRequest/actions';
import * as currentReviewActions from '~/store/currentReview/actions';
import { isTaskStartDateInThePast } from '~/utils/isTaskStartDateInThePast';

const Title = styled.div`
  text-transform: capitalize;
`;

export class ReviewGiveFeedbackSelf extends Component {
  state = {
    isFeedbackSigned: false,
    isStartDateInFuture: false,
    loading: true,
  };

  componentDidMount() {
    const { dispatch, match, app } = this.props;
    const reviewId = get(match, 'params.reviewId');
    dispatch(currentReviewActions.setCurrentReview());
    Promise.all([
      getReview(reviewId, {
        isQuestions: true,
        join: ['requests', 'ratings'],
      }),
    ]).then(async ([review]) => {
      const isLockedFeedback = get(review, `lockedStages[${REVIEW_STAGES.FEEDBACK}]`, false);
      const isFeedbackSigned = (review?.signatures || []).length > 0;
      const isStartDateInFuture = !isTaskStartDateInThePast(get(review, 'startDateSelfReview'));

      this.setState({
        loading: false,
        isFeedbackSigned,
        isLockedFeedback,
        isStartDateInFuture,
      });
      dispatch(currentReviewActions.setCurrentReview(review));
    });

    if (!app.isFieldsValidated) {
      dispatch(appActions.updateApp({ isFieldsValidated: true }));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(currentReviewActions.setCurrentReview());
  }

  handleClose = () => {
    const { dispatch, history } = this.props;
    const fromPath = new URL(window.location.href).searchParams.get('from');

    history.push(fromPath || '/');
    dispatch(currentReviewActions.setCurrentReview());
    dispatch(currentRequestActions.setCurrentRequest());
  };

  render() {
    const { review, users, i18n, logoUrl } = this.props;
    const { isFeedbackSigned, isLockedFeedback, isStartDateInFuture, loading } = this.state;

    if (isLockedFeedback || isFeedbackSigned || isStartDateInFuture) {
      const startDate = get(review, 'startDateSelfReview');
      const startDateFormatted = isStartDateInFuture && moment(startDate).format('YYYY-MM-DD');
      const title =
        (isLockedFeedback &&
          i18n._(t`This review is already locked. You can not give feedback anymore.`)) ||
        (isFeedbackSigned &&
          i18n._(t`This review is already signed. You can not give feedback anymore.`)) ||
        (isStartDateInFuture && i18n._(t`This feedback request is not yet available.`));

      return (
        <SetupLayout
          width="1070px"
          title={<Title>{i18n._(t`self review`)}</Title>}
          onClose={this.handleClose}
          loading={loading}
        >
          <GiveFeedbackLocked
            logoUrl={logoUrl}
            createdFor={review.createdFor}
            title={title}
            description={
              isStartDateInFuture && i18n._(t`You can provide feedback from ${startDateFormatted}`)
            }
          />
        </SetupLayout>
      );
    }

    return review && review.name ? (
      <GiveFeedback
        ratingType={RATING_TYPES.SELF_RATING}
        forUser={users[review.createdFor] || {}}
      />
    ) : null;
  }
}

export default withI18n()(
  connect((state) => ({
    users: state.users.data,
    review: state.currentReview,
    currentRole: state.selected.role,
    app: state.app,
    logoUrl: getAppThemeUrl(state),
  }))(withRouter(ReviewGiveFeedbackSelf)),
);
