import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';

import { COLORS, COLOR_SET } from '~/styles';

const Circle = styled.div`
  background-color: ${(props) => props.$color};
  width: 7px;
  min-width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 11px;
  margin-left: 3px;
`;

const Row = styled.div`
  min-height: 79px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 14px 6px;
  box-sizing: border-box;
  ${({ $isLast, $isFirst, $isOne, $isActive }) =>
    `
border-top: ${!$isFirst && !$isOne && !$isActive ? 'none' : `solid 1px ${COLORS.BORDER_LIGHT}`};
  border-bottom: solid 1px ${COLORS.BORDER_LIGHT};
  border-left: solid 1px ${COLORS.BORDER_LIGHT};
  border-right: solid 1px ${COLORS.BORDER_LIGHT};;
border-top-left-radius: ${$isFirst ? '6px' : '0px'};
border-bottom-left-radius: ${$isLast ? '6px' : '0px'};
border-top-right-radius: ${$isFirst ? '6px' : '0px'};
border-bottom-right-radius: ${$isLast ? '6px' : '0px'};
`};
  ${(props) =>
    props.$isActive &&
    `background-color: ${COLORS.BG_PAGE};
  border-color: ${COLOR_SET.MIDNICHT_BLUE};`};
`;

const SaveButton = styled(Button)`
  display: flex;
  align-self: flex-end;
  margin-top: 22px;
`;

const Status = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${COLOR_SET.MIDNICHT_BLUE};
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const SaveFeedbackModal = ({ items, onClose, onSubmit, loading }) => {
  const { i18n } = useLingui();
  const [status, setStatus] = useState(null);

  return (
    <Modal
      title={i18n._(t`Save and exit`)}
      onClose={onClose}
      width={310}
      minWidth={310}
      contentStyles={{
        height: '100%',
        padding: '10px 23px 17px',
      }}
      headerStyles={{ padding: '0 23px' }}
      hideFooter={true}
      showDivider={false}
    >
      {items.map((i, index) => (
        <Row
          key={i.key}
          $isActive={status === i.key}
          onClick={() => setStatus(i.key)}
          $isFirst={index === 0}
          $isLast={index === items.length - 1}
          $isOne={items.length === 1}
        >
          <Status>
            <Circle $color={i.color} />
            <span>{i18n._(i.label)}</span>
          </Status>
        </Row>
      ))}
      <SaveButton
        width={85}
        height={48}
        label={i18n._(t`Save`)}
        type="primary"
        disabled={!status || loading}
        onClick={() => onSubmit(status)}
      />
    </Modal>
  );
};

export default SaveFeedbackModal;
