import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Hint from '~/components/Hint';
import { SkillFullInfoModal } from '~/components/Modals/SkillFullInfoModal';
import Placeholder from '~/components/Placeholder';
import ProgressBarWide from '~/components/ProgressBarWide';
import SkillDefinitionModal from '~/components/SkillDefinitionModal';
import SvgIcon from '~/components/SvgIcon';
import Tabs from '~/components/Tabs';
import AboutRoleTab from '~/pages/RolePage/Tabs/AboutRoleTab';
import SkillGapTab from '~/pages/RolePage/Tabs/SkillGapTab';

import RolesIcon from '~/assets/mdi-ballot.svg';
import placeholderImage from '~/assets/role-placeholder.jpg';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { checkModuleCareer, getUser, getUsers } from '~/selectors/baseGetters';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getSkillMatchJobProfile from '~/utils/getSkillMatchJobProfile';

const CoverImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 6px 6px 0 0;
`;

const TextContent = styled.div``;

const TitleText = styled.h1`
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-left: 24px;
  color: ${COLORS.TEXT_BLACK};
`;

const SkillMatchSection = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const SkillMatchInfoSection = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
`;

const SkillMatchLabel = styled.p`
  padding: 0;
  margin: 0;
  margin-top: 2px;
  font-size: 14px;
  text-transform: uppercase;
`;

const MainTabsSection = styled.div`
  width: 100%;
`;

const UserRoleTabs = styled(Tabs)`
  padding: 0 24px;
`;

const TabsContentWrapper = styled.div`
  padding: 24px;
`;

const Percentage = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #2160e7;
  padding: 0;
  margin: 0;
  margin-left: auto;
`;

// we use it in RolePage
// and also in JobProfileModal
const Content = ({ jobProfile, userId }) => {
  const { i18n } = useLingui();
  const $showSkillModal = useBoolState(false);
  const isModuleCareerEnabled = useSelector(checkModuleCareer);
  const [activeTab, setActiveTab] = useState('about');
  const [selectedSkill, setSelectedSkill] = useState();
  const [skillFromUrlShown, setSkillFromUrlShown] = useState(false);
  const skillLabels = useSelector((state) => state.companySettings.skillLabels);
  const users = useSelector(getUsers);
  const viewer = useSelector(getUser);
  const user = userId ? users[userId] : viewer;
  const readOnly = Boolean(userId);

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const goToSkillPassport = () => {
    $showSkillModal.off();
    routes.PROFILE.go({}, { isBackPath: true, query: { skill: selectedSkill.id } });
  };

  // Show skill from url if passed in query params
  const skillFromUrl = new URL(window.location).searchParams.get('skill');
  if (skillFromUrl && !skillFromUrlShown) {
    setSelectedSkill(skillFromUrl);
    setSkillFromUrlShown(true);
    $showSkillModal.on();
  }

  let tabItems = [
    {
      key: 'about',
      label: (i18n) => i18n._(t`Role info`),
    },
    isModuleCareerEnabled && {
      key: 'gap',
      label: (i18n) => i18n._(t`Skill gap`),
    },
  ].filter((i) => i);

  return isEmpty(jobProfile) ? (
    <Placeholder
      title={i18n._(t`No role`)}
      subTitle={i18n._(t`We could not find role.`)}
      Icon={() => (
        <SvgIcon
          url={RolesIcon}
          width="50px"
          height="50px"
          isDefaultColor
          defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
        />
      )}
    />
  ) : (
    <>
      {jobProfile.coverImage && (
        <CoverImage
          alt={i18n._(t`Job profile image`)}
          src={jobProfile.coverImage ? jobProfile.coverImage : placeholderImage}
        />
      )}
      <TextContent>
        <TitleText>{jobProfile.name}</TitleText>
        <MainTabsSection>
          {
            // Hiding this for now but making it easy to reimplement later when relevant again
            tabItems.length && !tabItems.length && (
              <UserRoleTabs
                items={tabItems}
                currentItem={activeTab}
                handleChangeTab={handleChangeTab}
              />
            )
          }
          <TabsContentWrapper>
            {activeTab === 'about' && (
              <AboutRoleTab
                jobProfile={jobProfile}
                onSkillClick={(skill) => {
                  setSelectedSkill(skill);
                  $showSkillModal.on();
                }}
              />
            )}
            {activeTab === 'gap' && (
              <>
                <SkillMatchSection>
                  <SkillMatchInfoSection>
                    <SkillMatchLabel>
                      <Trans>Skill match</Trans>
                    </SkillMatchLabel>
                    <Hint>
                      <Trans>
                        The skill match is based on the skills in your skill passport compared to
                        the relevant skills for this role
                      </Trans>
                    </Hint>
                    <Percentage>
                      {Math.round(getSkillMatchJobProfile(user, jobProfile))}%
                    </Percentage>
                  </SkillMatchInfoSection>
                  <ProgressBarWide value={getSkillMatchJobProfile(user, jobProfile)} />
                </SkillMatchSection>
                <SkillGapTab
                  user={user}
                  jobProfile={jobProfile}
                  onSkillClick={(skill) => {
                    setSelectedSkill(skill);
                    $showSkillModal.on();
                  }}
                  readOnly={readOnly}
                />
              </>
            )}
          </TabsContentWrapper>
        </MainTabsSection>
      </TextContent>
      {$showSkillModal.value &&
        selectedSkill &&
        (readOnly ? (
          <SkillDefinitionModal
            userId={userId}
            onModalClose={$showSkillModal.off}
            skill={selectedSkill}
            scaleLabels={skillLabels}
            expectedLevel={jobProfile.skillsLevels[selectedSkill.id]}
            goToSkillPassport={goToSkillPassport}
            isUserCareer
          />
        ) : (
          <SkillFullInfoModal
            userId={userId}
            onClose={$showSkillModal.off}
            skillId={selectedSkill.id}
            expectedLevel={jobProfile.skillsLevels[selectedSkill.id]}
            scaleLabels={skillLabels}
          />
        ))}
    </>
  );
};

Content.propTypes = {
  jobProfile: PropTypes.object,
};

export default Content;
