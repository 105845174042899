import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { getUser } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getMultiLangString } from '~/utils/getMultiLangString';

import type { IUser, IConnection } from '@learned/types';

const useMultiLangString = () => {
  const currentUser: IUser & { connections: IConnection[] } = useSelector(getUser);
  const currentCompany = useSelector(getCurrentCompany);
  const currentConnection = currentUser.connections.find(
    (conn) => conn.company === currentCompany.id,
  ) as IConnection;

  return useCallback(
    (multiLangString: Record<string, string>) => {
      return getMultiLangString(multiLangString, currentConnection, currentCompany);
    },
    [currentConnection, currentCompany],
  );
};

export { useMultiLangString };
