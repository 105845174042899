// pages types

const singularName = (pluralName) => pluralName.slice(0, pluralName.length - 1);

export const pageOverview = (name) => `${name}`;
export const pageDashboard = (name, { id } = {}) => `${singularName(name)}/${id}`;
export const pageDashboardREST = (name, { id } = {}) => `${name}/${id}`;
export const pageCreate = (name) => `${singularName(name)}-create`;
export const pageCreateSingle = (name) => `${singularName(name)}-create-single`;
export const pageUpdate = (name, { id } = {}) => `${singularName(name)}-update/${id}`;
export const pageUpdateSingle = (name, { id } = {}) => `${singularName(name)}-update-single/${id}`;
export const pageOutside = (name) => `/${name}`;
export const pageSupervisionUser = (name, { id, userId } = {}) =>
  `supervision-user/${userId ? userId + '/' : ''}${pageDashboard(name, { id })}`;
export const pageOverviewFilter = (name, { filter } = {}) => `${name}/${filter}`;
export const pageReport = (name) => `${name}/report`;
export const pageOverviewPerUser = (name, { userId }) => `${name}/user/${userId}`;
export const pageUserPublic = (name, { id, subPage }) => `${name}/${id}/${subPage}`;
export const pageUserPublicSubPage = (name, { id, subPage, subPageId }) =>
  `${name}/${id}/${subPage}/${subPageId}`;
