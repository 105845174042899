import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import AddSkillModalNew from '~/components/AddSkillModalNew';
import Button from '~/components/Button';
import InfoIcon from '~/components/Icons/Info';
import SelectLevelModal from '~/components/SelectSkillsModal/SelectLevelModal';
import SkillName from '~/components/Skill/SkillName';
import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';
import Divider from '~/components/UI/Divider';

import DownArrowIcon from '~/assets/ic-keyboard-arrow-down.svg';
import UpArrowIcon from '~/assets/ic-keyboard-arrow-up.svg';
import DefaultCompanyAvatar from '~/assets/main-menu-icons/default-avatar.svg';

import { SKILL_SOURCE, SKILL_TYPE, SUGGESTIONS_SKILL_CATEGORY } from '~/constants/skills';
import useBoolState from '~/hooks/useBoolState';
import { getCompanyFocusAreas, getAppTheme } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import getLang from '~/selectors/getLang';
import { getCompanySkillCategories } from '~/services/companySettings';
import { getSkillCategories } from '~/services/skillCategories';
import { getSuggestedSkillsForJobtitle, getSkillByFilters } from '~/services/skills';
import { COLOR_PALETTE, COLORS } from '~/styles';
import { mapCompanyDefaultFocusAreas, mapLevelsEnabled } from '~/utils/focusAreas';

const GoalSkillsBox = styled.div`
  min-height: 48px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  padding-top: 7px;
`;

const GoalSkill = styled.div`
  border-radius: 6px;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : COLORS.BG_PAGE)};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: 0.25px;
  box-sizing: border-box;
  padding: 8px ${(props) => (props.paddingRight ? props.paddingRight : '36px')} 8px 12px;
  margin: 0 7px 7px 0;
  font-style: normal;
  font-stretch: normal;
  color: #000000;
  position: relative;
  &:hover .skill-remove-btn {
    display: block;
  }
  &:hover .skill-level {
    display: none;
  }
`;

const SkillNameWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  max-width: 16px;
  max-height: 16px;
  margin-right: 8px;
`;

const SkillActions = styled.div`
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  background: inherit;
`;

const GoalSkillRemoveButton = styled.button`
  display: none;
  background: inherit;
  border: none;
  color: var(--company-color);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 14px;
  background-color: white;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

const Level = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLOR_PALETTE.WHITE};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: var(--company-color);
`;

const SkillsContainer = styled.div`
  padding: 12px 16px 0 16px;
`;

const SkillsContainerTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: black;
  width: fit-content;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: solid 1px ${COLORS.BORDER_HARD};
  background-color: ${COLOR_PALETTE.WHITE};
  margin: 6px 0;
`;

const NumberOfRoleInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  padding: 12px;
  border-radius: 6px;
  background-color: #ebf1fe;
`;

const NumberOfRolesInfoText = styled.div`
  margin-left: 12px;
  font-size: 14px;
  color: ${COLOR_PALETTE.BLACK};
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
`;

const fadeIn = keyframes`
  from {
    display: none;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
`;
const RecommendedSkillsWrapper = styled.div`
  animation: ${fadeIn} 2s;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  padding: 16px 17px 11px 17px;
  margin-bottom: 17px;
`;

const SelectedSkillsWrapper = styled.div``;

const StyledSvgIcon = styled(SvgIcon)`
  margin-right: 24px;
`;

const LabelTitle = styled.div`
  padding: 0 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: black;
`;

const LabelSubTitle = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const AddSkillButton = styled(Button)`
  margin: 16px;
`;

const SetupRoleSkills = ({ item }) => {
  const { i18n } = useLingui();
  const $addSkillModal = useBoolState(false);
  const [editSkill, setEditSkill] = useState();
  const [skillCategories, setSkillCategories] = useState([]);
  const [preSelectedCategory, setPreSelectedCategory] = useState();
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [recommendedSkills, setRecommendedSkills] = useState({
    [SUGGESTIONS_SKILL_CATEGORY.LANGUAGE_SKILLS.id]: [],
    [SUGGESTIONS_SKILL_CATEGORY.PROFESSIONAL_SKILLS.id]: [],
    [SUGGESTIONS_SKILL_CATEGORY.IT_SKILLS.id]: [],
    [SUGGESTIONS_SKILL_CATEGORY.INTERPERSONAL_SKILLS.id]: [],
  });
  const company = useSelector(getCurrentCompany);
  const defaultFocusAreas = useSelector(getCompanyFocusAreas);
  const lang = useSelector(getLang);
  const appTheme = useSelector(getAppTheme);

  useEffect(() => {
    const fetchData = async () => {
      const [skillCategories, categoryOrder] = await Promise.all([
        getSkillCategories(),
        getCompanySkillCategories(company.id),
      ]);
      const orderedCategories = categoryOrder.map((c) => skillCategories[c]);
      setSkillCategories(orderedCategories);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newSelectedCategory = skillCategories.find((category) => category.id === selectedLabel);
    setSelectedCategory(newSelectedCategory || {});

    if (
      company.skillsSets.learnedPremiumEnabled &&
      selectedLabel &&
      item.getAlias &&
      newSelectedCategory.isDefault &&
      newSelectedCategory.categoryId !== SUGGESTIONS_SKILL_CATEGORY.LANGUAGE_SKILLS.id
    ) {
      const fetchData = async () => {
        const suggestedSkills = await getSuggestedSkillsForJobtitle({
          jobTitle: item.getAlias,
          language: lang,
          limit: 30,
          group: newSelectedCategory.categoryId,
        });
        const suggestedSkillsLabels = suggestedSkills.map((skill) => ({
          id: skill.value,
          name: skill.label,
          source: SKILL_SOURCE.company,
          ...(skill.isExistsInCompanySkills && {
            isExistsInCompanySkills: skill.isExistsInCompanySkills,
          }),
        }));

        setRecommendedSkills({
          ...recommendedSkills,
          [newSelectedCategory.categoryId]: suggestedSkillsLabels,
        });
      };
      fetchData();
    }
    // eslint-disable-next-line
  }, [selectedLabel]);

  const onAddSkill = (skills) => {
    const skillLevels = item.getSkillLevels;
    skills.selectedSkills.forEach((skill) => {
      // Set currently selected category for new skills
      if (skill.isNew) {
        skill.categories = [selectedCategory.id];
      } else if (skill.source === SKILL_SOURCE.company) {
        // Check if the skill already belongs to the selected category, if it doesn't then add it
        if (!skill.categories.find((category) => category === selectedCategory.id)) {
          skill.categories.push(selectedCategory.id);
          skill.isUpdateNeeded = true;
        }
      }

      // Add skill level
      skillLevels[skill.id] = skills.selectedSkillsLevels[skill.id];
    });

    item.setSkills(skills.selectedSkills.concat(item.getSkills));
    item.setSkillLevels(skillLevels);
  };

  const updateSkillLevel = (e, { level }) => {
    e.preventDefault();
    const skillLevels = { ...item.getSkillLevels };

    skillLevels[editSkill.id] = level;
    item.setSkillLevels(skillLevels);

    // If it's a recommended skill, add it or update it in item skills
    if (editSkill.isSuggestedSkill) {
      const skills = item.getSkills;
      const existingSkill = skills.find((sk) => sk.id === editSkill.id);

      if (editSkill.isUpdateNeeded && existingSkill) {
        existingSkill.categories = union(editSkill.categories, existingSkill.categories);
        existingSkill.isUpdateNeeded = true;
        existingSkill.focusAreas = editSkill.focusAreas;
      } else {
        skills.push(editSkill);
      }

      item.setSkills(skills);
    }
  };

  const setSkillToEdit = async (skill) => {
    if (!skill.isSuggestedSkill) {
      return setEditSkill(skill);
    }

    // If the recommended skill already exists as a custom skill, get it
    let skillPopulated = await getSkillByFilters(
      { name: skill.name, isDeleted: false, source: SKILL_SOURCE.company },
      ['focusAreas'],
    );

    if (!skillPopulated) {
      // Check if a recommended skill with this name was already selected in another category
      const alreadyAddedSkill = item.getSkills.find(
        (addedSkill) =>
          addedSkill.isNew && addedSkill.name.toLowerCase() === skill.name.toLowerCase(),
      );

      if (alreadyAddedSkill) {
        // If this skill name was already added on another category, add the new category to it
        alreadyAddedSkill.categories.push(selectedCategory.id);

        // As the skill is already selected with a level set on another category, it should not be done again
        // Trigger the render of the updated information
        return item.setSkills([...item.getSkills]);
      }

      skillPopulated = { ...skill };

      // Map company default focus areas
      const focusAreas = mapCompanyDefaultFocusAreas(defaultFocusAreas, skill.name);

      // Add focus areas to skill
      skillPopulated.focusAreas = focusAreas;
      skillPopulated.levelsEnabled = mapLevelsEnabled(focusAreas);

      // Add temporal field to know that this skill needs to be created
      skillPopulated.isNew = true;

      // Add the selected category id to this skill categories
      skillPopulated.categories = [selectedCategory.id];

      // Fill other skill fields
      skillPopulated.definition = skill.name;
      skillPopulated.aliases = [skill.name];
    } else {
      // If the skill is already selected on another category, add the new category to it
      if (!skillPopulated.categories.includes(selectedCategory.id)) {
        skillPopulated.categories.push(selectedCategory.id);

        // Add temporal field to know that this skill needs to be updated
        skillPopulated.isUpdateNeeded = true;
      }

      // Add isSuggestedSkill again if the skill already exists
      skillPopulated.isSuggestedSkill = true;
    }

    setEditSkill(skillPopulated);
  };

  const removeSkill = (e, skill) => {
    e.preventDefault();
    e.stopPropagation();
    const skills = item.getSkills.filter((selectedSkill) => skill.id !== selectedSkill.id);
    const skillLevels = { ...item.getSkillLevels };
    delete skillLevels[skill.id];
    item.setSkills(skills);
    item.setSkillLevels(skillLevels);
  };

  const renderSkill = (skill, index, backgroundColor, isRemovable, isSuggestedSkill) => {
    const skillLevel = get(item.getSkillLevels, skill.id, null);

    return (
      <GoalSkill
        backgroundColor={backgroundColor}
        paddingRight={!isRemovable && '12px'}
        onClick={() => setSkillToEdit({ ...skill, isSuggestedSkill })}
        key={index}
      >
        {isSuggestedSkill && skill.isExistsInCompanySkills ? (
          <Tooltip
            tooltip={i18n._(
              t`This skill is part of your company skill set. You can update it from there.`,
            )}
            placement="top-start"
            maxWidth="250px"
          >
            <SkillNameWrapper>
              <Logo src={appTheme.url || DefaultCompanyAvatar} alt={i18n._(t`Logo`)} />
              <SkillName skill={skill} />
            </SkillNameWrapper>
          </Tooltip>
        ) : (
          <SkillNameWrapper>
            <SkillName skill={skill} />
          </SkillNameWrapper>
        )}
        {isRemovable && (
          <SkillActions>
            <GoalSkillRemoveButton
              className="skill-remove-btn"
              onClick={(e) => removeSkill(e, skill)}
            >
              &#10005;
            </GoalSkillRemoveButton>
            {skillLevel && <Level className="skill-level">{skillLevel}</Level>}
          </SkillActions>
        )}
      </GoalSkill>
    );
  };

  return (
    <div>
      <NumberOfRoleInfoContainer>
        <InfoIcon backgroundColor="#ffffff" />
        <NumberOfRolesInfoText>
          <Trans>
            We recommend adding between 5 and 10 of the most important skills to a role. More skills
            will increase the workload when exchanging feedback.
          </Trans>
        </NumberOfRolesInfoText>
      </NumberOfRoleInfoContainer>
      {skillCategories.map((category) => {
        const skills = item.getSkills.filter(
          (skill) =>
            (skill.categories && skill.categories.includes(category.id)) ||
            (category.type &&
              category.type === SKILL_TYPE.soft &&
              (skill.source === SKILL_SOURCE.learned || skill.source === SKILL_SOURCE.learned2)),
        );
        const skillNames = skills.map((skill) => skill.name.toLowerCase());
        let filteredRecommendedSkills;
        if (selectedCategory && selectedCategory.isDefault) {
          const currRecommendedSkills = recommendedSkills[selectedCategory.categoryId];
          filteredRecommendedSkills = currRecommendedSkills
            ? recommendedSkills[selectedCategory.categoryId]
                .filter((recSkill) => !skillNames.includes(recSkill.name.toLowerCase()))
                .slice(0, 15)
            : [];
        }

        const isEmptySkills = isEmpty(skills);
        const isEmptyRecommendedSkills = isEmpty(filteredRecommendedSkills);
        const isEmptyAllSkills = isEmptySkills && isEmptyRecommendedSkills;

        return (
          <LabelWrapper key={category.id}>
            <TitleRow
              onClick={() => setSelectedLabel(selectedLabel === category.id ? null : category.id)}
            >
              <LabelTitle>{category.name}</LabelTitle>
              <LabelSubTitle>
                {skills.length
                  ? `${skills.length} ${i18n._(t`selected`)}`
                  : i18n._(t`No skills selected`)}
              </LabelSubTitle>
              <StyledSvgIcon
                width="14px"
                height="14px"
                url={selectedLabel === category.id ? UpArrowIcon : DownArrowIcon}
              />
            </TitleRow>
            {selectedLabel === category.id && (
              <>
                <Divider />
                {!isEmptyAllSkills && (
                  <SkillsContainer>
                    {company.skillsSets.learnedPremiumEnabled && !isEmptyRecommendedSkills && (
                      <RecommendedSkillsWrapper>
                        <Tooltip
                          tooltip={i18n._(
                            t`These are the most requested skills for this role based on market data.`,
                          )}
                          placement="top-start"
                          maxWidth="250px"
                        >
                          <SkillsContainerTitle>
                            <Trans>Recommended</Trans>
                          </SkillsContainerTitle>
                        </Tooltip>
                        <GoalSkillsBox>
                          {filteredRecommendedSkills.map((skill, index) =>
                            renderSkill(skill, index, COLOR_PALETTE.WHITE, false, true),
                          )}
                        </GoalSkillsBox>
                      </RecommendedSkillsWrapper>
                    )}
                    {!isEmptyAllSkills && (
                      <SelectedSkillsWrapper>
                        <SkillsContainerTitle>
                          <Trans>Selected</Trans>
                        </SkillsContainerTitle>
                        {!isEmptySkills && (
                          <GoalSkillsBox>
                            {skills.map((skill, index) =>
                              renderSkill(skill, index, COLORS.BG_PAGE, true, false),
                            )}
                          </GoalSkillsBox>
                        )}
                      </SelectedSkillsWrapper>
                    )}
                  </SkillsContainer>
                )}

                <AddSkillButton
                  type="primary-border"
                  label={`+ ${i18n._(t`Skills`)}`}
                  onClick={() => {
                    setPreSelectedCategory(category.categoryId ?? category.id);
                    $addSkillModal.on();
                  }}
                />
              </>
            )}
          </LabelWrapper>
        );
      })}

      {$addSkillModal.value && (
        <AddSkillModalNew
          onAddSkill={onAddSkill}
          onModalClose={$addSkillModal.off}
          preSelectedSkills={item.getSkillLevels}
          hiddenSkills={item.getSkills}
          isIncludeSuggestedSkills
          isShowPreloadedSkills
          isCategoriesFilter={false}
          preSelectedCategories={preSelectedCategory ? [preSelectedCategory] : []}
        />
      )}
      {editSkill && (
        <SelectLevelModal
          skill={editSkill}
          onClose={() => setEditSkill()}
          onSubmit={(e, param) => updateSkillLevel(e, param)}
          selectedLevel={item.getSkillLevels[editSkill.id]}
          isUpdate={true}
          readOnly={false}
        />
      )}
    </div>
  );
};

export default SetupRoleSkills;
