import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import ReviewRTFeedback from './ReviewRTFeedback';

import { COLOR_PALETTE } from '~/styles';

const NotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h2`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  padding: 16px;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  margin: 0;
`;

const SidePanel = ({ ratingType, requestedBy }) => {
  return (
    <NotesWrapper>
      <Header>
        <Trans>Feedback</Trans>
      </Header>
      <ReviewRTFeedback ratingType={ratingType} requestedBy={requestedBy} />
    </NotesWrapper>
  );
};

export default SidePanel;
