import React, { ReactNode, useEffect, useState } from 'react';

import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  AutocompleteFilterConversationStatus,
  AutocompleteFilterMembers,
} from '~/components/AutocompleteFilters';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import PaginationBar from '~/components/PaginationBar';
import SvgIcon from '~/components/SvgIcon';
import { Table } from '~/components/Table';
import { TablePlaceholder } from '~/components/TablePlaceholder';
import { CalendarDropdown, DateOption } from '~/pages/Conversations/components/CalendarDropdown';
import { TableHeader } from '~/pages/Conversations/components/TableHeader';

import { COLUMNS, SORT_OPTIONS } from './columns';

import EyeSlashIcon from '~/assets/eye-slash.svg';
import EyeIcon from '~/assets/eye.svg';

import { CONVERSATION_COLLECTION_TYPES, CONVERSATION_FILTER_STATUSES, ROLES } from '~/constants';
import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import {
  checkModuleCoachCreateReview,
  getSelectedRole,
  getUser,
  getUsers,
} from '~/selectors/baseGetters';
import { getConversationsAndReviews } from '~/services/userConversations';
import { getConversationPath } from '~/utils/userConversationsUtils';

import type { IUser, IReview } from '@learned/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

const FilterMargin = styled.div`
  margin-right: 10px;
`;

const StyledButton = styled.div<{ primary?: boolean; noBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 32px;
  border-radius: 16px;
  ${(props) => !props.noBorder && 'box-shadow: 0 2px 4px 0 rgba(145, 157, 165, 0.12);'}
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  margin-left: 8px;
  ${(props) => {
    if (props.primary) {
      return 'background-color: var(--company-color); color: white';
    } else {
      return 'background-color: white, color: var(--company-color);';
    }
  }}
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

const FiltersWrapper = styled.div`
  padding: 0px 32px 20px 32px;
  background: white;
  display: flex;
  flex-direction: row;
`;

const StyledAutocompleteMembers = styled(AutocompleteFilterMembers)`
  width: 140px;
  max-width: 140px;
`;

const StyledAutocompleteStatus = styled(AutocompleteFilterConversationStatus)`
  width: 140px;
  ${(props) => props.margin && 'margin-left: 16px;'}
  max-width: 140px;
`;

const FilterWrapper = styled.div`
  margin-left: 16px;
`;

export interface IType {
  id: string;
  key: string;
  name: string;
  translated: (i18n: any) => ReactNode;
}

interface IConversationFilterStatusType {
  key: string;
  id: string;
  name: (i18n: I18n) => string;
}

type IFilter = {
  isShowFilters: boolean;
  search: string;
  sortBy: SORT_OPTIONS;
  selectedDateOption: DateOption | undefined;
  selectedStatus: IConversationFilterStatusType;
  selectedCoaches: IUser[];
};

const initialFilters = {
  isShowFilters: false,
  search: '',
  statuses: [],
  types: [],
  sortBy: SORT_OPTIONS.NAME_A_Z,
  selectedDateOption: undefined,
  selectedStatus: CONVERSATION_FILTER_STATUSES.ALL,
  selectedCoaches: [],
};

const LS_KEY = 'LS_DEVELOPMENT_PERSONAL_USER_PUBLIC';

type TConversations = {
  userTempId?: string;
};
const Conversations = ({ userTempId }: TConversations) => {
  const { i18n } = useLingui();
  const params = useParams();
  const userId = get(params, 'userId') || userTempId; // userID that coach or admin look on

  const history = useHistory();
  const user = useSelector(getUser);
  const users = useSelector(getUsers);
  const selectedRole = useSelector(getSelectedRole);
  const isModuleCoachCreateConversation = useSelector(checkModuleCoachCreateReview);
  const [items, setItems] = useState<IReview[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const { pagination, changePagination, resetPagination } = usePagination(10);
  const [currentFilters, setCurrentFilters] = useState<IFilter>(initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch

  const fetchData = async () => {
    setIsLoading(true);

    const { conversations: items, total } = await getConversationsAndReviews({
      search: currentFilters.search || null,

      // filters
      coaches: currentFilters.selectedCoaches.length
        ? currentFilters.selectedCoaches.map((i) => i.id)
        : null,
      status:
        currentFilters.selectedStatus.key === CONVERSATION_FILTER_STATUSES.ALL.key
          ? null
          : currentFilters.selectedStatus.key,
      ...(currentFilters.selectedDateOption && {
        startDate: currentFilters.selectedDateOption.fromDate,
      }),
      ...(currentFilters.selectedDateOption && {
        endDate: currentFilters.selectedDateOption.toDate,
      }),

      // requirements
      tabsType: 'all',
      collectionType: CONVERSATION_COLLECTION_TYPES.REVIEW.key,
      userId,
      conversationTab: 'personal',

      // options
      skip: pagination.skip,
      limit: pagination.limit,
      order: currentFilters.sortBy,
    });

    // populate createdFor
    const itemsPopulated = map(items, (item: any) => {
      item.createdForPopulated = users[item.createdFor];
      return item;
    });

    setItems(itemsPopulated);
    setTotalCount(total);
    setIsLoading(false);
  };

  // first render
  useEffect(() => {
    // get filters from localStorage
    const localStorageData = localStorage.getItem(LS_KEY);
    const isLocalStorageData = !isEmpty(localStorageData);

    if (isLocalStorageData) {
      const parsedData = JSON.parse(localStorageData as string);

      setCurrentFilters({
        ...parsedData,
        selectedStatus:
          // @ts-ignore
          CONVERSATION_FILTER_STATUSES[parsedData?.selectedStatus?.key.toUpperCase()] ||
          initialFilters.selectedStatus, // LS does not store functions, so we need to add name for status
      });

      // set pagination from localstorage
      if (parsedData.pagination) {
        changePagination(parsedData.pagination);
      }
    }

    // eslint-disable-next-line
  }, [params]);

  // change filters fetch
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, [pagination.limit, pagination.skip, JSON.stringify(debCurrentFilters), params]);

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    const newPagination = {
      ...pagination,
      limit,
    };
    onPaginationChange(newPagination);
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    const newPagination = {
      ...pagination,
      skip,
      index,
    };
    onPaginationChange(newPagination);
  };

  const actionButton =
    (selectedRole === ROLES.COACH && isModuleCoachCreateConversation) ||
    selectedRole === ROLES.ADMIN
      ? {
          label: t`Create conversation`,
          onClick: () => {
            routes.REVIEW_CREATE.go(
              {},
              {
                isBackPath: true,
                query: { users: [userId] }, // pre-select user that coach/admin work with
              },
            );
          },
        }
      : undefined;

  const onCurrentFiltersChange = (newFilters: IFilter) => {
    setCurrentFilters(newFilters);

    // store in localstorage
    localStorage.setItem(LS_KEY, JSON.stringify(newFilters));
  };

  const onPaginationChange = (newPagination: typeof pagination) => {
    changePagination(newPagination);

    // store in localstorage
    localStorage.setItem(LS_KEY, JSON.stringify({ ...currentFilters, pagination: newPagination }));
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) => {
      const newFilters = { ...currentFilters, search: value };
      onCurrentFiltersChange(newFilters);
      resetPagination();
    },

    // @ts-ignore
    onChangeFilter: (key, value) => {
      const newFilters = { ...currentFilters, [key]: value };
      onCurrentFiltersChange(newFilters);

      // do not reset pagination for keys
      if (!['isShowFilters'].includes(key)) {
        resetPagination();
      }
    },
    resetFilters: () => onCurrentFiltersChange(initialFilters),
    selectedStatus: currentFilters.selectedStatus,
    selectedDateOption: currentFilters.selectedDateOption,
    selectedCoaches: currentFilters.selectedCoaches,
  };

  const onItemClick = {
    column: 'name',
    onClick: (conversation: any) => {
      const path = getConversationPath({
        conversation,
        selectedRole,
        userId,
        user,
        teams: undefined,
      });
      history.push(path as string);
    },
  };

  return (
    <Wrapper>
      <TableHeader filters={filters} actionButton={actionButton}>
        <FilterMargin>
          <StyledButton
            onClick={() => filters.onChangeFilter('isShowFilters', !filters.isShowFilters)}
            noBorder
          >
            <IconContainer>
              <SvgIcon
                width="20px"
                height="20px"
                url={filters.isShowFilters ? EyeSlashIcon : EyeIcon}
              />
            </IconContainer>
            {i18n._(filters.isShowFilters ? t`Hide filters` : t`Show filters`)}
          </StyledButton>
        </FilterMargin>
      </TableHeader>

      {filters.isShowFilters && (
        <FiltersWrapper>
          {selectedRole === ROLES.USER && (
            <StyledAutocompleteMembers
              checkedList={currentFilters.selectedCoaches}
              onChange={(value: IType[]) => filters.onChangeFilter('selectedCoaches', value)}
            />
          )}
          <StyledAutocompleteStatus
            margin={selectedRole === ROLES.USER}
            checkedList={[filters.selectedStatus]}
            onChange={(value: IType[]) => {
              filters.onChangeFilter('selectedStatus', value);
            }}
            isSingleSelect
            isDeleteDisabled
          />
          <FilterWrapper>
            <CalendarDropdown
              disabled={false}
              selectedItem={filters.selectedDateOption}
              // @ts-ignore
              setSelectedItem={(value: IType[]) =>
                filters.onChangeFilter('selectedDateOption', value)
              }
              filterOptions={[2, 1, 5, 6, 9]}
            />
          </FilterWrapper>
          <FilterWrapper>
            <Button
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`Reset all filters`)}
              onClick={filters.resetFilters}
              icon={ICONS.CLOSE}
            />
          </FilterWrapper>
        </FiltersWrapper>
      )}

      <Table
        data={items}
        columns={COLUMNS}
        sortBy={currentFilters.sortBy}
        setSortBy={(sortBy: SORT_OPTIONS) => onCurrentFiltersChange({ ...currentFilters, sortBy })}
        showMenu
        showDraftStatus
        onColClick={onItemClick}
      />
      {isEmpty(items) && (
        <TablePlaceholder
          isLoading={isLoading}
          isFiltered={!!currentFilters.search.length}
          noResultText={i18n._(t`No conversations found`)}
          emptyStateText={i18n._(t`No conversations yet… Let’s create one!`)}
        />
      )}
      <PaginationBar
        pagination={pagination}
        changePagination={onPageChangeClick}
        changePageSize={handleChangeItemsPerPage}
        count={totalCount}
        noShadow
        noBorder
        noTopBorder
        showCount
      />
    </Wrapper>
  );
};

export { Conversations };
