import React, { PureComponent } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import debounce from 'lodash/debounce';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  AutocompleteFilterRelevancies,
  AutocompleteFilterRoles,
  AutocompleteFilterSkills,
} from '~/components/AutocompleteFilters';
import { ButtonPrimary, ButtonPrimaryBorder } from '~/components/Button';
import SearchSelectButton from '~/components/SearchSelectButton';
import Select from '~/components/Select';
import { SearchField } from '~/components/Text';
import TextField from '~/components/TextField';

import { GOAL_CREATED_BY_FILTER, ROLES, ACTIVITIES_LIBRARY_TYPE_FILTER } from '~/constants';
import { getSelectedRole } from '~/selectors/baseGetters';
import getAllUsers from '~/selectors/getAllUsers';
import getCurrentTeam from '~/selectors/getCurrentTeam';
import { getJobProfile } from '~/services/jobProfiles';
import { getRoleRelevancy } from '~/services/roleRelevancies';
import { getSkill } from '~/services/skills';
import getUserFullName from '~/utils/getUserFullName';

const Row = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: ${(props) => (props.$isLeftSide ? 'flex-start' : 'flex-end')};
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
  & > * {
    margin-right: 8px;
    margin-top: 8px;
  }
  ${ButtonPrimary}, ${ButtonPrimaryBorder} {
    height: 32px;
    font-size: 12px;
    min-width: 65px;
    font-weight: bold;
  }
`;

const StyledSearch = styled(SearchField)`
  width: ${(props) => props.$searchWidth || '328px'};
  margin-right: 9px;
  ${TextField} {
    border-radius: 6px;
    font-size: 14px;
    height: ${(props) => props.$searchHight || '32px'};
  }
`;

const LeftSide = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
`;

const GapWrapper = styled.div`
  margin-right: 8px;
`;

const rolesFiler = map(GOAL_CREATED_BY_FILTER);

function composeFilters(filters) {
  return (item) => filters.every((filter) => filter(item));
}

const INITIAL_FILTERS = {
  search: '',
  createdIn: [],
  selectedMembers: [],
  selectedCoaches: [],
  selectedTypes: [],
  selectedTeams: [],
  selectedCategories: [],
  selectedSkills: [],
  selectedSkillType: 'all',
  selectedStatuses: [],
  selectedCustomFilter: [],
  selectedGoalCycles: [],
  selectedRoles: [],
  selectedRelevancies: [],
};

class FiltersHeading extends PureComponent {
  static propTypes = {
    searchFilter: PropTypes.bool,
    searchWidth: PropTypes.string,
    roleFilter: PropTypes.bool,
    relevancyFilter: PropTypes.bool,
    noBorders: PropTypes.bool,
    wrapperStyles: PropTypes.object,
    coachesFilter: PropTypes.bool,
    coaches: PropTypes.arrayOf(PropTypes.string),
    leftSideCoaches: PropTypes.bool,
    leftSideStatus: PropTypes.bool,
    leftSideRoles: PropTypes.bool,
    leftSideTeams: PropTypes.bool,
    leftSideMembers: PropTypes.bool,
    leftSideSearch: PropTypes.bool,
    rightSideSearch: PropTypes.bool,
    notIncludedRoles: PropTypes.arrayOf(PropTypes.string),
    noFilters: PropTypes.bool,
    onFilterSelectedChange: PropTypes.func,
    onFilterChange: PropTypes.func,
    debounceTime: PropTypes.number,
    preselectedIds: PropTypes.object,
  };

  static defaultProps = {
    searchFilter: true,
    roleFilter: false,
    relevancyFilter: false,
    noBorders: false,
    coaches: [],
    leftSideStatus: false,
    leftSideRoles: false,
    leftSideTeams: false,
    leftSideCoaches: false,
    leftSideMembers: false,
    leftSideSearch: false,
    rightSideSearch: false,
    notIncludedRoles: [],
    noFilters: false,
    debounceTime: 0,
    preselectedIds: {},
  };

  async componentDidMount() {
    await Promise.all([
      this._setSelectedSkills(),
      this._setSelectedRoles(),
      this._setSelectedRoleRelevancies(),
    ]);
    this.onFilterChange();
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
    } = this.props;
    const {
      match: { params: prevParams },
    } = prevProps;
    if (params.teamId !== prevParams.teamId) {
      this.resetFilter();
    }
  }

  async _setSelectedSkills() {
    if (!this.props.preselectedIds.skillId) {
      return;
    }

    const skill = await getSkill(this.props.preselectedIds.skillId);
    if (!skill) {
      return;
    }

    this.setState((prevState) => ({ ...prevState, selectedSkills: [skill] }));
  }

  async _setSelectedRoles() {
    if (!this.props.preselectedIds.roleId) {
      return;
    }

    const preselectedRole = await getJobProfile(this.props.preselectedIds.roleId);
    if (!preselectedRole) {
      return;
    }

    this.setState((prevState) => ({ ...prevState, selectedRoles: [preselectedRole] }));
  }

  async _setSelectedRoleRelevancies() {
    if (!this.props.preselectedIds.relevancyId) {
      return;
    }
    const preselectedRelevancy = await getRoleRelevancy(this.props.preselectedIds.relevancyId).then(
      (response) => response?.data?.roleRelevancy,
    );
    if (!preselectedRelevancy) {
      return;
    }
    this.setState((prevState) => ({ ...prevState, selectedRelevancies: [preselectedRelevancy] }));
  }

  state = { ...INITIAL_FILTERS };

  resetFilter() {
    this.setState({ ...INITIAL_FILTERS }, () => {
      this.onFilterChange();
    });
  }

  buildFilters() {
    const {
      searchFilter,
      leftSideSearch,
      createdInRoleFilter,
      membersFilter,
      coachesFilter,
      typesFilter,
      skillTypesFilter,
      statusFilter,
      leftSideStatus,
      categoriesFilter,
      customSearchFilter,
      teams,
      skills,
      skillsFilter,
      teamsFilter,
      customFilter,
      noFilters,
      goalCyclesFilter,
    } = this.props;
    const {
      search,
      createdIn,
      selectedMembers,
      selectedCoaches,
      selectedTypes,
      selectedCategories,
      selectedSkillType,
      selectedStatuses,
      selectedTeams,
      selectedSkills,
      selectedGoalCycles,
      selectedCustomFilter,
    } = this.state;
    const list = [];

    if (noFilters) {
      return search;
    }

    if ((searchFilter || leftSideSearch) && search) {
      customSearchFilter
        ? list.push(customSearchFilter(search))
        : list.push(
            (item) => (item?.name || '').toLowerCase().indexOf((search || '').toLowerCase()) !== -1,
          );
    }

    if (createdInRoleFilter && createdIn.length > 0) {
      list.push((item) => createdIn.indexOf(item.createdInRole) !== -1);
    }

    if (membersFilter && selectedMembers.length > 0) {
      list.push(
        (item) =>
          selectedMembers.indexOf(item.createdFor) !== -1 ||
          intersection(selectedMembers, item.users).length > 0,
      );
    }

    if (customFilter && customFilter.key && selectedCustomFilter.length > 0) {
      list.push((item) => selectedCustomFilter.includes(item[customFilter.key]));
    }

    if (coachesFilter && selectedCoaches.length > 0) {
      list.push((item) =>
        selectedCoaches.some(
          (coachId) =>
            (item.coaches || []).includes(coachId) || (item.coach && item.coach === coachId),
        ),
      );
    }

    if (skills && skillsFilter && selectedSkills.length > 0) {
      list.push((item) => {
        const skillIds = selectedSkills.map((skill) => skill.id);
        const skillsInterSection = intersection(skillIds, item.skills);
        return skillIds.indexOf(item.skills) !== -1 || skillsInterSection.length > 0;
      });
    }

    if (teams && teamsFilter && selectedTeams.length > 0) {
      const selectedTeamsUsers = selectedTeams.flatMap((t) => teams[t].members);

      list.push(
        (item) =>
          selectedTeamsUsers.indexOf(item.createdFor) !== -1 ||
          intersection(selectedTeamsUsers, item.users).length > 0,
      );
    }

    if (typesFilter && selectedTypes.length > 0) {
      list.push((item) => selectedTypes.indexOf(item.type) !== -1);
    }

    if (skillTypesFilter && selectedSkillType !== 'all') {
      list.push((item) => {
        return selectedSkillType === item.type || selectedSkillType === 'all';
      });
    }

    if ((statusFilter || leftSideStatus) && selectedStatuses.length > 0) {
      list.push((item) => selectedStatuses.indexOf(item.status) !== -1);
    }

    if (goalCyclesFilter && selectedGoalCycles.length > 0) {
      list.push((item) => {
        if (!item.goalCycles) {
          return false;
        }

        let retVal = false;
        item.goalCycles.forEach((c) => {
          if (selectedGoalCycles.includes(c)) {
            retVal = true;
          }
        });
        return retVal;
      });
    }

    if (categoriesFilter && selectedCategories.length > 0) {
      list.push((item) => {
        if (!item.categories) {
          return false;
        }

        let retVal = false;
        item.categories.forEach((category) => {
          if (selectedCategories.includes(category)) {
            retVal = true;
          }
        });
        return retVal;
      });
    }

    return composeFilters(list);
  }

  onFilterChange = () => {
    this.props.onFilterChange && this.props.onFilterChange(this.buildFilters());
    this.props.onFilterSelectedChange && this.props.onFilterSelectedChange({ ...this.state });
  };
  debouncedFilterChange = debounce(this.onFilterChange, this.props.debounceTime);

  handleSearch = (e) => this.setState({ search: e.target.value }, this.debouncedFilterChange);

  handleChangeCategory = (activeFilters) => {
    this.setState({ selectedCategories: activeFilters }, this.onFilterChange);
  };

  handleChangeType = (activeFilters) => {
    this.setState({ selectedTypes: activeFilters }, this.onFilterChange);
  };

  handleChangeStatus = (activeFilters) => {
    this.setState({ selectedStatuses: activeFilters }, this.onFilterChange);
  };

  handleChangeGoalCycles = (activeFilters) => {
    this.setState({ selectedGoalCycles: activeFilters }, this.onFilterChange);
  };

  handleChangeRoles = (activeFilters) =>
    this.setState({ selectedRoles: activeFilters }, this.onFilterChange);

  handleChangeRelevancies = (activeFilters) =>
    this.setState({ selectedRelevancies: activeFilters }, this.onFilterChange);

  handleChangeSkillType = (activeType) => {
    this.setState({ selectedSkillType: activeType }, this.onFilterChange);
  };

  handleChangeFilter = (activeFilters) => {
    this.setState({ createdIn: activeFilters }, this.onFilterChange);
  };

  handleChangeMembers = (activeFilters) => {
    this.setState({ selectedMembers: activeFilters }, this.onFilterChange);
  };

  handleChangeSkills = (activeFilters) => {
    this.setState({ selectedSkills: activeFilters }, this.onFilterChange);
  };

  handleChangeCoaches = (activeFilters) => {
    this.setState({ selectedCoaches: activeFilters }, this.onFilterChange);
  };

  handleChangeTeams = (activeFilters) => {
    this.setState({ selectedTeams: activeFilters }, this.onFilterChange);
  };

  handleChangeCustomFilter = (activeFilters) => {
    this.setState({ selectedCustomFilter: activeFilters }, this.onFilterChange);
  };

  getMembers() {
    const { users, currentRole, currentTeam } = this.props;

    switch (currentRole) {
      case ROLES.ADMIN: {
        return values(users);
      }
      case ROLES.COACH: {
        let allMembers = [];
        if (!isEmpty(currentTeam.members)) {
          allMembers = [...currentTeam.members];
        }

        if (!isEmpty(allMembers)) {
          allMembers = allMembers
            .map((m) => (m.id ? m : users[m] ? users[m] : null))
            .filter((m) => m);
        }
        return allMembers;
      }
      default: {
        return [];
      }
    }
  }

  render() {
    const {
      className,
      i18n,
      createdInRoleFilter,
      searchFilter,
      searchPlaceholder,
      customFilter,
      teams,
      teamsFilter,
      leftSideTeams,
      membersFilter,
      leftSideMembers,
      typesFilter,
      statusFilter,
      skillsFilter,
      categoriesFilter,
      roleFilter,
      relevancyFilter,
      categories,
      currentRole,
      addNewCategory,
      updateCategory,
      removeCategory,
      skillTypesFilter,
      statuses,
      children,
      leftSide,
      leftSideSearch,
      searchWidth,
      searchHight,
      actions,
      noBorders,
      wrapperStyles,
      coachesFilter,
      leftSideCoaches,
      leftSideStatus,
      coaches,
      users,
      types,
      goalCycles,
      notIncludedRoles,
      leftSideRoles,
      rightSideSearch,
      isLeftSide,
      goalCyclesFilter,
    } = this.props;
    const {
      search,
      createdIn,
      selectedMembers,
      selectedCoaches,
      selectedTypes,
      selectedRoles,
      selectedRelevancies,
      selectedCategories,
      selectedSkillType,
      selectedStatuses,
      selectedGoalCycles,
      selectedTeams,
      selectedSkills,
      selectedCustomFilter,
    } = this.state;
    const members = this.getMembers();
    const typesList =
      types ||
      map(ACTIVITIES_LIBRARY_TYPE_FILTER, (t) => ({
        id: t.id,
        label: t.label(i18n),
      }));

    return (
      <Row
        className={className}
        $isLeftSide={isLeftSide || leftSideSearch}
        $noBorders={noBorders}
        style={wrapperStyles}
      >
        {searchFilter && (
          <StyledSearch
            placeholder={searchPlaceholder || i18n._(t`Search`)}
            value={search}
            $searchWidth={searchWidth}
            onChange={this.handleSearch}
            $searchHight={searchHight}
          />
        )}
        {customFilter && (
          <GapWrapper>
            <SearchSelectButton
              checkedList={selectedCustomFilter}
              title={customFilter.title}
              options={customFilter.options}
              handleChange={this.handleChangeCustomFilter}
            />
          </GapWrapper>
        )}
        {leftSide ||
          ((leftSideSearch || leftSideMembers || leftSideTeams || leftSideRoles) && (
            <LeftSide>
              {leftSide && leftSide}
              {leftSideSearch && (
                <StyledSearch
                  placeholder={i18n._(t`Search`)}
                  value={search}
                  onChange={this.handleSearch}
                />
              )}
              {leftSideMembers && membersFilter && currentRole !== ROLES.USER && (
                <GapWrapper>
                  <SearchSelectButton
                    checkedList={selectedMembers}
                    options={map(members, (m) => ({ id: m.id, label: getUserFullName(m) }))}
                    title={i18n._(currentRole === ROLES.ADMIN ? t`Employee` : t`Team Member(s)`)}
                    handleChange={this.handleChangeMembers}
                  />
                </GapWrapper>
              )}
              {leftSideTeams && teams && teamsFilter && currentRole !== ROLES.USER && (
                <GapWrapper>
                  <SearchSelectButton
                    checkedList={selectedTeams}
                    options={map(teams, (t) => ({ id: t.id, label: t.name }))}
                    title={i18n._(t`Team(s)`)}
                    handleChange={this.handleChangeTeams}
                  />
                </GapWrapper>
              )}
              {leftSideCoaches && coachesFilter && currentRole === ROLES.ADMIN && (
                <GapWrapper>
                  <SearchSelectButton
                    checkedList={selectedCoaches}
                    options={map(
                      Object.values(users).filter((m) => coaches.includes(m.id)),
                      (m) => ({ id: m.id, label: getUserFullName(m) }),
                    )}
                    title={i18n._(t`Coaches`)}
                    handleChange={this.handleChangeCoaches}
                  />
                </GapWrapper>
              )}
              {leftSideStatus && (
                <GapWrapper>
                  <SearchSelectButton
                    checkedList={selectedStatuses}
                    title={i18n._(t`Status`)}
                    options={statuses}
                    handleChange={this.handleChangeStatus}
                  />
                </GapWrapper>
              )}
              {leftSideRoles && createdInRoleFilter && (
                <GapWrapper>
                  <SearchSelectButton
                    checkedList={createdIn}
                    options={rolesFiler.filter((r) => !notIncludedRoles.includes(r.id))}
                    title={i18n._(t`Created by`)}
                    handleChange={this.handleChangeFilter}
                  />
                </GapWrapper>
              )}
            </LeftSide>
          ))}
        {children}
        {categoriesFilter && (
          <GapWrapper>
            <SearchSelectButton
              checkedList={selectedCategories}
              options={categories}
              addNewItem={addNewCategory}
              handleChange={this.handleChangeCategory}
              updateItem={updateCategory}
              removeItem={removeCategory}
              title={i18n._(t`Category`)}
              position="left"
            />
          </GapWrapper>
        )}
        {typesFilter && (
          <GapWrapper>
            <SearchSelectButton
              checkedList={selectedTypes}
              title={i18n._(t`Type`)}
              options={typesList}
              handleChange={this.handleChangeType}
            />
          </GapWrapper>
        )}
        {skillsFilter && !leftSideMembers && (
          <GapWrapper>
            <AutocompleteFilterSkills
              checkedList={selectedSkills}
              title={i18n._(t`Skills`)}
              height="32px"
              chevronHeight={8}
              chevronWidth={11}
              onChange={this.handleChangeSkills}
              styles={{ height: '32px', width: '164px' }}
            />
          </GapWrapper>
        )}
        {membersFilter && currentRole !== ROLES.USER && !leftSideMembers && (
          <GapWrapper>
            <SearchSelectButton
              checkedList={selectedMembers}
              options={map(members, (m) => ({ id: m.id, label: getUserFullName(m) }))}
              title={i18n._(currentRole === ROLES.ADMIN ? t`Member(s)` : t`Team Member(s)`)}
              handleChange={this.handleChangeMembers}
            />
          </GapWrapper>
        )}
        {teams && teamsFilter && currentRole !== ROLES.USER && !leftSideTeams && (
          <GapWrapper>
            <SearchSelectButton
              checkedList={selectedTeams}
              options={map(teams, (t) => ({ id: t.id, label: t.name }))}
              title={i18n._(t`Team(s)`)}
              handleChange={this.handleChangeTeams}
            />
          </GapWrapper>
        )}
        {statusFilter && (
          <GapWrapper>
            <SearchSelectButton
              checkedList={selectedStatuses}
              title={i18n._(t`Status`)}
              options={statuses}
              handleChange={this.handleChangeStatus}
            />
          </GapWrapper>
        )}
        {createdInRoleFilter && !leftSideRoles && (
          <GapWrapper>
            <SearchSelectButton
              checkedList={createdIn}
              options={rolesFiler.filter((r) => !notIncludedRoles.includes(r.id))}
              title={i18n._(t`Created by`)}
              handleChange={this.handleChangeFilter}
            />
          </GapWrapper>
        )}
        {goalCyclesFilter && (
          <GapWrapper>
            <SearchSelectButton
              checkedList={selectedGoalCycles}
              title={i18n._(t`Goal cycles`)}
              options={goalCycles}
              handleChange={this.handleChangeGoalCycles}
            />
          </GapWrapper>
        )}
        {skillTypesFilter && (
          <Select
            height="32px"
            value={selectedSkillType}
            options={[
              { value: 'all', name: i18n._(t`All`) },
              { value: 'soft', name: i18n._(t`Soft skills`) },
              { value: 'hard', name: i18n._(t`Hard skills`) },
            ]}
            onChange={this.handleChangeSkillType}
          />
        )}
        {roleFilter && (
          <AutocompleteFilterRoles
            height="32px"
            chevronHeight={8}
            chevronWidth={11}
            onChange={this.handleChangeRoles}
            checkedList={selectedRoles}
            styles={{ height: '32px', width: '164px' }}
          />
        )}
        {relevancyFilter && (selectedRoles ?? []).length > 0 && (
          <AutocompleteFilterRelevancies
            title={i18n._(t`Relevancies`)}
            height="32px"
            chevronHeight={8}
            chevronWidth={11}
            onChange={this.handleChangeRelevancies}
            checkedList={selectedRelevancies}
            styles={{ height: '32px', width: '164px' }}
          />
        )}
        {rightSideSearch && (
          <StyledSearch
            placeholder={i18n._(t`Search`)}
            value={search}
            $searchWidth={searchWidth}
            onChange={this.handleSearch}
          />
        )}
        {actions}
      </Row>
    );
  }
}

export default connect((state) => ({
  currentRole: getSelectedRole(state),
  currentTeam: getCurrentTeam(state),
  users: getAllUsers(state),
}))(withI18n()(withRouter(FiltersHeading)));
