import React, { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import Switch from '~/components/Switch';
import LockedHint from '~/pages/ReviewUserPage/components/LockedHint';

import { REVIEW_STAGES, ROLES } from '~/constants';
import { getUser, getSelectedRole, checkModuleConversations } from '~/selectors/baseGetters';
import { COLOR_PALETTE, COLORS } from '~/styles';

// 600px is max, with bigger height position wrong, because height > screen height
const ReviewOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 526px;
  max-height: ${(props) => props.$maxHeight || 600}px;
  overflow-y: auto;
`;

const ReviewOptionsTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const ReviewOptionsDescription = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-top: 8px;
`;

const ReviewOptionsHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 24px 0px;
`;

const ReviewOptionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 24px 12px;
  &:first-of-type {
    margin: 12px 24px 27px;
  }
  &:last-of-type {
    margin: 12px 24px 24px;
  }
`;

const ReviewOptionsBlockDivider = styled.div`
  background-color: ${COLOR_PALETTE.GRAY_MIDDLE};
  height: 1px;
  width: 100%;
`;

const ReviewOptionsBlockButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 8px;

  > div {
    display: flex;
    align-items: center;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ReviewOptionsBlockButtonsLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const ReviewOptionsBlockButtonsReminder = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ReviewOptionsBlockToggles = styled.div`
  display: flex;
  padding: 12px 24px;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};

  &:last-of-type {
    border-bottom: none;
  }

  justify-content: space-between;
`;

const ReviewOptionsBlockTogglesText = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReviewOptionsBlockTogglesLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${COLOR_PALETTE.BLACK};
`;

const ReviewOptionsBlockTogglesDescription = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const SwitchWrapper = styled(Switch)`
  margin-left: 24px;
  margin-top: 28px;
`;

const ReviewOptionsActions = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReviewOptionsAction = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLOR_PALETTE.BLACK};
  cursor: pointer;
  padding: 12px 24px;
  border-bottom: solid 1px ${COLOR_PALETTE.GRAY_MIDDLE};

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: ${COLORS.BG_PAGE};
  }

  &:active {
    background-color: ${COLORS.HOVER_ACTIVE};
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReviewOptions = ({
  sendNominatePeersReminder,
  sendSelfReviewReminder,
  sendPeerReviewReminder,
  sendCoachReviewReminder,
  sendDigitalSignReminder,
  sendScheduleConversationReminder,
  shareCoachesCalibratedReviews,
  toggleDisableReview,
  toggleLockReview,
  downloadPDF,
  exportCSV,
  updateReview,
  deleteReview,
  updateCoaches,
  updateConversationCoaches,
  review,
}) => {
  const currentRole = useSelector(getSelectedRole);
  const userViewer = useSelector(getUser);
  const isConversationsModule = useSelector(checkModuleConversations);
  const [loadingStatus, setLoadingStatus] = useState({});
  const isCreatedByUser = review.createdBy === userViewer.id && review.createdIn === ROLES.USER;

  const isShowOptions =
    (currentRole === ROLES.ADMIN && !review.originalReview) ||
    (review.originalReview &&
      currentRole === ROLES.COACH &&
      review.createdBy === userViewer.id &&
      review.createdIn === ROLES.COACH) ||
    isCreatedByUser;

  const isShowShareOptions =
    (currentRole === ROLES.ADMIN && !review.originalReview && review.template?.isCalibrate) ||
    review.createdBy === userViewer.id;

  const disableToggle = review.lockedStages && review.lockedStages[REVIEW_STAGES.NOMINATE];
  const lockToggle = review.lockedStages && review.lockedStages[REVIEW_STAGES.FEEDBACK];

  const reminderDates = review.reminders;

  const reviewOptionsReminders = [
    review.isUsersReview &&
      !isCreatedByUser && {
        label: t`Ask peers for input`,
        reminderDate: reminderDates.nominatePeers,
        action: () => sendNominatePeersReminder,
      },
    review.isSelfReview &&
      !isCreatedByUser && {
        label: t`Prepare for the conversation`,
        reminderDate: reminderDates.selfReview,
        action: () => sendSelfReviewReminder,
      },
    review.isUsersReview && {
      label: t`Peers provide input`,
      reminderDate: reminderDates.peerReview,
      action: () => sendPeerReviewReminder,
    },
    review.isCoachesReview && {
      label: t`Coach provide input`,
      reminderDate: reminderDates.coachReview,
      action: () => sendCoachReviewReminder,
    },
    // only for admin reviews (for coach hide)
    isConversationsModule &&
      sendScheduleConversationReminder && {
        label: t`Plan the conversation`,
        reminderDate: reminderDates.conversation,
        action: () => sendScheduleConversationReminder,
      },
    review?.template?.digitalSign && {
      label: t`Complete the conversation`,
      reminderDate: reminderDates.digitalSign,
      action: () => sendDigitalSignReminder,
    },
  ].filter((i) => i);

  const reviewShareOptions = [
    !isCreatedByUser && {
      label: t`Share coach input (if calibrate)`,
      action: shareCoachesCalibratedReviews,
      tooltip: t`This will change the status from the coach input with status calibrate to status shared. This will make the coach input visible to the employee's. This action cannot be undone.`,
      isShowLoadingOnAction: true,
    },
  ].filter(Boolean);

  const reviewOptionsToggles = [
    !isCreatedByUser && {
      label: t`Disable people selections`,
      description: t`People can't nominate others anymore. Current nominees will remain.`,
      value: disableToggle,
      action: toggleDisableReview,
      disabled: lockToggle,
    },
    !isCreatedByUser && {
      label: t`Complete & lock review`,
      description: t`No more changes can be made to the conversation`,
      value: lockToggle,
      action: toggleLockReview,
    },
  ].filter(Boolean);

  const reviewOptionsActions = [
    !review.originalReview
      ? { label: t`Export CSV`, action: () => exportCSV }
      : { label: t`Download PDF`, action: () => downloadPDF },
    isShowOptions &&
      (currentRole === ROLES.COACH || (currentRole === ROLES.USER && isCreatedByUser)) &&
      isEmpty(review.signatures) &&
      review?.isCoachesReview && {
        label: t`Update Review coaches`,
        action: () => updateCoaches,
        isLocked: lockToggle,
        lockedTooltip: t`It is no longer possible to update coach reviewers. Review is locked`,
      },
    isShowOptions &&
      (currentRole === ROLES.COACH || (currentRole === ROLES.USER && isCreatedByUser)) &&
      isEmpty(review.signatures) && {
        label: t`Update participants`,
        action: () => updateConversationCoaches,
        isLocked: lockToggle,
        lockedTooltip: t`It is no longer possible to update participants. Review is locked`,
      },

    isShowOptions &&
      isEmpty(review.signatures) && { label: t`Edit conversation`, action: () => updateReview },
    (isShowOptions ||
      (currentRole === ROLES.ADMIN &&
        (review.createdIn === ROLES.COACH || review.createdIn === ROLES.USER))) && {
      label: t`Delete conversation`,
      action: () => deleteReview,
    },
  ].filter(Boolean);

  const $maxHeight = window.innerHeight - 100;

  return (
    <ReviewOptionsWrapper $maxHeight={$maxHeight}>
      {isShowOptions && (
        <ReviewOptionsHeader>
          <ReviewOptionsTitle>
            <Trans>Send reminder</Trans>
          </ReviewOptionsTitle>
          <ReviewOptionsDescription>
            <Trans>
              All participants that didn&apos;t completed the task will be notified to do so.
            </Trans>
          </ReviewOptionsDescription>
        </ReviewOptionsHeader>
      )}
      {isShowOptions && (
        <OptionsWrapper>
          <ReviewOptionsBlock>
            {reviewOptionsReminders.map((reviewBlock, i) => {
              return (
                <ReviewOptionsBlockButtons key={i}>
                  <div>
                    <ReviewOptionsBlockButtonsLabel>
                      <Trans id={reviewBlock.label} />
                    </ReviewOptionsBlockButtonsLabel>
                  </div>
                  <div>
                    <ReviewOptionsBlockButtonsReminder>
                      {reviewBlock.reminderDate ? moment(reviewBlock.reminderDate).fromNow() : null}
                    </ReviewOptionsBlockButtonsReminder>
                    <Button
                      type="primary-border"
                      label={<Trans>Send</Trans>}
                      onClick={reviewBlock.action()}
                      height={32}
                      width={83}
                    />
                  </div>
                </ReviewOptionsBlockButtons>
              );
            })}
          </ReviewOptionsBlock>
          {isShowShareOptions && !isCreatedByUser && (
            <>
              <ReviewOptionsBlockDivider />
              {!isCreatedByUser && (
                <ReviewOptionsBlock>
                  {reviewShareOptions.map(
                    ({ tooltip, label, action, isShowLoadingOnAction }, i) => {
                      const loadingKey = 'shareOptions-' + i;
                      const onClickAction = isShowLoadingOnAction
                        ? async (...props) => {
                            setLoadingStatus({ ...loadingStatus, [loadingKey]: true });
                            await action(...props);
                            setLoadingStatus({ ...loadingStatus });
                          }
                        : action;

                      return (
                        <ReviewOptionsBlockButtons key={i}>
                          <div>
                            <ReviewOptionsBlockButtonsLabel>
                              <Trans id={label} />
                            </ReviewOptionsBlockButtonsLabel>
                          </div>
                          <div>
                            <Button
                              loading={loadingStatus[loadingKey]}
                              disabled={loadingStatus[loadingKey]}
                              type="primary-border"
                              label={<Trans>Share</Trans>}
                              onClick={onClickAction}
                              tooltip={tooltip}
                              height={32}
                              width={83}
                            />
                          </div>
                        </ReviewOptionsBlockButtons>
                      );
                    },
                  )}
                </ReviewOptionsBlock>
              )}
            </>
          )}
          {!isCreatedByUser && (
            <>
              <ReviewOptionsBlockDivider />
              <div>
                {reviewOptionsToggles.map((toggle, i) => {
                  return (
                    <ReviewOptionsBlockToggles key={i}>
                      <ReviewOptionsBlockTogglesText>
                        <ReviewOptionsBlockTogglesLabel>
                          <Trans id={toggle.label} />
                        </ReviewOptionsBlockTogglesLabel>
                        <ReviewOptionsBlockTogglesDescription>
                          <Trans id={toggle.description} />
                        </ReviewOptionsBlockTogglesDescription>
                      </ReviewOptionsBlockTogglesText>
                      <div>
                        <SwitchWrapper
                          onChange={toggle.action}
                          checked={toggle.value}
                          disabled={toggle.disabled}
                        />
                      </div>
                    </ReviewOptionsBlockToggles>
                  );
                })}
              </div>
            </>
          )}
          <ReviewOptionsBlockDivider />
        </OptionsWrapper>
      )}
      <ReviewOptionsActions>
        {reviewOptionsActions.map((action, i) => {
          return (
            <ReviewOptionsAction onClick={!action.isLocked && action.action()} key={i}>
              <Trans id={action.label} />
              {action.isLocked && <LockedHint tooltip={<Trans id={action.lockedTooltip} />} />}
            </ReviewOptionsAction>
          );
        })}
      </ReviewOptionsActions>
    </ReviewOptionsWrapper>
  );
};

export default ReviewOptions;
