import React, { Component } from 'react';

import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SkillViewItem from '~/components/SkillViewItem';

const SkillsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SkillWrap = styled.div`
  display: flex;
`;

class SkillsView extends Component {
  static propTypes = {
    skills: PropTypes.array,
    skillsLevels: PropTypes.object,
    primaryColorView: PropTypes.object,
    onAddSkill: PropTypes.func,
    onUpdateSkill: PropTypes.func,
    onRemoveSkill: PropTypes.func,
    isUpdateMode: PropTypes.bool,
    readOnly: PropTypes.bool,
    isOnClickDisabled: PropTypes.bool,
  };

  static defaultProps = {
    skillsLevels: {},
    primaryColorView: false,
    onAddSkill: () => {},
    onUpdateSkill: () => {},
    isUpdateMode: false,
    readOnly: false,
    isOnClickDisabled: false,
  };

  renderSkill = (skill) => {
    const {
      skillsLevels,
      onAddSkill,
      onUpdateSkill,
      onRemoveSkill,
      primaryColorView,
      isUpdateMode,
      readOnly,
      isOnClickDisabled,
    } = this.props;

    if (isEmpty(skill)) {
      return null;
    }

    return (
      <SkillWrap key={skill.id}>
        <SkillViewItem
          skill={skill}
          isOnClickDisabled={isOnClickDisabled}
          skillLevel={skillsLevels[skill.id]}
          onAddSkill={onAddSkill}
          onUpdateSkill={onUpdateSkill(skill)}
          onRemoveSkill={onRemoveSkill}
          primaryColorView={primaryColorView}
          isUpdateMode={isUpdateMode}
          readOnly={readOnly}
        />
      </SkillWrap>
    );
  };

  render() {
    const { skills } = this.props;
    const { skillsLevels } = this.props;

    return (
      <SkillsWrap>{!isEmpty(skillsLevels) && skills && map(skills, this.renderSkill)}</SkillsWrap>
    );
  }
}

export default withI18n()(SkillsView);
