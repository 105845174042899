import React, { useCallback, useEffect, useState } from 'react';

import { GOAL_SORT_OPTIONS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonSize, ButtonVariant, Button } from '~/components/Buttons';
import { Dropdown } from '~/components/Dropdown';
import {
  Header,
  Heading,
  Title,
  Footer,
  StyledModal,
  StyledCloseButton,
} from '~/components/SearchSelectModal/design';

import { COLUMNS } from './columns';
import { AddButton, ListContainer, StyledTableList } from './design';

import { usePagination } from '~/hooks/usePagination';
import { getGoalSuggestions } from '~/services/goals';
import { COLORS } from '~/styles';

import type { IFocusArea, ISkill } from '@learned/types';

type IFilter = {
  search: string;
  skill: string;
  sortBy: GOAL_SORT_OPTIONS;
};

const initialFilters: IFilter = {
  search: '',
  skill: 'all',
  sortBy: GOAL_SORT_OPTIONS.NAME_A_Z,
};

type GoalSuggestionModalProps = {
  userId: string;
  onClose: () => void;
  onSelectSuggestion: (focusArea: IFocusArea) => void;
};

type PartialSkill = Partial<ISkill>;

const GoalSuggestionModal: React.FC<GoalSuggestionModalProps> = ({
  onClose,
  userId,
  onSelectSuggestion,
}) => {
  const { i18n } = useLingui();
  const initialItem = { id: 'all', name: i18n._(t`All`) };
  const [isLoading, setIsLoading] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<IFilter>(initialFilters);
  const { pagination, changePagination, resetPagination } = usePagination(10);
  const [suggestions, setSuggestions] = useState<Array<IFocusArea>>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<IFocusArea>();
  const [skills, setSkills] = useState<Array<ISkill>>([]);
  const [selectedSkill, setSelectedSkill] = useState<PartialSkill>(initialItem);
  const [totalSuggestions, setTotalSuggestions] = useState<number>(0);

  const fetchFocusAreas = useCallback(async () => {
    setIsLoading(true);
    const result = await getGoalSuggestions({
      userId,
      filters: {
        search: currentFilters.search,
        skills: selectedSkill.id !== 'all' ? [currentFilters.skill] : undefined,
      },
      options: {
        sortBy: currentFilters.sortBy,
        ...pagination,
      },
    });
    setSkills(result.data.skills);
    setSuggestions(result.data.suggestions || []);
    setTotalSuggestions(result.data.totalSuggestions);
    setIsLoading(false);
  }, [currentFilters, pagination, selectedSkill, userId]);

  const filters = {
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value });
      resetPagination();
    },
    setSkill: (skill: ISkill) => {
      setSelectedSkill(skill);
      setCurrentFilters({ ...currentFilters, skill: skill.id });
      resetPagination();
    },
  };

  const onResetFilters = (): void => {
    setSelectedSkill(initialItem);
    setCurrentFilters(initialFilters);
    resetPagination();
  };

  useEffect(() => {
    fetchFocusAreas();
  }, [currentFilters, pagination, fetchFocusAreas]);

  return (
    <StyledModal ignoreModalStyle showDivider={false} hideFooter isHideHeader onClose={onClose}>
      <Header>
        <Heading>
          <Title>
            <Trans>Goal suggestions</Trans>
          </Title>
          <StyledCloseButton
            label={''}
            variant={ButtonVariant.CLOSE}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
            color={COLORS.TOOLTIP_BACKGROUND}
          />
        </Heading>
      </Header>

      <ListContainer>
        <StyledTableList
          columns={COLUMNS}
          data={suggestions}
          isLoading={isLoading}
          filtersProps={{
            isToggleHideFilterVisible: true,
            filters,
            resetFilters: onResetFilters,
            isFiltered:
              !!currentFilters.search || (!!currentFilters.skill && currentFilters.skill !== 'all'),
            filterComponents: (
              <>
                <Dropdown
                  placeholder={i18n._(t`Skills`)}
                  items={[...skills, initialItem] as Array<ISkill>}
                  selectedItem={selectedSkill as ISkill}
                  onChange={(skill) => filters.setSkill(skill as ISkill)}
                  stringifyItem={(item: ISkill) => `${i18n._(t`${item.name}`)}`}
                  isSingleSelect
                />
              </>
            ),
          }}
          paginationProps={{
            pagination,
            changePagination,
            totalCount: totalSuggestions,
            paginationItemLabel: i18n._(t`Suggestions`),
          }}
          placeholderProps={{
            emptyStateText: i18n._(t`No suggestions found`),
          }}
          sortProps={{
            sortBy: currentFilters.sortBy,
            setSortBy: (sortBy: GOAL_SORT_OPTIONS) =>
              setCurrentFilters({ ...currentFilters, sortBy }),
          }}
          multiSelectProps={{
            isMultiSelectVisible: true,
            isSelectedCountVisible: false,
            isSelectAllVisible: false,
            isSingleSelect: true,
            multiSelect: {
              checkedCount: selectedSuggestion ? 1 : 0,
              onSelectItem: (item) =>
                setSelectedSuggestion((prevState) => {
                  if (prevState?.id === item.id) {
                    return null;
                  }
                  return item;
                }),
              isItemChecked: (item) => selectedSuggestion?.id === item.id,
            },
          }}
        />
      </ListContainer>
      <Footer>
        <Button
          label={i18n._(t`Cancel`)}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={onClose}
        />
        <AddButton
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          label={i18n._(t`Add goal`)}
          onClick={() => selectedSuggestion && onSelectSuggestion(selectedSuggestion)}
        />
      </Footer>
    </StyledModal>
  );
};

export default GoalSuggestionModal;
