import React, { useEffect, useState } from 'react';

import {
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
  ENGAGEMENT_REPORT_CHART_TYPES,
  REPORT_TYPES,
  ROLES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { ICONS, ICON_SIZES } from '~/components/Icon';
import Card, {
  ETrend,
  ETypes,
  TScores,
  TChartData,
} from '~/components/Reports/DashboardCharts/Card';
import SelectDropDown from '~/components/SelectDropDown';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import BaseLayout from '~/layouts/BaseLayout';

import {
  CardWrapper,
  CustomIcon,
  NavigationCtr,
  PageHead,
  TeamTitle,
  TeamsHeader,
  Title,
  ViewReport,
  NoMembersPlaceholder,
} from './TeamsStyles';

import routes from '~/constants/routes';
import { getUser, checkModuleSurvey } from '~/selectors/baseGetters';
import getAppThemeColor from '~/selectors/getAppThemeColor';
import getCurrentTeam from '~/selectors/getCurrentTeam';
import { getEngagementCharts } from '~/services/reports';
import { COLORS } from '~/styles';
import * as reportsUtil from '~/utils/reports';

import { MONTH_OPTIONS, TOptions } from '../Reports/options';

const Teams = () => {
  const { i18n } = useLingui();
  const currentTeam = useSelector(getCurrentTeam);
  const [monthSelected, setMonthSelected] = useState<TOptions>();
  const [isLoading, setIsLoading] = useState(true);
  const [timePrimaryData, setTimePrimaryData] = useState<TChartData[]>([]);
  const [average, setAverage] = useState(0);
  const [deviation, setDeviation] = useState(0);
  const user = useSelector(getUser);
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const companyColor = useSelector(getAppThemeColor);
  const R = parseInt(companyColor.substring(1, 3), 16);
  const G = parseInt(companyColor.substring(3, 5), 16);
  const B = parseInt(companyColor.substring(5, 7), 16);
  const { addToast } = useToasts();

  const [scoreData, setScoreData] = useState<TScores>({
    bestScore: [],
    lowestScore: [],
  });

  const getTrend = (value: string) => {
    if (value === 'DOWN' || value === 'down') {
      return ETrend.DOWN;
    }
    if (value === 'UP' || value === 'up') {
      return ETrend.UP;
    }
    return ETrend.NO_CHANGE;
  };

  const getChartData = async () => {
    try {
      setIsLoading(true);
      const [chartData, themeData] = await Promise.all([
        getEngagementCharts({
          viewAs: ROLES.COACH,
          reportType: REPORT_TYPES.ENGAGEMENT,
          filters: {
            teams: [`${currentTeam?.id}`],
          },
          chartType: ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_MONTH,
          dateRange: reportsUtil.getDateForTimeFrame(monthSelected?.key || MONTH_OPTIONS[0].key),
        }),
        getEngagementCharts({
          viewAs: ROLES.COACH,
          reportType: REPORT_TYPES.ENGAGEMENT,
          filters: {
            teams: [`${currentTeam?.id}`],
          },
          chartType: ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_THEME_RANK,
          primaryDimension: ENGAGEMENT_REPORT_CHART_DIMENSIONS.THEME,
          dateRange: reportsUtil.getDateForTimeFrame(monthSelected?.key || MONTH_OPTIONS[0].key),
        }),
      ]);
      setScoreData({
        bestScore: themeData?.data?.themeRank?.top.map((item) => ({
          title: item.name,
          value: (item.value || 0).toFixed(2),
          trend: getTrend(item.trend),
          deviation: item.deviation,
        })),
        lowestScore: themeData?.data?.themeRank?.bottom.map((item) => ({
          title: item.name,
          value: (item.value || 0).toFixed(2),
          trend: getTrend(item.trend),
          deviation: item.deviation,
        })),
      });
      const series = chartData?.data?.averageEngagement?.series || [];
      setAverage(chartData?.data?.averageEngagement?.average || 0);
      setDeviation(chartData?.data?.averageEngagement?.deviation || 0);
      const tempSeries = series.map((item) => ({
        key: moment(item.month).format('MMM'),
        value: Number((item.value || 0).toFixed(2)),
        deviation: item.deviation || 0,
      }));
      const filteredValues = series.filter((item) => item.value);
      setTimePrimaryData(filteredValues.length > 0 ? tempSeries : []);
    } catch (_error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onMonthChange = (value: string) => {
    const selectedOption = MONTH_OPTIONS.find((option) => option.key === value);
    if (selectedOption) {
      setMonthSelected(selectedOption);
      localStorage.setItem('reports-SelectedTimeFrameOfTeams', value);
    }
  };

  useEffect(() => {
    const timeFrameSelected = localStorage.getItem('reports-SelectedTimeFrameOfTeams');
    const selectedOption = MONTH_OPTIONS.find((option) => option.key === timeFrameSelected);
    setMonthSelected(selectedOption || MONTH_OPTIONS[0]);
  }, []);

  useEffect(() => {
    if (isModuleSurveyEnabled && monthSelected && currentTeam?.id) {
      getChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModuleSurveyEnabled, monthSelected, currentTeam?.id]);

  return (
    <>
      <BaseLayout>
        {isModuleSurveyEnabled && (
          <>
            <TeamsHeader R={R} G={G} B={B}>
              <TeamTitle>{currentTeam.name || i18n._(t`Team`)}</TeamTitle>
              <SelectDropDown
                options={MONTH_OPTIONS}
                value={monthSelected?.key}
                onChange={onMonthChange}
                keyName="key"
                borderColor={COLORS.BORDERS}
                listMarginTop="8px"
                small
                labelStyle={{
                  fontSize: '12px',
                }}
                optionStyles={{
                  fontSize: '12px',
                }}
                label={i18n._(t`Select`)}
                renderLabel={(item: TOptions) => <div>{item.title(i18n)}</div>}
                width="200px"
                listHeight="226px"
              />
            </TeamsHeader>
            <PageHead>
              <Title>{i18n._(t`Engagement`)}</Title>
              <NavigationCtr>
                <ViewReport
                  onClick={() => {
                    if (user.isAdmin) {
                      routes.REPORTS_ADMIN_ENGAGEMENT.go(
                        // @ts-ignore
                        { role: ROLES.ADMIN },
                        { query: { team: currentTeam.id } },
                      );
                    } else if (user.isCoach) {
                      routes.REPORTS_COACH_TEAMS_ENGAGEMENT.go(
                        // @ts-ignore
                        { role: ROLES.USER },
                        { query: { team: currentTeam.id } },
                      );
                    }
                  }}
                >
                  {i18n._(t`View Report`)}
                  <CustomIcon icon={ICONS.NEXT} size={ICON_SIZES.SMALL} />
                </ViewReport>
              </NavigationCtr>
            </PageHead>
            <CardWrapper>
              <Card
                title={i18n._(t`Average engagement`)}
                chartData={timePrimaryData}
                deviation={deviation}
                average={average}
                isLoading={isLoading}
              />
              <Card
                title={i18n._(t`Best scoring themes`)}
                type={ETypes.BAR_CHART}
                data={scoreData.bestScore}
                isLoading={isLoading}
              />
              <Card
                title={i18n._(t`Lowest scoring themes`)}
                type={ETypes.BAR_CHART}
                data={scoreData.lowestScore}
                oppositeGradient
                bColor={COLORS.CONFIRMATION_MODAL_DELETE}
                isLoading={isLoading}
              />
            </CardWrapper>
          </>
        )}
        {!isModuleSurveyEnabled && currentTeam && !currentTeam.members.length && (
          <NoMembersPlaceholder>{i18n._(t`Team does not have any members!`)}</NoMembersPlaceholder>
        )}
      </BaseLayout>
    </>
  );
};

export default Teams;
