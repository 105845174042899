import React, { Children } from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Body14 } from '~/components/UI/Typographics/text';
import { Block, Title } from '~/pages/ReviewTemplateSetup/components/StyledComponents';

import { COLOR_PALETTE } from '~/styles';

const RateLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 328px;
  max-height: 48px;
`;

const RateRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const RateValue = styled(Body14)`
  display: flex;
  margin-left: 12px;
  min-width: 30px;
  color: ${COLOR_PALETTE.DARK_GRAY_2};
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.38;
`;

const Ratings = ({ children }) => {
  return (
    <Block>
      <Title>
        <Trans>Rating</Trans>
      </Title>
      {Children.map(children, (label, i) => (
        <RateRow key={label}>
          <RateValue>{i + 1}</RateValue>
          <RateLabel>{label}</RateLabel>
        </RateRow>
      ))}
    </Block>
  );
};

export default React.memo(Ratings);
