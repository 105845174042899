import { t } from '@lingui/macro';
import _ from 'lodash';
import find from 'lodash/find';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

import { RATING_TYPES } from '~/constants';
import { GIVE_FEEDBACK_LEGEND } from '~/constants/reviews';
import { SKILL_CATEGORY_TYPE, SKILL_SOURCE, CATEGORY_SKILL_RATING_KEYS } from '~/constants/skills';
import { COLOR_SET } from '~/styles';
import getSkillName from '~/utils/getSkillName';
import { getAverageSkillsList, getExpectedLevels } from '~/utils/ratingUtils';

const ITEM_TYPE = {
  SKILL: 'skill',
};

function prepareRadioData({
  i18n,
  lang,
  rates,
  jobProfile,
  selectedSkills,
  selectedReviews,
  selectedCategories,
  skillCategories,
}) {
  const selfRatings = !isEmpty(rates)
    ? rates.selfRatings.filter((r) => r.review && selectedReviews.includes(r.review.id))
    : [];
  const peerRatings = !isEmpty(rates)
    ? rates.peerRatings.filter((r) => r.review && selectedReviews.includes(r.review.id))
    : [];
  const coachRatings = !isEmpty(rates)
    ? rates.coachRatings.filter((r) => r.review && selectedReviews.includes(r.review.id))
    : [];

  const avgSelfSkills = getAverageSkillsList(selfRatings);
  const avgPeerSkills = getAverageSkillsList(peerRatings);
  const avgCoachSkills = getAverageSkillsList(coachRatings);
  const expectedLevels = getExpectedLevels(jobProfile.skillsLevels || {});

  const items = {};

  const softCategory = skillCategories.find((c) => c.type === SKILL_CATEGORY_TYPE.soft);

  // add item with ratings per skillJobProfile
  jobProfile.skills.forEach((s) => {
    if (
      selectedSkills.includes(s.id) &&
      (intersection(selectedCategories, s.categories).length > 0 ||
        ((s.source === SKILL_SOURCE.learned || s.source === SKILL_SOURCE.learned2) &&
          selectedCategories.includes(softCategory.id)))
    ) {
      items[s.id] = { type: ITEM_TYPE.SKILL, id: s.id };
    }
  });

  // add avg rating per skill for self/peer/coach to item
  Object.keys(items).forEach((key) => {
    const self = find(avgSelfSkills, (r) => r.id === key);
    const peer = find(avgPeerSkills, (r) => r.id === key);
    const coach = find(avgCoachSkills, (r) => r.id === key);
    const expected = find(expectedLevels, (r) => r.id === key);
    items[key].self = self;
    items[key].peer = peer;
    items[key].coach = coach;
    items[key].expected = expected;
  });

  const itemsToDisplay = Object.values(items);

  // prepare labels: skillName/custom question name
  const labels = itemsToDisplay.map((item) => {
    return [
      getSkillName(
        find(jobProfile.skills, (s) => s.id === item.id),
        lang,
      ),
    ];
  });

  function prepareData(type) {
    // type could be self/peer/coach
    return itemsToDisplay.map((item) => {
      const r = item[type];
      if (!r) {
        return null;
      }
      return r.avg;
    });
  }

  const data = {
    rates: items,
    labels,
    datasets: [],
  };

  const isSelfRatingsExist = !isEmpty(selfRatings);
  const isPeerRatingsExist = !isEmpty(peerRatings);
  const isCoachRatingsExist = !isEmpty(coachRatings);
  const isExpectedLevelExist = !isEmpty(expectedLevels);

  // add self
  if (isSelfRatingsExist) {
    data.datasets.push({
      fill: false,
      borderColor: COLOR_SET.BLUE,
      backgroundColor: COLOR_SET.BLUE,
      data: prepareData('self'),
      label: i18n._(t`Self assessment`),
    });
  }

  // add peer
  if (isPeerRatingsExist) {
    data.datasets.push({
      fill: false,
      borderColor: COLOR_SET.AMBER_YELLOW,
      backgroundColor: COLOR_SET.AMBER_YELLOW,
      data: prepareData('peer'),
      label: i18n._(t`Peer assessment`),
    });
  }

  // add coach
  if (isCoachRatingsExist) {
    data.datasets.push({
      fill: false,
      borderColor: COLOR_SET.PINK,
      backgroundColor: COLOR_SET.PINK,
      data: prepareData('coach'),
      label: i18n._(t`Coach assessment`),
    });
  }

  if (isExpectedLevelExist) {
    data.datasets.push({
      fill: false,
      borderColor: COLOR_SET.CYAN_GREEN,
      backgroundColor: COLOR_SET.CYAN_GREEN,
      data: prepareData('expected'),
      label: i18n._(t`Expected level`),
    });
  }

  return data;
}

function trimEnabledLevels(levels, key) {
  const firstItem = key ? levels[0][key] : levels[0];
  const lastItem = key ? _.last(levels)[key] : _.last(levels);

  if (!firstItem) {
    return trimEnabledLevels(levels.slice(1), key);
  } else if (!lastItem) {
    return trimEnabledLevels(levels.slice(0, -1), key);
  }

  return levels;
}

function prepareItemsForSkillsProgressBar({ i18n, rates, skill }) {
  const progressPerLevel = 100 / trimEnabledLevels(skill.levelsEnabled).length;

  const avgExpectedLevel = {
    progress: rates[skill.id]?.expected?.avg * progressPerLevel || 0,
  };

  return {
    avgExpectedLevel,
    avgSelfRating: {
      progress: (rates[skill.id]?.self?.avg * 100) / _.last(rates[skill.id]?.self?.scale) || 0,
      color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.SELF_RATING].color,
      scale: rates[skill.id]?.self?.scale,
      title: i18n._(t`Your input`),
    },
    avgPeersRating: {
      progress: (rates[skill.id]?.peer?.avg * 100) / _.last(rates[skill.id]?.peer?.scale) || 0,
      color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.OTHER_RATING].color,
      scale: rates[skill.id]?.peer?.scale,
      title: i18n._(t`Peer input`),
    },
    avgCoachesRating: {
      progress: (rates[skill.id]?.coach?.avg * 100) / _.last(rates[skill.id]?.coach?.scale) || 0,
      color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.COACH_RATING].color,
      scale: rates[skill.id]?.coach?.scale,
      title: i18n._(t`Coach input`),
    },
  };
}

const isEqual = (...items) => {
  for (let i = 0; i < items.length - 1; i++) {
    if (!_.isEqual(items[0], items[i + 1])) {
      return false;
    }
  }
  return true;
};

const prepareLastScale = (lastScale) => {
  return trimEnabledLevels(
    lastScale?.map((value, index) => ({ value, index: index + 1 })),
    'value',
  ).map((item) => item.index);
};

function checkLastSkill(rates, skillId, skills) {
  const skill = skills?.find((item) => item.id === skillId);
  const lastScale = skill?.levelsEnabled ? prepareLastScale(skill?.levelsEnabled) : undefined;

  return isEqual(
    rates[skillId]?.coach?.scale,
    rates[skillId]?.peer?.scale,
    rates[skillId]?.self?.scale,
    lastScale,
  );
}

export {
  trimEnabledLevels,
  prepareRadioData,
  prepareItemsForSkillsProgressBar,
  isEqual,
  prepareLastScale,
  checkLastSkill,
};

export function attachInfoForRatings({ ratings, i18n }) {
  return {
    [CATEGORY_SKILL_RATING_KEYS.AVG_SELF_RATING]: {
      ...ratings[CATEGORY_SKILL_RATING_KEYS.AVG_SELF_RATING],
      color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.SELF_RATING].color,
      label: GIVE_FEEDBACK_LEGEND[RATING_TYPES.SELF_RATING].label(i18n),
    },
    [CATEGORY_SKILL_RATING_KEYS.AVG_PEERS_RATING]: {
      ...ratings[CATEGORY_SKILL_RATING_KEYS.AVG_PEERS_RATING],
      color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.OTHER_RATING].color,
      label: GIVE_FEEDBACK_LEGEND[RATING_TYPES.OTHER_RATING].label(i18n),
    },
    [CATEGORY_SKILL_RATING_KEYS.AVG_COACHES_RATING]: {
      ...ratings[CATEGORY_SKILL_RATING_KEYS.AVG_COACHES_RATING],
      color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.COACH_RATING].color,
      label: GIVE_FEEDBACK_LEGEND[RATING_TYPES.COACH_RATING].label(i18n),
    },
    [CATEGORY_SKILL_RATING_KEYS.AVG_EXPECTED_LEVEL]: {
      ...ratings[CATEGORY_SKILL_RATING_KEYS.AVG_EXPECTED_LEVEL],
    },
  };
}
