import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import PeopleIcon from '~/components/Icons/People';
import Modal from '~/components/Modal';
import FeedbackModal from '~/components/Modals/FeedbackModal';
import Placeholder from '~/components/Placeholder';

import RequestItem from './RequestItem';

import { REQUEST_STATUSES, REQUEST_TYPES, NOMINATE_TYPE } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getUsers } from '~/selectors/baseGetters';
import { cancelRequest } from '~/services/requests';
import { nominatePeopleForReview } from '~/services/reviews';
import getUserFullName from '~/utils/getUserFullName';

const AddButton = styled(Button)`
  padding: 18px 0;
`;

function NominateModal({
  review,
  onClose,
  type,
  onRequestsCreated,
  onRequestCancel,
  isDisabled,
  skipRequestList,
  isChooseYourself = false,
}) {
  const { i18n } = useLingui();
  const $modal = useBoolState();
  const users = useSelector(getUsers);
  const requests = (review.requests || []).filter(
    (r) => r.status !== REQUEST_STATUSES.CANCELLED.key,
  );

  let requestToShow;
  switch (type) {
    case NOMINATE_TYPE.peer: {
      requestToShow = requests.filter(
        (r) =>
          [REQUEST_TYPES.FEEDBACK_FROM_PEER, REQUEST_TYPES.FEEDBACK_FROM_OUTSIDE_PEER].indexOf(
            r.type,
          ) !== -1,
      );
      break;
    }
    case NOMINATE_TYPE.coach: {
      requestToShow = requests.filter((r) => r.type === REQUEST_TYPES.FEEDBACK_FROM_COACH);
      break;
    }
    case NOMINATE_TYPE.conversationCoach: {
      requestToShow = requests.filter((r) => r.type === REQUEST_TYPES.NOMINATE_CONVERSATION_COACH);
      break;
    }
  }

  const userName = getUserFullName(users[review.createdFor]);

  const createRateRequests = async ({ users, emails, message }) => {
    let nominateType;
    switch (type) {
      case NOMINATE_TYPE.peer:
        nominateType = REQUEST_TYPES.FEEDBACK_FROM_PEER;
        break;
      case NOMINATE_TYPE.coach:
        nominateType = REQUEST_TYPES.FEEDBACK_FROM_COACH;
        break;
      case NOMINATE_TYPE.conversationCoach:
        nominateType = REQUEST_TYPES.NOMINATE_CONVERSATION_COACH;
        break;
    }

    const requests = await nominatePeopleForReview(review.id, nominateType, users, emails, message);

    onRequestsCreated(requests);
  };

  const onRequestDelete = async (request) => {
    const response = await cancelRequest(request.id);
    if (response) {
      onRequestCancel(request);
    }
  };

  const getTitle = () => {
    switch (type) {
      case NOMINATE_TYPE.peer: {
        return i18n._(t`Update nominated peers for ${userName}`);
      }
      case NOMINATE_TYPE.coach: {
        return i18n._(t`Update nominated coaches for ${userName}`);
      }
      case NOMINATE_TYPE.conversationCoach: {
        return i18n._(t`Update participants`);
      }
    }
  };

  const getAddButtonLabel = () => {
    switch (type) {
      case NOMINATE_TYPE.peer: {
        return i18n._(t`+ Add peers to list`);
      }
      case NOMINATE_TYPE.coach: {
        return i18n._(t`+ Add coaches to list`);
      }
      case NOMINATE_TYPE.conversationCoach: {
        return i18n._(t`+ Add participants to list`);
      }
    }
  };

  const modal = (
    <FeedbackModal
      review={review}
      onClose={onClose}
      type={type}
      onSubmit={createRateRequests}
      isChooseYourself={isChooseYourself}
    />
  );

  if (skipRequestList) {
    return modal;
  }

  return (
    <Modal title={getTitle()} onClose={onClose} width={625} hideFooter>
      {requestToShow.length === 0 && (
        <Placeholder
          title={<Trans>No people nominated</Trans>}
          subTitle={<Trans>There is no person nominated</Trans>}
          Icon={PeopleIcon}
        />
      )}
      {requestToShow.map((req) => (
        <RequestItem
          key={req.id}
          request={req}
          onRequestDelete={onRequestDelete}
          isDisabled={isDisabled}
        />
      ))}
      {!isDisabled && (
        <AddButton
          label={getAddButtonLabel()}
          type={Button.types.linkPrimary}
          onClick={$modal.on}
        />
      )}
      {$modal.value && modal}
    </Modal>
  );
}

NominateModal.propTypes = {
  type: PropTypes.oneOf([NOMINATE_TYPE.peer, NOMINATE_TYPE.coach, NOMINATE_TYPE.conversationCoach])
    .isRequired,
  review: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onRequestsCreated: PropTypes.func.isRequired,
  onRequestCancel: PropTypes.func.isRequired,
  skipRequestList: PropTypes.bool,
  isChooseYourself: PropTypes.bool,
};

const Nominate = React.memo(NominateModal);
Nominate.NOMINATE_TYPE = NOMINATE_TYPE;
export default Nominate;
