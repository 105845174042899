import React, { ChangeEvent } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import { SearchField } from '~/components/Text';

import { COLOR_PALETTE } from '~/styles';

export interface IHeaderProps {
  actionButton?: {
    label: string;
    onClick: () => void;
  };
  secondaryButton?: {
    label: string;
    onClick: () => void;
  };
  filters: {
    search: string;
    setSearch: (value: string) => void;
  };
}

const LeftSideActions = styled.div`
  display: flex;
`;

const RightSide = styled.div`
  display: flex;
  gap: 8px;
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 12px;
  width: 350px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLOR_PALETTE.WHITE};
  padding: 20px 32px;
  border-top-right-radius: 10px;
`;

const TableHeader: React.FC<IHeaderProps> = ({
  children,
  actionButton,
  secondaryButton,
  filters,
}) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <LeftSideActions>
        <SearchFieldWrapper
          onChange={(e: ChangeEvent<HTMLInputElement>) => filters.setSearch(e.currentTarget.value)}
          value={filters.search}
          placeholder={i18n._(t`Search`)}
          style={{
            borderRadius: '6px',
            fontSize: '14px',
            fontWeight: 600,
          }}
        />
        {children}
      </LeftSideActions>
      <RightSide>
        {secondaryButton && (
          <Button
            // @ts-ignore
            type={'shadow'}
            label={i18n._(secondaryButton.label)}
            onClick={secondaryButton.onClick}
            width={144}
            height={32}
          />
        )}
        {actionButton && (
          <Button
            // @ts-ignore
            type={'primary-shadow'}
            label={i18n._(actionButton.label)}
            onClick={actionButton.onClick}
            width={144}
            height={32}
          />
        )}
      </RightSide>
    </Wrapper>
  );
};

export { TableHeader };
