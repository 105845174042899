import { t } from '@lingui/macro';

/**
 * Enum skill source
 * @readonly
 * @enum {string}
 */
export const SKILL_SOURCE = {
  learned: 'learned',
  learned2: 'learned2',
  company: 'company',
};

/**
 * Enum skill type
 * @readonly
 * @enum {string}
 */
export const SKILL_TYPE = {
  soft: 'soft',
  hard: 'hard',
};

export const SKILL_CATEGORY_TYPE = {
  soft: 'soft',
  hard: 'hard',
  null: null,
};

export const SUGGESTIONS_SKILL_CATEGORY = {
  PROFESSIONAL_SKILLS: {
    id: 'professional_skills',
    name: (i18n) => i18n._(t`Professional skills`),
  },
  IT_SKILLS: {
    id: 'it_skills',
    name: (i18n) => i18n._(t`IT Skills`),
  },
  INTERPERSONAL_SKILLS: {
    id: 'soft_skills',
    name: (i18n) => i18n._(t`Interpersonal skills`),
  },
  LANGUAGE_SKILLS: {
    id: 'lang_skills',
    name: (i18n) => i18n._(t`Languages`),
  },
};

export const CATEGORY_SKILL_RATING_KEYS = {
  AVG_EXPECTED_LEVEL: 'avgExpectedLevel',
  AVG_SELF_RATING: 'avgSelfRating',
  AVG_PEERS_RATING: 'avgPeersRating',
  AVG_COACHES_RATING: 'avgCoachesRating',
};
