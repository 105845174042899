import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const RoundCheckBoxDeselected = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst>
    <g fill="none" fillRule="evenodd">
      <ellipse fill="#FFF" cx="12" cy="11.5" rx="9" ry="8.5" />
      <path
        d="M12 19.998a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-18c-5.524 0-10 4.477-10 10s4.476 10 10 10c5.522 0 10-4.477 10-10s-4.478-10-10-10z"
        fill="#1E0863"
      />
    </g>
  </InlineSvg>
);

RoundCheckBoxDeselected.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

RoundCheckBoxDeselected.defaultProps = {
  size: 24,
  className: '',
};

export default RoundCheckBoxDeselected;
