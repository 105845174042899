import React, { Component } from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import { Dropdown } from '~/components/Dropdown';
import Modal from '~/components/Modal';

import { JOB_PROFILE_STATUSES } from '~/constants';
import { getCareerPlans } from '~/services/careerPlans';
import { getJobProfiles } from '~/services/jobProfiles';
import sortCollectionByName from '~/utils/sortCollectionByName';
import './styles.scss';

const JobsDropdown = styled(Dropdown)`
  width: 100%;

  & > div:first-child {
    height: 48px;
  }
`;

const JobModal = styled(Modal)`
  & > div:nth-child(3) {
    overflow: visible;
  }
`;

const INITIAL_STATE = {
  loading: false,
  selectedJobProfile: [],
  jobProfiles: {},
};

class SelectJobProfileModal extends Component {
  static propTypes = {
    onModalClose: PropTypes.func,
    title: PropTypes.string,
    onSubmit: PropTypes.func,
    selectedUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    const { selectedUser } = this.props;
    Promise.all([
      getCareerPlans({ createdFor: selectedUser.id, status: CAREER_PLAN_STATUSES.CURRENT }),
      getJobProfiles({ status: JOB_PROFILE_STATUSES.ACTIVE.key }),
    ]).then(([userCareerPlans, jobProfiles]) => {
      const connectedJobProfiles = Object.values(userCareerPlans).map((c) => c.jobProfile);
      const notConnectedJobProfiles = Object.values(jobProfiles).filter(
        (jp) => !connectedJobProfiles.includes(jp.id),
      );
      const sortedJobProfiles = sortCollectionByName(notConnectedJobProfiles);
      this.setState({ jobProfiles: sortedJobProfiles });
    });
  }

  onChangeSelectedJobProfile = (selectedJobProfile) => {
    this.setState({
      selectedJobProfile,
    });
  };

  closeModal = () => {
    this.props.onModalClose();
  };

  handleSubmit = async () => {
    const { onSubmit } = this.props;
    const { selectedJobProfile } = this.state;
    this.setState({ loading: true });
    await onSubmit(selectedJobProfile);
    this.setState({ loading: false });
    this.closeModal();
  };

  render() {
    const { i18n, title } = this.props;
    const { loading, selectedJobProfile, jobProfiles } = this.state;

    const companyJobProfiles = Object.values(jobProfiles);

    return (
      <JobModal
        className="new-team-modal"
        title={title}
        onClose={this.closeModal}
        footerRight={
          <Button
            label={i18n._(t`Save`)}
            disabled={isEmpty(selectedJobProfile)}
            onClick={this.handleSubmit}
            loading={loading}
            type="primary"
          />
        }
        width={500}
        minWidth={500}
      >
        <div>
          <div className="modal_subtitle">
            <Trans>Select Job Profile</Trans>
          </div>
          <JobsDropdown
            items={companyJobProfiles}
            placeholder={i18n._(t`Jobs`)}
            onChange={this.onChangeSelectedJobProfile}
            selectedItems={selectedJobProfile}
            stringifyItem={(item) => item.name}
            skipSort
          />
        </div>
      </JobModal>
    );
  }
}

export default withI18n()(SelectJobProfileModal);
