import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import { Roadmap } from '~/pages/Roadmap';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('general', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.ROADMAP.routePath(ROLES.USER) as React.Key}
        exact
        path={routes.ROADMAP.routePath(ROLES.USER)}
        component={Roadmap}
        allowedRoles={[ROLES.USER]}
      />,
    ],
    coach: [
      <PrivateRoute
        key={routes.ROADMAP.routePath(ROLES.COACH) as React.Key}
        exact
        path={routes.ROADMAP.routePath(ROLES.COACH)}
        component={Roadmap}
        allowedRoles={[ROLES.COACH]}
      />,
    ],
    admin: [
      <PrivateRoute
        key={routes.ROADMAP.routePath(ROLES.ADMIN) as React.Key}
        exact
        path={routes.ROADMAP.routePath(ROLES.ADMIN)}
        component={Roadmap}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
