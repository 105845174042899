import React, { memo } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import FeedbackDeadline from '~/components/FeedbackDeadline';
import RoleLabel from '~/components/RoleLabel';
import UserAvatar from '~/components/UI/Avatar';
import TaskStatus from '~/pages/ReviewUserPage/components/TaskStatus';

import { Item, ItemNameText, ItemText } from './CommonStyledComponents';

import { REQUEST_STATUSES } from '~/constants';
import { COLOR_PALETTE } from '~/styles';

const ItemWrapper = styled(Item)`
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 110px;
`;

const DeadlineWrapper = styled.div`
  display: flex;
  width: 160px;
  align-items: center;
`;

const ItemStatus = styled(ItemText)`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-transform: capitalize;
`;

const UserRequestItem = ({
  deadline,
  request,
  isCoach,
  giveFeedback,
  isLocked,
  isDisabled,
  lockedHint,
  userName,
  isActionDisabled,
  actionTooltip,
}) => {
  const { i18n } = useLingui();

  const getActionLabel = () => {
    switch (request.status) {
      case REQUEST_STATUSES.REQUESTED.key:
        return i18n._(t`Start`);
      case REQUEST_STATUSES.CALIBRATE.key:
        return isLocked ? i18n._(t`Shared`) : i18n._(t`Share`);
      case REQUEST_STATUSES.SHARED.key:
        if (isCoach) {
          return isLocked ? i18n._(t`Shared`) : i18n._(t`Update`);
        } else {
          return i18n._(t`View`);
        }
      default:
        return i18n._(t`Continue`);
    }
  };

  const getActionType = () => {
    switch (request.status) {
      case REQUEST_STATUSES.REQUESTED.key:
        return Button.types.primary;
      case REQUEST_STATUSES.SHARED.key:
        return Button.types.linkPrimary;
      default:
        return Button.types.primaryBorder;
    }
  };

  const renderLockedStatus = () => {
    return request.status === REQUEST_STATUSES.SHARED.key ? (
      <Button
        type={Button.types.linkPrimary}
        onClick={giveFeedback}
        label={i18n._(t`View`)}
        styles={{ padding: 0 }}
      />
    ) : (
      <TaskStatus status={TaskStatus.STATUSES.EXPIRED} />
    );
  };

  const userId = isCoach ? request.toUser : request.fromUser;

  return (
    <ItemWrapper>
      <Row>
        <UserAvatar size={28} userId={userId} isGray={isDisabled} />
        <ItemNameText
          $disabled={isDisabled}
          $completed={[
            REQUEST_STATUSES.SHARED.key,
            REQUEST_STATUSES.REJECTED.key,
            REQUEST_STATUSES.CANCELLED.key,
          ].includes(request.status)}
        >
          {isCoach
            ? i18n._(t`Prepare for your review with ${userName}`)
            : i18n._(t`Provide input for ${userName}'s review`)}
        </ItemNameText>
        {isCoach && <RoleLabel value={i18n._(t`Coach`)} />}
        {isLocked && lockedHint}
      </Row>
      <Row>
        {!isLocked && !isDisabled && (
          <DeadlineWrapper>
            <FeedbackDeadline
              shared={request.status === REQUEST_STATUSES.SHARED.key}
              deadline={deadline}
              width="29px"
              height="28px"
            />
          </DeadlineWrapper>
        )}
        <Actions>
          {isLocked ? (
            renderLockedStatus()
          ) : (
            <>
              {isDisabled ||
              [REQUEST_STATUSES.REJECTED.key, REQUEST_STATUSES.CANCELLED.key].includes(
                request.status,
              ) ? (
                <ItemStatus>
                  {request.status === REQUEST_STATUSES.CALIBRATE.key
                    ? i18n._(t`Started`)
                    : i18n._(get(REQUEST_STATUSES, `[${request.status.toUpperCase()}].label`))}
                </ItemStatus>
              ) : (
                <Button
                  type={getActionType()}
                  onClick={giveFeedback}
                  label={getActionLabel()}
                  styles={{ minWidth: '83px', padding: 0 }}
                  disabled={
                    (request.status !== REQUEST_STATUSES.SHARED.key && isLocked) || isActionDisabled
                  }
                  tooltip={actionTooltip}
                />
              )}
            </>
          )}
        </Actions>
      </Row>
    </ItemWrapper>
  );
};

UserRequestItem.propTypes = {
  request: PropTypes.object,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isCoach: PropTypes.bool,
  giveFeedback: PropTypes.func,
  lockedHint: PropTypes.node,
  isActionDisabled: PropTypes.bool,
  actionTooltip: PropTypes.string,
};

UserRequestItem.defaultProps = {
  isCoach: false,
};

export default memo(UserRequestItem);
