import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

export default (startDate, endDate) => {
  if (startDate && endDate) {
    return `${convertToTimeString(
      startDate,
      TIME_FORMATS.CLASSIC_FULL_SLASH,
    )} - ${convertToTimeString(endDate, TIME_FORMATS.CLASSIC_NO_DATE)}`;
  }
};
