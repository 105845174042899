import { ROLES, ROLE_LIBRARY_TABS } from '~/constants';
import {
  pageOverview,
  pageDashboard,
  pageSupervisionUser,
  pageCreate,
  pageUpdate,
} from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const newRouteName = 'career';
const routeName = 'roles';
const defaultRoleId = ':roleId';
const defaultUserId = ':userId';
const roleTypes = {};
Object.keys(ROLE_LIBRARY_TABS).map((key) => (roleTypes[key] = ROLE_LIBRARY_TABS[key].key));

export const JOB_PROFILES_ADMIN = new LinkConstructor(
  [ROLES.ADMIN],
  () => `${pageOverview(routeName)}`,
);

// user career
export const USER_CAREER = new LinkConstructor([ROLES.USER], () => `${pageOverview(newRouteName)}`);

// dashboard
export const JOB_PROFILE = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ roleId = defaultRoleId }) => pageDashboard(routeName, { id: roleId }),
);

export const JOB_CREATE = new LinkConstructor([ROLES.ADMIN], () => `${pageCreate(routeName)}`);

// update
export const JOB_UPDATE = new LinkConstructor(
  [ROLES.ADMIN],
  ({ roleId = defaultRoleId }) => `${pageUpdate(routeName, { id: roleId })}`,
);

// supervision
export const JOB_PROFILE_SUPERVISION_USER = new LinkConstructor(
  [ROLES.COACH, ROLES.ADMIN],
  ({ roleId = defaultRoleId, userId = defaultUserId }) =>
    pageSupervisionUser(routeName, { id: roleId, userId }),
);
