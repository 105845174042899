import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import DeleteModal from '~/components/DeleteModal';
import Editor from '~/components/Editor';
import Modal from '~/components/Modal';
import SearchSelectButton from '~/components/SearchSelectButton';
import Switch from '~/components/Switch';
import { TextField, FieldGroup } from '~/components/Text';
import { withToasts, TOAST_TYPES } from '~/components/Toast';
import Tooltip from '~/components/Tooltip';

import FocusArea from './components/FocusArea';
import NewFocusArea from './components/NewFocusArea';

import { LANGUAGES } from '~/constants';
import { SKILL_SOURCE, SUGGESTIONS_SKILL_CATEGORY } from '~/constants/skills';
import { getCompanyFocusAreas } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import getLang from '~/selectors/getLang';
import { getCompanySkillCategories } from '~/services/companySettings';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkill, createSkill, updateSkill, deleteSkill, getSkills } from '~/services/skills';
import { COLOR_PALETTE, COLORS } from '~/styles';
import { mapCompanyDefaultFocusAreas, mapLevelsEnabled } from '~/utils/focusAreas';
import getSkillName from '~/utils/getSkillName';

import { AutocompleteFilterSuggestedSkillAliases } from '../AutocompleteFilters';

const ButtonActions = styled.div`
  display: flex;
`;

const LvlSection = styled.div`
  .rc-switch {
    margin-right: 48px;
  }
`;

const SkillLevelsHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  margin-bottom: 8px;
  color: ${COLOR_PALETTE.BLACK};
`;

const LevelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 24px;
`;

const LevelTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  color: ${COLORS.TEXT_BLACK};
`;

const Notice = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  color: ${COLORS.TEXT_SECONDARY};
`;

const StyledFieldGroup = styled(FieldGroup)`
  margin-bottom: 0;
`;

const TopSection = styled.div`
  box-sizing: border-box;
  padding: 16px;
  border-radius: 3px;
  border: solid 1px ${COLORS.BORDER_HARD};
  margin-bottom: 24px;
`;

const TextLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: ${COLOR_PALETTE.BLACK};
  margin-bottom: 8px;
`;

const TooltipContainer = styled.div`
  width: fit-content;
`;

const FilterSuggestedSkillAliases = styled(AutocompleteFilterSuggestedSkillAliases)`
  width: 100%;
  border: 1px solid ${COLORS.BORDER} !important;
  border-radius: 3px;
  font-style: normal;
  font-stretch: 100%;

  letter-spacing: normal;
  line-height: 1.38;
  color: ${COLORS.TEXT_BLACK};
  padding: 12px 9px;
  height: 48px;
  box-sizing: border-box;
  font-size: ${(props) => (!isEmpty(props.checkedList) ? '16px' : '14px')};
  color: ${(props) => (!isEmpty(props.checkedList) ? COLORS.TEXT_BLACK : COLORS.PLACEHOLDER)};
  font-weight: ${(props) => (!isEmpty(props.checkedList) ? 'normal' : '100')};
`;

// Override internal tippy class to allow the width to be dinamically set
const FilterSuggestedSkillAliasesContainer = styled.div`
  [data-tippy-root] {
    width: calc(100% - 86px);
  }
`;

class SkillSetupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      definition: '',
      aliases: [],
      categories: [],
      learnedSkills: [],
      focusAreas: [],
      defaultFocusAreas: [],
      originalFocusAreas: [],
      levelsEnabled: [],
      loading: true,
      skillAlreadyExists: false,
      showDeleteModal: false,
      originalSkillCategories: [],
    };
  }

  async componentDidMount() {
    const { skillLabels, currentCompany, defaultFocusAreas, lang } = this.props;
    const skill = Object.assign({}, { ...this.props.skill });
    let focusAreas;
    let skillAlreadyExists = false;

    const [skillCategories, categoryOrder, learnedSkills] = await Promise.all([
      getSkillCategories(),
      getCompanySkillCategories(currentCompany.id),
      getSkills({ hideDeleted: true, source: SKILL_SOURCE.learned2 }),
    ]);

    const orderedCategories = categoryOrder.map((c) => skillCategories[c]);

    // Because a skill can be created /updated.
    if (skill.id) {
      skillAlreadyExists = true;
      const skillWithFocusAreas = await getSkill(skill.id, ['focusAreas']);
      focusAreas = skillWithFocusAreas.focusAreas;
    } else {
      focusAreas = [];
      skill.aliases = [];
      skill.categories = [];
      skill.name = '';
    }

    // Obtain skill data if it's a Learned soft skill, or create an object if it's a suggested skill
    if (skill.aliases.length) {
      const learnedAliasSkill = Object.values(learnedSkills)
        .filter((learnedSkill) => (skill.aliases || []).includes(learnedSkill.id))
        .map((learnedSkill) => ({ ...learnedSkill, name: getSkillName(learnedSkill, lang) }));
      skill.aliases = !isEmpty(learnedAliasSkill)
        ? learnedAliasSkill
        : [{ name: skill.aliases[0], id: skill.aliases[0], isSuggestedSkill: true }];
    }

    // compare the length of skill levelsEnabled with the skillLabels length
    let levelsEnabled = !skill.levelsEnabled
      ? Array.from({ length: skillLabels.length }, (_v) => false)
      : skill.levelsEnabled.length >= skillLabels.length
      ? skill.levelsEnabled
      : [...skill.levelsEnabled].concat(
          Array.from({ length: skillLabels.length - skill.levelsEnabled.length }, (_v) => false),
        );

    // add focus area for level without focus area
    levelsEnabled.forEach((_l, index) => {
      const levelFA = focusAreas.find((fa) => fa.level === index + 1);
      if (!levelFA) {
        focusAreas = [...focusAreas, { name: '', level: index + 1 }];
      }
    });

    this.setState({
      skill,
      originalSkillCategories: skill.categories,
      focusAreas,
      defaultFocusAreas,
      originalFocusAreas: focusAreas,
      levelsEnabled,
      learnedSkills: Object.values(learnedSkills),
      loading: false,
      skillAlreadyExists,
      categories: orderedCategories,
    });
  }

  onChangeName = (e) => {
    const change = e.target.value;
    this.setState((prevState) => {
      let skill = Object.assign({}, prevState.skill);
      skill.name = change;
      return { skill };
    });
  };

  onChangeDescription = (definition) => {
    this.setState((prevState) => {
      let skill = Object.assign({}, prevState.skill);
      skill.definition = definition;
      return { skill };
    });
  };

  handleCreateFocusAreas = (focusArea) => {
    const { focusAreas, levelsEnabled } = this.state;

    // toggle levelEnabled
    const isLevelAvailable = focusAreas.find((fa) => fa.level === focusArea.level);
    let updatedLevelsEnabled = [...levelsEnabled];
    if (!isLevelAvailable && !updatedLevelsEnabled[focusArea.level - 1]) {
      updatedLevelsEnabled[focusArea.level - 1] = true;
    }

    let updatedFocusAreas = focusAreas.concat(focusArea);
    focusArea.key = '_' + Math.random().toString(36).substr(2); // should be unique enough
    this.setState({
      focusAreas: updatedFocusAreas,
      originalFocusAreas: updatedFocusAreas,
      levelsEnabled: updatedLevelsEnabled,
    });
  };

  changeFocusAreaName = (name, index, level) => {
    const { focusAreas, levelsEnabled } = this.state;
    const updatedFocusAreas = [...focusAreas].map((fa, i) => (i === index ? { ...fa, name } : fa));

    // toggle levelEnabled
    let updatedLevelsEnabled = [...levelsEnabled];
    if (!updatedLevelsEnabled[level - 1]) {
      updatedLevelsEnabled[level - 1] = true;
    }
    this.setState({
      focusAreas: updatedFocusAreas,
      originalFocusAreas: updatedFocusAreas,
      levelsEnabled: updatedLevelsEnabled,
    });
  };

  handleDeleteFA = (deletedFocusAreaLevel, index) => {
    const { focusAreas, levelsEnabled } = this.state;
    let updatedFocusAreas = [...focusAreas];
    updatedFocusAreas.splice(index, 1);

    // toggle levelEnabled
    const isLevelAvailable = updatedFocusAreas.find((fa) => fa.level === deletedFocusAreaLevel);
    let updatedLevelsEnabled = [...levelsEnabled];
    if (!isLevelAvailable && updatedLevelsEnabled[deletedFocusAreaLevel - 1]) {
      updatedLevelsEnabled[deletedFocusAreaLevel - 1] = false;
    }

    this.setState({
      focusAreas: updatedFocusAreas,
      originalFocusAreas: updatedFocusAreas,
      levelsEnabled: updatedLevelsEnabled,
    });
  };

  handleDelete = async () => {
    const { onClose } = this.props;
    this.setState({ loading: true });
    await deleteSkill(this.state.skill.id);
    this.updateSkillSetLocally(this.state.skill, false, true);
    this.setState({ loading: false });
    onClose();
  };

  toggleDeleteModal = () => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  handleSubmit = async () => {
    const { focusAreas, skill, skillAlreadyExists, levelsEnabled } = this.state;
    const { i18n, onClose, toasts } = this.props;

    const isName = skill.name;
    const isDescription = skill.definition;
    const isLevelEnabled = levelsEnabled.some((level) => level); // min 1 levels should be enabled
    const isSkillCategories = !isEmpty(skill.categories);
    if (!isName || !isDescription || !isLevelEnabled || !isSkillCategories) {
      const toastsToAdd = [];
      if (!isLevelEnabled) {
        toastsToAdd.push({
          title: i18n._(t`No enabled levels`),
          subtitle: i18n._(t`Please enable min 1 level in skill`),
        });
      }

      if (!isSkillCategories) {
        toastsToAdd.push({
          title: i18n._(t`No skill categories`),
          subtitle: i18n._(t`Please add min 1 skill category`),
        });
      }

      if (!isName) {
        toastsToAdd.push({
          title: i18n._(t`No name`),
          subtitle: i18n._(t`Please enter a name for the template`),
        });
      }

      if (!isDescription) {
        toastsToAdd.push({
          title: i18n._(t`No description`),
          subtitle: i18n._(t`Please enter a description for the template`),
        });
      }

      toastsToAdd.forEach((toast) => toasts.add({ ...toast, type: TOAST_TYPES.ERROR }));

      return;
    }

    this.setState({ loading: true });
    const skillData = {
      skill: {
        ...skill,
        aliases: skill.aliases.map((alias) => (alias.isSuggestedSkill ? alias.name : alias.id)),
        levelsEnabled,
      },
      focusAreas: focusAreas.filter((fa) => !isEmpty(fa.name)),
    };

    if (!skillAlreadyExists) {
      const skillWithId = await createSkill(skillData);
      this.updateSkillSetLocally(skillWithId, true);
    } else {
      await updateSkill(skillData, ['focusAreas']);
      this.updateSkillSetLocally(skillData.skill);
    }
    this.setState({ loading: false });
    onClose();
  };

  updateSkillSetLocally = async (skill, skillIsNew, skillIsDeleted) => {
    const { updateDeleteSkill, addNewSkill } = this.props;

    if (skillIsNew) {
      addNewSkill(skill);
    } else {
      updateDeleteSkill(skill, skillIsDeleted);
    }
  };

  updateAliases = async (aliases) => {
    const { categories, originalSkillCategories, defaultFocusAreas, originalFocusAreas } =
      this.state;
    const { lang } = this.props;

    const skill = cloneDeep(this.state.skill);
    const newState = {};

    // Reset categories and focus areas to their last state before auto adding data
    skill.categories = cloneDeep(originalSkillCategories);
    let focusAreas = originalFocusAreas;

    // Only allow one value
    const newAlias = aliases.slice(aliases.length - 1, aliases.length);

    if (!isEmpty(newAlias)) {
      const aliasSkill = newAlias[0];

      // Auto add category for the selected alias
      if ([SKILL_SOURCE.learned2, SKILL_SOURCE.learned].includes(aliasSkill.source)) {
        // If the selected alias is a Learned skill, add the corresponding soft skills category
        const category = categories.find(
          (cat) => cat.categoryId === SUGGESTIONS_SKILL_CATEGORY.INTERPERSONAL_SKILLS.id,
        );

        if (category && !skill.categories.includes(category.id)) {
          skill.categories.push(category.id);
        }
      } else if (aliasSkill.isSuggestedSkill) {
        // If it is a recommended skill, get its category
        const category = categories.find((cat) => cat.categoryId === aliasSkill.categories[0]);

        if (category && !skill.categories.includes(category.id)) {
          skill.categories.push(category.id);
        }
      }

      // If there is no name, or the name belongs to a previously selected alias, set the new alias as name
      if (
        !skill.name ||
        (aliases.length > 1 && [aliases[0].name, aliases[0].nameNL].includes(skill.name))
      ) {
        skill.name = getSkillName(aliasSkill, lang);
      }

      // If there is no definition, or the definition belongs to a previously selected alias, set the new alias as definition
      if (
        !skill.definition ||
        (aliases.length > 1 &&
          [aliases[0].definition, aliases[0].definitionNL].includes(
            skill.definition.replace(/<(.*?)>/g, ''),
          ))
      ) {
        let definition;
        if (lang === LANGUAGES.NL && aliasSkill.definitionNL) {
          definition = aliasSkill.definitionNL;
        } else {
          definition = aliasSkill.definition;
        }

        skill.definition = '<p>' + definition + '</p>';
      }

      // If there are no focusAreas, add the focus areas of the alias skill
      if (focusAreas.every((focusArea) => !focusArea.name)) {
        if (aliasSkill.isSuggestedSkill) {
          // Recommended skills should be filled with the company default focus areas
          focusAreas = mapCompanyDefaultFocusAreas(defaultFocusAreas, aliasSkill.name);
        } else {
          // Learned skills use their own focus areas
          const aliasSkillWithFocusAreas = await getSkill(aliasSkill.id, ['focusAreas']);

          aliasSkillWithFocusAreas.focusAreas.forEach((fA) => {
            // Delete focus areas ids to allow them to be edited
            delete fA.id;

            // Set the dutch name if there is one, for dutch users
            if (lang === LANGUAGES.NL && fA.nameNL) {
              fA.name = fA.nameNL;
            }
          });

          focusAreas = aliasSkillWithFocusAreas.focusAreas;
        }

        // Enable levels for the focusAreas set
        newState.levelsEnabled = mapLevelsEnabled(focusAreas);
      }
    }

    skill.aliases = newAlias;

    newState.skill = skill;
    newState.focusAreas = focusAreas;

    this.setState(newState);
  };

  updateCategories = (selectedCategories) => {
    this.setState((prevState) => {
      let skill = Object.assign({}, prevState.skill);
      skill.categories = selectedCategories;
      return { skill, originalSkillCategories: selectedCategories };
    });
  };

  toggleLevel = (level) => {
    const { levelsEnabled } = this.state;
    const updatedLevelsEnabled = levelsEnabled.map((l, i) => (i === level ? !l : l));
    this.setState({ levelsEnabled: updatedLevelsEnabled });
  };

  render() {
    const { onClose, i18n, skillLabels } = this.props;
    const {
      skill,
      focusAreas,
      levelsEnabled,
      loading,
      skillAlreadyExists,
      showDeleteModal,
      categories,
    } = this.state;
    return (
      <Modal
        onClose={onClose}
        width={750}
        minWidth={750}
        title={i18n._(skillAlreadyExists ? t`Edit skill` : t`Create skill`)}
        headerStyles={{ paddingLeft: '24px', paddingRight: '22px' }}
        contentStyles={{ padding: '24px' }}
        footerStyles={{ paddingRight: '24px' }}
        footerRight={
          <ButtonActions>
            <Button
              loading={loading}
              type="primary"
              onClick={this.handleSubmit}
              label={i18n._(t`Save`)}
              width={83}
              height={48}
              styles={{ marginRight: '16px' }}
            />
            <Button
              loading={loading}
              type="primary-border"
              onClick={this.handleDelete}
              disabled={!skillAlreadyExists || loading}
              label={i18n._(t`Delete`)}
              width={83}
              height={48}
              styles={{ marginRight: '16px' }}
            />
            {showDeleteModal && (
              <DeleteModal
                title="skill"
                content={i18n._(t`Are you sure you want to remove this skill?`)}
                onClose={this.toggleDeleteModal}
                loading={loading}
                onDelete={this.handleDelete}
              />
            )}
            <Button
              loading={loading}
              type="primary-border"
              onClick={onClose}
              width={83}
              height={48}
              label={i18n._(t`Cancel`)}
            />
          </ButtonActions>
        }
      >
        {!loading && (
          <div>
            <TopSection>
              <FieldGroup>
                <TextLabel>
                  <Tooltip
                    tooltip={i18n._(
                      t`Create a new skill based on an existing Learned skill's name, description and focus areas.`,
                    )}
                  >
                    <TooltipContainer>
                      <Trans>Template</Trans>
                    </TooltipContainer>
                  </Tooltip>
                </TextLabel>
                <FilterSuggestedSkillAliasesContainer>
                  <FilterSuggestedSkillAliases
                    checkedList={skill.aliases}
                    onChange={this.updateAliases}
                    selectWidth="100%"
                  />
                </FilterSuggestedSkillAliasesContainer>
              </FieldGroup>
              <FieldGroup>
                <TextLabel>
                  <Trans>Skill name</Trans>*
                </TextLabel>
                <TextField
                  value={skill.name}
                  placeholder={i18n._(t`Example: Negotiating`)}
                  onChange={this.onChangeName}
                />
              </FieldGroup>
              <FieldGroup>
                <TextLabel>
                  <Trans>Skill definition</Trans>*
                </TextLabel>
                <Editor
                  onChange={this.onChangeDescription}
                  value={skill.definition || ''}
                  placeholder={i18n._(
                    t`This definition is used in review rounds and job profiles to help the employee to understand what the skill means.`,
                  )}
                  className="activity_description"
                />
              </FieldGroup>
              <StyledFieldGroup>
                <TextLabel>
                  <Trans>Skill category</Trans>*
                </TextLabel>
                <SearchSelectButton
                  checkedList={skill.categories}
                  title={i18n._(t`Select`)}
                  options={categories.map((c) => ({ id: c.id, label: c.name }))}
                  handleChange={this.updateCategories}
                  height="48px"
                />
              </StyledFieldGroup>
            </TopSection>
            <LvlSection>
              <FieldGroup>
                <SkillLevelsHeader>
                  <Trans>Skill levels and focus areas</Trans>
                </SkillLevelsHeader>
                <Notice>
                  <Trans>
                    With focus areas you can describe examples or behaviour that suits the certain
                    label.
                  </Trans>
                </Notice>
              </FieldGroup>
              {skillLabels.map((level, i) => (
                <FieldGroup key={i}>
                  <LevelHeader>
                    <LevelTitle>
                      {i + 1}. {level}
                    </LevelTitle>
                    <Switch onChange={() => this.toggleLevel(i)} checked={levelsEnabled[i]} />
                  </LevelHeader>
                  {focusAreas.map((fA, indexFA) =>
                    fA.level === i + 1 ? (
                      <FocusArea
                        name={fA.name}
                        level={fA.level}
                        key={
                          fA.key || `${i}${indexFA}`
                        } /* Let's use key where we can and a somewhat safe option in other FA's */
                        onDelete={(faLevel) => this.handleDeleteFA(faLevel, indexFA)}
                        onUpdateFocusArea={(name) =>
                          this.changeFocusAreaName(name, indexFA, fA.level)
                        }
                      />
                    ) : null,
                  )}
                  <NewFocusArea onCreate={this.handleCreateFocusAreas} level={i + 1} />
                </FieldGroup>
              ))}
            </LvlSection>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  skillLabels: state.companySettings.skillLabels,
  currentCompany: getCurrentCompany(state),
  defaultFocusAreas: getCompanyFocusAreas(state),
  lang: getLang(state),
});

export default withI18n()(connect(mapStateToProps)(withToasts(SkillSetupModal)));
