import React from 'react';

import styled from 'styled-components';

import { Button, ButtonVariant, ButtonSize } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Switch from '~/components/Switch';
import BoxWithShadow from '~/components/UI/BoxWithShadow';
import { AdminRights, ActiveRole, Team, SectionListProps } from '~/pages/PassportPage/types';

import { COLORS } from '~/styles';

const Wrapper = styled.div``;

const Item = styled(BoxWithShadow)<itemProps>`
  box-shadow: none;
  border-radius: 6px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: ${(props) => (props.displaySwitch ? 'transparent' : COLORS.BG_LIST)};
  padding: 17px 24px;
  margin-bottom: 8px;
`;

const ItemName = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_HOVER};
  flex: auto;
`;

const AdminSwitch = styled(Switch)`
  margin-right: 0px;
`;

const RemoveButton = styled(Button)`
  background-color: transparent;
`;

interface itemProps {
  displaySwitch?: boolean;
}

const SectionList = ({
  items,
  placeholder,
  itemActionLabel,
  itemActionOnClick,
  itemActionLoading,
  itemActionDisabled,
  isAdmin,
  displaySwitch,
}: SectionListProps) => {
  const renderItem = (item: AdminRights | Team | ActiveRole) => {
    return (
      <Item key={item.id} displaySwitch={displaySwitch}>
        {displaySwitch && (
          <AdminSwitch checked={isAdmin} onChange={() => itemActionOnClick(item.id)} />
        )}
        <ItemName>{item.name}</ItemName>
        {itemActionOnClick && !displaySwitch && (
          <RemoveButton
            size={ButtonSize.MEDIUM}
            label={itemActionLabel}
            variant={ButtonVariant.TEXT_DELETE}
            icon={ICONS.DELETE_BIN}
            onClick={() => itemActionOnClick(item.id)}
            isLoading={itemActionLoading}
            disabled={itemActionDisabled}
          />
        )}
      </Item>
    );
  };

  return <Wrapper>{items.length > 0 ? items.map(renderItem) : placeholder || null}</Wrapper>;
};

export { SectionList };
