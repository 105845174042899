import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import CompanyJobProfile from '~/pages/CompanyJobProfile';
import CompanyJobProfiles from '~/pages/CompanyJobProfiles';
import RoleCreatePage from '~/pages/RoleCreatePage';
import RolePage from '~/pages/RolePage';
import UserCareerWrapp from '~/pages/UserCareer/components/UserCareerWrapp';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('jobprofiles', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.USER_CAREER.routePath(ROLES.USER)}
        exact
        path={routes.USER_CAREER.routePath(ROLES.USER)}
        component={UserCareerWrapp}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.JOB_PROFILE.routePath(ROLES.USER)}
        exact
        path={routes.JOB_PROFILE.routePath(ROLES.USER)}
        component={RolePage}
        allowedRoles={[ROLES.USER]}
      />,
    ],
    coach: [
      <PrivateRoute
        key={routes.JOB_PROFILE_SUPERVISION_USER.routePath(ROLES.COACH)}
        exact
        path={routes.JOB_PROFILE_SUPERVISION_USER.routePath(ROLES.COACH)}
        component={RolePage}
        allowedRoles={[ROLES.COACH]}
      />,
    ],
    admin: [
      <PrivateRoute
        key={routes.JOB_PROFILES_ADMIN.routePath(ROLES.ADMIN)}
        exact
        path={routes.JOB_PROFILES_ADMIN.routePath(ROLES.ADMIN)}
        component={CompanyJobProfiles}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.JOB_PROFILE.routePath(ROLES.ADMIN)}
        exact
        path={routes.JOB_PROFILE.routePath(ROLES.ADMIN)}
        component={CompanyJobProfile}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.JOB_PROFILE_SUPERVISION_USER.routePath(ROLES.ADMIN)}
        exact
        path={routes.JOB_PROFILE_SUPERVISION_USER.routePath(ROLES.ADMIN)}
        component={RolePage}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.JOB_CREATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.JOB_CREATE.routePath(ROLES.ADMIN)}
        component={RoleCreatePage}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.JOB_UPDATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.JOB_UPDATE.routePath(ROLES.ADMIN)}
        component={RoleCreatePage}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
