import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

const d =
  'M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const StarIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} viewBox="0 0 20 20" {...props}>
    {(color) => (
      <g transform="translate(-2, -1.5)" fill={color}>
        <path d={d} {...props} />
      </g>
    )}
  </InlineSvg>
);

StarIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

StarIcon.defaultProps = {
  size: 24,
  className: '',
};

export default StarIcon;
