import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { COLORS, COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  background: ${COLORS.BG_PAGE};
  position: relative;
  min-height: 100vh;
`;

const Content = styled.div`
  width: 340px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.div`
  color: ${COLORS.TEXT_BLACK};
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 28px;
`;

const Description = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 20px;
`;

const MobileOutsideFeedbackNotAvailiable = () => {
  return (
    <Wrapper>
      <Content>
        <Main>
          <Title>
            <Trans>Ooops!</Trans>
          </Title>
          <Description>
            <Trans>
              Outside feedback requests cannot be answered with a mobile device. Please open this
              link on a desktop computer.
            </Trans>
          </Description>
        </Main>
      </Content>
    </Wrapper>
  );
};

export default MobileOutsideFeedbackNotAvailiable;
