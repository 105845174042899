import React, { Component } from 'react';

import { LINK_STATUS } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reverse from 'lodash/reverse';
import uniqby from 'lodash/uniqBy';
import qs from 'qs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import EvalPlanModal from '~/components/EvalPlanModal';
import HeadingNavigation from '~/components/HeadingNavigation';
import NewReviewReport from '~/components/NewReviewReport';
import NomineesModal from '~/components/NomineesModal';
import ReviewReport from '~/components/ReviewReport';
import { NominatePeopleAsParticipantModal } from '~/components/SelectedCoachesRow/components/NominatePeopleAsParticipantModal';
import Nominate from '~/components/SelectedCoachesRow/components/NomineesCoachesListModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import BaseLayout from '~/layouts/BaseLayout';
import RightSidebarLayout from '~/layouts/RightSidebarLayout';
import ReviewNotes from '~/pages/ReviewUserPage/components/ReviewNotes';

import HeadingDropdown from './components/HeadingDropdown';
import SpiderTab from './components/ReportTab/SpiderTab';
import ReviewHeader from './components/ReviewHeader';
import TodoSection from './components/TodoSection';

import { ROLES, REQUEST_TYPES, REQUEST_STATUSES, REVIEW_STAGES } from '~/constants';
import routes from '~/constants/routes';
import { getTeams } from '~/selectors/baseGetters';
import getAllPossibleUsers from '~/selectors/getAllPossibleUsers';
import { getIncomingRequestsForOriginalReview } from '~/services/requests';
import {
  getReview,
  setDateOfConversation as setDateOfConversationService,
  updateReviewConversationCoaches,
} from '~/services/reviews';
import { COLORS } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';
import { isTaskStartDateInThePast } from '~/utils/isTaskStartDateInThePast';

const ReportHeader = styled.div`
  margin: 32px 0 20px;
`;

const ReportTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
`;

const ReportSubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
  margin-top: 4px;
`;

const ReviewsPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
`;

const ReviewInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledHeadingNavigation = styled(HeadingNavigation)`
  width: 100%;
`;

const TabContent = styled.div``;

export class ReviewUserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      review: {},
      comments: [],
      attachments: [],
      loading: true,
      requestsIncoming: [], // peers requests from another user reviews
      sectionToOpen: 0,
      isPlanConversationModal: false,
      isConversationCoachModal: false,
      conversationCoaches: [],
      isDigitalSignModal: false,
      isNominatePeersModal: false,
    };
    this.myRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    await this.fetchData();
    this.setState({ loading: false });
  }

  fetchData = async () => {
    const { match, location, user } = this.props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { isNominateModal, sectionToOpen, isDigitalSignModal } = query;
    if (isNominateModal) {
      this.setState({ isNominatePeersModal: isNominateModal });
    }
    if (sectionToOpen) {
      this.setState({ sectionToOpen: parseInt(sectionToOpen, 10) });
    }
    let reviewId = match.params.reviewId;
    const review = await getReview(reviewId, {
      isQuestions: true,
      join: ['requests', 'ratings', 'comments', 'calendarEvents'],
      populate: ['files'],
    });

    const conversationCoaches = get(review, 'conversationCoaches', []);
    const signatures = get(review, 'signatures', []);
    const isConversationCoach = conversationCoaches.includes(user.id);
    const isReviewOwner = get(review, 'createdFor') === user.id;
    const isCoachSignaturesDone = signatures.length === conversationCoaches.length;

    // display sign modal
    // only for conversationCoaches or review owner
    if (isDigitalSignModal && (isCoachSignaturesDone ? isReviewOwner : isConversationCoach)) {
      this.setState({ isDigitalSignModal: true });
    }

    // Download the incoming peers requests with populated target field.
    // without coaches requests
    let requestsIncoming = [];
    if (review && review.originalReview && review.createdFor) {
      requestsIncoming = await getIncomingRequestsForOriginalReview(
        review.originalReview,
        review.createdFor,
      );
    }

    const newState = {
      review,
      requestsIncoming,
      comments: review.comments || [],
      attachments: review.attachments || [],
    };

    // open set date and time modal
    // only for review.conversationCoaches
    const url = new URL(window.location.href);
    const isPlanConversationModal = url.searchParams.get('isPlanConversationModal');
    if (isPlanConversationModal && isConversationCoach) {
      newState.isPlanConversationModal = isPlanConversationModal;
    }

    this.setState(newState);
  };

  setDefaultConversationCoaches = () => {
    const { review } = this.state;
    this.setState({
      conversationCoaches: [...get(review, 'conversationCoaches', [])],
    });
  };

  goBack = () => {
    const { history, currentRole, selectedTeam } = this.props;
    const { review } = this.state;
    const url = new URL(window.location.href);
    const from = url.searchParams.get('from');
    const backPath = from
      ? `${from}${url.hash}`
      : currentRole !== ROLES.COACH
      ? routes.CONVERSATIONS.build()
      : routes.USER_PUBLIC_SKILL_PASSPORT.build(
          { role: ROLES.COACH, teamId: selectedTeam.id },
          {
            userId: review.createdFor,
            hash: 'conversations',
          },
        );
    history.push(backPath);
  };

  handleOnRequestCreated = async (newRequests) => {
    const { review } = this.state;

    // remove request from existed
    const newRequestsIds = newRequests.map((r) => r.id);
    const existedRequests = (review.requests || []).filter((r) => !newRequestsIds.includes(r.id));

    this.setState({
      review: {
        ...review,
        requests: existedRequests.concat(newRequests),
      },
    });
  };

  handleRequestCancel = async (request) => {
    const { review } = this.state;

    this.setState({
      review: {
        ...review,
        requests: review.requests.map((req) =>
          req.id === request.id ? { ...req, status: REQUEST_STATUSES.CANCELLED.key } : req,
        ),
      },
    });
  };

  handleOnRatingUpdated = async (updatedRating) => {
    const { review } = this.state;
    this.setState({
      review: {
        ...review,
        ratings: (review.ratings || []).map((r) => (r.id === updatedRating.id ? updatedRating : r)),
      },
    });
  };

  handleOnSignaturesUpdated = async (updatedSignatures) => {
    const { review } = this.state;
    this.setState({
      review: {
        ...review,
        signatures: updatedSignatures,
      },
    });
  };

  // give peer/coaches feedback
  giveFeedback = async (request) => {
    const { history } = this.props;
    const { review } = this.state;
    const isLockedFeedback = get(review, `lockedStages[${REVIEW_STAGES.FEEDBACK}]`, false);

    if (!isEmpty(request)) {
      switch (request.type) {
        case REQUEST_TYPES.FEEDBACK_FROM_PEER:
          request.status === REQUEST_STATUSES.SHARED.key || isLockedFeedback
            ? history.push(
                routes.REVIEWS_INCOMING_REPORT.build(
                  { role: ROLES.USER },
                  {
                    requestId: request.id,
                    isBackPath: true,
                  },
                ),
              )
            : history.push(
                routes.REVIEW_GIVE_FEEDBACK.build(
                  { role: ROLES.USER },
                  {
                    reviewId: get(request, 'target.id', request.target),
                    requestId: request.id,
                    isBackPath: true,
                  },
                ),
              );
          break;
        case REQUEST_TYPES.FEEDBACK_FROM_COACH:
          isLockedFeedback
            ? history.push(
                routes.REVIEWS_INCOMING_REPORT.build(
                  { role: ROLES.USER },
                  {
                    requestId: request.id,
                    isBackPath: true,
                  },
                ),
              )
            : history.push(
                routes.REVIEW_GIVE_FEEDBACK.build(
                  { role: ROLES.USER },
                  {
                    reviewId: get(request, 'target.id', request.target),
                    requestId: request.id,
                    isBackPath: true,
                  },
                ),
              );

          return;
        default:
          break;
      }
    }
  };

  setDateOfConversation = async (data) => {
    const { review } = this.state;

    const startDate = convertToTimeString(data.startDate, TIME_FORMATS.ISO);
    const endDate = convertToTimeString(data.endDate, TIME_FORMATS.ISO);
    const result = await setDateOfConversationService(review.id, {
      startDate,
      endDate,
      includeLinkMeeting: data.includeLinkMeeting,
      returnCalendarEvent: true,
    });

    // Set the obtained calendarEvents into review
    review.calendarEvent = result.calendarEvent;

    if (!review.includeLinkMeeting && data.includeLinkMeeting) {
      const calendarEventIntegration =
        review.calendarEvent[`${review.calendarEvent.integration}Connection`];
      if (calendarEventIntegration) {
        calendarEventIntegration.linkStatus = LINK_STATUS.PENDING;
        review.calendarEvent[`${review.calendarEvent.integration}Connection`] = {
          ...calendarEventIntegration,
        };
      }
    }

    // local update
    this.setState({
      review: {
        ...review,
        dateOfConversation: result.dateOfConversation,
        dateOfConversationEnd: result.dateOfConversationEnd,
        includeLinkMeeting: result.includeLinkMeeting,
      },
    });

    return result;
  };

  toggleCoaches = (a) => {
    const { conversationCoaches } = this.state;
    let newConversationCoaches;
    if (conversationCoaches.includes(a)) {
      newConversationCoaches = conversationCoaches.filter((c) => c !== a);
    } else {
      newConversationCoaches = [...conversationCoaches, a];
    }
    this.setState({ conversationCoaches: newConversationCoaches });
  };

  updateConversationCoaches = () => {
    const { review, conversationCoaches } = this.state;
    updateReviewConversationCoaches(review.id, conversationCoaches);
    this.setState({
      isConversationCoachModal: false,
      review: {
        ...review,
        conversationCoaches,
      },
    });
  };

  onRequestsCreated = async (newRequests) => {
    const { review } = this.state;

    const coaches = newRequests
      .filter((r) => r.type === REQUEST_TYPES.FEEDBACK_FROM_COACH)
      .map((r) => r.toUser);

    const requests = review.requests || [];
    const allRequests = reverse(uniqby(reverse(requests.concat(newRequests)), 'id'));
    this.setState({
      review: {
        ...review,
        coaches: (review.coaches || []).concat(coaches),
        requests: allRequests,
      },
    });
  };

  onRequestCanceled = async (request) => {
    const { review } = this.state;

    this.setState({
      review: {
        ...review,
        coaches:
          request.type === REQUEST_TYPES.FEEDBACK_FROM_COACH
            ? (review.coaches || []).filter((id) => id !== request.toUser)
            : review.coaches,
        requests: review.requests.map((req) =>
          req.id === request.id ? { ...req, status: REQUEST_STATUSES.CANCELLED.key } : req,
        ),
      },
    });
  };

  onSignStart = () => {
    this.setState({ isDigitalSignModal: true });
  };

  updateReviewComments = (comments) => this.setState({ comments });

  updateReviewAttachments = (attachments) => this.setState({ attachments });

  updateCalendarEvent = (event) => {
    const { review } = this.state;
    this.setState({
      review: {
        ...review,
        calendarEvent: event,
      },
    });
  };

  render() {
    const {
      loading,
      requestsIncoming,
      review,
      sectionToOpen,
      isPlanConversationModal,
      isCoachesModal,
      isConversationCoachModal,
      conversationCoaches,
      isDigitalSignModal,
      isNominatePeersModal,
      comments,
      attachments,
      isParticipantsModal,
    } = this.state;
    const { currentRole, i18n, user, users, activeUsers } = this.props;
    const isLockedNominate = get(review, `lockedStages[${REVIEW_STAGES.NOMINATE}]`, false);
    const isLockedFeedback = get(review, `lockedStages[${REVIEW_STAGES.FEEDBACK}]`, false);
    const isLockedSignatures = !isEmpty(get(review, 'signatures'));
    const isLocked = isLockedFeedback || isLockedSignatures;
    const isUserOwner = currentRole === ROLES.USER && review.createdFor === user.id;
    const isCoach =
      [ROLES.USER, ROLES.COACH].includes(currentRole) &&
      review?.conversationCoaches?.includes(user.id);

    const reviewRequestWithoutRejected = get(review, 'requests', []).filter((r) => {
      return r.status !== REQUEST_STATUSES.REJECTED.key;
    });

    const reviewsWithoutRejected = {
      ...review,
      requests: reviewRequestWithoutRejected,
    };

    let userName;
    let userFirstName;
    const isYourReview = review?.createdFor && review?.createdFor === user.id;
    if (!isEmpty(review) && review?.createdFor && users[review.createdFor]) {
      userName = getUserFullName(users[review.createdFor]);
      userFirstName = users[review.createdFor]?.firstName || users[review.createdFor]?.email || '';
    }

    const sidebarTabs = [
      Object.keys(review).length > 0 && {
        id: 'notes',
        title: i18n._(t`Notes & Next steps`),
        tab: (
          <ReviewNotes
            i18n={i18n}
            review={review}
            comments={comments}
            files={attachments}
            updateReviewComments={this.updateReviewComments}
            updateReviewAttachments={this.updateReviewAttachments}
          />
        ),
      },
    ]
      .filter((i) => i)
      .reverse(); // tabs order start from the bottom

    const requestsPeers = (review.requests || []).filter((r) =>
      [REQUEST_TYPES.FEEDBACK_FROM_PEER, REQUEST_TYPES.FEEDBACK_FROM_OUTSIDE_PEER].includes(r.type),
    );

    return (
      <>
        <RightSidebarLayout tabs={sidebarTabs}>
          {review && review.name && (
            <StyledHeadingNavigation
              noPadding
              label={i18n._(t`Reviews`)}
              onBack={this.goBack}
              maxWidth={1000}
              smallWidth={true}
              actions={
                <>
                  <HeadingDropdown
                    review={review}
                    updateReviewState={(review) => this.setState({ review })}
                    currentRole={currentRole}
                    goBack={this.goBack}
                    openCoachesModal={() => this.setState({ isCoachesModal: true })}
                    setDefaultConversationCoaches={this.setDefaultConversationCoaches}
                    openConversationCoachModal={() =>
                      this.setState({ isConversationCoachModal: true })
                    }
                  />
                </>
              }
            />
          )}
          <BaseLayout maxWidth={1000} smallWidth={true}>
            <ShowSpinnerIfLoading loading={loading}>
              <ReviewsPageContainer>
                <ReviewInfoContainer>
                  <ReviewHeader
                    setConversationCoaches={() => {
                      this.setDefaultConversationCoaches();
                      this.setState({ isConversationCoachModal: true });
                    }}
                    review={review}
                    isYourReview={isYourReview}
                    userName={userName}
                    updateCalendarEvent={this.updateCalendarEvent}
                    openPlanEvalModal={() => this.setState({ isPlanConversationModal: true })}
                  />
                  <TodoSection
                    review={review}
                    currentRole={currentRole}
                    users={users}
                    currentUserId={user.id}
                    userName={userFirstName}
                    openGiveReviewModal={this.openGiveReviewModal}
                    openPlanEvalModal={() => this.setState({ isPlanConversationModal: true })}
                    requestsCoaches={(review.requests || []).filter(
                      (r) => r.type === REQUEST_TYPES.FEEDBACK_FROM_COACH,
                    )}
                    requestsPeers={requestsPeers}
                    requestsIncoming={requestsIncoming}
                    giveFeedback={this.giveFeedback}
                    onRequestsCreated={this.handleOnRequestCreated}
                    onSignStart={this.onSignStart}
                    goToReport={() => this.myRef.current.scrollIntoView()}
                    openNominatePeersModal={() => this.setState({ isNominatePeersModal: true })}
                    isCoach={isCoach}
                  />
                  <ReportHeader ref={this.myRef}>
                    <ReportTitle>
                      <Trans>The report</Trans>
                    </ReportTitle>
                    <ReportSubTitle>
                      <Trans>Use the report to structure your conversation</Trans>
                    </ReportSubTitle>
                  </ReportHeader>
                  <TabContent>
                    {!review.isRateWithLevel && <SpiderTab review={review} />}
                  </TabContent>
                  {review.isRateWithLevel ? (
                    <NewReviewReport
                      viewerId={user.id}
                      review={reviewsWithoutRejected}
                      isYourReview={isYourReview}
                      userFirstName={userFirstName}
                      isReadOnly={ROLES.USER !== currentRole && review.createdFor !== user.id}
                      handleOnRatingUpdated={this.handleOnRatingUpdated}
                      handleOnSignaturesUpdated={this.handleOnSignaturesUpdated}
                      users={users}
                      openSection={sectionToOpen}
                      isDigitalSignModal={isDigitalSignModal}
                      onCloseDigitalSignModal={() => this.setState({ isDigitalSignModal: false })}
                    />
                  ) : (
                    <ReviewReport review={review} />
                  )}
                </ReviewInfoContainer>
              </ReviewsPageContainer>
            </ShowSpinnerIfLoading>
            {isPlanConversationModal && (
              <EvalPlanModal
                conversation={{
                  startDate: review.dateOfConversation,
                  endDate: review.dateOfConversationEnd,
                  includeLinkMeeting: review.includeLinkMeeting,
                  calendarEvent: review.calendarEvent,
                }}
                reviewName={review.name}
                userFirstName={userFirstName}
                closeModal={() => this.setState({ isPlanConversationModal: false })}
                onSubmit={this.setDateOfConversation}
                readOnly={
                  isLocked || !isTaskStartDateInThePast(review?.startDateOfPlanConversation)
                }
                isDigitalMeeting
              />
            )}
            {isCoachesModal && (
              <NomineesModal
                review={review}
                type={NomineesModal.NOMINATE_TYPE.coach}
                onClose={() => this.setState({ isCoachesModal: false })}
                onRequestsCreated={this.onRequestsCreated}
                onRequestCancel={this.onRequestCanceled}
              />
            )}
            {isConversationCoachModal && (
              <Nominate
                onClose={() => this.setState({ isConversationCoachModal: false })}
                coaches={activeUsers}
                coachesSelected={conversationCoaches}
                onToggleCoach={this.toggleCoaches}
                onSave={this.updateConversationCoaches}
                setCoachArray={(conversationCoaches) => {
                  this.setState({
                    conversationCoaches,
                  });
                }}
                disabledCoach={user.id}
                setUsers={(conversationCoaches) => {
                  this.setState({ conversationCoaches, isConversationCoachModal: false });
                  this.updateConversationCoaches();
                }}
                removeCoach={(coach) => {
                  this.setState({
                    conversationCoaches: conversationCoaches.filter((c) => c !== coach),
                  });
                }}
                isParticipants
                changeModal={() => {
                  this.setState({ isConversationCoachModal: false });
                  this.setState({ isParticipantsModal: true });
                }}
                isEditMode={isEmpty(review.signatures)}
              />
            )}
            {isNominatePeersModal && (
              <NomineesModal
                type={NomineesModal.NOMINATE_TYPE.peer}
                review={review}
                onClose={() => this.setState({ isNominatePeersModal: false })}
                onRequestsCreated={this.handleOnRequestCreated}
                onRequestCancel={this.handleRequestCancel}
                isDisabled={
                  ((!isUserOwner || !isTaskStartDateInThePast(review?.startDateNominate)) &&
                    !isCoach) ||
                  isLocked ||
                  isLockedNominate
                }
                skipRequestList={isEmpty(requestsPeers)}
              />
            )}
            {isParticipantsModal && (
              <NominatePeopleAsParticipantModal
                onClose={() => {
                  this.setState({ isConversationCoachModal: true });
                  this.setState({ isParticipantsModal: false });
                }}
                selectedUser={users[review.createdFor]}
                setNewParticipants={(users) => {
                  this.setState({ conversationCoaches: [...conversationCoaches, ...users] });
                }}
                selectedParticipants={conversationCoaches}
              />
            )}
          </BaseLayout>
        </RightSidebarLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentRole: state.selected.role,
  selectedTeam: state.selected.team,
  user: state.auth.user,
  users: getAllPossibleUsers(state),
  activeUsers: Object.keys(state.users.data),
  teams: getTeams(state),
});

export default withI18n()(connect(mapStateToProps)(withRouter(ReviewUserPage)));
