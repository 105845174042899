import React from 'react';

import { Trans } from '@lingui/macro';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import ClockIcon from '~/assets/restore-black-18dp.svg';

import { COLOR_PALETTE, COLORS } from '~/styles';

const BlockLine = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.GRAY_LIGHT};
  display: flex;
  align-items: center;
  line-height: 1;
  div {
    margin-right: 4px;
  }
`;

const BlockLineWarn = styled(BlockLine)`
  color: ${COLORS.ERROR};
`;

const FeedbackDeadline = ({ deadline, width, height, shared }) => {
  if (!deadline) {
    return null;
  }
  const days = moment(deadline).diff(moment(), 'days');

  if (days >= 0) {
    return (
      <BlockLine>
        <SvgIcon
          width={width}
          height={height}
          url={ClockIcon}
          isDefaultColor
          defaultColor={COLOR_PALETTE.GRAY_LIGHT}
        />
        <Trans>{days}d left</Trans>
      </BlockLine>
    );
  } else {
    return shared ? null : (
      <BlockLineWarn>
        <SvgIcon
          width={width}
          height={height}
          url={ClockIcon}
          isDefaultColor
          defaultColor={COLORS.ERROR}
        />
        <Trans>Overdue</Trans>
      </BlockLineWarn>
    );
  }
};

FeedbackDeadline.propTypes = {
  deadline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

FeedbackDeadline.defaultProps = {
  width: '18px',
  height: '18px',
};

export default React.memo(FeedbackDeadline);
