import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { Marker } from '~/components/Marker';

import { CheckBox } from './components/CheckBox';
import { Header } from './components/Header';
import { RadioButton } from './components/RadioButton';
import { SelectedHeader } from './components/SelectedHeader';
import {
  TD,
  TR,
  TableWrapper,
  DraftLabel,
  DraftWrapper,
  IconMenuWrapper,
  IsVisible,
  StyledTop,
  SelectorWrapper,
} from './design';

import { COLORS } from '~/styles';

import PaginationBar from '../PaginationBar';
import { TablePlaceholder } from '../TablePlaceholder';

import type { IMultiSelect, ITableListProps } from './types';

const STATUS = {
  DRAFT: 'draft',
};

const Wrapper = styled.div`
  position: relative;
  background-color: ${COLORS.WHITE};
  border-radius: 0 10px 10px 10px;
  padding: 16px;
`;

const TableList = ({
  columns,
  data,
  onRowClick,
  sortProps: { sortBy, setSortBy } = {},
  multiSelectProps: {
    isMultiSelectVisible = false, // for multi and single select
    multiSelect,
    isSelectedCountVisible = true, // for single select should be false
    isSelectAllVisible = true, // for single select should be false
    isSingleSelect = false,
  } = {},
  menuProps: { isMenuVisible, createMenuItems } = { isMenuVisible: false },
  isDraftStatusVisible,
  onColClick,
  actionButton,
  secondaryButton,
  thirdButton,
  isLoading,
  paginationProps: {
    pagination,
    onPaginationChange,
    changePagination,
    totalCount,
    paginationItemLabel,
  },
  placeholderProps: { noResultText, emptyStateText } = {},
  filtersProps: { filters, filterComponents, isToggleHideFilterVisible, isFiltered, resetFilters },
  className,
  isAllDone = false,
  isHideHeader = false,
  isHideTop = false,
  topClassName,
}: ITableListProps) => {
  const { i18n } = useLingui();
  const [hovered, setHovered] = useState(-1);
  const handleSortBy = (key?: string) => {
    setSortBy && setSortBy(key || '');
  };

  const sortProps = {
    sortBy,
    handleSortBy,
  };

  useEffect(() => {
    if (data && isEmpty(data) && pagination.index > 1) {
      const newPagination = {
        ...pagination,
        index: --pagination.index,
        skip: pagination.skip - pagination.limit,
      };

      changePagination(newPagination);
      onPaginationChange?.(newPagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePagination, data, pagination]);

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    const newPagination = {
      ...pagination,
      limit,
    };

    changePagination(newPagination);
    onPaginationChange?.(newPagination);
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    const newPagination = {
      ...pagination,
      skip,
      index,
    };

    changePagination(newPagination);
    onPaginationChange?.(newPagination);
  };

  const onResetSearch = (): void => {
    resetFilters ? resetFilters() : filters.setSearch('');
  };

  return (
    <Wrapper className={className}>
      {!isHideTop && (
        <StyledTop
          filters={filters}
          actionButton={actionButton}
          secondaryButton={secondaryButton}
          thirdButton={thirdButton}
          filterComponents={filterComponents}
          isToggleHideFilterVisible={isToggleHideFilterVisible}
          resetFilters={resetFilters}
          className={topClassName}
        />
      )}
      {isMultiSelectVisible &&
        (multiSelect as IMultiSelect)?.checkedCount > 0 &&
        isSelectedCountVisible && <SelectedHeader multiSelect={multiSelect as IMultiSelect} />}
      <TableWrapper $isPointer={Boolean(onRowClick)}>
        {!isHideHeader && (
          <Header
            multiSelect={multiSelect}
            isMultiSelectVisible={isMultiSelectVisible}
            columns={columns}
            sortProps={sortProps}
            isMenuVisible={isMenuVisible}
            isSelectedCountVisible={isSelectedCountVisible}
            isSelectAllVisible={isSelectAllVisible}
          />
        )}
        {!isEmpty(data) && (
          <tbody>
            {data.map((item, i) => {
              const isItemChecked = isMultiSelectVisible && multiSelect?.isItemChecked(item);
              return (
                <TR
                  onMouseEnter={() => setHovered(i)}
                  onMouseLeave={() => setHovered(-1)}
                  key={item?.id}
                  onClick={() => onRowClick && onRowClick(item)}
                  role="link"
                  // @ts-ignore
                  isSelected={isMultiSelectVisible && isItemChecked}
                >
                  {isMultiSelectVisible && (
                    <TD className="checkbox" key={`td-${i}`} $isPointer padding="0 0 0 25px">
                      <SelectorWrapper>
                        {isSingleSelect ? (
                          <RadioButton
                            isSelected={Boolean(isItemChecked)}
                            isHovered={hovered === i}
                            onChange={() => multiSelect?.onSelectItem(item)}
                          />
                        ) : (
                          <CheckBox
                            isSelected={Boolean(isItemChecked)}
                            isHovered={hovered === i}
                            onChange={() => multiSelect?.onSelectItem(item)}
                          />
                        )}
                      </SelectorWrapper>
                    </TD>
                  )}
                  {columns.map((column) => {
                    const isCallWithClick = !!onColClick && onColClick.column === column.accessor;
                    return column.renderCell ? (
                      <TD
                        key={`td-${column.accessor}`}
                        maxWidth={column.maxWidth}
                        padding={column.padding}
                        isSelected={Boolean(isItemChecked)}
                        isHovered={hovered === i}
                      >
                        {column.renderCell(
                          item,
                          isCallWithClick ? onColClick?.onClick : undefined,
                          hovered === i,
                          Boolean(isItemChecked),
                        )}
                      </TD>
                    ) : (
                      <TD
                        key={`td-${column.accessor}`}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        isSelected={Boolean(isItemChecked)}
                        isHovered={hovered === i}
                      >
                        {/* @ts-ignore */}
                        {item?.[column.accessor]}
                      </TD>
                    );
                  })}
                  {(isMenuVisible || isDraftStatusVisible) && (
                    <TD minWidth="26px" padding="16px 26px 16px 0">
                      <IconMenuWrapper>
                        {isMenuVisible && createMenuItems && !isEmpty(createMenuItems(item)) && (
                          <IsVisible isVisible={hovered === i}>
                            <ContextMenu>
                              {createMenuItems(item).map((option, i) => (
                                <ContextOption
                                  key={i}
                                  action={option.action}
                                  icon={option.icon}
                                  isWarning={option.isWarning}
                                >
                                  {option.label}
                                </ContextOption>
                              ))}
                            </ContextMenu>
                          </IsVisible>
                        )}
                        {isDraftStatusVisible && item?.status === STATUS.DRAFT && (
                          <DraftWrapper>
                            <DraftLabel>
                              <Marker label={i18n._(t`Draft`)} />
                            </DraftLabel>
                          </DraftWrapper>
                        )}
                      </IconMenuWrapper>
                    </TD>
                  )}
                </TR>
              );
            })}
          </tbody>
        )}
      </TableWrapper>
      {isEmpty(data) && (
        <TablePlaceholder
          isLoading={isLoading}
          isFiltered={isFiltered}
          noResultText={noResultText}
          emptyStateText={emptyStateText}
          isAllDone={isAllDone}
          onResetSearch={onResetSearch}
        />
      )}
      <PaginationBar
        pagination={pagination}
        changePagination={onPageChangeClick}
        changePageSize={handleChangeItemsPerPage}
        count={totalCount}
        noShadow
        noBorder
        noTopBorder
        showCount
        itemLabel={paginationItemLabel}
      />
    </Wrapper>
  );
};

export { TableList };
