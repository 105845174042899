import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import {
  AutocompleteFilterActivityTypes,
  AutocompleteFilterCategories,
  AutocompleteFilterRelevancies,
  AutocompleteFilterSkills,
} from '~/components/AutocompleteFilters';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import IconButton from '~/components/IconButton';
import { ActivityModal } from '~/components/Modals/ActivityModal';
import { EditLibraryActivityModal } from '~/components/Modals/EditLibraryActivityModal';
import { SearchField } from '~/components/Text';
import Tooltip from '~/components/Tooltip';
import ActionsPopover from '~/components/UI/ActionsPopover';
import TableCard, { TableCol, TableRow } from '~/components/UI/TableCard';
import { Header6 } from '~/components/UI/Typographics/headers';

import { deleteOriginalLibraryActivities, getFilteredActivities } from '~/services/activities';
import { getActivityCategories } from '~/services/activityCategories';
import { updateJobProfile } from '~/services/jobProfiles';
import { getRoleRelevancies } from '~/services/roleRelevancies';
import { COLOR_PALETTE, COLOR_SET, COLORS } from '~/styles';

const StyledTableCard = styled(TableCard)`
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
  box-shadow: unset;
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const StyledSearch = styled(SearchField)`
  margin-right: 8px;
  width: 288px;
`;

const ActivityNameWrapper = styled.div`
  font-family: Poppins;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${COLOR_SET.TOTAL_BLACK};
  cursor: pointer;
  align-items: center;
  span {
    ${(props) => props.$isWithLabel && 'max-width: 310px'}
  }
`;

const ActivitySubTitle = styled.div`
  margin-right: 16px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  word-break: break-word;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ActionsPopoverWrapper = styled(ActionsPopover)``;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  cursor: pointer;
`;

const RelevancyName = styled(Header6)`
  width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledAutocompleteFilterCategories = styled(AutocompleteFilterCategories)`
  margin-right: 8px;
`;

const StyledAutocompleteFilterActivityTypes = styled(AutocompleteFilterActivityTypes)`
  margin-right: 8px;
`;

const StyledAutocompleteFilterSkills = styled(AutocompleteFilterSkills)`
  margin-right: 8px;
`;

const Label = styled.div`
  min-width: 114px;
  min-height: 32px;
  border-radius: 6px;
  border: solid 1px ${COLORS.ERROR};
  background-color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.ERROR};
  margin-left: 12px;
  box-sizing: border-box;
  padding: 2px 3px;
`;

const RoleLearningTab = ({ jobProfile, updateJobProfileState }) => {
  const { i18n } = useLingui();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activityCategories, setActivityCategories] = useState({});
  const [activityRelevancies, setActivityRelevancies] = useState(
    jobProfile.activityRelevancies || [],
  );
  const [relevancies, setRelevancies] = useState({});
  const [search, setSearch] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedRelevancies, setSelectedRelevancies] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [activityToViewInModal, setActivityToViewInModal] = useState(undefined);
  const [activityToViewInUpdateModal, setActivityToViewInUpdateModal] = useState(undefined);

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      if (jobProfile.activities.length) {
        const response = await getFilteredActivities(jobProfile.activities || []);
        const fetchedActivityCategoriesData = await getActivityCategories();
        const fetchedActivityCategories =
          fetchedActivityCategoriesData?.data[API_RETURN_FIELDS.ACTIVITY_CATEGORIES];
        const fetchedRoleRelevancies = await getRoleRelevancies();

        if (isMounted) {
          setActivities(Object.values(response.data.filteredActivities));
          setActivityCategories(fetchedActivityCategories);
          setRelevancies(fetchedRoleRelevancies.data.roleRelevancies);
        }
      }

      if (isMounted) {
        setLoading(false);
      }
    };

    fetch();

    return () => {
      isMounted = true;
    };
  }, [jobProfile.activities]);

  useEffect(() => {
    const selectedRelevancyIds = selectedRelevancies.map((i) => i.id);
    const filters = {
      ...(search.length && {
        name: { $regex: search.replace(/([^a-z0-9 ]+)/gi, ''), $options: 'mi' },
      }),
      ...(selectedTypes.length && {
        type: { $in: selectedTypes.map((i) => i.key) },
      }),
      ...(selectedCategories.length && {
        categories: {
          $in: selectedCategories.map((i) => i.id),
        },
      }),
      ...(selectedSkills.length && {
        skills: {
          $in: selectedSkills.map((i) => i.id),
        },
      }),
    };
    const fetch = async () => {
      const response = await getFilteredActivities(jobProfile.activities || [], filters);
      setActivities(
        Object.values(response.data.filteredActivities).filter((activities) =>
          selectedRelevancies.length
            ? activityRelevancies.find(
                (relevancy) =>
                  selectedRelevancyIds.includes(relevancy.relevancyId) &&
                  relevancy.activityId === activities.id,
              )
            : true,
        ),
      );
    };
    fetch();
  }, [
    search,
    selectedSkills,
    selectedRelevancies,
    selectedCategories,
    selectedTypes,
    jobProfile.activities,
    activityRelevancies,
  ]);

  const getCols = () => {
    return [
      {
        title: i18n._(t`Name`),
      },
      {
        title: i18n._(t`Category`),
        width: 168,
      },
      {
        title: i18n._(t`Type`),
        width: 108,
      },
      !isEmpty(relevancies)
        ? {
            title: i18n._(t`Relevancy`),
            width: 208,
          }
        : {
            width: 208,
          },
      {
        width: 48,
      },
    ].filter((i) => i);
  };

  const setCurrentRelevancy = async (activity, relevancy) => {
    const newRelevancies = activityRelevancies.filter((i) => i.activityId !== activity.id);
    if (relevancy) {
      newRelevancies.push({
        activityId: activity.id,
        relevancyId: relevancy.id,
      });
    }
    const updatedJobProfile = await updateJobProfile(jobProfile.id, {
      ...jobProfile,
      activityRelevancies: newRelevancies,
    });
    updateJobProfileState(updatedJobProfile);
    setActivityRelevancies(newRelevancies);
  };

  const deleteActivity = async (activityToDelete) => {
    await deleteOriginalLibraryActivities([activityToDelete.id]);
    setActivities((activities) => {
      return activities.filter((activity) => activity.id !== activityToDelete.id);
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const onSaveActivity = (activity) => {
    const newActivities = activities.map((i) => (i.id === activity.id ? activity : i));
    setActivities(newActivities);
  };

  const renderActivity = (activity) => {
    const activityRelevancyObject = activityRelevancies?.find((i) => i.activityId === activity.id);
    const menuItems = [
      {
        label: i18n._(t`None`),
        action: () => setCurrentRelevancy(activity),
      },
      ...Object.values(relevancies).map((relevancy) => ({
        label: relevancy.name,
        action: () => setCurrentRelevancy(activity, relevancy),
      })),
    ];
    const currentRelevancy = activityRelevancyObject?.relevancyId;

    return (
      <TableRow key={activity.id} height="48px">
        <TableCol>
          <ActivityNameWrapper
            $isWithLabel={!activity.isAvailableInUserLibrary}
            onClick={() => setActivityToViewInModal(activity)}
          >
            <span>{activity.name}</span>
            {!activity.isAvailableInUserLibrary && (
              <Tooltip
                tooltip={i18n._(
                  t`This activity is not available for employees. Enable the setting "Make content available for the user for on demand learning" in the activity settings.`,
                )}
              >
                <Label
                  onClick={(e) => {
                    e.stopPropagation();
                    setActivityToViewInUpdateModal(activity);
                  }}
                >
                  {i18n._(t`Not available`)}
                </Label>
              </Tooltip>
            )}
          </ActivityNameWrapper>
        </TableCol>
        <TableCol>
          <ActivitySubTitle>
            {activity.categories &&
              activity.categories.map(
                (i, index) =>
                  `${activityCategories[i] && activityCategories[i].name}${
                    index !== activity.categories.length - 1 ? ', ' : ''
                  }`,
              )}
          </ActivitySubTitle>
        </TableCol>
        <TableCol>
          <ActivitySubTitle>{activity.type}</ActivitySubTitle>
        </TableCol>
        <TableCol>
          {!isEmpty(relevancies) && (
            <ActionsPopoverWrapper
              items={menuItems}
              isRows
              placement="bottom"
              maxWidth={222}
              height="40px"
            >
              <DropdownWrapper>
                <RelevancyName>
                  {relevancies[currentRelevancy]?.name || i18n._(t`None`)}
                </RelevancyName>
                <Icon icon={ICONS.DROPDOWN} size={ICON_SIZES.SMALL} />
              </DropdownWrapper>
            </ActionsPopoverWrapper>
          )}
        </TableCol>
        <TableCol>
          <IconButton
            color="none"
            onClick={async () => {
              const newActivities = activities.filter((i) => i.id !== activity.id);
              const newActivityIds = newActivities.map((i) => i.id);
              await updateJobProfile(jobProfile.id, {
                ...jobProfile,
                activities: newActivityIds,
                activityRelevancies: activityRelevancies.filter((i) =>
                  newActivityIds.includes(i.activityId),
                ),
              });
              updateJobProfileState({
                ...jobProfile,
                activities: newActivityIds,
                activityRelevancies: activityRelevancies.filter((i) =>
                  newActivityIds.includes(i.activityId),
                ),
              });
              setActivities(newActivities);
            }}
            size={24}
            noBorder
          >
            <Icon icon={ICONS.DELETE_BIN} size={ICON_SIZES.LARGE} color="var(--company-color)" />
          </IconButton>
        </TableCol>
      </TableRow>
    );
  };

  return (
    <div>
      <FilterRow>
        <StyledSearch placeholder={i18n._(t`Search`)} value={search} onChange={handleSearch} />
        <StyledAutocompleteFilterCategories
          checkedList={selectedCategories}
          onChange={setSelectedCategories}
        />
        <StyledAutocompleteFilterActivityTypes
          checkedList={selectedTypes}
          onChange={setSelectedTypes}
        />
        <StyledAutocompleteFilterSkills checkedList={selectedSkills} onChange={setSelectedSkills} />
        {!isEmpty(relevancies) && (
          <AutocompleteFilterRelevancies
            checkedList={selectedRelevancies}
            onChange={setSelectedRelevancies}
          />
        )}
      </FilterRow>
      <StyledTableCard
        cols={getCols()}
        hideHeader
        noBorder
        noShadow
        hideSearch
        items={activities}
        loading={loading}
        renderRow={renderActivity}
      />
      {!!activityToViewInModal && (
        <ActivityModal
          activityId={activityToViewInModal.id}
          onClose={() => setActivityToViewInModal(undefined)}
        />
      )}
      {!!activityToViewInUpdateModal && (
        <EditLibraryActivityModal
          currentActivity={activityToViewInUpdateModal}
          onHideModal={() => setActivityToViewInUpdateModal(undefined)}
          onSaveActivity={onSaveActivity}
          deleteActivity={() => deleteActivity(activityToViewInUpdateModal)}
        />
      )}
    </div>
  );
};

export default RoleLearningTab;
