import React, { memo } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import qs from 'qs';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import DeleteConversationModal from '~/components/DeleteConversationModal';
import EvalPlanModal from '~/components/EvalPlanModal';
import HeadingNavigation from '~/components/HeadingNavigation';
import AddTalkingPointsModal from '~/components/Modals/AddTalkingPointsModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Shadow from '~/components/UI/Shadow';
import BaseLayout from '~/layouts/BaseLayout';
import ConversationApprove from '~/pages/UserConversation/components/ConversationApprove';

import ConversationContent from './components/ConversationContent';
import ConversationNotes from './components/ConversationNotes';
import CopyTalkingPointSection from './components/CopyTalkingPointSection';
import NextSteps from './components/NextSteps';
import useConversationState from './useConversationState';
import { CONVERS_TOAST_TYPES } from './userConversationConstants';

import { ROLES, TALKING_POINT_TYPES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getSelectedRole, getUser, getTeams } from '~/selectors/baseGetters';
import * as conversationsServices from '~/services/conversations';
import { deleteUserConversation } from '~/services/userConversations';
import { COLOR_PALETTE } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';
import history from '~/utils/history';

const ContentWrapper = styled.div`
  display: flex;
  height: calc(100% - 32px);
  max-height: calc(100% - 32px);
`;

const Content = styled(Shadow)`
  height: 100%;
  background-color: ${COLOR_PALETTE.WHITE};
  box-sizing: border-box;
  width: 858px;
`;

const DELETE_OPTIONS = {
  SINGLE: 'single',
  ALL: 'all',
  FUTURE: 'future',
};

const UserConversation = ({
  match: {
    params: { conversationId },
  },
}) => {
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const currentRole = useSelector(getSelectedRole);
  const teams = useSelector(getTeams);
  const { addToast } = useToasts();
  const showToastMessage = (type) => {
    const TOAST_INFO_TYPE = {
      [CONVERS_TOAST_TYPES.SWITCH_1_1]: {
        title: i18n._(t`Switched conversations`),
        subtitle: i18n._(t`You have opened a different event`),
      },
      [CONVERS_TOAST_TYPES.TP_COPY_TO_NEXT]: {
        title: i18n._(t`Talking point succesfully added to the next conversation`),
      },
      [CONVERS_TOAST_TYPES.STEP_COPY_TO_NEXT]: {
        title: i18n._(t`Next step succesfully added to the next conversation`),
      },
    };
    const toast = TOAST_INFO_TYPE[type];
    addToast({
      title: toast.title,
      subtitle: toast.subtitle,
      type: TOAST_TYPES.INFO,
    });
  };
  const $conversation = useConversationState(
    conversationId,
    user,
    currentRole,
    false,
    showToastMessage,
  );
  const $eventModal = useBoolState();
  const $deleteConversationModal = useBoolState();
  const $loadDeleteConversation = useBoolState(false);
  const $addTPModal = useBoolState();
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const parsedUrl = new URL(window.location.href);
  const backPath = `${parsedUrl.searchParams.get('from')}${parsedUrl.hash}`;

  const goBack = () => {
    if (params.from) {
      const from = location.hash.slice(1)
        ? params.from + '#' + location.hash.slice(1)
        : params.from;
      history.push(from);
    } else {
      routes.CONVERSATIONS.go({});
    }
  };

  const deleteConversation = async (deleteOption) => {
    $loadDeleteConversation.on();
    if (deleteOption === DELETE_OPTIONS.ALL) {
      await conversationsServices.deleteConversation($conversation.originalConversation.id);
    } else if (deleteOption === DELETE_OPTIONS.SINGLE) {
      await deleteUserConversation($conversation.id);
    } else if (deleteOption === DELETE_OPTIONS.FUTURE) {
      await conversationsServices.deleteConversationFutureEvents(
        $conversation.originalConversation.id,
      );
    }
    $loadDeleteConversation.off();
    goBack();
  };

  function renderActionButtons() {
    if (!$conversation.name) {
      return null;
    }

    return (
      <>
        {($conversation.isOriginalOwner || currentRole === ROLES.ADMIN) && (
          <Button
            type="primary-border"
            label={i18n._(t`Delete`)}
            tooltip={i18n._(t`Delete this event and/or all events`)}
            onClick={async () => {
              $deleteConversationModal.on();
            }}
          />
        )}
        {($conversation.isOriginalOwner || currentRole === ROLES.ADMIN) && (
          <Button
            type="primary-border"
            label={i18n._(t`Edit`)}
            tooltip={i18n._(t`Update all future events for this conversation`)}
            onClick={() =>
              routes.CONVERSATION_UPDATE.go(
                {},
                {
                  isBackPath: true,
                  conversationId: get($conversation, 'originalConversation.id'),
                },
              )
            }
          />
        )}
        <Button
          label={t`Download PDF`}
          onClick={$conversation.downloadPdf}
          tooltip={t`Download a pdf report for this conversation`}
          type="primary"
        />
      </>
    );
  }

  const coachTeams =
    currentRole === ROLES.USER
      ? Object.values(teams).filter((t) => {
          return t.coaches && t.coaches.includes(user.id);
        })
      : [];

  const sortedRepeatedConversations = orderBy($conversation.repeatedConversations, 'startDate').map(
    ({ startDate }) => startDate,
  );
  const earliestDate = sortedRepeatedConversations[0];
  const latestDate = last(sortedRepeatedConversations);

  const showDeleteFutureEventsOption =
    $conversation.originalConversation?.startDate &&
    moment(earliestDate).isBefore() &&
    moment(latestDate).isAfter();

  return (
    <div>
      <HeadingNavigation
        label={i18n._(t`Conversations`)}
        onBack={goBack}
        actions={renderActionButtons()}
      />
      <BaseLayout>
        <ContentWrapper>
          <ShowSpinnerIfLoading loading={$conversation.loading}>
            <Content>
              <ConversationContent
                date={convertToTimeString($conversation.startDate, TIME_FORMATS.FULL_WITH_WEEK_DAY)}
                width="100%"
                name={$conversation.name}
                createdBy={$conversation.createdBy?.id}
                eventModal={$eventModal}
                backpath={backPath}
                participants={$conversation.participants?.map((p) => (p.id ? p.id : p))}
                currentUserId={user.id}
                talkingPoints={$conversation.talkingPoints}
                doneTP={$conversation.donePoints}
                events={$conversation.repeatedConversations}
                openTPModal={$addTPModal.on}
                openEventModal={$eventModal.on}
                description={$conversation.description}
                coachTeams={coachTeams}
                updateConversation={() =>
                  routes.CONVERSATION_UPDATE.go(
                    {},
                    {
                      isBackPath: true,
                      conversationId: get($conversation, 'originalConversation.id'),
                    },
                  )
                }
                readOnly={$conversation.readOnly}
                conversationId={$conversation.id}
                attachments={$conversation.attachments}
                onAddAttachment={$conversation.onAddAttachment}
                onDeleteAttachment={$conversation.onDeleteAttachment}
                $conversation={$conversation}
                currentRole={currentRole}
                toast={showToastMessage}
                i18n={i18n}
              />
              {$addTPModal.value && (
                <AddTalkingPointsModal
                  onClose={$addTPModal.off}
                  participants={$conversation.participants}
                  onSubmit={$conversation.onAddTalkingPoints}
                  disabledItems={($conversation.talkingPoints || []).map((tp) =>
                    [TALKING_POINT_TYPES.CUSTOM.key, TALKING_POINT_TYPES.TEMPLATES.key].includes(
                      tp.type,
                    )
                      ? tp.name
                      : tp.target?.id,
                  )}
                />
              )}
              {$eventModal.value && (
                <EvalPlanModal
                  conversation={$conversation}
                  closeModal={(updatedConv, isUpdateFuture) => {
                    $eventModal.off();
                    if (updatedConv) {
                      $conversation.onUpdateEvent(updatedConv, isUpdateFuture);
                    }
                  }}
                  onSubmit={$conversation.setDateOfConversation}
                  readOnly={$conversation.readOnly}
                  isDigitalMeeting
                />
              )}
              {$deleteConversationModal.value && (
                <DeleteConversationModal
                  deleteOptions={DELETE_OPTIONS}
                  i18n={i18n}
                  loadDeleteConversation={$loadDeleteConversation.value}
                  onClose={$deleteConversationModal.off}
                  deleteConversation={deleteConversation}
                  showDeleteAll={
                    $conversation.originalConversation.repeat &&
                    $conversation.originalConversation.repeat.type !== 'no-repeat'
                  }
                  showDeleteFuture={showDeleteFutureEventsOption}
                />
              )}
              <CopyTalkingPointSection $conversation={$conversation} />
              {$conversation.isApprovalFlow && <ConversationApprove conversation={$conversation} />}
              <ConversationNotes
                conversationComments={$conversation.conversationComments}
                privateNotes={$conversation.privateNotes}
                deleteComment={$conversation.deleteConversationComment}
                deletePrivateNote={$conversation.deletePrivateNote}
                readOnly={$conversation.readOnly}
                conversationId={$conversation.id}
                handleComment={$conversation.createUpdateConversationComment}
                handlePrivateNote={$conversation.createUpdatePrivateNote}
              />
            </Content>
            <NextSteps
              i18n={i18n}
              conversationId={$conversation.id}
              conversationCreatedById={$conversation.createdBy?.id}
              repeatedConversations={$conversation.repeatedConversations}
              participants={$conversation.participants}
              toast={showToastMessage}
            />
          </ShowSpinnerIfLoading>
        </ContentWrapper>
      </BaseLayout>
    </div>
  );
};

export default memo(UserConversation);
