import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';
import UserCareer from '~/pages/UserCareer';

import TabHeader from './components/TabHeader';

import getUserFullName from '~/utils/getUserFullName';

const Container = styled.div`
  margin: 24px 24px;
`;

const TabHeaderWrapper = styled(TabHeader)`
  margin: 16px 24px;
`;

const CareerTab = ({ i18n, user }) => {
  const userName = getUserFullName(user);

  return (
    <BoxWithBorder>
      <TabHeaderWrapper
        title={i18n._(t`Career`)}
        description={i18n._(t`An overview of all ${userName} current roles and ambitions`)}
      />
      <Container>
        <UserCareer userId={user.id} />
      </Container>
    </BoxWithBorder>
  );
};

export default withI18n()(CareerTab);
