import React, { useEffect, useRef, useState } from 'react';

import { NOTIFICATION_PREFERENCES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';

import { ProfileSettingsBlock } from '~/components/ProfileSettingsComponents/MainDashboard/ProfileSettingsBlock';

import {
  HeaderWrap,
  StyledTitle,
  ColumnWrap,
  ColumnTitle,
  ContentWrap,
  StyledDivider,
} from './design';
import { NotificationRow } from './NotificationRow';

import {
  checkModuleCareer,
  checkModuleConversations,
  checkModuleGoals,
  checkModuleLearning,
  checkModuleReviews,
  checkModuleRTFeedbacks,
  checkModuleSurvey,
  getUser,
} from '~/selectors/baseGetters';
import { updateUserNotificationSettings } from '~/services/users';
import { updateUser } from '~/store/users/actions';

import type { NotificationPreference, NotificationSettings } from '@learned/types';

const defaultSettings: NotificationPreference = {
  system: { isEnabled: false },
  email: { isEnabled: false },
};

const NotificationBlock = () => {
  const { i18n } = useLingui();
  const [notificationSettings, setNotificationSettings] = useState<NotificationSettings>();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const sectionOne = useRef<Array<number>>([]);
  const sectionTwo = useRef<Array<number>>([]);

  const isReviewModuleActive = useSelector(checkModuleReviews);
  const isConversationModuleActive = useSelector(checkModuleConversations);
  const isFeedbackModuleActive = useSelector(checkModuleRTFeedbacks);
  const isCareerModuleActive = useSelector(checkModuleCareer);
  const isGoalsModuleActive = useSelector(checkModuleGoals);
  const isSurveyModuleActive = useSelector(checkModuleSurvey);
  const isDevelopmentModuleActive = useSelector(checkModuleLearning);

  const getModuleStatusByNotificationPreference = (
    notificationType: NOTIFICATION_PREFERENCES,
  ): boolean => {
    switch (notificationType) {
      case NOTIFICATION_PREFERENCES.reviews:
        return isReviewModuleActive;
      case NOTIFICATION_PREFERENCES.conversations:
        return isConversationModuleActive;
      case NOTIFICATION_PREFERENCES.feedback:
        return isFeedbackModuleActive;
      case NOTIFICATION_PREFERENCES.career:
        return isCareerModuleActive;
      case NOTIFICATION_PREFERENCES.development:
        return isDevelopmentModuleActive;
      case NOTIFICATION_PREFERENCES.goals:
        return isGoalsModuleActive;
      case NOTIFICATION_PREFERENCES.surveys:
        return isSurveyModuleActive;
      case NOTIFICATION_PREFERENCES.teams:
        return true;
      default:
        return false;
    }
  };

  const getPreferences = (key: NOTIFICATION_PREFERENCES): NotificationPreference => {
    return notificationSettings
      ? notificationSettings[NOTIFICATION_PREFERENCES[key]]
      : defaultSettings;
  };

  const onPreferenceChange = async (
    type: NOTIFICATION_PREFERENCES,
    preference: NotificationPreference,
  ) => {
    await updateUserNotificationSettings({ [type]: preference });
    user.connections[0].notificationSettings[type] = preference;
    /* @ts-ignore */
    dispatch(updateUser({ userId: user.id, update: user }));
  };

  useEffect(() => {
    setNotificationSettings(user?.connections[0]?.notificationSettings);
  }, [user]);

  const setSectionItems = (index: number): void => {
    if (index < 4) {
      sectionOne.current = [...sectionOne.current, index];
    } else if (index > 3 && index < 7) {
      sectionTwo.current = [...sectionTwo.current, index];
    }
  };

  const Header = (
    <HeaderWrap>
      <StyledTitle>{i18n._(t`Notification settings`)}</StyledTitle>
      <ColumnWrap>
        <ColumnTitle>{i18n._(t`e-mail`)}</ColumnTitle>
        <ColumnTitle>{i18n._(t`system`)}</ColumnTitle>
      </ColumnWrap>
    </HeaderWrap>
  );

  const Content = (
    <ContentWrap>
      {Object.values(NOTIFICATION_PREFERENCES).map(
        (type: NOTIFICATION_PREFERENCES, index: number) => {
          const moduleStatus = getModuleStatusByNotificationPreference(type);
          if (moduleStatus) {
            setSectionItems(index);
          }

          return (
            <div key={type}>
              {moduleStatus && (
                <NotificationRow
                  type={type}
                  preference={getPreferences(type)}
                  onChange={onPreferenceChange}
                />
              )}
              {((index === 3 && sectionOne.current.length > 0) ||
                (index === 6 && sectionTwo.current.length > 0)) && <StyledDivider />}
            </div>
          );
        },
      )}
    </ContentWrap>
  );

  return <ProfileSettingsBlock header={Header} content={Content} />;
};

export { NotificationBlock };
