import styled from 'styled-components';

import { COLORS } from '~/styles';

const Block = styled.div`
  border-radius: 6px;
  border: solid 1px #dae4f7;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 16px;
`;

const Title = styled.div`
  color: ${COLORS.TEXT_BLACK};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 25px;
`;

const Text = styled.div`
  color: ${COLORS.TEXT_BLACK};
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
`;

export { Block, Title, Text };
