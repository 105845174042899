import iconFactory from './iconFactory';

const d =
  'M10.998 18c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm23.82 5.16c.73.724 1.18 1.728 1.18 2.84 0 1.102-.446 2.1-1.17 2.826l-10 10c-.75.752-1.768 1.174-2.83 1.174s-2.08-.422-2.83-1.174L5.174 24.83c-.753-.75-1.177-1.768-1.176-2.83V12c0-2.21 1.79-4 4-4h10c1.106 0 2.106.446 2.83 1.17L34.82 23.162l-.002-.002zm-7.746-11.748l2-2 13.75 13.75C43.55 23.886 44 24.89 44 26c0 1.104-.446 2.102-1.17 2.828l-10.76 10.76-2-2L41.5 26 27.072 11.412z';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const TagsIcon = iconFactory('Tags', d);

TagsIcon.defaultProps.viewBox = '0 0 48 48';

export default TagsIcon;
