import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Header6Dark } from '~/components/UI/Typographics/headers';

import CommentMandatorySetup from './CommentMandatorySetup';
import IsApplicableSwitcher from './IsApplicableSwitcher';
import Ratings from './Ratings';
import Toggler from './Toggler';

import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getCompanySkillCategories } from '~/services/companySettings';
import { getSkillCategories } from '~/services/skillCategories';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
  margin-bottom: 8px;
`;

const SkillLabel = styled.div`
  display: flex;
  border-radius: 3px;
  border: solid 1px ${COLORS.BORDER_LIGHT};
  max-height: 48px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  padding: 11px 14px;
  color: ${COLORS.TEXT_SECONDARY};
  font-weight: 600;
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  input {
    width: 470px;
  }
`;

const QuestionName = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${COLORS.TEXT_SECONDARY};
  border-radius: 3px;
  background-color: ${COLORS.BG_PAGE};
  padding: 8px;
  min-height: 69px;
  height: 69px;
  width: 100%;
  box-sizing: border-box;
`;

const QuestionExample = styled(Header6Dark)`
  display: flex;
  align-items: center;
  margin: 0 0 9px;
  padding-left: 18px;
  border-radius: 3px;
  height: 48px;
  min-height: 48px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${COLOR_PALETTE.WHITE};
  border: 1px solid ${COLORS.BG_PAGE};
`;

const TogglerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const JobProfileQuestion = ({
  question,
  skillLabels,
  isSkippable,
  toggleIsSkippable,
  toggleIsCommentObligated,
  isCommentObligated,
  toggleSkillCategories,
  isCollapsed,
}) => {
  const { i18n } = useLingui();
  const company = useSelector(getCurrentCompany);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const [skillCategories, categoryOrder] = await Promise.all([
        getSkillCategories(),
        getCompanySkillCategories(company.id),
      ]);
      const orderedCategories = categoryOrder.map((c) => skillCategories[c]);
      if (isMounted) {
        setCategories(orderedCategories);
      }
    };
    fetchData();
    return () => (isMounted = false);
    // eslint-disable-next-line
  }, []);

  const questionCategories = categories.map((c) => {
    return {
      key: c.id,
      label: c.name,
      isEnabled: isEmpty(question.skillCategories) || question.skillCategories.includes(c.id),
    };
  });

  const updateCategories = (key, value) => {
    const currentCategories = isEmpty(question?.skillCategories)
      ? categories.map((c) => c.id)
      : // filter old deleted categories
        question.skillCategories.filter((c) => categories.map((c) => c.id).includes(c));
    // min 1 category
    if (currentCategories.length === 1 && !value) {
      return;
    }
    const updatedCategories = currentCategories.includes(key)
      ? currentCategories.filter((c) => c !== key)
      : [...currentCategories, key];
    toggleSkillCategories(updatedCategories);
  };

  return (
    <>
      <Row>
        <Column>
          <QuestionExample>{i18n._(t`How did you perform on [skill name]?`)}</QuestionExample>
          <QuestionName>{question.name}</QuestionName>
        </Column>
      </Row>
      {!isCollapsed && (
        <>
          <Row>
            {!isEmpty(questionCategories) && (
              <Column>
                <FilterTitle>
                  <Trans>Skill categories</Trans>
                </FilterTitle>
                <TogglerWrapper>
                  {questionCategories.map((item) => (
                    <Toggler
                      key={item.id}
                      item={item}
                      onClick={updateCategories}
                      isTooltipDisabled={true}
                    />
                  ))}
                </TogglerWrapper>
              </Column>
            )}
          </Row>
          <Ratings>
            {skillLabels.map((label) => (
              <SkillLabel key={label}>{label}</SkillLabel>
            ))}

            <IsApplicableSwitcher onChange={toggleIsSkippable} value={isSkippable} />
          </Ratings>
          <CommentMandatorySetup
            isObligated={isCommentObligated}
            onChange={toggleIsCommentObligated}
          />
        </>
      )}
    </>
  );
};

export default React.memo(JobProfileQuestion);
