import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import TagsIcon from '~/components/Icons/Tags';
import Placeholder from '~/components/Placeholder';
import SearchSelectButton from '~/components/SearchSelectButton';
import SelectLevelModal from '~/components/SelectSkillsModal/SelectLevelModal';
import SkillName from '~/components/Skill/SkillName';
import SkillSetupModal from '~/components/SkillSetupModal';
import SvgIcon from '~/components/SvgIcon';
import { SearchField } from '~/components/Text';
import TextField from '~/components/TextField';
import Shadow from '~/components/UI/Shadow';
import TableCard, { TableRow, TableCol } from '~/components/UI/TableCard';
import { Header3 } from '~/components/UI/Typographics/headers';

import SkillsIcon from '~/assets/mdi-ballot.svg';

import { SKILL_SOURCE, SUGGESTIONS_SKILL_CATEGORY } from '~/constants/skills';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import useStringState from '~/hooks/useStringState';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import getLang from '~/selectors/getLang';
import { getCompanySkillCategories } from '~/services/companySettings';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkills, getSuggestedSkillsNames } from '~/services/skills';
import { COLOR_PALETTE, COLORS } from '~/styles';

const ContentHeader = styled(Shadow)`
  background-color: white;
  border-radius: 6px 6px 0 0;
  border-bottom: none;
  display: flex;
  padding: 16px;
  align-items: center;
`;

const Header = styled(Header3)`
  margin: 0;
  margin-right: auto;
`;

const FilterWrapper = styled.div`
  margin-right: 8px;
`;

const NameSearch = styled(SearchField)`
  width: 248px;
  ${TextField} {
    border-radius: 6px;
    font-size: 14px;
    height: 32px;
  }
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: ${(props) => (props.$isBold ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  color: ${(props) => (props.$isBlack ? COLORS.TEXT_BLACK : COLORS.TEXT_SECONDARY)};
`;

function SkillSetDashboard({
  i18n,
  skillSetName,
  isLearnedSet = false,
  isSuggestionsSearch = false,
  newSkill = null,
  changeSetTotal,
}) {
  const [skills, setSkills] = useState([]);
  const [activeSkill, setActiveSkill] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const $search = useStringState();
  const debSearch = useDebounce($search.value, 300);
  const company = useSelector(getCurrentCompany);
  const $loading = useBoolState(true);
  const $isSkillModalOpen = useBoolState();
  const $isReadOnlySkillModalOpen = useBoolState();
  const lang = useSelector(getLang);
  const $isShowUpdateButton = useBoolState();

  useEffect(() => {
    const fetchData = async () => {
      if (isSuggestionsSearch) {
        if (!debSearch) {
          return $loading.off();
        }
        $loading.on();

        const suggestedSkillsNames = await getSuggestedSkillsNames({
          search: debSearch,
          limit: 20,
        });

        setSkills(suggestedSkillsNames.map((skill) => ({ ...skill, name: skill.description })));
        setCategories(Object.values(SUGGESTIONS_SKILL_CATEGORY));

        $loading.off();
      } else {
        const [skillCategories, categoryOrder] = await Promise.all([
          getSkillCategories(),
          getCompanySkillCategories(company.id),
        ]);
        const orderedCategories = categoryOrder.map((c) => skillCategories[c]);
        setCategories(orderedCategories);

        let filteredSkills = await getSkills({
          hideDeleted: true,
          source: isLearnedSet ? SKILL_SOURCE.learned2 : SKILL_SOURCE.company,
          search: debSearch,
          categories: selectedCategories,
          lang,
        });
        filteredSkills = Object.values(filteredSkills)
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name));
        setSkills(filteredSkills);
        $loading.off();
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [selectedCategories, debSearch]);

  useEffect(() => {
    if (!isEmpty(newSkill)) {
      const updatedSkills = [...skills, newSkill]
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));
      setSkills(updatedSkills);
    }
    // eslint-disable-next-line
  }, [newSkill]);

  const getCols = () => {
    return [
      {
        title: i18n._(t`Skill`),
      },
      {
        title: i18n._(t`Category`),
        width: '200px',
      },
      !isLearnedSet &&
        !isSuggestionsSearch && {
          title: i18n._(t`Last updated`),
          width: '140px',
        },
    ].filter((c) => c);
  };

  const openReadOnlySkillModal = (skill, isUpdate) => {
    setActiveSkill(skill);
    if (isUpdate) {
      $isShowUpdateButton.on();
    }
    $isReadOnlySkillModalOpen.on();
  };

  const showCategoryName = (categoryId) => {
    const category = categories.find((c) => c.id === categoryId);
    if (isEmpty(category)) {
      return '';
    }

    let name = category.name;
    if (typeof name === 'function') {
      name = name(i18n);
    }
    return <span>{name}</span>;
  };

  const renderRow = (skill) => (
    <TableRow key={skill.id}>
      <TableCol
        onClick={
          isSuggestionsSearch
            ? undefined
            : isLearnedSet
            ? () => openReadOnlySkillModal(skill, false)
            : () => openReadOnlySkillModal(skill, true)
        }
      >
        <Text $isBold $isBlack>
          <SkillName skill={skill} />
        </Text>
      </TableCol>
      <TableCol>
        {isSuggestionsSearch ? (
          <Text>{showCategoryName(skill.category)}</Text>
        ) : isLearnedSet ? (
          <Text>{categories.find((c) => c.type === 'soft').name}</Text>
        ) : (
          <Text>
            {showCategoryName(skill.categories[0])}
            {skill.categories.length > 1 ? ` + ${skill.categories.length - 1}` : ''}
          </Text>
        )}
      </TableCol>
      {!isLearnedSet && !isSuggestionsSearch && (
        <TableCol>
          <Text>
            {skill.meta.lastModifiedDate
              ? moment.utc(skill.meta.lastModifiedDate).format('DD-MM-YYYY')
              : moment.utc(skill.meta.createdDate).format('DD-MM-YYYY')}
          </Text>
        </TableCol>
      )}
    </TableRow>
  );

  const updateDeleteSkill = (skill, skillIsDeleted) => {
    const updatedSkills = skillIsDeleted
      ? skills.filter((s) => s.id !== skill.id)
      : skills
          .map((s) => (s.id === skill.id ? skill : s))
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name));
    setSkills(updatedSkills);
    if (skillIsDeleted) {
      changeSetTotal();
    }
  };

  const getPlaceholder = () => {
    if (isSuggestionsSearch) {
      return (
        <Placeholder
          Icon={() => <TagsIcon width={'50px'} height={'50px'} fill={COLORS.BG_PAGE} />}
          title={i18n._(t`Use the search box to look up skills`)}
          subTitle={i18n._(
            t`Search in more than 10.000 skills. Want to customise a skill? Go to your company skill and click +skill.`,
          )}
        />
      );
    } else {
      return (
        <Placeholder
          Icon={() => (
            <SvgIcon
              url={SkillsIcon}
              width={'50px'}
              height={'50px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
          title={i18n._(t`No skills available`)}
          subTitle={i18n._(t`There have not been added any skills to this skill set`)}
        />
      );
    }
  };

  const categoriesList = categories.map((c) => {
    return {
      id: c.id,
      label: c.name,
    };
  });

  return (
    <div>
      <ContentHeader>
        <Header>{skillSetName}</Header>
        {!isLearnedSet && !isSuggestionsSearch && (
          <FilterWrapper>
            <SearchSelectButton
              checkedList={selectedCategories}
              title={i18n._(t`Category`)}
              options={categoriesList}
              handleChange={(categories) => setSelectedCategories(categories)}
            />
          </FilterWrapper>
        )}
        <FilterWrapper>
          <NameSearch
            onChange={(e) => {
              $search.setForInput(e);
            }}
            placeholder={i18n._(t`Search`)}
          />
        </FilterWrapper>
      </ContentHeader>
      <TableCard
        cols={getCols()}
        items={skills}
        firstPlaceholder={getPlaceholder()}
        renderRow={renderRow}
        loading={$loading.value}
        noTopBorder
        hideHeader
      />
      {$isReadOnlySkillModalOpen.value && (
        <SelectLevelModal
          skill={activeSkill}
          categories={categories}
          onClose={() => {
            $isReadOnlySkillModalOpen.off();
            $isShowUpdateButton.off();
          }}
          readOnly={true}
          isShowUpdateButton={$isShowUpdateButton.value}
          onUpdateButtonClick={() => {
            $isReadOnlySkillModalOpen.off();
            $isShowUpdateButton.off();
            $isSkillModalOpen.on();
          }}
        />
      )}
      {$isSkillModalOpen.value && (
        <SkillSetupModal
          skill={activeSkill}
          onClose={$isSkillModalOpen.off}
          updateDeleteSkill={updateDeleteSkill}
        />
      )}
    </div>
  );
}

export default withI18n()(SkillSetDashboard);
