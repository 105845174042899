import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import Engagement from '~/pages/Reports/engagement/Layout';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('engagement', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.REPORTS_USER_ENGAGEMENT.routePath(ROLES.USER)}
        exact
        path={routes.REPORTS_USER_ENGAGEMENT.routePath(ROLES.USER)}
        component={Engagement}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        key={routes.REPORTS_COACH_TEAMS_ENGAGEMENT.routePath(ROLES.USER)}
        exact
        path={routes.REPORTS_COACH_TEAMS_ENGAGEMENT.routePath(ROLES.USER)}
        component={Engagement}
        allowedRoles={[ROLES.USER]}
      />,
    ],
    coach: [],
    admin: [
      <PrivateRoute
        key={routes.REPORTS_ADMIN_CUSTOM_REPORT.routePath(ROLES.ADMIN)}
        exact
        path={routes.REPORTS_ADMIN_CUSTOM_REPORT.routePath(ROLES.ADMIN)}
        component={Engagement}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.REPORTS_ADMIN_ENGAGEMENT.routePath(ROLES.ADMIN)}
        exact
        path={routes.REPORTS_ADMIN_ENGAGEMENT.routePath(ROLES.ADMIN)}
        component={Engagement}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
  routesPublic: [],
});
