import React, { ChangeEvent, useState, useContext } from 'react';

import { ENGAGEMENT_REPORT_CHART_TYPES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { DropdownIconState } from '~/components/Buttons/types';
import { ICONS, Icon } from '~/components/Icon';
import { TableGrid } from '~/components/TableGrid';
import { checkEmptyData } from '~/components/TableGrid/utils';
import { EngagementReportContext } from '~/pages/Reports/engagement/Layout';

import {
  SearchFieldWrapper,
  SearchCtr,
  FilterBtnCtr,
  FilterCtr,
  HeatmapCtr,
  DropDownCtr,
  BodyCtr,
  NoDataTextContainer,
} from './CustomStyles';

import { IColumnTable } from '~/@types/table';
import { useOutsideClick } from '~/hooks/useOutsideClick';
import useSearchState from '~/hooks/useSearchState';
import { COLORS } from '~/styles';

import ExportDropDown from '../../Components/ExportDropDown';
import ReFetchBtn from '../../Components/RefetchBtn';
import { MENU_SIZE, PAGINATION_PRIMARY_OPTIONS, TOptions, TPaginationProp } from '../../options';
import {
  EOptions,
  TEngagementData,
  TDataStruct,
  EExportOptions,
  TDropDownValues,
} from '../../types';
import { InformationModal } from '../InformationModal';

export type TFetchDataProp = {
  sortBy?: string;
  skip?: number;
  limit?: number;
  dt?: TDataStruct;
};

type TProps = {
  columns: IColumnTable<TEngagementData>[];
  companyAverageSelected: boolean;
  industryBenchMarkSelected: boolean;
  optionChangeHandler: (type: EOptions, val?: TDropDownValues) => void;
  engagementData: TEngagementData[];
  isLoading: boolean;
  isSecondaryColumn: boolean;
  noDataText: string;
  onShow: () => void;
  searchValue: string;
  selectedSortedOption: string;
  setShowExportMenu: (value: boolean) => void;
  showExportMenu: boolean;
  totalCount: number;
  onExportData: (type: EExportOptions, from: ENGAGEMENT_REPORT_CHART_TYPES) => void;
  handlePagination: (value: TPaginationProp, type: ENGAGEMENT_REPORT_CHART_TYPES) => void;
  pagination: TPaginationProp;
  enabledOptions: EOptions[];
  enabledOptionKeys: EOptions[];
  exportOptionArr: EExportOptions[];
  noRetry: boolean;
  dimensionSelected: {
    primary: TOptions | null;
    secondary: TOptions | null;
    measure: TOptions | null;
  };
};
const EngagementCustomPage = ({
  columns,
  companyAverageSelected,
  industryBenchMarkSelected,
  optionChangeHandler,
  engagementData,
  isLoading,
  isSecondaryColumn,
  noDataText,
  onShow,
  searchValue,
  selectedSortedOption,
  setShowExportMenu,
  showExportMenu,
  totalCount,
  onExportData,
  handlePagination,
  pagination,
  enabledOptionKeys,
  enabledOptions,
  exportOptionArr,
  noRetry,
  dimensionSelected,
}: TProps) => {
  const $search = useSearchState();
  const { i18n } = useLingui();
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [informationModalData, setInformationModalData] = useState<{
    id: string;
    name: string;
    value: number;
  } | null>(null);
  const { viewAs } = useContext(EngagementReportContext);

  const filters = {
    search: $search.value,
    setSearch: (value: string) => {
      $search.set(value.toLowerCase());
    },
  };

  const mainTablePlaceholder = checkEmptyData({
    data: engagementData,
    column: 'cells',
    field: 'measure',
    skipColumns: [],
    reportingThreshold: 0,
    i18n,
  });

  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowExportMenu(false);
    setShowInformationModal(false);
  });

  if (!isLoading && engagementData.length === 0) {
    return (
      <HeatmapCtr>
        <BodyCtr>
          <NoDataTextContainer>{noDataText}</NoDataTextContainer>
          {!noRetry && <ReFetchBtn optionChangeHandler={optionChangeHandler} />}
        </BodyCtr>
      </HeatmapCtr>
    );
  }

  const onItemClick = {
    column: 'primaryDimension',
    onClick: (d: TEngagementData) => {
      setInformationModalData({
        id: d.id,
        name: d.name || '',
        value:
          d.cells.find((c) => c.measure === (viewAs === ROLES.USER ? 'you' : 'average'))?.value ||
          0,
      });
      setShowInformationModal(true);
    },
  };

  const disabledBenchmark =
    dimensionSelected?.primary?.key === 'theme' &&
    (dimensionSelected?.secondary?.key === 'secondary_none' ||
      dimensionSelected?.secondary?.key === 'primary_none' ||
      !dimensionSelected?.secondary?.key) &&
    (dimensionSelected?.measure?.key === 'month' ||
      dimensionSelected?.measure?.key === 'quarter' ||
      dimensionSelected?.measure?.key === 'year');

  const isBenchmark = industryBenchMarkSelected && disabledBenchmark;

  const getRightMinWidth = () => {
    if (isBenchmark && companyAverageSelected) {
      return `${MENU_SIZE.RIGHT_FULL_WIDTH}px`;
    }
    if (companyAverageSelected) {
      return `${MENU_SIZE.ONLY_AVERAGE}px`;
    }
    if (isBenchmark) {
      return `${MENU_SIZE.ONLY_BENCHMARK}px`;
    }
    return '0px';
  };

  return (
    <>
      <HeatmapCtr>
        <FilterCtr>
          <FilterBtnCtr>
            <SearchCtr>
              <SearchFieldWrapper
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  optionChangeHandler(EOptions.customSearch, e.currentTarget.value);
                }}
                value={searchValue}
                placeholder={i18n._(t`Search...`)}
              />
            </SearchCtr>
            {enabledOptionKeys.includes(EOptions.exportOptions) && exportOptionArr.length > 0 && (
              <DropDownCtr>
                <Button
                  icon={ICONS.EXPORT}
                  disabled={!enabledOptions.includes(EOptions.exportOptions)}
                  label={`${i18n._(t`Export`)}`}
                  variant={ButtonVariant.SECONDARY}
                  onClick={() => setShowExportMenu(true)}
                  size={ButtonSize.MEDIUM}
                  dropDownIcon={showExportMenu ? DropdownIconState.UP : DropdownIconState.DOWN}
                />

                {showExportMenu && (
                  <ExportDropDown
                    onMenuChange={(type) => {
                      onExportData(type, ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT__PIVOT);
                      setShowExportMenu(false);
                    }}
                    reference={baseRef}
                    exportOptionArr={exportOptionArr}
                  />
                )}
              </DropDownCtr>
            )}
            <Icon icon={ICONS.EXPAND} color={COLORS.INACTIVE} onClick={onShow} className="icon" />
          </FilterBtnCtr>
        </FilterCtr>
        <TableGrid
          data={engagementData}
          onColClick={onItemClick}
          columns={columns}
          isLeftColumnsStriped
          isScrollbarVisible
          showSortBy={true}
          leftMinWidth={
            isSecondaryColumn
              ? `${MENU_SIZE.LEFT_HALF_WIDTH * 2 + 10}px`
              : `${MENU_SIZE.LEFT_FULL_WIDTH + 10}px`
          }
          rightMinWidth={getRightMinWidth()}
          isLoading={isLoading}
          paginationProps={{
            pagination,
            changePagination: (val) =>
              handlePagination(val, ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT__PIVOT),
            totalCount,
          }}
          placeholderProps={{
            noResultText: i18n._(t`No surveys found`),
            emptyStateText: i18n._(t`No surveys yet… Let’s create one!`),
            mainTable: mainTablePlaceholder,
          }}
          filtersProps={{
            filters,
            isToggleHideFilterVisible: false,
            isFiltered: false,
          }}
          showTopArea={false}
          sortBy={selectedSortedOption}
          setSortBy={(value) => {
            optionChangeHandler(EOptions.customSortedBy, value);
          }}
          paginationIndexAlternatives={PAGINATION_PRIMARY_OPTIONS.map((item) => ({
            id: item.id,
            label: item.label(i18n),
          }))}
        />
      </HeatmapCtr>
      {showInformationModal && informationModalData && (
        <InformationModal
          data={informationModalData}
          onClose={setShowInformationModal}
          baseRef={baseRef}
        />
      )}
    </>
  );
};

export default EngagementCustomPage;
