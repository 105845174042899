import styled from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 12px;
`;

const TeamsHeader = styled.div<{
  R?: number;
  G?: number;
  B?: number;
}>`
  height: 130px;
  background-image: linear-gradient(
    85deg,
    var(--company-color),
    rgba(${({ R }) => R}, ${({ G }) => G}, ${({ B }) => B}, 0.75)
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 50px;
`;

const Title = styled.div`
  color: ${COLORS.TEXT_HOVER};
`;

const NavigationCtr = styled.div``;

const ViewReport = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
`;

const PageHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  font-size: 14px;
`;

const CustomIcon = styled(Icon)``;

const TeamTitle = styled.div`
  color: ${COLORS.WHITE};
`;

const NoMembersPlaceholder = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export {
  CardWrapper,
  TeamsHeader,
  Title,
  NavigationCtr,
  ViewReport,
  PageHead,
  CustomIcon,
  TeamTitle,
  NoMembersPlaceholder,
};
