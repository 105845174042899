export default (focusArea, lang = 'en', full) => {
  const isEN = lang === 'en';
  let beh = '';
  if (full) {
    beh =
      !isEN && focusArea.exampleBehaviourNL
        ? focusArea.exampleBehaviourNL
        : focusArea.exampleBehaviour || '';
  }

  return `${!isEN && focusArea.nameNL ? focusArea.nameNL : focusArea.name} ${beh}`.trim();
};
