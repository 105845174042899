import React, { useMemo } from 'react';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';

import { Loader } from './components/Loader';
import {
  StyledCloseButton,
  StyledDeleteIconButton,
  StyledIconButton,
  StyledNavigationPrimaryButton,
  StyledNavigationSecondaryButton,
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledSquareButton,
  StyledTextDeleteButton,
  StyledTextPrimaryButton,
} from './design';
import { ButtonVariant, DropdownIconState } from './types';

import type { IButtonProps } from './types';

const Button = (props: IButtonProps) => {
  const {
    label,
    variant = ButtonVariant.PRIMARY,
    icon,
    iconSize: defaultIconSize,
    isLoading,
    counter,
    dropDownIcon = DropdownIconState.NONE,
  } = props;

  const isNavigationVariant = useMemo(
    () => [ButtonVariant.NAVIGATION_PRIMARY, ButtonVariant.NAVIGATION_SECONDARY].includes(variant),
    [variant],
  );

  const iconName = useMemo(() => {
    switch (variant) {
      case ButtonVariant.CLOSE:
        return ICONS.CLOSE;
      case ButtonVariant.ICON_DELETE:
        return ICONS.DELETE_BIN;
      default:
        return icon;
    }
  }, [icon, variant]);

  const iconSize = useMemo(() => {
    if (defaultIconSize) {
      return defaultIconSize;
    }

    switch (variant) {
      case ButtonVariant.CLOSE:
        return ICON_SIZES.LARGE;
      case ButtonVariant.SQUARE:
        return ICON_SIZES.SMALL;
      default:
        return ICON_SIZES.MEDIUM;
    }
  }, [defaultIconSize, variant]);

  const content = isLoading ? (
    <Loader />
  ) : (
    <>
      {iconName && (
        <div className="icon">
          <Icon icon={iconName} size={iconSize} />
        </div>
      )}
      {label && <span className="label">{label}</span>}
      {isNavigationVariant && (
        <div className="icon">
          <Icon icon={ICONS.NEXT} size={ICON_SIZES.SMALL} />
        </div>
      )}
      {!!counter && <div className="circleLabel">{counter}</div>}
      {dropDownIcon !== DropdownIconState.NONE && (
        <>
          {dropDownIcon === DropdownIconState.DOWN ? (
            <Icon icon={ICONS.DROPDOWN} size={ICON_SIZES.SMALL} />
          ) : (
            <div className="flip_vertically">
              <Icon icon={ICONS.DROPDOWN} size={ICON_SIZES.SMALL} />
            </div>
          )}
        </>
      )}
    </>
  );

  switch (variant) {
    default:
    case ButtonVariant.PRIMARY:
      return <StyledPrimaryButton {...props}>{content}</StyledPrimaryButton>;
    case ButtonVariant.SECONDARY:
      return <StyledSecondaryButton {...props}>{content}</StyledSecondaryButton>;
    case ButtonVariant.TEXT_PRIMARY:
      return <StyledTextPrimaryButton {...props}>{content}</StyledTextPrimaryButton>;
    case ButtonVariant.TEXT_DELETE:
      return <StyledTextDeleteButton {...props}>{content}</StyledTextDeleteButton>;
    case ButtonVariant.SQUARE:
      return <StyledSquareButton {...props}>{content}</StyledSquareButton>;
    case ButtonVariant.NAVIGATION_PRIMARY:
      return <StyledNavigationPrimaryButton {...props}>{content}</StyledNavigationPrimaryButton>;
    case ButtonVariant.NAVIGATION_SECONDARY:
      return (
        <StyledNavigationSecondaryButton {...props}>{content}</StyledNavigationSecondaryButton>
      );
    case ButtonVariant.CLOSE:
      return <StyledCloseButton {...props}>{content}</StyledCloseButton>;
    case ButtonVariant.ICON:
      return <StyledIconButton {...props}>{content}</StyledIconButton>;
    case ButtonVariant.ICON_DELETE:
      return <StyledDeleteIconButton {...props}>{content}</StyledDeleteIconButton>;
  }
};

export { Button };
