import React, { MouseEvent, useCallback, useEffect, useState } from 'react';

import { useLingui } from '@lingui/react';

import { Icon, ICONS } from '~/components/Icon';
import ChevronIcon from '~/components/Icons/Chevron';

import type { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

import { THLabel, TH, THead, SortMenu, SortIcons, TRHeader } from '../../design';
import { CheckBox } from '../CheckBox';

import type { IMultiSelect } from '../../types';

interface IHeaderProps {
  columns: IColumnTable[];
  isMultiSelectVisible?: boolean;
  multiSelect?: IMultiSelect;
  sortProps?: {
    sortBy?: string;
    handleSortBy: (col: string | undefined) => void;
  };
  isMenuVisible?: boolean;
  isSelectedCountVisible?: boolean;
  isSelectAllVisible?: boolean;
}

const i18nLabel = (label: string | Function, i18n: any) =>
  typeof label === 'function' ? label(i18n) : label;

const Header = ({
  isMultiSelectVisible = false,
  multiSelect,
  columns,
  sortProps,
  isMenuVisible,
  isSelectedCountVisible = true,
  isSelectAllVisible = true,
}: IHeaderProps) => {
  const [activeSortByColumn, setActiveSortByColumn] = useState<string | undefined>();
  const { i18n } = useLingui();
  const toggleSortColumnMenu = useCallback(
    (accessor: IColumnTable['accessor']) => {
      if (activeSortByColumn === accessor) {
        setActiveSortByColumn('');
      } else {
        setActiveSortByColumn(accessor);
      }
    },
    [activeSortByColumn],
  );

  const checkActiveSortColumn = (column: IColumnTable) => {
    return activeSortByColumn === column.accessor;
  };

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      // @ts-ignore
      const className = event?.target?.className;
      if ((className?.match && className?.match('item')) || className?.baseVal?.match('icon')) {
        return;
      } else {
        activeSortByColumn && toggleSortColumnMenu('');
      }
    },
    [toggleSortColumnMenu, activeSortByColumn],
  );

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      // @ts-ignore
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleMouseDown]);

  const isHideHeader =
    // @ts-ignore
    !!isMultiSelectVisible && multiSelect?.checkedCount > 0 && isSelectedCountVisible;

  return (
    <THead>
      <TRHeader isHideHeader={isHideHeader}>
        {isHideHeader && (
          <>
            <TH key={'th-multi-select'} minWidth="20px" maxWidth="20px" padding="0 0 0 32px" />
            {columns.map((column) => (
              <TH
                key={`th-${column.accessor}`}
                padding="0 24px"
                minWidth={column?.minWidth}
                maxWidth={column?.maxWidth}
              />
            ))}
          </>
        )}
        {/* @ts-ignore */}
        {isMultiSelectVisible && !isHideHeader && isSelectAllVisible && (
          <TH key={'th-multi-select'} minWidth="20px" maxWidth="20px" padding="16px 0 16px 25px">
            <CheckBox isSelected={multiSelect?.isAllChecked} onChange={multiSelect?.onCheckAll} />
          </TH>
        )}
        {isMultiSelectVisible && !isHideHeader && !isSelectAllVisible && (
          <TH key={'th-multi-select'} minWidth="20px" maxWidth="20px" padding="16px 0 16px 25px" />
        )}
        {!isHideHeader &&
          columns.map((column) => (
            <TH
              key={`th-${column.accessor}`}
              minWidth={column?.minWidth}
              maxWidth={column?.maxWidth}
              isActive={column.sortBy && sortProps?.sortBy === column?.sortBy?.asc.key}
            >
              <THLabel
                className={
                  activeSortByColumn !== undefined
                    ? checkActiveSortColumn(column)
                      ? 'hover'
                      : 'isFade'
                    : ''
                }
                $isPointer={Boolean(column.sortBy)}
                onClick={() => column.sortBy && toggleSortColumnMenu(column.accessor)}
              >
                <span>
                  {typeof column.name === 'function'
                    ? column.name && column.name(i18n)
                    : column.name}
                </span>
                {column.sortBy && (
                  <>
                    <SortIcons
                      className={
                        sortProps?.sortBy === column?.sortBy?.asc.key ||
                        sortProps?.sortBy === column?.sortBy?.desc.key
                          ? 'sortIconsActive'
                          : 'sortIcons'
                      }
                    >
                      <Icon
                        icon={ICONS.SORT_UP}
                        width={9}
                        height={7}
                        color={
                          sortProps?.sortBy === column?.sortBy?.asc.key
                            ? COLORS.COMPANY
                            : COLORS.PLACEHOLDERS
                        }
                      />
                      <Icon
                        icon={ICONS.SORT_DOWN}
                        width={9}
                        height={7}
                        color={
                          sortProps?.sortBy === column?.sortBy?.desc.key
                            ? COLORS.COMPANY
                            : COLORS.PLACEHOLDERS
                        }
                      />
                    </SortIcons>
                  </>
                )}
              </THLabel>
              {column.sortBy && checkActiveSortColumn(column) && (
                <SortMenu>
                  <button
                    className={`item ${
                      column?.sortBy?.asc?.key === sortProps?.sortBy ? 'selected' : ''
                    }`}
                    onClick={() => {
                      sortProps?.handleSortBy(column?.sortBy?.asc?.key);
                      setActiveSortByColumn('');
                    }}
                  >
                    <ChevronIcon
                      size={12}
                      className="icon"
                      width="12px"
                      height="15px"
                      color={COLORS.PLACEHOLDERS}
                    />
                    {i18nLabel(column.sortBy?.asc.label, i18n)}
                  </button>
                  <button
                    className={`item ${
                      column?.sortBy?.desc?.key === sortProps?.sortBy ? 'selected' : ''
                    }`}
                    onClick={() => {
                      sortProps?.handleSortBy(column?.sortBy?.desc?.key);
                      setActiveSortByColumn('');
                    }}
                  >
                    <ChevronIcon
                      size={12}
                      className="icon rotate"
                      width="12px"
                      height="15px"
                      color={COLORS.PLACEHOLDERS}
                    />

                    {i18nLabel(column.sortBy?.desc.label, i18n)}
                  </button>
                </SortMenu>
              )}
            </TH>
          ))}
        {isMenuVisible && <TH key={'th-menu'} minWidth="40px" />}
      </TRHeader>
    </THead>
  );
};

export { Header };
