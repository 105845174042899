import React, { useState, useEffect } from 'react';

import orderBy from 'lodash/orderBy';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import NotificationsDropdown from '~/components/Notifications/NotificationsDropdown';

import useBoolState from '~/hooks/useBoolState';
import { getIsMenuCollapsed } from '~/selectors/auth';
import { getUser } from '~/selectors/baseGetters';
import { getNotifications } from '~/services/notifications';
import { COLOR_PALETTE } from '~/styles';

import 'tippy.js/themes/light.css';

const NotificationWrapper = styled.div`
  position: absolute;
  border-radius: 10px;
  box-shadow: 3px 3px 2px 0 rgba(25, 42, 70, 0.08);
  background-color: ${COLOR_PALETTE.WHITE};
  list-style: none;
  padding: 0;
  left: ${(props) => (props.$isMenuCollapsed ? '80px' : '259px')};
  bottom: 10px;
  z-index: 9999;
`;

const Notifications = ({ onClose }) => {
  const [notifications, setNotifications] = useState([]);
  const $loading = useBoolState(true);
  const user = useSelector(getUser);
  const isMenuCollapsed = useSelector(getIsMenuCollapsed);

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      const notifications = await getNotifications();
      const notificationsSorted = orderBy(notifications, ['meta.createdDate'], ['desc']);
      if (isMounted) {
        setNotifications(notificationsSorted);
        $loading.off();
      }
    };

    fetch();

    return () => (isMounted = false);

    // eslint-disable-next-line
  }, []);

  const onNotificationDeleted = (notificationId) => {
    setNotifications(notifications.filter((n) => n.id !== notificationId));
  };

  return user ? (
    <NotificationWrapper $isMenuCollapsed={isMenuCollapsed}>
      <NotificationsDropdown
        notifications={notifications}
        onNotificationDeleted={onNotificationDeleted}
        onClose={onClose}
        loading={$loading.value}
      />
    </NotificationWrapper>
  ) : null;
};

export default Notifications;
