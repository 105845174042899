import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const GeneralBlockRow = styled.div`
  display: flex;
  margin-top: 12px;
`;

export const GeneralBlockWrapper = styled.div<{
  noTopMargin?: boolean;
}>`
  margin-top: ${({ noTopMargin }) => (noTopMargin ? '0px' : '32px')};
  margin-bottom: 32px;
`;

export const GrayTextRow = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

export const Counter = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  margin-left: 8px;

  &:hover {
    color: ${COLORS.COMPANY};
  }
`;

export const GrayText = styled.div<{
  isThin?: boolean;
  marginLeft?: string;
  marginRight?: string;
}>`
  ${(props) =>
    props.isThin &&
    css`
      font-weight: normal;
    `}

  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `}

  ${(props) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `}
    display: flex;
  align-items: center;
`;

export const GoalName = styled.div`
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

export const NameContainer = styled.div`
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
