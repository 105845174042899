import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import SvgIcon from '~/components/SvgIcon';

import RightArrowIcon from '~/assets/arrow-right.svg';
import DownArrowIcon from '~/assets/ic-keyboard-arrow-down.svg';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Wrapper = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalButton = styled(Button)`
  margin-left: 16px;
  width: 80px;
  max-width: 80px;
  min-width: 80px;
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 3px;
  border: solid 1px ${COLORS.BORDER_HARD};
  background-color: ${COLOR_PALETTE.WHITE};
  margin: 6px 0;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EditFieldsWrapper = styled.div``;

const EditFieldTitle = styled.div`
  margin-top: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: black;
`;

const StyledEditor = styled.textarea`
  margin-top: 4px;
  width: calc(100% - 24px);
  height: 110px;
  padding: 12px 12px 16px;
  border-radius: 3px;
  border: solid 1px #ccced5;
  background-color: ${COLOR_PALETTE.WHITE};

  font-size: 14px;
  line-height: 1.57;
  color: black;
`;

const LabelTitle = styled.div`
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.63;
  color: #000000;
`;

const LabelPos = styled.div`
  width: 46px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const LabelPosColor = styled.div`
  width: 58px;
  margin-right: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: ${(props) => props.$color};
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin-left: 24px;
`;

const StyledInput = styled.input`
  width: 328px;
  margin-top: 4px;
  height: 48px;
  padding: 8px 12px;
  border-radius: 3px;
  border: solid 1px #ccced5;
  background-color: ${COLOR_PALETTE.WHITE};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  letter-spacing: 0.25px;
  color: var(--grey-dark);
`;

const UpdateNinegridLabelsModal = ({ i18n, title, onClose, labels, update }) => {
  const [ninegridLabels, setNinegridLabels] = useState(cloneDeep(labels));
  const [selectedLabel, setSelectedLabel] = useState(-1);
  const positions = [
    {
      position: 'low',
      color: '#e93c3c',
    },
    {
      position: 'medium',
      color: '#ffc000',
    },
    {
      position: 'high',
      color: '#29ccab',
    },
  ];
  const save = () => {
    update(ninegridLabels);
    onClose();
  };

  const close = () => {
    setNinegridLabels(cloneDeep(labels));
    onClose();
  };

  const changeDescription = (e, i) => {
    const nextNinegridLabels = [...ninegridLabels];
    const item = nextNinegridLabels[i];
    item.description = e.target.value;
    nextNinegridLabels[i] = item;
    setNinegridLabels(nextNinegridLabels);
  };

  const changeName = (e, i) => {
    const nextNinegridLabels = [...ninegridLabels];
    const item = nextNinegridLabels[i];
    item.label = e.target.value;
    nextNinegridLabels[i] = item;
    setNinegridLabels(nextNinegridLabels);
  };

  const renderRows = () => {
    return (
      <LabelsWrapper>
        {ninegridLabels.map((label, i) => {
          return (
            <LabelWrapper key={i}>
              <TitleRow onClick={() => setSelectedLabel(selectedLabel === i ? -1 : i)}>
                <LabelTitle>{labels[i].label}</LabelTitle>
                <LabelPos>
                  <Trans>X-axis</Trans>
                </LabelPos>
                <LabelPosColor $color={positions[Math.floor(i / 3)].color}>
                  {positions[Math.floor(i / 3)].position}
                </LabelPosColor>
                <LabelPos>
                  <Trans>Y-axis</Trans>
                </LabelPos>
                <LabelPosColor $color={positions[i % 3].color}>
                  {positions[i % 3].position}
                </LabelPosColor>
                <StyledSvgIcon
                  width="14px"
                  height="14px"
                  url={selectedLabel === i ? DownArrowIcon : RightArrowIcon}
                />
              </TitleRow>
              {selectedLabel === i && (
                <EditFieldsWrapper>
                  <EditFieldTitle>
                    <Trans>Name</Trans>
                  </EditFieldTitle>
                  <StyledInput onChange={(e) => changeName(e, i)} value={label.label} />
                  <EditFieldTitle>
                    <Trans>Description</Trans>
                  </EditFieldTitle>
                  <StyledEditor
                    value={label.description}
                    onChange={(e) => changeDescription(e, i)}
                    placeholder={i18n._(t`Explain the role here...`)}
                  />
                </EditFieldsWrapper>
              )}
            </LabelWrapper>
          );
        })}
      </LabelsWrapper>
    );
  };

  return (
    <Modal
      title={title}
      onClose={close}
      footerRight={
        <FooterContainer>
          <ModalButton label={i18n._(t`Cancel`)} onClick={close} type="primary-border" />
          <ModalButton label={i18n._(t`Save`)} onClick={save} type="primary" />
        </FooterContainer>
      }
    >
      <Wrapper>{renderRows()}</Wrapper>
    </Modal>
  );
};

export default UpdateNinegridLabelsModal;
