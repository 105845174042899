import React, { useEffect, useState } from 'react';

import { IUser } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Dropdown } from '~/components/Dropdown';

import useDebounce from '~/hooks/useDebounce';
import { getCompanyUsers } from '~/services/users';
import getUserFullName from '~/utils/getUserFullName';

function MembersFilter({
  onChange,
  selectedMembers,
}: {
  onChange: (users: IUser[]) => void;
  selectedMembers: IUser[];
}) {
  const { i18n } = useLingui();
  const [members, setMembers] = useState<IUser[]>([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  const fetchMembers = async (): Promise<IUser[]> => {
    const { data } = await getCompanyUsers({
      limit: 20,
      search: debouncedSearch,
    });
    const members = data?.users ?? {};
    return Object.values(members);
  };

  useEffect(() => {
    let mounted = true;
    async function fetch() {
      const members = await fetchMembers();
      if (mounted) {
        setMembers(members);
      }
    }

    fetch();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <Dropdown
      placeholder={i18n._(t`Members`)}
      items={members}
      selectedItems={selectedMembers}
      stringifyItem={(member) => getUserFullName(member)}
      onSearchChange={(search) => setSearch(search)}
      onChange={onChange}
      isSearchable
    />
  );
}

export { MembersFilter };
