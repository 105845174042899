import React from 'react';

import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SkillsTable from './SkillsTable';

import routes from '~/constants/routes';
import { addSkillsToUser } from '~/store/auth/actions';

function SkillGapTab({ jobProfile, user, history, onSkillClick, readOnly }) {
  const dispatch = useDispatch();

  const addToPassport = async (skillId) => {
    await dispatch(addSkillsToUser([skillId]));
    alert('Skill added to passport');
  };

  const goToSkillPassport = () => {
    const path = routes.PROFILE.build({}, { isBackPath: true });
    history.push(path);
  };

  return (
    <>
      <SkillsTable
        user={user}
        jobProfile={jobProfile}
        addToPassport={addToPassport}
        // updateLevel={updateLevel}
        onOpenPassport={goToSkillPassport}
        onSkillClick={onSkillClick}
        readOnly={readOnly}
      />
    </>
  );
}

SkillGapTab.propTypes = {
  user: PropTypes.object,
  jobProfile: PropTypes.object,
  onSkillClick: PropTypes.func,
  readOnly: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    appTheme: state.appTheme.data,
    lang: state.locale.lang,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(SkillGapTab)));
