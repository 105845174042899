import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  LIBRARY_ITEM_COLLECTIONS,
  ROLES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ICONS } from '~/components/Icon';
import { ActivityModal } from '~/components/Modals/ActivityModal';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { TableList } from '~/components/TableList';

import { LEARNING_LIBRARY_COLUMNS } from './columns';
import { Filters } from './filters';
import { ILocalPath } from './types';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import {
  checkModuleLearning,
  getUser,
  isLearningActivitiesModuleEnabled,
} from '~/selectors/baseGetters';
import { getUserLibraryItems } from '~/services/activities';
import { deletePathUsers } from '~/services/paths';
import { deleteUserActivity, updateUserActivityProgress } from '~/services/userActivities';

import type { IFilterType, ILibraryItem, ILocalActivity } from './types';
import type { IUserActivity } from '@learned/types';
import type { IActivityProgressProps } from 'src/@types/activity';

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const DEBOUNCE_TIME = 300;

const LS_KEY = LS_KEYS.LS_ONBOARD_AND_LEARN_PERSONAL;

const initialFilters = {
  isShow: false,
  search: '',
  statuses: [],
  collections: [],
  pagination: DEFAULT_PAGINATION,
};

const PersonalLearningsTab = () => {
  const history = useHistory();
  const [activities, setActivities] = useState<{ [key: string]: ILocalActivity }>({});
  const [activity, setActivity] = useState<ILocalActivity | Partial<ILocalActivity> | null>(null);
  const [totalActivities, setTotalActivities] = useState<number>(0);
  const isModuleLearningEnabled = useSelector(checkModuleLearning);
  const [removeFromPlanItem, setRemoveFromPlanItem] = useState<ILibraryItem | null>(null);

  const [isShowActivityModal, setIsShowActivityModal] = useState(false);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
  const $loading = useBoolState(true);

  const user = useSelector(getUser);
  const isLearningActivities = useSelector(isLearningActivitiesModuleEnabled);
  const { i18n } = useLingui();

  // debounce filters
  const debCurrentFilters = useDebounce(currentFilters, DEBOUNCE_TIME); // to send request not immediately, but with delay

  useEffect(() => {
    if (!isModuleLearningEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleLearningEnabled, history]);

  // fetch library items (init and change of filters/pagination)
  useEffect(() => {
    let isMounted = true;

    const fetchItems = async () => {
      $loading.on();

      // @ts-ignore
      const {
        data: {
          [API_RETURN_FIELDS.USER_LIBRARY_ITEMS]: fetchedActivities,
          [API_RETURN_FIELDS.TOTAL]: fetchedTotalActivities,
        },
      } = await getUserLibraryItems({
        pagination: currentFilters.pagination,
        search: currentFilters.search,
        statuses: (currentFilters.statuses || []).map((t: any) => t.value),
        collections: (currentFilters.collections || []).map((t: any) => t.key),
      });

      if (isMounted) {
        setActivities(fetchedActivities);
        setTotalActivities(fetchedTotalActivities);
        $loading.off();
      }
    };

    fetchItems();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [debCurrentFilters, forceUpdateCounter]);

  // use this method to force update, for instance after item is deleted from library and
  const onForceFetch = () => setForceUpdateCounter(forceUpdateCounter + 1);

  const setActivityToEnrolledInState = (originalActivityId: string, isEnrolled = true) => {
    const updatedActivities = { ...activities };

    updatedActivities[originalActivityId] = { ...activities[originalActivityId], isEnrolled };

    setActivities(updatedActivities);
  };

  const createMenuItems = (item: ILibraryItem) => {
    const isActivity = item.collection === LIBRARY_ITEM_COLLECTIONS.ACTIVITIES;
    const isItemCreatedForUser = isActivity
      ? (item.owners || []).includes(user.id)
      : item.createdFor;
    const isItemAddedToPlanByUser = isItemCreatedForUser && item.createdBy === user.id;

    return [
      isItemAddedToPlanByUser && {
        label: i18n._(t`Remove from plan`),
        action: () => setRemoveFromPlanItem(item),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ].filter((i) => i);
  };

  const onSaveActivityProgress = async (
    { ...update }: IActivityProgressProps,
    userActivity?: IUserActivity,
  ) => {
    if (userActivity) {
      await updateUserActivityProgress(userActivity.id, update);
      await onForceFetch();
    }
  };

  const onRemoveFromPlan = async () => {
    const item = removeFromPlanItem;
    if (item) {
      const isActivity = item.collection === LIBRARY_ITEM_COLLECTIONS.ACTIVITIES;

      isActivity
        ? await deleteUserActivity(item.id, 'personal')
        : await deletePathUsers((item as unknown as ILocalPath).originalPath, [user.id]);

      await onForceFetch();
    }

    setRemoveFromPlanItem(null);
  };

  const filters = {
    search: currentFilters.search as string,
    setSearch: (value: string) => {
      setCurrentFilters((prevState: IFilterType) => ({
        ...prevState,
        search: value,
        pagination: DEFAULT_PAGINATION, // reset pagination
      }));
    },

    // @ts-ignore
    onChangeFilter: (key, value) =>
      setCurrentFilters({ ...currentFilters, pagination: DEFAULT_PAGINATION, [key]: value }),
    isShow: false,
    statuses: currentFilters.statuses,
    collections: currentFilters.collections,
    pagination: currentFilters.pagination,
  };

  const onNameClick = {
    column: 'name',
    onClick: (item: ILibraryItem) => {
      if (item.collection === 'paths') {
        // redirect to path dashboard
        routes.USER_PATH.go(
          { role: ROLES.USER },
          {
            pathId: item.id,
            query: {
              isLearningPage: true, // we use this to show/hide "Remove from plan" button
            },
            isBackPath: true,
          },
        );
      } else {
        // open activity modal
        setActivity(item);
        setIsShowActivityModal(true);
      }
    },
  };

  const changeFilters = async (filters: IFilterType) => {
    // reset pagination on filters change (so we always start from 1 page)
    const newFilters = { ...currentFilters, ...filters, pagination: DEFAULT_PAGINATION };

    // update state
    setCurrentFilters(newFilters);
  };

  const resetFilters = async () => {
    // do not change isShow when you reset filters
    const newFilters = { ...initialFilters, isShow: currentFilters.isShow };

    await changeFilters(newFilters);
  };

  return (
    <>
      <TableList
        data={Object.values(activities)}
        columns={LEARNING_LIBRARY_COLUMNS}
        onColClick={onNameClick}
        isDraftStatusVisible
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount: totalActivities,
        }}
        isLoading={$loading.value}
        menuProps={{
          // eslint-disable-next-line
          // @ts-ignore
          createMenuItems,
          isMenuVisible: true,
        }}
        placeholderProps={{
          noResultText: i18n._(t`No items found for you`),
          emptyStateText: i18n._(t`No items for you yet…`),
        }}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters?.search?.length,
          isToggleHideFilterVisible: true,
          filterComponents: (
            <Filters
              currentFilters={currentFilters}
              changeFilters={changeFilters}
              resetFilters={resetFilters}
              isLearningActivities={isLearningActivities}
              refetchData={onForceFetch}
              isAdmin={user?.isAdmin}
            />
          ),
        }}
      />

      {isShowActivityModal && activity && (
        <ActivityModal
          isUserActivity={true}
          isUpdateProgress={true}
          isActionButtonsVisible={false}
          isPlanningButtonsVisible={true}
          // @ts-ignore
          activityId={activity?.id}
          setActivity={setActivity}
          onClose={() => setIsShowActivityModal(false)}
          onSaveActivity={onSaveActivityProgress}
          setActivityToEnrolledInState={setActivityToEnrolledInState}
          onDeleteUserActivity={async () => onForceFetch()}
        />
      )}

      {removeFromPlanItem && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          title={i18n._(t`Remove from plan`)}
          description={
            removeFromPlanItem.collection === LIBRARY_ITEM_COLLECTIONS.ACTIVITIES
              ? i18n._(
                  t`Are you sure? Removing this activity from your list will also remove the activity from your connected goals.`,
                )
              : i18n._(
                  t`Are you sure? This will remove all your progress. This action cannot be undone.`,
                )
          }
          onClose={() => setRemoveFromPlanItem(null)}
          onSubmit={onRemoveFromPlan}
        />
      )}
    </>
  );
};

export { PersonalLearningsTab };
