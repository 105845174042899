import React, { Component, Fragment } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '~/components/Button';
import FocusAreaName from '~/components/FocusAreaName';
import Modal from '~/components/Modal';
import Radio from '~/components/Radio';
import RickTextView from '~/components/RickTextView';

import { ROLES } from '~/constants';
import { getCompanySkillCategories } from '~/services/companySettings';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkill } from '~/services/skills';
import { COLORS, COLOR_PALETTE } from '~/styles';
import getSkillLevels from '~/utils/getSkillLevels';
import sanitizeHtml from '~/utils/sanitize';

const Block = styled.div`
  margin-bottom: 24px;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  color: ${COLORS.TEXT_BLACK};
  margin: 8px 0 12px;
`;

const BulletList = styled.ul`
  margin: 0;
  padding: 0 0 0 32px;
`;

const Description = styled(RickTextView)`
font-size: 14px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.57;
letter-spacing: normal;
color: ${COLORS.TEXT_SECONDARY};

  p {
    margin: 0;
  }

  ol {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;

      li {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-0;
        list-style-type: none;
        &:before {
        content: counter(list-0, decimal) '. ';
        }
    }
  }

  ol li.ql-indent-8, .ql-indent-1, .ql-indent-2, .ql-indent-3, .ql-indent-4, .ql-indent-5, .ql-indent-6, .ql-indent-7 {
    padding-left: 24px;
    counter-increment: list-8;
    counter-reset: list-9;
    &:before {
      content: counter(list-8, lower-roman) '. ';
  }
`;

const Label = styled.span`
  color: ${COLORS.TEXT_BLACK};
  background-color: ${COLORS.BG_PAGE};
  height: 31px;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 4px;
`;

const Level = styled.div`
  margin-left: -24px;
  margin-right: -24px;
  position: relative;
  padding: 12px 60px 16px 24px;
  border-top: 1px solid ${COLORS.BORDER_LIGHT};
  background: ${(props) => (props.isSelected ? COLORS.BG_PAGE : 'none')};
  &:hover {
    background: ${(props) =>
      props.readOnly ? 'none' : props.isSelected ? COLORS.BG_PAGE : '#ecedf0'};
  }
`;

const LevelTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.21px;
  color: ${COLOR_PALETTE.DARK_GRAY_2};
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const FocusArea = styled.li`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
`;

const RadioWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LevelsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SkillLevelBlock = styled.button`
  height: 60px;
  border-radius: 6px;
  background-color: ${(props) => (props.active ? props.themColor : '#f8f8f8')};
  width: 280px;
  padding: 8px 16px;
  margin-bottom: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
  ${(props) =>
    props.active &&
    css`
      & ${SkillLevelName} {
        color: #fff;
      }
    `}
  &:hover {
    background: ${(props) => (props.isSelected ? '#b0e2d8' : '#ecedf0')};
  }
`;

const SkillLevelName = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const INITIAL_STATE = {
  loading: false,
  skill: {},
  levels: [],
  selectedLevel: null,
};

class SelectLevelModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static propTypes = {
    skill: PropTypes.object,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    selectedLevel: PropTypes.number,
    isUpdate: PropTypes.bool,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    isUpdate: false,
    readOnly: false,
  };

  componentDidMount = async () => {
    const { skill, selectedLevel, categories, company } = this.props;

    if (!isEmpty(skill)) {
      // Only fetch the skill if it is not already loaded and sent as an argument
      let skillPopulated = skill;
      let orderedCategories = [];
      if (skill.id && !skill.focusAreas) {
        skillPopulated = await getSkill(skill.id, ['focusAreas']);
      }
      if (!categories && !isEmpty(skillPopulated.categories)) {
        const [skillCategories, categoryOrder] = await Promise.all([
          getSkillCategories(),
          getCompanySkillCategories(company),
        ]);
        orderedCategories = categoryOrder.map((c) => skillCategories[c]);
      }
      this.setState({
        skill: skillPopulated,
        levels: getSkillLevels(skillPopulated),
        selectedLevel,
        ...(!isEmpty(orderedCategories) && { categories: orderedCategories }),
      });
    }
  };

  closeModal = () => {
    this.setState({ ...INITIAL_STATE });
    this.props.onClose();
  };

  selectSkillWithLevel = (e) => {
    const { isUpdate, onSubmit } = this.props;
    const { selectedLevel } = this.state;
    onSubmit(e, { level: selectedLevel, isUpdate });
    this.closeModal();
  };

  toggleLevel = (level) => {
    const { selectedLevel } = this.state;
    this.setState({ selectedLevel: level === selectedLevel ? null : level });
  };

  renderLevel = (isLevelEnabled, index) => {
    const { selectedLevel, levels } = this.state;
    const { readOnly, skillLabels } = this.props;

    const isSelected = index + 1 === selectedLevel;
    const focusAreas = levels[index + 1];

    return (
      isLevelEnabled &&
      skillLabels[index] && (
        <Level
          key={index}
          isSelected={isSelected}
          readOnly={readOnly}
          onClick={readOnly ? () => {} : () => this.toggleLevel(index + 1)}
        >
          <LevelTitle>{skillLabels[index]}</LevelTitle>
          {!isEmpty(focusAreas) && (
            <BulletList>
              {focusAreas.map((focusArea) => (
                <FocusArea key={focusArea.id || focusArea.key}>
                  <FocusAreaName focusArea={focusArea} />
                </FocusArea>
              ))}
            </BulletList>
          )}
          {!readOnly && (
            <RadioWrapper>
              <Radio checked={isSelected} size={24} />
            </RadioWrapper>
          )}
        </Level>
      )
    );
  };

  renderLevelBlock = (isLevelEnabled, index) => {
    const { themColor, readOnly, skillLabels } = this.props;
    const { selectedLevel } = this.state;
    const isSelected = index === selectedLevel;

    return (
      isLevelEnabled && (
        <SkillLevelBlock
          key={index}
          disabled={readOnly}
          active={isSelected}
          themColor={themColor}
          onClick={() => this.toggleLevel(index + 1)}
        >
          <SkillLevelName>{skillLabels[index]}</SkillLevelName>
        </SkillLevelBlock>
      )
    );
  };

  render() {
    const {
      i18n,
      readOnly,
      lang,
      submitLabel,
      title,
      currentRole,
      isShowUpdateButton,
      onUpdateButtonClick,
      categories,
    } = this.props;
    const { loading, skill, selectedLevel } = this.state;

    let definition = skill.definition;
    if (lang === 'nl' && skill.definitionNL && skill.definitionNL !== '') {
      definition = skill.definitionNL;
    }
    const userView = currentRole === ROLES.USER;
    const orderedCategories = categories || this.state.categories;
    const skillCategories =
      skill && skill.categories && !isEmpty(orderedCategories)
        ? skill.categories
            .map((c) => {
              const category = orderedCategories.find((i) => i.id === c);
              if (category) {
                return category.name;
              }
              return;
            })
            .filter((c) => c)
        : [];

    return (
      <Modal
        title={
          title ||
          (readOnly
            ? lang === 'nl' && skill.nameNL
              ? skill.nameNL
              : skill.name
            : i18n._(
                t`Select the level for ${
                  lang === 'nl' && skill.nameNL ? skill.nameNL : skill.name
                }`,
              ))
        }
        onClose={this.closeModal}
        width={625}
        minWidth={625}
        headerStyles={{
          padding: '10px 24px',
          height: '100%',
        }}
        dividerColor={COLORS.BORDER_LIGHT}
        contentStyles={{ padding: '16px 24px 0' }}
        hideFooter={readOnly && !isShowUpdateButton}
        footerRight={
          <>
            {!readOnly && (
              <Button
                type="primary-border"
                label={submitLabel || i18n._(t`Save`)}
                loading={loading}
                disabled={!selectedLevel}
                onClick={this.selectSkillWithLevel}
              />
            )}
            {isShowUpdateButton && (
              <Button type="primary-border" label={i18n._(t`Edit`)} onClick={onUpdateButtonClick} />
            )}
          </>
        }
      >
        <Block>
          <SubTitle>
            <Trans>Definition</Trans>
          </SubTitle>
          <Description dangerouslySetInnerHTML={{ __html: sanitizeHtml(definition) }} />
        </Block>
        {!isEmpty(skillCategories) && (
          <Block>
            <SubTitle>
              <Trans>Category</Trans>
            </SubTitle>
            {skillCategories.map((c, i) => (
              <Label key={i}>{c}</Label>
            ))}
          </Block>
        )}
        {userView && !readOnly ? (
          <Fragment>
            <SubTitle>
              <Trans>Select the level relevant for you</Trans>
            </SubTitle>
            <LevelsWrap>
              {!isEmpty(skill) && skill.levelsEnabled.map(this.renderLevelBlock)}
            </LevelsWrap>
          </Fragment>
        ) : (
          <Fragment>
            <SubTitle>
              <Trans>Levels</Trans>
            </SubTitle>
            {!isEmpty(skill) && skill.levelsEnabled.map(this.renderLevel)}
          </Fragment>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  currentRole: state.selected.role,
  themColor: state.appTheme.data.color,
  lang: state.locale.lang,
  skillLabels: state.companySettings.skillLabels,
  company: state.selected.company,
});

export default withI18n()(connect(mapStateToProps)(SelectLevelModal));
