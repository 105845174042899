import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Section from '~/components/GiveFeedback/components/Section';
import { getRatingBySections, getSections } from '~/components/GiveFeedback/utils';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';

import viewIcon from '~/assets/view-1.svg';

import { RATING_TYPES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getCurrentReview } from '~/selectors/baseGetters';
import getAllUsers from '~/selectors/getAllUsers';
import { getReview } from '~/services/reviews';
import * as currentRatingsActions from '~/store/currentRatings/actions';
import * as currentRequestActions from '~/store/currentRequest/actions';
import * as currentReviewActions from '~/store/currentReview/actions';
import { COLOR_PALETTE } from '~/styles';

const ViewRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  right: -200px;
`;

const ViewerText = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  margin-left: 10px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ratingType = RATING_TYPES.OTHER_RATING;

const ReviewPreviewModal = ({ onClose, reviewId }) => {
  const { i18n } = useLingui();
  const [sections, setSections] = useState([]);
  const $loading = useBoolState(true);
  const dispatch = useDispatch();
  const review = useSelector(getCurrentReview);
  const users = useSelector(getAllUsers);

  const forUser = users[review?.createdFor] || {};
  const forUserName = forUser?.firstName || forUser?.email || i18n._(t`[name member]`);

  const cleanStore = () => {
    // clean currentReview and currentRequest
    dispatch(currentReviewActions.setCurrentReview());
    dispatch(currentRequestActions.setCurrentRequest());
  };

  // init
  useEffect(() => {
    let isMount = true;

    cleanStore();

    const fetch = async () => {
      const review = await getReview(reviewId, {
        isQuestions: true,
        isInterviewee: true,
        // we don't need requests and ratings, so I do not join them
      });

      if (isMount) {
        const sections = getSections(review, ratingType, review.skillCategories);
        setSections(sections);

        // create default ratings only for preview (we do not store them in DB)
        // we need it to display correct scales in questions
        const ratings = getRatingBySections({
          sections,
          review,
          ratingType,
          user: {},
        });

        // create empty ratings for Section component
        dispatch(currentReviewActions.setCurrentReview(review));
        dispatch(currentRatingsActions.setCurrentRatings(ratings));

        $loading.off();
      }
    };

    fetch();

    return () => {
      cleanStore();
      isMount = false;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      title={i18n._(t`The questions you will ask input for`)}
      onClose={onClose}
      width={1060}
      headerActions={() => (
        <ViewRow>
          <SvgIcon
            url={viewIcon}
            width={'24px'}
            height={'14px'}
            isDefaultColor
            defaultColor={COLOR_PALETTE.DARK_GRAY}
          />
          <ViewerText>{i18n._(t`Preview`)}</ViewerText>
        </ViewRow>
      )}
    >
      <ShowSpinnerIfLoading loading={$loading.value}>
        {sections.map((section, sectionIndex) => {
          return (
            <Section
              key={sectionIndex}
              sectionCount={sections.length}
              sectionIndex={sectionIndex}
              section={section}
              ratingType={ratingType}
              skillCategories={review.skillCategories}
              goalCyclesLearningPlan={review.goalCyclesLearningPlan}
              goalCyclesBusinessPlan={review.goalCyclesBusinessPlan}
              goalCyclesLearningEval={review.goalCyclesLearningEval}
              goalCyclesBusinessEval={review.goalCyclesBusinessEval}
              {...(review.isRateWithLevel ? { skillLevels: review.skillsJobProfileLevels } : {})}
              isActive={true} // all sections are open always
              isReadOnly={true}
              requestedBy={{ firstName: forUserName }} // example userName for questions
              forUserName={forUserName} // example userName for section header (section for self/peer/coach desc)
            />
          );
        })}
      </ShowSpinnerIfLoading>
    </Modal>
  );
};

export default ReviewPreviewModal;
