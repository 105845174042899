import React from 'react';

import { Trans } from '@lingui/macro';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import IconButton from '~/components/IconButton';
import DeleteIcon from '~/components/Icons/Delete';
import StatusIcon from '~/components/StatusIcon';
import AvatarCard from '~/components/UI/AvatarCard';
import { Header6Light } from '~/components/UI/Typographics/headers';

import { REQUEST_STATUSES } from '~/constants';
import { COLOR_PALETTE } from '~/styles';

const RequestBox = styled.div`
  min-height: 56px;
  display: flex;
  align-items: center;
`;

const AvatarColumn = styled(AvatarCard)`
  flex: 1;
`;

const StatusColumn = styled(StatusIcon)`
  flex: 0 0 120px;
`;

const SourceColumn = styled.div`
  flex: 0 0 140px;
  ${Header6Light} {
    margin: 0;
  }
`;

const ActionColumns = styled.div`
  flex: 0 0 24px;
`;

const RequestItem = ({ request, onRequestDelete, isDisabled = false }) => {
  return (
    <RequestBox>
      <AvatarColumn userId={request.toUser} email={request.toEmail} type={AvatarCard.TYPES.LARGE} />
      <SourceColumn>
        {request.toEmail && (
          <Header6Light>
            <Trans>Extern</Trans>
          </Header6Light>
        )}
      </SourceColumn>
      <StatusColumn status={request.status} noBg />
      {!isDisabled && (
        <ActionColumns>
          {request.status === REQUEST_STATUSES.REQUESTED.key && (
            <IconButton
              onClick={() => onRequestDelete(request)}
              fill={false}
              color={COLOR_PALETTE.GRAY_MIDDLE}
              noBorder
              size={24}
            >
              <DeleteIcon size={20} />
            </IconButton>
          )}
        </ActionColumns>
      )}
    </RequestBox>
  );
};

RequestItem.propTypes = {
  isDisabled: PropTypes.bool,
  request: PropTypes.object.isRequired,
  onRequestDelete: PropTypes.func,
};

export default React.memo(RequestItem);
