import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import PlusIcon from '~/components/Icons/Plus';
import Modal from '~/components/Modal';
import RoleRelevancyRow from '~/components/RoleRelevancyRow';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { getCompanySettings } from '~/selectors/companySettings';
import { getRoleRelevancies } from '~/services/roleRelevancies';

const Wrapper = styled.div`
  max-height: 320px;
  height: fit-content;
  overflow: auto;
`;

const AddCategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0;
  cursor: pointer;
`;

const CategoryLabel = styled.div`
  width: 70px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #1e0863;
`;

const RoleRelevanciesModal = ({ onClose, onSubmit = () => null, companyRelevancies = [] }) => {
  const { i18n } = useLingui();
  const [relevancies, setRelevancies] = useState(companyRelevancies);
  const [error, setError] = useState(false);
  const currentCompany = useSelector(getCompanySettings);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      const fetchedRelevancies = await getRoleRelevancies(currentCompany.roleRelevancies);
      if (mounted) {
        const {
          data: { roleRelevancies },
        } = fetchedRelevancies;
        setRelevancies(Object.values(roleRelevancies));
        setLoading(false);
      }
    };
    fetch();

    return () => (mounted = false);
    // eslint-disable-next-line
  }, []);

  const save = () => {
    const newErrors = relevancies.find((i) => !i.name);
    if (!newErrors) {
      return onSubmit(relevancies);
    }
    setError(true);
  };

  return (
    <Modal
      title={i18n._(t`Edit Learning relevancy categories`)}
      onClose={onClose}
      minWidth={666}
      width={666}
      footerRight={<Button label={i18n._(t`Save`)} onClick={save} type="primary" />}
    >
      <ShowSpinnerIfLoading loading={loading}>
        <Wrapper>
          {relevancies.map((relevancy, index) => {
            return (
              <RoleRelevancyRow
                key={`${index}`}
                roleRelevancies={relevancies}
                relevancy={relevancy}
                error={error && !relevancy.name}
                relevancyIndex={index}
                onChange={setRelevancies}
              />
            );
          })}
        </Wrapper>
        <AddCategoryWrapper
          onClick={() =>
            setRelevancies([
              ...relevancies,
              {
                name: '',
              },
            ])
          }
        >
          <PlusIcon size={24} />
          <CategoryLabel>{i18n._(t`Category`)}</CategoryLabel>
        </AddCategoryWrapper>
      </ShowSpinnerIfLoading>
    </Modal>
  );
};

export default RoleRelevanciesModal;
