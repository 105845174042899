import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import Button from '~/components/Button';
import DropdownButton from '~/components/DropdownButton';
import SvgIcon from '~/components/SvgIcon';

import chevronIcon from '~/assets/ic-arrow-drop-down-down.svg';

import { COLOR_PALETTE, COLOR_SET } from '~/styles';

const StyledDropdownButton = styled(DropdownButton)`
  width: 100%;
  margin-right: 12px;
`;

const DropdownButtonLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
`;

const ButtonText = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.43;
  color: ${COLOR_PALETTE.DARK_GRAY};
  flex-grow: 1;
`;

const DropdownWrapper = styled.div`
  width: 182px;
  max-height: 300px;
  overflow: auto;
`;

const DropdownElement = styled.div`
  padding: 16px 24px;
  display: flex;
  border-radius: 6px;
  align-items: center;
  background-color: ${COLOR_PALETTE.WHITE};

  font-size: 12px;
  line-height: 1.33;
  color: #3b3b3b;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    background-color: rgba(161, 166, 180, 0.3);
  }
`;

const KPIDropdownButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  border-radius: 6;
  border: solid 1px ${COLOR_SET.LIGHT_GREY};
  background-color: ${COLOR_PALETTE.WHITE};
  margin: 0;
  &:hover {
    background-color: ${COLOR_PALETTE.WHITE};
  }
`;

const KpiDropdown = ({ items, kpi, popoverRef, placeholder, isError, onClick }) => {
  const renderContent = () => {
    return (
      <DropdownWrapper>
        {items.map((kpi) => {
          return (
            <DropdownElement
              key={kpi.id}
              onClick={() => {
                onClick(kpi);
                popoverRef.current._tippy.hide();
              }}
            >
              {kpi.name}
            </DropdownElement>
          );
        })}
      </DropdownWrapper>
    );
  };

  return (
    <StyledDropdownButton content={renderContent()} popoverRef={popoverRef} placement="bottom">
      <KPIDropdownButton
        type="white"
        buttonContentStyles={{ width: '100%' }}
        styles={{ ...(isError && { border: '1px solid red' }) }}
        label={
          <DropdownButtonLabel>
            <ButtonText>
              <Trans>{kpi.name || placeholder}</Trans>
              {!kpi && '*'}
            </ButtonText>
            <SvgIcon
              width="8px"
              height="5px"
              isDefaultColor
              defaultColor={COLOR_PALETTE.DARK_GRAY}
              url={chevronIcon}
            />
          </DropdownButtonLabel>
        }
      />
    </StyledDropdownButton>
  );
};

export { KpiDropdown };
