import React from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';

import { COLOR_PALETTE } from '~/styles';

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  li {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${COLOR_PALETTE.DARK_GRAY};
  }

  a {
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.29px;
    padding-top: 16px;
    display: block;
    padding-bottom: 20px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

function ReviewQualityCheckModal({ onClose, handleDraft, handlePublish, i18n }) {
  return (
    <Modal
      onClose={() => onClose()}
      minHeight={397}
      width={634}
      title={i18n._(t`Check review`)}
      footerRight={
        <ButtonGroup>
          <Button
            styles={{
              height: '48px',
              minWidth: '140px',
            }}
            label={i18n._(t`Save as draft`)}
            type="primary-border"
            onClick={handleDraft}
          />
          <Button
            styles={{
              height: '48px',
              marginLeft: '10px',
              minWidth: '100px',
            }}
            label={i18n._('Publish')}
            type="primary"
            onClick={handlePublish}
          />
        </ButtonGroup>
      }
    >
      <Content>
        <Text>
          <Trans>
            Please check if all settings for this review are correct. After publishing the review,
            any changes made to skills, roles or teams do not affect the review anymore.
          </Trans>
        </Text>
        <ul>
          <li>
            <Trans>Did you choose the correct role(s) per participant to review?</Trans>
          </li>
          <li>
            <Trans>Do all roles have the right skills?</Trans>
          </li>
        </ul>
        <Text>
          <Trans>If you’re not sure, save as draft and check above points.</Trans>
        </Text>
        <a
          href={
            'https://intercom.help/learned/en/articles/3950568-creating-a-review-round-as-admin'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Trans>Read more about reviews</Trans>
        </a>
      </Content>
    </Modal>
  );
}

ReviewQualityCheckModal.propTypes = {
  onClose: PropTypes.func,
  handleDraft: PropTypes.func,
  handlePublish: PropTypes.func,
};

export default withI18n()(ReviewQualityCheckModal);
