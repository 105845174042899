import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNil from 'lodash/isNil';
import styled from 'styled-components';

import DatePicker from '~/components/DatePickerDeprecated';
import Switch from '~/components/Switch';
import Tooltip from '~/components/Tooltip';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Wrapper = styled.div`
  border-radius: 6px;
  border: solid 1px #e4e8ef;
  padding: 14px 22px 24px 24px;
  margin-bottom: 16px;
  position: relative;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-bottom: 8px;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-right: 16px;
`;

const RowWithDates = styled.div`
  display: flex;
  align-items: center;
`;

const DatePickerWrapper = styled.div`
  margin-right: 25px;
`;

const Chip = styled.div`
  background-color: ${COLORS.BG_PAGE};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
  padding: 5px 11px 5px 10px;
  border-radius: 3px;
`;

const Chips = styled.div`
  display: flex;
  margin-left: 10px;
  position: absolute;
  right: 22px;
  top: 24px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  margin-left: 10px;
  right: 22px;
  top: 20px;
  font-size: 14px;

  & .switch {
    margin-right: 10px;
  }
`;

const TaskRow = ({ option, isError, children }) => {
  const { i18n } = useLingui();

  const defaultContent = (
    <RowWithDates>
      <Tooltip
        tooltip={i18n._(
          t`Task will become available 09:00 AM CET +0 or if the start date is today, immediately after the conversation is published.`,
        )}
      >
        <Label>{i18n._(t`Start`)}</Label>
      </Tooltip>
      <DatePickerWrapper>
        <DatePicker
          width={160}
          height={40}
          selected={option.startDate}
          onChange={option.onChangeStartDate}
          isValidated={!isError || Boolean(option.startDate)} // validation: required field
          minDate={new Date()} // validation: min date - today
          maxDate={new Date(option.deadline)} // validation: max date - deadline
        />
      </DatePickerWrapper>

      <Label>{i18n._(t`Deadline`)}</Label>
      <DatePickerWrapper>
        <DatePicker
          width={160}
          height={40}
          selected={option.deadline}
          onChange={option.onChangeDeadline}
          isValidated={!isError || Boolean(option.deadline)}
        />
      </DatePickerWrapper>
    </RowWithDates>
  );

  return (
    <Wrapper>
      <Title>{option.label}*</Title>
      <Description>{option.description}</Description>
      {children || defaultContent}

      {option.chips?.length > 0 && (
        <Chips>
          {option.chips.map?.((chip, i) => (
            <Chip key={i}>{chip}</Chip>
          ))}
        </Chips>
      )}

      {!isNil(option.enableSwitch) && (
        <ToggleWrapper>
          <Switch
            className="switch"
            checked={option.enableSwitch.value}
            onChange={option.enableSwitch.onClick}
            disabled={option.enableSwitch.isDisabled}
          />
          <span>{option.enableSwitch.title}</span>
        </ToggleWrapper>
      )}
    </Wrapper>
  );
};

export { TaskRow };
