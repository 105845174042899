export enum SIGNATURE_STATUSES {
  WAITING = 'waiting',
  ACTIVE = 'active',
  SIGNED = 'signed',
}

export enum SIGNATURE_VIEWS {
  USER = 'user',
  COACH = 'coach',
}

export enum REVIEW_SECTION_THEMES {
  DEFAULT = 'default',
  BUSINESS_GOALS = 'businessGoals',
  LEARNING_GOALS = 'learningGoals',
  SKILLS = 'skills',
  CAREER = 'career',
  WELL_BEING = 'wellBeing',
  AMBITIONS = 'ambitions',
}

export enum REVIEW_SECTIONS {
  NONE = 'none',
  PERFORMANCE = 'performance',
  POTENTIAL = 'potential',
  SKILLS = 'skills',
}

// if no digital sign -> create complete task only for reviews created after 23.08.2022
export const COMPLETE_REVIEW_FEATURE_START = '2022-08-23';
