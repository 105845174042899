import React, { FunctionComponent, ReactNode } from 'react';

import styled from 'styled-components';

import { COLOR_PALETTE } from '~/styles';

const HEIGHT = 22;

const Wrapper = styled.div<{ backgroundColor?: string }>`
  height: ${HEIGHT}px;
  background: ${(props) => props.backgroundColor ?? COLOR_PALETTE.DARK_GRAY};
  border-radius: 2px;
  display: flex;
  position: relative;
`;

const ArrowRight = styled.div<{ backgroundColor?: string }>`
  width: 0;
  height: 0;
  border-top: ${HEIGHT / 2}px solid transparent;
  border-bottom: ${HEIGHT / 2}px solid transparent;
  border-right: 12px solid ${(props) => props.backgroundColor ?? COLOR_PALETTE.DARK_GRAY};
  position: absolute;
  left: -11px;
`;

const Text = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.13px;
  color: #fff;
  padding: 0 6px;
  white-space: nowrap;
`;

interface IMarker {
  label: string | ReactNode;
  className?: string;
  backgroundColor?: string;
}

const Marker: FunctionComponent<IMarker> = ({ label, className, backgroundColor }) => {
  return (
    <Wrapper className={className} backgroundColor={backgroundColor}>
      <ArrowRight backgroundColor={backgroundColor} />
      <Text>{label}</Text>
    </Wrapper>
  );
};

export { Marker };
