import React, { useState, useEffect } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import FiltersHeading from '~/components/FiltersHeading';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import Tabs from '~/components/Tabs';
import ActionsContainer from '~/components/UI/ActionsContainer';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import Divider from '~/components/UI/Divider';
import TableCard from '~/components/UI/TableCard';
import TabsContainer from '~/components/UI/TabsContainer';

import LearningItem from './components/LearningItem';
import TabHeader from './components/TabHeader';

import goalsIcon from '~/assets/main-menu-icons/rocket.svg';

import { ROLES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getAppState, getSelectedRole } from '~/selectors/baseGetters';
import { getCoachDraftPaths } from '~/services/paths';
import { getPersonalUserActivities } from '~/services/userActivities';
import { getUserPaths } from '~/services/userPaths';
import * as appActions from '~/store/app/actions';
import { COLOR_PALETTE } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const TabHeaderWrapper = styled(TabHeader)`
  margin: 16px 24px;
`;

const RightBlock = styled.div`
  display: flex;
  align-items: center;
`;

const RowItem = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 32px;
  border-radius: 16px;
  ${(props) => !props.noBorder && 'box-shadow: 0 2px 4px 0 rgba(145, 157, 165, 0.12);'}
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  margin-left: 8px;
  ${(props) => {
    if (props.primary) {
      return 'background-color: var(--company-color); color: white';
    } else {
      return 'background-color: white, color: var(--company-color);';
    }
  }}
`;

const TabsWrapper = styled(Tabs)`
  margin-bottom: 0;
  border: none;
`;

const TabsRow = styled.div`
  > div {
    width: 100%;
  }
`;

const TABS_ENUM = {
  active: 'active',
  completed: 'completed',
};

const LearningTab = ({
  i18n,
  user,
  isFetchAgain,
  showLearningActivityModal,
  createLearningPath,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [currentTab, setCurrentTab] = useState(urlParams.get('tab') || TABS_ENUM.active);
  const [learningItems, setLearningItems] = useState([]);
  const [filteredLearningItems, setFilteredLearningItems] = useState([]);
  const $loading = useBoolState(true);
  const app = useSelector(getAppState);
  const userId = user.id;
  const dispatch = useDispatch();
  const currentRole = useSelector(getSelectedRole);
  const userName = getUserFullName(user);

  useEffect(() => {
    const fetchData = async () => {
      const [userPathsData, userActivities, coachDrafts] = await Promise.all([
        getUserPaths(userId),
        getPersonalUserActivities({ userId }),
        currentRole !== ROLES.ADMIN && getCoachDraftPaths(),
      ]);
      const userPaths = !isEmpty(userPathsData)
        ? userPathsData?.data[API_RETURN_FIELDS.USER_PATHS]
        : {};
      const paths = Object.values({ ...coachDrafts, ...userPaths }).map((path) => {
        return {
          ...path,
          collection: 'path',
        };
      });
      const combinedPathsActivities = paths.concat(Object.values(userActivities));
      setLearningItems(Object.values(combinedPathsActivities));
      setFilteredLearningItems(Object.values(combinedPathsActivities));
      $loading.off();
    };
    fetchData();

    if (app.isAssignedUserToPath) {
      dispatch(appActions.updateApp({ isAssignedUserToPath: false }));
    }

    // eslint-disable-next-line
  }, [userId, app, isFetchAgain]);
  const activeFilters = (value) => value.progress !== 100;
  const completedFilters = (value) => value.progress === 100;

  const TABS = [
    {
      label: t`Active (${learningItems.filter(activeFilters).length})`,
      key: TABS_ENUM.active,
    },
    {
      label: t`Completed (${learningItems.filter(completedFilters).length})`,
      key: TABS_ENUM.completed,
    },
  ];

  const handleChangeFilter = (filter) => {
    setFilteredLearningItems(learningItems.filter(filter));
  };

  const handleChangeTab = (key) => {
    setCurrentTab(key);
  };

  const cols = [
    { title: i18n._(t`Name`), width: 'auto' },
    { width: '80px' },
    { title: i18n._(t`Type`), width: '150px' },
    { title: i18n._(t`Progress`), width: '180px' },
  ];

  const prepareTab = (activeTab) => {
    const tabs = {
      active: {
        items: filteredLearningItems.filter(activeFilters),
      },
      completed: {
        items: filteredLearningItems.filter(completedFilters),
      },
    };
    return tabs[activeTab];
  };

  const selectedTab = prepareTab(currentTab);

  const TabsRightBlock = (
    <RightBlock>
      {showLearningActivityModal && (
        <RowItem>
          <StyledButton primary onClick={showLearningActivityModal}>
            {i18n._(t`+ Learning Activity`)}
          </StyledButton>
        </RowItem>
      )}
      {createLearningPath && (
        <RowItem>
          <StyledButton primary onClick={createLearningPath}>
            {i18n._(t`+ Learning Path`)}
          </StyledButton>
        </RowItem>
      )}
    </RightBlock>
  );

  return (
    <BoxWithBorder>
      <TabHeaderWrapper
        title={i18n._(t`Learning`)}
        description={i18n._(t`An overview of all ${userName} learning paths and activities`)}
      />
      <Divider $color={COLOR_PALETTE.GRAY_MIDDLE} />
      <TabsRow>
        <TabsContainer noBorder>
          <TabsWrapper
            items={TABS}
            isSmall
            currentItem={currentTab}
            handleChangeTab={handleChangeTab}
            rightBlock={TabsRightBlock}
          />
        </TabsContainer>
      </TabsRow>
      <ActionsContainer noBorders>
        <FiltersHeading onFilterChange={handleChangeFilter} isLeftSide />
      </ActionsContainer>
      <TableCard
        cols={cols}
        items={selectedTab.items}
        renderRow={(item) => (
          <LearningItem key={item.id} item={item} userId={userId} superViewMode />
        )}
        loading={$loading.value}
        hideHeader
        noBorder
        noMarginBottom
        firstPlaceholder={
          <Placeholder
            title={i18n._(t`No paths and activities`)}
            subTitle={i18n._(t`All your paths and activities will show here`)}
            Icon={() => (
              <SvgIcon
                url={goalsIcon}
                width="32px"
                height="32px"
                isDefaultColor={true}
                defaultColor="#cadafc"
              />
            )}
          />
        }
      />
    </BoxWithBorder>
  );
};

export default withI18n()(LearningTab);
