import React, { useState, useEffect } from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import JobProfileModal from '~/components/Modals/JobProfileModal';

import useBoolState from '~/hooks/useBoolState';
import { getUserCareerPlans } from '~/services/careerPlans';
import { COLOR_PALETTE, COLOR_SET } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Role = styled.div`
  min-height: 26px;
  background: ${COLOR_SET.TURQUOISE};
  padding: 0 9px 0 7px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
  white-space: break-spaces;
  cursor: pointer;

  // text
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};

  &:last-child {
    margin-right: 0;
  }
`;

const Roles = ({ requestedBy }) => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const $isJobProfileModal = useBoolState(false);

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      const items = await getUserCareerPlans(requestedBy.id, {
        status: CAREER_PLAN_STATUSES.CURRENT,
      });

      if (isMounted && !isEmpty(items)) {
        setRoles(Object.values(items));
      }
    };

    fetch();

    return () => (isMounted = false);
    // eslint-disable-next-line
  }, []);

  const openModal = (role) => {
    setSelectedRole(role);
    $isJobProfileModal.on();
  };

  const closeModal = () => {
    $isJobProfileModal.off();
    setSelectedRole(null);
  };

  return (
    <Wrapper>
      {roles.map((role, key) => (
        <Role key={key} onClick={() => openModal(role)}>
          {role.name}
        </Role>
      ))}
      {$isJobProfileModal.value && (
        <JobProfileModal
          onClose={closeModal}
          userId={requestedBy}
          jobProfileId={selectedRole.jobProfile}
          jobProfileName={selectedRole.name}
        />
      )}
    </Wrapper>
  );
};

export default Roles;
