import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

const d =
  'M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const StarBorderIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} viewBox="0 0 20 20" {...props}>
    {(color) => (
      <g transform="translate(-2, -1.5)" fill={color}>
        <path d={d} />
      </g>
    )}
  </InlineSvg>
);

StarBorderIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

StarBorderIcon.defaultProps = {
  size: 24,
  className: '',
};

export default StarBorderIcon;
