import React, { useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';

import Modal from '~/components/Modal';
import Content from '~/pages/RolePage/Content';

import useBoolState from '~/hooks/useBoolState';
import { getJobProfile } from '~/services/jobProfiles';

function JobProfilesToggleModal({ userId, onClose, jobProfileId, jobProfileName }) {
  const [jobProfile, setJobProfile] = useState({});
  const $loading = useBoolState(true);

  useEffect(() => {
    let isMounted = true;

    // this logic from RolePage useEffect
    async function retrieveData() {
      try {
        // Download job profile, focus areas and like for this job profile.
        const [jp] = await Promise.all([getJobProfile(jobProfileId, ['skills'])]);

        // Determine highest level for each skill
        if (!isEmpty(jp.skills)) {
          jp.skills.forEach((skill) => {
            skill.maxLevel = skill.levelsEnabled.filter((l) => l).length;
            skill.numberOfEnabledLevels = [];
            skill.levelsEnabled.forEach((l, i) => {
              if (l) {
                skill.numberOfEnabledLevels = [...skill.numberOfEnabledLevels, i + 1];
              }
            });
          });
        }

        // Save data in local state
        if (isMounted) {
          setJobProfile(jp);
        }
      } finally {
        if (isMounted) {
          $loading.off();
        }
      }
    }

    retrieveData();

    return () => (isMounted = false);
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      title={jobProfileName}
      onClose={onClose}
      width={1000}
      contentStyles={{ padding: 0 }}
      hideFooter
    >
      <Content userId={userId} jobProfile={jobProfile} />
    </Modal>
  );
}

JobProfilesToggleModal.propTypes = {
  onClose: PropTypes.func,
  userId: PropTypes.string.isRequired,
};

export default React.memo(JobProfilesToggleModal);
