import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckIcon from '~/components/Icons/Check';
import Tooltip from '~/components/Tooltip';

import { COLORS } from '~/styles';

const Box = styled.div`
  background: ${COLORS.BG_PAGE};
  color: ${COLORS.TEXT_SECONDARY};
  opacity: ${(props) => (props.$isEnabled ? 1 : 0.5)};
  line-height: 1;
  display: flex;
  align-items: center;
  min-height: 26px;
  padding: 4px;
  box-sizing: border-box;
  margin: 4px 8px 4px 0;
  border: ${(props) => (props.errorNotSelected ? `1px solid ${COLORS.ERROR}` : 'none')};
  svg {
    margin-left: 4px;
    min-width: 14px;
  }
  cursor: pointer;
`;

const BoxError = styled(Box)`
  color: ${COLORS.ERROR};
  border: 1px solid ${COLORS.ERROR};
`;

const ProfileBox = ({ name, error, errorNotSelected, isEnabled }) => {
  return error ? (
    <Tooltip
      maxWidth="300px"
      tooltip={
        <Trans>
          This role profile does not contain any skills. Each person requires to have a role profile
          with skills in order to create this review. Save this review and go to settings - roles in
          order to add skills to this role profile.
        </Trans>
      }
    >
      <BoxError>{name}</BoxError>
    </Tooltip>
  ) : (
    <Tooltip
      maxWidth="300px"
      disabled={!errorNotSelected}
      tooltip={
        <Trans>
          This participant does not have a active role profile. Each participant requires to have a
          an active role profile with skills in order to create this review. Save this review and go
          to settings - roles in order to add this participant to a role.
        </Trans>
      }
    >
      <Box $isEnabled={isEnabled} errorNotSelected={errorNotSelected}>
        {name} {isEnabled && <CheckIcon fill="var(--company-color)" size={16} />}
      </Box>
    </Tooltip>
  );
};

ProfileBox.propTypes = {
  name: PropTypes.string,
  error: PropTypes.bool,
  errorNotSelected: PropTypes.bool,
  isEnabled: PropTypes.bool,
};

export default ProfileBox;
