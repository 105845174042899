import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-transform: capitalize;
`;

const TASKS_STATUSES = {
  COMPLETED: 'completed',
  EXPIRED: 'expired',
};

const TaskStatus = ({ status }) => {
  const { i18n } = useLingui();

  const statuses = {
    [TASKS_STATUSES.COMPLETED]: i18n._(t`completed`),
    [TASKS_STATUSES.EXPIRED]: i18n._(t`expired`),
  };

  return <Wrapper>{statuses[status]}</Wrapper>;
};

TaskStatus.STATUSES = TASKS_STATUSES;

TaskStatus.propTypes = {
  value: PropTypes.oneOf(Object.values(TASKS_STATUSES)),
};

export default TaskStatus;
