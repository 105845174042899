import { css } from 'styled-components';

import { Header3 } from '~/components/UI/Typographics/headers';

import { COLORS } from '~/styles';

export const ListItemStyles = css`
  border-bottom: solid 1px ${COLORS.BG_PAGE};
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  ${Header3} {
    margin: 0;
  }
  &:hover {
    position: relative;
    box-shadow: 0 -1px 2px -2px rgba(0, 0, 0, 0.2), 0 3px 2px -2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;
