import React, { useState, useEffect } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Button from '~/components/Button';
import SetupFlow from '~/components/SetupFlow';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import SetupRoleCreate from '~/pages/RoleCreatePage/Steps/SetupRoleCreate';
import SetupRoleSkills from '~/pages/RoleCreatePage/Steps/SetupRoleSkills';

import { INSTRUCTIONS } from '~/constants/instructions';
import {
  createJobProfiles,
  getJobProfile,
  updateJobProfile,
  updateSkillsOfJobProfile,
} from '~/services/jobProfiles';
import { createSkill, updateSkill } from '~/services/skills';
import { COLOR_PALETTE } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

const ContentHeaderWrapper = styled.div`
  margin: 24px;
`;

const ContentHeaderHeader = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.82;
  color: #000000;
`;

const ContentHeaderContent = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ROLE_CREATE = {
  setup: 'setup',
  skills: 'skills',
};

const errors = {
  noName: {
    title: t`No name`,
    content: t`Give your role a name`,
  },
  noDescription: {
    title: t`No description`,
    content: t`Give your role a description`,
  },
};

const useRoleState = (roleId) => {
  const [name, setName] = useState('');
  const [alias, setAlias] = useState('');
  const [description, setDescription] = useState('');
  const [skills, setSkills] = useState([]);
  const [skillLevels, setSkillLevels] = useState({});
  const [careerLevel, setCareerLevel] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [coverImage, setCoverImage] = useState('');

  useEffect(() => {
    const fetchRole = async () => {
      const roleProfile = await getJobProfile(roleId, ['skills']);
      setCoverImage(roleProfile.coverImage);
      setName(roleProfile.name);
      setAlias(roleProfile.alias);
      setDescription(roleProfile.description);
      setSkills(roleProfile.skills);
      setSkillLevels(roleProfile.skillsLevels);
      setCareerLevel(roleProfile.careerLevel);
      setEducationLevel(roleProfile.educationLevel);
    };
    if (roleId) {
      fetchRole();
    }
  }, [roleId]);

  return {
    getName: name,
    setName,
    getAlias: alias,
    setAlias,
    getDescription: description,
    setDescription,
    getSkills: skills,
    setSkills,
    getSkillLevels: skillLevels,
    setSkillLevels,
    getCareerLevel: careerLevel,
    setCareerLevel,
    getEducationLevel: educationLevel,
    setEducationLevel,
    getCoverImage: coverImage,
    setCoverImage,
  };
};

const steps = [
  {
    key: ROLE_CREATE.setup,
    title: (i18n) => i18n._(t`Set-up the role`),
    component: SetupRoleCreate,
  },
  {
    key: ROLE_CREATE.skills,
    title: (i18n) => i18n._(t`Select skills`),
    component: SetupRoleSkills,
  },
];

const RoleCreatePage = ({
  i18n,
  match: {
    params: { roleId },
  },
}) => {
  const history = useHistory();
  const role = useRoleState(roleId);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    const fetchName = async () => {
      const profile = await getJobProfile(roleId, ['skills']);
      setName(profile.name);
    };
    if (roleId) {
      fetchName();
    }
  }, [roleId]);

  const onClose = () => {
    history.goBack();
  };

  const showToastMessage = (error, type) => {
    addToast({
      title: i18n._(error.title),
      subtitle: i18n._(error.content),
      type,
    });
  };

  const onPublish = async () => {
    if (!role.getName) {
      return showToastMessage(errors.noName, TOAST_TYPES.ERROR);
    }
    if (!role.getDescription) {
      return showToastMessage(errors.noDescription, TOAST_TYPES.ERROR);
    }
    let newRole;
    const updateOrCreate = {
      name: role.getName,
      alias: role.getAlias,
      description: role.getDescription,
      careerLevel: role.getCareerLevel || 1,
      educationLevel: role.getEducationLevel || 1,
      coverImage: role.getCoverImage,
    };

    setLoading(true);
    if (roleId) {
      newRole = await updateJobProfile(roleId, updateOrCreate);
    } else {
      newRole = await createJobProfiles(updateOrCreate);
    }

    // Create and map the new skills
    const processedSkills = await Promise.all(
      role.getSkills.map(async (skill) => {
        // Create a skill if it's new, update it if it already exists and was changed
        if (skill.isNew) {
          // Create focus areas, and new skill.
          const createdSkill = await createSkill({
            skill: {
              definition: skill.definition,
              name: skill.name,
              source: skill.source,
              levelsEnabled: skill.levelsEnabled,
              categories: skill.categories,
              aliases: skill.aliases,
            },
            focusAreas: skill.focusAreas,
          });

          if (createdSkill) {
            // Replace key in skillLevels with the id from the created skill
            const oldKeySkillLevel = role.getSkillLevels[skill.id];
            if (oldKeySkillLevel) {
              role.getSkillLevels[createdSkill.id] = oldKeySkillLevel;
              delete role.getSkillLevels[skill.id];
            }

            return createdSkill;
          }
          return {};
        } else if (skill.isUpdateNeeded) {
          return await updateSkill({ skill, focusAreas: skill.focusAreas }, ['focusAreas']);
        } else {
          return skill;
        }
      }),
    );

    const roleWithSkills = {
      ...newRole,
      skills: processedSkills.filter((skill) => skill.id).map((skill) => ({ id: skill.id })),
      skillsLevels: role.getSkillLevels,
    };
    await updateSkillsOfJobProfile(newRole.id, roleWithSkills, true);
    setLoading(false);
    history.goBack();
  };

  const getHeaderActions = () => {
    return (
      <>
        <Button
          loading={loading}
          label={i18n._(t`${roleId ? 'Save' : 'Create'}`)}
          onClick={onPublish}
        />
      </>
    );
  };

  return (
    <SetupFlow
      setupLayoutWidth="1070px"
      steps={steps}
      noTopMargin
      onClose={onClose}
      item={role}
      headerInstructions={i18n._(t`How roles work`)}
      headerInstructionsUrl={getInstructionUrl(INSTRUCTIONS.CREATING_ROLES)}
      title={roleId ? name : i18n._(t`Roles`)}
      isHidePublishButton
      headerActions={getHeaderActions}
      contentHeader={
        <ContentHeaderWrapper>
          <ContentHeaderHeader>
            {roleId ? <Trans>Update role</Trans> : <Trans>Create role</Trans>}
          </ContentHeaderHeader>
          <ContentHeaderContent>
            {roleId ? <Trans>Update a role profile</Trans> : <Trans>Create a role profile</Trans>}
          </ContentHeaderContent>
        </ContentHeaderWrapper>
      }
    />
  );
};

export default withI18n()(RoleCreatePage);
