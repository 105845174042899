import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment/moment';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import WarningIcon from '~/components/Icons/Warning';
import AvatarCard from '~/components/UI/AvatarCard';

import { COLOR_PALETTE, COLOR_SET } from '~/styles';

const CompleteInfoRow = styled.div`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${COLOR_PALETTE.GRAY_SOFT};
  padding-left: 16px;
  padding-right: 58px;
`;

const Info = styled.div`
  display: flex;
`;

const AgreeWarning = styled.div`
  display: flex;
  align-items: center;
  margin-right: 33px;
`;

const AgreeLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  color: ${COLOR_SET.ORANGE2};
  margin-left: 2px;
`;

const Status = styled.div`
  width: 100px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.25px;
  color: ${(props) => (props.$isCompleted ? COLOR_SET.CYAN_GREEN : COLOR_SET.BLUE)};
`;

const CompleteDate = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const UserItem = ({ userId, isCompleted, isShowAgree, isAgree, dateOfComplete } = {}) => {
  const { i18n } = useLingui();

  return (
    <CompleteInfoRow key={userId}>
      <AvatarCard userId={userId} type={AvatarCard.TYPES.MEDIUM} styles={{ padding: 0 }} />
      <Info>
        {isShowAgree && !isAgree && (
          <AgreeWarning>
            <WarningIcon size={24} fill={COLOR_SET.ORANGE2} />
            <AgreeLabel>{i18n._(t`Not agree with content!`)}</AgreeLabel>
          </AgreeWarning>
        )}
        <div>
          <Status $isCompleted={isCompleted}>
            {isCompleted ? i18n._(t`Completed`) : i18n._(t`To do`)}
          </Status>
          {dateOfComplete && (
            <CompleteDate>{moment(dateOfComplete).format('DD-MM-YYYY HH:mm')}</CompleteDate>
          )}
        </div>
      </Info>
    </CompleteInfoRow>
  );
};

UserItem.propTypes = {
  userId: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool,
  isShowAgree: PropTypes.bool,
  isAgree: PropTypes.bool,
  dateOfComplete: PropTypes.string,
};

export default React.memo(UserItem);
