import styled from 'styled-components';

import { COLORS } from '~/styles';

export const StyledLabel = styled.label<{
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
}>`
  height: 20px;
  border-radius: 3px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.13px;
  font-size: 12px;
  padding: 1px 6px 0px;
  text-align: center;
  color: ${({ disabled, color }) => (disabled ? COLORS.SUBTEXT : color)};
  background-color: ${({ disabled, backgroundColor }) =>
    disabled ? COLORS.INACTIVE : backgroundColor};
  border: solid 1px ${({ disabled, color }) => (disabled ? COLORS.SUBTEXT : color)};
  position: relative;
`;

export const Background = styled.div<{
  disabled?: boolean;
  color: string;
  backgroundByPercentage: number;
}>`
  position: absolute;
  color: ${({ disabled, color }) => (disabled ? COLORS.SUBTEXT : color)};
  background-color: ${({ disabled, color }) => (disabled ? COLORS.SUBTEXT : color)};
  opacity: ${({ backgroundByPercentage }) => backgroundByPercentage / 100};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
