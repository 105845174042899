import { createAction } from 'redux-act';

import { getCurrentCompanyId } from '~/selectors/baseGetters';
import {
  addCompanyLangs,
  removeCompanyLang,
  updateCompany,
  updateCompanyPrimaryLang,
} from '~/services/companies';

export const finishGetCompanies = createAction('finish get companies');
export const finishGetCompany = createAction('finish get company');

export const setCompanyNinegridLabels = createAction('Update company ninegrid labels');

export function setCompanyLabels(ninegridLabels) {
  return async (dispatch, getState) => {
    const companyId = getCurrentCompanyId(getState());
    await updateCompany(companyId, { ninegridLabels });
    dispatch(setCompanyNinegridLabels({ companyId, ninegridLabels }));
  };
}

export const setCompanyPrimaryLang = createAction('Update companies primary language');
export const removeLang = createAction('Remove language from company');
export const addLangs = createAction('Add languages to company');
export const addPrimaryLang = createAction('Add language to company, and as primary language');
export const updateProductSettings = createAction('Update company product settings');
export const updateProducts = createAction('Update company products');

export function setCompanyPrimaryLanguage(lang) {
  return async (dispatch, getState) => {
    const companyId = getCurrentCompanyId(getState());
    await updateCompanyPrimaryLang(companyId, lang);
    dispatch(setCompanyPrimaryLang({ companyId, lang }));
  };
}

export function removeCompanyLanguage(lang) {
  return async (dispatch, getState) => {
    const companyId = getCurrentCompanyId(getState());
    await removeCompanyLang(companyId, lang);
    dispatch(removeLang({ companyId, lang }));
  };
}

export function addCompanyLanguages(langs) {
  return async (dispatch, getState) => {
    const companyId = getCurrentCompanyId(getState());
    await addCompanyLangs(companyId, langs);
    dispatch(addLangs({ companyId, langs }));
  };
}

export function addCompanyPrimaryLanguage(lang) {
  return async (dispatch, getState) => {
    const companyId = getCurrentCompanyId(getState());
    await updateCompanyPrimaryLang(companyId, lang);
    dispatch(addPrimaryLang({ companyId, lang }));
  };
}

export function updateCompanyProductSettings(name, settings) {
  return async (dispatch, getState) => {
    const companyId = getCurrentCompanyId(getState());
    dispatch(updateProductSettings({ companyId, name, settings }));
  };
}

export function updateCompanyProducts(products) {
  return async (dispatch, getState) => {
    const companyId = getCurrentCompanyId(getState());
    dispatch(updateProducts({ companyId, products }));
  };
}
