export enum USER_INTEGRATIONS_TYPES {
  azure = 'azure',
  google = 'google',
}

export interface IUserIntegrationCalendar {
  hasOnlineMeetingProvider: boolean;
  updatedAt: Date;
}

export interface IUserIntegration {
  createdFor: string;
  token: string;
  refreshToken: string;
  expiresIn: Date;
  refreshTokenExpiresIn: Date;
  type: USER_INTEGRATIONS_TYPES;
  isRevoked: boolean;
  isDeleted: boolean;
  isSubscribed: boolean;
  id: string;
  email: string;
  syncStatus?: USER_INTEGRATION_SYNC_STATUS | null;

  // Google variables for notifications
  syncToken?: string;
  channelId?: string;
  resourceId?: string;
  securityToken?: string;
  // Azure/Google default calendar
  calendar?: IUserIntegrationCalendar;
}

export enum USER_INTEGRATION_SYNC_STATUS {
  DISCONNECTING = 'disconnecting',
  CONNECTING = 'connecting',
  SYNCHRONIZING = 'synchronizing',
}
