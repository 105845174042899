import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isSectionHasPlanQuestion } from '~/pages/ReviewSetup/helpers';

import Toggler from './Toggler';

import { REVIEW_OPTIONS } from '~/constants';
import { COLORS } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  border: ${(props) => (props.$isShowErrors ? `1px solid ${COLORS.ERROR}` : 'none')};
`;

const ReviewTypesToggler = ({ section, onClick, isShowErrors }) => {
  const { i18n } = useLingui();
  const TYPES = [
    {
      key: REVIEW_OPTIONS.IS_SELF_REVIEW.key,
      label: (i18n) => i18n._(t`Self review`),
      isEnabled: section[REVIEW_OPTIONS.IS_SELF_REVIEW.key],
      tooltip: i18n._(
        t`If enabled Self is asked to provide input for the questions in this section`,
      ),
    },
    {
      key: REVIEW_OPTIONS.IS_USERS_REVIEW.key,
      label: (i18n) => i18n._(t`Peer review`),
      isEnabled: section[REVIEW_OPTIONS.IS_USERS_REVIEW.key],
      tooltip: i18n._(
        t`If enabled Peers are asked to provide input for the questions in this section`,
      ),
    },
    {
      key: REVIEW_OPTIONS.IS_COACHES_REVIEW.key,
      label: (i18n) => i18n._(t`Coach review`),
      isEnabled: section[REVIEW_OPTIONS.IS_COACHES_REVIEW.key],
      tooltip: i18n._(
        t`If enabled Coaches are asked to provide input for the questions in this section`,
      ),
    },
  ];

  const isError =
    isShowErrors &&
    !section[REVIEW_OPTIONS.IS_SELF_REVIEW.key] &&
    !section[REVIEW_OPTIONS.IS_USERS_REVIEW.key] &&
    !section[REVIEW_OPTIONS.IS_COACHES_REVIEW.key];

  return (
    <Wrapper $isShowErrors={isError}>
      {TYPES.map((item) => (
        <Toggler
          key={item.key}
          item={item}
          onClick={onClick}
          isTooltipDisabled={
            isSectionHasPlanQuestion(section) && item.key === REVIEW_OPTIONS.IS_USERS_REVIEW.key
          }
          isDisabled={
            isSectionHasPlanQuestion(section) && item.key === REVIEW_OPTIONS.IS_USERS_REVIEW.key
          }
        />
      ))}
    </Wrapper>
  );
};

ReviewTypesToggler.propTypes = {
  section: PropTypes.object,
  onClick: PropTypes.func,
  isShowErrors: PropTypes.bool,
};

export default ReviewTypesToggler;
