import React, { memo } from 'react';

import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FeedbackDeadline from '~/components/FeedbackDeadline';
import { TemplateCard } from '~/components/SetupTools/components/TemplateBaseComponents';
import AvatarCard from '~/components/UI/AvatarCard';
import { Header2, Header4Light } from '~/components/UI/Typographics/headers';

import Roles from './Roles';

import { RATING_TYPES } from '~/constants';
import { checkModuleIsShowRolesInReview } from '~/selectors/baseGetters';

const Card = styled(TemplateCard)`
  margin-bottom: 24px;
  padding: 17px 25px 23px 20px;
`;

const Headline = styled.div`
  display: flex;
  align-items: center;
  ${Header2}, ${Header4Light} {
    margin: 0;
  }
  ${Header2} {
    margin-right: 20px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
  }
`;

const DeadlineBlock = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const AvatarBlock = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: flex-start;

  h5 {
    font-weight: 600;
  }
`;

const Bold = styled.span`
  font-weight: 600;
`;

const RolesWrapper = styled.div`
  margin-left: 20px;
`;

const ReviewIntroduction = ({ ratingType, name, deadline, requestedBy }) => {
  const isShowRolesInReview = useSelector(checkModuleIsShowRolesInReview);

  const getHeader = () => {
    let ratingTypeName = '';
    const title = `${requestedBy?.firstName || ''}'s ${name}`;

    switch (ratingType) {
      case RATING_TYPES.SELF_RATING: {
        ratingTypeName = (
          <>
            <Trans>Prepare for your</Trans> <Bold>{name}</Bold>
          </>
        );
        break;
      }
      case RATING_TYPES.OTHER_RATING:
      case RATING_TYPES.COACH_RATING: {
        ratingTypeName = (
          <>
            <Trans>Provide input for</Trans> <Bold>{title}</Bold>
          </>
        );
        break;
      }
      case RATING_TYPES.OUTSIDE_RATING: {
        ratingTypeName = (
          <>
            <Trans>Provide input for</Trans> <Bold>{title}</Bold>
          </>
        );
        break;
      }
      default:
        break;
    }
    return ratingTypeName;
  };

  return (
    <Card>
      <Headline>
        <Header2>{getHeader()}</Header2>
        <DeadlineBlock>
          <FeedbackDeadline deadline={deadline} />
        </DeadlineBlock>
      </Headline>
      <AvatarBlock>
        <AvatarCard
          type={AvatarCard.TYPES.MEDIUM}
          userId={requestedBy.id}
          size={28}
          styles={{ padding: 0 }}
        />
        {isShowRolesInReview && ratingType !== RATING_TYPES.OUTSIDE_RATING && (
          <RolesWrapper>
            <Roles requestedBy={requestedBy} />
          </RolesWrapper>
        )}
      </AvatarBlock>
    </Card>
  );
};

export default memo(ReviewIntroduction);
