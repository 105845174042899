import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import SkillCategoriesList from './SkillCategoriesList';

import useBoolState from '~/hooks/useBoolState';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import {
  getCompanySkillCategories,
  updateCompanySkillCategories,
} from '~/services/companySettings';
import { getSkillCategories, updateSkillCategory } from '~/services/skillCategories';
import { COLOR_PALETTE } from '~/styles';
import getDuplicateObjectsInArray from '~/utils/getDuplicateObjectsInArray';

import Divider from '../UI/Divider';

const SaveButton = styled(Button)`
  display: flex;
  align-self: flex-end;
`;

const ColumnLabelsDivider = styled(Divider)`
  margin-bottom: 30px;
`;

const ColumnsLabels = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const LabelText = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: ${COLOR_PALETTE.DARK_GRAY};
  width: ${(props) => props.width};
`;

function EditSkillCategoriesModal({ onClose, i18n }) {
  const [categories, setCategories] = useState([]);
  const [categoryErrors, setCategoryErrors] = useState([]);
  const $loading = useBoolState(true);
  const company = useSelector(getCurrentCompany);
  const { addToast } = useToasts();

  // categories with updated name
  const [updatedCategoriesIds, setUpdatedCategoriesIds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const [skillCategories, categoryOrder] = await Promise.all([
        getSkillCategories(),
        getCompanySkillCategories(company.id),
      ]);
      const orderedCategories = categoryOrder.map((c) => skillCategories[c]);
      setCategories(orderedCategories);
      $loading.off();
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const showToast = (title, message, type = TOAST_TYPES.INFO) => {
    addToast({
      title,
      subtitle: message,
      type,
    });
  };

  const checkErrors = () => {
    const duplicates = getDuplicateObjectsInArray(categories, 'name');
    const emptyName = categories.some((c) => isEmpty(c.name));
    const hasErrors = duplicates.length > 0 || emptyName;

    const errors = categories
      .filter((x) => {
        if (duplicates.includes(x.name) || isEmpty(x.name)) {
          return true;
        }
        return false;
      })
      .map((x) => {
        return x.id;
      });

    setCategoryErrors(errors);

    if (hasErrors) {
      let errorMessage = '';

      if (duplicates.length > 0) {
        errorMessage += i18n._(t`Skill category names must be unique. `);
      }
      if (emptyName) {
        errorMessage += i18n._(t`Skill category names cannot be empty.`);
      }

      showToast(
        i18n._(t`No valid input for skill category names`),
        errorMessage,
        TOAST_TYPES.ERROR,
      );
    }

    return errors.length > 0;
  };

  const onSubmit = async () => {
    $loading.on();
    const hasErrors = checkErrors();
    if (!hasErrors) {
      if (!isEmpty(updatedCategoriesIds)) {
        // update category name
        await Promise.all(
          updatedCategoriesIds.map(async (cId) => {
            const category = categories.find((c) => c.id === cId);
            await updateSkillCategory(category.id, { name: category.name });
          }),
        );
      }
      // update order, create new categories and delete categories
      await updateCompanySkillCategories(company.id, categories);
      showToast(
        i18n._(t`Skill category succesfully updated`),
        i18n._(t`The skill category names are succesfully updated`),
      );
      $loading.off();
      onClose();
    } else {
      $loading.off();
    }
  };

  const onChange = (updatedCategories) => setCategories(updatedCategories);

  return (
    <Modal
      title={i18n._(t`Edit skill categories`)}
      onClose={onClose}
      width={675}
      minWidth={462}
      minHeight="200px"
      contentStyles={{
        background: '#fff',
        height: '100%',
        padding: '12px 25px 24px',
      }}
      headerStyles={{ padding: '0 23px' }}
      hideFooter={true}
    >
      <ColumnsLabels>
        <LabelText width="60px">
          <Trans>ORDER</Trans>
        </LabelText>
        <LabelText width="190px">
          <Trans>DEFAULT LABEL</Trans>
        </LabelText>
        <LabelText>
          <Trans>CUSTOM LABEL</Trans>
        </LabelText>
      </ColumnsLabels>
      <ColumnLabelsDivider />
      <SkillCategoriesList
        skillCategories={categories}
        onChange={onChange}
        addUpdatedCategory={(id) => setUpdatedCategoriesIds([...updatedCategoriesIds, id])}
        errors={categoryErrors}
      />
      <SaveButton
        width={83}
        height={48}
        label={i18n._(t`Save`)}
        type="primary"
        onClick={onSubmit}
        loading={$loading.value}
      />
    </Modal>
  );
}

export default withI18n()(EditSkillCategoriesModal);
