import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Header4 } from '~/components/UI/Typographics/headers';

import { SkillCards } from './SkillCards';

import { COLORS } from '~/styles';

const SkillsSection = styled.div`
  padding: 16px 20px;
  box-sizing: border-box;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDER_HARD};
  margin: 24px;
`;

const StyledHeader4 = styled(Header4)`
  margin: 0 0 4px;
`;

function PerSkillSection({ rates, jobProfile, skillCategories, onSkillClick }) {
  return (
    <SkillsSection>
      <StyledHeader4>
        <Trans>Per skill</Trans>
      </StyledHeader4>
      {skillCategories.map((category) => (
        <SkillCards
          rates={rates}
          key={category.id}
          jobProfileSkills={jobProfile.skills}
          category={category}
          onSkillClick={onSkillClick}
        />
      ))}
    </SkillsSection>
  );
}

PerSkillSection.propTypes = {
  jobProfile: PropTypes.object.isRequired,
  skillCategories: PropTypes.array,
};

export { PerSkillSection };
