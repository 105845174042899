import { createAction } from 'redux-act';
export const setJobProfile = createAction('set job profile');
export const setJobProfiles = createAction('finish set job profiles');
export const updateSkillsToJobProfile = createAction('set jobprofiles');
export const removeJobProfiles = createAction('finish remove jobProfiles');

export const startUpdateJobProfiles = createAction('start update jobProfiles');
export const finishUpdateJobProfiles = createAction('finish update jobProfiles');

import * as jobProfilesService from '~/services/jobProfiles';

/*
 * data example [[JobProfile1Id, updateData1{}], [JobProfile2Id, updateData2{}], ...]
 * */
export function updateJobProfiles(data) {
  return function (dispatch) {
    dispatch(startUpdateJobProfiles());
    return jobProfilesService.updateJobProfiles(data).then((jobProfiles) => {
      dispatch(finishUpdateJobProfiles(jobProfiles));
      return jobProfiles;
    });
  };
}
