import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { TableList } from '~/components/TableList';
import UserDevelopmentCycleModal from '~/components/UserDevelopmentCycleModal';
import type { IFilterType } from '~/pages/OnboardAndLearn/tabs/AllLearningsTab/types';

import { COLUMNS, SORT_OPTIONS } from './columns';
import { Filters } from './filters';

import { REVIEW_STATUSES, ROLES } from '~/constants';
import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { getUser } from '~/selectors/baseGetters';
import { getReviews } from '~/services/reviews';

import type { IReview } from '@learned/types';
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_DEVELOPMENT_CONVERSATION_CYCLE;

const initialFilters = {
  isShowFilters: false,
  search: '',
  createdInRole: null,
  sortBy: SORT_OPTIONS.NAME_A_Z,
  selectedDateOption: undefined,
  selectedStatus: null,
  selectedCoaches: [],
  pagination: DEFAULT_PAGINATION,
};

const ConversationCycleTab = () => {
  const { i18n } = useLingui();
  const history = useHistory();
  const user = useSelector(getUser);
  const [items, setItems] = useState<IReview[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewYearModal, setIsPreviewYearModal] = useState(false);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch

  // redirect if no permission
  useEffect(() => {
    if (!user.isAdmin) {
      history.push(routes.HOME);
    }

    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    setIsLoading(true);

    const {
      data: { reviews: items, total },
      // @ts-ignore
    } = await getReviews({
      filters: {
        onlyOriginalReviews: true,
        ...(currentFilters.createdInRole && { createdIn: currentFilters.createdInRole }),
      },
      isGrouped: false,

      // filters
      search: currentFilters.search || null,
      users: currentFilters.selectedCoaches.length
        ? currentFilters.selectedCoaches.map((i: { id: string }) => i.id)
        : null,
      ...(currentFilters.selectedDateOption
        ? {
            startDate: currentFilters.selectedDateOption.fromDate,
          }
        : {}),
      ...(currentFilters.selectedDateOption
        ? {
            endDate: currentFilters.selectedDateOption.toDate,
          }
        : {}),

      // options
      skip: currentFilters.pagination.skip,
      limit: currentFilters.pagination.limit,
      order: currentFilters.sortBy,
      ...(currentFilters.selectedStatus && {
        status: currentFilters.selectedStatus,
      }),
    });

    setItems(Object.values(items));
    setTotalCount(total);
    setIsLoading(false);
  };

  // change filters fetch
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters)]);

  const actionButton = {
    label: t`Create cycle`,
    onClick: () => {
      history.push(
        routes.REVIEW_CREATE.build(
          {
            role: ROLES.ADMIN,
            companyId: undefined,
            teamId: undefined,
          },
          {
            isBackPath: true,
          },
        ),
      );
    },
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) =>
      setCurrentFilters((prevState: IFilterType) => ({
        ...prevState,
        search: value,
        pagination: DEFAULT_PAGINATION, // reset pagination
      })),

    // @ts-ignore
    onChangeFilter: (key, value) =>
      setCurrentFilters((state: any) => ({
        ...state,
        pagination: DEFAULT_PAGINATION,
        [key]: value,
      })),
    resetFilters: () => setCurrentFilters(initialFilters),
    selectedStatus: currentFilters.selectedStatus,
    selectedDateOption: currentFilters.selectedDateOption,
    selectedCoaches: currentFilters.selectedCoaches,
    createdInRole: currentFilters.createdInRole,
  };

  const onItemClick = {
    column: 'name',
    onClick: (item: any) => {
      const path =
        item.status === REVIEW_STATUSES.DRAFT.key
          ? routes.REVIEW_UPDATE.build(
              { companyId: undefined, teamId: undefined, role: ROLES.ADMIN },
              // @ts-ignore
              { reviewId: item.id, isBackPath: true },
            )
          : routes.REVIEW.build(
              { companyId: undefined, teamId: undefined, role: ROLES.ADMIN },
              // @ts-ignore
              { reviewId: item.id, isBackPath: true },
            );
      history.push(path as string);
    },
  };

  return (
    <Wrapper>
      <TableList
        data={items}
        columns={COLUMNS}
        onColClick={onItemClick}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: SORT_OPTIONS) => setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        isDraftStatusVisible
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={isLoading}
        placeholderProps={{
          noResultText: i18n._(t`No conversations found`),
          emptyStateText: i18n._(t`No conversations yet… Let’s create one!`),
        }}
        actionButton={actionButton}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length,
          isToggleHideFilterVisible: true,
          resetFilters: filters.resetFilters,
          filterComponents: <Filters filters={filters} />,
        }}
      />

      {isPreviewYearModal && (
        <UserDevelopmentCycleModal onClose={() => setIsPreviewYearModal(false)} />
      )}
    </Wrapper>
  );
};

export { ConversationCycleTab };
