import { ICompany, IConnection } from '@learned/types';

export const getMultiLangString = (
  objectToGetStringFrom: { [key: string]: string } | string,
  connection: IConnection,
  company: ICompany,
  isRequired = true, // isRequired is false when field can be returned as empty string, otherwise true
  defaultLang = 'en_GB',
): string => {
  if (typeof objectToGetStringFrom === 'string') {
    return objectToGetStringFrom;
  }
  const { preferredLang: pref } = connection;
  const { languages: langs, primaryLang: primary } = company;
  const preferredLang = pref?.locale;
  const languages = langs.map((lang) => lang.locale);
  const primaryLang = primary?.locale;

  const mainLangs = [
    ...(preferredLang && languages.includes(preferredLang) ? [preferredLang] : []),
    ...[primaryLang],
    ...[defaultLang],
    ...languages.filter((l) => ![primaryLang, preferredLang, defaultLang].includes(l)),
  ].filter((i) => i);

  mainLangs.forEach((lang) => {
    if (
      objectToGetStringFrom[lang] !== undefined &&
      (isRequired ? objectToGetStringFrom[lang]?.trim() : objectToGetStringFrom[lang])
    ) {
      return objectToGetStringFrom[lang];
    }
  });

  let translation = '';
  let isTranslated = false;

  mainLangs.forEach((lang) => {
    if (
      !isTranslated &&
      objectToGetStringFrom[lang] !== undefined &&
      (isRequired ? objectToGetStringFrom[lang]?.trim() : objectToGetStringFrom[lang])
    ) {
      isTranslated = true;
      translation = objectToGetStringFrom[lang];
    }
  });

  if (!isTranslated) {
    Object.values(objectToGetStringFrom).forEach((potentialString) => {
      if (!isRequired && !isTranslated) {
        isTranslated = true;
        translation = potentialString;
      }
      if (potentialString.trim() && !isTranslated) {
        isTranslated = true;
        translation = potentialString;
      }
    });
  }

  return translation;
};
