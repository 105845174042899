import React from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import Switch from '~/components/Switch';
import { Block, Text, Title } from '~/pages/ReviewTemplateSetup/components/StyledComponents';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .rc-switch {
    margin-right: 0;
    margin-left: 14px;
  }
`;

const CommentMandatorySetup = ({ onChange, isObligated }) => (
  <Block>
    <Title>
      <Trans>Comment</Trans>
    </Title>
    <Row>
      <Text>
        <Trans>Mandatory</Trans>
      </Text>
      <Switch onChange={onChange} checked={isObligated} />
    </Row>
  </Block>
);

export default withI18n()(CommentMandatorySetup);
