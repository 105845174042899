import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, SURVEY_SORT_OPTIONS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { FilterSurveyStatus } from '~/components/AutocompleteFilters/Survey';
import { Dropdown } from '~/components/Dropdown';
import { TableList } from '~/components/TableList';

import { USER_SURVEYS_COLUMNS } from './userSurveyColumns';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { ISurveyType, SURVEY_TYPE_WITH_NAMES } from '~/constants/survey';
import { USER_SURVEY_STATUS_WITH_NAMES } from '~/constants/userSurveys';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { getUserSurveys } from '~/services/userSurveys';

import type { IUserSurveyPopulated } from '@learned/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_SURVEYS_PERSONAL;

const initialFilters = {
  isShowFilters: false,
  search: '',
  statuses: [
    USER_SURVEY_STATUS_WITH_NAMES.ACTIVE,
    USER_SURVEY_STATUS_WITH_NAMES.CONTINUE,
    USER_SURVEY_STATUS_WITH_NAMES.COMPLETED,
  ],
  types: [],
  sortBy: SURVEY_SORT_OPTIONS.END_DATE_SOON_LATER,
  pagination: DEFAULT_PAGINATION,
};

const UserSurveysOverview = () => {
  const { i18n } = useLingui();
  const [surveys, setSurveys] = useState<IUserSurveyPopulated[] | []>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const { data } = await getUserSurveys(
        {
          search: currentFilters.search,
          statuses: currentFilters.statuses.map((s: { key: string }) => s.key),
          types: currentFilters.types.map((s: { key: string }) => s.key),
        },
        {
          sortBy: currentFilters.sortBy,
          skip: currentFilters.pagination.skip,
          limit: currentFilters.pagination.limit,
        },
      );

      setSurveys(data[API_RETURN_FIELDS.USER_SURVEYS]);
      setTotalCount(data[API_RETURN_FIELDS.TOTAL]);
      setIsLoading(false);
    };

    fetchData();

    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters)]);

  const onStatusClick = {
    column: 'status',
    onClick: (item: IUserSurveyPopulated) =>
      void routes.SURVEY_TASK.go(
        { role: ROLES.USER },
        { surveyTaskId: item.surveyTaskId, isBackPath: true },
      ),
  };

  const onNameClick = (userSurvey: IUserSurveyPopulated) => {
    routes.USER_SURVEY_DASHBOARD.go(
      { role: ROLES.USER },
      { userSurveyId: userSurvey.id, isBackPath: true },
    );
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value, pagination: DEFAULT_PAGINATION });
    },

    // @ts-ignore
    onChangeFilter: (key, value) => setCurrentFilters({ ...currentFilters, [key]: value }),
    resetFilters: () => setCurrentFilters(initialFilters),
    statuses: currentFilters.statuses,
    types: currentFilters.types,
  };

  return (
    <Wrapper>
      <TableList
        data={surveys}
        columns={USER_SURVEYS_COLUMNS}
        onRowClick={onNameClick}
        onColClick={onStatusClick}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: SURVEY_SORT_OPTIONS) =>
            setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        isDraftStatusVisible
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={isLoading}
        placeholderProps={{
          noResultText: i18n._(t`No surveys found for you`),
          emptyStateText: i18n._(t`No surveys for you yet…`),
        }}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length || !!currentFilters.statuses.length,
          isToggleHideFilterVisible: true,
          resetFilters: filters.resetFilters,
          filterComponents: (
            <>
              <FilterSurveyStatus
                selectedItems={filters.statuses}
                onChange={(value) => filters.onChangeFilter('statuses', value)}
                type="user-survey"
              />
              <Dropdown
                placeholder={i18n._(t`Type`)}
                items={Object.values(SURVEY_TYPE_WITH_NAMES)}
                selectedItems={filters.types}
                onChange={(value: ISurveyType[]) => filters.onChangeFilter('types', value)}
                stringifyItem={(item) => item.translated(i18n)}
              />
            </>
          ),
        }}
      />
    </Wrapper>
  );
};

export { UserSurveysOverview };
