import React from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { TextArea } from '~/components/Text';
import AdjustRatingScaleBlock from '~/pages/ReviewTemplateSetup/components/AdjustRatingScaleBlock';
import { AverageQuestionSetup } from '~/pages/ReviewTemplateSetup/components/AverageQuestionSetup';

import CommentMandatorySetup from './CommentMandatorySetup';
import Ratings from './Ratings';

import { COLORS } from '~/styles';

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    width: 470px;
  }
`;

const SkillLabel = styled.div`
  padding: 11px;
  display: flex;
  border-radius: 3px;
  border: solid 1px #dae4f7;
`;

const QuestionName = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${COLORS.TEXT_SECONDARY};
  border-radius: 3px;
  background-color: ${COLORS.BG_PAGE};
  padding: 8px;
  width: 100%;
`;

const StyledTextArea = styled(TextArea)`
  height: 48px;
  margin-bottom: 12px;
`;

const GoalEvalQuestion = ({
  question,
  isSkippable,
  toggleIsSkippable,
  toggleIsCommentObligated,
  isCommentObligated,
  toggleIsAverageQuestionEnabled,
  isAverageQuestionEnabled,
  updateName,
  isShowErrors,
  deleteScaleLabel,
  updateScaleLabel,
  sectionScaleLabels,
  addScaleLabel,
  isNoneSectionType,
  isCollapsed,
}) => {
  const { i18n } = useLingui();

  return (
    <>
      <Row>
        <StyledTextArea
          value={question.name || ''}
          onChange={(e) => updateName(e.target.value)}
          placeholder={i18n._(t`Give an explanation that helps answering this question`)}
          $accent
          resize
          rows="1"
          error={isShowErrors && !question.name}
        />
      </Row>
      <Row>
        <QuestionName>
          {question.type === QUESTION_TYPES.GOAL_BUSINESS_EVAL ? (
            <Trans>
              A separate question will be generated for the business goals of each participant for
              the selected goal cycle. The goal cycle can be selected in setting up the review.
            </Trans>
          ) : (
            <Trans>
              A separate question will be generated for the learning goals of each participant for
              the selected goal cycle. The goal cycle can be selected in setting up the review.
            </Trans>
          )}
        </QuestionName>
      </Row>
      {!isCollapsed && (
        <>
          <AverageQuestionSetup
            isAverageQuestionEnabled={isAverageQuestionEnabled}
            toggleIsAverageQuestionEnabled={toggleIsAverageQuestionEnabled}
          />
          {!question.hideRating &&
            (isNoneSectionType ? (
              <AdjustRatingScaleBlock
                scaleLabels={question.scaleLabels}
                onDelete={deleteScaleLabel}
                onChange={updateScaleLabel}
                addScaleLabel={addScaleLabel}
                toggleIsSkippable={toggleIsSkippable}
                isSkippable={isSkippable}
                isShowErrors={isShowErrors}
              />
            ) : (
              <Ratings>
                {sectionScaleLabels.map((label) => (
                  <SkillLabel key={label}>{label}</SkillLabel>
                ))}
              </Ratings>
            ))}
          <CommentMandatorySetup
            isObligated={isCommentObligated}
            onChange={toggleIsCommentObligated}
          />
        </>
      )}
    </>
  );
};

export default React.memo(GoalEvalQuestion);
