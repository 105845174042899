import React from 'react';

import { INTEGRATION_STATUS } from '@learned/constants';
import { I18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Loader } from '~/components/Buttons/components/Loader';
import { ICON_SIZES, Icon, ICONS } from '~/components/Icon';
import Label from '~/components/Label';

import { COLORS } from '~/styles';

type IStatusType = {
  [key: string]: {
    key: INTEGRATION_STATUS;
    text: (i18n: I18n) => string;
    icon?: ICONS;
    color: string;
    backGroundColor: string;
  };
};

export const USER_LIBRARY_STATUSES: IStatusType = {
  [INTEGRATION_STATUS.ACTIVE]: {
    key: INTEGRATION_STATUS.ACTIVE,
    text: (i18n: I18n) => i18n._(t`Active`),
    color: COLORS.TODO_BLUE,
    backGroundColor: COLORS.TODO_BLUE_LIGHT,
  },
  [INTEGRATION_STATUS.INACTIVE]: {
    key: INTEGRATION_STATUS.INACTIVE,
    text: (i18n: I18n) => i18n._(t`Inactive`),
    icon: ICONS.WARNING_2,
    color: COLORS.ACCENT_ERROR,
    backGroundColor: COLORS.ACCENT_ERROR_LIGHT,
  },
};

const StyledDiv = styled(Label)`
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  margin-right: 10px;
`;

const StyledIcon = styled(Icon)<{
  rotate?: number;
  color: string;
}>`
  box-sizing: border-box;
  margin-right: 5px;
  transform: rotate(${({ rotate }) => (rotate ? rotate : 0)}deg);
`;

const LoaderWrapper = styled.div`
  display: flex;

  & > div {
    color: ${COLORS.TOOLTIP_BACKGROUND};
  }
`;

const LoaderLabel = styled.span`
  font-size: 12px;
  color: ${COLORS.LABEL_GRAY};
`;

type LabelProps = {
  status: INTEGRATION_STATUS;
  isLoading?: boolean;
};

const StatusLabel = ({ status, isLoading }: LabelProps) => {
  const { i18n } = useLingui();
  const item = USER_LIBRARY_STATUSES[status];

  if (!item) {
    return null;
  }

  return isLoading ? (
    <LoaderWrapper>
      <Loader />
      <LoaderLabel>
        <Trans>Testing...</Trans>
      </LoaderLabel>
    </LoaderWrapper>
  ) : (
    <StyledDiv color={item.color} backgroundColor={item.backGroundColor}>
      {item.icon && <StyledIcon icon={item.icon} size={ICON_SIZES.SMALL} color={item.color} />}
      {item.text(i18n)}
    </StyledDiv>
  );
};

export { StatusLabel };
