import { NEXT_STEP_TYPES } from '@learned/constants';

import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

export const getRelevantSteps = ({ targetId, type = NEXT_STEP_TYPES.CONVERSATION }) => {
  return cloudRequest(serverRoutes.nextSteps.getRelevantSteps(targetId), { type });
};

export const createStepForConversation = ({ conversationId, comment, participant }) => {
  return cloudRequest(
    serverRoutes.nextSteps.createStepForConversation(conversationId),
    {},
    { comment, participant },
  );
};
export const createStep = ({ targetId, type, comment, participant }) => {
  return cloudRequest(
    serverRoutes.nextSteps.createStep,
    {},
    { targetId, type, comment, participant },
  );
};

export const switchCompletedState = ({ stepId, isCompleted }) => {
  return cloudRequest(serverRoutes.nextSteps.switchCompletedState(stepId), {}, { isCompleted });
};

export const copyToNextConversations = ({ stepId, nextConversationId }) => {
  return cloudRequest(
    serverRoutes.nextSteps.copyToNextConversations(stepId),
    {},
    { nextConversationId },
  );
};

export const deleteStep = (stepId) => {
  return cloudRequest(serverRoutes.nextSteps.deleteStep(stepId));
};

export const fetchNextSteps = ({ onlyActiveStepsCount, filters, options }) => {
  return cloudRequest(
    serverRoutes.nextSteps.fetchNextSteps,
    { onlyActiveStepsCount },
    { filters, options },
  );
};
