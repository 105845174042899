import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Placeholder from '~/components/Placeholder';
import StringInfinite from '~/components/StringInfinite';
import SvgIcon from '~/components/SvgIcon';
import { SearchField } from '~/components/Text';

import ReviewIcon from '~/assets/main-menu-icons/star.svg';

import { REVIEW_LOGS } from '~/constants';
import { getReviewLogs } from '~/services/reviews';
import { COLOR_PALETTE, COLORS } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  min-height: 62px;
  border-bottom: 1px solid ${COLORS.BORDER_HARD};
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.21px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-transform: uppercase;
`;

const Content = styled.div`
  margin-bottom: 24px;
  max-height: 400px;
  overflow: auto;
`;

const Log = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  min-height: 56px;
  border-bottom: 1px solid ${COLORS.BG_PAGE};
`;

const LogText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const LogDate = styled(LogText)`
  width: 180px;
`;

const LogCreator = styled(LogText)`
  width: 180px;
  margin-right: 120px;
`;

const LogComment = styled(LogText)``;

function ReviewLogs({ i18n, reviewId }) {
  const [logs, setLogs] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const logs = await getReviewLogs(reviewId);

      if (!isEmpty(logs)) {
        // add creator and user name here
        // and use it in search
        const logsWithNames = orderBy(logs, ['meta.createdDate'], ['desc']).map((log) => {
          return {
            ...log,
            creatorName: get(log, 'createdBy'),
            userName: get(log, 'data.participantFullName'),
          };
        });

        setLogs(logsWithNames);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const renderRow = (log, index) => {
    const createDate = get(log, 'meta.createdDate');
    const createDateFormatted = convertToTimeString(createDate, TIME_FORMATS.CLASSIC_FULL);

    let comment = '';

    switch (log.type) {
      case REVIEW_LOGS.CREATE_USER_REVIEW:
        comment = `Added participant: ${log.userName}.`;
        break;
      case REVIEW_LOGS.DELETE_USER_REVIEW:
        comment = `Removed participant: ${log.userName}.`;
        break;
      default:
        break;
    }

    return (
      <Log key={index}>
        <LogDate>{createDateFormatted}</LogDate>
        <LogCreator>
          <StringInfinite>{log.creatorName}</StringInfinite>
        </LogCreator>
        <LogComment>{comment}</LogComment>
      </Log>
    );
  };

  let filteredLogs = logs;

  if (search) {
    filteredLogs = filteredLogs.filter((item) => {
      // search in user name
      return item.userName && item.userName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
  }

  return (
    <>
      <Header>
        <Title>{i18n._(t`Activity`)}</Title>
        <SearchField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={i18n._(t`Search participant`)}
        />
      </Header>
      <Content>
        {isEmpty(filteredLogs) ? (
          <Placeholder
            title={i18n._(t`No logs`)}
            subTitle={i18n._(t`Review logs will show here.`)}
            Icon={() => (
              <SvgIcon
                url={ReviewIcon}
                width={'50px'}
                height={'50px'}
                isDefaultColor
                defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              />
            )}
          />
        ) : (
          filteredLogs.map(renderRow)
        )}
      </Content>
    </>
  );
}

ReviewLogs.propTypes = {
  reviewId: PropTypes.string.isRequired,
};

export default withI18n()(ReviewLogs);
