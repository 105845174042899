/* User getInstructionUrl() to get instructions in pages
 * for instance:
 * getInstructionUrl(INSTRUCTIONS.SYNC_TEAMS)
 * */

export const INSTRUCTIONS = {
  HOW_REVIEWS_WORK_USER: 'how_reviews_work_user',
  HOW_GOALS_WORK_USER: 'how_goals_work_user',
  HOW_TO_MANAGE_MEMBERS_ADMIN: 'how_to_manage_members_admin',
  INVITING_MANAGING_MEMBERS: 'invite_managaging_members',
  HOW_TEAMS_WORK_ADMIN: 'how_teams_work_admin',
  HOW_CONVERSATIONS_WORK_USER: 'how_conversations_work_user',
  HOW_CONVERSATIONS_WORK_COACH: 'how_conversations_work_coach',
  DESIGNING_COMPANY_PROFILE_SETTINGS: 'designing_company_profile_settings',
  COMPANY_SECURITY: 'company_security',
  CREATING_SKILL_SET: 'creating_skill_set',
  SYNC_MEMBERS: 'sync_members',
  SYNC_TEAMS: 'sync_teams',
  SYNC_ROLES: 'sync_roles',
  CLEANUP_TEAMS: 'cleanup_teams',
  CLEANUP_ROLES: 'cleanup_roles',
  CLEANUP_MEMBERS: 'cleanup_members',
  HOW_TO_COACH: 'how_to_coach',
  HOW_TO_PROFILE_WORKS: 'how_to_profile_works',
  HOW_LEARNING_WORKS_COACH: 'how_learning_works_coach',
  HOW_GOALS_WORK_COACH: 'how_goals_work_coach',
  HOW_GOALS_WORK_ADMIN: 'how_goals_work_admin',
  HOW_GOAL_UPDATES_WORK: 'how_goal_updates_work',
  HOW_FEEDBACK_CYCLE_WORK: 'how_feedback_cycle_work',
  HOW_TO_CREATE_A_GOAL: 'how_to_create_a_goal',
  CREATING_MANAGAGING_TEAMS: 'creating_managing_teams',
  CREATING_ROLES: 'creating_roles',
  HOW_CAREER_PLAN_WORKS: 'role_library',
  LEARNED_API_INTEGRATIONS: 'learned_api_integrations',
  BUILDING_REVIEWS_TEMPLATES: 'building_review_templates',
  BUILDING_REVIEWS_TEMPLATES_1_ON_1: 'building_review_templates_1_on_1',
  DESIGN_IDENTIY: 'design_identity',
  MANAGING_MEMBERS: 'managing_members',
  HOW_LEARNING_WORKS_ADMIN: 'how_learning_works_admin',
  HOW_PATHS_WORK_ADMIN: 'how_paths_work_admin',
  CREATE_REVIEW_ADMIN: 'create_review_admin',
  CREATE_REVIEW_COACH: 'create_review_coach',
  HOW_LEARNING_WORKS_USER: 'how_learning_works_user',
  HOW_NINE_GRID_WORKS_ADMIN: 'how_nine_grid_works_admin',
  HOW_GOAL_CYCLE_WORKS: 'how_goal_cycle_works',
  HOW_DO_GOALS_WORK: 'how_do_goals_work',
  HOW_SKILL_REPORT_WORKS: 'how_skill_report_works',
  HOW_PERFORMANCE_REPORT_WORKS: 'how_performance_report_works',
  HOW_CONVERSATION_REPORT_WORKS: 'how_conversation_report_works',
  HOW_MFA_WORKS: 'how_mfa_works',
  HOW_SURVEYS_WORK: 'how_surveys_work',
  HOW_TO_SETUP_MULTIPLE_LANGUAGES_WITHIN_COMPANY_ACCOUNT:
    'how_to_setup_multiple_languages_within_company_account',
  HOW_TO_CREATE_SURVEY_THEME: 'how_to_create_survey_theme',
  REPORTS_ABOUT_SECTION_DIMENSIONS: 'reports_about_section_dimensions',
  REPORTS_ABOUT_SECTION_FILTERS: 'reports_about_section_filters',
};

export const INSTRUCTION_URLS = {
  EN: [
    {
      key: INSTRUCTIONS.HOW_REVIEWS_WORK_USER,
      value: 'https://intercom.help/learned/en/articles/3665887-how-does-a-review-round-work',
    },
    {
      key: INSTRUCTIONS.HOW_GOALS_WORK_USER,
      value: 'https://intercom.help/learned/en/articles/5639737-learn-how-to-work-with-goals',
    },
    {
      key: INSTRUCTIONS.HOW_TO_MANAGE_MEMBERS_ADMIN,
      value: 'https://intercom.help/learned/en/articles/3665730-how-to-create-a-business-goal',
    },
    {
      key: INSTRUCTIONS.HOW_TEAMS_WORK_ADMIN,
      value: 'https://intercom.help/learned/en/articles/3950876-creating-and-managing-teams',
    },
    {
      key: INSTRUCTIONS.DESIGNING_COMPANY_PROFILE_SETTINGS,
      value:
        'https://intercom.help/learned/en/articles/3950514-designing-the-company-profile-settings-corporate-identity',
    },
    {
      key: INSTRUCTIONS.COMPANY_SECURITY,
      value: 'https://intercom.help/learned/en/articles/5025127-en-security',
    },
    {
      key: INSTRUCTIONS.CREATING_SKILL_SET,
      value: 'https://intercom.help/learned/en/articles/5609374-learn-how-to-work-with-skills',
    },
    {
      key: INSTRUCTIONS.SYNC_MEMBERS,
      value: 'https://intercom.help/learned/en/articles/4921781-the-learned-api-integrations',
    },
    {
      key: INSTRUCTIONS.SYNC_TEAMS,
      value: 'https://intercom.help/learned/en/articles/4921781-the-learned-api-integrations',
    },
    {
      key: INSTRUCTIONS.SYNC_ROLES,
      value: 'https://intercom.help/learned/en/articles/4921781-the-learned-api-integrations',
    },
    {
      key: INSTRUCTIONS.CLEANUP_TEAMS,
      value: 'https://intercom.help/learned/en/articles/4921781-the-learned-api-integrations',
    },
    {
      key: INSTRUCTIONS.CLEANUP_ROLES,
      value: 'https://intercom.help/learned/en/articles/4921781-the-learned-api-integrations',
    },
    {
      key: INSTRUCTIONS.CLEANUP_MEMBERS,
      value: 'https://intercom.help/learned/en/articles/4921781-the-learned-api-integrations',
    },
    {
      key: INSTRUCTIONS.INVITING_MANAGING_MEMBERS,
      value: 'https://intercom.help/learned/en/articles/3950800-inviting-and-managing-members',
    },
    {
      key: INSTRUCTIONS.HOW_TO_COACH,
      value:
        'https://intercom.help/learned/en/articles/3950217-how-do-i-manage-my-team-members-as-a-coach',
    },
    {
      key: INSTRUCTIONS.HOW_TO_PROFILE_WORKS,
      value:
        'https://intercom.help/learned/nl/articles/3950230-aan-de-slag-met-je-persoonlijke-skill-profielr',
    },
    {
      key: INSTRUCTIONS.HOW_CONVERSATIONS_WORK_USER,
      value: 'https://intercom.help/learned/en/articles/5639653-how-do-conversations-work',
    },
    {
      key: INSTRUCTIONS.HOW_LEARNING_WORKS_COACH,
      value:
        'https://intercom.help/learned/en/articles/5028247-how-do-you-assign-a-learning-path-as-a-coach',
    },
    {
      key: INSTRUCTIONS.HOW_GOALS_WORK_COACH,
      value:
        'https://intercom.help/learned/en/articles/3665845-how-to-work-with-business-goals-as-a-coach',
    },
    {
      key: INSTRUCTIONS.CREATING_MANAGAGING_TEAMS,
      value: 'https://intercom.help/learned/en/articles/3950876-creating-and-managing-teams',
    },
    {
      key: INSTRUCTIONS.CREATING_ROLES,
      value:
        'https://intercom.help/learned/en/articles/3950928-creating-role-profiles-in-the-company-profile',
    },
    {
      key: INSTRUCTIONS.LEARNED_API_INTEGRATIONS,
      value: 'https://intercom.help/learned/en/articles/4921781-the-learned-api-integrations',
    },
    {
      key: INSTRUCTIONS.BUILDING_REVIEWS_TEMPLATES,
      value:
        'https://intercom.help/learned/en/articles/4710573-building-review-templates-for-review-conversations',
    },
    {
      key: INSTRUCTIONS.BUILDING_REVIEWS_TEMPLATES_1_ON_1,
      value:
        'https://intercom.help/learned/en/articles/5609379-learn-how-to-create-a-1-1-conversation-template',
    },
    {
      key: INSTRUCTIONS.DESIGN_IDENTIY,
      value:
        'https://intercom.help/learned/en/articles/3950514-designing-the-company-profile-settings-corporate-identity',
    },
    {
      key: INSTRUCTIONS.MANAGING_MEMBERS,
      value: 'https://intercom.help/learned/en/articles/3950800-inviting-and-managing-members',
    },
    {
      key: INSTRUCTIONS.HOW_GOALS_WORK_ADMIN,
      value:
        'https://intercom.help/learned/en/articles/3950641-working-with-business-goals-as-admin',
    },
    {
      key: INSTRUCTIONS.HOW_LEARNING_WORKS_ADMIN,
      value: 'https://intercom.help/learned/en/articles/3951151-adding-content-to-the-library',
    },
    {
      key: INSTRUCTIONS.HOW_PATHS_WORK_ADMIN,
      value: 'https://intercom.help/learned/en/articles/4273459-how-to-create-learning-paths',
    },
    {
      key: INSTRUCTIONS.CREATE_REVIEW_ADMIN,
      value: 'https://intercom.help/learned/en/articles/5639729-learn-how-to-work-with-reviews',
    },
    {
      key: INSTRUCTIONS.CREATE_REVIEW_COACH,
      value:
        'https://intercom.help/learned/en/articles/5028790-creating-and-conducting-formal-conversations-reviews-as-a-coach',
    },
    {
      key: INSTRUCTIONS.HOW_CONVERSATIONS_WORK_COACH,
      value:
        'https://intercom.help/learned/en/articles/3665868-planning-and-conducting-a-1-1-conversation-as-a-coach',
    },
    {
      key: INSTRUCTIONS.HOW_LEARNING_WORKS_USER,
      value: 'https://intercom.help/learned/en/articles/4273841-how-do-i-work-on-a-learning-path',
    },
    {
      key: INSTRUCTIONS.HOW_CAREER_PLAN_WORKS,
      value:
        'https://intercom.help/learned/en/articles/3665945-how-to-set-up-a-career-plan-in-learned',
    },
    {
      key: INSTRUCTIONS.HOW_NINE_GRID_WORKS_ADMIN,
      value: 'https://intercom.help/learned/en/articles/5370390-how-does-a-9-grid-work',
    },
    {
      key: INSTRUCTIONS.HOW_GOAL_CYCLE_WORKS,
      value: 'https://intercom.help/learned/en/articles/5421437-how-do-goal-cycles-work-in-learned',
    },
    {
      key: INSTRUCTIONS.HOW_SKILL_REPORT_WORKS,
      value: 'https://intercom.help/learned/en/articles/5402552-the-skills-report-in-learned',
    },
    {
      key: INSTRUCTIONS.HOW_PERFORMANCE_REPORT_WORKS,
      value: 'https://intercom.help/learned/en/articles/5402562-the-performance-report-in-learned',
    },
    {
      key: INSTRUCTIONS.HOW_CONVERSATION_REPORT_WORKS,
      value:
        'https://intercom.help/learned/en/articles/5402592-the-conversations-report-in-learned',
    },
    {
      key: INSTRUCTIONS.HOW_TO_CREATE_A_GOAL,
      value: 'https://intercom.help/learned/en/articles/5639737-learn-how-to-work-with-goals',
    },
    {
      key: INSTRUCTIONS.HOW_GOAL_UPDATES_WORK,
      value: 'https://intercom.help/learned/en/articles/5894311-how-goal-updates-work',
    },
    {
      key: INSTRUCTIONS.HOW_FEEDBACK_CYCLE_WORK,
      value: 'https://intercom.help/learned/en/articles/6007328-how-to-set-up-a-feedback-cycle',
    },
    {
      key: INSTRUCTIONS.HOW_MFA_WORKS,
      value:
        'https://intercom.help/learned/en/articles/6106311-working-with-multi-factor-authentication',
    },
    {
      key: INSTRUCTIONS.HOW_SURVEYS_WORK,
      value: 'https://intercom.help/learned/en/articles/8034411-how-surveys-work',
    },
    {
      key: INSTRUCTIONS.HOW_TO_SETUP_MULTIPLE_LANGUAGES_WITHIN_COMPANY_ACCOUNT,
      value:
        'https://intercom.help/learned/en/articles/7977054-how-to-set-up-multiple-languages-within-company-account',
    },
    {
      key: INSTRUCTIONS.HOW_TO_CREATE_SURVEY_THEME,
      value: 'https://intercom.help/learned/en/articles/8036391-how-to-create-a-survey-theme',
    },

    {
      key: INSTRUCTIONS.REPORTS_ABOUT_SECTION_DIMENSIONS,
      value: 'https://intercom.help/learned/en/articles/8120273-the-learned-engagement-report',
    },
    {
      key: INSTRUCTIONS.REPORTS_ABOUT_SECTION_FILTERS,
      value: 'https://intercom.help/learned/en/articles/8120273-the-learned-engagement-report',
    },
    {
      key: INSTRUCTIONS.HOW_DO_GOALS_WORK,
      value: 'https://intercom.help/learned/en/articles/5639737-learn-how-to-work-with-goals',
    },
  ],
};
