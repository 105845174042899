enum REPORT_TYPES {
  ENGAGEMENT = 'engagement',
}

enum ENGAGEMENT_REPORT_CHART_TYPES {
  ENGAGEMENT_BY_MONTH = 'engagement_by_month',
  ENGAGEMENT_BY_DIMENSION = 'engagement_by_dimension',
  ENGAGEMENT_THEME_RANK = 'engagement_theme_rank',
  ENGAGEMENT__PIVOT = 'engagement__pivot'
}

enum ENGAGEMENT_REPORT_CHART_DIMENSIONS {
  THEME = 'theme',
  SURVEY = 'survey',
  TEAM = 'team',
  QUESTION = 'question',
  MONTH = 'month',
  WEEK = 'week',
  YEAR = 'year',
  QUARTER = 'quarter',
  _LEARNED_THEME = '_learned_theme'
}

enum LUCA_INSIGHT_STATUS {
  PENDING = 'PENDING',
  READY = 'READY',
  FAILED = 'FAILED',
  NO_DATA = 'NO_DATA',
}

enum LUCA_INSIGHT_TARGET {
  ENGAGEMENT_SUMMARY = 'ENGAGEMENT_SUMMARY',
  ENGAGEMENT_LEARNED_THEME_ = 'ENGAGEMENT_LEARNED_THEME_'
}

export { REPORT_TYPES, ENGAGEMENT_REPORT_CHART_TYPES, ENGAGEMENT_REPORT_CHART_DIMENSIONS, LUCA_INSIGHT_STATUS, LUCA_INSIGHT_TARGET }