import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SetupCard from '~/components/SetupTools/SetupCard';
import Switch from '~/components/Switch';
import { Header7 } from '~/components/UI/Typographics/headers';

import { COLOR_PALETTE, COLOR_SET, COLORS } from '~/styles';

const TopLabel = styled.div`
  width: 207px;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 24px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${COLOR_SET.MIDNICHT_BLUE};
  outline: none;
  cursor: pointer;

  h6 {
    color: ${COLOR_PALETTE.WHITE};
    margin: 0;
    text-transform: uppercase;
    white-space: nowrap;
  }
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  color: ${COLORS.TEXT_SECONDARY};
  margin: 0 0 16px 0;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  padding: 12px 8px 8px;
  min-height: 64px;
  height: 64px;
  width: 100%;
  box-sizing: border-box;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Section = styled.div`
  width: 100%;
  padding: 9px 16px 19px 15px;
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RowHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-bottom: 8px;
`;

const RowDescription = styled.div`
  font-size: 14px;
  color: ${COLOR_PALETTE.DARK_GRAY_2};
`;

const DigitalSignWrapper = styled.div`
  margin-top: 18px;
`;

const DigitalSignRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DigitalSignLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${COLOR_PALETTE.BLACK};
  margin-right: 17px;
`;

const SignSection = ({ digitalSign, onToggle, updateIsAgreeSeen, updateIsAgreeContent }) => {
  const { i18n } = useLingui();

  const renderRow = (row) => {
    return (
      <DigitalSignRow key={row.label}>
        <DigitalSignLabel>{row.label}</DigitalSignLabel>
        <Switch onChange={row.onChange} checked={row.value} disabled={!row.onChange} />
      </DigitalSignRow>
    );
  };

  const renderDigitalSign = () => {
    const items = [
      {
        label: i18n._(t`Participants need to agree to having seen the report`),
        value: digitalSign.isAgreeSeen,
        onChange: updateIsAgreeSeen,
      },
      {
        label: i18n._(t`Participants need to agree to the content of the report`),
        value: digitalSign.isAgreeContent,
        onChange: updateIsAgreeContent,
      },
    ];

    return <DigitalSignWrapper>{items.map(renderRow)}</DigitalSignWrapper>;
  };

  return (
    <>
      <TopLabel role="button">
        <Header7>
          <Trans>Complete conversation</Trans>
        </Header7>
      </TopLabel>
      <SetupCard removeTopLeftRadius cropBorder hideFooter i18n={i18n}>
        <Description>
          <Trans>
            All participants and coaches will be asked to complete the conversation. Optinally, they
            can add a final comment.
          </Trans>
        </Description>
        <Content>
          <Section>
            <RowSpaceBetween>
              <RowHeader>{i18n._(t`Digitally sign`)}</RowHeader>
              <Switch onChange={onToggle} checked={Boolean(digitalSign)} />
            </RowSpaceBetween>
            <RowDescription>{i18n._(t`Participants need to sign the report `)}</RowDescription>
            {digitalSign && renderDigitalSign()}
          </Section>
        </Content>
      </SetupCard>
    </>
  );
};

SignSection.propTypes = {
  digitalSign: PropTypes.shape({
    isAgreeSeen: PropTypes.bool,
    isAgreeContent: PropTypes.bool,
    isUserComment: PropTypes.bool,
    isCoachComment: PropTypes.bool,
  }),
  onDelete: PropTypes.func,
  updateIsAgreeSeen: PropTypes.func,
  updateIsAgreeContent: PropTypes.func,
};

export default SignSection;
