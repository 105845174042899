import { useState } from 'react';

import { LastSavedStatus } from '~/constants/lastSaved';

// @deprecated Use hooks/useAutoSave
const useAutoSaveState = ({ errorMessage }: { errorMessage: string }) => {
  const [lastSavedTime, setLastSavedTime] = useState<Date>();
  const [lastSavedStatus, setLastSavedStatus] = useState<LastSavedStatus>();
  const [lastSavedErrorMessage, setLastSavedErrorMessage] = useState<string>(errorMessage);

  const setLastSaveSuccess = () => {
    setLastSavedTime(new Date());
    setLastSavedStatus(LastSavedStatus.SUCCESS);
  };

  const setLastSaveError = () => {
    setLastSavedStatus(LastSavedStatus.ERROR);
  };

  return {
    lastSavedStatus,
    lastSavedTime,
    lastSavedErrorMessage,
    setLastSaveSuccess,
    setLastSaveError,
    setLastSavedErrorMessage,
  };
};

export { useAutoSaveState };
