import React, { Component } from 'react';

import {
  ROLES,
  QUESTION_TYPES,
  GOAL_TYPES,
  GOAL_CYCLES_VIRTUAL_STATUSES,
} from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import AutocompleteDropdown from '~/components/AutocompleteDropdown';
import { AutocompleteFilterGoalCycles } from '~/components/AutocompleteFilters';
import Button from '~/components/Button';
import Editor from '~/components/Editor';
import WarningIcon from '~/components/Icons/Warning';
import SvgIcon from '~/components/SvgIcon';
import { TextArea } from '~/components/Text';
import Tooltip from '~/components/Tooltip';
import { hasTemplateCoachesEnabled, getSelectedCoaches } from '~/pages/ReviewSetup/helpers';
import TemplatePreviewModal from '~/pages/ReviewTemplatePreview/components/TemplatePreviewModal';

import starIcon from '~/assets/main-menu-icons/star.svg';

import { REVIEW_STATUSES } from '~/constants';
import routes from '~/constants/routes';
import { getGoalsByCycles } from '~/services/goals';
import { getTemplatesOld } from '~/services/reviewTemplates';
import { getCompanyUsers } from '~/services/users';
import * as currentReviewActions from '~/store/currentReview/actions';
import { COLOR_PALETTE, COLORS } from '~/styles';
import {
  getGoalCyclesIDs,
  getGoalCyclesWithoutGoalsOfSomeType,
  joinGoalCyclesNames,
} from '~/utils/goalCyclesUtils';

const DropdownContainer = styled.div`
  width: 312px;
  margin-bottom: 12px;
`;

const ReviewTemplateWrapper = styled.div`
  padding: 16px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDER_HARD};
`;

const NoReviewTemplateWrapper = styled.div`
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px -1px rgba(14, 26, 45, 0.3);
  border: solid 1px #f6f8fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 25px;
  padding-bottom: 25px;
  justify-content: center;
`;

const NoTemplateHeader = styled.div`
  font-size: 16px;
  margin-top: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLORS.TEXT_BLACK};
`;

const NoTemplateText = styled.div`
  font-size: 14px;
  line-height: 1.57;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const Header = styled.div`
  width: 300px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const EmailInvite = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin: 24px 0 40px 0;
`;

const ReviewName = styled(TextArea)`
  margin: 12px 0 32px 0;
`;

const LinkWrapper = styled(Link)`
  text-decoration: inherit;
  &:hover {
    text-decoration: inherit;
  }
`;

const ReviewDescription = styled(Editor)`
  margin-top: 12px;
`;

const GoalSelectorWrapper = styled.div`
  padding: 16px;
  margin-top: 24px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
`;

const AutoFilterContainer = styled.div`
  width: min-content;
`;

const FilterGoalCycles = styled(AutocompleteFilterGoalCycles)`
  width: 312px;
  height: 46px;
  margin: 10px 0 24px 0;
  flex: 0 0 312px;
  ${(props) => props.disabled && 'cursor: unset;'}
  ${(props) => props.error && 'border: 1px solid red;'}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PreviewTemplateButton = styled(Button)`
  margin-bottom: 12px;
`;

const GoalSelectorHeader = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: black;
`;

const EvalFilterRow = styled.div`
  display: flex;
  align-items: start;
`;

const EvalFilterWarning = styled.div`
  margin-left: 22px;
  color: ${COLORS.ERROR};
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 20px;
  svg {
    padding-right: 8px;
  }
`;

class ReviewTabSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      filteredTemplates: [],
      bussGoalCyclesWithNoGoals: [],
      learnGoalCyclesWithNoGoals: [],
      showPreviewTemplateModal: false,
    };
  }

  async componentDidMount() {
    const { review } = this.props;
    if (!review.id || (review.id && review.status === REVIEW_STATUSES.DRAFT.key)) {
      await this.fetchData();
    }
  }

  fetchData = async () => {
    const templates = await getTemplatesOld();
    const sortedTemplates = Object.values(templates).sort((a, b) =>
      b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1,
    );
    this.setState({
      templates: sortedTemplates,
      filteredTemplates: sortedTemplates,
    });
  };

  selectTemplate = async (template) => {
    const { dispatch } = this.props;

    await this.setReviewCoaches(template);

    dispatch(currentReviewActions.updateCurrentReviewTemplate(template));
  };

  setReviewCoaches = async (template) => {
    const { dispatch, review } = this.props;
    const isOldTemplateHasCoaches = hasTemplateCoachesEnabled(review);
    const isNewTemplateHasCoaches = hasTemplateCoachesEnabled({ templateItem: template });
    // if new template does not have coaches as interviewer
    // clean review.coaches
    if (review.template && !isNewTemplateHasCoaches) {
      dispatch(currentReviewActions.updateCurrentReview({ coaches: {} }));
    }

    // if prev template was without coaches and new one with
    // add review.coaches
    if (review.template && isNewTemplateHasCoaches && !isOldTemplateHasCoaches) {
      const defaultCoaches = await this.getDefaultCoaches();
      dispatch(
        currentReviewActions.updateCurrentReview({
          coaches: defaultCoaches,
        }),
      );
    }
  };

  getDefaultCoaches = async () => {
    const { review } = this.props;
    const { data } = await getCompanyUsers(null, ['coaches']);
    const reviewUsers = [];
    if (!isEmpty(review.users)) {
      review.users.forEach((userId) => {
        const reviewUser = data?.users?.[userId.id || userId];
        if (!isEmpty(reviewUser)) {
          reviewUsers.push(reviewUser);
        }
      });
    }

    return getSelectedCoaches(get(review, 'coaches', []), reviewUsers);
  };

  onDescriptionChange = (value) => {
    const { dispatch } = this.props;

    dispatch(
      currentReviewActions.updateCurrentReview({
        description: value,
      }),
    );
  };

  onNameChange = (event) => {
    const { dispatch } = this.props;
    const name = event.target.value;

    dispatch(
      currentReviewActions.updateCurrentReview({
        name,
      }),
    );
  };

  createNoTemplatesPlaceholder = () => {
    const { user } = this.props;
    return (
      <NoReviewTemplateWrapper>
        <SvgIcon
          width="28px"
          height="28px"
          isDefaultColor
          defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
          url={starIcon}
        />
        <NoTemplateHeader>
          <Trans>No templates available</Trans>
        </NoTemplateHeader>
        <NoTemplateText>
          {user.isAdmin ? (
            <LinkWrapper
              to={routes.REVIEW_TEMPLATE_CREATE.build({ role: ROLES.ADMIN }, { isBackPath: true })}
            >
              <Trans>Create a template</Trans>
            </LinkWrapper>
          ) : (
            <Trans>Ask your HR manager to create a template</Trans>
          )}
        </NoTemplateText>
      </NoReviewTemplateWrapper>
    );
  };

  setGoalCycleBusinessReview = (goalCycles) => {
    const {
      review: { goalCyclesBusinessEval },
      dispatch,
    } = this.props;

    if (!isEmpty(goalCycles) && !isEqual(goalCycles, goalCyclesBusinessEval)) {
      getGoalsByCycles(getGoalCyclesIDs(goalCycles)).then((goals) => {
        const noGoalsCycles = getGoalCyclesWithoutGoalsOfSomeType(
          goalCycles,
          goals,
          GOAL_TYPES.BUSINESS,
        );

        this.setState({ bussGoalCyclesWithNoGoals: noGoalsCycles });
      });
    }

    dispatch(
      currentReviewActions.updateCurrentReview({
        goalCyclesBusinessEval: goalCycles,
      }),
    );
  };

  setGoalCycleBusinessPlan = (goals) => {
    const { dispatch } = this.props;

    dispatch(
      currentReviewActions.updateCurrentReview({
        goalCyclesBusinessPlan: goals.slice(-1),
      }),
    );
  };

  setGoalCycleLearningReview = (goalCycles) => {
    const {
      review: { goalCyclesLearningEval },
      dispatch,
    } = this.props;

    if (!isEmpty(goalCycles) && !isEqual(goalCycles, goalCyclesLearningEval)) {
      getGoalsByCycles(getGoalCyclesIDs(goalCycles)).then((goals) => {
        const noGoalsCycles = getGoalCyclesWithoutGoalsOfSomeType(
          goalCycles,
          goals,
          GOAL_TYPES.PERSONAL,
        );
        this.setState({ learnGoalCyclesWithNoGoals: noGoalsCycles });
      });
    }

    dispatch(
      currentReviewActions.updateCurrentReview({
        goalCyclesLearningEval: goalCycles,
      }),
    );
  };

  setGoalCycleLearningPlan = (goals) => {
    const { dispatch } = this.props;

    dispatch(
      currentReviewActions.updateCurrentReview({
        goalCyclesLearningPlan: goals.slice(-1),
      }),
    );
  };

  showSection = (types) => {
    const {
      review: { templateItem },
    } = this.props;

    return !!templateItem.sections.filter(
      (section) =>
        !!section.questions.filter((question) => types.indexOf(question.type) !== -1).length,
    ).length;
  };

  handleSearchChange = (search) => {
    const filteredTemplates = this.state.templates.filter((template) =>
      template.name.toLowerCase().includes(search.toLowerCase()),
    );
    this.setState({ filteredTemplates });
  };

  render() {
    const { showErrors, review, i18n } = this.props;
    const {
      templates,
      filteredTemplates,
      bussGoalCyclesWithNoGoals,
      learnGoalCyclesWithNoGoals,
      showPreviewTemplateModal,
    } = this.state;
    const isPublished = review.id && review.status !== REVIEW_STATUSES.DRAFT.key;
    const noTemplatesPlaceholder = this.createNoTemplatesPlaceholder();

    const selectedTemplate = review.templateItem;
    const {
      goalCyclesBusinessEval,
      goalCyclesBusinessPlan,
      goalCyclesLearningEval,
      goalCyclesLearningPlan,
    } = review;

    return (
      <>
        <ButtonsContainer>
          <DropdownContainer>
            <AutocompleteDropdown
              items={filteredTemplates}
              selectedItem={selectedTemplate}
              onChange={this.selectTemplate}
              searchField
              onSearchChange={this.handleSearchChange}
              noItemsPlaceholder={noTemplatesPlaceholder}
              showNoItemsPlaceholder={templates.length <= 0}
              disabled={isPublished}
            />
          </DropdownContainer>
          {!!selectedTemplate && (
            <PreviewTemplateButton
              type="link-primary"
              label={i18n._(t`Preview`)}
              onClick={() => {
                this.setState({
                  showPreviewTemplateModal: true,
                });
              }}
            />
          )}
        </ButtonsContainer>
        {/* hide name and description for published reviews */}
        {!!selectedTemplate && (
          <ReviewTemplateWrapper>
            <Header>
              <Trans>Name</Trans>
            </Header>
            <ReviewName
              name="abstract"
              value={review.name}
              maxLength="100"
              onChange={this.onNameChange}
            />
            {!isPublished && (
              <>
                <Header>
                  <Trans>Email subject</Trans>
                </Header>
                <EmailInvite>
                  <Trans>You are invited to:</Trans> {review.name}
                </EmailInvite>
              </>
            )}
            <Header>
              <Trans>Description</Trans>
            </Header>
            <ReviewDescription
              value={review.description}
              onChange={this.onDescriptionChange}
              compact={true}
              error={showErrors && !review.description}
            />
            {this.showSection([
              QUESTION_TYPES.GOAL_BUSINESS_PLAN,
              QUESTION_TYPES.GOAL_BUSINESS_EVAL,
            ]) && (
              <GoalSelectorWrapper key="business-goal">
                <GoalSelectorHeader>
                  <Trans>Business goals</Trans>
                </GoalSelectorHeader>
                {this.showSection([QUESTION_TYPES.GOAL_BUSINESS_EVAL]) && (
                  <>
                    <Tooltip
                      maxWidth="300px"
                      tooltip={i18n._(
                        t`The participants will be asked to evaluate the goals connected to these goal cycles.`,
                      )}
                    >
                      <Header>
                        <Trans>Select a goal cycle to evaluate goals for</Trans>*
                      </Header>
                    </Tooltip>
                    <EvalFilterRow>
                      <FilterGoalCycles
                        checkedList={goalCyclesBusinessEval}
                        onChange={this.setGoalCycleBusinessReview}
                        disabled={isPublished}
                        error={showErrors && size(goalCyclesBusinessEval) === 0}
                      />
                      {bussGoalCyclesWithNoGoals.length > 0 && (
                        <EvalFilterWarning>
                          <WarningIcon size={20} />
                          <Trans>
                            Goal cycle(s) &quot;
                            {joinGoalCyclesNames(bussGoalCyclesWithNoGoals)}&quot; does not contain
                            any goals
                          </Trans>
                        </EvalFilterWarning>
                      )}
                    </EvalFilterRow>
                  </>
                )}
                {this.showSection([QUESTION_TYPES.GOAL_BUSINESS_PLAN]) && (
                  <>
                    <Tooltip
                      maxWidth="300px"
                      tooltip={i18n._(
                        t`The participants will be asked to plan new goals for the select goal cycle.`,
                      )}
                    >
                      <Header>
                        <Trans>Select goal cycle to plan for</Trans>*
                      </Header>
                    </Tooltip>

                    <Tooltip
                      disabled={!isPublished}
                      tooltip={i18n._(
                        t`The goal cycle(s) can't be updated after the conversation is published`,
                      )}
                    >
                      <AutoFilterContainer>
                        <FilterGoalCycles
                          disabled={isPublished}
                          checkedList={goalCyclesBusinessPlan}
                          onChange={this.setGoalCycleBusinessPlan}
                          error={showErrors && size(goalCyclesBusinessPlan) === 0}
                          type={GOAL_CYCLES_VIRTUAL_STATUSES.UNCOMPLETED}
                        />
                      </AutoFilterContainer>
                    </Tooltip>
                  </>
                )}
              </GoalSelectorWrapper>
            )}
            {this.showSection([
              QUESTION_TYPES.GOAL_LEARNING_EVAL,
              QUESTION_TYPES.GOAL_LEARNING_PLAN,
            ]) && (
              <GoalSelectorWrapper key="learning-goal">
                <GoalSelectorHeader>
                  <Trans>Learning goals</Trans>
                </GoalSelectorHeader>
                {this.showSection([QUESTION_TYPES.GOAL_LEARNING_EVAL]) && (
                  <>
                    <Tooltip
                      maxWidth="300px"
                      tooltip={i18n._(
                        t`The participants will be asked to evaluate the goals connected to these goal cycles.`,
                      )}
                    >
                      <Header>
                        <Trans>Select a goal cycle to evaluate goals for</Trans>*
                      </Header>
                    </Tooltip>
                    <EvalFilterRow>
                      <FilterGoalCycles
                        checkedList={goalCyclesLearningEval}
                        onChange={this.setGoalCycleLearningReview}
                        disabled={isPublished}
                        error={showErrors && size(goalCyclesLearningEval) === 0}
                      />
                      {learnGoalCyclesWithNoGoals.length > 0 && (
                        <EvalFilterWarning>
                          <WarningIcon size={20} />
                          <Trans>
                            Goal cycle(s) &quot;
                            {joinGoalCyclesNames(learnGoalCyclesWithNoGoals)}&quot; does not contain
                            any goals
                          </Trans>
                        </EvalFilterWarning>
                      )}
                    </EvalFilterRow>
                  </>
                )}
                {this.showSection([QUESTION_TYPES.GOAL_LEARNING_PLAN]) && (
                  <>
                    <Tooltip
                      maxWidth="300px"
                      tooltip={i18n._(
                        t`The participants will be asked to plan new goals for the select goal cycle.`,
                      )}
                    >
                      <Header>
                        <Trans>Select goal cycle to plan for</Trans>*
                      </Header>
                    </Tooltip>
                    <Tooltip
                      disabled={!isPublished}
                      tooltip={i18n._(
                        t`The goal cycle(s) can't be updated after the review is published`,
                      )}
                    >
                      <AutoFilterContainer>
                        <FilterGoalCycles
                          disabled={isPublished}
                          checkedList={goalCyclesLearningPlan}
                          onChange={this.setGoalCycleLearningPlan}
                          error={showErrors && size(goalCyclesLearningEval) === 0}
                          type={GOAL_CYCLES_VIRTUAL_STATUSES.UNCOMPLETED}
                        />
                      </AutoFilterContainer>
                    </Tooltip>
                  </>
                )}
              </GoalSelectorWrapper>
            )}
          </ReviewTemplateWrapper>
        )}
        {showPreviewTemplateModal && (
          <TemplatePreviewModal
            onClose={() => this.setState({ showPreviewTemplateModal: false })}
            template={selectedTemplate}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    review: state.currentReview,
    user: state.auth.user,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(ReviewTabSetup)));
