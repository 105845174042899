import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import IconButton from '~/components/IconButton';
import CheckIcon from '~/components/Icons/Check';
import { NominatePeopleAsParticipantModal } from '~/components/SelectedCoachesRow/components/NominatePeopleAsParticipantModal';
import Avatar from '~/components/UI/Avatar';

import NomineesCoachesSetupModal from './components/NomineesCoachesListModal';

import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const RowCursor = styled(Row)`
  cursor: pointer;
`;

const AvatarWrapper = styled.div`
  padding: 4px 2px 4px 4px;
  background: ${COLORS.HOVER_ACTIVE};
  margin-right: ${(props) => (props.$isCoverView ? '-21px' : '8px')};
  border-radius: 3px;
  display: flex;
  align-items: center;
`;

const AvatarActionIcon = styled.div`
  margin-left: 1px;
`;

const MoreCoachesNumber = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-left: 29px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  display: flex;
  justify-content: center;
  gap: 8px;
`;

const Error = styled.div`
  color: ${COLORS.ERROR};
`;

const SelectCoachesLabel = styled.div`
  color: var(--company-color);
`;

const ReadOnly = styled.div``;

const COACHES_FOR_FULL_VIEW = 3;

const CoachFull = ({
  coachId,
  isSelected,
  isCoverView = false,
  onToggleCoach,
  isDisabled,
} = {}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <AvatarWrapper
      key={coachId}
      $isHover={isHover}
      $isCoverView={isCoverView}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Avatar
        userId={coachId}
        size={28}
        showTooltip={
          !isCoverView
        } /* do not show tooltip for covered avatars (they will have a modal)*/
        isGray={!isSelected}
      />

      {!isCoverView && !isDisabled && (isHover || isSelected) && (
        <AvatarActionIcon>
          {isSelected ? (
            <IconButton noBorder size={14} onClick={() => onToggleCoach(coachId, false)}>
              {isHover ? <Icon icon={ICONS.CLOSE} size={ICON_SIZES.SMALL} /> : <CheckIcon />}
            </IconButton>
          ) : (
            <IconButton noBorder size={14} onClick={() => onToggleCoach(coachId, true)}>
              <CheckIcon />
            </IconButton>
          )}
        </AvatarActionIcon>
      )}
    </AvatarWrapper>
  );
};

const SelectedCoachesRow = ({
  user,
  coaches = [],
  coachesSelected = [],
  onToggleCoach,
  isReadOnly = false,
  disabledCoach,
  removeParticipants = () => undefined,
  isParticipants = false,
  updateCoaches = (_arg0) => undefined,
} = {}) => {
  const { i18n } = useLingui();
  if (isReadOnly) {
    return (
      <Row>
        <ReadOnly>
          {i18n._(
            isParticipants
              ? t`You can change participants in the conversation overview page`
              : t`You can change coaches in dashboard`,
          )}
        </ReadOnly>
      </Row>
    );
  }

  if (isEmpty(coaches) && !isParticipants) {
    return (
      <Row>
        <Error>{i18n._(t`No coaches`)}</Error>
      </Row>
    );
  }

  const isFullView = size(coaches) <= COACHES_FOR_FULL_VIEW && !isParticipants;

  const CoachesFull = () => {
    return (
      <Row>
        {map(coaches, (coachId) => (
          <CoachFull
            key={coachId}
            coachId={coachId}
            isSelected={coachesSelected.includes(coachId)}
            onToggleCoach={onToggleCoach}
            isDisabled={disabledCoach && disabledCoach === coachId}
          />
        ))}
      </Row>
    );
  };

  const CoachesCovered = () => {
    const $isNomineesCoaches = useBoolState(false);
    const $isParticipants = useBoolState(false);
    let showCoaches = coachesSelected;
    const all = size(coaches);
    const selected = size(coachesSelected);
    const [coachArray, setCoachArray] = useState(coachesSelected);

    if (COACHES_FOR_FULL_VIEW > 0) {
      showCoaches = coachesSelected.filter((_, index) => {
        return COACHES_FOR_FULL_VIEW >= index + 1;
      });
    }

    const changeModal = () => {
      $isNomineesCoaches.toggle();
      $isParticipants.toggle();
    };

    return (
      <>
        <RowCursor onClick={$isNomineesCoaches.on}>
          {isEmpty(showCoaches) ? (
            <SelectCoachesLabel>
              {i18n._(isParticipants ? t`Select participants` : t`Select coaches`)}
            </SelectCoachesLabel>
          ) : (
            map(showCoaches, (coachId) => (
              <CoachFull
                coachId={coachId}
                isSelected={coachesSelected.includes(coachId)}
                isCoverView={true}
                onToggleCoach={onToggleCoach}
              />
            ))
          )}
          {
            <MoreCoachesNumber>
              {isParticipants ? (
                <>
                  {selected > 3 && `+${selected - 3}`}
                  <Icon icon={ICONS.EDIT_PENCIL} className="icon" />
                </>
              ) : (
                `${selected}/${all}`
              )}
            </MoreCoachesNumber>
          }
          {}
        </RowCursor>
        {$isNomineesCoaches.value && (
          <NomineesCoachesSetupModal
            user={user}
            coaches={isParticipants ? coachArray : coaches}
            coachesSelected={coachArray}
            onToggleCoach={onToggleCoach}
            onClose={() => {
              updateCoaches(coachArray);
              $isNomineesCoaches.off();
            }}
            disabledCoach={disabledCoach}
            isParticipants={isParticipants}
            removeParticipants={removeParticipants}
            isParticipantsModal={$isParticipants}
            changeModal={changeModal}
            setCoachArray={setCoachArray}
          />
        )}
        {$isParticipants.value && (
          <NominatePeopleAsParticipantModal
            selectedUser={user}
            onClose={changeModal}
            setNewParticipants={(users) => {
              setCoachArray([...coachArray, ...users]);
            }}
            selectedParticipants={coachArray}
          />
        )}
      </>
    );
  };

  return isFullView ? <CoachesFull /> : <CoachesCovered />;
};

SelectedCoachesRow.propTypes = {
  coaches: PropTypes.arrayOf(PropTypes.string),
  coachesSelected: PropTypes.arrayOf(PropTypes.string),
  onToggleCoach: PropTypes.func,
};

SelectedCoachesRow.defaultProps = {
  onToggleCoach: () => {},
};

export default SelectedCoachesRow;
