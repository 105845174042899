import styled from 'styled-components';

import Switch from '~/components/Switch';

import { COLORS } from '~/styles';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  padding: 18px;
  box-sizing: border-box;
  width: 100%;
  img {
    height: 56px;
  }
`;

export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  flex-grow: 1;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
`;

export const Title = styled.h2`
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: normal;
  color: ${COLORS.COMPANY};
  margin-bottom: 8px;
`;

export const Subtitle = styled.span<{
  marginBottom?: string;
  textAlign?: string;
}>`
  width: 100%;
  text-align: center;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  font-size: 16px;
  font-weight: normal;
  color: ${COLORS.SUBTEXT};
  margin-bottom: ${({ marginBottom }) => marginBottom || '50px'};
`;

export const FormWrapper = styled.div<{
  width?: string;
  padding?: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: ${({ width }) => width || '284px'};
  padding: ${({ padding }) => padding || 'unset'};
  & .dropdown {
    width: 100%;
  }
  & from {
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  & .back {
    color: ${COLORS.SUBTEXT};
    margin-left: -26px;
  }
`;

export const PasswordRequirement = styled.div`
  margin: 12px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
`;

export const PasswordFormItem = styled.div`
  text-align: left;
  position: relative;
`;

export const PasswordRequirementBlock = styled.div`
  position: absolute;
  right: -310px;
  top: 0;
  z-index: 1000;
  width: 239px;
  border-radius: 6px;
  box-shadow: 1px 1px 2px 0 rgba(25, 42, 70, 0.08);
  border: solid 0.5px ${COLORS.BORDERS};
  background-color: ${COLORS.WHITE};
  padding: 16px;
`;

export const PasswordText = styled.div`
  margin-left: 14px;
`;

export const Label = styled.div<{ marginBottom?: string }>`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  color: ${COLORS.TEXT_HOVER};
`;

export const Description = styled.div<{
  marginTop?: string;
}>`
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  margin-left: 2px;
  white-space: nowrap;
`;

export const LeftItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 50px 0 45px;
`;

export const FreeTrial = styled.div`
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.COMPANY};
  margin-top: 30px;
`;

export const ProductItem = styled.div<{
  isDescriptionVisible?: boolean;
  isSelected?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 16px;
  max-height: ${({ isDescriptionVisible }) => (!isDescriptionVisible ? '48px' : 'unset')};
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isSelected }) => (isSelected ? COLORS.COMPANY : COLORS.BORDERS)};
  border-radius: 6px;
  box-sizing: border-box;

  & .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    margin-top: -4px;
  }

  & .switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    margin-top: -6px;
  }

  & .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 6px;

    & .name {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: ${({ isDescriptionVisible }) => (isDescriptionVisible ? '18px' : '14px')};
      margin-top: ${({ isDescriptionVisible }) => (isDescriptionVisible ? '-6px' : 'unset')};
      gap: 18px;
      color: ${COLORS.TEXT_MAIN};
    }

    & .description {
      font-size: 14px;
      color: ${COLORS.SUBTEXT};
      line-height: 1.5;
      text-align: left;
    }
  }

  &:hover {
    background-color: ${COLORS.HOVER};
  }
  cursor: pointer;
`;

export const ProductSwitch = styled(Switch)`
  margin: 0;
`;

export const ReadMore = styled.div`
  color: ${COLORS.COMPANY};
  font-size: 12px;
  flex-grow: 1;
  text-align: right;
`;
