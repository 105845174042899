import React, { Component } from 'react';

import { CONNECTION_STATUSES, ROLES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import difference from 'lodash/difference';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import WaitModal from '~/components/Modals/WaitModal';
import SetupFlow from '~/components/SetupFlow';
import { withToasts, TOAST_TYPES } from '~/components/Toast';
import Tooltip from '~/components/Tooltip';
import { prepareDefaultDeadlines } from '~/pages/ReviewSetup/components/ReviewTabWorkflow/defaultDeadlines';

import ReviewQualityCheckModal from './components/ReviewQualityCheckModal';
import ReviewTabPeople from './components/ReviewTabPeople';
import ReviewTabSetup from './components/ReviewTabSetup';
import ReviewTabWorkflow from './components/ReviewTabWorkflow';
import {
  didReviewUsersHaveValidJobProfiles,
  getEvalGoalCyclesIDs,
  getReviewSkillCategories,
  goalCycleIdMapper,
  hasJobProfileQuestion,
  isJobProfileSelectedForEveryUser,
  isValidGoalCycles,
} from './helpers';

import { REVIEW_STATUSES, TEMPLATE_NEW_REVIEW } from '~/constants';
import routes from '~/constants/routes';
import { getSelectedRole } from '~/selectors/baseGetters';
import {
  getReview,
  createReview,
  updateReview,
  deleteReview,
  addUsersToReview,
  deleteUsersFromReview,
  updatePublishedReview,
} from '~/services/reviews';
import { getCompanyUsers } from '~/services/users';
import * as appActions from '~/store/app/actions';
import { fetchReviewUsers } from '~/store/currentReview/actions';
import * as currentReviewActions from '~/store/currentReview/actions';
import { COLOR_PALETTE } from '~/styles';
import { checkHasReviewCompleteLogic } from '~/utils/checkHasReviewCompleteLogic';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

const PublishingWaitModal = styled(WaitModal)`
  padding-top: 50vh;
`;

const ContentHeaderWrapper = styled.div`
  margin: 24px;
`;

const ContentHeaderHeader = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.82;
  color: #000000;
`;

const ContentHeaderContent = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const REVIEW_STEPS = {
  people: 'people',
  introduce: 'introduce',
  templates: 'templates',
  deadlines: 'deadlines',
  publish: 'publish',
};

const REVIEW_STEPS_DATA = (_isUpdate) =>
  [
    {
      key: REVIEW_STEPS.introduce,
      title: (i18n) => i18n._(t`Select a template and introduce the conversation`),
      component: ReviewTabSetup,
      requiredProps: ['name', 'template', 'description', isValidGoalCycles],
    },
    {
      key: REVIEW_STEPS.people,
      title: (i18n, { currentRole } = {}) =>
        currentRole === ROLES.ADMIN
          ? i18n._(t`Select the participants and adjust settings`)
          : i18n._(t`Adjust settings and select coaches`),
      component: ReviewTabPeople,
      requiredProps: [
        'users',
        didReviewUsersHaveValidJobProfiles,
        isJobProfileSelectedForEveryUser,
      ],
    },
    {
      key: REVIEW_STEPS.deadlines,
      title: (i18n, { currentRole } = {}) =>
        currentRole === ROLES.COACH
          ? i18n._(t`Schedule the conversation and set the deadlines`)
          : i18n._(t`Set-up the timeline`),
      component: ReviewTabWorkflow,
      requiredProps: [
        // startDates
        (review) => (review.isSelfReview ? review.startDateSelfReview : true),
        (review) => (review.isUsersReview ? review.startDateUsersRequest : true),
        (review) => (review.isCoachesReview ? review.startDateCoachesRequest : true),
        (review) => (review.isUsersReview ? review.startDateNominate : true),
        (review) => {
          const isCompleteLogic = checkHasReviewCompleteLogic(
            review?.template?.digitalSign,
            get(review, 'meta.createdDate'),
          );
          return isCompleteLogic ? review.startDateToSign : true;
        },

        // deadlines
        (review) => (review.isSelfReview ? review.deadlineSelfReview : true),
        (review) => (review.isUsersReview ? review.deadlineUsersRequest : true),
        (review) => (review.isCoachesReview ? review.deadlineCoachesRequest : true),
        (review) => (review.isUsersReview ? review.deadlineNominate : true),
        (review) => {
          const isCompleteLogic = checkHasReviewCompleteLogic(
            review?.template?.digitalSign,
            get(review, 'meta.createdDate'),
          );
          return isCompleteLogic ? review.deadlineToSign : true;
        },
      ],
    },
  ].filter((i) => i);

const CREATE_STEPS = REVIEW_STEPS_DATA(false);
const UPDATE_STEPS = REVIEW_STEPS_DATA(true);

const getUsersSelectedCoaches = (usersToAdd, allCoaches) => {
  const coaches = {};
  if (!isEmpty(usersToAdd)) {
    usersToAdd.forEach((userId) => {
      coaches[userId] = get(allCoaches, `[${userId}]`, []);
    });
  }
  return coaches;
};

const getUsersSelectedJobProfiles = (usersToAdd, allJobProfilesPerUser) => {
  const jobProfilesPerUser = {};
  if (!isEmpty(usersToAdd)) {
    usersToAdd.forEach((userId) => {
      jobProfilesPerUser[userId] = get(allJobProfilesPerUser, `[${userId}]`, []);
    });
  }
  return jobProfilesPerUser;
};

class ReviewSetup extends Component {
  state = {
    loading: true,
    isShowPublishingModal: false,
    loadingLabel: null,
    currentStep: REVIEW_STEPS.introduce,
    initReviewUsers: [],
    isShowReviewQualityCheckModal: false,
  };

  async componentDidMount() {
    const { dispatch, match, app, currentRole } = this.props;

    let href = window.location.href;
    let url = new URL(href);
    this.from = url.searchParams.get('from');
    const reviewId = get(match, 'params.reviewId');
    const { users: preSelectedUsersIds } = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (!reviewId) {
      // setup fetched review
      dispatch(
        currentReviewActions.setCurrentReview({
          ...TEMPLATE_NEW_REVIEW,
          ...prepareDefaultDeadlines({}, { currentRole }),
          ...(!isEmpty(preSelectedUsersIds) && { users: preSelectedUsersIds }),
        }),
      );
    } else {
      let review = await getReview(reviewId, {
        join: ['requests', 'ratings'],
        isQuestions: true,
      });

      if (isEmpty(review)) {
        this.handleClose();
      } else {
        if (!isEmpty(review.template) && review.template.id) {
          review.templateItem = { ...review.template };
          review.template = review.templateItem.id;
        }

        if (review?.status === REVIEW_STATUSES.DRAFT.key) {
          const isJobReview = hasJobProfileQuestion(review);
          const skillCategories = isJobReview ? getReviewSkillCategories(review) : null;
          const planGoalCyclesIds = getEvalGoalCyclesIDs(review);
          const usersAvailable = await getCompanyUsers(
            {
              goalCycles: planGoalCyclesIds,
              ...(isJobReview && !isEmpty(skillCategories) && { skillCategories }),
              statuses: [CONNECTION_STATUSES.ACTIVE],
            },
            [isJobReview && 'jobProfiles', 'coaches'].filter(Boolean),
          ).then((response) => response?.data?.users ?? {});

          // remove disabled coaches from draft review
          const updatedCoaches = {};
          Object.entries(review.coaches).map(([userId, coaches]) => {
            updatedCoaches[userId] = coaches.filter((coach) => usersAvailable[coach] !== undefined);
          });

          // remove disabled conversation coaches from draft review
          const updatedConversationCoaches = {};
          Object.entries(review.conversationCoaches).map(([userId, coaches]) => {
            updatedConversationCoaches[userId] = coaches.filter(
              (coach) => usersAvailable[coach] !== undefined,
            );
          });

          // remove disabled users from draft review
          const updatedUsers = review.users.filter(
            (userId) => usersAvailable[userId] !== undefined,
          );

          review = {
            ...review,
            coaches: updatedCoaches,
            conversationCoaches: updatedConversationCoaches,
            users: updatedUsers,
          };
        }

        dispatch(currentReviewActions.setCurrentReview(review));
        review.users = (await this.fetchFreshReviewUsers()).users;

        if (review.id && review.status !== REVIEW_STATUSES.DRAFT.key) {
          this.setState({ initReviewUsers: review.users });
        }
      }
    }

    if (!app.isFieldsValidated) {
      dispatch(appActions.updateApp({ isFieldsValidated: true }));
    }

    this.setState({ loading: false });
  }

  async fetchFreshReviewUsers() {
    return await this.props.dispatch(fetchReviewUsers());
  }

  handleClose = () => {
    const { history, dispatch } = this.props;

    const backPath = this.from
      ? location.hash.slice(1)
        ? this.from + '#' + location.hash.slice(1)
        : this.from
      : history.push(
          routes.CONVERSATIONS.build(
            { role: ROLES.USER },
            { hash: 'development-conversation_cycle' },
          ),
        );

    history.push(backPath);
    dispatch(currentReviewActions.setCurrentReview({}));
  };

  deleteThisReview = async () => {
    const { i18n, match } = this.props;
    const { reviewId } = match.params;
    if (
      await confirm(
        i18n,
        i18n._(t`Are you sure you want to delete this review round? This cannot be undone.`),
      )
    ) {
      this.setState({ loading: true });
      this.setState({
        isShowPublishingModal: true,
        loadingLabel: i18n._(t`We are deleting your review`),
      });
      await deleteReview(reviewId);
      this.handleClose();
    }
  };

  onSubmit = async (isDraft) => {
    const { i18n, review: { id, ...reviewFromStore } = {}, match } = this.props;
    const { reviewId } = match.params;
    const { isShowReviewQualityCheckModal } = this.state;

    try {
      if (isDraft) {
        this.setState({ loading: true });
      } else {
        this.setState({
          isShowPublishingModal: true,
          loadingLabel: i18n._(t`We are publishing your review`),
        });
      }

      if (isShowReviewQualityCheckModal) {
        this.setState({ isShowReviewQualityCheckModal: false });
      }

      const REVIEW_DATES = [
        // deadlines
        'deadlineNominate',
        'deadlineSelfReview',
        'deadlineUsersRequest',
        'deadlineCoachesRequest',
        'deadlineToSign',
        'deadlineOfPlanConversation',

        // dates
        'dateOfConversation',
        'dateOfConversationEnd',

        // startDates
        'startDateNominate',
        'startDateSelfReview',
        'startDateUsersRequest',
        'startDateCoachesRequest',
        'startDateOfPlanConversation',
        'startDateToSign',
      ];

      const review = {
        ...reviewFromStore,
        ...REVIEW_DATES.reduce((obj, key) => {
          obj[key] = convertToTimeString(reviewFromStore[key], TIME_FORMATS.ISO);
          return obj;
        }, {}),

        goalCyclesBusinessEval: reviewFromStore.goalCyclesBusinessEval.map(goalCycleIdMapper),
        goalCyclesLearningEval: reviewFromStore.goalCyclesLearningEval.map(goalCycleIdMapper),
        goalCyclesBusinessPlan: reviewFromStore.goalCyclesBusinessPlan.map(goalCycleIdMapper),
        goalCyclesLearningPlan: reviewFromStore.goalCyclesLearningPlan.map(goalCycleIdMapper),
        users: (reviewFromStore.users || []).map((u) => u.id || u),
        coaches: reviewFromStore.coaches || {},
        conversationCoaches: reviewFromStore.conversationCoaches || {},
        skills: (reviewFromStore.skills || []).map((s) => s.id || s),
        status: isDraft ? REVIEW_STATUSES.DRAFT.key : REVIEW_STATUSES.ACTIVE.key,
        template: reviewFromStore.template,
      };

      delete review.subReviews;
      delete review.meta;
      delete review.company;
      delete review.createdBy;
      delete review.createdIn;
      delete review.requests;
      delete review.ratings;

      reviewId ? await updateReview(id, review) : await createReview(review);

      this.handleClose();
    } finally {
      if (isDraft) {
        this.setState({ loading: false });
      } else {
        this.setState({ isShowPublishingModal: false, loadingLabel: null });
      }
    }
  };

  handleSaveDraft = () => this.onSubmit(true);
  handleSubmit = () => this.onSubmit(false);

  updateReview = async () => {
    const { review, i18n } = this.props;
    const { initReviewUsers, loading } = this.state;

    if (!(await this.makeStepsChecks()) || loading) {
      return;
    }

    this.setState({ loading: true });
    this.setState({
      isShowPublishingModal: true,
      loadingLabel: i18n._(t`We are updating your review`),
    });

    // update users
    const reviewUsersIds = initReviewUsers.map((u) => u.id || u);
    const updatedReviewUsersIds = review.users.map((u) => u.id || u);
    const usersToDelete = difference(reviewUsersIds, updatedReviewUsersIds);
    const usersToAdd = difference(updatedReviewUsersIds, reviewUsersIds);

    if (!isEmpty(usersToAdd)) {
      const coaches = getUsersSelectedCoaches(usersToAdd, review.coaches);
      const conversationCoaches = getUsersSelectedCoaches(usersToAdd, review.conversationCoaches);
      const jobProfilesPerUser = getUsersSelectedJobProfiles(usersToAdd, review.jobProfilesPerUser);
      const START_DATES = [
        // startDates
        'startDateNominate',
        'startDateSelfReview',
        'startDateUsersRequest',
        'startDateCoachesRequest',
        'startDateOfPlanConversation',
        'startDateToSign',
      ];
      await addUsersToReview(review.id, usersToAdd, {
        coaches,
        conversationCoaches,
        jobProfilesPerUser,
        startDates: {
          ...START_DATES.reduce((obj, key) => {
            obj[key] = convertToTimeString(review[key], TIME_FORMATS.ISO);
            return obj;
          }, {}),
        },
      });
    }
    if (!isEmpty(usersToDelete)) {
      await deleteUsersFromReview(review.id, usersToDelete);
    }

    const REVIEW_DATES_UPDATE = [
      // deadlines
      'deadlineNominate',
      'deadlineSelfReview',
      'deadlineUsersRequest',
      'deadlineCoachesRequest',
      'deadlineToSign',
      'deadlineOfPlanConversation',

      // startDates
      'startDateNominate',
      'startDateSelfReview',
      'startDateUsersRequest',
      'startDateCoachesRequest',
      'startDateOfPlanConversation',
      'startDateToSign',
    ];

    // update review
    const update = {
      name: review.name,
      description: review.description,
      ...REVIEW_DATES_UPDATE.reduce((obj, key) => {
        obj[key] = convertToTimeString(review[key], TIME_FORMATS.ISO);
        return obj;
      }, {}),
    };

    await updatePublishedReview(review.id, update);
    this.setState({ loading: false });
    this.setState({ isShowPublishingModal: false, loadingLabel: null });

    this.handleClose();
  };

  makeStepsChecks = async () => {
    const { toasts, i18n } = this.props;
    const review = await this.fetchFreshReviewUsers();
    let isPass = true;

    if (!didReviewUsersHaveValidJobProfiles(review)) {
      toasts.add({
        title: i18n._(t`Warning`),
        subtitle: i18n._(t`Not all participants have a role/ a role that has at least 1 skill`),
        type: TOAST_TYPES.ERROR,
      });
      isPass = false;
    }

    if (
      !isJobProfileSelectedForEveryUser({
        ...review,
        initReviewUsers: this.state.initReviewUsers?.map((u) => u.id),
      })
    ) {
      toasts.add({
        title: i18n._(t`Warning`),
        subtitle: i18n._(t`Not all participants have a selected role`),
        type: TOAST_TYPES.ERROR,
      });
      isPass = false;
    }

    if (!isValidGoalCycles(review)) {
      toasts.add({
        title: i18n._(t`Warning`),
        subtitle: i18n._(t`Select a goal cycle in order to publish the review`),
        type: TOAST_TYPES.ERROR,
      });
      isPass = false;
    }
    return isPass;
  };

  publishReview = async () => {
    this.setState({ isCheckingSteps: true });
    await this.makeStepsChecks();
    this.setState({ isShowReviewQualityCheckModal: true, isCheckingSteps: false });
  };

  getHeaderActions = (onSubmitFromSetupFlow) => {
    const {
      match: {
        params: { reviewId },
      },
      i18n,
      review,
    } = this.props;
    const { isShowPublishingModal, loading, isCheckingSteps } = this.state;
    const isUpdate = reviewId && review.status !== REVIEW_STATUSES.DRAFT.key;

    return (
      <>
        <Tooltip
          tooltip={`${
            isUpdate
              ? i18n._(t`Ready updating the conversation? Press ‘Save’ to save the changes`)
              : i18n._(t`Use this option to continue setting up your review at a later stage`)
          }.`}
        >
          <div>
            <Button
              label={
                reviewId
                  ? review.status === REVIEW_STATUSES.DRAFT.key
                    ? i18n._(t`Update draft`)
                    : i18n._(t`Save`)
                  : i18n._(t`Save as draft`)
              }
              type="primary-border"
              onClick={isUpdate ? this.updateReview : this.handleSaveDraft}
              disabled={!review.name || isShowPublishingModal || loading || isCheckingSteps}
            />
          </div>
        </Tooltip>
        {!isUpdate && (
          <Tooltip
            tooltip={`${i18n._(
              t`If you are ready to inform the participants and start the review press publish`,
            )}.`}
          >
            <div>
              <Button
                label={i18n._('Publish')}
                type="primary"
                onClick={onSubmitFromSetupFlow}
                disabled={!review.name || isShowPublishingModal || loading || isCheckingSteps}
                loading={isCheckingSteps}
              />
            </div>
          </Tooltip>
        )}
      </>
    );
  };

  render() {
    const {
      match: {
        params: { reviewId },
      },
      i18n,
      review,
    } = this.props;
    const {
      loading,
      initReviewUsers,
      isShowPublishingModal,
      loadingLabel,
      isShowReviewQualityCheckModal,
    } = this.state;
    const isUpdate = reviewId && review.status !== REVIEW_STATUSES.DRAFT.key;

    return (
      <>
        {isShowPublishingModal && <PublishingWaitModal subtitle={loadingLabel} marginTop="120px" />}
        {isShowReviewQualityCheckModal && (
          <ReviewQualityCheckModal
            onClose={() => this.setState({ isShowReviewQualityCheckModal: false })}
            handleDraft={this.handleSaveDraft}
            handlePublish={this.handleSubmit}
          />
        )}
        <SetupFlow
          setupLayoutWidth="1070px"
          noTopMargin={true}
          steps={isUpdate ? UPDATE_STEPS : CREATE_STEPS}
          title={isUpdate ? review.name : i18n._(t`Conversations`)}
          onClose={this.handleClose}
          item={review}
          componentsProps={{ initReviewUsers, isPublishedReview: isUpdate }}
          onSubmit={this.publishReview}
          loading={loading}
          isHidePublishButton={true}
          headerInstructions={i18n._(t`How to set-up a conversation`)}
          headerInstructionsUrl={
            'https://intercom.help/learned/en/articles/3950568-creating-a-review-round-as-admin-and-coach'
          }
          headerActions={this.getHeaderActions}
          bottomActions={
            reviewId &&
            !isUpdate && (
              <Button
                styles={{
                  height: '40px',
                  padding: '0px 15px',
                  fontSize: '12px',
                  textTransform: 'uppercase',
                  borderRadius: '6px',
                }}
                label={i18n._(t`Delete this review`)}
                type="primary-border"
                onClick={this.deleteThisReview}
                disabled={!reviewId}
                loading={loading}
              />
            )
          }
          contentHeader={
            <ContentHeaderWrapper>
              <ContentHeaderHeader>
                <Trans
                  id={isUpdate ? t`Update conversation` : t`Create development conversation`}
                />
              </ContentHeaderHeader>
              <ContentHeaderContent>
                <Trans>Select a template and determine a timeline</Trans>
              </ContentHeaderContent>
            </ContentHeaderWrapper>
          }
        />
      </>
    );
  }
}

export default withToasts(
  withI18n()(
    connect((state) => ({
      review: state.currentReview,
      app: state.app,
      currentRole: getSelectedRole(state),
    }))(withRouter(ReviewSetup)),
  ),
);
