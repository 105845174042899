import FileSaver from 'file-saver';
import isEmpty from 'lodash/isEmpty';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getSkill(id, join = []) {
  return cloudRequest(serverRoutes.skills.getSkill, { id, join });
}

export function getSkillByFilters(filters, join = []) {
  return cloudRequest(serverRoutes.skills.getSkillByFilters, {}, { filters, join });
}

/**
 *
 * @param [filters]
 * @param [filters.enabledSkillsOnly] - get skills according to company settings
 * @param [filters.hideDeleted] - skipp deleted skills
 * @param [filters.limit] - max return items count
 * @param [filters.search] - search skills by name
 * @returns {Promise<AxiosResponse<object>>}
 */
export function getSkills(
  filters = {
    enabledSkillsOnly: false,
    hideDeleted: false,
    limit: 0,
    search: '',
    categories: [],
    source: '',
    lang: 'en',
  },
) {
  return cloudRequest(serverRoutes.skills.getSkills, filters);
}

export function getSkillSets(filters = {}) {
  return cloudRequest(serverRoutes.skills.getSkillSets, filters);
}

export function createSkill(data) {
  const focusAreas = data.focusAreas;
  const skill = data.skill;
  return cloudRequest(serverRoutes.skills.createSkill, {}, { focusAreas, skill });
}

export function updateSkill(data, join = []) {
  const focusAreas = data.focusAreas;
  const skill = data.skill;
  return cloudRequest(
    serverRoutes.skills.updateSkill,
    { id: skill.id, join },
    { focusAreas, skill },
  );
}

export function updateEnabledSkillSetOfCompany(data) {
  return cloudRequest(serverRoutes.companies.updateEnabledSkillSetOfCompany, {}, data);
}

export function deleteSkill(id, join = []) {
  return cloudRequest(serverRoutes.skills.deleteSkill, { id, join });
}

export function getSkillsById(skillsIds = [], join) {
  return cloudRequest(serverRoutes.skills.getSkillsById, { skills: skillsIds.join(','), join });
}

/**
 *
 * @param skillId - string
 * @param members - string[]
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSkillReport(skillId, { reviewId, isLatest, members = [], pagination = {} }) {
  return cloudRequest(serverRoutes.skills.getSkillReport(skillId), {
    skillId,
    reviewId,
    ...(!isEmpty(members) && { members: JSON.stringify(members) }),
    pagination,
    isLatest,
  });
}

export function downloadSkillReport(skillId, { members = [], isLatest, reviewId }) {
  return cloudRequest(serverRoutes.skills.downloadSkillReportCSV, {
    skillId,
    isLatest,
    ...(!isLatest && { reviewId }),
    ...(!isEmpty(members) && { members: JSON.stringify(members) }),
  }).then((response) => {
    FileSaver.saveAs(response, 'skill_rating_per_member_report.csv');
  });
}

export function downloadSkillsReportCSV({
  isLatest,
  reviewId,
  sectionTypes,
  skills = [],
  members = [],
}) {
  return cloudRequest(serverRoutes.skills.downloadSkillsReportCSV, {
    isLatest,
    reviewId,
    sectionTypes,
    ...(!isEmpty(skills) && { skills: JSON.stringify(skills) }),
    ...(!isEmpty(members) && { members: JSON.stringify(members) }),
  }).then((response) => {
    FileSaver.saveAs(response, 'skill_rating_per_skill_report.csv');
  });
}

export function getSkillsReport({
  pagination = {},
  sectionTypes,
  skills = [],
  members = [],
  reviewId,
  isLatest,
} = {}) {
  return cloudRequest(serverRoutes.skills.getSkillsReport, {
    pagination: JSON.stringify(pagination),
    sectionTypes: sectionTypes ? JSON.stringify(sectionTypes) : null,
    ...(!isEmpty(members) && { members: JSON.stringify(members) }),
    ...(!isEmpty(skills) && { skills: JSON.stringify(skills) }),
    reviewId,
    isLatest,
  });
}

export function getSuggestedSkillsNames({ search, limit, categories = [] }) {
  return cloudRequest(serverRoutes.skills.getSuggestedSkillsNames, { search, limit, categories });
}

export function getSuggestedSkillsForJobtitle({ jobTitle, language, limit, group }) {
  return cloudRequest(serverRoutes.skills.getSuggestedSkillsForJobtitle, {
    jobTitle,
    language,
    limit,
    group,
  });
}

export function getSuggestedSkillsAliases({ search = '', limit }) {
  return cloudRequest(serverRoutes.skills.getSuggestedSkillsAliases, { search, limit });
}
