import styled from 'styled-components';

import { COLORS } from '~/styles';

const ModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.TEXT_HOVER};
  z-index: 1000;
`;

const ModalInner = styled.div`
  max-width: 475px;
  min-width: 475px;
  max-height: 80%;
  overflow: scroll;
  padding: 32px;
  background: ${COLORS.WHITE};
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  border-radius: 6px;
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  gap: 10px;
`;

const ModalTitleLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: ${COLORS.TEXT_MAIN};
`;

const ModalTitleRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  padding-top: 20px;
  border-top: solid 1px ${COLORS.BORDERS};
`;

const ModalBody = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
`;

const CrossButtonCtr = styled.div`
  cursor: pointer;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const LoadingContainerIcon = styled.div`
  flex-basis: 30%;
  svg {
    width: 32px;
    height: 32px;
    vertical-align: middle;
  }
`;

const LoadingContainerGreeting = styled.div`
  flex-basis: 70%;
  p {
    font-size: 9px;
    color: ${COLORS.COMPANY};
    overflow: hidden;
    white-space: pre;
    animation: typing 1.5s steps(15, end) forwards;
    animation-iteration-count: infinite; /* This makes the animation loop */
    animation-delay: 0s; /* This adds a 0-second pause between each loop */
    width: 0;
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 110%;
    }
  }
`;

const TimeFrameWrapper = styled.div`
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
`;

const AverageContainer = styled.div`
  display: flex;
  gap: 15px;
`;
const AverageContainerBar = styled.div`
  width: 10px;
  border-radius: 6px;
  background-color: ${COLORS.COMPANY};
`;
const AverageContainerDetails = styled.div`
  .admin-average {
    font-size: 27px;
  }
  .user-average {
    font-size: 24px;
    color: ${COLORS.CONFIRMATION_MODAL_INFO};
  }
  .user-team-average {
    font-size: 14px;
    color: ${COLORS.ACCENT_WARNING};
  }
  .user-company-average {
    font-size: 14px;
    color: ${COLORS.COMPANY};
  }
  .trend-indicator {
    display: flex;
    gap: 5px;
    flex-direction: column;
    text-align: left;
  }
`;

const ThemeDescription = styled.p`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
`;

const InsightRequestContainer = styled.div`
  .title {
    color: ${COLORS.TEXT_MAIN};
    font-size: 14px;
    font-weight: 600;
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90px;
    color: ${COLORS.TEXT_MAIN};
    font-weight: 600;
    background-color: ${COLORS.BG_LIST};
    border-radius: 6px;
  }
`;

const InsightResponseContainer = styled.div`
  .title {
    color: ${COLORS.TEXT_MAIN};
    font-size: 14px;
    font-weight: 600;
  }
  .body {
    display: flex;
    gap: 10px;
    flex-direction: row;
    min-height: 90px;
    color: ${COLORS.TEXT_MAIN};
    background-color: ${COLORS.BG_LIST};
    padding: 10px;
    border-radius: 6px;

    p {
      margin-top: 0px;
      white-space: pre-line;
      font-size: 14px;
    }

    .disclaimer {
      cursor: default;
      font-size: 10px;
      font-style: italic;
      color: ${COLORS.SUBTEXT};
      float: right;
    }
  }
`;

export {
  ModalWrapper,
  ModalFooter,
  ModalInner,
  ModalTitle,
  ModalTitleLeft,
  ModalTitleRight,
  ModalBody,
  CrossButtonCtr,
  LoadingContainer,
  LoadingContainerIcon,
  LoadingContainerGreeting,
  TimeFrameWrapper,
  AverageContainer,
  AverageContainerBar,
  AverageContainerDetails,
  ThemeDescription,
  InsightRequestContainer,
  InsightResponseContainer,
};
