import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';

import Modal from '~/components/Modal';
import TemplatePreviewBody from '~/pages/ReviewTemplatePreview/components/TemplatePreviewBody';

const TemplatePreviewModal = ({ onClose, template, i18n }) => {
  const isLearnedTemplate = template.company === 'superadmin';
  return (
    <Modal onClose={onClose} hideFooter={true} title={i18n._(t`Template preview`)}>
      <TemplatePreviewBody
        template={template}
        isLearnedTemplate={isLearnedTemplate}
        isModal={true}
      />
    </Modal>
  );
};

export default withI18n()(TemplatePreviewModal);
