import React from 'react';

import { ROLES } from '@learned/constants';
import { REVIEW_STATUSES } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DigitalMeeting } from '~/components/DigitalMeeting';
import RowSetDateOfConversation from '~/pages/ReviewSetup/components/RowSetDateOfConversation';

import { TaskRow } from './TaskRow';
import { Tip } from './Tip';

import { USER_INTEGRATION_TYPES } from '~/constants';
import { useUserCalendarIntegration } from '~/hooks/useUserHasIntegration';
import { getCurrentReview, getSelectedRole } from '~/selectors/baseGetters';
import { updateCurrentReview } from '~/store/currentReview/actions';
import { checkHasReviewCompleteLogic } from '~/utils/checkHasReviewCompleteLogic';

const Container = styled.div``;

const TipWrapper = styled.div`
  margin-bottom: 24px;
`;

const StyledDigitalMeeting = styled(DigitalMeeting)`
  margin-top: 16px;
`;

const ReviewTabWorkflow = ({ showErrors, isPublishedReview }) => {
  const dispatch = useDispatch();
  const { i18n } = useLingui();
  const currentRole = useSelector(getSelectedRole);
  const review = useSelector(getCurrentReview);
  const { hasCalendarIntegration, calendarIntegration } = useUserCalendarIntegration();
  const isCompleteLogic = checkHasReviewCompleteLogic(
    review?.templateItem?.digitalSign,
    get(review, 'meta.createdDate'),
  );

  const onChangeDate = (label) => (value) => {
    const dateUTC = moment.utc(moment(value)).format();

    const updateReview = {
      [label]: dateUTC,
    };

    // if deadlineDate < startDate => change startDate to deadline value
    const datesRelations = {
      deadlineNominate: 'startDateNominate',
      deadlineSelfReview: 'startDateSelfReview',
      deadlineUsersRequest: 'startDateUsersRequest',
      deadlineCoachesRequest: 'startDateCoachesRequest',
      deadlineOfPlanConversation: 'startDateOfPlanConversation',
      deadlineToSign: 'startDateToSign',
    };
    const startDateKey = datesRelations[label];

    if (startDateKey && moment(dateUTC).isBefore(review[startDateKey])) {
      updateReview[startDateKey] = dateUTC;
    }

    dispatch(updateCurrentReview(updateReview));
  };
  const onChangeDigitalMeeting = (value) =>
    void dispatch(updateCurrentReview({ includeLinkMeeting: value }));

  const onClickSwitch = (key, value) => {
    const updatedReview = {
      ...review,
      [key]: value,
    };
    dispatch(updateCurrentReview(updatedReview));
  };

  const options = [];

  if (review.isUsersReview) {
    options.push({
      id: 1,
      label: i18n._(t`Nominate peers`),
      description: i18n._(t`Participants are asked to select peers for input for the conversation`),
      onChangeStartDate: onChangeDate('startDateNominate'),
      onChangeDeadline: onChangeDate('deadlineNominate'),
      startDate: review.startDateNominate,
      deadline: review.deadlineNominate,
    });
  }

  if (review.isSelfReview) {
    options.push({
      id: 2,
      label: i18n._(t`Prepare for the conversation`),
      description: i18n._(t`Participants need to provide their input for their conversation`),
      onChangeStartDate: onChangeDate('startDateSelfReview'),
      onChangeDeadline: onChangeDate('deadlineSelfReview'),
      startDate: review.startDateSelfReview,
      deadline: review.deadlineSelfReview,
    });
  }

  if (review.isUsersReview) {
    options.push({
      id: 3,
      label: i18n._(t`Peer input`),
      description: i18n._(t`Nominated peers need to provide their input `),
      onChangeStartDate: onChangeDate('startDateUsersRequest'),
      onChangeDeadline: onChangeDate('deadlineUsersRequest'),
      startDate: review.startDateUsersRequest,
      deadline: review.deadlineUsersRequest,
    });
  }

  if (review.isCoachesReview) {
    options.push({
      id: 4,
      label: i18n._(t`Coach input`),
      description: i18n._(t`Nominated coaches need to provide their input `),
      onChangeStartDate: onChangeDate('startDateCoachesRequest'),
      onChangeDeadline: onChangeDate('deadlineCoachesRequest'),
      startDate: review.startDateCoachesRequest,
      deadline: review.deadlineCoachesRequest,
      chips: [
        review?.templateItem?.isCalibrate
          ? i18n._(t`Calibration enabled`)
          : i18n._(t`Calibration disabled`),
      ],
    });
  }

  // for admins -> deadline to plan conversation
  if (currentRole === ROLES.ADMIN) {
    // hide row, if review created by coach and published
    if (review.createdIn === ROLES.COACH && isPublishedReview) {
      // do nothing
    } else {
      options.push({
        id: 5,
        label: i18n._(t`Schedule the conversation`),
        description: i18n._(t`The participants needs to set a date and time for the conversation`),
        onChangeStartDate: onChangeDate('startDateOfPlanConversation'),
        onChangeDeadline: onChangeDate('deadlineOfPlanConversation'),
        startDate: review.startDateOfPlanConversation,
        deadline: review.deadlineOfPlanConversation,
        enableSwitch:
          // if it's NOT an old conversation without isPlanConversationTaskEnabled field or it's NOT CREATE/EDIT mode
          !isNil(review.isPlanConversationTaskEnabled) || !review.id
            ? {
                value: review.isPlanConversationTaskEnabled,
                title: i18n._(t`On/Off`),
                onClick: (value) => onClickSwitch('isPlanConversationTaskEnabled', value),
                // if it's EDIT mode and review status is NOT draft
                isDisabled: !!review.id && review.status !== REVIEW_STATUSES.DRAFT,
              }
            : undefined,
      });
    }
  }

  if (isCompleteLogic) {
    options.push({
      id: 6,
      label: i18n._(t`Complete the conversation`),
      description: i18n._(t`The participants need to complete the conversation`),
      onChangeStartDate: onChangeDate('startDateToSign'),
      onChangeDeadline: onChangeDate('deadlineToSign'),
      startDate: review.startDateToSign,
      deadline: review.deadlineToSign,
      chips: [
        review?.templateItem?.digitalSign
          ? i18n._(t`Digi sign enabled`)
          : i18n._(t`Digi sign disabled`),
      ],
    });
  }

  return (
    <>
      <TipWrapper>
        <Tip />
      </TipWrapper>

      {/* only for Coach*/}
      {currentRole === ROLES.COACH && (
        <TaskRow
          key={99}
          option={{
            label: i18n._(t`Date and time`),
            description: i18n._(t`Schedule the conversation`),
          }}
          isError={showErrors}
        >
          <RowSetDateOfConversation
            isPublished={isPublishedReview}
            startDate={review.dateOfConversation}
            endDate={review.dateOfConversationEnd}
          />
          {hasCalendarIntegration && calendarIntegration.hasOnlineMeetingProvider && (
            <StyledDigitalMeeting
              onChange={onChangeDigitalMeeting}
              checked={review.includeLinkMeeting}
              calendarType={calendarIntegration.type}
            />
          )}
          {hasCalendarIntegration &&
            calendarIntegration.type === USER_INTEGRATION_TYPES.azure &&
            !calendarIntegration.hasOnlineMeetingProvider && (
              <StyledDigitalMeeting
                tippyProps={{
                  content: (
                    <Trans>
                      Your integration email account does not have access to create Microsoft Teams
                      meetings
                    </Trans>
                  ),
                }}
                calendarType={calendarIntegration.type}
                disabled={true}
              />
            )}
        </TaskRow>
      )}
      <Container>
        {options.map((option) => (
          <TaskRow key={option.id} option={option} isError={showErrors} />
        ))}
      </Container>
    </>
  );
};

export default ReviewTabWorkflow;
