import React, { useEffect, useState } from 'react';

import {
  ACTIVITY_PROGRESS_TYPES,
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  PATH_STATUSES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ICONS } from '~/components/Icon';
import { ActivityModal } from '~/components/Modals/ActivityModal';
import { AddToPlanModal } from '~/components/Modals/AddToPlanModal';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { CreateLearningLibraryItemModal } from '~/components/Modals/CreateLearningLibraryItemModal';
import { EditLibraryActivityModal } from '~/components/Modals/EditLibraryActivityModal';
import { SelectLPModal } from '~/components/Modals/SelectLPModal';
import { ShowParticipantsActivityModal } from '~/components/Modals/ShowParticipantsActivityModal';
import { TableList } from '~/components/TableList';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { Filters } from '~/pages/OnboardAndLearn/tabs/AllLearningsTab/filters';

import { LEARNING_LIBRARY_COLUMNS } from './columns';

import { LEARNING_LIBRARY_SORT_BY } from '~/constants/learningLibrary';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import {
  checkLearningCreateActivityCoachModule,
  checkLearningCreateActivityUserModule,
  checkLearningCreatePathCoachModule,
  checkLearningCreatePathUserModule,
  checkModuleLearning,
  getCurrentCompanyId,
  getSelectedRole,
  getUser,
  isLearningActivitiesModuleEnabled,
} from '~/selectors/baseGetters';
import {
  copyOriginalActivity,
  deleteLibraryItems,
  deleteOriginalLibraryActivities,
  getLibraryItems,
} from '~/services/activities';
import { getActivityCategories } from '~/services/activityCategories';
import { getJobProfile } from '~/services/jobProfiles';
import { addPathUsers, copyPath } from '~/services/paths';
import { getRoleRelevancy } from '~/services/roleRelevancies';
import { getSkill } from '~/services/skills';
import {
  createUserActivityFromLibrary,
  getPersonalUserActivities,
  updateUserActivityProgress,
} from '~/services/userActivities';

import type { IFilterType, ILocalActivity, ILibraryItem } from './types';
import type { IUserActivity, IUserPath } from '@learned/types';
import type { IActivityProgressProps } from 'src/@types/activity';

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const DEBOUNCE_TIME = 300;

const LS_KEY = LS_KEYS.LS_ONBOARD_AND_LEARN_ALL;

const initialFilters = {
  isShow: false,
  search: '',
  skills: [],
  roles: [],
  activityCategories: [],
  activityTypes: [],
  activitySources: [],
  activityShowEditors: [],
  collections: [],
  sortBy: [LEARNING_LIBRARY_SORT_BY.LAST_UPDATED],
  rolesRelevancies: [],
  pagination: DEFAULT_PAGINATION,
};

const AllLearningsTab = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [activities, setActivities] = useState<{ [key: string]: ILocalActivity }>({});
  const [userActivities, setUserActivities] = useState<IUserActivity[]>([]);
  const [activity, setActivity] = useState<ILocalActivity | Partial<ILocalActivity> | null>(null);
  const [totalActivities, setTotalActivities] = useState<number>(0);
  const [checkedActivities, setCheckedActivities] = useState<
    Array<{ id: string; collection: string; owners: string[] }>
  >([]); // store id and collection to define proper method on delete with multiple select
  const [isDeleteActivityModalVisible, setIsDeleteActivityModalVisible] = useState(false);
  const [deleteActivityModalDescription, setDeleteActivityModalDescription] = useState('');
  const [deleteActivitiesIds, setDeleteActivitiesIds] = useState<string[]>([]);
  const isModuleLearningEnabled = useSelector(checkModuleLearning);
  const [isDeleteLibraryItemsModalVisible, setIsDeleteLibraryItemsModalVisible] = useState(false);
  const [deleteLibraryItemsModalDescription, setDeleteLibraryItemsModalDescription] = useState('');
  const [deleteLibraryItemsModalProps, setDeleteLibraryItemsModalProps] = useState<{
    activitiesIds: string[];
    pathsIds: string[];
  }>({
    activitiesIds: [],
    pathsIds: [],
  });

  const [isShowParticipantsActivityModal, setIsShowParticipantsActivityModal] = useState(false);
  const [isShowSelectLPModal, setIsShowSelectLPModal] = useState(false);
  const [isShowAddedToPlanModal, setIsShowAddedToPlanModal] = useState(false);
  const [isShowEditorsActivityModal, setIsShowEditorsActivityModal] = useState(false);
  const [isActivity, setIsActivity] = useState(false);
  const [isShowActivityModal, setIsShowActivityModal] = useState(false);
  const [isShowEditLibraryActivityModal, setIsShowEditLibraryActivityModal] = useState(false);
  const [isShowCreateLearningItemModal, setIsShowCreateLearningItemModal] = useState(false);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [activityCategories, setActivityCategories] = useState<{ [key: string]: { name: string } }>(
    {},
  );
  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
  const $loading = useBoolState(true);

  const user = useSelector(getUser);
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const currentRole = useSelector(getSelectedRole);
  const isLearningActivities = useSelector(isLearningActivitiesModuleEnabled);
  const isLearningCreateActivityCoachModuleEnabled = useSelector(
    checkLearningCreateActivityCoachModule,
  );
  const isLearningCreateActivityUserModuleEnabled = useSelector(
    checkLearningCreateActivityUserModule,
  );
  const isLearningCreatePathCoachModuleEnabled = useSelector(checkLearningCreatePathCoachModule);
  const isLearningCreatePathUserModuleEnabled = useSelector(checkLearningCreatePathUserModule);
  const { i18n } = useLingui();

  // debounce filters
  const debCurrentFilters = useDebounce(currentFilters, DEBOUNCE_TIME); // to send request not immediately, but with delay

  useEffect(() => {
    if (!isModuleLearningEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleLearningEnabled, history]);

  // init
  // add filter data from query
  useEffect(() => {
    let isMounted = true;

    // get filters from query
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { roleId, relevancyId, skillId } = queryParams;

    const preDefineFilters = async () => {
      let preselectedFilters = {};

      if (roleId || relevancyId || skillId) {
        // get filters from query
        const update: any = { isShow: true }; // show filters tab if some filter is pre-selected

        // add skill to filters, if one exist in DB
        if (skillId) {
          const skill = await getSkill(skillId);
          if (skill) {
            update.skills = [skill];
          }
        }

        // add role to filters, if one exist in DB
        if (roleId) {
          const role = await getJobProfile(roleId);
          if (role) {
            update.roles = [role];
          }
        }

        // add roleRelevancy to filters, if one exist in DB
        if (relevancyId) {
          const {
            data: { roleRelevancy },
          } = await getRoleRelevancy(relevancyId);
          if (roleRelevancy) {
            update.rolesRelevancies = [roleRelevancy];
          }
        }

        preselectedFilters = { ...update };
      }

      if (isMounted && !isEmpty(preselectedFilters)) {
        // we reset filters (if something in query)
        setCurrentFilters(() => ({ ...initialFilters, ...preselectedFilters }));
      }
    };

    preDefineFilters();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  // fetch library items (init and change of filters/pagination)
  useEffect(() => {
    let isMounted = true;

    const fetchItems = async () => {
      $loading.on();

      // pre-fetch data
      const userActivitiesTemp = isEmpty(userActivities)
        ? (Object.values(await getPersonalUserActivities()) as IUserActivity[])
        : userActivities;
      const activityCategoriesTemp = isEmpty(activityCategories)
        ? (await getActivityCategories())?.data[API_RETURN_FIELDS.ACTIVITY_CATEGORIES]
        : activityCategories;

      // @ts-ignore
      const {
        data: { activities: fetchedActivities, total: fetchedTotalActivities },
      } = await getLibraryItems(
        { includeParticipants: true },
        // @ts-ignore
        {
          pagination: currentFilters.pagination,
          search: currentFilters.search,
          types: (currentFilters.activityTypes || []).map((t: any) => t.key),
          collections: (currentFilters.collections || []).map((t: any) => t.key),
          skills: (currentFilters.skills || []).map((t: any) => t.id),
          sources: (currentFilters.activitySources || []).map((t: any) => t.key),
          show: (currentFilters.activityShowEditors || []).map((t: any) => t.key),
          sortBy: (currentFilters.sortBy || []).map((t: any) => t.key),
          roles: (currentFilters.roles || []).map((t: any) => t.id),
          rolesRelevancies: (currentFilters.rolesRelevancies || []).map((t: any) => t.id),
          activityCategories: (currentFilters.activityCategories || []).map((t: any) => t.id),
        },
      );

      if (isMounted) {
        Object.keys(fetchedActivities).map((key: string) => {
          const oActivity = fetchedActivities[key];
          const uActivity = userActivitiesTemp.find(
            (uActivity: IUserActivity) => uActivity.originalActivity === key,
          );

          // populate categories
          let updatedOriginalActivity = {
            ...oActivity,
            categoriesPopulated: (oActivity?.categories || [])
              .map((categoryId: string) => activityCategoriesTemp[categoryId])
              .filter((c: object | undefined) => c),
          };

          // add progress and status of user activities
          if (uActivity) {
            updatedOriginalActivity = {
              ...updatedOriginalActivity,
              progress: uActivity.progress,
              status: uActivity.status,
            };
          }

          fetchedActivities[key] = updatedOriginalActivity;
        });
        setActivities(fetchedActivities);
        setTotalActivities(fetchedTotalActivities);
        setActivityCategories(activityCategoriesTemp);
        setUserActivities(userActivitiesTemp);
        $loading.off();
      }
    };

    fetchItems();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [debCurrentFilters, forceUpdateCounter]);

  // use this method to force update, for instance after item is deleted from library and
  const onForceFetch = () => setForceUpdateCounter(forceUpdateCounter + 1);

  const onSaveActivity = () => {
    onForceFetch();
    setActivity(null);
    setIsShowEditLibraryActivityModal(false);
  };

  const setActivityToEnrolledInState = (originalActivityId: string, isEnrolled = true) => {
    const updatedActivities = { ...activities };

    updatedActivities[originalActivityId] = { ...activities[originalActivityId], isEnrolled };

    setActivities(updatedActivities);
  };

  const deleteActivities = async (ids: string[]) => {
    setIsDeleteActivityModalVisible(true);
    setDeleteActivityModalDescription(
      i18n._(
        t`Are you sure? Deleting the activity will delete the activity from the library, from all paths where this activity was added to and from the profile of all employees who have added this activity to their list.`,
      ),
    );
    setDeleteActivitiesIds(ids);
  };

  const onDeleteLibraryItems = async ({
    activitiesIds,
    pathsIds,
  }: {
    activitiesIds?: string[];
    pathsIds?: string[];
  }) => {
    setIsDeleteLibraryItemsModalVisible(true);
    setDeleteLibraryItemsModalDescription(
      i18n._(
        t`Are you sure? Deleting the library item(s) will delete the items(s) from the library, from all places where this item(s) was added to and from the profile of all employees who have added this item(s) to their list.`,
      ),
    );
    setDeleteLibraryItemsModalProps({
      activitiesIds: activitiesIds || [],
      pathsIds: pathsIds || [],
    });
  };

  const openCreateNewModal = () => {
    if (
      isLearningCreateActivityUserModuleEnabled ||
      (isLearningCreateActivityCoachModuleEnabled && user.isCoach) ||
      user.isAdmin
    ) {
      if (
        isLearningCreatePathUserModuleEnabled ||
        (isLearningCreatePathCoachModuleEnabled && user.isCoach) ||
        user.isAdmin
      ) {
        setIsShowCreateLearningItemModal(true);
      } else {
        openEditLibraryActivityModal();
      }
    }
  };

  const openEditLibraryActivityModal = () => {
    const newActivity: Partial<ILocalActivity> = {
      isAvailableInUserLibrary: true,
      createdInRole: currentRole,
      createdBy: user.id,
      company: currentCompanyId,
      progressType: ACTIVITY_PROGRESS_TYPES.IS_DONE,
      progressDetails: {
        min: null,
        max: null,
        currency: null,
      },
    };
    setActivity(newActivity);
    if (isShowCreateLearningItemModal) {
      setIsShowCreateLearningItemModal(false);
    }
    setIsShowEditLibraryActivityModal(true);
  };

  const onHideEditLibraryActivityModal = () => {
    setIsShowEditLibraryActivityModal(false);
    setActivity(null);
  };

  const isAllChecked = !Object.values(activities).find((activity) => {
    return checkedActivities.every((checkedItem) => checkedItem.id === activity.id);
  });
  const onCheckAll = () => {
    const checkedActivities = isAllChecked
      ? []
      : Object.values(activities).map((activity) => ({
          id: activity.id,
          collection: activity.collection,
          owners: activity.owners,
        }));
    setCheckedActivities(checkedActivities);
  };
  const onCheckItem = (item: ILibraryItem) => {
    const isChecked = checkedActivities.some((checked) => checked.id === item.id);
    setCheckedActivities(
      // @ts-ignore
      isChecked
        ? checkedActivities.filter((i) => i.id !== item.id)
        : [...checkedActivities, { id: item.id, collection: item.collection, owners: item.owners }],
    );
  };

  const duplicateActivity = async (activityId: string) => {
    const { data } = await copyOriginalActivity(activityId);
    const newActivity = data[API_RETURN_FIELDS.ACTIVITY];
    if (!isEmpty(newActivity)) {
      onForceFetch();
      addToast({
        title: i18n._(t`Activity duplicated`),
        subtitle: i18n._(t`You can find the activity copy in the library.`),
        type: TOAST_TYPES.INFO,
      });
    }
  };

  const duplicatePath = async (pathId: string) => {
    await copyPath(pathId);
    onForceFetch();
    addToast({
      title: i18n._(t`Path duplicated`),
      subtitle: i18n._(t`You can find the path copy in the library.`),
      type: TOAST_TYPES.INFO,
    });
  };

  const onDuplicateItem = (id: string, isActivity: boolean) => {
    if (isActivity) {
      duplicateActivity(id);
    } else {
      duplicatePath(id);
    }
  };

  const onEditItem = (id: string, isActivity: boolean) => {
    onForceFetch();
    if (isActivity) {
      onEditActivity(id);
    } else {
      onEditPath(id);
    }
  };

  const onEditPath = (id: string) => {
    routes.PATH_UPDATE.go({}, { isBackPath: true, pathId: id });
  };

  const onEditActivity = (activityId: string) => {
    const activity = activities[activityId];
    setActivity(activity);

    if (isShowActivityModal) {
      setIsShowActivityModal(false);
    }
    setIsShowEditLibraryActivityModal(true);
  };

  const onAddActivityToPlan = async (activity: ILocalActivity) => {
    await createUserActivityFromLibrary(activity.id, 'personal');
    setActivityToEnrolledInState?.(activity.id);
    setActivity(activity);
    setIsShowAddedToPlanModal(true);
    addToast({
      title: i18n._(t`Successfully added to plan`),
      subtitle: i18n._(t`Activity was added to plan`),
      type: TOAST_TYPES.INFO,
    });
  };

  const onAddPathToPlan = async (activityId: string) => {
    await addPathUsers(activityId, [user.id]);
    addToast({
      title: i18n._(t`Successfully added to plan`),
      subtitle: i18n._(t`Path was added to plan`),
      type: TOAST_TYPES.INFO,
    });
  };

  const onOpenCreatePathFlow = () => {
    routes.PATH_CREATE.go(
      {},
      {
        query: { users: [] },
        isBackPath: true,
      },
    );
  };

  const createMenuItems = (item: ILocalActivity) => {
    const isActivity = item.collection === 'activities';
    const isDraft = (item as ILibraryItem).status === PATH_STATUSES.DRAFT;

    const createAllowed =
      user.isAdmin ||
      (isActivity
        ? user.isCoach
          ? isLearningCreateActivityCoachModuleEnabled
          : isLearningCreateActivityUserModuleEnabled
        : user.isCoach
        ? isLearningCreatePathCoachModuleEnabled
        : isLearningCreatePathUserModuleEnabled);

    return [
      !isDraft &&
        !item?.isEnrolled && {
          label: i18n._(t`Add to plan`),
          action: isActivity ? () => onAddActivityToPlan(item) : () => onAddPathToPlan(item.id),
          icon: ICONS.ADD_PLUS,
        },
      (((item?.owners || []) as string[])?.includes(user.id) || user.isAdmin) && {
        label: i18n._(t`Edit`),
        action: () => onEditItem(item.id, isActivity),
        icon: ICONS.EDIT_PENCIL,
      },
      !isDraft &&
        ((createAllowed && ((item?.owners || []) as string[])?.includes(user.id)) ||
          user.isAdmin) && {
          label: i18n._(t`Duplicate`),
          action: () => onDuplicateItem(item.id, isActivity),
          icon: ICONS.DUPLICATE,
        },
      !isDraft &&
        (user.isAdmin || user.isCoach) && {
          label: i18n._(t`Participants`),
          action: () => {
            setActivity(item);
            setIsShowParticipantsActivityModal(true);
            setIsActivity(isActivity);
          },
          icon: ICONS.USERS,
        },
      (((item?.owners || []) as string[])?.includes(user.id) || user.isAdmin) && {
        label: i18n._(t`Delete`),
        action: isActivity
          ? () => onDeleteLibraryItems({ activitiesIds: [item.id as string] })
          : () => onDeleteLibraryItems({ pathsIds: [item.id as string] }),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ].filter((i) => i);
  };

  const onDeleteChecked = () =>
    onDeleteLibraryItems({
      pathsIds: checkedActivities.filter((i) => i.collection === 'paths').map((i) => i.id),
      activitiesIds: checkedActivities
        .filter((i) => i.collection === 'activities')
        .map((i) => i.id),
    });

  const onHideShowParticipantsActivityModal = (editors: string[], isEditor: boolean) => {
    setActivity?.({
      ...activity,
      ...(isEditor ? { owners: editors } : { users: editors }),
    });

    setIsShowParticipantsActivityModal(false);
    setIsShowEditorsActivityModal(false);
  };

  const onSaveActivityProgress = async (
    { ...update }: IActivityProgressProps,
    userActivity?: IUserActivity,
  ) => {
    if (userActivity) {
      const { data } = await updateUserActivityProgress(userActivity.id, update);
      if (data?.userActivity) {
        setActivities((prevState) => ({
          ...prevState,
          [data.userActivity.originalActivity]: {
            ...prevState[data.userActivity.originalActivity],
            progress: data.userActivity.progress,
            status: data.userActivity.status,
          },
        }));
      }
    }
  };

  const checkUserPermissionsForSelectedItems = () => {
    const isUserNotOwnerInSomeActivities = checkedActivities.some(
      (a) => !(a?.owners || []).includes(user.id),
    );
    return user.isAdmin || !isUserNotOwnerInSomeActivities;
  };

  const filters = {
    search: currentFilters.search as string,
    setSearch: (value: string) => {
      setCurrentFilters((prevState: IFilterType) => ({
        ...prevState,
        search: value,
        pagination: DEFAULT_PAGINATION, // reset pagination
      }));
    },

    // @ts-ignore
    onChangeFilter: (key, value) =>
      setCurrentFilters({ ...currentFilters, pagination: DEFAULT_PAGINATION, [key]: value }),
    isShow: false,
    skills: currentFilters.skills,
    roles: currentFilters.roles,
    activityCategories: currentFilters.activityCategories,
    activityTypes: currentFilters.activityTypes,
    activitySources: currentFilters.activitySources,
    activityShowEditors: currentFilters.activityShowEditors,
    collections: currentFilters.collections,
    sortBy: currentFilters.sortBy,
    rolesRelevancies: currentFilters.rolesRelevancies,
    pagination: currentFilters.pagination,
  };

  // create button visible if:
  // - USER role -> setting: user can create activity in library enabled
  // - COACH role -> setting: coach can create activity in library enabled
  // - ADMIN role -> always
  const isActionButtonVisible =
    isLearningCreateActivityUserModuleEnabled ||
    (isLearningCreateActivityCoachModuleEnabled && user.isCoach) ||
    user.isAdmin;
  const actionButton = {
    label: t`Create new`,
    onClick: openCreateNewModal,
  };

  const multiSelect = {
    checkedCount: checkedActivities.length,
    onCheckAll,
    onSelectItem: (item: ILibraryItem) => onCheckItem(item),
    isItemChecked: (item: ILibraryItem) => checkedActivities.map((a) => a.id).includes(item.id),
    isAllChecked,
    onDelete: checkUserPermissionsForSelectedItems() ? onDeleteChecked : undefined,
  };

  const onNameClick = {
    column: 'name',
    onClick: (item: ILibraryItem) => {
      if (item.collection === 'paths') {
        // redirect to path
        routes.PATH.go(
          {},
          {
            pathId: item.id,
            query: {
              isLibrary: true,
            },
            isBackPath: true,
          },
        );
      } else {
        // open activity modal
        setActivity(item);
        setIsShowActivityModal(true);
      }
    },
  };

  const changeFilters = async (filters: IFilterType) => {
    // reset pagination on filters change (so we always start from 1 page)
    const newFilters = { ...currentFilters, ...filters, pagination: DEFAULT_PAGINATION };

    // update state
    setCurrentFilters(newFilters);
  };

  const resetFilters = async () => {
    // do not change isShow when you reset filters
    const newFilters = { ...initialFilters, isShow: currentFilters.isShow };

    await changeFilters(newFilters);
  };

  return (
    <>
      <TableList
        data={Object.values(activities)}
        columns={LEARNING_LIBRARY_COLUMNS}
        onColClick={onNameClick}
        isDraftStatusVisible
        actionButton={isActionButtonVisible && actionButton}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount: totalActivities,
        }}
        isLoading={$loading.value}
        menuProps={{
          createMenuItems,
          isMenuVisible: true,
        }}
        multiSelectProps={{
          isMultiSelectVisible: true,
          multiSelect,
        }}
        placeholderProps={{
          noResultText: i18n._(t`No items found for you`),
          emptyStateText: i18n._(t`No items for you yet…`),
        }}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters?.search?.length,
          isToggleHideFilterVisible: true,
          filterComponents: (
            <Filters
              currentFilters={currentFilters}
              changeFilters={changeFilters}
              resetFilters={resetFilters}
              isLearningActivities={isLearningActivities}
              refetchData={onForceFetch}
              isAdmin={user?.isAdmin}
            />
          ),
        }}
      />

      {isShowActivityModal && activity && (
        <ActivityModal
          // @ts-ignore
          activityId={activity?.id}
          setActivity={setActivity}
          onClose={() => setIsShowActivityModal(false)}
          onSaveActivity={onSaveActivityProgress}
          setActivityToEnrolledInState={setActivityToEnrolledInState}
          onEditActivity={() => activity?.id && onEditItem(activity.id, true)}
          // @ts-ignore
          duplicateActivity={() => duplicateActivity(activity?.id)}
          isActionButtonsVisible
          isPlanningButtonsVisible
          onDeleteUserActivity={() => {
            activity?.id && setActivityToEnrolledInState(activity.id, false);
          }}
        />
      )}

      {isShowEditLibraryActivityModal && (
        <EditLibraryActivityModal
          // @ts-ignore
          currentActivity={activity}
          onHideModal={onHideEditLibraryActivityModal}
          // @ts-ignore
          onSaveActivity={onSaveActivity}
          deleteActivity={() => deleteActivities([(activity as ILocalActivity).id as string])}
        />
      )}
      {isShowCreateLearningItemModal && (
        <CreateLearningLibraryItemModal
          onClose={() => setIsShowCreateLearningItemModal(false)}
          onOpenCreateLibaryActivityModal={openEditLibraryActivityModal}
          onOpenCreatePathFlow={onOpenCreatePathFlow}
        />
      )}
      {(isShowParticipantsActivityModal || isShowEditorsActivityModal) && activity && (
        <ShowParticipantsActivityModal
          isEditor={isShowEditorsActivityModal}
          onClose={onHideShowParticipantsActivityModal}
          activity={isActivity ? (activity as IUserActivity) : undefined}
          path={!isActivity ? (activity as unknown as IUserPath) : undefined}
        />
      )}
      {isShowSelectLPModal && (
        <SelectLPModal
          setIsShowSelectLPModal={setIsShowSelectLPModal}
          setIsShowAddedToPlanModal={setIsShowAddedToPlanModal}
          // @ts-ignore
          activityId={activity?.id}
        />
      )}
      {isShowAddedToPlanModal && (
        <AddToPlanModal
          setIsShowAddedToPlanModal={setIsShowAddedToPlanModal}
          setIsShowSelectLPModal={setIsShowSelectLPModal}
        />
      )}
      {isDeleteActivityModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          description={deleteActivityModalDescription}
          onClose={() => setIsDeleteActivityModalVisible(false)}
          onSubmit={async () => {
            if (isShowEditLibraryActivityModal) {
              setIsShowEditLibraryActivityModal(false);
              setActivity(null);
            }
            await deleteOriginalLibraryActivities(deleteActivitiesIds);
            // force fetch
            onForceFetch();

            // clean all selected items
            setCheckedActivities([]);
          }}
        />
      )}
      {isDeleteLibraryItemsModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          description={deleteLibraryItemsModalDescription}
          onClose={() => setIsDeleteLibraryItemsModalVisible(false)}
          onSubmit={async () => {
            try {
              $loading.on();
              await deleteLibraryItems(deleteLibraryItemsModalProps);

              // force fetch
              onForceFetch();

              // clean all selected items
              setCheckedActivities([]);

              addToast({
                title: i18n._(t`Library item(s) deleted!`),
                subtitle: i18n._(t`Library item(s) are deleted from the learning library.`),
                type: TOAST_TYPES.INFO,
              });
            } finally {
              $loading.off();
            }
          }}
        />
      )}
    </>
  );
};

export { AllLearningsTab };
