import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import { NextSteps as NextStepsPage } from '~/pages/NextSteps';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('nextSteps', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.NEXT_STEPS.routePath(ROLES.USER)}
        exact
        path={routes.NEXT_STEPS.routePath(ROLES.USER)}
        component={NextStepsPage}
        allowedRoles={[ROLES.USER]}
      />,
    ],
  },
  routesPublic: [],
});
