import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { convertQuestionOptions } from '~/utils/surveyQuestions';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { ISurveyQuestion, ISurveyQuestionDefaultData } from '@learned/types';

function turnSurveyQuestionIntoQuestionForm(
  question: ISurveyQuestion,
  languageState: ILanguageStateReturn,
) {
  return {
    name: turnMultiLangIntoArray(question.name, languageState.companyLanguages),
    type: question.type,
    options: convertQuestionOptions(question, languageState.companyLanguages),
    settings: {
      isCommentsAllowed: (question?.data as ISurveyQuestionDefaultData)?.isCommentsAllowed ?? false,
      isCommentsObligated:
        (question?.data as ISurveyQuestionDefaultData)?.isCommentsObligated ?? false,
      isMeasurementReversed:
        (question?.data as ISurveyQuestionDefaultData)?.isMeasurementReversed ?? false,
    },
  };
}

export { turnSurveyQuestionIntoQuestionForm };
