import React, { Component } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import GiveFeedback from '~/components/GiveFeedback';
import { GiveFeedbackLocked } from '~/components/GiveFeedbackLocked';
import SetupLayout from '~/layouts/SetupLayout';

import { REQUEST_TYPES, RATING_TYPES, REQUEST_STATUSES } from '~/constants';
import getAppThemeUrl from '~/selectors/getAppThemeUrl';
import { getRequest } from '~/services/requests';
import { getReview } from '~/services/reviews';
import * as appActions from '~/store/app/actions';
import * as currentRequestActions from '~/store/currentRequest/actions';
import * as currentReviewActions from '~/store/currentReview/actions';
import { isTaskStartDateInThePast } from '~/utils/isTaskStartDateInThePast';

const Title = styled.div`
  text-transform: capitalize;
`;

export class ReviewGiveFeedback extends Component {
  state = {
    isFeedbackNonActive: true,
    isFeedbackSigned: true,
    isStartDateInFuture: false,
  };

  componentDidMount() {
    const { dispatch, match, app } = this.props;

    // clean currentReview and currentRequest
    dispatch(currentReviewActions.setCurrentReview());
    dispatch(currentRequestActions.setCurrentRequest());

    const requestId = get(match, 'params.requestId');
    const reviewId = get(match, 'params.reviewId');
    this.setState({ loading: true });
    Promise.all([
      getRequest(requestId),
      getReview(reviewId, {
        isQuestions: true,
        join: ['requests', 'ratings'],
        isInterviewee: true,
      }),
    ]).then(async ([request, review]) => {
      dispatch(currentReviewActions.setCurrentReview(review));
      dispatch(currentRequestActions.setCurrentRequest(request));

      const isStartDateInFuture = !isTaskStartDateInThePast(
        request.type === REQUEST_TYPES.FEEDBACK_FROM_COACH
          ? review.startDateCoachesRequest
          : review.startDateUsersRequest,
      );

      this.setState({
        loading: false,
        isFeedbackNonActive: [
          REQUEST_STATUSES.CANCELLED.key,
          REQUEST_STATUSES.REJECTED.key,
          REQUEST_STATUSES.EXPIRED.key,
        ].includes(request.status),
        isFeedbackSigned: (review?.signatures || []).length > 0,
        isStartDateInFuture,
      });
    });

    if (!app.isFieldsValidated) {
      dispatch(appActions.updateApp({ isFieldsValidated: true }));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(currentReviewActions.setCurrentReview());
    dispatch(currentRequestActions.setCurrentRequest());
  }

  handleClose = () => {
    const { dispatch, history } = this.props;
    const fromPath = new URL(window.location.href).searchParams.get('from');

    history.push(fromPath || '/');
    dispatch(currentReviewActions.setCurrentReview());
    dispatch(currentRequestActions.setCurrentRequest());
  };

  render() {
    const { currentReview, currentRequest, users, i18n, logoUrl } = this.props;
    const { isFeedbackNonActive, isFeedbackSigned, isStartDateInFuture, loading } = this.state;

    let ratingType;

    switch (currentRequest.type) {
      case REQUEST_TYPES.FEEDBACK_FROM_PEER:
        ratingType = RATING_TYPES.OTHER_RATING;
        break;
      case REQUEST_TYPES.FEEDBACK_FROM_COACH:
        ratingType = RATING_TYPES.COACH_RATING;
        break;
      case REQUEST_TYPES.FEEDBACK_FROM_OUTSIDE_PEER:
        ratingType = RATING_TYPES.OUTSIDE_RATING;
        break;
      default:
        break;
    }

    const getTitle = () => {
      let ratingTypeName = '';

      switch (ratingType) {
        case RATING_TYPES.OTHER_RATING: {
          ratingTypeName = i18n._(t`peer review`);
          break;
        }
        case RATING_TYPES.COACH_RATING: {
          ratingTypeName = i18n._(t`coach review`);
          break;
        }
        case RATING_TYPES.SELF_RATING: {
          ratingTypeName = i18n._(t`self review`);
          break;
        }
        default:
          break;
      }

      return ratingTypeName;
    };

    if (isFeedbackNonActive || isFeedbackSigned || isStartDateInFuture) {
      const startDate =
        currentRequest.type === REQUEST_TYPES.FEEDBACK_FROM_COACH
          ? currentReview.startDateCoachesRequest
          : currentReview.startDateUsersRequest;
      const startDateFormatted = isStartDateInFuture && moment(startDate).format('YYYY-MM-DD');
      const title =
        (isFeedbackNonActive &&
          i18n._(t`This feedback request is cancelled, rejected or expired.`)) ||
        (isFeedbackSigned &&
          i18n._(t`This review is already signed. You can not give feedback anymore.`)) ||
        (isStartDateInFuture && i18n._(t`This feedback request is not yet available.`));

      return (
        <SetupLayout
          width="1070px"
          title={<Title>{getTitle()}</Title>}
          onClose={this.handleClose}
          loading={loading}
        >
          <GiveFeedbackLocked
            logoUrl={logoUrl}
            createdFor={currentReview.createdFor}
            title={title}
            description={
              isStartDateInFuture && i18n._(t`You can provide feedback from ${startDateFormatted}`)
            }
          />
        </SetupLayout>
      );
    }

    return (
      !isEmpty(currentReview) &&
      !isEmpty(currentRequest) && (
        <GiveFeedback ratingType={ratingType} forUser={users[currentRequest.fromUser] || {}} />
      )
    );
  }
}

export default withI18n()(
  connect((state) => ({
    users: state.users.data,
    currentReview: state.currentReview,
    currentRequest: state.currentRequest,
    currentRole: state.selected.role,
    app: state.app,
    logoUrl: getAppThemeUrl(state),
  }))(withRouter(ReviewGiveFeedback)),
);
