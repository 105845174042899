import React from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { isEmpty } from 'lodash';
import get from 'lodash/get';
import styled from 'styled-components';

import AvatarCard from '~/components/UI/AvatarCard';
import Divider from '~/components/UI/Divider';

import convertToTimeString from '~/utils/convertToTimeString';

import ReviewLogs from '../components/ReviewLogs';

const ReviewTable = styled.table`
  padding: 24px;

  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
`;

const GoalCycleTitleRow = styled.tr`
  text-transform: uppercase;
`;

const FirstCell = styled.td`
  width: 260px;
  word-wrap: break-word;
`;

const TaskRow = styled.tr`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: #000000;
`;

const SecondaryCell = styled.td`
  width: 260px;
  word-wrap: break-word;
`;

const ReviewDataRow = styled.tr`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: #000000;
`;

const CreatedByContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

const Info = ({ review, i18n }) => {
  const createdAt = review && review.meta && new Date(review.meta.createdDate);
  const isGoalReview = !!(
    review.goalCyclesBusinessEval.length ||
    review.goalCyclesBusinessPlan.length ||
    review.goalCyclesLearningEval.length ||
    review.goalCyclesLearningPlan.length
  );
  const getCreatedBy = () => {
    return (
      <CreatedByContainer>
        <AvatarCard userId={review.createdBy} showTooltip={false} type={AvatarCard.TYPES.LARGE} />
      </CreatedByContainer>
    );
  };

  const potentialTasks = {
    deadlineOfPlanConversation: i18n._(t`Plan conversation`),
    ...(review.isUsersReview && { deadlineNominate: i18n._(t`Ask peers for input`) }),
    ...(review.isSelfReview && { deadlineSelfReview: i18n._(t`Prepare for the conversation`) }),
    ...(review.isUsersReview && { deadlineUsersRequest: i18n._(t`Peers provide input`) }),
    ...(review.isCoachesReview && { deadlineCoachesRequest: i18n._(t`Coach provide input`) }),
    ...(review.deadlineToSign && { deadlineToSign: i18n._(t`Digital Sign`) }),
  };

  const getTasks = () => {
    const ret = [];
    const sortedTasks = Object.keys(potentialTasks)
      .map((i) => {
        return { key: i, deadline: review[i] };
      })
      .filter((i) => !isEmpty(i.deadline))
      .sort((a, b) => new Date(a.deadline) - new Date(b.deadline));

    sortedTasks.map(({ key, deadline }) =>
      ret.push(
        <TaskRow key={key}>
          <FirstCell>{potentialTasks[key]}</FirstCell>
          <SecondaryCell>{convertToTimeString(deadline)}</SecondaryCell>
        </TaskRow>,
      ),
    );
    return ret;
  };

  return (
    <>
      <Divider />
      <ReviewTable>
        <tbody>
          <tr>
            <FirstCell>
              <Trans>CREATED BY</Trans>
            </FirstCell>
            <SecondaryCell>
              <Trans>CREATED AT</Trans>
            </SecondaryCell>
            <SecondaryCell>
              <Trans>PARTICIPANTS</Trans>
            </SecondaryCell>
            <SecondaryCell>
              <Trans>TEMPLATE NAME</Trans>
            </SecondaryCell>
          </tr>
          <ReviewDataRow>
            <FirstCell>{getCreatedBy()}</FirstCell>
            <SecondaryCell>{convertToTimeString(createdAt)}</SecondaryCell>
            <SecondaryCell>{review && review.users && review.users.length}</SecondaryCell>
            <SecondaryCell>{get(review, 'template.name', 'N/A')}</SecondaryCell>
          </ReviewDataRow>
        </tbody>
      </ReviewTable>
      <Divider />
      {isGoalReview && (
        <>
          <ReviewTable>
            <tbody>
              <GoalCycleTitleRow>
                <FirstCell>
                  <Trans>Goal cycle - Business goals review</Trans>
                </FirstCell>
                <SecondaryCell>
                  <Trans>Goal cycle - Business goals plan</Trans>
                </SecondaryCell>
                <SecondaryCell>
                  <Trans>Goal cycle - Learning goals review</Trans>
                </SecondaryCell>
                <SecondaryCell>
                  <Trans>Goal cycle - Learning goals plan</Trans>
                </SecondaryCell>
              </GoalCycleTitleRow>
              <ReviewDataRow>
                <FirstCell>
                  {review.goalCyclesBusinessEval.length
                    ? review.goalCyclesBusinessEval[0].name
                    : i18n._(t`N/A`)}
                </FirstCell>
                <SecondaryCell>
                  {review.goalCyclesBusinessPlan.length
                    ? review.goalCyclesBusinessPlan[0].name
                    : i18n._(t`N/A`)}
                </SecondaryCell>
                <SecondaryCell>
                  {review.goalCyclesLearningEval.length
                    ? review.goalCyclesLearningEval[0].name
                    : i18n._(t`N/A`)}
                </SecondaryCell>
                <SecondaryCell>
                  {review.goalCyclesLearningPlan.length
                    ? review.goalCyclesLearningPlan[0].name
                    : i18n._(t`N/A`)}
                </SecondaryCell>
              </ReviewDataRow>
            </tbody>
          </ReviewTable>
          <Divider />
        </>
      )}
      <ReviewTable>
        <tbody>
          <tr>
            <FirstCell>
              <Trans>TASKS</Trans>
            </FirstCell>
            <SecondaryCell>
              <Trans>DEADLINES</Trans>
            </SecondaryCell>
          </tr>
          {getTasks()}
        </tbody>
      </ReviewTable>
      <Divider />
      <ReviewLogs reviewId={review.id} />
    </>
  );
};

export default withI18n()(Info);
