export default function (arr = []) {
  const arrWithoutDuplicates = [...new Set(arr)];

  let duplicates = [...arr];
  arrWithoutDuplicates.forEach((item) => {
    const i = duplicates.indexOf(item);
    duplicates = duplicates.slice(0, i).concat(duplicates.slice(i + 1, duplicates.length));
  });

  return duplicates;
}
