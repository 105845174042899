import React, { useRef } from 'react';

import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import styled from 'styled-components';

import SearchListItem from '~/components/AutocompleteFilter/SearchListItem';
import { DateRangePicker } from '~/components/DateRangePicker';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Box = styled.div<{ $error?: boolean; isSelected?: boolean }>`
  height: 32px;
  border-radius: 6px;
  border: solid 1px ${(props) => (props.$error ? COLORS.ERROR : COLORS.BORDER_LIGHT)};
  background-color: ${COLORS.WHITE};
  color: ${COLORS.TEXT_SECONDARY};
  min-width: 150px;
  padding: ${(props) => (props.isSelected ? '0 8px 0 4px' : '8px')};
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SelectedItem = styled.div<{ background?: string }>`
  background: ${({ background }) => (background ? background : COLORS.BG_PAGE)};
  color: ${({ background }) => (background ? 'white' : COLOR_PALETTE.DARK_GRAY)};
  padding: 1px 8px;
  border-radius: 4px;
  margin-right: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

type CalendarDropdownProps = {
  disabled: boolean;
  className?: string;
  error?: boolean;
  selectedItem?: DateOption;
  setSelectedItem?: (arg0: DateOption | undefined) => void;
  chevronWidth?: number;
  chevronHeight?: number;
  filterOptions?: number[];
  showReset?: boolean;
};

const ChevronIcon = styled(Icon)`
  margin-left: auto;
  min-width: 12px;
  min-height: 12px;
`;

const PoperWrap = styled.div`
  outline: none;
`;

const DropdownWrap = styled.div<{ width?: string }>`
  background: ${COLOR_PALETTE.WHITE};
  border-radius: 6px;
  overflow: hidden;
  width: ${(props) => props.width || '238px'};
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  outline: none;
`;

const OptionList = styled(Scrollbars)``;

export type DateOption = {
  id: number;
  title: (arg0: I18n) => string;
  fromDate?: Date;
  toDate?: Date;
  isCustom?: boolean;
};

const DateOptions = (filterOptions?: number[]): DateOption[] => {
  const currentDateTime = new Date();
  const currentDate = new Date(
    currentDateTime.getFullYear(),
    currentDateTime.getMonth(),
    currentDateTime.getDate(),
  );

  return [
    {
      id: 1,
      title: (i18n: I18n) => i18n._(t`Last 30 days`),
      fromDate: new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate() - 30,
      ),
      toDate: currentDate,
      isCustom: false,
    },
    {
      id: 2,
      title: (i18n: I18n) => i18n._(t`Last 90 days`),
      fromDate: new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate() - 90,
      ),
      toDate: currentDate,
      isCustom: false,
    },
    {
      id: 3,
      title: (i18n: I18n) => i18n._(t`Last 6 months`),
      fromDate: new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth() - 6,
        currentDateTime.getDate(),
      ),
      toDate: currentDate,
      isCustom: false,
    },
    {
      id: 4,
      title: (i18n: I18n) => i18n._(t`Last year`),
      fromDate: new Date(
        currentDateTime.getFullYear() - 1,
        currentDateTime.getMonth(),
        currentDateTime.getDate(),
      ),
      toDate: currentDate,
      isCustom: false,
    },
    {
      id: 5,
      title: (i18n: I18n) => i18n._(t`Next 30 days`),
      toDate: new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate() + 30,
      ),
      fromDate: currentDate,
      isCustom: false,
    },
    {
      id: 6,
      title: (i18n: I18n) => i18n._(t`Next 90 days`),
      toDate: new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate() + 90,
      ),
      fromDate: currentDate,
      isCustom: false,
    },
    {
      id: 7,
      title: (i18n: I18n) => i18n._(t`Next 6 months`),
      toDate: new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth() + 6,
        currentDateTime.getDate(),
      ),
      fromDate: currentDate,
      isCustom: false,
    },
    {
      id: 8,
      title: (i18n: I18n) => i18n._(t`Next year`),
      toDate: new Date(
        currentDateTime.getFullYear() + 1,
        currentDateTime.getMonth(),
        currentDateTime.getDate(),
      ),
      fromDate: currentDate,
      isCustom: false,
    },
    {
      id: 9,
      title: (i18n: I18n) => i18n._(t`Custom timeframe`),
      isCustom: true,
    },
  ].filter((i) => (filterOptions?.length ? filterOptions.includes(i.id) : true));
};

const CalendarDropdown = ({
  disabled = false,
  className,
  error = false,
  selectedItem,
  setSelectedItem,
  filterOptions,
  showReset = false,
}: CalendarDropdownProps) => {
  const ref = useRef();
  const $open = useBoolState(false);
  const $isCustomDateModalVisible = useBoolState(false);
  const { i18n } = useLingui();

  const ResetOption: DateOption = {
    id: 0,
    title: (i18n: I18n) => i18n._(t`Reset filters`),
  };

  const handleSelect = (option: DateOption) => {
    if (option.isCustom) {
      $isCustomDateModalVisible.on();
      $open.off();
      return;
    }
    setSelectedItem && setSelectedItem(option.toDate ? option : undefined);
  };

  const dateOptions = DateOptions(filterOptions);
  const renderPopover = () => {
    return $open.value ? (
      // @ts-ignore
      <PoperWrap tabIndex="0">
        <DropdownWrap>
          <OptionList autoHeightMin={200} autoHeight>
            {showReset && (
              <SearchListItem
                // @ts-ignore
                option={ResetOption}
                // @ts-ignore
                labelProperty={(i: DateOption) => i.title(i18n)}
                onChange={handleSelect}
                isSelected={false}
              />
            )}
            {dateOptions.map((o: DateOption, index) => (
              <SearchListItem
                key={o.id || index}
                // @ts-ignore
                option={o}
                // @ts-ignore
                labelProperty={(i: DateOption) => i.title(i18n)}
                onChange={handleSelect}
                isSelected={o.id === selectedItem?.id}
              />
            ))}
          </OptionList>
        </DropdownWrap>
      </PoperWrap>
    ) : null;
  };

  return (
    <>
      {/* @ts-ignore */}
      <Box
        onClick={!disabled ? $open.toggle : undefined}
        ref={ref}
        className={className}
        $error={error}
        isSelected={selectedItem}
      >
        {selectedItem ? (
          <SelectedItem>
            {selectedItem.title
              ? selectedItem.title(i18n)
              : DateOptions([selectedItem.id])[0].title(i18n)}
          </SelectedItem>
        ) : (
          i18n._(t`Date range`)
        )}
        {!disabled && (
          <ChevronIcon
            icon={ICONS.DROPDOWN}
            color={!selectedItem ? COLORS.SUBTEXT : COLORS.COMPANY}
            size={ICON_SIZES.SMALL}
          />
        )}
      </Box>
      <Tippy
        visible
        placement="bottom-start"
        onClickOutside={$open.off}
        interactive
        maxWidth={650}
        offset={[0, 3]}
        // @ts-ignore
        reference={ref}
        render={renderPopover}
      />
      {$isCustomDateModalVisible.value && (
        <DateRangePicker
          onClose={(from, to) => {
            $isCustomDateModalVisible.off();
            setSelectedItem &&
              to &&
              from &&
              setSelectedItem({
                id: 9,
                title: (i18n: I18n) => i18n._(t`Custom timeframe`),
                ...(to && { toDate: new Date(to) }),
                ...(from && { fromDate: new Date(from) }),
              });
          }}
        />
      )}
    </>
  );
};

export { CalendarDropdown };
