import React, { ReactNode } from 'react';

import { Background, StyledLabel } from './design';

export interface LabelProps {
  color?: string;
  backgroundColor?: string;
  backgroundByPercentage?: number;
  disabled?: boolean;
  children: ReactNode;
  className?: string;
}

const Label = ({
  color = '#8389A0',
  backgroundColor = 'transparent',
  backgroundByPercentage,
  disabled = false,
  children,
  className,
}: LabelProps) => {
  return (
    <StyledLabel
      color={color}
      backgroundColor={!backgroundByPercentage ? backgroundColor : 'transparent'}
      disabled={disabled}
      className={className}
    >
      {backgroundByPercentage && (
        <Background backgroundByPercentage={backgroundByPercentage} color={color} />
      )}
      {children}
    </StyledLabel>
  );
};

export default Label;
