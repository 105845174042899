import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import DatePicker from '~/components/DatePickerDeprecated';

import * as currentReviewActions from '~/store/currentReview/actions';
import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DatePickerWrapper = styled.div`
  margin-right: 25px;
`;

const PublishedInfo = styled.div`
  width: 400px;
  display: flex;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const InputLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
  margin-right: 16px;
`;

const RowSetDateOfConversation = ({ startDate, endDate, isPublished }) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const date = startDate;
  const startTime = startDate;
  const endTime = endDate;

  const onChangeDate = (value) => updateDateTime(value, 'date');
  const onChangeStartTime = (value) => updateDateTime(value, 'startTime');
  const onChangeEndTime = (value) => updateDateTime(value, 'endTime');
  const updateDateInReview = (data) => dispatch(currentReviewActions.updateCurrentReview(data));

  const updateDateTime = (value, type) => {
    switch (type) {
      case 'date': {
        const end = moment(endDate);
        updateDateInReview({
          dateOfConversation: moment(value).format(),
          dateOfConversationEnd: moment(value).hours(end.hours()).minutes(end.minutes()).format(),
        });
        break;
      }
      case 'startTime': {
        const newStartTime = moment(value);
        const start = moment(startDate).hours(newStartTime.hours()).minutes(newStartTime.minutes());
        updateDateInReview({ dateOfConversation: start.format() });

        // change endTime, if it's before startTime
        const isStartDateAfterEndDate = start.isAfter(moment(endDate));
        if (isStartDateAfterEndDate) {
          updateDateInReview({ dateOfConversationEnd: start.add(15, 'minutes').format() });
        }
        break;
      }
      case 'endTime': {
        const newEndTime = moment(value);
        const end = moment(endDate).hours(newEndTime.hours()).minutes(newEndTime.minutes());
        updateDateInReview({ dateOfConversationEnd: end.format() });

        // change startTime, if it's after endTime
        const isStartDateAfterEndDate = moment(startDate).isAfter(end);
        if (isStartDateAfterEndDate) {
          updateDateInReview({ dateOfConversation: end.subtract(15, 'minutes').format() });
        }
        break;
      }
      default:
        break;
    }
  };
  return (
    <Wrapper>
      {isPublished ? (
        <PublishedInfo>
          <Trans>You can change date of conversation in dashboard</Trans>
        </PublishedInfo>
      ) : (
        <Row>
          <InputLabel>
            <Trans>Date</Trans>
          </InputLabel>
          <DatePickerWrapper>
            <DatePicker width={160} height={40} selected={date} onChange={onChangeDate} />
          </DatePickerWrapper>

          <InputLabel>
            <Trans>From</Trans>
          </InputLabel>
          <DatePickerWrapper>
            <DatePicker
              showTimeSelect={true}
              showTimeSelectOnly={true}
              placeholder={i18n._(t`11:30`)}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              width={70}
              height={40}
              timeIntervals={15}
              selected={startTime}
              onChange={onChangeStartTime}
              timeCaption="time"
              isHideIcon
              padding="0"
              textAlign="center"
            />
          </DatePickerWrapper>

          <InputLabel>
            <Trans>To</Trans>
          </InputLabel>
          <DatePickerWrapper>
            <DatePicker
              showTimeSelect={true}
              showTimeSelectOnly={true}
              placeholder={i18n._(t`12:30`)}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              width={70}
              height={40}
              timeIntervals={15}
              selected={endTime}
              onChange={onChangeEndTime}
              timeCaption="time"
              isHideIcon
              padding="0"
              textAlign="center"
            />
          </DatePickerWrapper>
        </Row>
      )}
    </Wrapper>
  );
};

RowSetDateOfConversation.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  isPublishedReview: PropTypes.bool,
};

export default RowSetDateOfConversation;
