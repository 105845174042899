import React, { useEffect, useState } from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import InfoIcon from '~/components/Icons/Info';
import DigitallySignPreview from '~/pages/ReviewTemplatePreview/components/DigitallySignPreview';
import SectionPreview from '~/pages/ReviewTemplatePreview/components/SectionPreview';

import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getCompanySkillCategories } from '~/services/companySettings';
import { getSkillCategories } from '~/services/skillCategories';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Container = styled.div`
  border-radius: 6px;
  padding: 16px;
  background-color: ${COLOR_PALETTE.WHITE};
  cursor: default !important;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TemplateTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${COLOR_PALETTE.BLACK};
`;

const TemplateSubTitle = styled.div`
  margin-left: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const InfoContainer = styled.div`
  margin: 16px 0 24px 0;
  padding: 12px 8px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InfoText = styled.div`
  margin-right: 4px;
  font-size: 14px;
  line-height: 1.57;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const TemplatePreviewBody = ({ template, isLearnedTemplate, isModal = false }) => {
  const [categories, setCategories] = useState([]);
  const company = useSelector(getCurrentCompany);
  const { i18n } = useLingui();
  useEffect(
    () => {
      let isMounted = true;
      const isJobProfileQuestion = template.sections.some((s) =>
        s.questions.some((q) => q.type === QUESTION_TYPES.JOB_PROFILE),
      );
      const fetchData = async () => {
        const [skillCategories, categoryOrder] = await Promise.all([
          getSkillCategories(),
          getCompanySkillCategories(company.id),
        ]);
        const orderedCategories = categoryOrder.map((c) => skillCategories[c]);
        if (isMounted) {
          setCategories(orderedCategories);
        }
      };
      if (isJobProfileQuestion) {
        fetchData();
      }
      return () => (isMounted = false);
    },
    // eslint-disable-next-line
    [],
  );
  return (
    <Container>
      {!isModal && (
        <TitleContainer>
          <TemplateTitle>{template.name}</TemplateTitle>
          <TemplateSubTitle>
            <Trans>Preview</Trans>
          </TemplateSubTitle>
        </TitleContainer>
      )}
      <InfoContainer>
        <InfoIcon backgroundColor={COLOR_PALETTE.WHITE} />
        <InfoText>
          {i18n._(
            isLearnedTemplate
              ? t`This is a preview of a Learned template. To make changes to this template, duplicate it to your company templates`
              : t`This is a preview of the template. To update this template, go to  your admin profile.`,
          )}
        </InfoText>
      </InfoContainer>
      {template.sections.map((section, index) => {
        return (
          <SectionPreview
            key={section.id}
            section={section}
            sectionCount={index + 1}
            amountOfSections={template.sections.length}
            categories={categories}
          />
        );
      })}
      <DigitallySignPreview />
    </Container>
  );
};

export default React.memo(TemplatePreviewBody);
