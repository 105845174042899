import React, { useEffect, useState } from 'react';

import { CONNECTION_STATUSES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { confirm } from '~/components/ConfirmDialog';
import { Icon, ICONS } from '~/components/Icon';
import SelectedCoachesRow from '~/components/SelectedCoachesRow';
import Tooltip from '~/components/Tooltip';
import { TableCol } from '~/components/UI/Table';
import UserListPicker from '~/components/UserListPicker';

import ProfileBox from './ProfileBox';
import ProfileList from './ProfileList';

import { REVIEW_STATUSES, ROLES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import useDataStore from '~/hooks/useDataStore';
import { getCurrentReview, getSelectedRole, getUser } from '~/selectors/baseGetters';
import getCurrentTeam from '~/selectors/getCurrentTeam';
import { getCompanyUsers } from '~/services/users';
import * as currentReviewActions from '~/store/currentReview/actions';
import { COLORS } from '~/styles';
import getUsersForCurrentTeam from '~/utils/getUsersForCurrentTeam';

// utils
import {
  hasJobProfileQuestion,
  hasGoalBusinessEvalQuestion,
  hasGoalLearningEvalQuestion,
  getUserGoalsBusinessEval,
  getUserGoalsLearningEval,
  getEvalGoalCyclesIDs,
  hasTemplateCoachesEnabled,
  getSelectedCoaches,
  checkSelectedJobProfilesPerUser,
  getReviewSkillCategories,
} from '../../helpers';

const Error = styled.div`
  color: ${COLORS.ERROR};
`;

const PeopleContainer = styled.div`
  margin: ${(props) => (props.$isCoach ? '-20px -16px 0' : '0 -16px')};
`;

const List = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
`;

const SelectIconWrapp = styled.span`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  margin-bottom: -4px;
`;

const COACHES_TYPE = {
  CONVERSATIONS: 'conversations',
  REVIEWS: 'reviews',
};

const SelectAllIconComponent = ({ isSelected, onClick }) => (
  <SelectIconWrapp onClick={onClick}>
    <Icon
      icon={ICONS.DUPLICATE}
      fill={isSelected ? COLORS.COMPANY : COLORS.SUBTEXT}
      color={isSelected ? COLORS.COMPANY : COLORS.SUBTEXT}
    />
  </SelectIconWrapp>
);

const getColsForUser = ({
  isJobReview = false,
  isGoalBusinessEvalReview = false,
  isGoalLearningEvalReview = false,
  isReviewWithCoaches = false,
  review,
  i18n,
  onCoachToggle,
  onConversationCoachToggle,
  initReviewUsers,
  isCoach,
  currentUser,
  selectAllCoaches,
  isAllConvCoachesSelected,
  isAllCoachesSelected,
  users,
  usersAvailable,
  originalCoaches,
  showErrors,
  removeParticipant = () => undefined,
  setConversationParticipants = (_arg0) => undefined,
} = {}) =>
  [
    isJobReview && {
      title: t`Role profile`,
      render(user) {
        // do not display jobProfiles for published reviews.
        const isReadOnly = review.id && review.status !== REVIEW_STATUSES.DRAFT.key;
        const isInitUser = initReviewUsers.some((u) => user.id === u.id);

        return (
          <TableCol>
            <ProfileList
              user={user}
              review={review}
              isError={showErrors}
              isReadOnly={isReadOnly && isInitUser}
            />
          </TableCol>
        );
      },
    },
    (isGoalBusinessEvalReview || isGoalLearningEvalReview) && {
      title: t`Goal Evaluation`,
      render(user) {
        const renderBusinessGoals = () => {
          const cycleGoals = getUserGoalsBusinessEval(user, review);
          const name = `${cycleGoals.length} ${
            cycleGoals.length > 1 ? i18n._(t`goals`) : i18n._(t`goal`)
          }`;
          return (
            <List>
              {cycleGoals.length > 0 ? (
                <>
                  <Trans>Business</Trans>:<ProfileBox name={name} />
                </>
              ) : (
                <Tooltip
                  maxWidth="300px"
                  tooltip={
                    <Trans>
                      This person does not have goals connected to the selected goal cycle. This
                      will result in an empty review section.
                    </Trans>
                  }
                >
                  <Error>
                    <Trans>No business goals</Trans>
                  </Error>
                </Tooltip>
              )}
            </List>
          );
        };
        const renderLearningGoals = () => {
          const cycleGoals = getUserGoalsLearningEval(user, review);
          const name = `${cycleGoals.length} ${
            cycleGoals.length > 1 ? i18n._(t`goals`) : i18n._(t`goal`)
          }`;
          return (
            <List>
              {cycleGoals.length > 0 ? (
                <>
                  <Trans>Learning</Trans>:<ProfileBox name={name} />
                </>
              ) : (
                <Tooltip
                  maxWidth="300px"
                  tooltip={
                    <Trans>
                      This person does not have goals connected to the selected goal cycle. This
                      will result in an empty review section.
                    </Trans>
                  }
                >
                  <Error>
                    <Trans>No learning goals</Trans>
                  </Error>
                </Tooltip>
              )}
            </List>
          );
        };
        return (
          <TableCol>
            {isGoalBusinessEvalReview && renderBusinessGoals()}
            {isGoalLearningEvalReview && renderLearningGoals()}
          </TableCol>
        );
      },
    },

    // do not display column, if template does not have coaches as interviewee
    isReviewWithCoaches && {
      title: t`Review Coach`,
      tippy: t`These coaches will be asked to write a coach review`,
      tooltipProps: {
        maxWidth: '400px',
      },
      ...((!review.id || review.status === REVIEW_STATUSES.DRAFT.key) && {
        node: (
          <SelectAllIconComponent
            isSelected={isAllCoachesSelected.value}
            onClick={() => selectAllCoaches(COACHES_TYPE.REVIEWS)}
          />
        ),
      }),
      render(user) {
        // do not display coaches for published reviews.
        const isReadOnly = review.id && review.status !== REVIEW_STATUSES.DRAFT.key;
        const isInitUser = initReviewUsers.some((u) => user.id === u.id);
        const coachesSelected = get(review, `coaches[${user.id}]`, []);
        const coaches = get(user, 'coaches', []).filter((c) => users[c]);
        return (
          <TableCol key={`coach-${user.id}`}>
            <SelectedCoachesRow
              user={user}
              coaches={coaches.filter(
                (coach) =>
                  usersAvailable[coach] !== undefined ||
                  (originalCoaches?.[user.id] ?? []).includes(coach),
              )}
              coachesSelected={coachesSelected}
              onToggleCoach={(coachId, enable) => onCoachToggle(user.id, coachId, enable)}
              isReadOnly={isReadOnly && isInitUser}
              updateCoaches={(c) => setConversationParticipants(c, user.id, false)}
            />
          </TableCol>
        );
      },
    },
    // always display, do not depends on coaches in template
    {
      title: t`Participants`,
      ...((!review.id || review.status === REVIEW_STATUSES.DRAFT.key) && {
        node: (
          <SelectAllIconComponent
            isSelected={isAllConvCoachesSelected.value}
            onClick={() => selectAllCoaches(COACHES_TYPE.CONVERSATIONS)}
          />
        ),
      }),
      tippy: t`These participants will be invited for the conversation.`,
      tooltipProps: {
        maxWidth: '400px',
      },
      render(user) {
        // do not display conversation coaches for published reviews.
        const isReadOnly = review.id && review.status !== REVIEW_STATUSES.DRAFT.key;
        const isInitUser = initReviewUsers.some((u) => user.id === u.id);
        const coachesSelected = get(review, `conversationCoaches[${user.id}]`, []);
        return (
          <TableCol key={`conv.coach-${user.id}`}>
            <SelectedCoachesRow
              user={user}
              coaches={coachesSelected}
              coachesSelected={coachesSelected}
              onToggleCoach={(coachId, enable) =>
                onConversationCoachToggle(user.id, coachId, enable)
              }
              isReadOnly={isReadOnly && isInitUser}
              disabledCoach={isCoach && currentUser.id}
              isParticipants
              removeParticipant={removeParticipant}
              updateCoaches={(c) => setConversationParticipants(c, user.id, true)}
            />
          </TableCol>
        );
      },
    },
  ].filter((c) => c);

const ReviewTabPeople = ({ showErrors, initReviewUsers }) => {
  const dispatch = useDispatch();
  const { i18n } = useLingui();
  const currentRole = useSelector(getSelectedRole);
  const currentUser = useSelector(getUser);
  const review = useSelector(getCurrentReview);
  const [originalCoaches, _] = useState(review.coaches);
  const isAllConvCoachesSelected = useBoolState(!review.id);
  const isAllCoachesSelected = useBoolState(!review.id);
  const team = useSelector(getCurrentTeam);
  const isJobReview = hasJobProfileQuestion(review);
  const skillCategories = isJobReview ? getReviewSkillCategories(review) : null;
  const isGoalBusinessEvalReview = hasGoalBusinessEvalQuestion(review);
  const isGoalLearningEvalReview = hasGoalLearningEvalQuestion(review);
  const isReviewWithCoaches = hasTemplateCoachesEnabled(review);
  const planGoalCyclesIds = getEvalGoalCyclesIDs(review);
  // all users for display already added
  // duplicate changes to usersAvailable
  const $users = useDataStore({
    startState: { loading: true },
    fetch: () =>
      getCompanyUsers(
        {
          goalCycles: planGoalCyclesIds,
          ...(isJobReview && !isEmpty(skillCategories) && { skillCategories }),
          statuses: [CONNECTION_STATUSES.ACTIVE, CONNECTION_STATUSES.INACTIVE],
        },
        [isJobReview && 'jobProfiles', 'coaches'].filter(Boolean),
      ).then((response) => response?.data?.users ?? {}),
  });

  // only active users - for adding new people
  const $usersAvailable = useDataStore({
    startState: { loading: true },
    fetch: () =>
      getCompanyUsers(
        {
          goalCycles: planGoalCyclesIds,
          ...(isJobReview && !isEmpty(skillCategories) && { skillCategories }),
          statuses: [CONNECTION_STATUSES.ACTIVE],
        },
        [isJobReview && 'jobProfiles', 'coaches'].filter(Boolean),
      ).then((response) => response?.data?.users ?? {}),
  });
  const isCoachOrUser = currentRole === ROLES.COACH || currentRole === ROLES.USER;

  const setConversationParticipants = (participants = [], userId, isParticipants) => {
    const conversationCoaches = review.conversationCoaches;
    const coaches = review.coaches;
    if (isParticipants) {
      conversationCoaches[userId] = participants || [];
    } else {
      coaches[userId] = participants || [];
    }
    if (!participants.length) {
      if (isParticipants) {
        isAllConvCoachesSelected.set(false);
      } else {
        isAllCoachesSelected.set(false);
      }
    }
    dispatch(
      currentReviewActions.updateCurrentReview({
        conversationCoaches,
        coaches,
      }),
    );
  };
  // key 'coaches' || 'conversationCoaches'
  const onCoachToggleDefault = (key) => (userId, coachId, enable) => {
    const updateCoaches = review[key] || {};
    let userCoaches = review[key][userId] || [];
    if (enable) {
      userCoaches.push(coachId);
    } else {
      userCoaches = userCoaches.filter((id) => id !== coachId);
    }

    updateCoaches[userId] = userCoaches;

    dispatch(
      currentReviewActions.updateCurrentReview({
        [key]: updateCoaches,
      }),
    );
  };

  const onConversationCoachToggle = onCoachToggleDefault('conversationCoaches');
  const onCoachToggle = onCoachToggleDefault('coaches');

  const selectAllCoaches = (coachesType) => {
    const reviewUsers = get(review, 'users', []);
    const isSelected =
      coachesType === COACHES_TYPE.CONVERSATIONS
        ? isAllConvCoachesSelected.value
        : isAllCoachesSelected.value;
    let updatedCoaches = {};

    reviewUsers.map((user) => {
      const reviewUser = typeof user === 'object' ? user : $users.items[user];
      if (!reviewUser) {
        return;
      }
      updatedCoaches[user.id] = isSelected
        ? []
        : get(user, 'coaches', []).filter(
            (coach) =>
              $usersAvailable.items[coach] !== undefined ||
              (originalCoaches?.[user.id] ?? []).includes(coach),
          );
      if (
        coachesType === COACHES_TYPE.CONVERSATIONS &&
        isCoachOrUser &&
        isSelected &&
        get(user, 'coaches', []).includes(currentUser.id)
      ) {
        updatedCoaches[user.id] = [currentUser.id];
      }
    });

    dispatch(
      currentReviewActions.updateCurrentReview({
        ...(coachesType === COACHES_TYPE.REVIEWS
          ? { coaches: updatedCoaches }
          : { conversationCoaches: updatedCoaches }),
      }),
    );

    if (coachesType === COACHES_TYPE.CONVERSATIONS) {
      isSelected ? isAllConvCoachesSelected.off() : isAllConvCoachesSelected.on();
    } else {
      isSelected ? isAllCoachesSelected.off() : isAllCoachesSelected.on();
    }
  };

  const updateSelectedUsers = async (selectedUsers, { isDelete } = {}) => {
    const reviewUsers = get(review, 'users', []);
    const reviewCoaches = get(review, 'coaches', {});
    const reviewConversationCoaches = get(review, 'conversationCoaches', {});
    const reviewJobProfilesPerUser = get(review, 'jobProfilesPerUser', {});
    const selectedUsersIds = selectedUsers.map((u) => u.id);

    const deleteUsers = (selectedUsers) => {
      const coachesWithoutDeleted = cloneDeep(reviewCoaches);
      const conversationCoachesWithoutDeleted = cloneDeep(reviewConversationCoaches);
      const jobProfilesPerUserWithoutDeleted = cloneDeep(reviewJobProfilesPerUser);

      selectedUsers.forEach((u) => {
        if (coachesWithoutDeleted[u.id]) {
          delete coachesWithoutDeleted[u.id];
        }
        if (conversationCoachesWithoutDeleted[u.id]) {
          delete conversationCoachesWithoutDeleted[u.id];
        }
        if (jobProfilesPerUserWithoutDeleted[u.id]) {
          delete jobProfilesPerUserWithoutDeleted[u.id];
        }
      });

      dispatch(
        currentReviewActions.updateCurrentReview({
          users: reviewUsers.filter((u) => !selectedUsersIds.includes(u.id)),
          ...(isReviewWithCoaches && { coaches: coachesWithoutDeleted }),
          ...(isJobReview && { jobProfilesPerUser: jobProfilesPerUserWithoutDeleted }),
          conversationCoaches: conversationCoachesWithoutDeleted,
        }),
      );
    };
    const addUsers = (selectedUsers) => {
      const coachesWithAdded = cloneDeep(reviewCoaches);
      const conversationCoachesWithAdded = cloneDeep(reviewConversationCoaches);
      const jobProfilesPerUserWithAdded = cloneDeep(reviewJobProfilesPerUser);

      selectedUsers.forEach((user) => {
        if (!coachesWithAdded[user.id]) {
          coachesWithAdded[user.id] = get(user, 'coaches', []).filter(
            (c) => $usersAvailable.items[c],
          );
        }
        if (!conversationCoachesWithAdded[user.id]) {
          conversationCoachesWithAdded[user.id] = get(user, 'coaches', []).filter(
            (c) => $usersAvailable.items[c],
          );
        }
        if (!jobProfilesPerUserWithAdded[user.id]) {
          jobProfilesPerUserWithAdded[user.id] = get(user, 'jobProfiles', []).map((j) => j.id);
        }
      });

      dispatch(
        currentReviewActions.updateCurrentReview({
          users: reviewUsers.concat(selectedUsers),
          ...(isReviewWithCoaches && { coaches: coachesWithAdded }),
          ...(isJobReview && { jobProfilesPerUser: jobProfilesPerUserWithAdded }),
          conversationCoaches: conversationCoachesWithAdded,
        }),
      );
    };
    const confirmText = i18n._(
      t`Are you sure you want to delete this participant from the review. All progress from this participant will be lost and this cannot be undone.`,
    );

    isDelete
      ? review.id
        ? (await confirm(i18n, confirmText)) && deleteUsers(selectedUsers)
        : deleteUsers(selectedUsers)
      : addUsers(selectedUsers);
  };

  useEffect(() => {
    async function mount() {
      const reviewUsers = [];
      const users = await $users.fetch();

      // fetch available users and store them in $usersAvailable
      const usersAvailable = await $usersAvailable.fetch();

      if (!isEmpty(review.users)) {
        review.users.forEach((userId) => {
          const reviewUser = users[userId.id || userId];
          if (!isEmpty(reviewUser)) {
            reviewUsers.push(reviewUser);
          }
        });
      }

      if (!isEmpty(reviewUsers)) {
        const selectedCoaches = getSelectedCoaches(get(review, 'coaches', []), reviewUsers, {
          usersAvailable,
        });
        const selectedConversationCoaches = getSelectedCoaches(
          get(review, 'conversationCoaches', []),
          reviewUsers,
          { usersAvailable },
        );
        // filter out jobProfiles, that do not linked with user
        // (if JP was added to user, review created in draft, and then JP removed from user)
        const selectedJobProfilesPerUser = checkSelectedJobProfilesPerUser(
          get(review, 'jobProfilesPerUser', []),
          reviewUsers,
          review.status === REVIEW_STATUSES.DRAFT.key || !review.id,
        );

        dispatch(
          currentReviewActions.updateCurrentReview({
            users: reviewUsers,
            ...(isReviewWithCoaches && { coaches: selectedCoaches }), // only add coaches if they are in template
            ...(isJobReview && { jobProfilesPerUser: selectedJobProfilesPerUser }),
            conversationCoaches: selectedConversationCoaches,
          }),
        );
      }
    }
    mount();
    // eslint-disable-next-line
  }, []);

  const isCoachReview =
    (review.id && [ROLES.COACH, ROLES.USER].includes(review.createdIn)) ||
    (!review.id && isCoachOrUser);
  const availableUsers = isCoachOrUser
    ? getUsersForCurrentTeam($usersAvailable.items, team)
    : Object.values($usersAvailable.items);

  const colsForUser = getColsForUser({
    isJobReview,
    isGoalBusinessEvalReview,
    isGoalLearningEvalReview,
    isReviewWithCoaches,
    review,
    i18n,
    onCoachToggle,
    onConversationCoachToggle,
    initReviewUsers,
    isCoachOrUser,
    currentUser,
    selectAllCoaches,
    isAllConvCoachesSelected,
    isAllCoachesSelected,
    users: $users.items,
    usersAvailable: $usersAvailable.items,
    showErrors,
    originalCoaches,
    setConversationParticipants,
  });
  const isAdditionalCols = !isEmpty(colsForUser);
  return (
    <PeopleContainer $isCoach={isCoachOrUser}>
      <UserListPicker
        loading={$users.loading && $usersAvailable.loading}
        users={availableUsers}
        selectedUsers={
          review && !isEmpty($users.items) && review.users.map((u) => (u.id ? u : $users.items[u]))
        }
        onSubmit={updateSelectedUsers}
        disabled={isCoachOrUser || review.status === REVIEW_STATUSES.DONE.key}
        error={showErrors && isEmpty(review.users)}
        showButton={!isCoachReview}
        additionalCols={isAdditionalCols ? colsForUser : undefined}
        isLimitUserCol={isAdditionalCols}
      />
    </PeopleContainer>
  );
};

export default React.memo(ReviewTabPeople);
