import styled from 'styled-components';

import { COLORS } from '~/styles';

export const SettingItemWrapper = styled.div<{ $isSecondLevel?: boolean }>`
  display: flex;
  padding: ${(props) => (props?.$isSecondLevel ? '23px 20px 23px 90px' : '23px 20px')};
  border-radius: 6px;
`;

export const ItemsWrapper = styled.div`
  & ${SettingItemWrapper}:nth-child(odd) {
    background-color: ${COLORS.BG_LIST};
  }
`;

export const FooterRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  width: 100%;
  margin: 30px 0;
`;
