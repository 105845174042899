import React, { useState, useEffect } from 'react';

import {
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
  ENGAGEMENT_REPORT_CHART_TYPES,
  REPORT_TYPES,
  ROLES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { TOAST_TYPES, useToasts } from '~/components/Toast';

import routes from '~/constants/routes';
import { usePagination } from '~/hooks/usePagination';
import { getSelectedRole } from '~/selectors/baseGetters';
import { getEngagementCharts, TTotalGraphData, TTabResponse } from '~/services/reports';
import * as reportsUtil from '~/utils/reports';

import {
  MONTH_OPTIONS,
  PAGINATION_PRIMARY_OPTIONS,
  PRIMARY_OPTIONS,
  SECONDARY_OPTIONS,
  MEASURE_OPTIONS,
} from '../../options';
import { TDataStruct } from '../../types';
import { TabBody } from '../LayoutStyles';
import Total from '../tabs/Total';

const EngagementReportAIThemeChart = ({ tabsData }: { tabsData: TTabResponse }) => {
  const { i18n } = useLingui();
  const [noDataText] = useState(i18n._(t`There is no data available yet. Please try again later.`));
  const [isLoading, setIsLoading] = useState(true);
  const [totalGraphData, setTotalGraphData] = useState<TTotalGraphData[]>([]);
  const { pagination } = usePagination(PAGINATION_PRIMARY_OPTIONS[3].id);
  const currentRole = useSelector(getSelectedRole);
  const location = useLocation();

  const { addToast } = useToasts();

  type TViewAs = ROLES.ADMIN | ROLES.COACH | ROLES.USER;
  const getViewAs = (): TViewAs => {
    let viewAs = currentRole as TViewAs;
    if (
      location.pathname ===
      routes.REPORTS_COACH_TEAMS_ENGAGEMENT.build(
        // @ts-ignore
        { role: ROLES.USER },
      )
    ) {
      viewAs = ROLES.COACH;
    }
    return viewAs;
  };

  const VIEW_ROLE = getViewAs();

  const getChartData = async (dt: TDataStruct) => {
    try {
      const tempPayloadObj = {
        viewAs: getViewAs(),
        reportType: REPORT_TYPES.ENGAGEMENT,
        chartType: ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION,
        primaryDimension: (dt.primary?.key as ENGAGEMENT_REPORT_CHART_DIMENSIONS) || 'theme',
        filters: {
          themes: [],
          teams: [],
          surveys: [],
        },
        options: {
          includeCompanyAverage: !!dt.includeCompanyAverage,
          includeBenchmark: !!dt.includeBenchmark,
          ...(getViewAs() === ROLES.USER && { includeTeamAverage: !!dt.includeTeamAverage }),
        },
        dateRange: reportsUtil.getDateForTimeFrame(dt.monthSelected || MONTH_OPTIONS[0].key),
        pagination: {
          limit: 100,
          skip: 0,
        },
      };

      setIsLoading(true);
      const chartData = await getEngagementCharts(tempPayloadObj);
      if (chartData?.data?.chartType === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION) {
        setTotalGraphData(chartData?.data?.dimensionAverage || []);
      }
      setIsLoading(false);
    } catch (_error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  const initData = async () => {
    try {
      setIsLoading(true);
      const tempCurrentTab = ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION;

      const selectedTabData = tabsData?.tabs.find((tab) => tab.key === tempCurrentTab);
      if (!selectedTabData) {
        setIsLoading(false);
        return;
      }

      const selectedFilterData = selectedTabData?.filters || {};
      const selectedOptData = selectedTabData?.options || {};
      const enabledFilterArr = Object.entries(selectedFilterData).filter(
        ([_, value]) => value.isEnabled,
      );

      const initiatePrimary = PRIMARY_OPTIONS.filter((option) => option.key === 'theme');
      const initiateMeasure = MEASURE_OPTIONS.filter((option) => option.key === 'month');

      const tempPrimaryRowSelected =
        PRIMARY_OPTIONS.filter(
          (option) => option.key === selectedTabData?.primaryDimension?.value,
        )[0] ||
        initiatePrimary[0] ||
        null;

      const tempSecondaryRowSelected =
        SECONDARY_OPTIONS.filter(
          (option) => option.key === selectedTabData?.secondaryDimension?.value,
        )[0] || null;

      const tempMeasureRowSelected =
        MEASURE_OPTIONS.filter((option) => option.key === selectedTabData?.measure?.value)[0] ||
        initiateMeasure[0] ||
        null;

      const tempCompanyAverageSelected = selectedOptData?.includeCompanyAverage?.value || false;
      const tempIndustryBenchMarkSelected = selectedOptData?.includeBenchmark?.value || false;
      const tempIsHeatmapSelected = selectedOptData?.isHeatmapColored?.value || false;
      const tempTeamAverageSelected = selectedOptData?.includeTeamAverage?.value || false;

      const timeFrameEnabled = enabledFilterArr.filter(([key]) => key === 'timeFrame');
      const tempMonthSelected =
        timeFrameEnabled.length > 0 ? `${timeFrameEnabled[0][1].value}` : MONTH_OPTIONS[0].key;

      const tempObj = {
        primary: tempPrimaryRowSelected,
        secondary: tempSecondaryRowSelected,
        measure: tempMeasureRowSelected,
        includeCompanyAverage: tempCompanyAverageSelected,
        includeTeamAverage: tempTeamAverageSelected,
        includeBenchmark: tempIndustryBenchMarkSelected,
        isHeatmapSelected: tempIsHeatmapSelected,
        monthSelected: tempMonthSelected,
        themesOptionSelected: [],
        teamsOptionSelected: [],
        surveysOptionSelected: [],
        totalSortedBy: '',
        customSortedBy: '',
        customSearch: '',
        totalSearch: '',
        skip: 0,
        limit: PAGINATION_PRIMARY_OPTIONS[0].id,
      };
      if (selectedTabData.key === ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_DIMENSION) {
        await getChartData(tempObj);
      }
    } catch (_error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsData.id]);

  const getTabBody = () => {
    return (
      <Total
        viewRole={VIEW_ROLE}
        totalCount={0}
        handlePagination={() => {}}
        pagination={pagination}
        isLoading={isLoading}
        noDataText={noDataText}
        data={totalGraphData}
        onShow={() => {}}
        onExportData={() => {}}
        showExportMenu={false}
        totalSearch={''}
        setShowExportMenu={() => {}}
        optionChangeHandler={() => {}}
        sortedBy={null}
        enabledOptions={[]}
        enabledOptionKeys={[]}
        exportOptionArr={[]}
        activatedDimensions={{
          includeTeamAverage: false,
          includeCompanyAverage: false,
        }}
        noRetry={true}
      />
    );
  };

  return <TabBody isFull={true}>{getTabBody()}</TabBody>;
};

export default EngagementReportAIThemeChart;
