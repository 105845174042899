import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import DeleteIcon from '~/components/Icons/Delete';
import PlusIcon from '~/components/Icons/Plus';
import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';

import RightArrowIcon from '~/assets/arrow-right.svg';
import DownArrowIcon from '~/assets/ic-keyboard-arrow-down.svg';

import { COLORS, COLOR_PALETTE } from '~/styles';

const Block = styled.div`
  box-sizing: border-box;
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SkillLevelWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const FocusAreasWrapper = styled.div`
  display: block;
  width: 100%;
`;

const FocusAreaInput = styled.input`
  width: 524px;
  margin-top: 8px;
  height: 48px;
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px ${COLORS.BORDER_HARD};
  background-color: ${COLOR_PALETTE.WHITE};
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const FocusArea = styled.div`
  display: flex;
`;

const LabelWrapper = styled.div`
  width: 560px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 3px;
  border: solid 1px ${COLORS.BORDER_HARD};
  background-color: ${COLOR_PALETTE.WHITE};
  margin: 6px 0;
`;

const DeleteButtonWrapper = styled.div`
  align-items: center;
  justify-content: center;
  padding: 20px 0 0 10px;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelTitle = styled.div`
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.63;
  color: #000000;
`;

const EditFieldsWrapper = styled.div``;

const EditFieldTitle = styled.div`
  margin-top: 18px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: black;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin-left: 24px;
`;

const StyledInput = styled.input`
  width: 328px;
  margin-top: 4px;
  height: 48px;
  box-sizing: border-box;
  padding: 8px 12px;
  border-radius: 3px;
  border: solid 1px #ccced5;
  background-color: ${COLOR_PALETTE.WHITE};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  letter-spacing: 0.25px;
  color: var(--grey-dark);
`;

const InfoText = styled.div`
  color: #99a5be;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.25px;
  display: flex;
  margin-top: 8px;
  flex-direction: row;
  align-items: center;
`;

const DeleteIconWrapper = styled(DeleteIcon)`
  cursor: ${(props) => (props.$isDeleteForbidden ? 'no-drop' : 'pointer')};
`;

// isDeleteForbidden - check is company have skills with max level.
const LabelRow = ({
  i18n,
  label,
  _labelNumber,
  labelIndex,
  skillLevels,
  defaultFocusAreas,
  onChange,
  updateSkillLevel,
  error,
}) => {
  const isDeleteable = (i) => i !== 0 || i === skillLevels.length - 1;

  const addDefaultFocusArea = async () => {
    let newDefaultFocusAreas = [...defaultFocusAreas];
    const newLevelFocusAreas = isEmpty(newDefaultFocusAreas[labelIndex])
      ? ['']
      : [...newDefaultFocusAreas[labelIndex], ''];
    newDefaultFocusAreas[labelIndex] = newLevelFocusAreas;
    onChange(skillLevels, newDefaultFocusAreas);
  };

  const updateFocusArea = (focusAreaIndex, focusAreaValue) => {
    let newDefaultFocusAreas = [...defaultFocusAreas];
    newDefaultFocusAreas[labelIndex] = newDefaultFocusAreas[labelIndex].map((item, index) => {
      if (focusAreaIndex === index) {
        return focusAreaValue;
      }
      return item;
    });
    onChange(skillLevels, newDefaultFocusAreas);
  };

  const removeFocusArea = async (focusAreaIndex) => {
    const textConfirm = i18n._(t`Are you sure you want to delete this focus area?`);

    if (await confirm(i18n, textConfirm)) {
      let newDefaultFocusAreas = [...defaultFocusAreas];
      const levelFocusAreas = newDefaultFocusAreas[labelIndex];
      if (levelFocusAreas.length !== 1) {
        newDefaultFocusAreas[labelIndex] = levelFocusAreas.filter(
          (_item, index) => index !== focusAreaIndex,
        );
        onChange(skillLevels, newDefaultFocusAreas);
      }
    }
  };

  const DeleteFocusAreaButton = (focusAreaIndex) => (
    <div
      onClick={() => removeFocusArea(focusAreaIndex)}
      tabIndex={0}
      role="button"
      onKeyDown={removeFocusArea}
    >
      <DeleteIconWrapper size={24} fill={COLOR_PALETTE.GRAY_MIDDLE} />
    </div>
  );

  return (
    <EditFieldsWrapper>
      <EditFieldTitle>
        <Trans>Name</Trans>
      </EditFieldTitle>
      <StyledInput
        value={label || ''}
        onChange={(e) => updateSkillLevel(e.target.value)}
        error={error}
      />

      <EditFieldTitle>
        <Trans>Focus areas</Trans>
      </EditFieldTitle>
      <InfoText>
        <Trans>These focus areas will be used for all skills unless you overwrite them. </Trans>
      </InfoText>

      {!isEmpty(defaultFocusAreas[labelIndex]) && (
        <FocusAreasWrapper>
          {defaultFocusAreas[labelIndex].map((focusArea, i) => (
            <FocusArea key={i}>
              <FocusAreaInput
                value={focusArea || ''}
                onChange={(e) => {
                  updateFocusArea(i, e.target.value);
                }}
                error={error}
              />
              <DeleteButtonWrapper>
                {isDeleteable(i) && DeleteFocusAreaButton(i)}
              </DeleteButtonWrapper>
            </FocusArea>
          ))}
        </FocusAreasWrapper>
      )}

      <Button
        type="link-primary"
        label={i18n._(t`Add focus area`)}
        iconLeft={<PlusIcon size={24} />}
        onClick={addDefaultFocusArea}
        styles={{
          marginTop: '16px',
          paddingLeft: 0,
          fontSize: '14px',
          lineHeight: '1.71',
          letterSpacing: '0.25',
          marginBottom: '18px',
        }}
      />
    </EditFieldsWrapper>
  );
};

const SkillLevelsList = ({
  skillLevels,
  defaultFocusAreas,
  isShowErrors,
  i18n,
  onChange,
  skillSets,
}) => {
  const [selectedLabel, setSelectedLabel] = useState(-1);
  // Logic for hot fix: admin can't delete skillLevels from company
  // until some skill use this level
  // for instance: company has skill with 8 levels.
  // company can't change skillLabels to 7, until company delete all skills with 8 levels
  // TODO (check for Alex) think about delete levels from skill and delete focus areas
  // when admin delete level from skillLabels
  // prepare company skills array
  const companySkills = Object.values(get(skillSets, 'companySkills', {}));

  const skillsWithMaxLevel = companySkills.filter(
    (s) => size(s.levelsEnabled) === size(skillLevels),
  );

  const isDeleteable = (i) => i !== 0 && i > 4 && i === skillLevels.length - 1;

  const isDeleteForbidden = () => !isEmpty(skillsWithMaxLevel);

  const addSkillLevel = async () => {
    if (skillLevels.length < 10) {
      onChange([...skillLevels, 'Level ' + (skillLevels.length + 1)], defaultFocusAreas);
    }
  };

  const updateSkillLevel = (skillIndex, skillValue) => {
    const newSkillLevels = skillLevels.map((item, index) => {
      if (skillIndex === index) {
        return skillValue;
      }
      return item;
    });
    onChange(newSkillLevels, defaultFocusAreas);
  };

  const removeSkillLevel = async () => {
    const textConfirm = i18n._(
      t`Are you sure you want to delete this skill level. This will remove this skill level from all customized skills. Once saved this cannot be undone.`,
    );

    if (await confirm(i18n, textConfirm)) {
      if (skillLevels.length !== 1) {
        onChange(
          skillLevels.filter((_item, index) => index !== skillLevels.length - 1),
          defaultFocusAreas,
        );
      }
    }
  };

  const DeleteButton = (
    <div
      onClick={isDeleteForbidden() ? () => {} : removeSkillLevel}
      role="button"
      tabIndex={0}
      onKeyDown={isDeleteForbidden() ? () => {} : removeSkillLevel}
    >
      <DeleteIconWrapper
        $isDeleteForbidden={isDeleteForbidden()}
        size={24}
        fill={COLOR_PALETTE.GRAY_MIDDLE}
      />
    </div>
  );

  const textDeleteDisabled = i18n._(
    t`You cannot delete a level that is in use by 1 or multiple skills`,
  );

  return (
    <Block>
      <LabelsWrapper>
        {skillLevels.map((label, i) => (
          <SkillLevelWrapper key={i}>
            <LabelWrapper>
              <TitleRow onClick={() => setSelectedLabel(selectedLabel === i ? -1 : i)}>
                <LabelTitle>Level {i + 1}</LabelTitle>
                <StyledSvgIcon
                  width="14px"
                  height="14px"
                  url={selectedLabel === i ? DownArrowIcon : RightArrowIcon}
                />
              </TitleRow>
              {selectedLabel === i && (
                <LabelRow
                  label={label}
                  labelIndex={i}
                  labelNumber={i + 1}
                  skillLevels={skillLevels}
                  defaultFocusAreas={defaultFocusAreas}
                  updateSkillLevel={(e) => {
                    updateSkillLevel(i, e);
                  }}
                  onChange={onChange}
                  onDelete={removeSkillLevel}
                  i18n={i18n}
                  error={
                    isShowErrors &&
                    (isEmpty(label) || skillLevels.filter((sl) => sl === label).length > 1)
                  }
                />
              )}
            </LabelWrapper>
            <DeleteButtonWrapper>
              {isDeleteable(i) &&
                (isDeleteForbidden() ? (
                  <Tooltip tooltip={textDeleteDisabled}>{DeleteButton}</Tooltip>
                ) : (
                  DeleteButton
                ))}
            </DeleteButtonWrapper>
          </SkillLevelWrapper>
        ))}
        {skillLevels.length < 10 && (
          <Button
            type="link-primary"
            label={i18n._(t`Add skill level`)}
            iconLeft={<PlusIcon size={24} />}
            onClick={addSkillLevel}
            styles={{
              paddingLeft: 0,
              fontSize: '14px',
              lineHeight: '1.71',
              letterSpacing: '0.25',
              marginBottom: '34px',
            }}
          />
        )}
      </LabelsWrapper>
    </Block>
  );
};

const mapStateToProps = (state) => ({
  skillSets: state.skillSets,
});

export default withI18n()(connect(mapStateToProps)(SkillLevelsList));
