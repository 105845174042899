import React from 'react';

import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import defaultCompanyAvatar from '~/assets/learned-logo/learned-iconic.png';

const CompanyLogo = styled.div`
  width: ${(props) => (props.$size ? `${props.$size}px` : '46px')};
  height: ${(props) => (props.$size ? `${props.$size}px` : '46px')};
  min-width: ${(props) => (props.$size ? `${props.$size}px` : '46px')};
  min-height: ${(props) => (props.$size ? `${props.$size}px` : '46px')};
  outline-color: transparent;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  border: ${(props) => (props.$isSelected ? '2px solid var(--company-color)' : 'none')};
`;

const DefaultCompanyAvatar = styled.img`
  width: ${(props) => (props.$size ? `${props.$size}px` : '46px')};
  height: ${(props) => (props.$size ? `${props.$size}px` : '46px')};
  min-width: ${(props) => (props.$size ? `${props.$size}px` : '46px')};
  min-height: ${(props) => (props.$size ? `${props.$size}px` : '46px')};
  box-sizing: border-box;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
`;

const CompanyAvatar = ({ size, logoUrl, isSelected, className }) => {
  return logoUrl ? (
    <CompanyLogo
      className={className}
      $size={size}
      $isSelected={isSelected}
      style={{
        backgroundImage: 'url(' + logoUrl + ')',
      }}
    />
  ) : (
    <DefaultCompanyAvatar
      className={className}
      width={size + 'px'}
      height={size + 'px'}
      alt="Default avatar"
      src={defaultCompanyAvatar}
    />
  );
};

CompanyAvatar.propTypes = {
  logoUrl: PropTypes.string,
  size: PropTypes.number,
  multipleCompanies: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export default CompanyAvatar;
