import React, { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SearchSelectButton from '~/components/SearchSelectButton';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SkillName from '~/components/Skill/SkillName';
import SvgIcon from '~/components/SvgIcon';

import SearchIcon from '~/assets/ic-search-24-px.svg';
import LearnedLogo from '~/assets/learned-logo/learned_logo.png';
import DefaultCompanyAvatar from '~/assets/main-menu-icons/default-avatar.svg';
import PlaceholderIcon from '~/assets/mdi-tag-multiple.svg';

import { SKILL_SOURCE } from '~/constants/skills';
import useDebounce from '~/hooks/useDebounce';
import { getCompanyFocusAreas, getAppTheme } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import getCurrentCompanySkillsSets from '~/selectors/getCurrentCompanySkillsSets';
import getLang from '~/selectors/getLang';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkills, getSkill, getSuggestedSkillsNames } from '~/services/skills';
import { COLOR_PALETTE, COLORS } from '~/styles';
import { mapCompanyDefaultFocusAreas, mapLevelsEnabled } from '~/utils/focusAreas';
import getSkillLevels from '~/utils/getSkillLevels';
import getSkillName from '~/utils/getSkillName';

import SelectLevelModal from '../SelectLevelModal';

const GoalsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GoalSkill = styled.div`
  margin: 10px 8px 8px 8px;
  padding: 8px 36px 8px 12px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: 0.25px;
  box-sizing: border-box;
  font-size: 14px;
  color: var(--company-color);
  position: relative;
  &:hover .skill-remove-btn {
    display: block;
    background-color: ${COLOR_PALETTE.WHITE};
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
  &:hover .skill-level {
    display: none;
  }
`;

const FilterWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;

  .filter-left {
    margin-right: 8px;
  }
`;

const GoalSkillRemoveButton = styled.button`
  display: none;
  background: inherit;
  border: none;
  color: var(--company-color);
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

const Level = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLOR_PALETTE.WHITE};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: var(--company-color);
`;

const Logo = styled.img`
  max-width: 16px;
  max-height: 16px;
  margin-right: 8px;
`;

const PlaceholderContainer = styled.div`
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PlaceholderTitle = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #000000;
`;

const PlaceholderText = styled.div`
  font-size: 14px;
  margin-top: 8px;
  line-height: 1.57;
  text-align: center;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const GoalSkillsBox = styled.div`
  min-height: 48px;
  border-radius: 6px;
  background-color: ${COLOR_PALETTE.WHITE};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  padding-top: ${(props) => props.isTopPadding && '7px'};
  border: 1px solid ${(props) => (props.error ? COLORS.ERROR : COLORS.BORDER_HARD)};

  .img-search {
    display: block;
    margin-left: 8px;
  }
`;

const ResultSkillsBox = styled.div`
  min-height: 48px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  margin-top: ${(props) => (props.$isSmallMargin ? '6px' : '26px')};
  height: auto;
  overflow-y: auto;

  p {
    margin: 10px 8px 8px 0;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
    letter-spacing: 0.25px;
    color: #000000;
    background-color: ${COLORS.BG_PAGE};
    box-sizing: border-box;
  }
`;

const SearchInput = styled.input`
  border: none;
  flex: 1;
  background: transparent;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  color: #70747f;
  margin: 0 0 7px 7px;
  box-sizing: border-box;

  &::placeholder {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    vertical-align: center;
    color: #70747f;
  }
`;

const Skill = styled.p`
  background-color: green;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const SkillActions = styled.div`
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  background: inherit;
`;

const SkillNameWrapper = styled.div`
  cursor: pointer;
`;

const ErrorText = styled.p`
  font-size: 16px;
  color: ${COLORS.ERROR};
  margin-left: 4px;
`;

const FILTERED_SKILLS_LIMIT = 20;

const sortSkills = (skills) => skills.sort((a, b) => a.name.localeCompare(b.name));

const getFilteredSkills = async ({
  categories,
  search,
  prioritisedSkills,
  hiddenSkills,
  preSelectedSkills,
  isIncludeSuggestedSkills,
  limit = FILTERED_SKILLS_LIMIT,
  skillsSets,
}) => {
  if (!search && isEmpty(skillsSets) && isEmpty(categories)) {
    return Object.values(prioritisedSkills).filter(
      (skill) => skill.source === SKILL_SOURCE.company,
    );
  }

  // Holds only one skill per skill name to prevent duplicates
  let prioritisedSkillsObj = { ...prioritisedSkills };

  if (!isEmpty(categories)) {
    prioritisedSkillsObj = Object.fromEntries(
      Object.entries(prioritisedSkillsObj).filter(
        ([_key, s]) => !isEmpty(intersection(categories, s.categories)),
      ),
    );
  }

  if (!isEmpty(skillsSets)) {
    const availableSkillsSources = [];
    if (skillsSets.includes('learned2SoftSkills')) {
      availableSkillsSources.push(SKILL_SOURCE.learned2);
    }
    if (skillsSets.includes('companySkills')) {
      availableSkillsSources.push(SKILL_SOURCE.company);
    }
    prioritisedSkillsObj = Object.fromEntries(
      Object.entries(prioritisedSkillsObj).filter(([_key, s]) =>
        availableSkillsSources.includes(s.source),
      ),
    );
  }

  // Get suggested skills if they are needed
  if (isIncludeSuggestedSkills && !isEmpty(search)) {
    const suggestedSkillsNames = await getSuggestedSkillsNames({
      categories,
      search,
      limit,
    });

    // Map and add only the suggested skills with names that don't already exist
    suggestedSkillsNames
      .map((skill) => ({
        id: skill.description,
        name: skill.description,
        categories: [skill.category],
        isSuggestedSkill: skill.isSuggestedSkill,
      }))
      .forEach((sk) => {
        if (!prioritisedSkillsObj[sk.name]) {
          prioritisedSkillsObj[sk.name] = sk;
        }
      });
  }

  // Delete from the object the skills with the same name as then ones that should be hidden
  if (hiddenSkills) {
    hiddenSkills.forEach((sk) => {
      if (prioritisedSkillsObj[sk.name]) {
        delete prioritisedSkillsObj[sk.name];
      }
    });
  }

  // Filter out preselected skills and skills that don't match the search string
  let resultSkills = Object.values(prioritisedSkillsObj).filter((item) => {
    if (preSelectedSkills && preSelectedSkills[item.id]) {
      return false;
    }
    if (item.name && item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
      return true;
    }
    return item.nameNL && item.nameNL.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });

  // If the amount of skills is larger than the desired length, first remove the last suggested skills from it
  let skillExcessCount = resultSkills.length - limit;
  if (skillExcessCount > 0) {
    for (let i = resultSkills.length - 1; i >= 0; i--) {
      if (!skillExcessCount) {
        break;
      }
      if (!resultSkills[i].isSuggestedSkill) {
        continue;
      }

      resultSkills[i] = null;
      skillExcessCount--;
    }
  }

  // Sort skills by name and slice them to get the desired length
  return sortSkills(resultSkills.filter(Boolean)).slice(0, limit);
};

const SelectSkills = ({
  hiddenSkills,
  preSelectedSkills,
  isIncludeSuggestedSkills,
  toggleSkill,
  withoutLevels,
  selectedSkills,
  selectedLevels,
  error,
  errorText,
  isShowPreloadedSkills,
  isSkillSetFilter,
  isCategoriesFilter,
  preSelectedCategories,
  isHidePremiumSkillSet = false,
}) => {
  const { i18n } = useLingui();
  const company = useSelector(getCurrentCompany);
  const [skill, setSkill] = useState({});
  const [prioritisedSkills, setPrioritisedSkills] = useState({});
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isLevelModalOpen, setIsLevelModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateSkill, setIsUpdateSkill] = useState(false);
  const [skillCategories, setSkillCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(preSelectedCategories ?? []);
  const [selectedSkillSets, setSelectedSkillSets] = useState([]);
  const skillsSets = useSelector(getCurrentCompanySkillsSets);
  const defaultFocusAreas = useSelector(getCompanyFocusAreas);
  const lang = useSelector(getLang);
  const search = useDebounce(searchInputValue, 300);
  const appTheme = useSelector(getAppTheme);

  useEffect(() => {
    const disabledSkillsSources = [];
    if (!skillsSets.learnedSoftEnabled) {
      disabledSkillsSources.push(SKILL_SOURCE.learned);
    }
    if (!skillsSets.learned2SoftEnabled) {
      disabledSkillsSources.push(SKILL_SOURCE.learned2);
    }
    if (!skillsSets.companySkillsEnabled) {
      disabledSkillsSources.push(SKILL_SOURCE.company);
    }

    const fetchSkills = async () => {
      const [skills, skillCategories] = await Promise.all([
        getSkills({ enabledSkillsOnly: true, hideDeleted: true }),
        getSkillCategories(),
      ]);

      const skillValues = values(skills);

      // Holds only one skill per skill name, based on skill set priority, to prevent duplicates
      const prioritisedSkillsObj = {};

      // Priority 1: company
      // Priority 2: learned2
      skillValues.forEach((sk) => {
        // Filter disabled skill sets
        if (disabledSkillsSources.includes(sk.source)) {
          return;
        }

        const skillName = getSkillName(sk, lang);

        // it needs for filter
        const categories = sk.categories
          .map((c) => {
            const skillCategory = Object.values(skillCategories).find(
              (cat) => cat.id === c || cat.categoryId === c,
            );
            if (skillCategory) {
              return skillCategory.categoryId || skillCategory.id;
            }
          })
          .filter((c) => c);

        if (!prioritisedSkillsObj[skillName]) {
          prioritisedSkillsObj[skillName] = { ...sk, categories };
        } else if (sk.source === SKILL_SOURCE.company) {
          prioritisedSkillsObj[skillName] = { ...sk, categories };
        }
      });

      setSkillCategories(Object.values(skillCategories));
      setPrioritisedSkills(prioritisedSkillsObj);

      // If it should load existing skills at the beggining
      if (isShowPreloadedSkills) {
        const returnedSkills = await getFilteredSkills({
          categories: selectedCategories,
          hiddenSkills,
          isIncludeSuggestedSkills: false,
          preSelectedSkills,
          search: '',
          prioritisedSkills: prioritisedSkillsObj,
          limit: 50,
        });
        setFilteredSkills(returnedSkills);
      }

      setIsLoading(false);
    };

    fetchSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIncludeSuggestedSkills, lang, skillsSets]);

  useEffect(() => {
    getFilteredSkills({
      categories: selectedCategories,
      hiddenSkills,
      isIncludeSuggestedSkills:
        isIncludeSuggestedSkills &&
        skillsSets.learnedPremiumEnabled &&
        (isEmpty(selectedSkillSets) || selectedSkillSets.includes('learnedPremiumSkills')),
      preSelectedSkills,
      search,
      prioritisedSkills,
      skillsSets: selectedSkillSets,
    }).then((skills) => setFilteredSkills(skills));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hiddenSkills,
    isIncludeSuggestedSkills,
    preSelectedSkills,
    search,
    skillsSets.learnedPremiumEnabled,
    selectedCategories,
    selectedSkillSets,
  ]);

  const handleSearch = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setSearchInputValue(value);
  };

  const onOpenLevelModal = (skill, isUpdate) => {
    setSkill(skill);
    setIsUpdateSkill(isUpdate);
    setIsLevelModalOpen(true);
  };

  const handleSelect = async (e, selectedSkill, isUpdate) => {
    let skill = { ...selectedSkill };

    // If it is a recommended skill, fill focusAreas and levelsEnabled with default values
    if (skill.isSuggestedSkill) {
      const focusAreas = mapCompanyDefaultFocusAreas(defaultFocusAreas, skill.name);
      skill.focusAreas = focusAreas;
      skill.levelsEnabled = mapLevelsEnabled(focusAreas);

      // Add temporal field to know that this skill needs to be created
      skill.isNew = true;

      // Add the selected category id to this skill categories
      const skillCategory = skillCategories.find((cat) => cat.categoryId === skill.categories[0]);
      if (skillCategory) {
        skill.categories = [skillCategory.id];
      }

      // Fill other skill fields
      skill.source = SKILL_SOURCE.company;
      skill.definition = skill.name;
      skill.aliases = [skill.name];
    }

    if (withoutLevels) {
      toggleSkill(skill)(e);
    } else {
      const skillPopulated = skill.isSuggestedSkill
        ? skill
        : await getSkill(skill.id, ['focusAreas']);

      const skillLevels = getSkillLevels(skillPopulated);
      const levelsEnabled = skillPopulated.levelsEnabled.filter((l) => l);

      if (skillLevels.filter((level) => level).length > 1 || levelsEnabled.length > 1) {
        onOpenLevelModal(skillPopulated, isUpdate);
      } else {
        const selectedLevel = skillLevels.findIndex((level) => !isEmpty(level));
        toggleSkill(skillPopulated)(e, { level: selectedLevel > 0 ? selectedLevel : 1, isUpdate });
      }
    }
  };

  const categoriesList = skillCategories.map((c) => {
    return {
      id: c.categoryId || c.id,
      label: c.name,
    };
  });

  const skillSetsList = [
    skillsSets.companySkillsEnabled && {
      id: 'companySkills',
      label: company.name,
    },
    skillsSets.learned2SoftEnabled && {
      id: 'learned2SoftSkills',
      label: i18n._(t`Learned detailed soft skills`),
    },
    skillsSets.learnedPremiumEnabled &&
      !isHidePremiumSkillSet && {
        id: 'learnedPremiumSkills',
        label: i18n._(t`Learned full skills database`),
      },
  ].filter((s) => s);

  const onCloseLevelModal = () => {
    setIsLevelModalOpen(false);
    setSkill({});
    setIsUpdateSkill(false);
  };

  const getPlaceHolder = () => (
    <PlaceholderContainer>
      <SvgIcon
        width="40px"
        height="40px"
        url={PlaceholderIcon}
        defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
        isDefaultColor
      />
      <PlaceholderTitle>
        {search ? <Trans>No results</Trans> : <Trans>Type to search</Trans>}
      </PlaceholderTitle>
      <PlaceholderText>
        {search ? (
          <Trans>We could not find any skills..</Trans>
        ) : (
          <Trans>Type the name of the skill to search</Trans>
        )}
      </PlaceholderText>
    </PlaceholderContainer>
  );

  sortSkills(selectedSkills);

  return (
    <ShowSpinnerIfLoading loading={isLoading}>
      <GoalsContainer>
        <div className="modal_input-group">
          <GoalSkillsBox error={error} isTopPadding={isEmpty(selectedSkills)}>
            <SvgIcon
              classNameIcon="img-search"
              width="24px"
              height="24px"
              isDefaultColor
              defaultColor={COLORS.SUBTEXT}
              url={SearchIcon}
            />
            {!isEmpty(selectedSkills) &&
              selectedSkills.map((skill, index) => {
                const skillLevel = get(selectedLevels, skill.id, null);
                return (
                  <GoalSkill onClick={(e) => handleSelect(e, skill, true)} key={index}>
                    <SkillNameWrapper>
                      <SkillName skill={skill} />
                    </SkillNameWrapper>
                    <SkillActions>
                      <GoalSkillRemoveButton
                        className="skill-remove-btn"
                        onClick={toggleSkill(skill)}
                      >
                        &#10005;
                      </GoalSkillRemoveButton>
                      {skillLevel && <Level className="skill-level">{skillLevel}</Level>}
                    </SkillActions>
                  </GoalSkill>
                );
              })}
            <SearchInput
              type="text"
              placeholder={i18n._(t`Search`)}
              value={searchInputValue}
              onChange={handleSearch}
            />
          </GoalSkillsBox>
          {(isSkillSetFilter || isCategoriesFilter) && (
            <FilterWrapper>
              {isSkillSetFilter && (
                <SearchSelectButton
                  checkedList={selectedSkillSets}
                  title={i18n._(t`Skill set`)}
                  options={skillSetsList}
                  handleChange={(skillSets) => setSelectedSkillSets(skillSets)}
                  className="filter-left"
                />
              )}
              {isCategoriesFilter && (
                <SearchSelectButton
                  checkedList={selectedCategories}
                  title={i18n._(t`Category`)}
                  options={categoriesList}
                  handleChange={(categories) => setSelectedCategories(categories)}
                />
              )}
            </FilterWrapper>
          )}
          {error && <ErrorText> {errorText} </ErrorText>}
          {!isEmpty(filteredSkills) ? (
            <ResultSkillsBox $isSmallMargin={isSkillSetFilter || isCategoriesFilter}>
              {filteredSkills.map((skill, index) => {
                const isSelected = selectedSkills
                  ? selectedSkills.map((g) => g.id).indexOf(skill.id) !== -1
                  : false;
                return (
                  !isSelected && (
                    <Skill onClick={(e) => handleSelect(e, skill, false)} key={index}>
                      {[SKILL_SOURCE.company, SKILL_SOURCE.learned2].includes(skill.source) && (
                        <Logo
                          src={
                            skill.source === SKILL_SOURCE.company
                              ? appTheme.url || DefaultCompanyAvatar
                              : LearnedLogo
                          }
                          alt={i18n._(t`Logo`)}
                        />
                      )}
                      <SkillName skill={skill} />
                    </Skill>
                  )
                );
              })}
            </ResultSkillsBox>
          ) : (
            getPlaceHolder()
          )}
        </div>

        {isLevelModalOpen && (
          <SelectLevelModal
            skill={skill}
            selectedLevel={get(selectedLevels, skill.id)}
            onClose={onCloseLevelModal}
            onSubmit={toggleSkill(skill)}
            isUpdate={isUpdateSkill}
            categories={skillCategories}
          />
        )}
      </GoalsContainer>
    </ShowSpinnerIfLoading>
  );
};

SelectSkills.propTypes = {
  isIncludeSuggestedSkills: PropTypes.bool,
  withoutLevels: PropTypes.bool,
  selectedLevels: PropTypes.object,
  selectedSkills: PropTypes.array,
  hiddenSkills: PropTypes.array,
};

SelectSkills.defaultProps = { hiddenSkills: [] };

export default SelectSkills;
