import React from 'react';

import styled from 'styled-components';

import { COLOR_PALETTE } from '~/styles';

interface RowHeaderProps {
  extraColumns: Array<{ id: string; name: string }>;
  isSecondConversationVisible?: boolean;
}

const Row = styled.tr`
  height: 40px;
  border-bottom: 1px solid #ecf1ff;

  & th {
    white-space: nowrap;
  }
`;

const TableColHeader = styled.th<{ width?: number }>`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  line-height: 1.33;
  letter-spacing: normal;
  width: ${({ width }) => (width ? `${width}px` : '200px')};
  color: ${({ color }) => (color ? color : COLOR_PALETTE.DARK_GRAY)};
  padding: 12px 24px;
`;

const RowHeader = ({ extraColumns, isSecondConversationVisible }: RowHeaderProps) => {
  const extraColumnsComponent = extraColumns?.map(({ id, name }) => (
    <TableColHeader key={id} width={216}>
      {name}
    </TableColHeader>
  ));

  return (
    <Row>
      <TableColHeader width={193}>name</TableColHeader>
      <TableColHeader width={193} color="#c129cc">
        conversation name
      </TableColHeader>
      {extraColumnsComponent}
      {isSecondConversationVisible && (
        <>
          <TableColHeader width={193} color="#5c76ff">
            conversation name
          </TableColHeader>
          {extraColumnsComponent}
        </>
      )}
    </Row>
  );
};

export { RowHeader };
