import React, { useEffect, useRef, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import moment from 'moment';
import { Chart } from 'react-chartjs-2';
import styled from 'styled-components';

import { COLORS } from '~/styles';

import { TEngagementData } from '../constants';

import type { ChartData, ChartOptions } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineController, LineElement);

const GraphOuterCtr = styled.div``;

const GraphCtr = styled.div`
  position: relative;
  cursor: default;
`;

const TooltipCtr = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.BORDERS};
  border-radius: 6px;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
  padding: 15px 17px;
  width: 240px;
  height: auto;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  transition: visibility 0.4s ease-in-out;
  transform: translate(0%, -100%);
  box-sizing: border-box;
  pointer-events: none;
  user-select: none;
`;

const TooltipTitle = styled.div`
  color: ${COLORS.TEXT_HOVER};
  font-weight: 600;
  font-size: 18px;
`;

const TooltipRowValue = styled.div`
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
`;

const TooltipSecondary = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
  box-sizing: border-box;
`;

const OtherContent = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  display: flex;
  margin-top: 8px;
  gap: 10px;
  align-items: center;
`;

const OtherColorBar = styled.div`
  background: currentColor;
  width: 8px;
  align-self: stretch;
  border-radius: 50px;
`;

const OtherContentLeft = styled.div`
  color: currentColor;
`;

const OtherContentRight = styled.div`
  color: ${COLORS.TEXT_HOVER};
`;

const TooltipColorBar = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  width: 8px;
  border-radius: 50px;
  align-self: stretch;
  opacity: 0.8;
  flex: none;
`;

const TooltipColorText = styled.div`
  display: flex;
  flex-direction: column;
`;

const TooltipColValue = styled.div`
  color: ${COLORS.TEXT_HOVER};
  font-size: 24px;
`;

const TooltipComparison = styled.div`
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 4px;
  position: relative;
  color: ${COLORS.CONFIRMATION_MODAL_SUCCESS};
  border: 1px solid currentColor;
`;

const TooltipBackgroundCtr = styled.div`
  background: ${COLORS.CONFIRMATION_MODAL_SUCCESS};
  opacity: 0.2;
  position: absolute;
  inset: 0;
  background: currentColor;
`;

const Progress = styled.div``;

const Tile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ColoredCircle = styled.div<{ selectedColor: string }>`
  background: ${({ selectedColor }) => selectedColor};
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const GraphFooter = styled.div`
  font-size: 12px;
  color: ${COLORS.TEXT_HOVER};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  margin-top: 20px;
`;

export enum ELabelType {
  FILL = 'fill',
  BORDER = 'border',
}

export interface ILineGraphPoint {
  // TODO:[REPORTS] Change the data type to be generic !
  data: TEngagementData;
  colors: string[];
  label: string;
  type: ELabelType;
  key: string;
}

export interface ILineGraphProps {
  min?: number;
  max?: number;
  height: number;
  stepSize: number;
  width?: number;
  colorRatio?: number;
  timeData?: ILineGraphPoint[];
}

const createGradient = (
  canvas: HTMLCanvasElement,
  height: number,
  color1: string,
  color2: string,
) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx?.createLinearGradient(0, 0, 0, height);
  gradient?.addColorStop(0, color1);
  gradient?.addColorStop(1, color2);
  return gradient;
};
{
  /* TODO:[REPORTS] Should the Component name be LineGraph */
}
function TimeGraph({
  min = 0,
  max = 100,
  height = 600,
  stepSize = 20,
  width,
  colorRatio = 0.8,
  timeData = [],
}: ILineGraphProps) {
  const filterMainData = timeData?.filter((a) => a.key === 'primary');
  const filteredTeamData = timeData?.filter((a) => a.key === 'team');
  const filteredCompanyData = timeData?.filter((a) => a.key === 'company');
  const filteredBenchmarkData = timeData?.filter((a) => a.key === 'benchmark');
  const primaryData = filterMainData && filterMainData.length > 0 ? filterMainData[0].data : [];
  const primaryDataLabels = primaryData.length > 0 ? primaryData.map((a) => a.key) : [];

  const toolRef = useRef<HTMLDivElement>(null);
  const yRef = useRef<HTMLDivElement>(null);
  const xRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const tRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  const teamOuterRef = useRef<HTMLDivElement>(null);
  const companyRef = useRef<HTMLDivElement>(null);
  const companyOuterRef = useRef<HTMLDivElement>(null);
  const benchmarkRef = useRef<HTMLDivElement>(null);
  const benchmarkOuterRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ChartJS<'line'>>(null);
  const [chartDataSet, setChartDataSet] = useState<ChartData<'line'>>({ labels: [], datasets: [] });
  const { i18n } = useLingui();
  const filteredLabels = timeData?.filter((i) => i.type === ELabelType.FILL);
  const GRADIENT_COLORS =
    filteredLabels && filteredLabels?.length > 0
      ? filteredLabels[0].colors
      : ['rgba(161, 109, 251, 1)', 'rgba(104, 59, 246, 0)'];

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const GRADIENT = createGradient(
      chart.canvas,
      height * colorRatio,
      GRADIENT_COLORS[0],
      GRADIENT_COLORS.length > 1 ? GRADIENT_COLORS[1] : COLORS.TRANSPARENT,
    );

    const dataSets = timeData.map((item, i) => {
      const data = item.data.map((d) => d.value);
      return {
        label: item.key,
        data,
        tension: i === 0 ? 0.2 : 0,
        fill: i === 0,
        backgroundColor: i === 0 ? GRADIENT : COLORS.TRANSPARENT,
        borderWidth: i === 0 ? 0 : 3,
        ...(i !== 0 && { borderDash: [2, 2] }),
        borderColor:
          (timeData && timeData.length > i && timeData[i].colors[0]) || COLORS.TEXT_HOVER,
        pointRadius: 3,
        pointBorderWidth: 1,
        pointBackgroundColor:
          (timeData && timeData.length > i && timeData[i].colors[0]) || COLORS.WHITE,
        pointHoverBorderWidth: 1,
        pointHoverRadius: 6,
        pointHoverBorderColor: 'rgba(0, 0, 0, 0.5)',
        pointHoverBackgroundColor:
          (timeData && timeData.length > i && timeData[i].colors[0]) || COLORS.WHITE,
        order: timeData.length - i,
      };
    });

    if (dataSets?.length) {
      // @ts-ignore
      setChartDataSet({
        labels: primaryDataLabels,
        datasets: dataSets,
      });
    }

    // eslint-disable-next-line
  }, [chartRef]);

  const options: ChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          if (
            toolRef?.current &&
            xRef?.current &&
            yRef?.current &&
            progressRef?.current &&
            tRef?.current
          ) {
            if (context.tooltip.opacity === 0) {
              toolRef.current.style.visibility = 'hidden';
              return;
            }
            toolRef.current.style.visibility = 'visible';
            toolRef.current.style.left = `${context.tooltip.x}px`;
            toolRef.current.style.top = `${context.tooltip.y}px`;
            if (context.tooltip.dataPoints && context.tooltip.dataPoints.length > 0) {
              const primaryPoint = context.tooltip.dataPoints.find(
                (dp) => dp.dataset.label === 'primary',
              ) || { formattedValue: '', dataIndex: 0 };
              yRef.current.innerText = `${primaryPoint.formattedValue}%`;
              const selectedIndex = primaryPoint.dataIndex;
              xRef.current.innerText = primaryData[selectedIndex].key;
              const deviation = Number(primaryData[selectedIndex].deviation.toFixed(2));
              progressRef.current.innerText = `${deviation}% compared to the previous`;
              if (deviation === 0) {
                tRef.current.style.display = 'none';
              } else {
                tRef.current.style.display = 'flex';
                if (deviation > 0) {
                  tRef.current.style.color = COLORS.CONFIRMATION_MODAL_SUCCESS;
                } else {
                  tRef.current.style.color = COLORS.CONFIRMATION_MODAL_DELETE;
                }
              }
              if (teamOuterRef && teamRef && teamRef.current && teamOuterRef.current) {
                const tempVal =
                  filteredTeamData.length > 0 ? filteredTeamData[0].data[selectedIndex].value : 0;
                if (tempVal > 0) {
                  teamRef.current.innerText = `${tempVal}%`;
                  teamOuterRef.current.style.display = 'flex';
                } else {
                  teamOuterRef.current.style.display = 'none';
                }
              }
              if (companyRef && companyOuterRef && companyRef.current && companyOuterRef.current) {
                const tempVal =
                  filteredCompanyData.length > 0
                    ? filteredCompanyData[0]?.data[selectedIndex].value
                    : 0;
                if (tempVal > 0) {
                  companyRef.current.innerText = `${tempVal}%`;
                  companyOuterRef.current.style.display = 'flex';
                } else {
                  companyOuterRef.current.style.display = 'none';
                }
              }

              if (
                benchmarkRef &&
                benchmarkOuterRef &&
                benchmarkRef.current &&
                benchmarkOuterRef.current
              ) {
                const tempVal =
                  filteredBenchmarkData.length > 0
                    ? filteredBenchmarkData[0]?.data[selectedIndex].value
                    : 0;
                if (tempVal > 0) {
                  benchmarkRef.current.innerText = `${tempVal}%`;
                } else {
                  benchmarkRef.current.innerText = '-';
                }
              }

              if (primaryPoint.dataIndex && primaryPoint.dataIndex > primaryData.length - 3) {
                toolRef.current.style.transform = 'translate(-50%, -100%)';
              } else {
                toolRef.current.style.transform = 'translate(0%, -100%)';
              }
            }
          }
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
          lineWidth: 0,
        },
        ticks: {
          callback: (value) => {
            const valueIndex = parseInt(value.toString(), 10);
            const label = primaryDataLabels[valueIndex];

            if (label.includes('01')) {
              return moment(label).format('YYYY MMM');
            }
            return moment(label).format('MMM');
          },
        },
      },
      y: {
        beginAtZero: true,
        min,
        max,
        ticks: {
          stepSize,
        },
        grid: {
          display: true,
          color: COLORS.BORDERS,
        },
        border: {
          display: false,
        },
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
  };

  return (
    <GraphOuterCtr>
      <GraphCtr>
        <Chart
          ref={chartRef}
          height={height}
          width={width}
          type="line"
          data={chartDataSet}
          options={options as any}
        />
        <TooltipCtr ref={toolRef}>
          {/* TODO:[REPORTS] why is the title hardcoded here, I suggest to pass it as a prop */}
          <TooltipTitle>{i18n._(t`Average engagement`)}</TooltipTitle>
          <TooltipRowValue ref={xRef} />

          {filterMainData.length > 0 && (
            <TooltipSecondary>
              <TooltipColorBar color={filterMainData[0]?.colors[0] || COLORS.TEXT_HOVER} />
              <TooltipColorText>
                <TooltipColValue ref={yRef} />
                <TooltipComparison ref={tRef}>
                  <TooltipBackgroundCtr />
                  <Progress ref={progressRef} />
                </TooltipComparison>
              </TooltipColorText>
            </TooltipSecondary>
          )}

          {filteredTeamData.length > 0 && (
            <OtherContent
              ref={teamOuterRef}
              color={filteredTeamData[0]?.colors[0] || COLORS.TEXT_HOVER}
            >
              <OtherColorBar />
              <OtherContentLeft>{filteredTeamData[0].label}</OtherContentLeft>
              <OtherContentRight ref={teamRef} />
            </OtherContent>
          )}
          {filteredCompanyData.length > 0 && (
            <OtherContent
              ref={companyOuterRef}
              color={filteredCompanyData[0]?.colors[0] || COLORS.TEXT_HOVER}
            >
              <OtherColorBar />
              <OtherContentLeft>{filteredCompanyData[0].label}</OtherContentLeft>
              <OtherContentRight ref={companyRef} />
            </OtherContent>
          )}
          {filteredBenchmarkData.length > 0 && (
            <OtherContent
              color={filteredBenchmarkData[0]?.colors[0] || COLORS.TEXT_HOVER}
              ref={benchmarkOuterRef}
            >
              <OtherColorBar />
              <OtherContentLeft>{filteredBenchmarkData[0].label}</OtherContentLeft>
              <OtherContentRight ref={benchmarkRef} />
            </OtherContent>
          )}
        </TooltipCtr>
      </GraphCtr>
      {timeData && (
        <GraphFooter>
          {timeData.map((item, i) => (
            <Tile key={`tiles-${i + 1}`}>
              <ColoredCircle selectedColor={item.colors[0]} />
              {item.label}
            </Tile>
          ))}
        </GraphFooter>
      )}
    </GraphOuterCtr>
  );
}

export default TimeGraph;
