import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import Editor from '~/components/Editor';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import Divider from '~/components/UI/Divider';

import NoteItem from './components/NoteItem';
import TabHeader from './components/TabHeader';

import useBoolState from '~/hooks/useBoolState';
import * as notesService from '~/services/notes';
import { COLOR_PALETTE } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const TabHeaderWrapper = styled(TabHeader)`
  margin: 16px 24px;
`;

const Container = styled.div`
  margin: 24px 24px;
`;

const Label = styled.div`
  color: ${COLOR_PALETTE.BLACK};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 8px;
`;

const EditorWrapper = styled(Editor)`
  margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 12px 0;
  justify-content: flex-end;
`;

const RenderEditor = ({ currentNote, i18n, onChangeNote, onSaveNote, cancelEdit, editMode }) => {
  return (
    <>
      <EditorWrapper
        value={currentNote}
        placeholder={i18n._(t`Type your notes here…`)}
        compact={true}
        small={true}
        onChange={(value) => onChangeNote(value)}
      />
      <ButtonContainer>
        <Button
          label={i18n._(t`Save`)}
          onClick={onSaveNote}
          height={32}
          tooltip={i18n._(t`Save your note so you can read it back later`)}
        />
        {editMode && (
          <Button
            marginLeft={12}
            label={i18n._(t`Cancel`)}
            type="primary-border"
            onClick={cancelEdit}
            height={32}
            tooltip={i18n._(t`Cancel editing this note`)}
          />
        )}
      </ButtonContainer>
    </>
  );
};

const NotesTab = ({ user, i18n }) => {
  const [notes, setNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState('');
  const [editNote, setEditNote] = useState({});
  const [editNoteValue, setEditNoteValue] = useState('');
  const $loading = useBoolState(true);
  const userName = getUserFullName(user);
  const userId = user.id;

  useEffect(() => {
    const retrieveData = async () => {
      const notesData = await notesService.getNotes({ createdFor: user.id });
      setNotes(Object.values(notesData));
      $loading.off();
    };

    retrieveData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSaveNote = () => {
    $loading.on();
    notesService
      .createNote(userId, currentNote)
      .then((newNote) => {
        setNotes([...notes, newNote]);
        setCurrentNote('');
        $loading.off();
      })
      .catch(() => {
        // Show toast that something went wrong?
        $loading.off();
      });
  };

  const onSaveEditNote = (id) => {
    $loading.on();
    notesService
      .updateNote(id, editNoteValue)
      .then((updatedNote) => {
        setNotes([...notes.filter((n) => n.id !== id), updatedNote]);
        setEditNote({});
        $loading.off();
      })
      .catch(() => {
        $loading.off();
      });
  };

  const onEditNote = (note) => {
    setEditNote(note);
  };

  const onRemoveNote = async (note) => {
    if (
      await confirm(i18n, i18n._(t`This will delete the user note. This cannot be undone.`), {
        title: i18n._(t`Are you sure?`),
      })
    ) {
      $loading.on();
      notesService
        .removeNote(note.id)
        .then(() => {
          setNotes(
            notes.filter((n) => n.id !== note.id),
            $loading.off(),
          );
        })
        .catch(() => {
          // Show toast that something went wrong?
          $loading.off();
        });
    }
  };

  const onChangeNote = (note) => {
    setCurrentNote(note);
  };

  const onChangeEditNote = (note) => {
    setEditNoteValue(note);
  };

  return (
    <BoxWithBorder>
      <TabHeaderWrapper
        title={i18n._(t`Notes`)}
        description={i18n._(t`Your private notes about ${userName}`)}
      />
      <Divider $color={COLOR_PALETTE.GRAY_MIDDLE} />
      <Container>
        <RenderEditor
          currentNote={currentNote}
          onChangeNote={onChangeNote}
          onSaveNote={onSaveNote}
          i18n={i18n}
        />
        <Label>
          <Trans>Notes</Trans>
        </Label>
        {notes
          .sort((a, b) => new Date(b?.meta?.createdDate) - new Date(a?.meta?.createdDate))
          .map((n) => {
            if (n.id === editNote.id) {
              return (
                <RenderEditor
                  key={n.id}
                  currentNote={n.value}
                  i18n={i18n}
                  onSaveNote={() => onSaveEditNote(n.id)}
                  cancelEdit={() => setEditNote({})}
                  onChangeNote={onChangeEditNote}
                  editMode
                />
              );
            }
            return <NoteItem key={n.id} note={n} editNote={onEditNote} removeNote={onRemoveNote} />;
          })}
      </Container>
    </BoxWithBorder>
  );
};

export default withI18n()(withRouter(NotesTab));
