import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import ReviewTemplatePreview from '~/pages/ReviewTemplatePreview';
import ReviewTemplateSetup from '~/pages/ReviewTemplateSetup';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('reviewTemplates', {
  routesPrivate: {
    admin: [
      <PrivateRoute
        key={routes.REVIEW_TEMPLATE_CREATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.REVIEW_TEMPLATE_CREATE.routePath(ROLES.ADMIN)}
        component={ReviewTemplateSetup}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.REVIEW_TEMPLATE_UPDATE.routePath(ROLES.ADMIN)}
        exact
        path={routes.REVIEW_TEMPLATE_UPDATE.routePath(ROLES.ADMIN)}
        component={ReviewTemplateSetup}
        allowedRoles={[ROLES.ADMIN]}
      />,
      <PrivateRoute
        key={routes.REVIEW_TEMPLATE_PREVIEW.routePath(ROLES.ADMIN)}
        exact
        path={routes.REVIEW_TEMPLATE_PREVIEW.routePath(ROLES.ADMIN)}
        component={ReviewTemplatePreview}
        allowedRoles={[ROLES.ADMIN]}
      />,
    ],
  },
});
