import React from 'react';

import useScript from '~/hooks/useScript';

const GOOGLE_TAG_MANAGER_ID = 'G-F2412Z31HE';

const innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${GOOGLE_TAG_MANAGER_ID}');
`;

// Google tag manager
const GoogleTagManager = () => {
  useScript({
    async: true,
    src: `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG_MANAGER_ID}`,
  });

  useScript({
    innerHTML,
  });

  return <div className="google-tag-manager" />;
};

export { GoogleTagManager };
