import React, { ReactChild } from 'react';

import { Trans } from '@lingui/macro';

import { Option, Select } from '~/components/Dropdown/design';
import { useMultiSelectState } from '~/components/Dropdown/hooks';
import type { DropdownMultiSelectProps } from '~/components/Dropdown/types';
import { Icon, ICONS } from '~/components/Icon';

import { BaseDropdown } from './BaseDropdown';
import { MultiSelectCheckBox } from './MultiSelectCheckBox';

import { COLORS } from '~/styles';

function MultiSelectDropdown<T>({
  selectedItems,
  onChange,
  placeholder,
  renderItem,
  CustomDropdownComponent,
  CustomOptionComponent,
  hashItem,
  selectHeight,
  ...props
}: DropdownMultiSelectProps<T> & {
  renderItem: (item: T) => ReactChild;
}) {
  const {
    showDropdown,
    hasSelectedItem,
    isItemSelected,
    toggleDropdown,
    hideDropdown,
    handleNewItemSelected,
  } = useMultiSelectState({ selectedItems, onChange, hashItem });

  function renderOption(item: T) {
    return CustomOptionComponent ? (
      <CustomOptionComponent
        isSelected={isItemSelected(item)}
        item={item}
        handleNewItemSelected={handleNewItemSelected}
      />
    ) : (
      <Option onClick={() => handleNewItemSelected(item)}>
        <MultiSelectCheckBox selected={isItemSelected(item)} />
        <span>{renderItem(item)}</span>
      </Option>
    );
  }

  return (
    <BaseDropdown
      {...props}
      showDropdown={showDropdown}
      hideDropdown={hideDropdown}
      renderOption={renderOption}
    >
      {CustomDropdownComponent ? (
        <CustomDropdownComponent
          clicked={showDropdown}
          active={hasSelectedItem}
          onClick={toggleDropdown}
          selectedItems={selectedItems}
        />
      ) : (
        <Select
          $clicked={showDropdown}
          $active={hasSelectedItem}
          onClick={toggleDropdown}
          selectHeight={selectHeight}
        >
          <span>
            {placeholder ? placeholder : <Trans>Dropdown</Trans>}{' '}
            {selectedItems.length > 0 ? `(${selectedItems.length})` : ''}
          </span>
          <Icon color={COLORS.ICONS_PRIMARY} height="11" width="11" icon={ICONS.DROPDOWN} />
        </Select>
      )}
    </BaseDropdown>
  );
}

export { MultiSelectDropdown };
