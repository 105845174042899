import { I18n } from '@lingui/core';

export type TOptions = {
  key: string;
  title: (trans: I18n) => string;
  description?: (trans: I18n) => string | undefined;
};

export enum EAction {
  getData = 'getData',
  exportCsv = 'exportCsv',
  exportPdf = 'exportPdf',
}

export type TDataStruct = {
  primary: TOptions | null;
  secondary: TOptions | null;
  measure: TOptions | null;
  isHeatmapSelected: boolean;
  themesOptionSelected: TOptions[];
  teamsOptionSelected: TOptions[];
  surveysOptionSelected: TOptions[];
  monthSelected: string;
  includeBenchmark?: boolean;
  includeCompanyAverage?: boolean;
  includeTeamAverage?: boolean;
  totalSortedBy?: string;
  customSortedBy?: string;
  customSearch?: string;
  totalSearch?: string;
  limit?: number;
  skip?: number;
};

export type TRenderedTableItem = {
  title: (item: I18n) => string;
};

export type TTeam = {
  id: string;
  name: string;
};

export type TColumns = { id: string | number; name: string | number };

export type TEngagementData = {
  id: string;
  name?: string;
  secondaryName?: string;
  showPrimary?: boolean;
  cells: {
    measure: string; // columnId
    value: number;
  }[];
};

export type TData = {
  query: string;
  columns: TColumns[];
  rows: TEngagementData[];
  total: number;
};

export enum EOptions {
  primaryDimension = 'primaryDimension',
  secondaryDimension = 'secondaryDimension',
  measure = 'measure',
  // Options
  includeBenchmark = 'includeBenchmark',
  includeCompanyAverage = 'includeCompanyAverage',
  isHeatmapColored = 'isHeatmapColored',
  exportOptions = 'exportOptions',
  includeTeamAverage = 'includeTeamAverage',
  // filters
  timeFrame = 'timeFrame',
  themes = 'themes',
  teams = 'teams',
  surveys = 'surveys',
  reset = 'reset',
  currentTab = 'currentTab',
  customSortedBy = 'customSortedBy',
  totalSortedBy = 'totalSortedBy',
  totalSearch = 'totalSearch',
  customSearch = 'customSearch',
  limit = 'limit',
  skip = 'skip',
  fetchDataAgain = 'fetchDataAgain',
}

export enum EMenuTypes {
  filter = 'filter',
  rows = 'rows',
  none = 'none',
}

export enum EExportOptions {
  'csv' = 'csv',
  'pdf' = 'pdf',
}

export type TDropDownValues = string | TOptions[] | null | TOptions;

export type TUpdateFnProps = {
  [key: string]: string | TOptions[] | boolean | TOptions | null | number;
};
