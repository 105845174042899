import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

const Name = styled.div<{
  isGrayColor?: boolean;
}>`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 312px;
  ${({ isGrayColor }) =>
    isGrayColor &&
    css`
      color: ${COLORS.SUBTEXT};
    `}
`;

const SubText = styled.div<{
  isGrayColor?: boolean;
}>`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: -0.13px;
  ${({ isGrayColor }) =>
    isGrayColor &&
    css`
      color: ${COLORS.SUBTEXT};
    `}
`;

const Wrap = styled.div<{ isLeftMargin?: boolean }>`
  margin-left: ${(props) => (props.isLeftMargin ? '15px' : '0px')};
`;

interface INameWithQuestions {
  name: string;
  questionsTotal?: number | undefined;
  isGrayColor?: boolean;
  isLeftMargin?: boolean;
}

const NameWithQuestions = ({
  name,
  questionsTotal,
  isGrayColor,
  isLeftMargin = true,
}: INameWithQuestions) => {
  const { i18n } = useLingui();
  return (
    <Wrap isLeftMargin={isLeftMargin}>
      <Name isGrayColor={isGrayColor}>{name}</Name>
      {questionsTotal !== undefined && (
        <SubText isGrayColor={isGrayColor}>
          {i18n._(t`Includes ${questionsTotal} questions`)}
        </SubText>
      )}
    </Wrap>
  );
};

export { NameWithQuestions };
