import React, { Component } from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import qs from 'qs';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import SidePanel from '~/components/GiveFeedback/components/SidePanel';
import OverviewHeading from '~/components/OverviewHeading';
import ProgressBar from '~/components/ProgressBar';
import { withToasts, TOAST_TYPES } from '~/components/Toast';
import LangDropdown from '~/components/UI/LangDropdown';
import BaseLayout from '~/layouts/BaseLayout';
import RightSidebarLayout from '~/layouts/RightSidebarLayout';

import AutoSave from './components/AutoSave';
import { autosave } from './components/AutoSave/utils';
import ReviewIntroduction from './components/ReviewIntroduction';
import ReviewStatus from './components/ReviewStatus';
import SaveFeedbackModal from './components/SaveFeedbackModal';
import Section from './components/Section';
import StartModal from './components/StartModal';
import {
  getSections,
  getReviewErrorsCount,
  getRatingBySections,
  calcFeedbackProgress,
  getFirstErrorLocation,
} from './utils';

import {
  RATING_TYPES,
  REQUEST_STATUSES,
  REVIEW_STATUSES,
  GIVE_FEEDBACK_STATUSES,
} from '~/constants';
import routes from '~/constants/routes';
import { checkModuleRTFeedbacks } from '~/selectors/baseGetters';
import { createOrUpdateFeedbackRatings, getUserReviewRatings } from '~/services/ratings';
import { acceptRequest, declineRequest } from '~/services/requests';
import { setSelfReviewStatus, updateGoalCycleForUserReview } from '~/services/reviews';
import * as appActions from '~/store/app/actions';
import * as currentRatingsActions from '~/store/currentRatings/actions';
import * as currentRequestActions from '~/store/currentRequest/actions';
import * as currentReviewActions from '~/store/currentReview/actions';
import { COLORS, COLOR_SET } from '~/styles';
import disableActionOnLoading from '~/utils/disableActionOnLoading';
import history from '~/utils/history';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const MainContent = styled.div`
  flex-direction: column;
  flex: 1;
`;

const Title = styled.div`
  text-transform: capitalize;
`;

const AutoSaveWrapper = styled.div`
  margin-right: 15px;
  display: flex;
`;

const LangWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const scrollToRef = (ref) => {
  const offset = ref.offsetTop + 96; // 96 header height

  // EI and EDGE do not support scrollTo(0, offset)
  document.getElementById('main-content').style.scrollBehavior = 'smooth';
  document.getElementById('main-content').scrollLeft = 0;
  document.getElementById('main-content').scrollTop = offset;
  document.getElementById('main-content').style.scrollBehavior = '';
};

const SAVE_FEEDBACK_STATUSES = {
  DRAFT: 'draft',
  CALIBRATE: 'calibrate',
  SHARE: 'share',
};
export class GiveFeedback extends Component {
  static propTypes = {
    ratingType: PropTypes.oneOf(Object.values(RATING_TYPES)),
    forUser: PropTypes.object,
    email: PropTypes.string,
    logoUrl: PropTypes.string,
  };

  state = {
    loading: false,
    isSaved: false,
    isStartModal: false,
    showErrors: false,
    errorQuestion: null,
    activeSection: 0,
    activeQuestionStep: null,
    isUpdate: true,
    autoSaveStatus: null,
    isReviewCoach: false,
    isSaveFeedbackModal: false,
  };

  autosave = null;

  sections = [];

  _isMounted = false;

  // you can access the elements with itemsRef.current[n]
  itemsRefs = React.createRef();

  async componentDidMount() {
    const { review, user, ratingType, email, isRealTime, dispatch, app, currentRequest } =
      this.props;

    this._isMounted = true;
    // currentReview and currentRequest we should not clean
    // because we do not fetch data for them
    dispatch(currentRatingsActions.setCurrentRatings({}));
    if (!app.isFieldsValidated) {
      dispatch(appActions.updateApp({ isFieldsValidated: true }));
    }

    if (!isEmpty(review)) {
      const newState = {};
      const sections = getSections(review, ratingType, review.skillCategories);
      this.sections = sections;

      const isFirstTime =
        isEmpty((review.ratings || []).filter((r) => r.type === ratingType)) &&
        currentRequest.status === REQUEST_STATUSES.REQUESTED.key;
      if (isFirstTime) {
        // if no rating = first time popup display
        newState.isUpdate = false;
        newState.isStartModal = true;
      }
      dispatch(
        currentRatingsActions.setCurrentRatings(
          getRatingBySections({ sections, review, user, email, isRealTime, ratingType }),
        ),
      );

      // Check if user is a coach of the review
      // + additional check that it's coach review
      const isReviewCoach =
        ratingType === RATING_TYPES.COACH_RATING && review.coaches.includes(user.id);

      let fetchedRatings = {};
      if (isReviewCoach) {
        fetchedRatings = await getUserReviewRatings({
          userId: review.createdFor,
          reviewId: review.id,
        });
      }

      this.setState({
        ...newState,
        fetching: false,
        loading: false,
        fetchedRatings: Object.values(fetchedRatings),
        isReviewCoach,
      });
    }
    this.setupBeforeUnloadListener();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    this._isMounted = false;
    dispatch(currentReviewActions.setCurrentReview({}));
    dispatch(currentRequestActions.setCurrentRequest({}));
    dispatch(currentRatingsActions.setCurrentRatings({}));
    window.removeEventListener('onbeforeunload', (ev) => {
      ev.preventDefault();
    });
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener('beforeunload', (ev) => {
      if (!this.state.isSaved) {
        ev.preventDefault();
      }
    });
  };

  handleClose = async (isConfirmed = false) => {
    const { dispatch, review, ratingType, i18n } = this.props;

    // confirm
    if (
      this.state.isSaved ||
      isConfirmed ||
      (!this.state.isSaved &&
        (await confirm(
          i18n,
          i18n._(t`Are you sure you want to exit this screen? Your data will be lost.`),
        )))
    ) {
      dispatch(currentReviewActions.setCurrentReview({}));
      dispatch(currentRequestActions.setCurrentRequest({}));
      dispatch(currentRatingsActions.setCurrentRatings({}));

      const query = qs.parse(history.location.search, { ignoreQueryPrefix: true });
      const { from } = query;

      // redirect to from, if undefined -> to UserReviewDashboard
      from
        ? history.replace(from)
        : ratingType === RATING_TYPES.OTHER_RATING
        ? history.push(routes.HOME)
        : routes.REVIEW.go({}, { reviewId: review.id });
    }
  };

  handleSubmit = () => {
    const { currentRatings, toasts, i18n, review } = this.props;
    const { showErrors } = this.props;
    const { isUpdate } = this.state;
    const notValid = getReviewErrorsCount(this.sections, currentRatings) > 0;

    if (showErrors !== notValid) {
      const { errorSection, errorQuestion } = getFirstErrorLocation(
        this.sections,
        currentRatings,
        review.jobProfiles,
      );
      const errorRef = get(this.itemsRefs, `current[${errorSection}][${errorQuestion}]`);

      // scroll to question with error
      if (errorRef) {
        scrollToRef(errorRef);
      }

      this.setState({
        showErrors: notValid,
        // open section with error
        // can't open question with error, because collapse logic encapsulated inside QuestionCard
        activeSection: errorSection,
        errorQuestion,
      });
    }

    if (notValid) {
      toasts.add({
        title: i18n._(t`Not completed`),
        subtitle: i18n._(t`Please answer all required questions before sharing the review.`),
        type: TOAST_TYPES.ERROR,
      });
    } else {
      toasts.add({
        title: isUpdate ? i18n._(t`Review updated`) : i18n._(t`Review shared`),
        subtitle: isUpdate
          ? i18n._(t`Your review has been successfully updated`)
          : i18n._(t`Your review has been successfully shared`),
        type: TOAST_TYPES.INFO,
      });
      this.onSubmit(false);
    }
  };

  onDecline = async () => {
    const { i18n, currentRequest, ratingType } = this.props;
    if (
      await confirm(
        i18n,
        i18n._(t`Are you sure want to reject? This will be permanent and can’t be undone.`),
      )
    ) {
      await declineRequest(currentRequest.id);
      this.setState({ isSaved: true });
      ratingType === RATING_TYPES.OUTSIDE_RATING ? location.reload() : this.handleClose(true);
    }
  };

  // isRedirect - for autoSave
  onSubmit = async (
    isDraft,
    { disableFinalRedirect = false, isAutoSave = false, isCalibrate = false } = {},
  ) => {
    const { dispatch, currentRatings, currentRequest, loading, ratingType, review } = this.props;
    let createdRatings = null; // return this value to define success or fail

    // stop all not finished autosaves
    if (this.autosave && this.autosave.cancel) {
      this.autosave.cancel();
      this.setState({ autoSaveStatus: null });
    }

    if (disableActionOnLoading(loading)) {
      return;
    }

    this.setState({ loading: true });

    if (!isEmpty(currentRatings)) {
      const currentRatingsArray = Object.values(currentRatings);
      createdRatings = await createOrUpdateFeedbackRatings({
        ratings: currentRatingsArray,
        reviewId: review.id,
        share: !isDraft && !isCalibrate,
        isSelfReview: ratingType === RATING_TYPES.SELF_RATING,
        isAutoSave,
        isCalibrate,
      });

      // add id to ratings, if one does not have it
      // need for autosave logic only for first autosave
      // when ratings do not have ids
      if (!isEmpty(createdRatings) && !currentRatingsArray[0].id) {
        dispatch(currentRatingsActions.setCurrentRatingsIds(createdRatings));
      }
    }

    // Set self review status to done if shared
    if (ratingType === RATING_TYPES.SELF_RATING) {
      await setSelfReviewStatus(this.props.review.id, isDraft);
    }

    // autoSave does not affect on request status
    if (
      ratingType === RATING_TYPES.OTHER_RATING ||
      ratingType === RATING_TYPES.COACH_RATING ||
      ratingType === RATING_TYPES.OUTSIDE_RATING
    ) {
      await acceptRequest(
        currentRequest.id,
        isDraft ? null : isCalibrate ? REQUEST_STATUSES.CALIBRATE.key : REQUEST_STATUSES.SHARED.key,
      );
    }

    const reviewHasGoalCycle = !!review.questions.find((q) =>
      [QUESTION_TYPES.GOAL_LEARNING_PLAN, QUESTION_TYPES.GOAL_BUSINESS_PLAN].includes(q.type),
    );
    const isPermissionToPlanGoal = [RATING_TYPES.SELF_RATING, RATING_TYPES.COACH_RATING].includes(
      ratingType,
    );

    // save goals in userReview only on Publish
    // only for self review and for coaches
    if (!isDraft && !isCalibrate && reviewHasGoalCycle && isPermissionToPlanGoal) {
      await updateGoalCycleForUserReview(review.id);
    }

    // check question only from sections available for user (depends on type: self/peer/coach)
    const isOnlyPlanGoalsQuestions = get(review, 'template.sections', [])
      // filter section related to user
      .filter((section) => {
        switch (ratingType) {
          case RATING_TYPES.SELF_RATING:
            return section.isSelfReview;
          case RATING_TYPES.OTHER_RATING:
          case RATING_TYPES.OUTSIDE_RATING:
            return section.isUsersReview;
          case RATING_TYPES.COACH_RATING:
            return section.isCoachesReview;
          default:
            return false;
        }
      })
      // check is remaining sections has only goal planning questions
      .every((section) => {
        return (section?.questions || []).every((q) =>
          [QUESTION_TYPES.GOAL_BUSINESS_PLAN, QUESTION_TYPES.GOAL_LEARNING_PLAN].includes(q.type),
        );
      });

    // define error by created ratings;
    const isError = isEmpty(createdRatings) && !isOnlyPlanGoalsQuestions;

    // should be at the end

    /*
     * _isMounted resolve the problem
     * Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
     * */
    if (this._isMounted) {
      this.setState({ loading: false, isSaved: true }, () => {
        // reload or close page when isSaved changed

        // do not redirect if error popup
        if (!isError && !disableFinalRedirect) {
          ratingType === RATING_TYPES.OUTSIDE_RATING ? location.reload() : this.handleClose(true);
        }
      });
    }

    return createdRatings;
  };

  setIsSaved = async () => new Promise((resolve) => this.setState({ isSaved: true }, resolve));

  changeActiveSection = (index) => {
    if (this.state.activeSection !== index) {
      this.setState({ activeSection: index, activeQuestionStep: null });
    } else {
      this.setState({ activeSection: null, activeQuestionStep: null });
    }
  };

  changeActiveQuestionStep = (section, newStep) => {
    const { activeQuestionStep } = this.state;
    if (activeQuestionStep !== newStep) {
      this.setState({ activeSection: section, activeQuestionStep: newStep });
    }
  };

  // handle rating change: rate/explanation/isSkipped
  onRatingChange = (id, data) => {
    const { dispatch } = this.props;

    // update storage
    dispatch(currentRatingsActions.updateCurrentRating({ id, data }));

    // update DB
    this.autosave = autosave(id, this.updateRatingDB, {
      onStart: () => this._isMounted && this.setState({ autoSaveStatus: AutoSave.STATUSES.SAVING }),
      onRestart: () =>
        this._isMounted && this.setState({ autoSaveStatus: AutoSave.STATUSES.SAVING }),
      onEnd: () => this._isMounted && this.setState({ autoSaveStatus: AutoSave.STATUSES.SAVED }),
    });
  };

  updateRatingDB = async (currentRatingId) => {
    const { currentRatings, dispatch, currentRole } = this.props;
    const currentRating = currentRatings[currentRatingId];

    if (currentRating) {
      const updatedRatings = await createOrUpdateFeedbackRatings(
        {
          ratings: [currentRating],
          reviewId: currentRating.review,
          isSelfReview: currentRating.type === RATING_TYPES.SELF_RATING,
          isAutoSave: true,
        },
        { forceRole: currentRole }, // force user role, to prevent crash, when user switch to admin role, while autosave in progress
      );

      // There should only be one rating in the array.
      const updatedRating = updatedRatings?.length > 0 && updatedRatings[0];

      if (!updatedRating) {
        // AutoSave status set
        this.setState({ autoSaveStatus: AutoSave.STATUSES.ERROR });
      } else if (!currentRating.id) {
        // add id in local storage if rating do not have it
        dispatch(
          currentRatingsActions.updateCurrentRating({
            id: currentRatingId,
            data: { id: updatedRating.id },
          }),
        );
      }
    }

    // need this for autosave.onEnd callback works
    return;
  };

  handleSaveFeedback = (status) => {
    switch (status) {
      case SAVE_FEEDBACK_STATUSES.DRAFT:
        this.onSubmit(true);
        break;
      case SAVE_FEEDBACK_STATUSES.CALIBRATE:
        this.onSubmit(false, { isCalibrate: true });
        break;
      case SAVE_FEEDBACK_STATUSES.SHARE:
        this.handleSubmit();
        break;
      default:
        break;
    }
  };

  getReviewUserName = () => {
    const { review, users } = this.props;
    if (isEmpty(review)) {
      return '';
    }
    return users[review?.createdFor]?.firstName || users[review?.createdFor]?.email || '';
  };

  getStatusViewText = () => {
    const { currentRequest, ratingType } = this.props;
    let text = '';
    switch (currentRequest.status) {
      case REQUEST_STATUSES.SHARED.key:
        text =
          ratingType === RATING_TYPES.SELF_RATING
            ? t`Visible to you, coaches, admins`
            : t`Visible to you, coaches, admins, ${this.getReviewUserName}`;
        break;
      case REQUEST_STATUSES.CALIBRATE.key:
        text = t`Visible to you, coaches, admins`;
        break;
      default:
        text = t`Visible to you`;
        break;
    }
    return text;
  };

  render() {
    const {
      user,
      i18n,
      currentRequest,
      currentRatings,
      ratingType,
      review,
      forUser,
      logoUrl,
      isModuleRTFeedbacksEnabled,
    } = this.props;
    const {
      loading,
      isSaved,
      isStartModal,
      activeSection,
      activeQuestionStep,
      showErrors,
      errorQuestion,
      autoSaveStatus,
      fetchedRatings,
      isReviewCoach,
      isSaveFeedbackModal,
    } = this.state;
    const forUserName = forUser?.firstName || forUser?.email || '';

    const isHideSaveDraft =
      ratingType === RATING_TYPES.OUTSIDE_RATING
        ? true
        : review.status === REVIEW_STATUSES.DONE.key;

    const getTitle = () => {
      let ratingTypeName = '';

      switch (ratingType) {
        case RATING_TYPES.OTHER_RATING: {
          ratingTypeName = i18n._(t`peer review`);
          break;
        }
        case RATING_TYPES.COACH_RATING: {
          ratingTypeName = i18n._(t`coach review`);
          break;
        }
        case RATING_TYPES.SELF_RATING: {
          ratingTypeName = i18n._(t`self review`);
          break;
        }
        default:
          break;
      }

      return ratingTypeName;
    };

    const getTagTooltip = () => {
      switch (ratingType) {
        case RATING_TYPES.SELF_RATING:
          return i18n._(
            t`Use the feedback and compliments you have received in the last months to prepare for your conversations.`,
          );
        case RATING_TYPES.OTHER_RATING:
        case RATING_TYPES.COACH_RATING:
          return i18n._(
            t`Use the feedback you have provided to this colleague to write your input for his/her conversation.`,
          );
        default:
          return '';
      }
    };

    const sidebarTabs = [
      user &&
        isModuleRTFeedbacksEnabled && {
          id: 'feedback',
          title: i18n._(t`Feedback`),
          tooltip: getTagTooltip(),
          tab: <SidePanel ratingType={ratingType} requestedBy={forUser} />,
        },
    ]
      .filter((i) => i)
      .reverse(); // tabs order start from the bottom

    // save feedback options
    const statusActions = [
      !isHideSaveDraft && {
        label: t`Save as draft`,
        key: SAVE_FEEDBACK_STATUSES.DRAFT,
        color: COLOR_SET.RED_2,
      },
      isReviewCoach &&
        review?.template?.isCalibrate && {
          label: t`Calibrate`,
          key: SAVE_FEEDBACK_STATUSES.CALIBRATE,
          color: COLORS.PEER_COVERAGE_STATUS,
        },
      {
        label: ratingType !== RATING_TYPES.OUTSIDE_RATING ? t`Share` : t`Complete & share`,
        key: SAVE_FEEDBACK_STATUSES.SHARE,
        color: COLOR_SET.CYAN_GREEN,
      },
    ].filter((a) => a);

    return (
      <RightSidebarLayout tabs={sidebarTabs}>
        <OverviewHeading
          maxWidth={1000}
          smallWidth={true}
          title={<Title>{getTitle()}</Title>}
          onBack={ratingType !== RATING_TYPES.OUTSIDE_RATING ? this.handleClose : null}
          logoUrl={logoUrl}
          loading={loading}
          noPadding
        >
          <>
            <AutoSaveWrapper>
              <AutoSave status={autoSaveStatus} />
            </AutoSaveWrapper>
            <AutoSaveWrapper>
              <ProgressBar
                color="var(--company-color)"
                width={115}
                value={calcFeedbackProgress(this.sections, Object.values(currentRatings))}
                valueAtEnd
              />
            </AutoSaveWrapper>
            {ratingType === RATING_TYPES.OUTSIDE_RATING && (
              <LangWrapper>
                <LangDropdown />
              </LangWrapper>
            )}
            {ratingType !== RATING_TYPES.OUTSIDE_RATING && (
              <ReviewStatus
                status={
                  ratingType !== RATING_TYPES.SELF_RATING
                    ? currentRequest?.status
                    : review.isSelfFeedbackProvided
                    ? GIVE_FEEDBACK_STATUSES.SHARED.key
                    : GIVE_FEEDBACK_STATUSES.DRAFT.key
                }
                viewText={this.getStatusViewText()}
              />
            )}
            {review.status !== REVIEW_STATUSES.DONE.key && (
              <Button
                loading={loading}
                label={<>{i18n._(t`Save and exit`)}</>}
                height={48}
                onClick={() => this.setState({ isSaveFeedbackModal: true })}
              />
            )}
          </>
        </OverviewHeading>
        <BaseLayout maxWidth={1000} smallWidth={true}>
          <Wrapper>
            <MainContent>
              <ReviewIntroduction
                name={review.name}
                deadline={
                  ratingType === RATING_TYPES.SELF_RATING
                    ? get(review, 'deadlineSelfReview')
                    : currentRequest.deadline
                }
                requestedBy={forUser}
                ratingType={ratingType}
              />
              {this.sections.map((section, sectionIndex) => {
                return (
                  <Section
                    key={sectionIndex}
                    ref={this.itemsRefs}
                    sectionCount={this.sections.length}
                    sectionIndex={sectionIndex}
                    isActive={sectionIndex === activeSection}
                    section={section}
                    forUserName={forUserName}
                    ratingType={ratingType}
                    fetchedRatings={fetchedRatings}
                    isReviewCoach={isReviewCoach}
                    requestedBy={forUser}
                    isNotSharedFeedback={
                      ratingType === RATING_TYPES.SELF_RATING
                        ? !review.isSelfFeedbackProvided
                        : currentRequest.status !== REQUEST_STATUSES.SHARED.key
                    }
                    skillCategories={review.skillCategories}
                    changeActiveSection={this.changeActiveSection}
                    activeQuestionStep={activeQuestionStep}
                    changeActiveQuestionStep={this.changeActiveQuestionStep}
                    showErrors={showErrors}
                    errorQuestion={errorQuestion}
                    goalCyclesLearningPlan={review.goalCyclesLearningPlan}
                    goalCyclesBusinessPlan={review.goalCyclesBusinessPlan}
                    goalCyclesLearningEval={review.goalCyclesLearningEval}
                    goalCyclesBusinessEval={review.goalCyclesBusinessEval}
                    {...(review.isRateWithLevel
                      ? { skillLevels: review.skillsJobProfileLevels }
                      : {})}
                    saveAsDraft={() => {
                      const isDraft =
                        currentRequest.status !== REQUEST_STATUSES.SHARED.key &&
                        currentRequest.status !== REQUEST_STATUSES.CALIBRATE.key;
                      const isCalibrate = currentRequest.status === REQUEST_STATUSES.CALIBRATE.key;
                      this.onSubmit(isDraft, {
                        disableFinalRedirect: true,
                        isAutoSave: true,
                        isCalibrate,
                      });
                    }}
                    onRatingChange={this.onRatingChange}
                    callBeforeRedirect={this.setIsSaved}
                    viewerId={user?.id}
                  />
                );
              })}
              <Prompt
                when={!isSaved}
                message="Are you sure you want to exit this screen? Your data will be lost."
              />
              {/* do not display startModal for self-review */}
              {isStartModal && ratingType !== RATING_TYPES.SELF_RATING && (
                <StartModal
                  ratingType={ratingType}
                  review={review}
                  forUserName={forUserName}
                  loading={loading}
                  onSubmit={() => {
                    acceptRequest(currentRequest.id);
                    this.setState({ isStartModal: false });
                  }}
                  onReject={this.onDecline}
                  onBack={this.handleClose}
                  hideHeaderClose={true}
                />
              )}
              {isSaveFeedbackModal && (
                <SaveFeedbackModal
                  onClose={() => this.setState({ isSaveFeedbackModal: false })}
                  onSubmit={this.handleSaveFeedback}
                  items={statusActions}
                  loading={loading}
                />
              )}
            </MainContent>
          </Wrapper>
        </BaseLayout>
      </RightSidebarLayout>
    );
  }
}

export default withI18n()(
  connect((state) => ({
    user: state.auth.user,
    users: state.users.data,
    review: state.currentReview,
    currentRatings: state.currentRatings,
    currentRequest: state.currentRequest,
    currentRole: state.selected.role,
    app: state.app,
    isModuleRTFeedbacksEnabled: checkModuleRTFeedbacks(state),
  }))(withToasts(GiveFeedback)),
);
