import React from 'react';

import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Tooltip from '~/components/Tooltip';

import { GIVE_FEEDBACK_STATUSES } from '~/constants';
import { COLOR_PALETTE } from '~/styles';

const Circle = styled.div`
  width: 7px;
  min-width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${(props) => props.$color};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-right: 63px;
`;

const Text = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 200px;
`;

const Status = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.7px;
  color: ${COLOR_PALETTE.BLUE_DARK};
  margin-left: 9px;
`;

const ReviewStatus = ({ status, viewText }) => {
  const { i18n } = useLingui();
  const statusItem =
    Object.values(GIVE_FEEDBACK_STATUSES).find((s) => s.key === status) ||
    GIVE_FEEDBACK_STATUSES.DRAFT;
  return (
    <Tooltip tooltip={<Text>{i18n._(viewText)}</Text>}>
      <Row>
        <Circle $color={statusItem.color} />
        <Status>{i18n._(statusItem.label)}</Status>
      </Row>
    </Tooltip>
  );
};

export default ReviewStatus;
