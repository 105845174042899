import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { useSelector } from 'react-redux';

import { Icon, ICONS } from '~/components/Icon';

import { MultiSelectCheckBox } from './components/MultiSelectCheckBox';
import {
  Flag,
  LanguageCounter,
  Row,
  SelectedLang,
  StyledDropdown,
  StyledOption,
} from './MultiLangualDropdown.design';

import { ROLES } from '~/constants';
import type { ILanguage } from '~/constants/languages';
import routes from '~/constants/routes';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

export interface IMultiLangComponentProps {
  languageState: ILanguageStateReturn;
  isSingleSelect?: boolean;
}

const SingleSelectMultiLangualDropdown = ({
  languages,
  setLanguages,
  stringifyLanguage,
  preSelectedLanguage,
}: {
  languages: ILanguage[];
  setLanguages: (languages: ILanguage[]) => void;
  stringifyLanguage: (item: ILanguage) => string;
  preSelectedLanguage?: ILanguage;
}) => {
  const CustomOption = ({
    item,
    isSelected,
    handleNewItemSelected,
  }: {
    item: ILanguage;
    isSelected: boolean;
    handleNewItemSelected: (item: ILanguage) => void;
  }) => (
    <StyledOption onClick={() => handleNewItemSelected(item)}>
      <Icon
        icon={isSelected ? ICONS.RADIO_BUTTON_SELECTED : ICONS.RADIO_BUTTON}
        stroke={COLORS.BORDERS}
        fill={COLORS.COMPANY}
      />
      <span>{stringifyLanguage(item)}</span>
    </StyledOption>
  );

  const CustomSelect = ({
    selectedItem,
    onClick,
  }: {
    clicked: boolean;
    active: boolean;
    onClick: () => void;
    selectedItem?: ILanguage;
  }) => (
    <Row onClick={onClick}>
      <SelectedLang>
        {selectedItem && (
          <Flag key={selectedItem.locale}>
            {`${getUnicodeFlagIcon(
              selectedItem.locale.substring(selectedItem.locale.indexOf('_') + 1),
            )}`}
          </Flag>
        )}
      </SelectedLang>
      <Icon icon={ICONS.DROPDOWN} />
    </Row>
  );

  return (
    <StyledDropdown
      isSingleSelect
      onChange={(selectedItem) => selectedItem && setLanguages([selectedItem])}
      items={languages}
      selectedItem={preSelectedLanguage}
      CustomDropdownComponent={CustomSelect}
      CustomOptionComponent={CustomOption}
      stringifyItem={stringifyLanguage}
      hashItem={(item) => item.locale}
      skipSort
    />
  );
};

const MultiSelectMultiLangualDropdown = ({
  languages,
  setLanguages,
  stringifyLanguage,
  companyPrimaryLanguage,
  preSelectedLanguages,
}: {
  languages: ILanguage[];
  setLanguages: (languages: ILanguage[]) => void;
  stringifyLanguage: (item: ILanguage) => string;
  companyPrimaryLanguage: ILanguage;
  preSelectedLanguages: ILanguage[];
}) => {
  const user = useSelector(getUser);
  const { i18n } = useLingui();
  const isAdmin = user.isAdmin;

  const goToLanguages = () => {
    routes.SETTINGS.go({ role: ROLES.ADMIN });
  };

  const MultiLanguageOption = ({
    item,
    isSelected,
    handleNewItemSelected,
  }: {
    item: ILanguage;
    isSelected: boolean;
    handleNewItemSelected: (item: ILanguage) => void;
  }) => (
    <StyledOption
      $disabled={companyPrimaryLanguage.locale === item.locale}
      onClick={
        companyPrimaryLanguage.locale !== item.locale
          ? () => handleNewItemSelected(item)
          : undefined
      }
    >
      <MultiSelectCheckBox selected={isSelected} />
      <span>{stringifyLanguage(item)}</span>
    </StyledOption>
  );

  const MultiLanguageSelect = ({
    selectedItems,
    onClick,
  }: {
    clicked: boolean;
    active: boolean;
    onClick: () => void;
    selectedItems: ILanguage[];
  }) => (
    <Row onClick={onClick}>
      <SelectedLang>
        {selectedItems.slice(0, 3).map((lang: ILanguage) => (
          <Flag key={lang.locale}>
            {`${getUnicodeFlagIcon(lang.locale.substring(lang.locale.indexOf('_') + 1))}`}
          </Flag>
        ))}
        {selectedItems.length > 3 && (
          <LanguageCounter> {selectedItems.length - 3}+</LanguageCounter>
        )}
      </SelectedLang>
      <Icon icon={ICONS.DROPDOWN} width="16" height="16" />
    </Row>
  );

  return (
    <StyledDropdown
      onChange={(selectedItems) => setLanguages(selectedItems)}
      items={languages}
      CustomDropdownComponent={MultiLanguageSelect}
      CustomOptionComponent={MultiLanguageOption}
      actions={
        isAdmin
          ? [{ handler: goToLanguages, name: i18n._(t`Create new`), icon: ICONS.ADD_PLUS }]
          : undefined
      }
      selectedItems={preSelectedLanguages}
      stringifyItem={stringifyLanguage}
      hashItem={(item) => item.locale}
      skipSort
    />
  );
};

const MultiLangComponent = ({
  languageState,
  isSingleSelect = false,
}: IMultiLangComponentProps) => {
  const { i18n } = useLingui();
  const { languages, setLanguages, companyLanguages, companyPrimaryLanguage } = languageState;
  const items = companyLanguages.sort((a, b) => {
    if (a.locale === companyPrimaryLanguage.locale) {
      return -1;
    }

    if (b.locale === companyPrimaryLanguage.locale) {
      return 1;
    }

    return a.language.toLowerCase().localeCompare(b.language.toLowerCase());
  });

  const stringifyItem = (item: ILanguage) =>
    `${item.language} ${item.locale === companyPrimaryLanguage.locale ? i18n._(t`(default)`) : ''}`;

  return isSingleSelect ? (
    <SingleSelectMultiLangualDropdown
      languages={items}
      preSelectedLanguage={companyLanguages.find(
        (language) => !!languages.find((i: ILanguage) => i.locale === language.locale),
      )}
      stringifyLanguage={stringifyItem}
      setLanguages={setLanguages}
    />
  ) : (
    <MultiSelectMultiLangualDropdown
      languages={items}
      preSelectedLanguages={companyLanguages.filter(
        (language) =>
          !!languages.find((i: ILanguage) => i.locale === language.locale) ||
          language.locale === companyPrimaryLanguage.locale,
      )}
      stringifyLanguage={stringifyItem}
      setLanguages={setLanguages}
      companyPrimaryLanguage={companyPrimaryLanguage}
    />
  );
};
export { MultiLangComponent };
