import React, { useEffect } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';

import TabHeader from './components/TabHeader';
import { Meetings } from './Meetings';

import routes from '~/constants/routes';
import { checkModuleConversations } from '~/selectors/baseGetters';
import getUserFullName from '~/utils/getUserFullName';

const TabContainer = styled.div`
  margin: 16px 24px;

  .user-public-meetings_header {
    margin-bottom: 24px;
  }
`;

const MeetingsTab = ({ user, i18n }) => {
  const userName = getUserFullName(user);
  const history = useHistory();
  const isModuleMeetingsEnabled = useSelector(checkModuleConversations);

  useEffect(() => {
    if (!isModuleMeetingsEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleMeetingsEnabled, history]);

  return (
    <BoxWithBorder>
      <TabContainer>
        <TabHeader
          title={i18n._(t`Meetings`)}
          description={i18n._(t`An overview of all ${userName} meetings`)}
          className="user-public-meetings_header"
        />
        <Meetings user={user} />
      </TabContainer>
    </BoxWithBorder>
  );
};

MeetingsTab.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withI18n()(MeetingsTab);
