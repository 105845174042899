import React from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import IconButton from '~/components/IconButton';
import NotificationIcon from '~/components/Icons/Notification';
import StatusIcon from '~/components/StatusIcon';
import AvatarCard from '~/components/UI/AvatarCard';
import { Header6Light } from '~/components/UI/Typographics/headers';

import { REQUEST_STATUSES, REQUEST_TYPES } from '~/constants';
import { COLORS } from '~/styles';

const RequestBox = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
`;

const AvatarColumn = styled(AvatarCard)`
  flex: 1;
  overflow: hidden;
`;

const StatusColumn = styled(StatusIcon)`
  flex: 0 0 120px;
  text-align: center;
`;

const SourceColumn = styled.div`
  flex: 0 0 70px;
  text-align: center;
  ${Header6Light} {
    margin: 0;
  }
`;

const ActionColumns = styled.div`
  flex: 0 0 24px;
`;

const DateColumn = styled.div`
  flex: 0 0 145px;
  text-align: right;
  padding-right: 8px;
  box-sizing: border-box;
`;

const DateText = styled.span`
  color: ${COLORS.TEXT_SECONDARY};
`;

function getDateRelation(date) {
  if (date) {
    const currentDate = moment();
    const lastUpdateDate = moment(date);

    const days = currentDate.diff(lastUpdateDate, 'days');
    if (days > 0) {
      return <Trans>{days} day(s) ago</Trans>;
    } else {
      const hours = currentDate.diff(lastUpdateDate, 'hours');
      if (hours > 0) {
        return <Trans>{hours} hour(s) ago</Trans>;
      } else {
        const minutes = currentDate.diff(lastUpdateDate, 'minutes');
        if (minutes > 1) {
          return <Trans>{minutes} minute(s) ago</Trans>;
        } else {
          return <Trans>just now</Trans>;
        }
      }
    }
  }
  return '';
}

const RequestItem = ({ request, onReminderClick, i18n }) => {
  const justNow =
    request.reminderDate && moment().diff(moment(request.reminderDate), 'minutes') <= 1;

  return (
    <RequestBox>
      <AvatarColumn userId={request.toUser} email={request.toEmail} type={AvatarCard.TYPES.LARGE} />
      <SourceColumn>
        {request.toEmail && (
          <Header6Light>
            <Trans>Extern</Trans>
          </Header6Light>
        )}
      </SourceColumn>
      <StatusColumn status={request.status} noBg />
      <DateColumn>
        <DateText>{getDateRelation(request.reminderDate)}</DateText>
      </DateColumn>
      <ActionColumns>
        {request.status === REQUEST_STATUSES.REQUESTED.key &&
          request.type !== REQUEST_TYPES.FEEDBACK_FROM_OUTSIDE_PEER && (
            <IconButton
              onClick={() => onReminderClick(request)}
              fill={false}
              size={24}
              disabled={justNow}
              tooltip={i18n._(t`Send reminder`)}
            >
              <NotificationIcon size={18} />
            </IconButton>
          )}
      </ActionColumns>
    </RequestBox>
  );
};

RequestItem.propTypes = {
  isDisabled: PropTypes.bool,
  request: PropTypes.object.isRequired,
  onRequestDelete: PropTypes.func,
};

export default React.memo(withI18n()(RequestItem));
