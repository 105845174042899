import React, { useRef, useEffect, useState } from 'react';

import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import moment from 'moment/moment';
import styled from 'styled-components';

import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';

import ConversationsRow from './ConversationsRow';

import CardDevelopmentCycleIcon from '~/assets/card-development-cycle.svg';

import useBoolState from '~/hooks/useBoolState';
import { getReviewsForDevelopmentCycle } from '~/services/reviews';
import { COLOR_PALETTE } from '~/styles';

const TIMELINE_WIDTH = 3;
const TIMELINE_CIRCLE_TOP = 15;
const TIMELINE_CIRCLE_BOTTOM = 72 - TIMELINE_CIRCLE_TOP; // 72 row height

const Wrapper = styled.div`
  max-height: 476px;
  overflow: auto;
  margin-right: 24px; // to split scroll from row
  padding-right: 16px;
`;

const TimelineWrapper = styled.div`
  position: relative;
`;

const Timeline = styled.div`
  position: absolute;
  width: ${TIMELINE_WIDTH}px;
  background-color: var(--company-color);
  top: ${TIMELINE_CIRCLE_TOP}px;
  bottom: ${TIMELINE_CIRCLE_BOTTOM}px;
  left: 8px;
`;

const defineScrollTarget = (items) => {
  let scrollTarget = null;
  if (isEmpty(items)) {
    return scrollTarget;
  }

  const currentDate = moment();

  if (!isEmpty(items)) {
    let minDays = null;

    items.forEach((item) => {
      const startDate = item.startDate; // startDate adds to item in back-end dynamically
      if (!startDate) {
        return;
      } // do nothing if startDate is undefined

      const days = moment(startDate).diff(currentDate, 'days');

      const isFutureReview = 0 <= days;
      if (isFutureReview) {
        // set minDays if it is null or item has closer startDate
        if (isNull(minDays) || days <= minDays) {
          minDays = days;
          scrollTarget = item;
        }
      }
    });
  }

  // if all items in the past -> scroll to the last
  if (!scrollTarget) {
    scrollTarget = items[items.length - 1];
  }

  return scrollTarget;
};

const Conversations = () => {
  const [items, setItems] = useState([]);
  const [scrollTarget, setScrollTarget] = useState(null);
  const $loading = useBoolState(true);
  const myRef = useRef(null);

  const executeScroll = () => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      const prevYear = moment().subtract(1, 'years').toDate();
      const nextYear = moment().add(1, 'years').toDate();

      const items = await getReviewsForDevelopmentCycle({
        startDate: prevYear,
        endDate: nextYear,
      });

      if (isMounted) {
        setScrollTarget(defineScrollTarget(items));
        setItems(items);
        $loading.off();
      }
    };

    fetchData();

    return () => (isMounted = false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // scroll to current date
    if (!$loading.value) {
      executeScroll();
    }
  }, [$loading.value]);

  return (
    <ShowSpinnerIfLoading loading={$loading.value}>
      {isEmpty(items) ? (
        <Placeholder
          title={<Trans>Nothing here yet..</Trans>}
          subTitle={<Trans>Your development conversations will show here</Trans>}
          Icon={() => (
            <SvgIcon
              url={CardDevelopmentCycleIcon}
              width="48px"
              height="48px"
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
          )}
        />
      ) : (
        <Wrapper>
          <TimelineWrapper>
            <Timeline />
            {items.map((item, key) => {
              // add ref only for scrollTarget item
              const rowParams = {};
              if (scrollTarget && item.id === scrollTarget.id) {
                rowParams.propsRef = myRef;
              }

              return (
                <ConversationsRow key={key} item={item} startDate={item.startDate} {...rowParams} />
              );
            })}
          </TimelineWrapper>
        </Wrapper>
      )}
    </ShowSpinnerIfLoading>
  );
};

export default React.memo(Conversations);
