import styled from 'styled-components';

import { Icon } from '~/components/Icon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModuleRow = styled.div`
  display: flex;
  width: 100%;
  margin: 12px 0;
  gap: 2%;
  box-sizing: border-box;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 12px;
`;

const ViewReport = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
`;

const CustomIcon = styled(Icon)``;

const PageEndCtr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
  font-size: 14px;
`;

export { Wrapper, ModuleRow, CardWrapper, ViewReport, CustomIcon, PageEndCtr };
