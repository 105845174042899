import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import FeedbackDeadline from '~/components/FeedbackDeadline';
import Modal from '~/components/Modal';
import RickTextView from '~/components/RickTextView';
import AvatarCard from '~/components/UI/AvatarCard';

import { RATING_TYPES, REQUEST_TYPES } from '~/constants';
import { COLOR_PALETTE, COLORS } from '~/styles';

const Row = styled.div`
  display: flex;
  align-items: center;

  & > button {
    margin-right: 8px;
    margin-left: 8px;
  }
`;

const RowSpaceBetween = styled(Row)`
  justify-content: space-between;
  width: 100%;
`;

const FeedbackType = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  color: ${COLOR_PALETTE.BLACK};
  text-transform: capitalize;
  padding-bottom: 2.5px;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 24px;
`;

const MessageLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const Message = styled.div`
  min-height: 175px;
  box-sizing: border-box;
  padding: 14px 14px 28px 21px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  color: ${COLOR_PALETTE.DARK_GRAY};
  background-color: ${COLORS.BG_PAGE};

  p {
    margin: 0;
  }
`;

const modalContentStyles = { padding: 0, height: '240px', flexDirection: 'row' };

function StartModal({
  i18n,
  loading,
  ratingType,
  currentRequest,
  review,
  onSubmit,
  onReject,
  onBack,
  forUserName,
}) {
  const getHeader = () => {
    let ratingTypeName = '';
    let deadline = null;

    switch (ratingType) {
      case RATING_TYPES.OTHER_RATING: {
        ratingTypeName = i18n._(t`Provide input for ${forUserName}'s ${review.name}`);
        deadline = review.deadlineUsersRequest;
        break;
      }
      case RATING_TYPES.COACH_RATING: {
        ratingTypeName = i18n._(t`Provide input for ${forUserName}'s ${review.name}`);
        deadline = review.deadlineCoachesRequest;
        break;
      }
      case RATING_TYPES.OUTSIDE_RATING: {
        ratingTypeName = i18n._(t`Provide input for ${forUserName}'s ${review.name}`);
        deadline = review.deadlineUsersRequest;
        break;
      }
      default:
        break;
    }

    return (
      <RowSpaceBetween>
        <Row>
          <FeedbackType>{ratingTypeName}</FeedbackType>
        </Row>

        <FeedbackDeadline deadline={deadline} />
      </RowSpaceBetween>
    );
  };

  return (
    <Modal
      title={getHeader()}
      width={831}
      minWidth={831}
      minHeight="240px"
      contentStyles={modalContentStyles}
      hideHeaderClose={true}
      hideFooter={false}
      footerCenter={false}
      footerLeft={
        <Row>
          {/* do not display back button for outside feedback*/}
          {ratingType !== RATING_TYPES.OUTSIDE_RATING && (
            <Button
              type={Button.types.white}
              label={i18n._(t`Back`)}
              styles={{ padding: '0 18px 0 20px', minWidth: '90px' }}
              onClick={onBack}
              loading={loading}
            />
          )}
        </Row>
      }
      footerRight={
        <Row>
          <Button
            type={Button.types.white}
            label={i18n._(t`Reject`)}
            styles={{ padding: '0 18px 0 20px', minWidth: '90px' }}
            onClick={onReject}
            loading={loading}
          />
          <Button
            label={i18n._(t`Start`)}
            styles={{ padding: '0 18px 0 20px', minWidth: '90px' }}
            onClick={onSubmit}
            loading={loading}
          />
        </Row>
      }
    >
      <Content>
        <div>
          {!isEmpty(currentRequest) && <AvatarCard type="large" userId={currentRequest.fromUser} />}
        </div>
        <div style={{ width: '590px' }}>
          <MessageLabel>{i18n._(t`message`)}</MessageLabel>
          {/* For COACH should be default message, because request creates automatically*/}
          <Message>
            {currentRequest.type === REQUEST_TYPES.FEEDBACK_FROM_COACH ? (
              i18n._(
                t`Because you are the coach of this person you have been automatically nominated to write a review`,
              )
            ) : get(currentRequest, 'description', '').includes('<p>') ? (
              <RickTextView html={currentRequest.description} />
            ) : (
              get(currentRequest, 'description', '')
            )}
          </Message>
        </div>
      </Content>
    </Modal>
  );
}

StartModal.propTypes = {
  ratingType: PropTypes.oneOf(Object.values(RATING_TYPES)).isRequired,
  review: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    currentRequest: state.currentRequest,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(StartModal)));
