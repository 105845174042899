import styled, { css } from 'styled-components';

import { Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

export const Select = styled.div<{ $clicked?: boolean; $active: boolean; selectHeight?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${({ selectHeight }) => selectHeight || '32px'};
  box-sizing: border-box;
  padding: 6.9px 9px 6.9px 9px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;

  svg {
    min-width: 11px;
    min-height: 11px;
    margin-left: 5px;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ${(props) =>
    props.$clicked &&
    css`
      color: ${COLORS.TEXT_HOVER};
      background-color: ${COLORS.HOVER};
    `}

  ${(props) =>
    props.$active &&
    css`
      color: ${COLORS.TEXT_MAIN};
      border: solid 1px ${COLORS.COMPANY};

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${COLORS.COMPANY};
        opacity: 0.1;
      }
    `}
`;

export const DropdownItem = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 72px;
  min-height: 40px;
  box-sizing: border-box;
  align-items: center;
`;

export const Option = styled(DropdownItem)<{ $selected?: boolean }>`
  align-items: center;
  color: ${COLORS.TEXT_HOVER};
  font-size: 12px;
  gap: 16px;
  padding: 9px 16px;
  text-align: left;
  background-color: ${COLORS.WHITE};

  svg {
    min-width: 18px;
    min-height: 18px;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  :hover {
    color: ${COLORS.TEXT_HOVER};
    background-color: ${COLORS.HOVER};
  }

  ${(props) =>
    props.$selected &&
    css`
      color: ${COLORS.WHITE};
      background-color: ${COLORS.COMPANY};
    `}
`;

export const NoResults = styled(Option)`
  color: ${COLORS.PLACEHOLDERS};
  :hover {
    color: ${COLORS.PLACEHOLDERS};
    background-color: ${COLORS.WHITE};
  }
`;

export const DropdownContainer = styled.div`
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  background-color: ${COLORS.WHITE};
  overflow: hidden;
`;

export const SliderAnimation = styled.div<{ $showDropdown?: boolean; $height: number }>`
  max-height: 0;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  transition: max-height 0.2s ease-out, border 0s ease-out 0.2s;
  width: 100%;
  z-index: 1000;
  ${(props) =>
    props.$showDropdown &&
    css`
      transition: max-height 0.2s ease-out, border 0s ease-out 0s;
      box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
      max-height: ${props.$height}px;
    `}
`;

export const Wrapper = styled.div`
  width: 195px;
  position: relative;
`;

export const Options = styled.div`
  max-height: 275px;
  overflow-y: auto;
`;

export const OptionContainer = styled.div<{
  $skipTopBorderRadius?: boolean;
  $skipBottomBorderRadius?: boolean;
}>`
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  stroke: ${COLORS.BORDERS};

  .checkmark {
    stroke: ${COLORS.COMPANY};
  }
`;

export const SearchInput = styled.input`
  background-color: ${COLORS.WHITE};
  border: none;
  color: ${COLORS.TEXT_HOVER};
  display: flex;
  width: 100%;
  align-items: center;
  padding: 7px 16px;
  margin: 2px;
`;

export const SearchPlaceholder = styled.span`
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 11px;
  padding: 11px 15px;
  align-items: center;
  color: ${COLORS.PLACEHOLDERS};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  pointer-events: none;
`;

export const SearchIcon = styled(Icon)`
  cursor: none;
  margin: 0;
`;

export const SearchDropdownItem = styled(DropdownItem)`
  position: relative;
  border-bottom: solid 1px ${COLORS.BORDERS};
  margin: -1px -1px 0 -1px;
  :focus-within {
    border-radius: 6px;
    border: solid 1px ${COLORS.COMPANY_HOVER};
    ${SearchPlaceholder} {
      visibility: hidden;
    }
  }

  > svg {
    cursor: pointer;
    margin: 10px;
    min-height: 12px;
    min-width: 12px;
  }
`;
