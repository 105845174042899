import React from 'react';

import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SpiderReview from '~/components/SpiderReview';

import { COLORS } from '~/styles';

const BlockWrapper = styled.div`
  padding: 16px;
  margin-bottom: 48px;
`;

const SummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  color: ${COLORS.TEXT_PRIMARY_2};
`;

function SpiderTab({ review }) {
  if (isEmpty(review)) {
    return null;
  }

  let isQuestionsWithRatings = false;
  if (!isEmpty(review.questions)) {
    isQuestionsWithRatings = Boolean(review.questions.find((q) => !q.hideRating));
  }

  return (
    <>
      {isQuestionsWithRatings && (
        <BlockWrapper>
          <SummaryHeader>
            <Title>
              <Trans>Summary</Trans>
            </Title>
          </SummaryHeader>
          <SpiderReview review={review} />
        </BlockWrapper>
      )}
    </>
  );
}

SpiderTab.propTypes = {
  review: PropTypes.object.isRequired,
};

export default SpiderTab;
