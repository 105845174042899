import React, { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AutocompleteFilter from '~/components/AutocompleteFilter';
import Modal from '~/components/Modal';
import ColleaguesList from '~/components/Modals/ColleaguesModal/components/ColleaguesList';
import PaginationBar from '~/components/PaginationBar';
import { SearchField, StyledIcon } from '~/components/Text';
import TextField from '~/components/TextField';

import { JOB_PROFILE_STATUSES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import useStringState from '~/hooks/useStringState';
import { checkModuleCareer, checkModuleRoleSearch, getUser } from '~/selectors/baseGetters';
import getLang from '~/selectors/getLang';
import { getJobProfiles } from '~/services/jobProfiles';
import { getSkills } from '~/services/skills';
import { getTeams } from '~/services/teams';
import { getCompanyUsers } from '~/services/users';

const NameSearch = styled(SearchField)`
  margin-bottom: 10px;
  margin-right: 8px;
  margin-left: 8px;
  ${TextField} {
    padding: 12px 9px 12px 40px;
    max-height: 48px;
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
    }
  }
  ${StyledIcon} {
    left: 10px;
  }
`;

const FilterRow = styled.div`
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
  margin-right: 8px;
  margin-left: 8px;
`;

const SkillsFilter = styled(AutocompleteFilter)`
  flex: 1;
  height: 48px;
  margin-right: 8px;
  font-size: 16px;
`;

const RolesFilter = styled(AutocompleteFilter)`
  width: 230px;
  height: 48px;
  margin-right: 8px;
  font-size: 16px;
`;

const TeamsFilter = styled(AutocompleteFilter)`
  width: 230px;
  height: 48px;
  font-size: 16px;
`;

const fetchSkills = (search, lang) => {
  return getSkills({
    search,
    hideDeleted: true,
    enabledSkillsOnly: true,
    limit: 20,
    lang,
  });
};

const fetchJobProfiles = (search) => {
  return getJobProfiles({
    limit: 20,
    search,
    status: JOB_PROFILE_STATUSES.ACTIVE.key,
  });
};

const fetchTeams = (search) => {
  return getTeams({
    limit: 20,
    search,
  });
};

const ColleaguesModal = ({ onClose }) => {
  const { i18n } = useLingui();
  const $search = useStringState();
  const debSearch = useDebounce($search.value, 300);
  const { pagination, resetPagination, changePagination } = usePagination(6);
  const $usersLoading = useBoolState();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [jobProfiles, setJobProfiles] = useState([]);
  const isModuleCareerEnabled = useSelector(checkModuleCareer);
  const lang = useSelector(getLang);
  const currentUser = useSelector(getUser);
  const isModuleRolesSearchEnabled = useSelector(checkModuleRoleSearch);

  const emptyFilters =
    !debSearch && isEmpty(selectedTeams) && isEmpty(selectedSkills) && isEmpty(selectedRoles);
  useEffect(() => {
    const mount = async () => {
      await Promise.all([getJobProfiles()]).then(([jobProfiles]) => {
        setJobProfiles(Object.values(jobProfiles));
      });
    };
    mount();
  }, []);

  useEffect(() => {
    async function retrieveData() {
      $usersLoading.on();
      const { data } = await getCompanyUsers(
        {
          search: debSearch,
          limit: pagination.limit,
          skip: pagination.skip,
          skills: selectedSkills.map((s) => s.id),
          jobProfiles: selectedRoles.map((r) => r.id),
          teams: selectedTeams.map((t) => t.id),
          sort: { lastName: 1 },
          usersToHide: [currentUser.id],
        },
        ['teams'],
      );
      setUsers(Object.values(data?.users ?? {}));
      setTotal(data?.total ?? 0);
      $usersLoading.off();
    }

    if (
      debSearch ||
      !isEmpty(selectedTeams) ||
      !isEmpty(selectedSkills) ||
      !isEmpty(selectedRoles)
    ) {
      retrieveData();
    } else if (emptyFilters) {
      setUsers([]);
      setTotal(0);
    }
    // eslint-disable-next-line
  }, [debSearch, selectedSkills, selectedRoles, selectedTeams, pagination]);

  return (
    <Modal
      title={<Trans>Find colleagues</Trans>}
      headerStyles={{ padding: '15px 24px 0' }}
      greyDescription={i18n._(t`Find colleagues based on skills, their role or team`)}
      greyDescriptionStyles={{ padding: '0px 24px 8px' }}
      onClose={onClose}
      width={750}
      minHeight={644}
      hideFooter
      showDivider={false}
    >
      <NameSearch
        onChange={(e) => {
          $search.setForInput(e);
          resetPagination();
        }}
        placeholder={i18n._(t`Search by name`)}
      />
      <FilterRow>
        <SkillsFilter
          placeholder={<Trans>Search by skill</Trans>}
          maxCount={5}
          checkedList={selectedSkills}
          onChange={(value) => {
            setSelectedSkills(value);
            resetPagination();
          }}
          fetch={(search) => fetchSkills(search, lang)}
          alphabeticalList
          isSearchIcon
        />
        {isModuleRolesSearchEnabled && isModuleCareerEnabled && (
          <RolesFilter
            placeholder={<Trans>Search by role</Trans>}
            checkedList={selectedRoles}
            onChange={(value) => {
              setSelectedRoles(value);
              resetPagination();
            }}
            fetch={fetchJobProfiles}
            alphabeticalList
            isSearchIcon
          />
        )}
        <TeamsFilter
          placeholder={<Trans>Search by team</Trans>}
          checkedList={selectedTeams}
          onChange={(value) => {
            setSelectedTeams(value);
            resetPagination();
          }}
          fetch={fetchTeams}
          alphabeticalList
          isSearchIcon
        />
      </FilterRow>
      <ColleaguesList
        users={users}
        totalUsers={total}
        jobProfiles={jobProfiles}
        isModuleRolesSearchEnabled={isModuleRolesSearchEnabled}
        emptyFilters={emptyFilters}
        onClose={onClose}
        $usersLoading={$usersLoading}
      />
      <PaginationBar
        pagination={pagination}
        changePagination={changePagination}
        count={total}
        showCount
        noBorder
        noShadow
      />
    </Modal>
  );
};

export default React.memo(ColleaguesModal);
