import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

const Done = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--company-color);
`;

const Warning = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e93c3c;
`;

const STATUSES = {
  SAVING: 'saving',
  SAVED: 'saved',
  ERROR: 'error',
};

function AutoSave({ status }) {
  const { i18n } = useLingui();

  const renderRow = () => {
    switch (status) {
      case AutoSave.STATUSES.SAVING:
        return <Done>{i18n._(t`Saving`)}...</Done>;
      case AutoSave.STATUSES.SAVED:
        return <Done>{i18n._(t`Saved`)}</Done>;
      case AutoSave.STATUSES.ERROR:
        return <Warning>{i18n._(t`Error, can not save`)}</Warning>;
      default:
        return null;
    }
  };

  return <Wrapper>{renderRow()}</Wrapper>;
}

AutoSave.STATUSES = STATUSES;

AutoSave.propTypes = {
  status: PropTypes.oneOf(Object.values(STATUSES)),
};

export default AutoSave;
