import React from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';

import * as appActions from '~/store/app/actions';
import { COLORS, COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  background: ${COLORS.BG_PAGE};
  position: relative;
  min-height: 100vh;
`;

const Content = styled.div`
  width: 340px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.div`
  color: ${COLORS.TEXT_BLACK};
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 28px;
`;

const Description = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 20px;
`;

const BackText = styled(Description)`
  font-style: italic;
  margin-bottom: 32px;
`;

const NotFound = ({ i18n, history }) => {
  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(appActions.setIsRequestError({ value: false }));
    history.goBack();
  };

  return (
    <Wrapper>
      <Content>
        <Main>
          <Title>
            <Trans>Ooops!</Trans>
          </Title>
          <Description>
            <Trans>
              This page does not seem to exist. Please go back and and retry your previous action.
              Does it happen again? Please contact us via the chat.
            </Trans>
          </Description>
          <BackText>
            <Trans>Click the button to go back to the main page.</Trans>
          </BackText>
          <Button onClick={goBack} label={i18n._(t`Go back`)} width={100} />
        </Main>
      </Content>
    </Wrapper>
  );
};

export default withI18n()(withRouter(NotFound));
