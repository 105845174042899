import { QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';

export default function createQuestionName({ i18n, type, name, selectedSkills }) {
  switch (type) {
    case QUESTION_TYPES.CUSTOM:
      return name;
    case QUESTION_TYPES.SKILL: {
      let skillsNames = '';
      if (!isEmpty(selectedSkills)) {
        skillsNames = selectedSkills.map((skill) => skill.name).join(', ');
      }
      return i18n._(
        t`A separate question will be generated for the following skills: ${skillsNames}`,
      );
    }
    case QUESTION_TYPES.JOB_PROFILE:
      return i18n._(
        t`A separate question will be generated for the skills in the role profile of each participant. The quantity of questions depend on the quantity of skills in the role profile`,
      );
    default:
      return '';
  }
}
