import { useState } from 'react';

const DEFAULT_SIGN = {
  isAgreeSeen: true,
  isAgreeContent: true,
  isUserComment: true, // always true
  isCoachComment: true, // always true
};

const useSignState = () => {
  const [digitalSign, setDigitalSign] = useState(null);

  const setKey = (key, value) => {
    setDigitalSign({
      ...digitalSign,
      [key]: value,
    });
  };

  return {
    digitalSign,
    initSignSection: (data) => {
      setDigitalSign(data || null);
    },
    addSignSection: () => {
      setDigitalSign(DEFAULT_SIGN);
    },
    deleteSignSection: () => {
      setDigitalSign(null);
    },
    toggleSignSection: (value) => (value ? setDigitalSign(DEFAULT_SIGN) : setDigitalSign(null)),
    setIsAgreeSeen: (value) => setKey('isAgreeSeen', value),
    setIsAgreeContent: (value) => setKey('isAgreeContent', value),
  };
};

export default useSignState;
