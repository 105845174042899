import { ICompany } from './company';
import { IUser } from './user';

export enum CONVERSATION_REPEAT_TYPES {
  NO_REPEAT = 'no-repeat',
  WEEK = 'week',
  YEAR = 'year',
  CUSTOM = 'custom',
}

export enum INTEGRATION_STATUS {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  NOT_CREATED = 'notCreated',
  AZURE = 'azure',
  GOOGLE = 'google',
}

export enum LINK_STATUS {
  FAILED = 'failed',
  SUCCESS = 'success',
  PENDING = 'pending',
}

export enum EVENT_STATUS {
  CANCELLED = 'cancelled',
  CONFIRMED = 'confirmed',
}

export enum SOURCE_COLLECTION {
  REVIEWS = 'reviews',
  USER_CONVERSATIONS = 'userConversations',
}

export enum CALENDAR_EVENT_LAST_ATTEMPT_STATUS {
  SUCCESS = 'success',
  CREATION_ERROR = 'creationError',
  UPDATE_ERROR = 'updateError',
}

export enum UPDATE_OPTION {
  THIS_AND_FUTURE_EVENTS = 'thisAndFutureEvents',
  ONLY_THIS_EVENT = 'onlyThisEvent',
}

export interface CalendarConnection {
  integrationId?: string;
  eventId?: string;
  parentEventId?: string;
  lastAttemptStatus?: CALENDAR_EVENT_LAST_ATTEMPT_STATUS;
  lastAttemptDate?: string;
  isFirstInSequence?: boolean;
  linkStatus?: LINK_STATUS;
  linkExpiration?: Date;
  linkMeeting?: string;
}

export interface ICalendarEvent {
  id: string;
  company: ICompany['id'];
  startDate: Date;
  endDate: Date;
  sourceId: string;
  sourceCollection: SOURCE_COLLECTION;
  originalSourceId: string;
  creator: IUser['id'];
  isRepeating: boolean;
  isPast?: boolean;
  subsequence?: number;
  orderInSubsequence?: number;
  repeatRules?:
    | {
        type: CONVERSATION_REPEAT_TYPES.NO_REPEAT;
        deadlines: { startDate: Date; endDate: Date }[];
        until: null;
        every: null;
      }
    | {
        type: CONVERSATION_REPEAT_TYPES.WEEK | CONVERSATION_REPEAT_TYPES.YEAR;
        every: number;
        until: Date;
        deadlines: { startDate: Date; endDate: Date }[];
      }
    | {
        type: CONVERSATION_REPEAT_TYPES.CUSTOM;
        deadlines: { startDate: Date; endDate: Date }[];
        until: null;
        every: null;
      };

  integration: INTEGRATION_STATUS;
  azureConnection?: CalendarConnection;
  googleConnection?: CalendarConnection;

  meta?: {
    createdDate?: string;
  };
}
