import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

export type TOptions = {
  key: string;
  title: (i18n: I18n) => string;
};

export const MONTH_OPTIONS = [
  { key: '12', title: (i18n: I18n) => i18n._(t`Last 12 months`) },
  { key: '24', title: (i18n: I18n) => i18n._(t`Last 24 months`) },
  { key: '36', title: (i18n: I18n) => i18n._(t`Last 36 months`) },
];
export const PRIMARY_OPTIONS = [
  { key: 'primary_none', title: () => '-' },
  { key: 'theme', title: (i18n: I18n) => i18n._(t`Theme`) },
  { key: 'team', title: (i18n: I18n) => i18n._(t`Team`) },
  { key: 'survey', title: (i18n: I18n) => i18n._(t`Survey`) },
  { key: 'question', title: (i18n: I18n) => i18n._(t`Question`) },
];
export const SECONDARY_OPTIONS = [
  { key: 'secondary_none', title: () => '-' },
  { key: 'theme', title: (i18n: I18n) => i18n._(t`Theme`) },
  { key: 'team', title: (i18n: I18n) => i18n._(t`Team`) },
  { key: 'survey', title: (i18n: I18n) => i18n._(t`Survey`) },
  { key: 'question', title: (i18n: I18n) => i18n._(t`Question`) },
];
export const MEASURE_OPTIONS = [
  { key: 'measure_none', title: () => '-' },
  { key: 'theme', title: (i18n: I18n) => i18n._(t`Theme`) },
  { key: 'team', title: (i18n: I18n) => i18n._(t`Team`) },
  { key: 'survey', title: (i18n: I18n) => i18n._(t`Survey`) },
  // { key: 'question', title: (i18n: I18n) => i18n._(t`Question`) },
  { key: 'week', title: (i18n: I18n) => i18n._(t`Week`) },
  { key: 'month', title: (i18n: I18n) => i18n._(t`Month`) },
  { key: 'quarter', title: (i18n: I18n) => i18n._(t`Quarter`) },
  { key: 'year', title: (i18n: I18n) => i18n._(t`Year`) },
];

export const PAGINATION_OPTIONS = [
  { id: 50, label: (i18n: I18n) => i18n._(t`50 per page`) },
  { id: 100, label: (i18n: I18n) => i18n._(t`100 per page`) },
  { id: 150, label: (i18n: I18n) => i18n._(t`150 per page`) },
];

// TODO: remove this when we confirm on per page options
export const PAGINATION_PRIMARY_OPTIONS = [
  { id: 10, label: (i18n: I18n) => i18n._(t`10 per page`) },
  { id: 25, label: (i18n: I18n) => i18n._(t`25 per page`) },
  { id: 50, label: (i18n: I18n) => i18n._(t`50 per page`) },
  { id: 100, label: (i18n: I18n) => i18n._(t`100 per page`) },
];

export const MENU_SIZE = {
  RIGHT_FULL_WIDTH: 200,
  LEFT_HALF_WIDTH: 180,
  LEFT_FULL_WIDTH: 280,
  ONLY_AVERAGE: 90,
  ONLY_BENCHMARK: 110,
};

export type TPaginationProp = {
  skip: number;
  limit: number;
  index: number;
};
