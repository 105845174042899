import React, { memo } from 'react';

import { ROLES, SIGNATURE_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import FeedbackDeadline from '~/components/FeedbackDeadline';
import { getSignatureStatus } from '~/components/NewReviewReport/sections/DigitallySign/helpers';
import RoleLabel from '~/components/RoleLabel';
import UserAvatar from '~/components/UI/Avatar';
import TaskStatus from '~/pages/ReviewUserPage/components/TaskStatus';

import { Item, ItemNameText } from './CommonStyledComponents';

const ItemWrapper = styled(Item)`
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  min-width: 110px;
  justify-content: flex-end;
`;

const Deadlines = styled.div`
  display: flex;
  width: 160px;
  align-items: center;
`;

const UserDigitallySignItem = ({
  view,
  userId,
  review,
  isDisabled,
  isLocked,
  onSignStart,
  lockedHint,
  isUserOwner,
  userName,
  isActionDisabled,
  actionTooltip,
}) => {
  const { i18n } = useLingui();

  const signStatus = getSignatureStatus(view, {
    signatures: review.signatures,
    conversationCoaches: review.conversationCoaches,
    userId,
  });

  const renderActionButton = (signStatus) => {
    let content = null;

    switch (signStatus) {
      case SIGNATURE_STATUSES.ACTIVE: {
        content = (
          <Button
            type={Button.types.primary}
            label={i18n._(t`Start`)}
            onClick={onSignStart}
            styles={{ minWidth: '83px', padding: 0 }}
            disabled={isLocked || isActionDisabled}
            tooltip={actionTooltip}
          />
        );
        break;
      }
      case SIGNATURE_STATUSES.WAITING: {
        if (view === ROLES.USER) {
          content = (
            <Button
              type={Button.types.primary}
              label={i18n._(t`Start`)}
              onClick={onSignStart}
              styles={{ minWidth: '83px', padding: 0 }}
              disabled={true}
              tooltip={i18n._(
                t`You can complete your report when the other participant(s) have completed the report.`,
              )}
            />
          );
        }
        break;
      }
      default:
        break;
    }
    return !isDisabled && content;
  };

  return (
    <ItemWrapper>
      <Row>
        <UserAvatar size={28} userId={userId} isGray={isDisabled} />
        <ItemNameText $disabled={isDisabled} $completed={signStatus === SIGNATURE_STATUSES.SIGNED}>
          {isUserOwner
            ? i18n._(t`Complete your conversation`)
            : i18n._(t`Complete ${userName}'s conversation`)}
        </ItemNameText>
        {view === ROLES.COACH && <RoleLabel value={i18n._(t`Participant`)} />}
        {isLocked && lockedHint}
      </Row>
      <Row>
        {signStatus !== SIGNATURE_STATUSES.SIGNED && (
          <Deadlines>
            <FeedbackDeadline deadline={review.deadlineToSign} width="29px" height="28px" />
          </Deadlines>
        )}
        <Actions>
          {isLocked ? (
            <TaskStatus
              status={
                signStatus === SIGNATURE_STATUSES.SIGNED
                  ? TaskStatus.STATUSES.COMPLETED
                  : TaskStatus.STATUSES.EXPIRED
              }
            />
          ) : signStatus === SIGNATURE_STATUSES.SIGNED ? (
            <TaskStatus status={TaskStatus.STATUSES.COMPLETED} />
          ) : (
            renderActionButton(signStatus)
          )}
        </Actions>
      </Row>
    </ItemWrapper>
  );
};

UserDigitallySignItem.propTypes = {
  view: PropTypes.oneOf([ROLES.USER, ROLES.COACH]),
  userId: PropTypes.string,
  currentRole: PropTypes.string,
  review: PropTypes.object,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  onSignStart: PropTypes.func,
  lockedHing: PropTypes.node,
  isActionDisabled: PropTypes.bool,
};

export default memo(UserDigitallySignItem);
