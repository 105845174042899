import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Button from '~/components/Button';
import PlusIcon from '~/components/Icons/Plus';
import Modal from '~/components/Modal';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { Row } from './Row';

import useBoolState from '~/hooks/useBoolState';
import { getKpis, saveKpis } from '~/services/kpis';
import getDuplicateObjectsInArray from '~/utils/getDuplicateObjectsInArray';

const SaveButton = styled(Button)`
  display: flex;
  align-self: flex-end;
`;

const List = styled.div``;

function EditKPIModal({ onClose }) {
  const { i18n } = useLingui();
  const $loading = useBoolState(true);
  const $isError = useBoolState(false);
  const [kpis, setKpis] = useState([]);
  // const company = useSelector(getCurrentCompany);
  const { addToast } = useToasts();

  // on first render
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const response = await getKpis({ filters: { isDeleted: false } });
      if (isMounted) {
        setKpis(response?.data[API_RETURN_FIELDS.KPIS] || []);
        $loading.off();
      }
    };

    fetchData();

    return () => (isMounted = false);

    // eslint-disable-next-line
  }, []);

  const showToast = (title, message, type = TOAST_TYPES.INFO) => {
    addToast({
      title,
      subtitle: message,
      type,
    });
  };

  const checkErrors = () => {
    const duplicates = getDuplicateObjectsInArray(kpis, 'name');
    const emptyName = kpis.some((c) => isEmpty(c.name));
    const hasErrors = duplicates.length > 0 || emptyName;

    if (hasErrors) {
      let errorMessage = '';

      if (duplicates.length > 0) {
        errorMessage += i18n._(t`KPI names must be unique. `);
      }
      if (emptyName) {
        errorMessage += i18n._(t`KPI names cannot be empty.`);
      }

      showToast(i18n._(t`No valid input for KPI names`), errorMessage, TOAST_TYPES.ERROR);

      $isError.on();
    }

    return hasErrors;
  };

  const onSubmit = async () => {
    $loading.on();
    const hasErrors = checkErrors();

    if (!hasErrors) {
      try {
        showToast(
          i18n._(t`KPI successfully updated`),
          i18n._(t`KPI names are successfully updated`),
        );

        // prepare kpis to save
        // leave only name and id with type string.
        // New kpi has id with type number (we do not need to send this id to server, server will create new one).
        const preparedKpis = kpis.map((kpi) => ({
          name: kpi.name,
          ...(typeof kpi.id === 'string' && { id: kpi.id }),
        }));

        await saveKpis(preparedKpis);
      } finally {
        $loading.off();
      }

      onClose();
    } else {
      $loading.off();
    }
  };

  const onKPIAdd = () => {
    const kpisWithNew = [...kpis];
    const newTempId = +new Date();
    kpisWithNew.push({ id: newTempId, name: '' }); // before submit kpi has temp id - number (date in unixTime);
    setKpis(kpisWithNew);
  };

  const onKPIDelete = (id) => {
    setKpis(kpis.filter((kpi) => kpi.id !== id));

    // reset error
    if ($isError.value) {
      $isError.off();
    }
  };

  const onKPIUpdate = (id, name) => {
    setKpis(
      kpis.map((kpi) => {
        if (kpi.id === id) {
          kpi.name = name;
        }
        return kpi;
      }),
    );

    // reset error
    if ($isError.value) {
      $isError.off();
    }
  };

  return (
    <Modal
      title={i18n._(t`Key performance indicators (KPI’s)`)}
      onClose={onClose}
      width={675}
      minWidth={462}
      minHeight="200px"
      contentStyles={{
        background: '#fff',
        height: '100%',
        padding: '12px 25px 24px',
      }}
      headerStyles={{ padding: '0 23px' }}
      hideFooter={true}
    >
      <List>
        {kpis.map((kpi) => {
          const isEmpty = !kpi.name; // no name validation;
          const isDuplicate = kpis.filter((k) => k.name === kpi.name).length > 1; // duplicate validation;
          const isError = $isError.value && (isEmpty || isDuplicate);
          return (
            <Row
              key={kpi.id}
              kpi={kpi}
              onDelete={onKPIDelete}
              onUpdate={onKPIUpdate}
              isError={isError}
            />
          );
        })}
      </List>
      <Button
        type="link-primary"
        label={i18n._(t`KPI`)}
        iconLeft={<PlusIcon size={36} />}
        onClick={onKPIAdd}
        styles={{
          fontSize: '16px',
          fontWeight: '600',
          fontStretch: 'normal',
          fontStyle: 'normal',
          letterSpacing: 'normal',
          width: '100px',
          padding: 0,
        }}
      />
      <SaveButton
        width={83}
        height={48}
        label={i18n._(t`Save`)}
        type="primary"
        onClick={onSubmit}
        loading={$loading.value}
      />
    </Modal>
  );
}

export default EditKPIModal;
