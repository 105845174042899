import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ProgressBar from '~/components/ProgressBar';

import routes from '~/constants/routes';
import { COLOR_SET } from '~/styles';

const WIDTH = 178;

const Wrapper = styled.div`
  width: ${WIDTH}px;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_SET.BLACK};
  margin-bottom: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const Description = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 6px;
  color: var(--company-color);
`;

const tippyOpts = {
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
      },
    },
  ],
};

const LinkStyled = styled(Link)`
  color: var(--company-color);
  text-decoration: none;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  cursor: pointer;
  margin-left: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const getUserLibraryByRelevancyIDLink = ({ roleId, relevancyId }) => {
  return routes.ONBOARD_AND_LEARN.build(
    {},
    {
      query: {
        roleId,
        relevancyId,
      },
      hash: 'all',
      isBackPath: true,
    },
  );
};

const CategoryItem = ({ name, progress, activitiesTotal, activitiesDone, roleId, relevancyId }) => {
  const { i18n } = useLingui();

  const label = i18n._(t`${activitiesDone} of the ${activitiesTotal} (${progress}%)`);

  return (
    <Wrapper>
      <Header>
        <Tippy trigger="mouseenter" theme="dark" popperOptions={tippyOpts} content={name}>
          <Title>{name}</Title>
        </Tippy>
        <LinkStyled
          to={getUserLibraryByRelevancyIDLink({
            roleId,
            relevancyId,
          })}
        >
          <Trans>View</Trans>
        </LinkStyled>
      </Header>
      <ProgressBar
        color="var(--company-color)"
        width={WIDTH}
        height={8}
        value={Number(progress)}
        isHideValue
      />
      <Description>{label}</Description>
    </Wrapper>
  );
};

export default CategoryItem;
