import React, { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { Chart } from 'react-chartjs-2';
import styled from 'styled-components';

import HorizontalBarChart from '~/components/HorizontalBarChart';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import Divider from '~/components/UI/Divider';
import TableCard from '~/components/UI/TableCard';
import GraphFiltersBar from '~/pages/ReviewAdminPage/components/Status/GraphFiltersBar';
import UserReviewStatusItem from '~/pages/ReviewAdminPage/components/Status/UserReviewStatusItem';

import PeopleIcon from '~/assets/icons-people.svg';
import PersonIcon from '~/assets/icons-person.svg';

import { KPI_TYPES } from '~/constants';
import { getTeamsInReview, getTeamsStatistics } from '~/services/reviews';
import { COLOR_SET } from '~/styles';
import { checkHasReviewCompleteLogic } from '~/utils/checkHasReviewCompleteLogic';

import FiltersBar from '../components/FiltersBar';
import Header from '../components/Header';
import InfoBoard, { BoardProgress, BoardValue } from '../components/InfoBoard';

const ParticipantsSection = styled(BoxWithBorder)``;

const PerTeamSection = styled(BoxWithBorder)`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const Graph = styled.div`
  display: flex;
  margin: 24px;
  min-height: 355px;
  height: ${(props) => props.pixelsToAdd + 355}px;
`;

const BarChart = styled(HorizontalBarChart)``;

const BarChartOptions = (isPercentage, maxValue = 100) => ({
  plugins: {
    legend: {
      position: 'bottom',
      labels: { usePointStyle: true, font: { size: 14 } },
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      min: 0,
      max: maxValue,
      ticks: {
        callback: (value) => (value ? value + (isPercentage ? '%' : '') : ''),
        font: {
          size: 12,
          weight: 'bold',
        },
        color: COLOR_SET.DARK_GRAY,
      },
    },
    y: {
      grid: { display: false },
      ticks: { font: { size: 14 }, color: COLOR_SET.TOTAL_BLACK },
    },
  },
});

const STATUS_COLS = {
  PARTICIPANT: {
    title: (i18n) => i18n._(t`Participant`),
    label: (i18n) => i18n._(t`Participant`),
    id: 'name',
  },
  SELF_REVIEW: {
    title: (i18n) => i18n._(t`Prepared conversation`),
    label: (i18n) => i18n._(t`Prepared conversation`),
    id: 'self',
  },
  PEER_REVIEW: {
    title: (i18n) => i18n._(t`Nominated Peer(s)`),
    label: (i18n) => i18n._(t`Nominated Peer(s)`),
    id: 'peer',
  },
  COACH_REVIEW: {
    title: (i18n) => i18n._(t`Nominated Coach(es)`),
    label: (i18n) => i18n._(t`Nominated Coach(es)`),
    id: 'coach',
  },
  CONVERSATION_COACH_REVIEW: {
    title: (i18n) => i18n._(t`Participants`),
    label: (i18n) => i18n._(t`Participants`),
    id: 'conversationCoach',
  },
  DATE_OF_CONVERSATION: {
    title: (i18n) => i18n._(t`Date`),
    label: (i18n) => i18n._(t`Date`),
    id: 'conversationDate',
  },
  ONE_ON_ONE: {
    title: (i18n) => i18n._(t`1-1`),
    label: (i18n) => i18n._(t`1-1`),
    id: 'oneOnOne',
  },
  DIGITALLY_SIGN: {
    title: (i18n) => i18n._(t`Completed`),
    label: (i18n) => i18n._(t`Completed`),
    id: 'digitallySign',
  },
};

const getCols = (i18n, { isLinkedConversations = false, isCompleteLogic = false }) => {
  const {
    PARTICIPANT,
    SELF_REVIEW,
    PEER_REVIEW,
    COACH_REVIEW,
    CONVERSATION_COACH_REVIEW,
    ONE_ON_ONE,
    DATE_OF_CONVERSATION,
    DIGITALLY_SIGN,
  } = STATUS_COLS;
  return [
    {
      title: PARTICIPANT.title(i18n),
      id: PARTICIPANT.id,
      dataType: 'string',
    },
    {
      title: SELF_REVIEW.title(i18n),
      id: SELF_REVIEW.id,
      width: '150px',
    },
    {
      title: PEER_REVIEW.title(i18n),
      id: PEER_REVIEW.id,
      width: '150px',
    },
    {
      title: COACH_REVIEW.title(i18n),
      id: COACH_REVIEW.id,
      width: '170px',
      tippy: i18n._(t`The coaches that are asked to provide input`),
    },
    {
      title: CONVERSATION_COACH_REVIEW.title(i18n),
      id: CONVERSATION_COACH_REVIEW.id,
      width: '150px',
      tippy: i18n._(t`The participants that are invited to the conversation`),
    },
    {
      title: DATE_OF_CONVERSATION.title(i18n),
      id: DATE_OF_CONVERSATION.id,
      width: '120px',
      tippy: i18n._(t`The date of the conversation`),
    },
    isLinkedConversations && {
      title: ONE_ON_ONE.title(i18n),
      id: ONE_ON_ONE.id,
      width: '100px',
    },
    isCompleteLogic && {
      title: DIGITALLY_SIGN.title(i18n),
      id: DIGITALLY_SIGN.id,
      width: '120px',
    },
    {
      width: '60px',
      name: 'key-for-unnamed-menu-in-table',
    },
  ].filter(Boolean);
};

const defaultStatistics = {
  nominatePercentage: 0,
  avgPeersNominated: 0,
  avgCoachesNominated: 0,
  selfReviewPercentage: 0,
  peerReviewPercentage: 0,
  coachReviewPercentage: 0,
};

const IDEAL_BAR_AMOUNT = 10;
const DEFAULT_KPI = KPI_TYPES.NOMINATED_PEERS;

const fillArray = (array = [], expectedLength = 0) => {
  const missingItems = expectedLength - array.length;
  if (missingItems > 0) {
    return array.concat(Array(missingItems).fill(''));
  } else {
    return array;
  }
};

const StatusTab = ({
  review,
  i18n,
  onRequestsCreated,
  onRequestCancel,
  onFeedbackRemind,
  onDeleteSignatures,
  filters,
  applyFilters,
  statistics = defaultStatistics,
  updateConversationCoaches,
}) => {
  const [graphFilters, setGraphFilters] = useState({
    selectedKPI: [DEFAULT_KPI.id],
    selectedTeams: [],
  });
  const [graphData, setGraphData] = useState({
    labels: fillArray([], IDEAL_BAR_AMOUNT),
    data: [],
    kpiType: DEFAULT_KPI,
    maxValue: 100,
  });
  const [isGraphHidden, setIsGraphHidden] = useState(false);
  const [graphLoading, setGraphLoading] = useState(true);

  const setTeamStatistics = async (reviewId, teams = [], selectedTeams, kpiId) => {
    const kpiType = Object.values(KPI_TYPES).find((type) => type.id === kpiId);

    // If the teams changed, then obtain the statistics
    if (teams !== selectedTeams) {
      const teamsIds = teams.map((team) => team.id) || [];
      const statistics = await getTeamsStatistics(reviewId, teamsIds);
      teams.map((team) => (team.statistics = statistics[team.id]));
    }

    const teamsStatistics = teams.map((team) => team.statistics[kpiId]);

    // Max right value in the graph
    let maxValue;
    if (kpiType.isPercentage) {
      maxValue = 100;
    } else if (teamsStatistics.length) {
      maxValue = Math.ceil(Math.max(...teamsStatistics));
    }
    if (!maxValue) {
      maxValue = 1;
    }

    setGraphData({
      labels: fillArray(
        teams.map((team) => team.name),
        IDEAL_BAR_AMOUNT,
      ),
      data: teamsStatistics,
      kpiType,
      maxValue,
    });
    setGraphFilters({ selectedKPI: [kpiId], selectedTeams: teams });

    // Force the chart to resize
    map(Chart.instances, (instance) => instance.resize());
  };

  useEffect(() => {
    getTeamsInReview(review.id, { limit: IDEAL_BAR_AMOUNT, sort: { name: 1 } })
      .then((teams) => setTeamStatistics(review.id, Object.values(teams), [], DEFAULT_KPI.id))
      .then(() => setGraphLoading(false));
  }, [review.id]);

  const applyGraphFilters = async (newFilters) => {
    const { selectedTeams, selectedKPI } = newFilters;
    await setTeamStatistics(review.id, selectedTeams, graphFilters.selectedTeams, selectedKPI[0]);
  };

  // If the amount of bars is bigger than the ideal amount, increase the size of the section proportionally
  let pixelsToAdd = 0;
  const extraBars = graphData.labels.length - IDEAL_BAR_AMOUNT;
  if (extraBars > 0) {
    pixelsToAdd = extraBars * 30;
  }

  const isLinkedConversations = review.subReviews.some((r) => !isEmpty(r.conversations));
  const isCompleteLogic = checkHasReviewCompleteLogic(
    review?.template?.digitalSign, // added ? because some old review does not have template
    get(review, 'meta.createdDate'),
  );
  const cols = getCols(i18n, { isLinkedConversations, isCompleteLogic });

  return (
    <>
      <InfoBoard>
        {review.isUsersReview && (
          <BoardProgress
            progress={statistics.nominatePercentage}
            title={<Trans>of participants nominated peer(s)</Trans>}
          />
        )}
        {review.isUsersReview && (
          <BoardValue
            value={statistics.avgPeersNominated}
            icon={<SvgIcon width="48px" height="48px" url={PeopleIcon} />}
            title={<Trans>Peer nominated on average</Trans>}
          />
        )}
        {review.isCoachesReview && (
          <BoardValue
            value={statistics.avgCoachesNominated}
            icon={<SvgIcon width="36px" height="36px" url={PersonIcon} />}
            title={<Trans>Coach nominated on average</Trans>}
          />
        )}
        {review.isSelfReview && (
          <BoardProgress
            progress={statistics.selfReviewPercentage}
            title={<Trans>Participants prepared</Trans>}
          />
        )}
        {review.isUsersReview && (
          <BoardProgress
            progress={statistics.peerReviewPercentage}
            title={<Trans>Peers provided input</Trans>}
          />
        )}
        {review.isCoachesReview && (
          <BoardProgress
            progress={statistics.coachReviewPercentage}
            title={<Trans>Coaches provided input</Trans>}
          />
        )}
      </InfoBoard>
      <PerTeamSection>
        <Header
          title={i18n._(t`Per team`)}
          subtitle={i18n._(t`An overview per team`)}
          onHideButtonClick={setIsGraphHidden}
        />
        <Divider />
        {isGraphHidden ? null : (
          <ShowSpinnerIfLoading loading={graphLoading}>
            <GraphFiltersBar filters={graphFilters} applyFilters={applyGraphFilters} />
            <Divider />
            <Graph pixelsToAdd={pixelsToAdd}>
              <BarChart
                options={BarChartOptions(graphData.kpiType.isPercentage, graphData.maxValue)}
                data={{
                  labels: graphData.labels,
                  datasets: [
                    {
                      borderRadius: {
                        topRight: 50,
                        bottomRight: 50,
                      },
                      borderSkipped: false,
                      barPercentage: 0.55,
                      backgroundColor:
                        document.documentElement.style.getPropertyValue('--company-color'),
                      minBarLength: 0,
                      label: graphData.kpiType.legend(i18n),
                      pointStyle: 'rectRounded',
                      data: graphData.data,
                    },
                  ],
                }}
              />
            </Graph>
          </ShowSpinnerIfLoading>
        )}
      </PerTeamSection>
      <ParticipantsSection>
        <Header title={i18n._(t`Per member`)} subtitle={i18n._(t`An overview per participant`)} />
        <Divider />
        <FiltersBar
          filters={filters}
          sortOptions={Object.values(STATUS_COLS).filter(
            (col) =>
              ![
                STATUS_COLS.CONVERSATION_COACH_REVIEW.id,
                STATUS_COLS.ONE_ON_ONE.id,
                !isCompleteLogic && STATUS_COLS.DIGITALLY_SIGN.id,
              ]
                .filter(Boolean)
                .includes(col.id),
          )}
          applyFilters={applyFilters}
        />
        <TableCard
          hideHeader
          noBorder
          noMarginBottom
          noBottomBorder
          items={review.subReviews}
          cols={cols.filter((c) => {
            switch (c.id) {
              case STATUS_COLS.PEER_REVIEW.id: {
                return review.isUsersReview;
              }
              case STATUS_COLS.SELF_REVIEW.id: {
                return review.isSelfReview;
              }
              case STATUS_COLS.COACH_REVIEW.id: {
                return review.isCoachesReview;
              }
              case STATUS_COLS.CONVERSATION_COACH_REVIEW.id: {
                return review.isCoachesReview;
              }
              default: {
                return true;
              }
            }
          })}
          renderRow={(userReview) => (
            <UserReviewStatusItem
              key={userReview.id}
              locked={review?.lockedStages?.feedback}
              userReview={userReview}
              isSelfReview={review.isSelfReview}
              isUsersReview={review.isUsersReview}
              isCoachesReview={review.isCoachesReview}
              onRequestsCreated={onRequestsCreated}
              onRequestCancel={onRequestCancel}
              onFeedbackRemind={onFeedbackRemind}
              updateConversationCoaches={updateConversationCoaches}
              isLinkedConversations={isLinkedConversations}
              digitalSign={get(review, 'template.digitalSign')}
              onDeleteSignatures={onDeleteSignatures}
              isCompleteLogic={isCompleteLogic}
              originalReview={review}
            />
          )}
        />
      </ParticipantsSection>
    </>
  );
};

export default React.memo(withI18n()(StatusTab));
