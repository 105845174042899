import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Tooltip, { TOOLTIP_PLACEMENTS, TOOLTIP_SIZES } from '~/components/Tooltip';

import { updateSelectedRole } from '~/store/selected/actions';
import { COLORS } from '~/styles';

import { MenuItemTitle, MenuBlockIcon, MenuItem, MenuRow, MenuCol } from '../styles/menuItemDesign';
import { IMenuItem } from '../types';

import type { DraggableProvided } from 'react-beautiful-dnd';

const LinkStyled = styled(Link)`
  text-decoration: none;
  width: 100%;
  display: flex;
`;

const MenuBlockIconStyled = styled(MenuBlockIcon)`
  width: 20px;
  min-width: 20px;
`;

const MenuColStyled = styled(MenuCol)<{
  isHovering?: boolean;
}>`
  overflow: hidden;
  margin-right: ${({ isHovering }) => (isHovering ? '0px' : '14px')};
`;

const ContextMenuWrapper = styled.div`
  flex: 1 1 20%;
  &:hover {
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 12%) 0px 4px 4px 0px;
  }
`;

const ContextOptionWrapper = styled.div`
  & svg {
    color: ${COLORS.TEXT_MAIN} !important;
  }
`;

interface ISecondaryMenuItemProps {
  menuItem: IMenuItem;
  pathname: string;
  isSortable?: boolean;
  showContextMenu?: boolean;
  draggableProvided?: DraggableProvided;
  onDeleteItem?: (id: string, isActiveRoute: boolean) => void;
  onDuplicateItem?: (id: string) => void;
}

const SecondaryMenuItem = ({
  menuItem,
  pathname,
  isSortable,
  showContextMenu,
  draggableProvided,
  onDeleteItem,
  onDuplicateItem,
}: ISecondaryMenuItemProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const isActiveRoute = menuItem.url === pathname;
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const itemContent =
    isSortable || showContextMenu ? (
      <>
        {isSortable && draggableProvided && (
          <MenuBlockIconStyled {...draggableProvided.dragHandleProps}>
            <Icon icon={ICONS.GRAB_GRID} size={ICON_SIZES.SMALL} />
          </MenuBlockIconStyled>
        )}
        <MenuColStyled>
          <Tooltip
            placement={TOOLTIP_PLACEMENTS.RIGHT}
            delay={[500, 0]}
            tooltip={menuItem.title?.(i18n)}
            size={TOOLTIP_SIZES.DEFAULT_TL}
            arrow={false}
          >
            <MenuItemTitle isCurrent={isActiveRoute} isBigLeftMargin>
              {menuItem.title?.(i18n)}
            </MenuItemTitle>
          </Tooltip>
        </MenuColStyled>
        {isHovering && showContextMenu && (
          <ContextMenuWrapper>
            <ContextMenu>
              {onDuplicateItem && (
                <ContextOptionWrapper>
                  <ContextOption
                    action={() => {
                      onDuplicateItem(menuItem.key);
                    }}
                    icon={ICONS.DUPLICATE}
                  >
                    {i18n._(t`Duplicate`)}
                  </ContextOption>
                </ContextOptionWrapper>
              )}
              {onDeleteItem && (
                <ContextOptionWrapper>
                  <ContextOption
                    isWarning
                    action={() => {
                      onDeleteItem(menuItem.key, isActiveRoute);
                    }}
                    icon={ICONS.DELETE_BIN}
                  >
                    {i18n._(t`Delete`)}
                  </ContextOption>
                </ContextOptionWrapper>
              )}
            </ContextMenu>
          </ContextMenuWrapper>
        )}
      </>
    ) : (
      <MenuCol>
        <Tooltip
          placement={TOOLTIP_PLACEMENTS.RIGHT}
          delay={[500, 0]}
          tooltip={menuItem.title?.(i18n)}
          size={TOOLTIP_SIZES.DEFAULT_TL}
          arrow={false}
        >
          <MenuItemTitle isCurrent={isActiveRoute} isBigLeftMargin>
            {menuItem.title?.(i18n)}
          </MenuItemTitle>
        </Tooltip>
      </MenuCol>
    );

  return (
    <LinkStyled
      onClick={() => {
        // @ts-ignore
        dispatch(updateSelectedRole(menuItem.role));
      }}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      // @ts-ignore
      to={menuItem.url}
    >
      <MenuItem isCurrentRoute={isActiveRoute}>
        <MenuRow>{itemContent}</MenuRow>
      </MenuItem>
    </LinkStyled>
  );
};
export default SecondaryMenuItem;
