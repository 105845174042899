import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';

import { IJobProfile } from '~/constants/jobProfiles';
import { COLORS } from '~/styles';

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 0 25px;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDER_LIGHT};
  width: 100%;
  box-sizing: border-box;
  color: ${COLORS.TEXT_PRIMARY};
  margin-left: 17px;
  &:hover {
    background: ${COLORS.BG_PAGE};
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CoverImg = styled.div`
  width: 99px;
  height: 63px;
  background-color: #edf0f7;
  border-radius: 6px 0 0 6px;
  margin-right: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--company-color);
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`;

const ItemName = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  white-space: nowrap;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PreviewCol = styled.div`
  width: 112px;
  margin: 18px 0;
  border-left: solid 1px ${COLORS.BORDER_LIGHT};
  cursor: pointer;
`;

const PreviewText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.COMPANY};
  margin-left: 22px;
`;

type RoleItemProps = {
  role: IJobProfile;
  checked: boolean;
  onCheckRole: (arg0: IJobProfile) => void;
  onPreview: (arg0: IJobProfile) => void;
};
const RoleItem = ({ role, checked, onCheckRole, onPreview }: RoleItemProps) => {
  return (
    <CardWrapper>
      <CheckBox checked={checked} onChange={() => onCheckRole(role)} size="24px" />
      <Card>
        <Row>
          <CoverImg
            style={{
              backgroundImage: role.coverImage ? 'url(' + role.coverImage + ')' : 'none',
            }}
          />
          <div>
            <ItemName>{role.name}</ItemName>
          </div>
        </Row>
        {
          <PreviewCol onClick={() => onPreview(role)}>
            <PreviewText>
              <Trans>Preview</Trans>
            </PreviewText>
          </PreviewCol>
        }
      </Card>
    </CardWrapper>
  );
};

export { RoleItem };
