import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import Switch from '~/components/Switch';

import { COLORS } from '~/styles';

const Row = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;

  .rc-switch {
    margin-right: 0;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: ${COLORS.TEXT_BLACK};
  letter-spacing: 0.48px;
`;

const IsApplicableSwitcher = ({ onChange, value }) => {
  return (
    <Row>
      <Text>
        <Trans>Not applicable</Trans>
      </Text>
      <Switch onChange={onChange} checked={value} />
    </Row>
  );
};

export default React.memo(IsApplicableSwitcher);
