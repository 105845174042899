import React, { useEffect, useRef } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '~/components/Button';
import DigitalMeetingLink from '~/components/DigitalMeetingLink';
import { Icon } from '~/components/Icon';
import PencilIcon from '~/components/Icons/Pencil';
import RickTextView from '~/components/RickTextView';
import Avatar from '~/components/UI/Avatar';
import BoxWithBorder from '~/components/UI/BoxWithBorder';

import { ROLES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';
import { stripHtml } from '~/utils/stripHtml';
import { getConversationDate } from '~/utils/userConversationsUtils';

const ContentWrapper = styled(BoxWithBorder)`
  width: 100%;
  margin-bottom: 24px;
  box-sizing: border-box;
  border-radius: 6px;
`;

const DateContainer = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.BLACK};
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) =>
    props.active
      ? css`
          &:hover {
            font-weight: 600;
            color: var(--company-color);
            cursor: pointer;
          }
        `
      : css`
          cursor: default;
        `}
  ${(props) =>
    props.isEmpty &&
    css`
      font-weight: 600;
      color: var(--company-color);
    `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const ContentRow = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const UserName = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${COLORS.BLACK};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  color: ${COLORS.BLACK};
`;

const ParticipantWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 8px;
`;

const ParticipantText = styled.div``;

const ParticipantAvatar = styled(Avatar)`
  margin-left: -8px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  ${(props) => (!props.showAll ? 'max-height: 64px;' : 'max-height: unset')}
  overflow: hidden;
`;

const Date = styled.div`
  width: 168px;
  text-align: left;
`;

const IconContainer = styled.div`
  align-self: flex-start;
  margin-top: 8px;
`;

const ReviewHeader = ({
  openPlanEvalModal,
  review,
  userName,
  setConversationCoaches,
  updateCalendarEvent,
}) => {
  const $isShowAll = useBoolState(true);
  const [heightOfDescription, setHeightOfDescription] = React.useState(0);
  const ref = useRef();
  const user = useSelector(getUser);
  const { i18n } = useLingui();
  const date = getConversationDate({ conversation: review, isReview: true });
  const participants = review.conversationCoaches;
  const numberOfParticipants = participants.length;
  const isCreator = review.createdBy === user.id;
  // it checks the user is coach or owner
  const isDateEditable =
    // owner with user role
    (isCreator && review.createdFor === user.id && review.createdIn === ROLES.USER) ||
    // coaches
    review.coaches.includes(user.id);

  // checks if the user a participant of the event,
  // potentially admin can see this Report Page, but the link is not valid for admin when the admin is not a participant
  const isAllowedToAccessMeetingLink =
    review.coaches.includes(user.id) ||
    participants.includes(user.id) ||
    review.createdFor === user.id;

  useEffect(() => {
    setHeightOfDescription(ref?.current?.clientHeight);
    $isShowAll.off();
    // eslint-disable-next-line
  }, []);

  return (
    <ContentWrapper>
      <Wrapper>
        <ContentRow>
          <Icon name="User" height={24} width={24} />
          <UserName>
            <Avatar size={28} userId={review.createdFor} />
            {userName}
          </UserName>
        </ContentRow>
        <ContentRow>
          <Icon name="Conversation" height={24} width={24} />
          <Title>{review.name}</Title>
        </ContentRow>
        <ContentRow>
          <Icon name="CalendarCheckbox" height={24} width={24} />
          {isDateEditable ? (
            <DateContainer active onClick={openPlanEvalModal} isEmpty={!date}>
              <Date>{date || i18n._(t`Set date`)}</Date>
              {date && <PencilIcon className="icon" size={20} />}
            </DateContainer>
          ) : (
            <DateContainer isEmpty={!date}>
              <Date>{date}</Date>
            </DateContainer>
          )}
          {review.includeLinkMeeting && isAllowedToAccessMeetingLink && (
            <DigitalMeetingLink
              event={review.calendarEvent}
              updateCalendarEvent={updateCalendarEvent}
            />
          )}
        </ContentRow>
        <ContentRow>
          <Icon name="UserGroup" height={24} width={24} />
          <DateContainer
            active={isCreator}
            onClick={isCreator ? setConversationCoaches : undefined}
          >
            <ParticipantText>
              {numberOfParticipants
                ? `${numberOfParticipants} ${i18n._(
                    numberOfParticipants > 1 ? t`participants` : t`participant`,
                  )}`
                : i18n._(t`Invite participants`)}
            </ParticipantText>
            <ParticipantWrapper>
              {participants.map((participant, index) =>
                index > 0 ? (
                  <ParticipantAvatar key={participant} size={28} userId={participant} />
                ) : (
                  <Avatar key={participant} size={28} userId={participant} />
                ),
              )}
            </ParticipantWrapper>
            {isCreator && !!numberOfParticipants && isEmpty(review.signatures) && (
              <PencilIcon className="icon" size={20} />
            )}
          </DateContainer>
        </ContentRow>
        {stripHtml(review.description).trim() && (
          <ContentRow>
            <IconContainer>
              <Icon name="Description" height={24} width={24} />
            </IconContainer>
            <DescriptionContainer>
              <Description showAll={$isShowAll.value} ref={ref}>
                <RickTextView html={review.description} />
              </Description>
              {heightOfDescription > 64 && (
                <Button
                  type="link-primary"
                  label={$isShowAll.value ? i18n._(t`Show less`) : i18n._(t`Show more`)}
                  onClick={$isShowAll.toggle}
                />
              )}
            </DescriptionContainer>
          </ContentRow>
        )}
      </Wrapper>
    </ContentWrapper>
  );
};

export default ReviewHeader;
