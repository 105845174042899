import React from 'react';

import { Sticky } from 'react-sticky';

import { Button } from '~/components/Buttons/Button';
import { ButtonVariant, ButtonSize } from '~/components/Buttons/types';
import {
  Wrapper,
  Box,
  BoxRight,
  BoxRightContainer,
  NavBlock,
  Row,
  Actions,
  StickContainer,
  Title,
  TitleWrapper,
} from '~/components/DashboardHeader/design';
import StringInfinite from '~/components/StringInfinite';
import Tooltip, { TOOLTIP_SIZES, TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

export interface IDashboardHeaderProps {
  onBack?: () => void;
  actions?: React.ReactNode;
  title?: React.ReactChild;
  description?: React.ReactNode;
  className?: string;
  logoUrl?: string;
  instructions?: string;
  instructionsUrl?: string;
  maxWidth?: string | number;
  smallWidth?: boolean;
  noPadding?: boolean;
  subHeader?: React.ReactNode;
  height?: string;
}

export const DashboardHeader = React.memo<IDashboardHeaderProps>(
  ({ onBack, actions, title, subHeader }) => {
    return (
      <>
        <Wrapper>
          <Row>
            <Box>
              {onBack && (
                <NavBlock>
                  <Button
                    label={''}
                    size={ButtonSize.MEDIUM}
                    variant={ButtonVariant.CLOSE}
                    onClick={onBack}
                  />
                </NavBlock>
              )}
              {title && (
                <TitleWrapper>
                  <Tooltip
                    placement={TOOLTIP_PLACEMENTS.BOTTOM}
                    disabled={false}
                    size={TOOLTIP_SIZES.BIG}
                    tooltip={title}
                    maxWidth="300px"
                  >
                    <Title>
                      <StringInfinite>{title}</StringInfinite>
                    </Title>
                  </Tooltip>
                </TitleWrapper>
              )}
            </Box>
            <BoxRight>
              <BoxRightContainer>{actions && <Actions>{actions}</Actions>}</BoxRightContainer>
            </BoxRight>
          </Row>
        </Wrapper>
        {subHeader}
      </>
    );
  },
);

DashboardHeader.displayName = 'DashboardHeader';

const Navigation = (props: IDashboardHeaderProps) => {
  /* added disableHardwareAcceleration to prevent header popup overflow by page content*/
  const content = <DashboardHeader {...props} />;

  return (
    <Sticky relative disableHardwareAcceleration disableCompensation={false} topOffset={12}>
      {({ style, isSticky }) => {
        return (
          <StickContainer style={style} $isSticky={isSticky} height={props.height}>
            {content}
          </StickContainer>
        );
      }}
    </Sticky>
  );
};

export default React.memo(Navigation);
