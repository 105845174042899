import React, { memo } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import FeedbackDeadline from '~/components/FeedbackDeadline';
import RoleLabel from '~/components/RoleLabel';
import AvatarGroup from '~/components/UI/AvatarGroup';
import TaskStatus from '~/pages/ReviewUserPage/components/TaskStatus';

import { Item, ItemNameText } from './CommonStyledComponents';

import getEventTime from '~/utils/getEventTime';

const ItemWrapper = styled(Item)`
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  min-width: 110px;
  justify-content: flex-end;
`;

const Deadlines = styled.div`
  display: flex;
  width: 160px;
  align-items: center;
`;

const UserPlanConversationItem = ({
  isActionDisabled,
  isDisabled,
  coaches,
  startDate,
  endDate,
  onAction,
  actionTooltip,
  isLocked,
  deadline,
  lockedHint,
}) => {
  const { i18n } = useLingui();
  const isDone = startDate && endDate;

  return (
    <ItemWrapper>
      <Row>
        <AvatarGroup users={coaches} size={28} showTooltip />
        <ItemNameText $disabled={isDisabled} $completed={isDone}>
          <Trans>Set a date and time for review</Trans>
        </ItemNameText>
        <RoleLabel value={i18n._(t`Participant`)} />
        {isLocked && lockedHint}
      </Row>
      <Row>
        {!isLocked && !isDone && (
          <Deadlines>
            <FeedbackDeadline deadline={deadline} width="29px" height="28px" />
          </Deadlines>
        )}
        <Actions>
          {isLocked ? (
            <TaskStatus
              status={isDone ? TaskStatus.STATUSES.COMPLETED : TaskStatus.STATUSES.EXPIRED}
            />
          ) : (
            <>
              {isDisabled ? (
                getEventTime(startDate, endDate)
              ) : (
                <Button
                  type={isDone ? Button.types.linkPrimary : Button.types.primary}
                  onClick={onAction}
                  label={isDone ? getEventTime(startDate, endDate) : i18n._(t`Start`)}
                  styles={{ minWidth: '83px', padding: 0 }}
                  disabled={isLocked || isActionDisabled}
                  tooltip={actionTooltip}
                />
              )}
            </>
          )}
        </Actions>
      </Row>
    </ItemWrapper>
  );
};

UserPlanConversationItem.propTypes = {
  isLocked: PropTypes.bool,
  deadline: PropTypes.string,
  lockedHint: PropTypes.node,
  isDisableAction: PropTypes.bool,
};

export default memo(UserPlanConversationItem);
