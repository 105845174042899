import { createAction } from 'redux-act';

import type { Dispatch } from 'redux';

export const getNumberOfItemsPerPage = createAction<number>('get number of items per page');

export const updateNumberOfItemsPerPage = (numOfItems: number) => {
  return async function (dispatch: Dispatch) {
    dispatch(getNumberOfItemsPerPage(numOfItems));
    return numOfItems;
  };
};
