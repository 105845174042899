import { ROLES } from '@learned/constants';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getKpis({ filters = {}, options = {} } = {}) {
  const body = {
    filters,
    options,
  };
  return cloudRequest(serverRoutes.kpis.getKpis, null, body, { forceRole: ROLES.ADMIN });
}

export function saveKpis(kpis) {
  const body = {
    kpis,
  };
  return cloudRequest(serverRoutes.kpis.saveKpis, null, body, { forceRole: ROLES.ADMIN });
}
