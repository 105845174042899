import React from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import { COLOR_PALETTE, COLORS } from '~/styles';

// 600px is max, with bigger height position wrong, because height > screen height
const MemberOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 526px;
  max-height: ${(props) => props.$maxHeight || 600}px;
  overflow-y: auto;
`;

const MemberOptionsActions = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReviewOptionsAction = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLOR_PALETTE.BLACK};
  cursor: pointer;
  padding: 12px 24px;

  &:hover {
    background-color: ${COLORS.BG_PAGE};
    color: var(--company-color);
  }

  &:active {
    background-color: ${COLORS.HOVER_ACTIVE};
  }
`;

const RoleOptions = ({
  createRole,
  synchroniseRoles,
  cleanupRoles,
  importRoles,
  showRoleRelevancies,
  isImportRolesEnabled,
  isSyncRolesEnabled,
  isDeleteRolesEnabled,
  isLearningEnabled,
}) => {
  const dropdownOptions = [
    {
      label: t`Create role manually`,
      action: () => createRole,
      visibility: () => true,
    },
    {
      label: t`Import role(s)`,
      action: () => importRoles,
      visibility: () => isImportRolesEnabled,
    },
    {
      label: t`Synchronise role(s)`,
      action: () => synchroniseRoles,
      visibility: () => isSyncRolesEnabled,
    },
    {
      label: t`Cleanup role(s)`,
      action: () => cleanupRoles,
      visibility: () => isDeleteRolesEnabled,
    },
    {
      label: t`Edit Learning relevancy categories`,
      action: () => showRoleRelevancies,
      visibility: () => isLearningEnabled,
    },
  ];

  const $maxHeight = window.innerHeight - 100;
  return (
    <MemberOptionsWrapper $maxHeight={$maxHeight}>
      <MemberOptionsActions>
        {dropdownOptions.map((action, i) => {
          if (action.visibility()) {
            return (
              <ReviewOptionsAction onClick={action.action()} key={i}>
                <Trans id={action.label} />
              </ReviewOptionsAction>
            );
          }
        })}
      </MemberOptionsActions>
    </MemberOptionsWrapper>
  );
};

export default withI18n()(RoleOptions);
