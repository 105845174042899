export enum CAREER_PLAN_STATUSES {
  CURRENT = 'current',
  INACTIVE = 'inactive',
}

export enum CAREER_PLAN_DEADLINES {
  ZERO_TO_ONE = 1,
  ONE_TO_THREE = 2,
  THREE_TO_FIVE = 3,
  FIVE_TO_TEN = 4,
}