import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import CheckBox from '~/components/CheckBox';
import Modal from '~/components/Modal';
import PaginationBar from '~/components/PaginationBar';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import { SearchField } from '~/components/Text';

import { RoleItem } from './RoleItem';

import ActivitiesIcon from '~/assets/collections_bookmark.svg';

import { IJobProfile } from '~/constants/jobProfiles';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { COLORS } from '~/styles';

const headerStyles = {
  padding: '25px 24px 20px 41px',
  borderBottom: `solid 1px ${COLORS.BG_PAGE}`,
  fontSize: '22px',
  fontWeight: '600',
  lineHeight: '1.18',
  letterSpacing: '-0.24px',
  height: '78px',
};

const modalContentStyle = {
  flex: '1',
  padding: '19px 35px 0',
};

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 32px 0 64px;
`;

const FilterRow = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
`;

const List = styled.div``;

const HeaderRow = styled.div<{ $isActive?: boolean }>`
  display: flex;
  width: 100%;
  margin-bottom: 9px;
  box-sizing: border-box;
  padding: 0 25px;
  height: 40px;
  background-color: ${(props) => (props.$isActive ? 'var(--company-color)' : COLORS.BG_PAGE)};
  align-items: center;
  color: ${(props) => (props.$isActive ? COLORS.WHITE : COLORS.TEXT_SECONDARY)};
`;

const RowText = styled.div<{ $isUpperCase?: boolean }>`
  margin-left: 17px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  ${(props) => props.$isUpperCase && 'text-transform: uppercase;'}
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  margin-right: 6px;
  width: 100%;
`;

type AddRoleModalProps = {
  onClose: () => void;
  jobProfiles: { [key: string]: IJobProfile };
  save: (roles: string[]) => void;
};

const Content = styled.div`
  flex: 1;
  .libary-activity-modal {
    flex-wrap: nowrap;
    margin: 0 17px 12px 25px;
  }
  border: solid 1px ${COLORS.HOVER};
  border-radius: 10px;
  padding: 4px 0 20px;
  box-sizing: border-box;
`;

const AddRoleModal = ({ jobProfiles, onClose, save }: AddRoleModalProps) => {
  const { i18n } = useLingui();
  const $isLoading = useBoolState(false);
  const [roles, setRoles] = useState<{ [key: string]: IJobProfile }>(jobProfiles);
  const [selectedRoles, setSelectedRoles] = useState<{ [key: string]: IJobProfile }>({});
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({ index: 1, limit: 10, skip: 0 });
  const [totalRoles, setTotalRoles] = useState(Object.values(roles).length);
  const selectedRoleIds = values(selectedRoles)
    .map((a) => a.id)
    .filter((a) => a);
  const isAllChecked = !Object.values(roles).find((role) => {
    return !selectedRoleIds.includes(role.id);
  });

  useEffect(() => {
    const newRoles: { [key: string]: IJobProfile } = {};
    Object.values(jobProfiles).filter((role) => {
      if (role.name.toLowerCase().includes(search.toLowerCase())) {
        newRoles[role.id] = role;
      }
    });
    setRoles(newRoles);
    setTotalRoles(Object.values(newRoles).length);
  }, [search, jobProfiles]);

  const onCheckAll = () => {
    if (isAllChecked) {
      setSelectedRoles({});
    } else {
      setSelectedRoles(roles);
    }
  };

  const onCheckRole = (role: IJobProfile) => {
    if (selectedRoles[role.id]) {
      delete selectedRoles[role.id];
      setSelectedRoles({ ...selectedRoles });
    } else {
      setSelectedRoles({ ...selectedRoles, [role.id]: role });
    }
  };

  const previewRole = (role: IJobProfile) => {
    routes.JOB_PROFILE.go(
      {},
      {
        roleId: role.id,
        isBackPath: true,
      },
    );
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    const newPagination = {
      ...pagination,
      skip,
      index,
    };
    setPagination(newPagination);
  };
  return (
    <Modal
      title={i18n._(t`Select role`)}
      onClose={onClose}
      minWidth={1009}
      width={1009}
      minHeight="600px"
      headerStyles={headerStyles}
      contentStyles={modalContentStyle}
      showDivider={false}
      hideFooter
    >
      <Content>
        <ShowSpinnerIfLoading loading={$isLoading.value}>
          <FilterRow>
            <SearchFieldWrapper
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              value={search}
              placeholder={i18n._(t`Search`)}
              style={{
                borderRadius: '6px',
                fontSize: '14px',
                fontWeight: 600,
              }}
            />
          </FilterRow>
          {!isEmpty(roles) ? (
            <>
              <List>
                <HeaderRow $isActive={!isEmpty(selectedRoles)}>
                  <CheckBox
                    checked={isAllChecked}
                    onChange={onCheckAll}
                    size="24px"
                    isDefaultColor={isEmpty(selectedRoles)}
                    defaultColor={COLORS.WHITE}
                  />
                  <RowText $isUpperCase={isEmpty(selectedRoles)}>
                    {!isEmpty(selectedRoles) ? (
                      i18n._(t`${Object.values(selectedRoles).length} selected`)
                    ) : (
                      <Trans>Roles</Trans>
                    )}
                  </RowText>
                </HeaderRow>
                {values(roles)
                  .splice(pagination.skip, pagination.limit)
                  .map((role) => (
                    <RoleItem
                      key={role.id}
                      role={role}
                      checked={!!selectedRoles[role.id]}
                      onCheckRole={onCheckRole}
                      onPreview={previewRole}
                    />
                  ))}
              </List>
              <PaginationBar
                pagination={pagination}
                changePagination={onPageChangeClick}
                count={totalRoles}
                noShadow
                noBorder
                showCount
              />
            </>
          ) : (
            <Placeholder
              Icon={() => (
                <SvgIcon
                  url={ActivitiesIcon}
                  width="50px"
                  height="50px"
                  isDefaultColor
                  defaultColor={COLORS.MIDDLE_GRAY}
                />
              )}
              title={i18n._(t`No roles match your criteria`)}
            />
          )}
        </ShowSpinnerIfLoading>
      </Content>
      <Footer>
        <Button
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          label={i18n._(t`Connect to role`)}
          onClick={() => {
            save(Object.values(selectedRoles).map((i) => i.id));
            onClose();
          }}
        />
      </Footer>
    </Modal>
  );
};

export default AddRoleModal;
