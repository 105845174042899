import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Container = styled.div`
  background-color: ${COLORS.WHITE};
`;

export const ItemWrap = styled.div<{ isDragging: boolean }>`
  width: 100%;
  display: flex;
  height: 53px;
  margin-bottom: 8px;
  border-radius: 6px;
  &:hover {
    .icon-container {
      display: felx;
    }

    .content-container {
      background-color: ${COLORS.HOVER};
    }
  }
  pointer-events: ${({ isDragging }) => (isDragging ? 'none' : 'visible')};
  z-index: ${({ isDragging }) => (isDragging ? '100' : '1')};

  .icon-container {
    display: ${({ isDragging }) => (isDragging ? 'flex' : 'none')};
  }

  .content-container {
    background-color: ${({ isDragging }) => (isDragging ? COLORS.HOVER : 'none')};
  }
  cursor: pointer;
`;

export const IconWrap = styled.div`
  width: 25px;
  display: none;
  border-radius: 6px;
  align-items: center;
`;

export const ContentWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
`;

export const ContentText = styled.div`
  margin-left: 30px;
  font-size: 14px;
  line-height: 26px;
  font-family: Poppins;
  color: ${COLORS.BLACK};
  width: 100%;
  height: 100%;
  display: flex;
`;
