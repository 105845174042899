import { ROLES } from '~/constants';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'reports';

export const REPORT_PERFORMANCE = new LinkConstructor(
  [ROLES.ADMIN],
  () => `${routeName}/performance`,
);

// company/<companyId>/admin/reports/dashboard
export const REPORTS_ADMIN_DASHBOARD = new LinkConstructor(
  [ROLES.ADMIN],
  () => `${routeName}/dashboard`,
);

// company/<companyId>/admin/reports/engagement
export const REPORTS_ADMIN_ENGAGEMENT = new LinkConstructor(
  [ROLES.ADMIN],
  () => `${routeName}/engagement`,
);

// company/<companyId>/admin/reports/<reportId>
export const REPORTS_ADMIN_CUSTOM_REPORT = new LinkConstructor(
  [ROLES.ADMIN],
  ({ reportId = ':reportId' }) => `${routeName}/custom/${reportId}`,
);

// company/<companyId>/coach/reports/engagement
export const REPORTS_COACH_TEAMS_ENGAGEMENT = new LinkConstructor(
  [ROLES.USER],
  () => `coach/${routeName}/engagement`,
);

// company/<companyId>/reports/engagement
export const REPORTS_USER_ENGAGEMENT = new LinkConstructor(
  [ROLES.USER],
  () => `${routeName}/engagement`,
);
