import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Editor from '~/components/Editor';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { TextArea } from '~/components/Text';
import ActionsPopover from '~/components/UI/ActionsPopover';
import { Header6, Header6Dark } from '~/components/UI/Typographics/headers';

import AdjustRatingScaleBlock from './AdjustRatingScaleBlock';
import CommentMandatorySetup from './CommentMandatorySetup';
import Ratings from './Ratings';

import { COLORS } from '~/styles';

const ActionsPopoverWrapper = styled(ActionsPopover)`
  margin-left: 16px;
`;

const Header = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
  margin: 20px 0 12px 0;
  width: fit-content;
`;

const ActionsTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 222px;
  height: 54px;
  border-radius: 3px;
  padding: 15px;
  border: solid 1px ${COLORS.BORDER_HARD};

  h6 {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    width: 712px;
  }
`;

const StyledTextArea = styled(TextArea)`
  height: 48px;
`;

const DescBlock = styled.div`
  margin-top: 24px;
  ${Header6Dark} {
    margin: 0 0 10px;
  }
`;

const SkillLabel = styled.div`
  padding: 11px;
  display: flex;
  border-radius: 3px;
  border: solid 1px #dae4f7;
`;

const CustomQuestion = ({
  question,
  updateName,
  updateDescription,
  handleQuestionRating,
  isNoneSectionType,
  sectionScaleLabels,
  deleteScaleLabel,
  updateScaleLabel,
  addScaleLabel,
  toggleIsSkippable,
  isSkippable,
  isShowErrors,
  toggleIsCommentObligated,
  isCommentObligated,
  isCollapsed,
}) => {
  const { i18n } = useLingui();
  const hideRatingOptions = [
    { label: i18n._(t`Only comment`), action: () => handleQuestionRating(true) },
    { label: i18n._(t`Rating and comment`), action: () => handleQuestionRating(false) },
  ];

  return (
    <>
      <Row>
        <StyledTextArea
          value={question.name || ''}
          onChange={(e) => updateName(e.target.value)}
          placeholder={i18n._(t`Add your question…`)}
          $accent
          resize={true}
          rows="1"
          error={isShowErrors && !question.name}
        />
        <ActionsPopoverWrapper
          items={hideRatingOptions}
          isRows
          placement="bottom"
          maxWidth={222}
          height="40px"
        >
          <ActionsTextWrapper>
            <Header6>
              {question.hideRating ? i18n._(t`Only comment`) : i18n._(t`Rating and comment`)}
            </Header6>
            <Icon icon={ICONS.DROPDOWN} size={ICON_SIZES.SMALL} color="var(--company-color)" />
          </ActionsTextWrapper>
        </ActionsPopoverWrapper>
      </Row>
      {!isCollapsed && (
        <>
          <DescBlock>
            <Header>
              <Trans>Description</Trans>
            </Header>
            <Editor
              value={question.description || ''}
              compact={true}
              small={true}
              onChange={(value) => updateDescription(value)}
            />
          </DescBlock>
          {!question.hideRating &&
            (isNoneSectionType ? (
              <AdjustRatingScaleBlock
                scaleLabels={question.scaleLabels}
                onDelete={deleteScaleLabel}
                onChange={updateScaleLabel}
                addScaleLabel={addScaleLabel}
                toggleIsSkippable={toggleIsSkippable}
                isSkippable={isSkippable}
                isShowErrors={isShowErrors}
              />
            ) : (
              <Ratings>
                {sectionScaleLabels.map((label) => (
                  <SkillLabel key={label}>{label}</SkillLabel>
                ))}
              </Ratings>
            ))}
          <CommentMandatorySetup
            isObligated={isCommentObligated}
            onChange={toggleIsCommentObligated}
          />
        </>
      )}
    </>
  );
};

export default React.memo(CustomQuestion);
