import React, { useState } from 'react';

import { GOAL_TYPES } from '@learned/constants';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';

import { getType } from '~/selectors/currentGoal';

import {
  ArrowSingle,
  AdvanceSettingsRow,
  AdvanceSettingsTitle,
  ItemContainer,
  ItemRow,
  StyledDivider,
} from '../../stepsDesign';
import { ContributeSection } from '../ContributeSection';
import { DescriptionSection } from '../DescriptionSection';
import { EditorsSection } from '../EditorsSection';
import { TimeFrameSection } from '../TimeFrameSection';
import { TypeSection } from '../TypeSection';

const AdvancedSettingsSection = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const type = useSelector(getType);

  return (
    <>
      <StyledDivider />
      <AdvanceSettingsRow
        isCollapsed={isOpen}
        marginTop="24px"
        marginBottom={isOpen ? '16px' : '24px'}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <AdvanceSettingsTitle>
          <Trans>Advanced settings</Trans>
        </AdvanceSettingsTitle>
        <ArrowSingle>
          <Icon icon={ICONS.BACK} size={ICON_SIZES.MEDIUM} />
        </ArrowSingle>
      </AdvanceSettingsRow>
      {!isOpen && <StyledDivider />}
      {isOpen && (
        <ItemContainer marginRight="0">
          <DescriptionSection />
          <ItemRow marginTop="16px" marginBottom="24px">
            <TimeFrameSection />
            {[GOAL_TYPES.PERSONAL, GOAL_TYPES.BUSINESS].includes(type) && <TypeSection />}
          </ItemRow>
          <StyledDivider />
          {type !== GOAL_TYPES.COMPANY && <ContributeSection />}
          <EditorsSection />
          <StyledDivider />
        </ItemContainer>
      )}
    </>
  );
};

export { AdvancedSettingsSection };
