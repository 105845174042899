import React, { useLayoutEffect, useRef, useState } from 'react';
import type { MouseEvent } from 'react';

import { ButtonSize, ButtonVariant } from '~/components/Buttons/types';
import {
  Base,
  ContextContainer,
  Option,
  Options,
  StyledButton,
} from '~/components/ContextMenu/design';
import type { ContextOptionProps, ContextMenuProps } from '~/components/ContextMenu/types';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { useOutsideClick } from '~/hooks/useOutsideClick';

function ContextOption({ action, children, icon, isWarning, persistOnClick }: ContextOptionProps) {
  const optionHandler = (e: MouseEvent) => {
    action();

    if (persistOnClick) {
      // When we stop propagation the parent options container will not close the menu when clicked
      e.stopPropagation();
    }
  };

  return (
    <Option $isWarning={isWarning} onClick={optionHandler}>
      {icon && <Icon className="icon" icon={icon} size={ICON_SIZES.SMALL} />}
      {children}
    </Option>
  );
}

function ContextMenu({ children, className, menuHeight }: ContextMenuProps) {
  const [showMenu, setShowMenu] = useState(false);
  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowMenu(false);
  });
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(contentRef?.current?.clientHeight ?? 0);
  }, []);

  return (
    <Base ref={baseRef} className={className}>
      <StyledButton
        variant={ButtonVariant.ICON}
        icon={ICONS.KEBAB_MENU}
        size={ButtonSize.MEDIUM}
        onClick={(e) => {
          setShowMenu((prev) => !prev);
          e.preventDefault();
          e.stopPropagation();
        }}
        label={''}
      />
      <ContextContainer $showContainer={showMenu} $height={menuHeight || height}>
        <Options
          ref={contentRef}
          onClick={(e) => {
            setShowMenu(false);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {children}
        </Options>
      </ContextContainer>
    </Base>
  );
}

export { ContextMenu, ContextOption };
