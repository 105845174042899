import { ROLES } from '~/constants';

export default ({
  isModuleCareerEnabled,
  isModuleGoalsEnabled,
  isModuleLearningEnabled,
  isModuleSkillPassportEnabled,
  selectedRole,
  TABS,
  TABS_ENUM,
  isModuleReviewsEnabled,
  isModuleMeetingsEnabled,
}) => {
  const PASSPORT_TABS = [];
  if (isModuleReviewsEnabled) {
    PASSPORT_TABS.push(TABS.find((t) => t.key === TABS_ENUM.CONVERSATIONS));
  }
  if (isModuleMeetingsEnabled) {
    PASSPORT_TABS.push(TABS.find((t) => t.key === TABS_ENUM.MEETINGS));
  }
  if (isModuleGoalsEnabled) {
    PASSPORT_TABS.push(TABS.find((t) => t.key === TABS_ENUM.GOALS));
  }
  if (isModuleLearningEnabled) {
    PASSPORT_TABS.push(TABS.find((t) => t.key === TABS_ENUM.LEARNING));
  }
  if (isModuleCareerEnabled) {
    PASSPORT_TABS.push(TABS.find((t) => t.key === TABS_ENUM.CAREER));
  }
  if (isModuleSkillPassportEnabled) {
    PASSPORT_TABS.push(TABS.find((t) => t.key === TABS_ENUM.PROFILE));
  }
  if (selectedRole === ROLES.COACH) {
    PASSPORT_TABS.push(TABS.find((t) => t.key === TABS_ENUM.NOTES));
  }
  if (selectedRole === ROLES.ADMIN) {
    PASSPORT_TABS.push(TABS.find((t) => t.key === TABS_ENUM.SETTINGS));
  }

  return PASSPORT_TABS;
};
