import React, { useEffect, useState } from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';

import { AutocompleteFilterTeams } from '~/components/AutocompleteFilters';
import Button from '~/components/Button';
import SearchSelectButton from '~/components/SearchSelectButton';

import { KPI_TYPES } from '~/constants';

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  height: 32px;
`;

const FilterTeams = styled(AutocompleteFilterTeams)`
  height: 32px;
  width: 156px;
`;

const KPISelectButton = styled(SearchSelectButton)`
  height: 30px;
  width: auto;
  margin-right: 6px;
`;

const ApplyButton = styled(Button)`
  margin-left: auto;
  height: 20px;
`;

const GraphFiltersBar = ({ filters, applyFilters = async () => {}, i18n }) => {
  const [newFilters, setNewFilters] = useState(filters);
  const [filtersChanged, setFiltersChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFiltersChanged(!isEqual(filters, newFilters));
  }, [filters, newFilters]);

  useEffect(() => {
    setNewFilters(filters);
  }, [filters]);

  const onApplyClick = async () => {
    setLoading(true);
    await applyFilters(newFilters);
    setLoading(false);
  };

  const setSelectedKPI = (kpi) => {
    if (kpi.length) {
      setNewFilters({ ...newFilters, selectedKPI: kpi });
    }
  };

  const setSelectedTeams = (teams) => {
    setNewFilters({ ...newFilters, selectedTeams: teams });
  };

  const kpiTypesValues = Object.values(KPI_TYPES);

  return (
    <FiltersContainer>
      <KPISelectButton
        title={kpiTypesValues.find((type) => type.id === newFilters.selectedKPI[0]).label(i18n)}
        checkedList={newFilters.selectedKPI}
        handleChange={setSelectedKPI}
        options={kpiTypesValues}
        width="230px"
        minWidth="auto"
        isRadio
        noHeader
      />
      <FilterTeams
        checkedList={newFilters.selectedTeams}
        onChange={setSelectedTeams}
        placeholder={<Trans>Team(s)</Trans>}
      />
      <ApplyButton
        disabled={!filtersChanged}
        loading={loading}
        onClick={onApplyClick}
        styles={{
          height: '32px',
          fontSize: '14px',
          width: 'auto',
        }}
        label={<Trans>Apply filters</Trans>}
      />
    </FiltersContainer>
  );
};

export default withI18n()(GraphFiltersBar);
