import { TEngagementData } from './types';

export const isFirst = (tempArr: TEngagementData[], i: number) => {
  if (i === 0) {
    return true;
  }
  const currentRow = tempArr[i];
  const previousRow = tempArr[i - 1];
  return currentRow.name !== previousRow.name;
};
