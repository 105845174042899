import React, { useState, useEffect, useMemo } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import CategoriesSection from '~/components/CategoriesSection';
import Legend from '~/components/Legend';

import { PerSkillSection } from './PerSkillSection';
import { SpiderSection } from './SpiderSection';

import { RATING_TYPES } from '~/constants';
import { GIVE_FEEDBACK_LEGEND } from '~/constants/reviews';
import { CATEGORY_SKILL_RATING_KEYS } from '~/constants/skills';
import useBoolState from '~/hooks/useBoolState';
import getLang from '~/selectors/getLang';
import { getSkillsRatings } from '~/services/ratings';
import { COLORS, COLOR_PALETTE } from '~/styles';

import { attachInfoForRatings, prepareRadioData } from '../utils';

const LegendWrapper = styled.div`
  margin-bottom: 20px;
`;

const SummaryWrapper = styled.div`
  margin: 20px 24px;
  box-sizing: content-box;
  & > div:last-child {
    margin-bottom: 20px;
  }
`;

const ReportHeader = styled.div`
  margin: 32px 0 16px;
`;

const ReportTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.TEXT_BLACK};
`;

const ReportSubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
  margin-top: 4px;
`;

function SkillsSection({
  jobProfile,
  scaleLabels,
  skillCoverage,
  skillCategories,
  onSkillClick,
  userId,
}) {
  const { i18n } = useLingui();

  const [rates, setRates] = useState({});
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const lang = useSelector(getLang);
  const $loading = useBoolState();

  // TODO (task is created) move logic to back-end UserCareer: SkillsSection
  useEffect(() => {
    async function fetchData() {
      $loading.on();
      const skillsIds = jobProfile.skills.map((s) => s.id);
      const skillsRates = await getSkillsRatings({ skills: skillsIds, createdFor: userId });
      const ratesRevies = uniq(
        [...skillsRates.selfRatings, ...skillsRates.coachRatings, ...skillsRates.peerRatings]
          .map((rate) => (rate.review ? rate.review.id : null))
          .filter((r) => r),
      );
      const skillCategoriesIds = skillCategories.map((c) => c.id);
      setSelectedCategories(skillCategoriesIds);
      setSelectedReviews(ratesRevies);
      setSelectedSkills(skillsIds);
      setRates(skillsRates);
      $loading.off();
    }
    fetchData();
    // eslint-disable-next-line
  }, [jobProfile]);

  const preparedRatings = prepareRadioData({
    i18n,
    lang,
    rates,
    jobProfile,
    selectedSkills,
    selectedReviews,
    selectedCategories,
    skillCategories,
  });

  const total = useMemo(
    () =>
      skillCoverage?.total ? attachInfoForRatings({ ratings: skillCoverage?.total, i18n }) : null,
    [i18n, skillCoverage?.total],
  );

  const { categories, legend } = useMemo(() => {
    const categories = skillCoverage?.categories.map((category) => {
      return {
        ...category,
        ratings: attachInfoForRatings({ ratings: category.ratings, i18n }),
      };
    });

    const legend = [
      {
        key: CATEGORY_SKILL_RATING_KEYS.AVG_SELF_RATING,
        color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.SELF_RATING].color,
        label: GIVE_FEEDBACK_LEGEND[RATING_TYPES.SELF_RATING].label(i18n),
      },
      {
        key: CATEGORY_SKILL_RATING_KEYS.AVG_PEERS_RATING,
        color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.OTHER_RATING].color,
        label: GIVE_FEEDBACK_LEGEND[RATING_TYPES.OTHER_RATING].label(i18n),
      },
      {
        key: CATEGORY_SKILL_RATING_KEYS.AVG_COACHES_RATING,
        color: GIVE_FEEDBACK_LEGEND[RATING_TYPES.COACH_RATING].color,
        label: GIVE_FEEDBACK_LEGEND[RATING_TYPES.COACH_RATING].label(i18n),
      },
      {
        key: CATEGORY_SKILL_RATING_KEYS.AVG_EXPECTED_LEVEL,
        color: COLOR_PALETTE.GRAY_MIDDLE,
        label: i18n._(t`Expected level for role`),
      },
    ].filter(({ key }) => {
      let isFilter = false;
      categories &&
        Object.values(categories).map((item) => {
          if (item.ratings?.[key].progress) {
            isFilter = true;
          }
        });

      return isFilter;
    });

    return { categories, legend };

    // eslint-disable-next-line
  }, [i18n, jobProfile.skills, skillCoverage?.categories]);

  return (
    <>
      <SummaryWrapper>
        <ReportHeader>
          <ReportTitle>
            <Trans>The report</Trans>
          </ReportTitle>
          <ReportSubTitle>
            <Trans>
              All the competenties and skills required for this role. Click on a skill for detailed
              information.
            </Trans>
          </ReportSubTitle>
        </ReportHeader>

        <LegendWrapper>
          <Legend items={legend} />
        </LegendWrapper>

        <CategoriesSection
          title={i18n._(t`Summary`)}
          description={i18n._(t`Your average skill match with the required skills for this role.`)}
          categories={categories}
          total={total}
        />
      </SummaryWrapper>
      <SpiderSection
        rates={rates}
        jobProfile={jobProfile}
        initialSelectedSkills={selectedSkills}
        initialSelectedReviews={selectedReviews}
        initialSelectedCategories={selectedCategories}
        skillCategories={skillCategories}
        scaleLabels={scaleLabels}
        userId={userId}
      />
      <PerSkillSection
        rates={preparedRatings.rates}
        jobProfile={jobProfile}
        skillCategories={skillCategories}
        userId={userId}
        onSkillClick={onSkillClick}
      />
    </>
  );
}

SkillsSection.propTypes = {
  jobProfile: PropTypes.object.isRequired,
  scaleLabels: PropTypes.array.isRequired,
};

export { SkillsSection };
