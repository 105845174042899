import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { AutocompleteFilterMembers } from '~/components/AutocompleteFilters';
import { Dropdown } from '~/components/Dropdown';
import { CalendarDropdown, DateOption } from '~/pages/Conversations/components/CalendarDropdown';

import { ROLES } from '~/constants';
import { getSelectedRole } from '~/selectors/baseGetters';

import type { IType } from './types';

const StyledAutocompleteMembers = styled(AutocompleteFilterMembers)`
  width: 140px;
  max-width: 140px;
`;

export interface IFilters {
  isShowFilters: boolean;
  search: string;
  setSearch: (value: string) => void;
  onChangeFilter: (key: string, value: any) => void;
  resetFilters: () => void;
  selectedStatus: string | null;
  selectedDateOption: DateOption;
  selectedCoaches: string;
  createdInRole: string;
}

const Filters = ({ filters }: { filters: IFilters }) => {
  const { i18n } = useLingui();
  const selectedRole = useSelector(getSelectedRole);

  const CreatedInRoleDropDownItems = [
    {
      id: ROLES.USER,
      key: ROLES.USER,
      name: i18n._(t`User`),
      value: ROLES.USER,
    },
    {
      id: ROLES.COACH,
      key: ROLES.COACH,
      name: i18n._(t`Coach`),
      value: ROLES.COACH,
    },
    {
      id: ROLES.ADMIN,
      key: ROLES.ADMIN,
      name: i18n._(t`Admin`),
      value: ROLES.ADMIN,
    },
  ];

  const statusesRoleDropDownItems = [
    {
      key: 'active',
      id: 'active',
      name: i18n._(t`Active`),
      value: 'active',
    },
    {
      key: 'done',
      id: 'done',
      name: i18n._(t`Archived`),
      value: 'done',
    },
  ];

  return (
    <>
      {selectedRole === ROLES.USER && (
        <StyledAutocompleteMembers
          checkedList={filters.selectedCoaches}
          onChange={(value: IType[]) => filters.onChangeFilter('selectedCoaches', value)}
        />
      )}
      <Dropdown
        placeholder={i18n._(t`Status`)}
        items={statusesRoleDropDownItems}
        selectedItem={statusesRoleDropDownItems.find((item) => item.id === filters.selectedStatus)}
        onChange={(selectedItem) => filters.onChangeFilter('selectedStatus', selectedItem?.id)}
        stringifyItem={(item: any) => `${i18n._(t`${item.name}`)}`}
        isSingleSelect
        isClearOption
      />
      <CalendarDropdown
        disabled={false}
        selectedItem={filters.selectedDateOption}
        // @ts-ignore
        setSelectedItem={(value: IType[]) => filters.onChangeFilter('selectedDateOption', value)}
        filterOptions={[2, 1, 5, 6, 9]}
      />
      <Dropdown
        placeholder={i18n._(t`Created in Role`)}
        items={CreatedInRoleDropDownItems}
        selectedItem={CreatedInRoleDropDownItems.find((item) => item.id === filters.createdInRole)}
        onChange={(selectedItem) => filters.onChangeFilter('createdInRole', selectedItem?.id)}
        stringifyItem={(item: any) => `${i18n._(t`${item.name}`)}`}
        isSingleSelect
        isClearOption
      />
    </>
  );
};

export { Filters };
