import React from 'react';

import { PATH_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui, withI18n } from '@lingui/react';
import styled from 'styled-components';

import { LearningItemType } from '~/components/LearningItemType';
import { Marker } from '~/components/Marker';
import { ActivityModal } from '~/components/Modals/ActivityModal';
import ProgressBarWide from '~/components/ProgressBarWide';
import { TableCol, TableRow } from '~/components/UI/TableCard';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE, COLOR_SET } from '~/styles';

const Name = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  color: ${(props) => (props.$isDraft ? COLOR_PALETTE.DARK_GRAY : COLOR_SET.TOTAL_BLACK)};
`;

const Progress = styled.div`
  align-items: center;
  display: flex;
  width: 160px;
`;

const ProgressValue = styled.span`
  color: ${COLOR_PALETTE.BLACK};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-left: 12px;
`;

const StyledTableCol = styled(TableCol)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const RenderPathItem = ({ path, superViewMode, userId }) => {
  const { i18n } = useLingui();
  const isDraft = path.status === PATH_STATUSES.DRAFT;

  const onItemClick = () => {
    if (isDraft) {
      routes.PATH_UPDATE.go({}, { isBackPath: true, pathId: path.id, query: { users: [userId] } });
    } else if (superViewMode) {
      routes.USER_PUBLIC_PATH.go({}, { isBackPath: true, pathId: path.id, userId });
    } else {
      routes.PATH.go({}, { pathId: path.id });
    }
  };

  const getProgress = () => {
    if (path && path.activities) {
      let progress = 0;
      path &&
        path.activities &&
        path.activities.forEach((activity) => {
          progress += activity.progress;
        });
      return Math.round(progress / path.activities.length);
    }
    return 0;
  };

  return (
    <TableRow onClick={onItemClick} pointer>
      <TableCol pointer>
        <Name $isDraft={isDraft}>{path.name && path.name.slice(0, 50)}</Name>
      </TableCol>
      <TableCol />
      <TableCol pointer>
        <LearningItemType isActivity={false} />
      </TableCol>
      <StyledTableCol pointer>
        {isDraft ? (
          <Progress>
            <Marker label={i18n._(t`draft`)} />
          </Progress>
        ) : (
          <Progress>
            <ProgressBarWide value={getProgress()} isActive height="8px" />
            <ProgressValue>{getProgress()}%</ProgressValue>
          </Progress>
        )}
      </StyledTableCol>
    </TableRow>
  );
};

const RenderActivityItem = ({ activity }) => {
  const $isShowViewActivityModal = useBoolState(false);

  return (
    <TableRow onClick={() => $isShowViewActivityModal.on()} pointer>
      <TableCol pointer>
        <Name>{activity.name && activity.name.slice(0, 50)}</Name>
      </TableCol>
      <TableCol />
      <TableCol pointer>
        <LearningItemType isActivity={true} type={activity.type} />
      </TableCol>
      <StyledTableCol pointer>
        <Progress>
          <ProgressBarWide value={activity.progress || 0} isActive height="8px" />
          <ProgressValue>{Math.round(activity.progress) || 0}%</ProgressValue>
        </Progress>
      </StyledTableCol>
      {$isShowViewActivityModal.value && (
        <ActivityModal
          activityId={activity.id}
          onClose={$isShowViewActivityModal.off}
          isUserActivity
        />
      )}
    </TableRow>
  );
};

const LearningItem = ({ item, superViewMode, userId }) => {
  if (item) {
    if (item.collection !== 'path') {
      return <RenderActivityItem activity={item} userId={userId} superViewMode={superViewMode} />;
    } else {
      return <RenderPathItem path={item} userId={userId} superViewMode={superViewMode} />;
    }
  }
  return null;
};

export default withI18n()(LearningItem);
