import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const PencilIcon = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst viewBox="0 0 24 24">
    <defs>
      <filter colorInterpolationFilters="auto" id="d27q2htkaa">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.117647 0 0 0 0 0.031373 0 0 0 0 0.388235 0 0 0 1.000000 0"
        />
      </filter>
      <filter colorInterpolationFilters="auto" id="ex7gtjlm0b">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.000000 0 0 0 0 0.368627 0 0 0 0 0.996078 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g transform="translate(-951 -430)" filter="url(#d27q2htkaa)" fill="none" fillRule="evenodd">
      <g transform="translate(951 430)" filter="url(#ex7gtjlm0b)">
        <g fill="#000" fillRule="nonzero">
          <path d="M14.166 6.517 6.21 14.472l3.314 3.315 7.955-7.955zM19.863 6.786 17.21 4.135a.482.482 0 0 0-.663 0l-1.72 1.72 3.315 3.314 1.72-1.72a.468.468 0 0 0 0-.663zM5.548 15.135l-.332.332a.47.47 0 0 0-.119.2l-1.079 3.731a.47.47 0 0 0 .581.58L8.33 18.9a.468.468 0 0 0 .201-.119l.332-.332-3.314-3.314z" />
        </g>
      </g>
    </g>
  </InlineSvg>
);

PencilIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

PencilIcon.defaultProps = {
  size: 24,
  className: '',
};

export default PencilIcon;
