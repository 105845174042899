import React, { useEffect, useRef, useState } from 'react';

import { NEXT_STEP_SORT_OPTIONS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import type { IColumnTable } from '~/@types/table';
import { TASKS_STATUSES, TASKS_TYPES } from '~/constants';
import routes from '~/constants/routes';
import { COLORS } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';

import type { ITaskWithId } from '@learned/types';
import type { I18n } from '@lingui/core';

const DateRow = styled.div<{ isGrayColor?: boolean }>`
  font-size: 14px;
  max-width: 140px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  height: 100%;
  box-sizing: border-box;
  padding-top: 15px;
  ${({ isGrayColor }) =>
    isGrayColor &&
    css`
      color: ${COLORS.SUBTEXT};
    `}
`;

const Name = styled.div<{
  isGrayColor?: boolean;
  isOverflow?: boolean;
}>`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  min-width: 733px;
  max-width: 733px;
  letter-spacing: -0.18px;
  white-space: normal;
  padding-right: 27px;
  border-width: 0px 1px 0px 0;
  border-style: solid;
  border-color: ${COLORS.BORDERS};
  word-wrap: break-word;
  text-decoration: ${({ isGrayColor }) => (isGrayColor ? 'line-through' : '')};
  ${({ isGrayColor }) =>
    isGrayColor &&
    css`
      color: ${COLORS.SUBTEXT};
    `}
  ${({ isOverflow }) =>
    css`
      margin-top: ${isOverflow ? 8 : 0}px;
    `}
`;

const SubText = styled.div<{
  isGrayColor?: boolean;
  isTitleOverflow?: boolean;
}>`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: -0.13px;
  ${({ isGrayColor }) =>
    isGrayColor &&
    css`
      color: ${COLORS.SUBTEXT};
    `}
  ${({ isTitleOverflow }) =>
    css`
      margin-bottom: ${isTitleOverflow ? 8 : 0}px;
    `}
`;

const Wrap = styled.div<{ isLeftMargin?: boolean }>`
  margin-left: ${(props) => (props.isLeftMargin ? '15px' : '0px')};
`;

const NameRow = styled.div<{ isCentered?: boolean; padding?: string }>`
  background-color: inherit;
  padding: ${({ padding }) => padding || 'unset'};
`;

const FlexRow = styled.div<{
  $isHidePointer?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${({ $isHidePointer }) => (!$isHidePointer ? 'pointer' : 'default')};
`;

const SubTextSpan = styled.span`
  &:hover {
    color: ${COLORS.COMPANY_HOVER};
  }
`;

const SpanWithSideMargin = styled.span`
  margin: 0px 10px 0px 10px;
`;

interface INameWithSubText {
  name: string;
  subText: { type: string; name: string };
  isCompleted: boolean;
  targetId: string;
  isLeftMargin?: boolean;
}

const NameWithSubText = ({
  name,
  subText,
  isCompleted,
  targetId,
  isLeftMargin = true,
}: INameWithSubText) => {
  const history = useHistory();

  const divRef = useRef<HTMLDivElement>(null);
  const [textHeight, setTextHeight] = useState(0);

  useEffect(() => {
    if (divRef.current) {
      const height = divRef.current.clientHeight;
      setTextHeight(height);
    }
  }, []);

  const redirectToTarget = (): void => {
    let url;
    if (subText.type === TASKS_TYPES.NEXT_STEP_CONVERSATION.key) {
      /* @ts-ignore */
      url = routes.CONVERSATION.build({}, { conversationId: targetId });
    } else {
      /* @ts-ignore */
      url = routes.REVIEW.build({}, { reviewId: targetId });
    }

    history.push(url);
  };

  return (
    <Wrap isLeftMargin={isLeftMargin}>
      <Name
        isGrayColor={isCompleted}
        onClick={redirectToTarget}
        ref={divRef}
        isOverflow={textHeight > 30} // when height is more than 30px set top padding
      >
        {name}
      </Name>

      <SubText isGrayColor={isCompleted} isTitleOverflow={textHeight > 30}>
        {`From ${
          TASKS_TYPES.NEXT_STEP_CONVERSATION.key === subText.type ? '1:1 Meeting' : 'conversation'
        }`}
        <SubTextSpan onClick={redirectToTarget}>
          <SpanWithSideMargin>{'>'}</SpanWithSideMargin>
          {subText.name}
        </SubTextSpan>
      </SubText>
    </Wrap>
  );
};

const NextStepNameRow = ({ step }: { step: ITaskWithId }) => {
  return (
    <NameRow>
      <FlexRow>
        <NameWithSubText
          name={step.target.name}
          isCompleted={TASKS_STATUSES.COMPLETED === step.taskStatus}
          isLeftMargin={false}
          subText={{ type: step.type, name: step.target.targetName! }}
          targetId={step.target.targetId}
        />
      </FlexRow>
    </NameRow>
  );
};

const COLUMNS: IColumnTable<ITaskWithId>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Next step`),
    accessor: 'name',
    minWidth: '700px',
    padding: '0px 0px 0px 24px',
    renderCell: (step) => {
      return <NextStepNameRow step={step} />;
    },
    sortBy: {
      asc: {
        key: NEXT_STEP_SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: NEXT_STEP_SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Date created`),
    accessor: 'created',
    minWidth: '149px',
    sortBy: {
      asc: {
        key: NEXT_STEP_SORT_OPTIONS.CREATED_NEW_OLD,
        label: (i18n: I18n) => i18n._(t`New - old`),
      },
      desc: {
        key: NEXT_STEP_SORT_OPTIONS.CREATED_OLD_NEW,
        label: (i18n: I18n) => i18n._(t`Old - new`),
      },
    },
    renderCell: (step: ITaskWithId) => {
      return (
        <DateRow isGrayColor={TASKS_STATUSES.COMPLETED === step.taskStatus ? true : false}>
          {convertToTimeString(step.createdDate)}
        </DateRow>
      );
    },
  },
];

export { COLUMNS };
