import React from 'react';

import moment from 'moment';
import styled from 'styled-components';

import { ICONS, Icon } from '~/components/Icon';

import { COLORS } from '~/styles';

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DateWrap = styled.div`
  width: 40px;
  min-width: 40px;
  height: 46px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDERS};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.SUBTEXT};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 9px;
  box-sizing: border-box;
`;

const Day = styled.div`
  font-size: 18px;
  height: 25px;
`;

const Month = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 3px;
  height: 17px;
`;

const DateBlock = ({
  isShowPlaceholder,
  date,
}: {
  isShowPlaceholder: boolean;
  date?: Date | null;
}) => {
  const day = date ? moment(date).format('DD') : null;
  const month = date ? moment(date).format('MMM') : null;

  return (
    <DateWrap>
      {isShowPlaceholder || !date ? (
        // @ts-ignore
        <Icon icon={ICONS.CALENDAR} size={14} color={COLORS.INACTIVE} />
      ) : (
        <Col>
          <Month>{month}</Month>
          <Day>{day}</Day>
        </Col>
      )}
    </DateWrap>
  );
};

export { DateBlock };
