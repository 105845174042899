import { t } from '@lingui/macro';

import { SKILL_SOURCE } from '~/constants/skills';
import getSkillName from '~/utils/getSkillName';

export { getSkillName };

export const getSkillSource = (skillSetType) => {
  switch (skillSetType) {
    case 'companySkills':
      return { source: SKILL_SOURCE.company };
    case 'learnedSoftSkills':
      return { source: SKILL_SOURCE.learned };
    case 'learned2SoftSkills':
      return { source: SKILL_SOURCE.learned2 };
  }
};

export const getSkillSetName = (skillSetType, company, i18n) => {
  switch (skillSetType) {
    case 'companySkills':
      return company.name;
    case 'learnedSoftSkills':
      return i18n._(t`learned skills`);
    case 'learned2SoftSkills':
      return i18n._(t`Learned detailed soft skills`);
    case 'learnedPremiumSkills':
      return i18n._(t`Learned full skills database`);
  }
};

export function getSkillDefinition(skill, lang) {
  return lang === 'nl' && skill?.definitionNL ? skill?.definitionNL : skill?.definition;
}
