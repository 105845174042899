import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getCompanySkillLabels(companyId) {
  return cloudRequest(serverRoutes.companySettings.getCompanySkillLabels(companyId));
}

export function getCompanySkillCategories(companyId) {
  return cloudRequest(serverRoutes.companySettings.getCompanySkillCategories(companyId));
}

export function getCompanyUserNotifications(companyId) {
  return cloudRequest(serverRoutes.companySettings.getCompanyUserNotifications(companyId));
}

export function updateCompanyUserNotifications(companyId, notificationSettings) {
  return cloudRequest(
    serverRoutes.companySettings.updateCompanyUserNotifications(companyId),
    null,
    {
      notificationSettings,
    },
  );
}

export function updateCompanySkillLabels(companyId, skillLabels) {
  return cloudRequest(serverRoutes.companySettings.updateCompanySkillLabels(companyId), null, {
    skillLabels,
  });
}

export function updateCompanySkillCategories(companyId, skillCategories) {
  return cloudRequest(serverRoutes.companySettings.updateCompanySkillCategories(companyId), null, {
    skillCategories,
  });
}

export function getCompanyDefaultFocusAreas(companyId) {
  return cloudRequest(serverRoutes.companySettings.getCompanyDefaultFocusAreas(companyId));
}

export function updateCompanyDefaultFocusAreas(companyId, defaultFocusAreas) {
  return cloudRequest(
    serverRoutes.companySettings.updateCompanyDefaultFocusAreas(companyId),
    null,
    {
      defaultFocusAreas,
    },
  );
}

// GoalUpdates
export function getCompanyGoalUpdates(companyId, { forceRole } = {}) {
  return cloudRequest(serverRoutes.companySettings.getCompanyGoalUpdates(companyId), null, null, {
    ...(forceRole && { forceRole }),
  });
}

export function updateCompanyGoalUpdates(companyId, goalUpdates, { forceRole } = {}) {
  return cloudRequest(
    serverRoutes.companySettings.updateCompanyGoalUpdates(companyId),
    null,
    {
      goalUpdates,
    },
    { ...(forceRole && { forceRole }) },
  );
}

// feedback cycle
export function getCompanyFeedbackCycle(companyId) {
  return cloudRequest(serverRoutes.companySettings.getCompanyFeedbackCycle(companyId));
}

export function updateCompanyFeedbackCycle(companyId, update, type) {
  return cloudRequest(serverRoutes.companySettings.updateCompanyFeedbackCycle(companyId), null, {
    update,
    type,
  });
}

export function updateRoleRelevancies(companyId, roleRelevancies) {
  return cloudRequest(serverRoutes.companySettings.updateRoleRelevancies(companyId), null, {
    roleRelevancies,
  });
}

export function getCompanySettings() {
  return cloudRequest(
    serverRoutes.companySettings.getCompanySettings(),
    {
      settings: [
        'skillLabels',
        'focusAreas',
        'skillCategories',
        'goalUpdates',
        'feedbackCycle',
        'roleRelevancies',
        'notifications',
        'coaches',
      ],
    },
    {},
  );
}

export function updateCompanySettings(update) {
  return cloudRequest(serverRoutes.companySettings.updateCompanySettings(), null, { update });
}
