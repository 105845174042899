import React, { useEffect, useState, useCallback } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { ITask, ITaskWithId, TASKS_STATUSES } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import { TableList } from '~/components/TableList';

import { COLUMNS } from './taskColumns';

import { TASKS_FILTER_TYPES } from '~/constants';
import useDebounce from '~/hooks/useDebounce';
import {
  checkModuleRTFeedbacks,
  checkModuleReviews,
  checkModuleSurvey,
} from '~/selectors/baseGetters';
import { getTasks } from '~/services/tasks';
import { COLORS } from '~/styles';
import getTaskURL from '~/utils/getTaskURL';

import SectionWrapper from '../SectionWrapper';

const StatusDropDown = styled(Dropdown)`
  position: absolute;
  width: 150px;
  height: 32px;
  right: 30px;
`;

const TableWrapper = styled.div`
  tr {
    height: 90px;
    background-color: ${COLORS.BG_LIST};
    margin-bottom: 5px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  color: ${COLORS.BLACK};
  margin-left: 32px;
  margin-top: 21px;
`;

const StyledTableList = styled(TableList)`
  padding: 0px 15px 15px 15px;
`;

type IFilter = {
  search: string;
  status: string;
  pagination: { skip: number; limit: number; index: number };
};

type StatusDropDownItem = {
  name: string;
  value: string;
};

const PAGE_SIZE = 5;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };

const initialFilters: IFilter = {
  search: '',
  status: TASKS_STATUSES.ACTIVE,
  pagination: DEFAULT_PAGINATION,
};

const statusDropDownItems: Array<StatusDropDownItem> = [
  { name: 'Open tasks', value: TASKS_STATUSES.ACTIVE },
  { name: 'Completed tasks', value: TASKS_STATUSES.COMPLETED },
  { name: 'All tasks', value: '' },
];

const TasksSection = () => {
  const { search: searchFromQuery }: { search?: string } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const isModuleRTFeedbacksEnabled = useSelector(checkModuleRTFeedbacks);
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const [currentFilters, setCurrentFilters] = useState<IFilter>({
    ...initialFilters,
    search: searchFromQuery || initialFilters.search,
  });
  const [selectedStatus, setSelectedStatus] = useState(
    statusDropDownItems.find((item) => item.value === TASKS_STATUSES.ACTIVE),
  );
  const [totalTasksCount, setTotalTaskCount] = useState<number>(0);
  const [allTasks, setAllTasks] = useState<Array<ITaskWithId>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { i18n } = useLingui();
  const history = useHistory();

  const debCurrentFilters = useDebounce(currentFilters, 500);

  const transfromData = (steps: Array<ITask> = []): Array<ITaskWithId> => {
    return steps.map((step, index) => ({ ...step, id: String(index) }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getTasks({
        // @ts-ignore
        filterTaskTypes: [TASKS_FILTER_TYPES.TASKS.key],
        taskStatus: currentFilters.status,
        search: currentFilters.search,
        limit: currentFilters.pagination.limit,
        skip: currentFilters.pagination.skip,
      });
      const fetchedTasks = transfromData(data.tasks || []);
      setAllTasks(fetchedTasks);
      setTotalTaskCount(data.totals[API_RETURN_FIELDS.TOTAL_FILTERED_TASKS]);
      setIsLoading(false);
    };
    if (isModuleSurveyEnabled || isModuleRTFeedbacksEnabled || isModuleReviewsEnabled) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debCurrentFilters]);

  const filters = {
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value, pagination: DEFAULT_PAGINATION });
    },
  };

  const onSelectStatusesFilter = (selectedItem: any) => {
    setSelectedStatus(selectedItem);
    setCurrentFilters({
      ...currentFilters,
      status: selectedItem.value,
      pagination: DEFAULT_PAGINATION,
    });
  };

  const onResetFilters = (): void => {
    setCurrentFilters(initialFilters);
    setSelectedStatus(statusDropDownItems.find((item) => item.value === TASKS_STATUSES.ACTIVE));
  };

  const navigateToTask = useCallback(
    (task) => {
      const path = getTaskURL(task);
      if (!isEmpty(task)) {
        history.push(path!);
      }
    },
    [history],
  );

  return (
    <SectionWrapper title={i18n._(t`Tasks`)}>
      <Title>{i18n._(t`Tasks`)}</Title>
      <TableWrapper>
        <StyledTableList
          columns={COLUMNS}
          data={allTasks}
          onRowClick={navigateToTask}
          isLoading={isLoading}
          filtersProps={{
            filters,
            resetFilters: onResetFilters,
            isFiltered:
              !!currentFilters.search ||
              (!!currentFilters.status && currentFilters.status !== TASKS_STATUSES.ACTIVE),
            filterComponents: (
              <>
                <StatusDropDown
                  placeholder={i18n._(t`Status`)}
                  items={statusDropDownItems}
                  selectedItem={selectedStatus}
                  onChange={onSelectStatusesFilter}
                  stringifyItem={(item: any) => `${i18n._(t`${item.name}`)}`}
                  isSingleSelect
                />
              </>
            ),
          }}
          paginationProps={{
            pagination: currentFilters.pagination,
            changePagination: ({ skip, limit, index }) =>
              setCurrentFilters({
                ...currentFilters,
                pagination: { ...currentFilters.pagination, skip, limit, index },
              }),
            totalCount: totalTasksCount,
            paginationItemLabel: i18n._(t`tasks`),
          }}
          isHideHeader
          placeholderProps={{
            emptyStateText: 'No open tasks found!',
          }}
        />
      </TableWrapper>
    </SectionWrapper>
  );
};

export { TasksSection };
