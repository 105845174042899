import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import Editor from '~/components/Editor';
import ImageUploadCover from '~/components/ImageUploadCover';
import Modal from '~/components/Modal';
import Select from '~/components/Select';

import { WORK_EXPERIENCE_LEVELS, EDUCATION_LEVELS } from '~/constants';
import { createJobProfiles, updateJobProfile } from '~/services/jobProfiles';
import { setJobProfile } from '~/store/jobProfiles/actions';
import disableActionOnLoading from '~/utils/disableActionOnLoading';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  box-sizing: border-box;
  padding-right: 46px;
`;

const ImageUpload = styled(ImageUploadCover)`
  border-radius: 6px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  color: #3b3b3b;
  margin-top: ${(props) => (props.isFirst ? '32px' : '40px')};
  margin-bottom: 8px;
`;

const Row = styled.div`
  display: flex;
`;

const INITIAL_STATE = {
  loading: false,
  jobProfileName: '',
  jobProfileDescription: '',
  jobProfileWorkLevel: '',
  jobProfileEducationLevel: '',
  coverImage: '',
};

class NewJobProfileModal extends Component {
  constructor() {
    super();
    this.state = {
      ...INITIAL_STATE,
    };
  }

  static propTypes = {
    company: PropTypes.object,
    jobProfile: PropTypes.object,
    onModalClose: PropTypes.func,
    afterSubmit: PropTypes.func,
  };

  static defaultProps = {
    afterSubmit: () => {},
  };

  componentDidMount = () => {
    const { jobProfile } = this.props;
    if (jobProfile) {
      this.setState({
        jobProfileName: jobProfile.name,
        jobProfileDescription: jobProfile.description,
        jobProfileWorkLevel: jobProfile.careerLevel,
        jobProfileEducationLevel: jobProfile.educationLevel,
        coverImage: jobProfile.coverImage,
      });
    }
  };

  createOrUpdateJobProfile = async () => {
    const { loading, company, jobProfile, dispatch } = this.props;
    const {
      jobProfileName,
      jobProfileDescription,
      jobProfileWorkLevel,
      jobProfileEducationLevel,
      coverImage,
    } = this.state;

    if (disableActionOnLoading(loading)) {
      return;
    }

    this.setState({ loading: true });
    let newJobProfile = {
      name: jobProfileName,
      description: jobProfileDescription,
      careerLevel: jobProfileWorkLevel,
      educationLevel: jobProfileEducationLevel,
      coverImage,
    };

    if (jobProfile) {
      newJobProfile = {
        ...jobProfile,
        ...newJobProfile,
      };
      await updateJobProfile(newJobProfile.id, newJobProfile);
      this.setState({ loading: false });
      await dispatch(setJobProfile(newJobProfile));
      await this.props.afterSubmit();
      return this.closeModal();
    } else {
      newJobProfile = {
        ...newJobProfile,
        company: company.id ? company.id : company,
      };
      const jobProfileNew = await createJobProfiles(newJobProfile);
      this.setState({ loading: false });
      await dispatch(setJobProfile(jobProfileNew));
      return this.closeModal();
    }
  };

  onChangeJobProfileName = (e) => {
    e.preventDefault();
    this.setState({
      jobProfileName: e.target.value,
    });
  };

  onChangeJobProfileDescription = (value) => {
    this.setState({
      jobProfileDescription: value,
    });
  };

  onChangeEducationLevel = (level) => {
    const newLevel = level ? Number(level) : '';
    this.setState({
      jobProfileEducationLevel: newLevel,
    });
  };

  onChangeWorkLevel = (level) => {
    const newLevel = level ? Number(level) : '';
    this.setState({
      jobProfileWorkLevel: newLevel,
    });
  };

  onChangeCover = (url) => {
    this.setState({
      coverImage: url,
      uploading: false,
    });
  };

  onUploadStart = () => {
    this.setState({
      uploading: true,
    });
  };

  onUploadError = () => {
    this.setState({
      uploading: false,
    });
  };

  closeModal = () => {
    this.props.onModalClose();
  };

  render() {
    const { i18n, jobProfile } = this.props;
    const {
      jobProfileName,
      jobProfileDescription,
      jobProfileWorkLevel,
      jobProfileEducationLevel,
      coverImage,
      uploading,
    } = this.state;

    return (
      <Modal
        className="new-team-modal"
        title={jobProfile ? i18n._(t`Update the job profile`) : i18n._(t`Create a job profile`)}
        onClose={this.closeModal}
        width={625}
        minWidth={625}
        minHeight="536px"
        contentStyles={{ background: '#fff', height: '100%', padding: '0 16px 48px' }}
        footerRight={
          <Button
            type="primary-border"
            label={i18n._(t`Save`)}
            onClick={this.createOrUpdateJobProfile}
            disabled={
              !jobProfileName ||
              !jobProfileDescription ||
              !jobProfileWorkLevel ||
              !jobProfileEducationLevel ||
              uploading
            }
            loading={this.state.loading}
          />
        }
      >
        <div>
          <Label isFirst>
            <Trans>Job*</Trans>
          </Label>
          <textarea
            className="textarea"
            value={jobProfileName}
            onChange={this.onChangeJobProfileName}
            placeholder={i18n._(t`Example: Jr. consultant`)}
            rows={3}
          />
        </div>
        <div>
          <Label>
            <Trans>Job description</Trans>*
          </Label>
          <Editor
            value={jobProfileDescription}
            onChange={this.onChangeJobProfileDescription}
            placeholder={i18n._(
              t`Example: What are the main responsibilities for this job? (the required skills you can add later).`,
            )}
          />
        </div>
        <Row>
          <Column>
            <Label>
              <Trans>Workexperience</Trans>*
            </Label>
            <Select
              value={jobProfileWorkLevel}
              emptyOption={isEmpty(jobProfileWorkLevel)}
              options={WORK_EXPERIENCE_LEVELS}
              onChange={this.onChangeWorkLevel}
            />
          </Column>
          <Column>
            <Label>
              <Trans>Educational level</Trans>*
            </Label>
            <Select
              value={jobProfileEducationLevel}
              emptyOption={isEmpty(jobProfileEducationLevel)}
              options={filter(EDUCATION_LEVELS, (l) => l)}
              onChange={this.onChangeEducationLevel}
            />
          </Column>
        </Row>
        <div>
          <Label>
            <Trans>Header image</Trans>
          </Label>
          <ImageUpload
            height="145px"
            imageUrl={coverImage}
            onUpload={this.onChangeCover}
            onUploadStart={this.onUploadStart}
            onUploadError={this.onUploadError}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.users.data,
  };
};

export default connect(mapStateToProps)(withI18n()(withRouter(NewJobProfileModal)));
