import React, { useState, useEffect, useMemo } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch } from 'react-redux';

import { Icon, ICONS, ICON_SIZES } from '~/components/Icon';

import {
  ArrowContainer,
  Arrow,
  ArrowSingle,
  PageNumber,
  ItemCount,
  BarContainer,
  NumbersDropdown,
} from './design';

import { updateNumberOfItemsPerPage } from '~/store/pagination/actions';
import { COLORS } from '~/styles';

import type { IPaginationBarProps, DropdownItem } from './types';

const calculateTotalPages = (count: number, limit: number): number => {
  return limit > 0 ? Math.ceil(count / limit) : 0;
};

const PaginationBar = ({
  pagination,
  changePagination,
  changePageSize,
  count = 0,
  noBorder,
  noTopBorder,
  noShadow,
  showCount = false,
  itemLabel,
  className,
  paginationIndexAlternatives,
}: IPaginationBarProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const itemsPerPage = pagination.limit;
  const pageIndex = pagination.index;
  const totalPages = calculateTotalPages(count, itemsPerPage);

  const [loading, setLoading] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState<DropdownItem>({
    id: itemsPerPage,
    label: itemsPerPage + ` ${i18n._(t`per page`)}`,
  });

  const paginationNumbers = useMemo(
    () => [
      { id: 10, label: i18n._(t`10 per page`) },
      { id: 25, label: i18n._(t`25 per page`) },
      { id: 50, label: i18n._(t`50 per page`) },
      { id: 100, label: i18n._(t`100 per page`) },
    ],
    [i18n],
  );

  useEffect(() => {
    if (numberOfItems && itemsPerPage !== numberOfItems.id) {
      const selectedNumberOfItems = paginationNumbers.find((p) => p.id === itemsPerPage);
      selectedNumberOfItems && setNumberOfItems(selectedNumberOfItems);
    }
  }, [itemsPerPage, paginationNumbers, numberOfItems]);

  const onNumberOfItems = (item: DropdownItem) => {
    changePageSize?.({ limit: item.id });
    dispatch(updateNumberOfItemsPerPage(item.id));
  };

  const onNextClick = async (index: number) => {
    if (index < totalPages && !loading) {
      setLoading(true);

      await changePagination({
        skip: index * itemsPerPage,
        limit: itemsPerPage,
        index: index + 1,
      });

      setLoading(false);
    }
  };

  const onPreviousClick = async (index: number) => {
    if (index > 1 && !loading) {
      setLoading(true);

      await changePagination({
        skip: (index - 2) * itemsPerPage,
        limit: itemsPerPage,
        index: index - 1,
      });

      setLoading(false);
    }
  };

  return (
    <>
      {/* Show pagination block, only if amount of items more that perPage minimum amount */}
      {count > paginationNumbers[0].id || count > itemsPerPage ? (
        <BarContainer
          className={className}
          $noTopBorder={noTopBorder}
          $noBorder={noBorder}
          $noShadow={noShadow}
        >
          {showCount ? (
            <ItemCount>
              {count} {itemLabel ?? <Trans>items</Trans>}
            </ItemCount>
          ) : null}
          {count > itemsPerPage ? (
            <ArrowContainer showCount={showCount && !(!!count && changePageSize)}>
              <Arrow $isClickable={pageIndex > 1} onClick={() => onPreviousClick(2)}>
                <Icon
                  icon={ICONS.ARROW_DOUBLE_LEFT}
                  color={pageIndex > 1 && !loading ? COLORS.TEXT_MAIN : COLORS.BORDERS}
                  size={ICON_SIZES.SMALL}
                  // @ts-ignore
                  alt={i18n._(t`First`)}
                />
              </Arrow>
              <ArrowSingle $isClickable={pageIndex > 1} onClick={() => onPreviousClick(pageIndex)}>
                <Icon
                  icon={ICONS.BACK}
                  color={pageIndex > 1 && !loading ? COLORS.TEXT_MAIN : COLORS.BORDERS}
                  size={ICON_SIZES.SMALL}
                  // @ts-ignore
                  alt={i18n._(t`Prev`)}
                />
              </ArrowSingle>
              <PageNumber>
                <Trans>{`${pageIndex} of ${totalPages}`}</Trans>
              </PageNumber>
              <ArrowSingle
                $isClickable={pageIndex < totalPages}
                onClick={() => onNextClick(pageIndex)}
              >
                <Icon
                  icon={ICONS.NEXT}
                  color={pageIndex < totalPages && !loading ? COLORS.TEXT_MAIN : COLORS.BORDERS}
                  size={ICON_SIZES.SMALL}
                  // @ts-ignore
                  alt={i18n._(t`Next`)}
                />
              </ArrowSingle>
              <Arrow
                $isClickable={pageIndex < totalPages}
                onClick={() => onNextClick(totalPages - 1)}
              >
                <Icon
                  icon={ICONS.ARROW_DOUBLE_RIGHT}
                  color={pageIndex > 1 && !loading ? COLORS.TEXT_MAIN : COLORS.BORDERS}
                  size={ICON_SIZES.SMALL}
                  // @ts-ignore
                  alt={i18n._(t`Last`)}
                />
              </Arrow>
            </ArrowContainer>
          ) : null}
          {!!count && changePageSize && (
            <NumbersDropdown
              items={paginationIndexAlternatives || paginationNumbers}
              placeholder={i18n._(t`${itemsPerPage} per page`)}
              onChange={(item) => onNumberOfItems(item as DropdownItem)}
              selectedItem={numberOfItems}
              stringifyItem={(item) => (item as DropdownItem).label.toString()}
              hashItem={(item) => (item as DropdownItem).id.toString()}
              skipSort
              isSingleSelect
            />
          )}
        </BarContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default PaginationBar;
