import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { ICONS } from '~/components/Icon';

import { EOptions, TDropDownValues } from '../types';

const ReloadBtn = styled.div`
  cursor: pointer;
`;

type TProps = {
  optionChangeHandler: (type: EOptions, val?: TDropDownValues) => void;
};

function ReFetchBtn({ optionChangeHandler }: TProps) {
  const { i18n } = useLingui();
  return (
    <ReloadBtn>
      <Button
        icon={ICONS.REFRESH}
        label={`${i18n._(t`Try Again`)}`}
        variant={ButtonVariant.PRIMARY}
        onClick={() => {
          optionChangeHandler(EOptions.fetchDataAgain);
        }}
        size={ButtonSize.MEDIUM}
      />
    </ReloadBtn>
  );
}

export default ReFetchBtn;
