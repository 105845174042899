import React, { useMemo } from 'react';
import type { MouseEventHandler } from 'react';

import { SURVEY_QUESTION_TYPES } from '@learned/constants';
import { Trans } from '@lingui/macro';
import styled, { css } from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import type { IQuestion } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type { ISurveyQuestion } from '@learned/types';
import type { IMultiLangString } from '@learned/types/src/multiLangString';

interface IQuestionProps {
  name: IQuestion['name'] | ISurveyQuestion['name'];
  type: IQuestion['type'];
  index: number;
  onClickTitle?: MouseEventHandler<HTMLDivElement>;
  onClickCard?: MouseEventHandler<HTMLDivElement>;
  languageState: ILanguageStateReturn;
  isActive?: boolean;
  className?: string;
  themeName?: IMultiLangString;
  children?: React.ReactNode;
  isPreselectedLang?: boolean;
  hideIndex?: boolean;
}

const Wrapper = styled.div<{ isActive?: boolean }>`
  width: 100%;
  padding: 16px 25px 16px 22px;
  border: 1px solid ${COLORS.BORDER_HARD};
  border-radius: 10px;

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: ${COLORS.HOVER};
          box-shadow: 0 4px 4px 0 rgba(30, 8, 99, 0.21);
        `
      : css`
          background-color: ${COLORS.WHITE};
        `};

  &:hover {
    background-color: ${COLORS.HOVER};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: end;
  font-size: 12px;
  color: ${COLORS.DARK_GRAY};
  align-items: center;
`;

const Title = styled.div`
  font-size: 14px;
  color: ${COLORS.BLACK};
  margin-bottom: 26px;
  cursor: pointer;
`;

const Main = styled.div`
  display: flex;
  gap: 40px;
  color: ${COLORS.BLACK};

  & .index {
    font-size: 14px;
  }
`;

const Separator = styled.span`
  padding-left: 15px;
  padding-right: 15px;
`;

const PreviewQuestionCard = ({
  name,
  type,
  index,
  onClickTitle,
  onClickCard,
  languageState,
  isActive,
  className,
  themeName,
  children,
  isPreselectedLang = false,
  hideIndex = false,
}: IQuestionProps) => {
  const getMultiLangString = useMultiLangString();
  const ANSWER_TYPES = {
    [SURVEY_QUESTION_TYPES.TEXT]: {
      key: SURVEY_QUESTION_TYPES.TEXT,
      icon: <Icon icon={ICONS.TEXT_ANSWER} size={ICON_SIZES.LARGE} />,
    },
    [SURVEY_QUESTION_TYPES.SINGLE]: {
      key: SURVEY_QUESTION_TYPES.SINGLE,
      icon: <Icon icon={ICONS.SINGLE_SELECT} size={ICON_SIZES.LARGE} />,
    },
    [SURVEY_QUESTION_TYPES.MULTIPLE]: {
      key: SURVEY_QUESTION_TYPES.MULTIPLE,
      icon: <Icon icon={ICONS.MULTI_SELECT} size={ICON_SIZES.LARGE} />,
    },
    [SURVEY_QUESTION_TYPES.TRAFFIC]: {
      key: SURVEY_QUESTION_TYPES.TRAFFIC,
      icon: <Icon icon={ICONS.TRAFFIC} size={ICON_SIZES.LARGE} />,
    },
    [SURVEY_QUESTION_TYPES.STARS]: {
      key: SURVEY_QUESTION_TYPES.STARS,
      icon: <Icon icon={ICONS.STAR} size={ICON_SIZES.LARGE} />,
    },
    [SURVEY_QUESTION_TYPES.SMILEYS]: {
      key: SURVEY_QUESTION_TYPES.SMILEYS,
      icon: <Icon icon={ICONS.SMILEY} size={ICON_SIZES.LARGE} />,
    },
  };

  const { companyPrimaryLanguage, languages } = languageState;

  const locale = isPreselectedLang ? languages[0].locale : companyPrimaryLanguage.locale;

  const getTranslatedThemeName = (name: IMultiLangString) => {
    return name[locale] || <Trans>Empty</Trans>;
  };

  const questionName = useMemo(() => {
    return Array.isArray(name)
      ? name.find((t) => locale === t.locale)?.value
      : name[locale] || <Trans>Empty</Trans>;
  }, [locale, name]);

  const translatedThemeName = themeName
    ? isPreselectedLang
      ? getTranslatedThemeName(themeName)
      : getMultiLangString(themeName)
    : null;

  return (
    <Wrapper onClick={onClickCard} className={className} isActive={isActive}>
      <Header>
        {themeName && (
          <>
            <span>{translatedThemeName}</span>
            <Separator>|</Separator>
          </>
        )}
        {/* @ts-ignore */}
        <span className="icon">{ANSWER_TYPES[type].icon}</span>
      </Header>
      <Main>
        {!hideIndex && <div className="index">{++index}</div>}
        <Title onClick={onClickTitle}>{questionName}</Title>
      </Main>
      {children}
    </Wrapper>
  );
};

export { PreviewQuestionCard };
