import React, { useEffect, useState } from 'react';

import { INTEGRATIONS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import LearnMore from '~/components/LearnMore';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Divider from '~/components/UI/Divider';

import ExternalRole from './externalRole';

import RolesIcon from '~/assets/mdi-ballot.svg';

import { INTEGRATIONS_CONN_ERROR_MSG } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import useBoolState from '~/hooks/useBoolState';
import { getJobProfilesFromIntegration } from '~/services/integrations';
import { createJobProfiles } from '~/services/jobProfiles';
import { setJobProfiles } from '~/store/jobProfiles/actions';
import { COLOR_PALETTE, COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

import ShowSpinnerIfLoading from '../ShowSpinnerIfLoading';

const Wrapper = styled.div`
  margin-top: 24px;
`;

const PlaceholderWrap = styled.div`
  margin: 25px auto 100px auto;
`;

const RolesListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SelectedAllCheckMark = styled(CheckBox)`
  align-self: center;
  padding: 8px;
`;

const SelectAllLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-transform: uppercase;
`;

const RolesList = styled.div`
  height: 350px;
  overflow: auto;
`;

const ButtonSection = styled.div`
  display: flex;
  padding: 8px 0px 16px 18px;
  justify-content: flex-end;
`;

const messages = {
  rolesImported: {
    title: t`Success`,
    content: t`The selected roles have been imported`,
  },
};

function RolesIntegrationTab({ i18n, onModalClose, search, integration, jobProfiles }) {
  const $loading = useBoolState(true);
  const $isSelectAll = useBoolState(false);
  const [rolesExternal, setRolesExternal] = useState([]);
  const [selectedExternalRoles, setSelectedExternalRoles] = useState([]);
  const [isIntegrationError, setIsIntegrationError] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    if (!$loading.value) {
      $loading.on();
    }

    const getData = async () => {
      let rolesExternal;
      try {
        rolesExternal = await getJobProfilesFromIntegration(integration.id, true);
      } catch (e) {
        // Do nothing
      }

      // If it is undefined then the fetching of integration data failed
      if (!rolesExternal) {
        setIsIntegrationError(true);
        setRolesExternal([]);
        $loading.off();
        return;
      }

      // sort roles by name, ascending
      rolesExternal.sort((a, b) => (b.name > a.name ? -1 : 1));

      // Create field in each role to know if its name already exists in local teams
      const roleIds = Object.values(jobProfiles)
        .filter((jp) => jp.externalId)
        .map((jp) => `${jp.name}_${jp.externalId}`);

      rolesExternal.forEach(
        (roleExt) =>
          (roleExt.duplicatedName = roleIds.includes(roleExt.name + '_' + roleExt.externalId)),
      );

      setRolesExternal(rolesExternal);
      $loading.off();
    };
    getData();

    // eslint-disable-next-line
  }, [integration]);

  const toggleSelectAll = (isChecked) => {
    if (isChecked) {
      // select all
      $isSelectAll.on();
      setSelectedExternalRoles([...rolesExternal.filter((role) => !role.duplicatedName)]);
    } else {
      // deselect all
      $isSelectAll.off();
      setSelectedExternalRoles([]);
    }
  };

  const toggleExternalRole = (externalRole) => {
    const selectedId = externalRole.id;
    let newSelectedList = [...selectedExternalRoles];
    if (newSelectedList.includes(externalRole)) {
      // remove from selected list
      newSelectedList = newSelectedList.filter((t) => t.id !== selectedId);
    } else {
      // add to selected list
      newSelectedList.push(externalRole);
    }

    // affect on isSelectAll checkbox
    const rolesExternalLength = rolesExternal.filter((role) => !role.duplicatedName).length;
    if (isEmpty(newSelectedList) || newSelectedList.length !== rolesExternalLength) {
      // deselect all
      $isSelectAll.off();
    } else if (newSelectedList.length === rolesExternalLength) {
      // select all
      $isSelectAll.on();
    }
    setSelectedExternalRoles(newSelectedList);
  };

  const createRolesFromExternal = async () => {
    if (!isEmpty(selectedExternalRoles)) {
      $loading.on();
      const newJobProfiles = selectedExternalRoles.map((externalRole) => ({
        name: externalRole.name,
        description: '',
        externalId: String(externalRole.id),
        externalSource: integration.id,
      }));

      const createdJobProfiles = await createJobProfiles(newJobProfiles);

      // fast update redux-store
      if (!isEmpty(createdJobProfiles)) {
        const updateJobProfiles = {
          ...jobProfiles,
        };

        createdJobProfiles.forEach((createdJP) => (updateJobProfiles[createdJP.id] = createdJP));
        dispatch(setJobProfiles(updateJobProfiles));
      }

      onModalClose();
      $loading.off();
      showToastMessage(messages.rolesImported, TOAST_TYPES.INFO);
    }
  };

  // search logic
  let rolesExternalFiltered = [...rolesExternal];
  if (search) {
    rolesExternalFiltered = rolesExternal.filter((item) =>
      (item.name || '').toLowerCase().includes(search.toLowerCase()),
    );
  }

  const showToastMessage = (msg, type) => {
    addToast({
      title: i18n._(msg.title),
      subtitle: i18n._(msg.content),
      type,
    });
  };

  return (
    <Wrapper>
      <LearnMore
        label={i18n._(t`Manually created roles cannot be synchronised or delete via an API.`)}
        LearnMoreLink={getInstructionUrl(INSTRUCTIONS.LEARNED_API_INTEGRATIONS)}
      />
      <ShowSpinnerIfLoading loading={$loading.value}>
        {isEmpty(rolesExternalFiltered) ? (
          <PlaceholderWrap>
            <Placeholder
              title={i18n._(t`No roles available`)}
              subTitle={i18n._(
                isIntegrationError
                  ? INTEGRATIONS_CONN_ERROR_MSG
                  : t`It seems that all roles in your HR system have been added to Learned.`,
              )}
              subTitleStyles={{ ...(isIntegrationError && { color: COLORS.ACCENT_ERROR }) }}
              Icon={() => (
                <SvgIcon
                  url={RolesIcon}
                  width="50px"
                  height="50px"
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
            />
          </PlaceholderWrap>
        ) : (
          <>
            <RolesListHeader>
              <SelectAllWrapper onClick={() => toggleSelectAll(!$isSelectAll.value)}>
                <SelectedAllCheckMark checked={$isSelectAll.value} size={24} />
                <SelectAllLabel>
                  <Trans>select all</Trans>
                </SelectAllLabel>
              </SelectAllWrapper>
            </RolesListHeader>
            <Divider depth={1} />
            <RolesList>
              {rolesExternalFiltered.map((externalRole) => (
                <ExternalRole
                  disabled={externalRole.duplicatedName}
                  key={externalRole.id}
                  externalRole={externalRole}
                  isSelected={selectedExternalRoles.includes(externalRole)}
                  onToggle={() => toggleExternalRole(externalRole)}
                  isShowExternalId={integration.externalSoftware !== INTEGRATIONS.KOMBO}
                />
              ))}
            </RolesList>
          </>
        )}
        <ButtonSection>
          <Button
            label={
              selectedExternalRoles.length > 1
                ? i18n._(t`Import ${selectedExternalRoles.length} roles`)
                : i18n._(t`Import`)
            }
            disabled={isEmpty(selectedExternalRoles)}
            onClick={createRolesFromExternal}
            loading={$loading.value}
            type="primary"
          />
        </ButtonSection>
      </ShowSpinnerIfLoading>
    </Wrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    jobProfiles: state.jobProfiles.data,
  };
};

export default withI18n()(connect(mapStateToProps)(RolesIntegrationTab));
