import isEmpty from 'lodash/isEmpty';

export default function (user, jobProfile) {
  // If the user has no skills, we always won't match the job profile
  if (!user || !user.skills || !user.skillsLevels) {
    return 0.0;
  }
  // If the jobprofile has no skills required, we always match it 100%
  if (!jobProfile || isEmpty(jobProfile.skills)) {
    return 100.0;
  }

  // Compute total of skill match scores and divide by number of skills for average.
  let skillMatch = 0.0;
  jobProfile.skills.forEach((skill) => {
    const skillId = skill.id ? skill.id : skill;
    // Compute how much the users' skill matches the jobprofile
    // Ratio of level that user has and level that jobProfile requires.
    // For example, user has level 2, jobprofile requires level 4: match = 50%
    // Or user has level 5, jobprofile requires level 2: match = 100%
    if ((user.skills || []).includes(skillId) && user.skillsLevels[skillId]) {
      skillMatch +=
        Math.min(user.skillsLevels[skillId], jobProfile.skillsLevels[skillId]) /
        jobProfile.skillsLevels[skillId];
    }
  });

  // Divide by total, multiply by 100 for percentage.
  skillMatch /= jobProfile.skills.length;
  skillMatch *= 100.0;

  return skillMatch;
}
