import React, { useState, useEffect } from 'react';

import { GOAL_CYCLES_VIRTUAL_STATUSES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import CompanySettingBlock from '~/components/CompanySettingBlock';
import { confirm } from '~/components/ConfirmDialog';
import CreateGoalCycleModal from '~/components/CreateGoalCycleModal';
import OverviewHeading from '~/components/OverviewHeading';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SvgIcon from '~/components/SvgIcon';
import Tabs from '~/components/Tabs';
import { SearchField } from '~/components/Text';
import TextField from '~/components/TextField';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import ActionsContainer from '~/components/UI/ActionsContainer';
import TableCard from '~/components/UI/TableCard';
import TabsContainer from '~/components/UI/TabsContainer';
import BaseLayout from '~/layouts/BaseLayout';

import GoalCycleItem from './components/GoalCycleItem';

import goalsIcon from '~/assets/main-menu-icons/rocket.svg';

import { INSTRUCTIONS } from '~/constants/instructions';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import useStringState from '~/hooks/useStringState';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getCompanyGoalUpdates, updateCompanyGoalUpdates } from '~/services/companySettings';
import {
  getGoalCycles,
  createGoalCycle,
  updateGoalCycle,
  deleteGoalCycle,
} from '~/services/goalCycles';
import { getReviews } from '~/services/reviews';
import getInstructionUrl from '~/utils/getInstructionUrl';

const OverviewHeadingStyled = styled(OverviewHeading)`
  background: none;
`;

const NameSearch = styled(SearchField)`
  width: 389px;
  ${TextField} {
    border-radius: 6px;
    font-size: 14px;
    height: 32px;
    margin: 4px 0;
  }
`;

const TabsWrapper = styled(Tabs)`
  margin-bottom: 0;
  border: none;
`;

const TABS_ENUM = {
  active: 'active',
  completed: 'completed',
};

function GoalCycles() {
  const { i18n } = useLingui();
  const [goalCycles, setGoalCycles] = useState([]);
  const [companyReviews, setCompanyReviews] = useState([]);
  const [activeGoalCycle, setActiveGoalCycle] = useState(null);
  const [currentTab, setCurrentTab] = useState(TABS_ENUM.active);
  const $search = useStringState();
  const debSearch = useDebounce($search.value, 300);
  const $loading = useBoolState();
  const $goalUpdatesloading = useBoolState(true);
  const $isModalOpen = useBoolState();
  const { addToast } = useToasts();
  const company = useSelector(getCurrentCompany);
  const [settings, setSettings] = useState(null);
  const [savedSettings, setSavedSettings] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      $loading.on();
      const filteredGoalSycles = await getGoalCycles({
        search: debSearch,
        // display uncompleted goal cycles for Active tab
        type:
          currentTab === TABS_ENUM.active ? GOAL_CYCLES_VIRTUAL_STATUSES.UNCOMPLETED : currentTab,
      });
      if (isMounted) {
        setGoalCycles(Object.values(filteredGoalSycles));
      }
      // fetch review to disable DELETE option for goal cycle in review
      getReviews().then(({ data: { reviews } }) => {
        if (isMounted) {
          setCompanyReviews(Object.values(reviews));
        }
      });
      $loading.off();
    };
    fetchData();
    return () => (isMounted = false);
    // eslint-disable-next-line
  }, [currentTab, debSearch]);

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      const settings = await getCompanyGoalUpdates(company.id, { forceRole: ROLES.ADMIN });

      if (isMounted) {
        setSettings(settings);
        setSavedSettings(settings);
      }
      $goalUpdatesloading.off();
    };

    fetch();

    return () => (isMounted = false);
    // eslint-disable-next-line
  }, []);

  const TABS = [
    { label: t`Active`, key: TABS_ENUM.active },
    { label: t`Completed`, key: TABS_ENUM.completed },
  ];

  const handleChangeTab = (key) => {
    setCurrentTab(key);
  };

  const cols = [
    { title: i18n._(t`Cycle`), width: 'auto' },
    { title: i18n._(t`Start`), width: '120px' },
    { title: i18n._(t`End`), width: '170px' },
  ];

  const removeItem = async (id) => {
    if (await confirm(i18n, i18n._(t`Are you sure you want to delete this goal cycle?`))) {
      await deleteGoalCycle(id, { forceRole: ROLES.ADMIN });
      const updatedGoalCycles = goalCycles.filter((c) => c.id !== id);
      setGoalCycles([...updatedGoalCycles]);
    }
  };
  const updateItem = (cycle) => {
    setActiveGoalCycle(cycle);
    $isModalOpen.on();
  };

  const saveGoalCycle = async (newGoalCycle) => {
    const $isUpdate = !isEmpty(activeGoalCycle);

    if ($isUpdate) {
      const updatedGoalCycle = await updateGoalCycle(
        newGoalCycle.id,
        {
          name: newGoalCycle.name,
          startDate: newGoalCycle.startDate,
          endDate: newGoalCycle.endDate,
        },
        { forceRole: ROLES.ADMIN },
      );
      const updatedGoalCycles = goalCycles
        .map((c) => (c.id === activeGoalCycle.id ? updatedGoalCycle : c))
        .sort(sortByStartDate);
      setGoalCycles([...updatedGoalCycles]);
      setActiveGoalCycle(null);
    } else {
      const createdGoalCycle = await createGoalCycle(newGoalCycle, { forceRole: ROLES.ADMIN });
      const updatedGoalCycles = [...goalCycles, createdGoalCycle].sort(sortByStartDate);
      setGoalCycles(updatedGoalCycles);
    }
    $isModalOpen.off();
  };

  const sortByStartDate = (a, b) => {
    const dateA = a.startDate;
    const dateB = b.startDate;
    return moment(dateB).isAfter(dateA) ? 1 : -1;
  };

  const getLinkedReviews = (goalCycle) =>
    companyReviews.filter((r) => {
      return (
        get(r, 'goalCyclesBusinessPlan', [])
          .map((c) => c.id)
          .includes(goalCycle.id) ||
        get(r, 'goalCyclesLearningPlan', [])
          .map((c) => c.id)
          .includes(goalCycle.id)
      );
    });

  const updateSettings = async (data = {}) => {
    const backup = settings;

    // Check if anything changed, otherwise only update local
    const isFieldsChanged = Object.keys(data).some((key) => data[key] !== savedSettings[key]);

    if (!isFieldsChanged) {
      localUpdate(data);
      return;
    }

    // If any relevant field is updated, reset the timer so it is valid for the next pubsub execution
    const inputFields = ['weekDay', 'repeat', 'showTaskDaysBefore', 'hideTaskDaysAfter'];
    const isResetTimer = Object.keys(data).some((key) =>
      ['isEnabled', ...inputFields].includes(key),
    );
    if (isResetTimer) {
      data.lastCreatedGoalTasksDate = null;
    }

    localUpdate(data);
    try {
      await updateCompanyGoalUpdates(company.id, data, { forceRole: ROLES.ADMIN });

      // Update saved settings locally
      setSavedSettings({ ...settings, ...data });

      // Show toast if any input field has changed
      const isInputFieldChanged = Object.keys(data).some((key) => inputFields.includes(key));
      if (isInputFieldChanged) {
        addToast({
          title: i18n._(t`Saved`),
          subtitle: i18n._(t`Your changes have been saved and will be applied after 23:59`),
          type: TOAST_TYPES.INFO,
        });
      }
    } catch (e) {
      // restore settings locally, if the changes does not apply on back-end
      setSettings(backup);
    }
  };

  const localUpdate = (data) => {
    setSettings({ ...settings, ...data });
  };

  return (
    <>
      <OverviewHeadingStyled
        title={i18n._(t`Goal cycles`)}
        description={i18n._(t`An overview of all goal cycles`)}
        instructions={i18n._(t`How goal cycles work`)}
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.HOW_GOAL_UPDATES_WORK)}
      >
        <Button label={i18n._(t`+ Goal cycle`)} onClick={() => $isModalOpen.on()} />
      </OverviewHeadingStyled>
      <BaseLayout>
        <TabsContainer noBorderBottom>
          <TabsWrapper
            items={TABS}
            isSmall
            currentItem={currentTab}
            handleChangeTab={handleChangeTab}
          />
        </TabsContainer>
        <ActionsContainer noHorizontalBorders noBorderRadiusBottom noBorderRadiusTop>
          <NameSearch
            onChange={(e) => {
              $search.setForInput(e);
            }}
            placeholder={i18n._(t`Search for goal cycle`)}
          />
        </ActionsContainer>
        <TableCard
          cols={cols}
          items={goalCycles}
          renderRow={(goalCycle) => (
            <GoalCycleItem
              key={goalCycle.id}
              goalCycle={goalCycle}
              updateItem={updateItem}
              removeItem={removeItem}
              linkedReviews={getLinkedReviews(goalCycle)}
            />
          )}
          loading={$loading.value}
          hideHeader
          noTopBorder
          marginBottom="32px"
          firstPlaceholder={
            <Placeholder
              title={i18n._(t`No goal cycles made yet`)}
              subTitle={i18n._(
                t`Create a goal cycle and link goals to this goal cylce. This way you can start evaluating goals in reviews.`,
              )}
              Icon={() => (
                <SvgIcon
                  url={goalsIcon}
                  width="32px"
                  height="32px"
                  isDefaultColor={true}
                  defaultColor="#cadafc"
                />
              )}
            />
          }
        />
        <ShowSpinnerIfLoading loading={$goalUpdatesloading.value}>
          {company.id && !isEmpty(settings) && (
            <CompanySettingBlock
              settings={settings}
              updateSettings={updateSettings}
              localUpdate={localUpdate}
              title={i18n._(t`Task for goal update`)}
            />
          )}
        </ShowSpinnerIfLoading>
        {$isModalOpen.value && (
          <CreateGoalCycleModal
            onClose={() => {
              $isModalOpen.off();
              setActiveGoalCycle(null);
            }}
            onSave={saveGoalCycle}
            cycle={activeGoalCycle}
          />
        )}
      </BaseLayout>
    </>
  );
}

export default GoalCycles;
