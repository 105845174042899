import debounce from 'lodash/debounce';

const DELAY = 10000;
let debounced = null;
let lastId = null;

// if id the save -> wait for delay
// if id changes -> execute Fn immediately and start new timer for new id.
export const autosave = (
  id,
  Fn,
  { onStart = () => {}, onRestart = () => {}, onEnd = () => {} } = {},
) => {
  if (id !== lastId) {
    if (debounced) {
      // execute immediately
      debounced.flush();
    }

    // init autosave
    debounced = debounce(
      (arg) => {
        onEnd();
        return Fn(arg);
      },
      DELAY,
      { leading: false, trailing: true },
    );

    // start autosave
    debounced(id);
    onStart();

    // update UPDATE_RATING_ID
    lastId = id;
  } else {
    // restart autosave
    debounced(id);
    onRestart();
  }

  return debounced;
};
