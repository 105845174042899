import { QUESTION_TYPES, KPI_TYPES } from '@learned/constants';

interface IReviewTemplateQuestionBase {
  name?: string;
  description?: string;
  skills?: string[];
  hideRating?: boolean;
  isSkippable?: boolean;
  isAverageQuestionEnabled?: boolean;
}

interface IReviewTemplateQuestionCustom extends IReviewTemplateQuestionBase {
  type: QUESTION_TYPES.CUSTOM;
  scaleLabels: string[];
}

interface IReviewTemplateQuestionEval extends IReviewTemplateQuestionBase {
  type: QUESTION_TYPES.GOAL_BUSINESS_EVAL | QUESTION_TYPES.GOAL_LEARNING_EVAL;
  isAverageQuestionEnabled: boolean;
}

export interface IReviewTemplateQuestionJobProfile extends IReviewTemplateQuestionBase {
  type: QUESTION_TYPES.JOB_PROFILE;
  skillCategories: string[];
}

interface IReviewTemplateQuestionSkill extends IReviewTemplateQuestionBase {
  type: QUESTION_TYPES.SKILL;
}

export type IReviewTemplateQuestion =
  | IReviewTemplateQuestionEval
  | IReviewTemplateQuestionCustom
  | IReviewTemplateQuestionJobProfile
  | IReviewTemplateQuestionSkill;

interface IReviewTemplateSectionBase {
  name: string;
  questions: IReviewTemplateQuestion[];
  scaleLabels?: string[];
  isSkippable?: boolean;
  isSelfReview?: boolean;
  isUsersReview?: boolean;
  isCoachesReview?: boolean;
}

interface IReviewTemplateSectionPotential extends IReviewTemplateSectionBase {
  type: KPI_TYPES.POTENTIAL;
  kpi: string;
  isSkippable: boolean;
  scaleLabels: string[];
}

interface IReviewTemplateSectionPerformance extends IReviewTemplateSectionBase {
  type: KPI_TYPES.PERFORMANCE;
  kpi: string;
  isSkippable: boolean;
  scaleLabels: string[];
}

interface IReviewTemplateSectionSkills extends IReviewTemplateSectionBase {
  type: KPI_TYPES.SKILLS;
  kpi: string;
}

interface IReviewTemplateSectionNone extends IReviewTemplateSectionBase {
  type: null;
  kpi: null;
}

// kpi created by admin
interface IReviewTemplateSectionCustom extends IReviewTemplateSectionBase {
  type: null;
  kpi: string;
}

export type IReviewTemplateSection =
  | IReviewTemplateSectionPotential
  | IReviewTemplateSectionPerformance
  | IReviewTemplateSectionSkills
  | IReviewTemplateSectionNone
  | IReviewTemplateSectionCustom;

export enum REVIEW_STATUSES {
  DRAFT = 'draft',
  ACTIVE = 'active',
  DONE = 'done',
}

export interface IReviewTemplate {
  id: string;
  name: string;
  description: string;
  sections: IReviewTemplateSection[];
  status: REVIEW_STATUSES;
  company: string;
  createdBy?: string;
  meta: {
    createdDate: string;
    lastModifiedDate: string;
  };
}
