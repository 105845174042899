import React from 'react';

import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import IconButton from '~/components/IconButton';

import { COLORS, COLOR_PALETTE, COLOR_SET } from '~/styles';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

const OptionListItem = styled.div`
  height: 40px;
  box-sizing: border-box;
  padding: 11px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  &:hover {
    ${(props) => !props.isSelected && `background-color: ${COLORS.BG_PAGE};`}
  }
  &:last-child {
    border-bottom: none;
  }
  ${(props) => props.isSelected && 'background-color: var(--company-color);'}
`;

const OptionListItemLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.isSelected ? COLORS.WHITE : COLORS.TEXT_BLACK)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    color: ${COLOR_SET.MIDNICHT_BLUE};
  }
`;

const tippyOpts = { modifiers: [{ name: 'computeStyles', options: { gpuAcceleration: false } }] };

const SearchListItem = React.memo((props) => {
  const {
    option,
    isActive,
    labelProperty,
    labelPropertyReserve,
    onChange,
    onRemove,
    showTooltip = false,
    isSelected = false,
  } = props;

  return (
    <OptionListItem
      onMouseDown={(e) => e.preventDefault()}
      onMouseUp={(e) => !isActive && onChange(option, e)}
      isSelected={isSelected}
    >
      <Tippy
        trigger="mouseenter"
        theme="light"
        popperOptions={tippyOpts}
        disabled={!showTooltip}
        content={
          typeof labelProperty === 'function'
            ? labelProperty(option)
            : option[labelProperty] || option[labelPropertyReserve]
        }
      >
        <OptionListItemLabel isSelected={isSelected}>
          {typeof labelProperty === 'function'
            ? labelProperty(option)
            : option[labelProperty] || option[labelPropertyReserve]}
        </OptionListItemLabel>
      </Tippy>
      {isActive && onRemove && (
        <IconButton
          noBorder
          size={18}
          onClick={(e) => {
            e.stopPropagation();
            onRemove(option);
          }}
        >
          <Icon icon={ICONS.CLOSE} size={ICON_SIZES.SMALL} color="var(--company-color)" />
        </IconButton>
      )}
    </OptionListItem>
  );
});

SearchListItem.displayName = 'SearchListItem';

export default SearchListItem;
