import React from 'react';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled, { css } from 'styled-components';

import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import ToolTip from '~/components/Tooltip';

import { AvatarsRow } from './AvatarsRow';
import { DateBlock } from './DateBlock';
import { SidebarRowIcon } from './SidebarRowIcon';

import { COLORS } from '~/styles';

import { SIDEBAR_ITEMS, SIDEBAR_ITEM_TYPES } from '../constants';

const Placeholder = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: ${COLORS.PLACEHOLDERS};
`;

const Row = styled.div<{ isAlignCenter?: boolean }>`
  width: 218px;
  min-height: 32px;
  display: flex;
  cursor: pointer;
  padding: 5px 1px 5px 8px;
  box-sizing: border-box;

  ${({ isAlignCenter }) =>
    isAlignCenter &&
    css`
      align-items: center;
    `}

  &:hover {
    border-radius: 6px;
    background-color: ${COLORS.HOVER};

    .row-name {
      color: ${COLORS.TEXT_HOVER};

      span {
        color: ${COLORS.SUBTEXT};
      }
    }
  }
`;

const RowName = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.SUBTEXT};
    margin-left: 7px;
  }
`;

const ConvoName = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  display: flex;
  align-items: center;

  div {
    max-height: 2.7em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.3;
  }
`;

const RowWrap = styled.div`
  margin-bottom: 19px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
  margin-bottom: 5px;
`;

const NameWrap = styled.div`
  div {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SidebarRow = ({
  type,
  name,
  onClick,
  isShowPlaceholder = false,
  nameCount,
  isLoading,
  date,
  coaches,
}: {
  name?: string;
  type: SIDEBAR_ITEM_TYPES;
  onClick: (() => void) | ((userId?: string) => void) | undefined;
  isShowPlaceholder?: boolean;
  nameCount?: number | null;
  isLoading: boolean;
  date?: Date | null;
  coaches?: string[];
}) => {
  const { i18n } = useLingui();

  const item = Object.values(SIDEBAR_ITEMS).find((i) => i.key === type);
  const isItemsWithIcon = [
    SIDEBAR_ITEM_TYPES.NEXT_STEPS,
    SIDEBAR_ITEM_TYPES.CAREER,
    SIDEBAR_ITEM_TYPES.TEAMS,
  ].includes(type);

  return (
    <ShowSpinnerIfLoading loading={isLoading}>
      <RowWrap>
        <Title>
          <Trans>{item?.title(i18n)}</Trans>
        </Title>
        <Row
          onClick={type !== SIDEBAR_ITEM_TYPES.COACH ? (onClick as () => void) : undefined}
          isAlignCenter={isShowPlaceholder}
        >
          {isItemsWithIcon && <SidebarRowIcon type={type} />}
          {type === SIDEBAR_ITEM_TYPES.CONVERSATION && (
            <DateBlock isShowPlaceholder={isShowPlaceholder} date={date} />
          )}
          {isShowPlaceholder ? (
            // @ts-ignore
            <Placeholder>{item?.placeholder(i18n)}</Placeholder>
          ) : type !== SIDEBAR_ITEM_TYPES.COACH ? (
            type !== SIDEBAR_ITEM_TYPES.CONVERSATION ? (
              <RowName className="row-name">
                {name && name.length > 16 ? (
                  <NameWrap>
                    <ToolTip tooltip={name} disabled={false}>
                      <div>{name}</div>
                    </ToolTip>
                  </NameWrap>
                ) : (
                  name
                )}
                {!!nameCount && <span>+{nameCount}</span>}
              </RowName>
            ) : (
              <ConvoName>
                <div>{name}</div>
              </ConvoName>
            )
          ) : (
            <AvatarsRow
              onClick={onClick as (userId?: string) => void}
              coaches={coaches as string[]}
            />
          )}
        </Row>
      </RowWrap>
    </ShowSpinnerIfLoading>
  );
};

export { SidebarRow };
