import React from 'react';

import PassportPage from '~/pages/PassportPage';

import CoachTeamPage from './Teams';

const Coach = () => {
  const searchParams = new URLSearchParams(location.search);
  const memberId = searchParams.get('memberId');
  if (memberId) {
    return <PassportPage />;
  }
  return <CoachTeamPage />;
};

export default Coach;
