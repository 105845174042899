import React, { Component, Fragment } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';

// Actions

import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import { confirm } from '~/components/ConfirmDialog';
import EditSkillCategoriesModal from '~/components/EditSkillCategoriesModal';
import OverviewHeading from '~/components/OverviewHeading';
import SkillSetupModal from '~/components/SkillSetupModal';
import { withToasts, TOAST_TYPES } from '~/components/Toast';
import TableCard, { TableRow, TableCol } from '~/components/UI/TableCard';
import { Header3 } from '~/components/UI/Typographics/headers';
import BaseLayout from '~/layouts/BaseLayout';

import SkillLevelsModal from './components/SkillLevelsModal';

import { INSTRUCTIONS } from '~/constants/instructions';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getCompany } from '~/services/companies';
import { getCompanySkillLabels, getCompanyDefaultFocusAreas } from '~/services/companySettings';
import { getSkillSets, updateEnabledSkillSetOfCompany } from '~/services/skills';
import {
  updateCompanySkillLabels,
  updateCompanyDefaultFocusAreas,
} from '~/store/companySettings/actions';
import { setSkillSets } from '~/store/skillSets/actions';
import getDuplicatesInArray from '~/utils/getDuplicatesInArray';
import getInstructionUrl from '~/utils/getInstructionUrl';
import { getSkillSetName, getSkillSource } from '~/utils/skillUtils';

import SkillSetDashboard from '../SkillSetDashboard';

const LEARNED_PREMIUM_SKILL_SET_KEY = 'learnedPremiumSkills';

class SkillSetsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSkill: null,
      loading: true,
      skillSets: [],
      skillLevels: [],
      defaultFocusAreas: [],
      isShowErrors: false,
      originalSkillLevels: [],
      items: null,
      isSkillModalOpen: false,
      isSkillLevelsModalOpen: false,
      isSkillCategoriesModalOpen: false,
      newSkill: null,
    };
  }

  componentDidMount = async () => {
    const {
      dispatch,
      company: { id },
    } = this.props;
    const [skillSets, company, companySkillLevels, companyDefaultFocusAreas] = await Promise.all([
      getSkillSets({ isTotal: true }),
      getCompany(id),
      getCompanySkillLabels(id),
      getCompanyDefaultFocusAreas(id),
    ]);
    await dispatch(setSkillSets(skillSets));
    this.setState({ skillLevels: companySkillLevels });
    this.setState({ originalSkillLevels: companySkillLevels });
    const companyFocusAreas = companySkillLevels.map((_l, i) => companyDefaultFocusAreas[i] || []);
    this.setState({ defaultFocusAreas: companyFocusAreas });
    this.setState({ originalDefaultFocusAreas: companyFocusAreas });

    if (company) {
      const companySkillsEnabled = company.skillsSets.companySkillsEnabled;
      const learned2SoftEnabled = company.skillsSets.learned2SoftEnabled;
      const learnedPremiumEnabled = company.skillsSets.learnedPremiumEnabled;
      this.setState({ companySkillsEnabled, learned2SoftEnabled, learnedPremiumEnabled });
    }
    this.setState({ loading: false });
  };

  openSkillModal = (skill) => {
    this.setState({ isSkillModalOpen: true, currentSkill: skill });
  };

  closeSkillModal = () => {
    this.setState({ isSkillModalOpen: false, newSkill: null });
  };

  openSkillCategoriesModal = () => {
    this.setState({ isSkillCategoriesModalOpen: true });
  };

  closeSkillCategoriesModal = () => {
    this.setState({ isSkillCategoriesModalOpen: false });
  };

  openSkillLevelsModal = () => {
    this.setState({ isSkillLevelsModalOpen: true, isShowErrors: false });
  };

  closeSkillLevelsModal = async () => {
    const { i18n } = this.props;
    const { skillLevels, originalSkillLevels, defaultFocusAreas, originalDefaultFocusAreas } =
      this.state;

    if (
      !isEqual(skillLevels, originalSkillLevels) ||
      !isEqual(defaultFocusAreas, originalDefaultFocusAreas)
    ) {
      if (
        await confirm(
          i18n,
          i18n._(t`Are you sure you want to exit this screen? Your unsaved data will be lost.`),
        )
      ) {
        this.setState({ skillLevels: originalSkillLevels });
        this.setState({ defaultFocusAreas: originalDefaultFocusAreas });
        this.setState({ isSkillLevelsModalOpen: false });
      }
    } else {
      this.setState({ isSkillLevelsModalOpen: false });
    }
  };

  changeSkillLevelsModal = (newSkillLevels, newDefaultFocusAreas) => {
    this.setState({ skillLevels: newSkillLevels });
    this.setState({ defaultFocusAreas: newDefaultFocusAreas });
  };

  showToast = (title, message, type = TOAST_TYPES.INFO) => {
    const { toasts } = this.props;
    toasts.add({
      title,
      subtitle: message,
      type,
    });
  };

  checkErrors = () => {
    const { i18n } = this.props;
    const { skillLevels } = this.state;

    const duplicates = getDuplicatesInArray(skillLevels);
    const emptyName = skillLevels.some((c) => isEmpty(c));
    const hasErrors = duplicates.length > 0 || emptyName;

    const errors = skillLevels.filter((x) => {
      if (duplicates.includes(x) || isEmpty(x)) {
        return true;
      }
      return false;
    });

    if (hasErrors) {
      this.setState({ isShowErrors: true });
      let errorMessage = '';

      if (duplicates.length > 0) {
        errorMessage += i18n._(t`Skill level names must be unique. `);
      }
      if (emptyName) {
        errorMessage += i18n._(t`Skill level names cannot be empty.`);
      }

      this.showToast(
        i18n._(t`No valid input for skill level names`),
        errorMessage,
        TOAST_TYPES.warning,
      );
    }

    return errors.length > 0;
  };

  submitSkillLevelsModal = () => {
    const { dispatch, i18n } = this.props;
    const { skillLevels, defaultFocusAreas } = this.state;

    const hasErrors = this.checkErrors();
    if (!hasErrors) {
      dispatch(updateCompanySkillLabels(skillLevels));
      dispatch(updateCompanyDefaultFocusAreas(defaultFocusAreas));
      this.setState({ isSkillLevelsModalOpen: false, originalSkillLevels: skillLevels });
      this.showToast(
        i18n._(t`Skill levels successfully updated`),
        i18n._(t`The skill level names are successfully updated`),
      );
    }
  };

  handleCreateSkill = () => {
    const { activeSkillSet } = this.state;

    const { source } = getSkillSource(activeSkillSet);
    this.openSkillModal({
      source,
    });
  };

  getCols = (i18n) => [
    { title: i18n._(t`Name`) },
    { title: i18n._(t`Skills`), width: '100px' },
    { title: i18n._(t`Active`), width: '100px' },
  ];

  handleChangeSkillSetStatus = async (setting, skillSetType) => {
    await updateEnabledSkillSetOfCompany({ setting, skillSetType });
    this.setState({ [skillSetType]: setting });
  };

  getTabs = () => ({
    companySkills: {
      key: 'companySkills',
    },
    learned2Skills: {
      key: 'learned2SoftSkills',
    },
    learnedPremiumSkills: {
      key: LEARNED_PREMIUM_SKILL_SET_KEY,
    },
  });

  addNewSkill = (skill) => {
    this.setState({ newSkill: skill });
    this.changeSetTotal(true);
  };

  changeSetTotal = (isNew) => {
    const { skillSets, dispatch } = this.props;
    skillSets.companySkills.isTotal = isNew
      ? Number(skillSets.companySkills.isTotal) + 1
      : Number(skillSets.companySkills.isTotal) - 1;
    dispatch(setSkillSets(skillSets));
  };

  renderSkillSet = (activeSkillSet) => {
    const { newSkill } = this.state;
    const { company, i18n } = this.props;
    const tabs = this.getTabs();
    const skillSetName = getSkillSetName(activeSkillSet, company, i18n);

    switch (activeSkillSet) {
      case tabs.companySkills.key:
        return (
          <SkillSetDashboard
            skillSetName={skillSetName}
            isLearnedSet={false}
            newSkill={newSkill}
            changeSetTotal={this.changeSetTotal}
          />
        );
      case tabs.learned2Skills.key:
        return <SkillSetDashboard skillSetName={skillSetName} isLearnedSet />;
      case tabs.learnedPremiumSkills.key:
        return <SkillSetDashboard skillSetName={skillSetName} isSuggestionsSearch />;
    }
  };

  getItems = () => {
    const { skillSets } = this.props;
    return [
      { companySkills: skillSets.companySkills },
      { learned2SoftSkills: skillSets.learned2Skills },
      { learnedPremiumSkills: {} },
    ];
  };

  updateActiveSkillSet = (activeSkillSet) => {
    this.setState({ activeSkillSet, insideSkillSet: true });
  };

  goBackToSkillSetOverview = () => {
    this.setState({ insideSkillSet: false });
  };

  getSkillSetType = (key) => {
    return key.substring(0, key.indexOf('Skills')) + 'Enabled';
  };

  renderRow = (skillSet, index) => {
    const { company, i18n } = this.props;

    const skillSetType =
      index === 0 ? 'companySkillsEnabled' : this.getSkillSetType(Object.keys(skillSet)[0]);
    const skillSetEnabledBox = this.state[skillSetType];

    const skillsCount = skillSet.learnedPremiumSkills
      ? '100.000+'
      : Object.values(skillSet)[0].isTotal;

    return (
      <TableRow key={index}>
        <TableCol onClick={() => this.updateActiveSkillSet(Object.keys(skillSet)[0])}>
          <Header3>{getSkillSetName(Object.keys(skillSet)[0], company, i18n)}</Header3>
        </TableCol>
        <>
          <TableCol>{skillsCount}</TableCol>
          <TableCol>
            <CheckBox
              size={24}
              checked={skillSetEnabledBox}
              onChange={() => this.handleChangeSkillSetStatus(!skillSetEnabledBox, skillSetType)}
            />
          </TableCol>
        </>
      </TableRow>
    );
  };

  render() {
    const {
      loading,
      insideSkillSet,
      activeSkillSet,
      isSkillModalOpen,
      isSkillLevelsModalOpen,
      currentSkill,
      skillLevels,
      defaultFocusAreas,
      isSkillCategoriesModalOpen,
      isShowErrors,
    } = this.state;
    const { company, i18n } = this.props;

    const isLearned = activeSkillSet && activeSkillSet.indexOf('learned') !== -1;

    return (
      <Fragment>
        <OverviewHeading
          onBack={insideSkillSet && this.goBackToSkillSetOverview}
          title={insideSkillSet ? i18n._(t`Skill sets`) : i18n._(t`Skills`)}
          description={!insideSkillSet ? i18n._(t`An overview of all skill sets`) : null}
          instructions={i18n._(t`How skills work`)}
          instructionsUrl={getInstructionUrl(INSTRUCTIONS.CREATING_SKILL_SET)}
        >
          {!insideSkillSet && (
            <Button
              styles={{
                width: 'auto',
                height: '48px',
                padding: '8px',
                borderRadius: '6px',
                fontSize: '14px',
                fontWeight: '600',
              }}
              label={i18n._(t`Edit categories`)}
              onClick={this.openSkillCategoriesModal}
              loading={loading}
              type="primary-border"
            />
          )}
          {!insideSkillSet && (
            <Button
              styles={{
                width: 'auto',
                height: '48px',
                padding: '8px',
                borderRadius: '6px',
                fontSize: '14px',
                fontWeight: '600',
              }}
              label={i18n._(t`Edit levels`)}
              onClick={this.openSkillLevelsModal}
              loading={loading}
              type="primary-border"
            />
          )}
          {insideSkillSet && !isLearned && (
            <Button
              width={68}
              height={48}
              label={<>+ {i18n._(t`Skill`)}</>}
              onClick={this.handleCreateSkill}
            />
          )}
        </OverviewHeading>
        <BaseLayout>
          {!loading && company && !insideSkillSet && (
            <TableCard
              hideHeader={true}
              cols={this.getCols(i18n)}
              items={this.getItems()}
              loading={loading || !company}
              renderRow={this.renderRow}
            />
          )}

          {insideSkillSet && this.renderSkillSet(activeSkillSet)}
          {isSkillModalOpen && (
            <SkillSetupModal
              skill={currentSkill}
              onClose={this.closeSkillModal}
              addNewSkill={this.addNewSkill}
            />
          )}
          {isSkillLevelsModalOpen && (
            <SkillLevelsModal
              skillLevels={skillLevels}
              defaultFocusAreas={defaultFocusAreas}
              isShowErrors={isShowErrors}
              onClose={this.closeSkillLevelsModal}
              onChange={this.changeSkillLevelsModal}
              onSubmit={this.submitSkillLevelsModal}
            />
          )}
          {isSkillCategoriesModalOpen && (
            <EditSkillCategoriesModal onClose={this.closeSkillCategoriesModal} />
          )}
        </BaseLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCompany: state.selected.company,
  company: getCurrentCompany(state),
  skillSets: state.skillSets,
});

export default withI18n()(connect(mapStateToProps)(withToasts(SkillSetsOverview)));
