import React, { useEffect } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import OverviewHeading from '~/components/OverviewHeading';
import BoxWithShadow from '~/components/UI/BoxWithShadow';
import BaseLayout from '~/layouts/BaseLayout';
import UserCareer from '~/pages/UserCareer';

import { INSTRUCTIONS } from '~/constants/instructions';
import routes from '~/constants/routes';
import { checkModuleCareer } from '~/selectors/baseGetters';
import getInstructionUrl from '~/utils/getInstructionUrl';

const StyledBoxWithShadow = styled(BoxWithShadow)`
  box-shadow: none;
`;

function UserCareerWrapp({ i18n }) {
  const isCareerModuleEnabled = useSelector(checkModuleCareer);
  const history = useHistory();

  useEffect(() => {
    if (!isCareerModuleEnabled) {
      history.push(routes.HOME);
    }
  }, [isCareerModuleEnabled, history]);

  const getBackPath = () => {
    let url = new URL(window.location.href);
    let path = url.searchParams.get('from');
    if (!isEmpty(path)) {
      url.searchParams.get('from').split('#')[0];
    }
    return path || null;
  };

  const backPath = getBackPath();

  return (
    <>
      <OverviewHeading
        title={i18n._(t`Career`)}
        description={i18n._(t`An overview of your current roles and ambitions`)}
        instructions={i18n._(t`How career paths work`)}
        instructionsUrl={getInstructionUrl(INSTRUCTIONS.HOW_CAREER_PLAN_WORKS)}
        smallWidth
        backPath={backPath}
      />
      <BaseLayout smallWidth>
        <StyledBoxWithShadow>
          <UserCareer />
        </StyledBoxWithShadow>
      </BaseLayout>
    </>
  );
}

export default withI18n()(UserCareerWrapp);
