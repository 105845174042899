import React, { Component } from 'react';

import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SelectLevelModal from '~/components/SelectSkillsModal/SelectLevelModal';
import SkillName from '~/components/Skill/SkillName';

import { COLORS, COLOR_PALETTE } from '~/styles';

const SkillTag = styled.div`
  padding: 7px 7px 11px 11px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};

  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: 0.25px;
  color: #000000;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  min-height: 26px;
  margin-right: 8px;
`;

const LevelWrapper = styled.div`
  background-color: ${COLOR_PALETTE.WHITE};
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
`;

const Level = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #70747f;
`;

class SkillViewItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDetailsOpen: false,
      isHover: false,
    };
  }

  static propTypes = {
    skill: PropTypes.object,
    skillLevel: PropTypes.number,
    onAddSkill: PropTypes.func,
    onUpdateSkill: PropTypes.func,
    isUpdateMode: PropTypes.bool,
    readOnly: PropTypes.bool,
    isOnClickDisabled: PropTypes.bool,
  };

  static defaultProps = {
    skillLevel: 1,
    onAddSkill: () => {},
    onUpdateSkill: () => {},
    isUpdateMode: false,
    readOnly: false,
    isOnClickDisabled: false,
  };

  showDetails = () => {
    this.setState({ isDetailsOpen: true });
  };

  hideDetails = () => {
    this.setState({ isDetailsOpen: false });
  };

  render() {
    const { skill, skillLevel, onUpdateSkill, readOnly, isOnClickDisabled } = this.props;

    const { isDetailsOpen } = this.state;

    if (isEmpty(skill)) {
      return null;
    }

    return (
      <div>
        <SkillTag
          $isOnClickDisabled={isOnClickDisabled}
          onMouseOver={() => this.setState({ isHover: true })}
          onFocus={() => this.setState({ isHover: true })}
          onMouseLeave={() => this.setState({ isHover: false })}
          onClick={!isOnClickDisabled && this.showDetails}
        >
          <SkillName skill={skill} />
          <LevelWrapper>
            <Level>{skillLevel}</Level>
          </LevelWrapper>
        </SkillTag>

        {isDetailsOpen && (
          <SelectLevelModal
            skill={skill}
            selectedLevel={skillLevel}
            onClose={this.hideDetails}
            onSubmit={onUpdateSkill}
            readOnly={readOnly}
            isUpdate={true}
          />
        )}
      </div>
    );
  }
}

export default withI18n()(SkillViewItem);
