import React from 'react';

import * as PropTypes from 'prop-types';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import styled from 'styled-components';

import ProgressProvider from '~/components/ProgressProvider';
import { Header6Dark } from '~/components/UI/Typographics/headers';

import BoardItem from './BoardItem';

import { COLORS } from '~/styles';

const ProgressBox = styled(BoardItem)`
  position: relative;
  ${Header6Dark} {
    width: 104px;
  }
`;

const Progress = styled(CircularProgressbarWithChildren)`
  max-width: 160px;
`;

const ProgressValue = styled.div`
  font-size: 30px;
  font-weight: 600;
  color: var(--company-color);
`;

const BoardProgress = ({ progress, title }) => {
  return (
    <ProgressBox>
      <ProgressProvider progress={progress} steps={10}>
        {(value) => (
          <Progress
            value={value}
            strokeWidth={5}
            styles={buildStyles({
              trailColor: 'transparent',
              pathColor: COLORS.BG_PAGE,
            })}
          >
            <ProgressValue>{value}%</ProgressValue>
            <Header6Dark>{title}</Header6Dark>
          </Progress>
        )}
      </ProgressProvider>
    </ProgressBox>
  );
};

BoardProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default React.memo(BoardProgress);
