import React from 'react';

import styled from 'styled-components';

import AvatarCard, { UserName } from '~/components/UI/AvatarCard';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { COLOR_PALETTE } from '~/styles';

const CountNotice = styled.span`
  font-size: 12px;
  color: var(--company-color);
`;

const StyledAvatarCard = styled(AvatarCard)`
  ${UserName} {
    font-weight: 500;
  }
`;

const UserRowContainer = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 5fr;
  flex-direction: row;
  min-height: 56px;
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  margin-bottom: 5px;
  padding: 0 5px;
  box-sizing: border-box;

  :hover {
    cursor: pointer;
    background-color: #ebf1fe;
  }
`;

const TeamsList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0 5px 40px;
`;

const Tag = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
  border-radius: 3px;
  background-color: ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  height: 26px;
  padding: 3px 8px;
  margin: 2px 4px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GREY};
`;

const LimitedList = ({ items = [], limit = 4, render }) => {
  if (items.length > limit) {
    return (
      <>
        {items.slice(0, limit).map(render)}
        <CountNotice>+{items.length - limit}</CountNotice>
      </>
    );
  }
  return items.map(render);
};

const ColleagueItem = ({ onClose, user }) => {
  return (
    <UserRowContainer
      key={user.id}
      onClick={() => {
        routes.USER_PUBLIC_SKILL_PASSPORT.go(
          { role: ROLES.USER },
          { userId: user.id, isBackPath: true },
        );
        onClose();
      }}
    >
      <StyledAvatarCard type="medium" userId={user.id} />
      <TeamsList>
        <LimitedList
          items={user.teams}
          limit={3}
          render={(team) => <Tag key={team.id}>{team.name}</Tag>}
        />
      </TeamsList>
    </UserRowContainer>
  );
};

export default ColleagueItem;
