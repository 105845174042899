import React, { useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';
import Tooltip from '~/components/Tooltip';

import SidebarOpenIcon from '~/assets/fast-forward.svg';

import { finishUpdateApp } from '~/store/app/actions';
import { COLOR_PALETTE } from '~/styles';

const SIDEBAR_WIDTH = '412px';

const Layout = styled.div`
  display: flex;
  overflow: hidden;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RightSidebar = styled.div`
  width: ${(props) => (props.$isOpen ? SIDEBAR_WIDTH : 0)};
  min-width: ${(props) => (props.$isOpen ? SIDEBAR_WIDTH : 0)};
  position: relative;
  right: 0;
  transition: width 0.3s, min-width 0.3s;
  margin-left: 58px;
`;

const SidebarFixed = styled.div`
  position: fixed;
  height: 100vh;
  width: ${(props) => (props.$isOpen ? SIDEBAR_WIDTH : 0)};

  // styles
  border-radius: 6px;
  box-shadow: ${(props) => (props.$isOpen ? '0 8px 8px 0 rgba(145, 157, 165, 0.12)' : 'none')};
  border: ${(props) => (props.$isOpen ? 'solid 1px #dae4f7' : 'none')};
  background-color: #fff;
  transition: width 0.3s;
`;

const Tags = styled.div`
  position: absolute;
  top: calc(68px + 30px); // header height + content margin top
  left: -33px;
  display: flex;
  transform: translateX(-100%) rotate(270deg);
  transform-origin: 100% 0;
`;

const TagItem = styled.div`
  margin-right: 8px;
  min-height: 33px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--company-color);
  padding: 0 13px;
  color: white;
  white-space: nowrap;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

const Tabs = styled.div``;

const RightSidebarLayout = ({ children, tabs = [], isOpenDefault = false }) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);
  const [activeTabId, setActiveTabId] = useState(isOpenDefault ? tabs[0]?.id : null);
  const dispatch = useDispatch();

  const onTagClick = (id) => (activeTabId === id ? closeTab() : openTab(id));

  useEffect(() => {
    // duplicate sidebar status to redux-store
    dispatch(finishUpdateApp({ isRightSideBarOpen: isOpen }));

    // eslint-disable-next-line
  }, [isOpen]);

  const closeTab = () => {
    // clear active tab
    setActiveTabId(null);
    // close sidebar
    setIsOpen(false);
  };

  const openTab = (id) => {
    // set active tab
    setActiveTabId(id);
    // open sidebar
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  return (
    <Layout>
      <Content>{children}</Content>
      {!isEmpty(tabs) && (
        <RightSidebar $isOpen={isOpen}>
          <SidebarFixed $isOpen={isOpen}>
            <Tags>
              {tabs.map((item) => {
                const tag = (
                  <TagItem key={item.id} onClick={() => onTagClick(item.id)}>
                    <SvgIcon
                      width="20px"
                      height="12px"
                      url={SidebarOpenIcon}
                      isDefaultColor
                      defaultColor={COLOR_PALETTE.WHITE}
                      styles={{
                        transform: isOpen ? 'rotate(90deg)' : 'rotate(270deg)',
                        marginRight: '8px',
                      }}
                    />
                    {item.title}
                  </TagItem>
                );
                return item.tooltip ? (
                  <Tooltip key={item.id} tooltip={item.tooltip} placement="left">
                    {tag}
                  </Tooltip>
                ) : (
                  tag
                );
              })}
            </Tags>
            <Tabs>{tabs.find((item) => item.id === activeTabId)?.tab}</Tabs>
          </SidebarFixed>
        </RightSidebar>
      )}
    </Layout>
  );
};

RightSidebarLayout.propTypes = {
  isOpenDefault: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      tooltip: PropTypes.string,
      tab: PropTypes.node,
    }),
  ),
};

export default RightSidebarLayout;
