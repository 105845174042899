import React from 'react';

import * as PropTypes from 'prop-types';

import { toFixed } from '~/utils/math';

const ProgressProvider = ({ progress, steps = 1, children }) => {
  const [step, setStep] = React.useState(0);
  const stepSize = progress / steps;

  React.useEffect(() => {
    if (steps > 1) {
      const interval = setInterval(() => {
        setStep((s) => {
          if (s < steps) {
            return s + 1;
          } else {
            clearInterval(interval);
            return steps;
          }
        });
      }, 1000 / steps);
      return () => clearInterval(interval);
    }
    setStep(1);
    // eslint-disable-next-line
  }, [progress]);

  return children(toFixed(step * stepSize, progress % 1 > 0 ? 1 : 0)); // % 1 > 0 ? 1 : 0 - for better animation if progress is integer
};

ProgressProvider.propTypes = {
  progress: PropTypes.number.isRequired,
  steps: PropTypes.number,
  children: PropTypes.func,
};

export default React.memo(ProgressProvider);
