import React from 'react';

import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import { UserSurveyDashboard } from '~/pages/UserSurveyDashboard';

import Module from './createModule';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

export default new Module('userSurveys', {
  routesPrivate: {
    user: [
      <PrivateRoute
        layout={FocusLayout}
        key={routes.USER_SURVEY_DASHBOARD.routePath(ROLES.USER)}
        exact
        path={routes.USER_SURVEY_DASHBOARD.routePath(ROLES.USER)}
        component={UserSurveyDashboard}
        allowedRoles={[ROLES.USER]}
      />,
    ],
  },
  routesPublic: [],
});
