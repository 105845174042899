import React, { useMemo, useState } from 'react';

import { ProductName, ProductStatus } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { FooterRight, ItemsWrapper } from './design';
import { SettingItem } from './SettingItem';

import { getCurrentCompanyId, getSelectedCompanyProducts } from '~/selectors/baseGetters';
import { productsUpdate } from '~/services/companies';
import { updateCompanyProductSettings } from '~/store/companies/actions';
import { setCompanyProductsToReduxStore } from '~/store/products/actions';

import type { IProductSettingsProps } from './types';

const ProductSettingsModal = ({ name, closeModal }: IProductSettingsProps) => {
  const { i18n } = useLingui();
  const products = useSelector(getSelectedCompanyProducts);
  const dispatch = useDispatch();

  const [productSettings, setProductSettings] = useState<{
    [key in string]: { isEnabled: boolean };
  }>(products[name].settings);

  const { addToast } = useToasts();
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const handleToggleSwitch = (value: boolean, key: string) => {
    setProductSettings((prevSettings) => ({
      ...prevSettings,
      [key]: {
        isEnabled: value,
      },
    }));
  };

  const handleSave = async () => {
    closeModal();

    const response = await productsUpdate({
      companyId: currentCompanyId,
      products: {
        [name]: {
          settings: Object.entries(productSettings)
            // convert null to false
            .map(([key, value]) => ({
              [key]: {
                isEnabled: !!value?.isEnabled,
              },
            }))
            // convert array to object
            .reduce((prevItems, currentItem) => {
              const [key, value] = Object.entries(currentItem)[0];
              prevItems = {
                ...prevItems,
                [key]: value,
              };

              return prevItems;
            }, {}),
        },
      },
    });

    if (response) {
      dispatch(updateCompanyProductSettings(name, productSettings));
      // update company products in redux-store
      if (response?.data?.company) {
        dispatch(setCompanyProductsToReduxStore(response?.data?.company));
      }

      addToast({
        title: i18n._(t`Success`),
        subtitle: i18n._(t`product updated`),
        type: TOAST_TYPES.SUCCESS,
      });
    }
  };

  const { title, items } = useMemo(() => {
    switch (name) {
      default:
      case ProductName.CAREER:
        return {
          title: i18n._(t`Career Settings`),
          items: {
            employeeCanSetAmbitions: {
              title: i18n._(t`Members see potential career moves and can set career ambitions`),
            },
            userCanAssignRoles: {
              title: i18n._(
                t`Allow users to assign themselves and remove themselves from current roles`,
              ),
            },
          },
        };
      case ProductName.PERFORMANCE:
        return {
          title: i18n._(t`Performance Settings`),
          items: {
            usersCanQuickSelectOtherUsersInReviews: {
              title: i18n._(t`Members can select other employees in feedback and conversations`),
            },
            previewDevelopmentCycle: {
              title: i18n._(t`Members can preview their development cycle on their dashboard`),
            },
            coachCanCreateReviews: {
              title: i18n._(t`Coaches can schedule development conversations`),
            },
            userCanCreateReviews: {
              title: i18n._(t`Users can schedule development conversations`),
            },
            showEmployeeRolesInReviews: {
              title: i18n._(t`Show all current roles of the employee in the conversations module`),
            },
            realtimeFeedback: {
              title: i18n._(t`Members can ask feedback from other Members`),
            },
            conversationsAndTeamMeetings: {
              title: i18n._(t`Enable 1-1 & team meetings`),
            },
            goals: {
              title: i18n._(t`Enable goals`),
            },
            goalsInTeam: {
              title: i18n._(t`Enable team goals`),
              parent: 'goals',
            },
            goalsInOrganisation: {
              title: i18n._(t`Enable organisation goals`),
              parent: 'goals',
            },
          },
        };
      case ProductName.TALENT:
        return {
          title: i18n._(t`Talents Settings`),
          items: {
            employeeCanSetUpSkillPassport: {
              title: i18n._(t`Members can set up their skill profile`),
            },
            employeeCanLookupProfile: {
              title: i18n._(t`Members can see the profile of other employees`),
            },
            employeeCanSearchBasedOnRoles: {
              title: i18n._(t`Members can search for people based on roles`),
            },
          },
        };
      case ProductName.LEARNING_AND_ONBOARDING:
        return {
          title: i18n._(t`Learning & Onboarding Settings`),
          items: {
            employeeCanCreateActivity: {
              title: i18n._(t`Members can create activities`),
            },
            employeeCanCreatePath: {
              title: i18n._(t`Members can create learning paths`),
              parent: 'employeeCanCreateActivity',
            },
            coachCanCreateActivity: {
              title: i18n._(t`Coaches can create activities`),
            },
            coachCanCreatePath: {
              title: i18n._(t`Coaches can create learning paths`),
              parent: 'coachCanCreateActivity',
            },
            freeLearnedActivities: {
              title: i18n._(t`Show free Learned learning content`),
            },
            ...([ProductStatus.ACTIVE, ProductStatus.TRIAL_ACTIVE].includes(
              products[ProductName.CAREER].status,
            ) && {
              showActivityProgressForRole: {
                title: i18n._(t`Show progress of relevant learning activities for this role`),
              },
            }),
          },
        };
    }
  }, [i18n, name, products]);

  const isSettingEnabled = (key: string) => !!productSettings[key]?.isEnabled;

  return (
    <Modal
      title={title}
      onClose={closeModal}
      showDivider={false}
      centerModal
      minWidth={750}
      contentStyles={{ padding: '20px 40px 0', overflow: 'visible' }}
      headerStyles={{ padding: '20px 40px', fontSize: '26px', fontWeight: 'normal' }}
      borderRadius={6}
      hideFooter
    >
      <ItemsWrapper>
        {Object.entries(items)
          // filter out settings where parent setting is disabled
          .filter(([_key, item]) => {
            if (item.parent) {
              return isSettingEnabled(item.parent);
            }
            return true;
          })
          .map(([key, item]) => (
            <SettingItem
              key={key}
              title={item.title}
              checked={isSettingEnabled(key)}
              onClick={(value) => handleToggleSwitch(value, key)}
              isSecondLevel={Boolean(item.parent)}
            />
          ))}
      </ItemsWrapper>
      <FooterRight>
        <Button
          label={i18n._(t`Cancel`)}
          onClick={closeModal}
          type="button"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
        />
        <Button
          label={i18n._(t`Save`)}
          type="button"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={handleSave}
        />
      </FooterRight>
    </Modal>
  );
};

export { ProductSettingsModal };
