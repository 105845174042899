import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import Checkbox from '~/components/CheckBox';
import Modal from '~/components/Modal';

import useBoolState from '~/hooks/useBoolState';
import { COLOR_PALETTE } from '~/styles';

const Description = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-bottom: 16px;
`;

const FooterActions = styled.div`
  display: flex;
  flex-direction: row;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin-left: 12px;
`;

const LinkName = styled.a`
  margin-top: 16px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.29px;
  text-decoration: none;
  cursor: pointer;
`;

const ConfirmSubmitModal = ({ onClose, onSubmit }) => {
  const { i18n } = useLingui();
  const $loading = useBoolState(false);
  const $isRoleChecked = useBoolState(false);
  const $isKPIChecked = useBoolState(false);
  const $isScaleChecked = useBoolState(false);

  const list = [
    {
      label: i18n._(t`Do all sections have the correct self-, peer- and coach feedback settings?`),
      value: $isRoleChecked.value,
      onChange: $isRoleChecked.toggle,
    },
    {
      label: i18n._(t`Do all sections measure the right KPI?`),
      value: $isKPIChecked.value,
      onChange: $isKPIChecked.toggle,
    },
    {
      label: i18n._(t`Do all sections use the right scale of measurement?`),
      value: $isScaleChecked.value,
      onChange: $isScaleChecked.toggle,
    },
  ];

  const renderContent = () => {
    return (
      <div>
        <Description>
          <Trans>
            Please check if your template contains the right settings for your company before you
            save the template. Changes made to this template do not effect reviews that have been
            published.
          </Trans>
        </Description>
        {list.map((item, index) => (
          <Row key={index} onClick={item.onChange}>
            <Checkbox size={24} checked={item.value} />
            <Label>{item.label}</Label>
          </Row>
        ))}
        <LinkName
          href="https://intercom.help/learned/en/articles/4710573-building-review-templates-for-review-conversations"
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${i18n._(t`Read more about review templates`)}`}
        </LinkName>
      </div>
    );
  };

  const isAllChecked = list.every((i) => i.value);

  return (
    <Modal
      width={600}
      onClose={onClose}
      title={i18n._(t`Check your template`)}
      footerRight={
        <FooterActions>
          <Button
            styles={{
              height: '40px',
              marginLeft: '10px',
              padding: '0px 15px',
              fontSize: '14px',
              width: '83px',
              minWidth: '83px',
            }}
            type="primary-border"
            label={i18n._(t`Cancel`)}
            onClick={onClose}
            loading={$loading.value}
          />
          <Button
            styles={{
              height: '40px',
              marginLeft: '10px',
              padding: '0px 15px',
              fontSize: '14px',
              width: '83px',
              minWidth: '83px',
            }}
            type="primary"
            label={i18n._(t`Save`)}
            onClick={async () => {
              $loading.on();
              await onSubmit();
              $loading.off();
              onClose();
            }}
            loading={$loading.value}
            disabled={!isAllChecked}
          />
        </FooterActions>
      }
    >
      {renderContent()}
    </Modal>
  );
};

ConfirmSubmitModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ConfirmSubmitModal;
