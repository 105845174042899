import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { HeaderTabs } from '~/components/HeaderTabs';
import NoCalendarIntegrationWarning from '~/components/Warnings/NoCalendarIntegrationWarning';
import BaseLayout from '~/layouts/BaseLayout';

import { ConversationCycleTab } from './tabs/ConversationCycle';
import { ParticipationTab } from './tabs/Participation';
import { PersonalTab } from './tabs/Personal';
import { TemplatesTab } from './tabs/Templates';

import { getUser } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';

import type { I18n } from '@lingui/core';

const HeaderWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: fit-content;
  border-radius: 6px 6px 0 0;
`;

export const TABS_ENUM = {
  ABOUT_YOU: 'about_you',
  ABOUT_SOMEONE: 'about_someone',
  CONVERSATION_CYCLE: 'conversation_cycle',
  TEMPLATES: 'templates',
};

interface IDevelopmentSectionProps {
  parentHash: string;
}

const DevelopmentSection = ({ parentHash }: IDevelopmentSectionProps) => {
  const user = useSelector(getUser);

  // define tabs
  const TABS = [
    {
      label: (i18n: I18n) => i18n._(t`About you`),
      key: TABS_ENUM.ABOUT_YOU,
    },
    {
      label: (i18n: I18n) => i18n._(t`About someone else`),
      key: TABS_ENUM.ABOUT_SOMEONE,
    },
    user.isAdmin && {
      label: (i18n: I18n) => i18n._(t`Conversation cycle`),
      key: TABS_ENUM.CONVERSATION_CYCLE,
    },
    user.isAdmin && {
      label: (i18n: I18n) => i18n._(t`Templates`),
      key: TABS_ENUM.TEMPLATES,
    },
  ].filter((i) => i);

  // currentHash everything after "-", so for #development-meetings -> the hash is meetings
  const currentHash = window.location.hash.substring(1).split('-')[1] || TABS_ENUM.ABOUT_YOU;
  const defaultTab = TABS.find((item) => item.key === currentHash);
  const [currentTab, setCurrentTab] = useState(defaultTab?.key as string);

  const handleChangeTab = (key: string) => {
    window.location.hash = `${parentHash}-${key}`;
    setCurrentTab(key);
  };

  return (
    <>
      <BaseLayout smallWidth>
        <NoCalendarIntegrationWarning />
        <HeaderWrapper>
          <HeaderTabs tabs={TABS} selectedTab={currentTab} handleChangeTab={handleChangeTab} />
        </HeaderWrapper>
        {currentTab === TABS_ENUM.ABOUT_YOU && <PersonalTab />}
        {currentTab === TABS_ENUM.ABOUT_SOMEONE && <ParticipationTab />}
        {currentTab === TABS_ENUM.CONVERSATION_CYCLE && <ConversationCycleTab />}
        {currentTab === TABS_ENUM.TEMPLATES && <TemplatesTab />}
      </BaseLayout>
    </>
  );
};

export { DevelopmentSection };
