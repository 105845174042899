import React, { useState, useEffect } from 'react';

import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';
import SkillName from '~/components/Skill/SkillName';
import SvgIcon from '~/components/SvgIcon';

import hatIcon from '~/assets/graduation-hat.svg';
import workIcon from '~/assets/work.svg';

import { EDUCATION_LEVELS, WORK_EXPERIENCE_LEVELS } from '~/constants';
import { SKILL_CATEGORY_TYPE, SKILL_SOURCE } from '~/constants/skills';
import { getCompanySkillCategories } from '~/services/companySettings';
import { getSkillCategories } from '~/services/skillCategories';
import { COLOR_PALETTE, COLORS } from '~/styles';

const TextHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #000000;

  margin-top: 24px;
  margin-bottom: 12px;
`;

const RelevantSkillsSubheader = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin: 8px 0;
`;

const SkillCategory = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const CategoryTitle = styled.div`
  margin: 12px 0;
`;

const SkillContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
`;

const Text = styled(RickTextView)`
  font-size: 16px;
  line-height: 1.75;
  font-weight: normal;
`;

const ExperienceSection = styled.div`
  display: flex;
`;

const ExperienceBlock = styled.div`
  margin-left: 8px;
  margin-right: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  min-width: 128px;
`;

const ExperienceIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const ExperienceText = styled.p`
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: normal;
  margin-left: 8px;
`;

const SkillsSection = styled.div``;

const SkillItemContainer = styled.div`
  background-color: ${COLORS.BG_PAGE};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-bottom: 8px;
  margin-right: 8px;
  overflow: hidden;
`;

const SkillRow = styled.div`
  display: flex;
  min-height: 42px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  align-content: center;
  font-size: 14px;
  font-weight: 600;

  :hover {
    cursor: pointer;
  }
`;

const ProgressBar = styled.div`
  height: 2px;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: linear-gradient(
    to right,
    var(--company-color) ${(props) => props.$percentage}%,
    #f6f8fc 0%
  );
`;

function AboutRoleTab({ i18n, appTheme, jobProfile, onSkillClick }) {
  const [skillCategories, setSkillCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const [skillCategories, categoryOrder] = await Promise.all([
        getSkillCategories(),
        getCompanySkillCategories(jobProfile.company),
      ]);
      const orderedCategories = categoryOrder.map((c) => skillCategories[c]);
      setSkillCategories(orderedCategories);
    };
    fetchData();
  }, [jobProfile.company]);

  return (
    <>
      <Text html={jobProfile.description} />
      {jobProfile.careerLevel !== WORK_EXPERIENCE_LEVELS['1'].key &&
        jobProfile.educationLevel !== EDUCATION_LEVELS['1'].key && (
          <TextHeader>
            <Trans>Requirements</Trans>
          </TextHeader>
        )}

      <ExperienceSection>
        {jobProfile.careerLevel !== WORK_EXPERIENCE_LEVELS['1'].key && (
          <ExperienceBlock>
            <ExperienceIcon>
              <SvgIcon
                width="24px"
                height="22px"
                isDefaultColor
                defaultColor={appTheme.color}
                url={workIcon}
              />
            </ExperienceIcon>
            <ExperienceText>
              {jobProfile.careerLevel &&
                WORK_EXPERIENCE_LEVELS[jobProfile.careerLevel].shortName(i18n)}{' '}
              <Trans> work experience </Trans>
            </ExperienceText>
          </ExperienceBlock>
        )}

        {jobProfile.educationLevel !== EDUCATION_LEVELS['1'].key && (
          <ExperienceBlock>
            <ExperienceIcon>
              <SvgIcon
                width="26px"
                height="26px"
                isDefaultColor
                defaultColor={appTheme.color}
                url={hatIcon}
              />
            </ExperienceIcon>
            <ExperienceText>
              {jobProfile.educationLevel &&
                EDUCATION_LEVELS[jobProfile.educationLevel].shortName(i18n)}{' '}
              <Trans> degree </Trans>
            </ExperienceText>
          </ExperienceBlock>
        )}
      </ExperienceSection>
      {jobProfile.skills && (
        <SkillsSection>
          <TextHeader>
            <Trans>Relevant skills</Trans>
          </TextHeader>
          <RelevantSkillsSubheader>
            <Trans>
              These skills are relevant for the role. Click on the skill for more information.
            </Trans>
          </RelevantSkillsSubheader>
          <SkillCategory>
            {skillCategories.map((category) => {
              const skills = jobProfile.skills.filter(
                (skill) =>
                  skill.categories.includes(category.id) ||
                  (category.type &&
                    category.type === SKILL_CATEGORY_TYPE.soft &&
                    (skill.source === SKILL_SOURCE.learned ||
                      skill.source === SKILL_SOURCE.learned2)),
              );
              return skills.length ? (
                <div key={category.id}>
                  <CategoryTitle>{category.name}</CategoryTitle>
                  <SkillContainer>
                    {skills.map((skill, indexOfSkill) => {
                      const indexOfLevel = skill.numberOfEnabledLevels.indexOf(
                        Number(jobProfile.skillsLevels[skill.id]),
                      );
                      return skill ? (
                        <SkillItemContainer onClick={() => onSkillClick(skill)} key={indexOfSkill}>
                          <SkillRow>
                            <SkillName skill={skill}>{skill.name}</SkillName>
                          </SkillRow>
                          <ProgressBar $percentage={((indexOfLevel + 1) / skill.maxLevel) * 100} />
                        </SkillItemContainer>
                      ) : null;
                    })}
                  </SkillContainer>
                </div>
              ) : null;
            })}
          </SkillCategory>
        </SkillsSection>
      )}
    </>
  );
}

AboutRoleTab.propTypes = {
  onSkillClick: PropTypes.func,
  jobProfile: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    appTheme: state.appTheme.data,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(AboutRoleTab)));
