import React, { Dispatch, useMemo } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { ISection, SECTION_TYPE } from '~/components/SideBar';

import { COLORS } from '~/styles';

export interface ISectionProps extends ISection {
  title: string;
  index: number;
  isFirst?: boolean;
  isLast?: boolean;
  hideErrorState?: boolean;
  topLineColor?: string;
  bottomLineColor?: string;
  currentSection: number;
  setCurrentSection: Dispatch<number>;
}

const Title = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 12px;
  width: 100%;
  color: inherit;
  width: 216px;
  max-width: 216px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0 0 0 10px;
  width: 100%;
`;

const Circle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: inherit;
  z-index: 20;
`;

const Step = styled.span`
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
  white-space: nowrap;
`;

const Dot = styled.span`
  width: 6px;
  height: 6px;
  background-color: var(--company-color);
  border-radius: 100px;
`;

const Minus = styled.span`
  width: 6px;
  height: 1px;
  background-color: ${COLORS.WHITE};
`;

const Line = styled.div<{
  top?: string;
  bottom?: string;
  left?: string;
  color?: string;
}>`
  position: absolute;
  top: ${({ top }) => top || 'unset'};
  bottom: ${({ bottom }) => bottom || 'unset'};
  left: ${({ left }) => left || '16px'};
  width: 1px;
  z-index: 10;
  background-color: ${({ color }) => color || '#dcdcdc'};
`;

const Subsections = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 46px;
  padding-bottom: 30px;
  padding-top: 16px;
  & .item {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: -0.13px;
    color: ${COLORS.SUBTEXT};
  }
`;

const Card = styled.div<{ type: ISection['type'] }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 15px 21px 15px 10px;
  border-radius: 6px;
  color: ${COLORS.TEXT_MAIN};
  cursor: pointer;
  min-width: 316px;
  max-width: 316px;
  height: 50px;
  box-sizing: border-box;

  background-color: ${COLORS.BG_PAGE};
  &:hover {
    background-color: #f5f7fd;
    .step {
      color: ${COLORS.TEXT_HOVER};
    }
  }

  ${({ type }) =>
    !type &&
    css`
      & .circle {
        border: 1px solid ${COLORS.BORDERS};
        min-width: 14px;
        height: 14px;
      }
    `}

  ${({ type }) =>
    type &&
    type === SECTION_TYPE.ERROR &&
    css`
      color: ${COLORS.ACCENT_ERROR};
      & .circle {
        min-width: 14px;
        height: 14px;
        background-color: ${COLORS.ACCENT_ERROR};
        color: ${COLORS.WHITE};
      }
    `}

    ${({ type }) =>
    type &&
    type === SECTION_TYPE.CURRENT &&
    css`
      background-color: ${COLORS.WHITE};
      & .circle {
        background-color: inherit;
        border: 1px solid var(--company-color);
        min-width: 14px;
        height: 14px;
      }
    `}


    ${({ type }) =>
    type &&
    type === SECTION_TYPE.DONE &&
    css`
      & .circle {
        min-width: 14px;
        height: 14px;
        background-color: var(--company-color);
        margin-left: 1px;
      }
    `}
`;

const Section = ({
  title,
  type,
  topLineColor,
  bottomLineColor,
  fields,
  index,
  currentSection,
  setCurrentSection,
  hideErrorState,
}: ISectionProps) => {
  const { i18n } = useLingui();

  type = currentSection === index ? SECTION_TYPE.CURRENT : type;
  if (hideErrorState === true && type === SECTION_TYPE.ERROR) {
    type = undefined;
  }
  const step = useMemo(() => index + 1, [index]);

  return (
    <Wrapper>
      <Card type={type} onClick={() => setCurrentSection(index)}>
        {topLineColor && <Line color={topLineColor} top="-10px" bottom="50%" />}
        <Circle className="circle">
          {/* @ts-ignore */}
          {type === SECTION_TYPE.DONE && (
            <Icon icon={ICONS.CHECKMARK} size={8} color={COLORS.WHITE} />
          )}
          {type === SECTION_TYPE.CURRENT && <Dot />}
          {type === SECTION_TYPE.ERROR && <Minus />}
          {type === SECTION_TYPE.LOCKED && (
            <Icon icon={ICONS.LOCK} size={ICON_SIZES.SMALL} color={COLORS.PLACEHOLDERS} />
          )}
        </Circle>
        <Title>{title}</Title>
        <Step className="step">{i18n._(t`Step ${step}`)}</Step>
        {bottomLineColor && <Line color={bottomLineColor} top="50%" bottom="0" />}
      </Card>
      {!isEmpty(fields) && (
        <Subsections>
          {bottomLineColor && <Line color={bottomLineColor} top="0" bottom="0" />}
          {fields?.map((field) => (
            <div className="item" key={field}>
              {field}
            </div>
          ))}
        </Subsections>
      )}
    </Wrapper>
  );
};

export { Section };
