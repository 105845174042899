import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Modal from '~/components/Modal';
import Spinner from '~/components/Spinner';

import { COLOR_PALETTE } from '~/styles';

const Content = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  text-align: center;
  color: ${COLOR_PALETTE.BLACK};
  margin: 40px 0 17px 0;
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: ${COLOR_PALETTE.GRAY_MIDDLE};
`;

function WaitModal({ onModalClose, subtitle, marginTop }) {
  const { i18n } = useLingui();

  return (
    <Modal
      onClose={() => onModalClose()}
      minHeight={256}
      width={325}
      hideFooter
      isHideHeader
      showDivider={false}
      marginTop={marginTop}
      contentStyles={{ height: '256px' }}
    >
      <Content>
        <Spinner height="46px" />
        <Title>{i18n._(t`Please wait`)}...</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Content>
    </Modal>
  );
}

export default WaitModal;
