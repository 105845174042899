import React from 'react';

import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import { COLOR_PALETTE } from '~/styles';

const Wrapper = styled.div`
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin: 32px 0 20px 0;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: black;
`;

const Subtitle = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.57;
  color: ${COLOR_PALETTE.DARK_GRAY};
  text-align: center;
`;

const DashboardBoxPlaceholder = ({ title, subtitle, icon }) => {
  return (
    <Wrapper>
      <StyledSvgIcon
        width="32px"
        height="32px"
        url={icon}
        isDefaultColor={true}
        defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
      />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Wrapper>
  );
};

export default DashboardBoxPlaceholder;
