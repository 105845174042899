import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ToolTip from '~/components/Tooltip';
import { Avatar } from '~/components/UserAvatar/components/Avatar';
import { Avatars, AvatarsContainer, Counter, MultiContainer } from '~/components/UserAvatar/design';
import UserDashboardUsersModal from '~/components/UserDashboardUsersModal';
import { UserModal } from '~/components/UsersModal';

import { getDeletedUsers, getInactiveUsers, getUsers } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

import type { IUser } from '@learned/types';

export const StyledAvatar = styled(Avatar)`
  margin-right: -7px;

  border: solid 1px ${COLORS.BG_LIST};
`;

interface UserAvatarGroupProps {
  users: ({
    isDeactivated?: boolean;
    isDeleted?: boolean;
    isAnonymous?: boolean;
    // either we have a entire user object or just userID but never both
  } & ({ fullUser: IUser; userId?: never } | { fullUser?: never; userId: IUser['id'] }))[];
  options?: {
    maxUsers?: number;
    isCounterClickable?: boolean;
  };
  onClick?: (userId?: string) => void | undefined;
  isNewModal?: boolean;
}

function UserAvatarGroup({
  users,
  options = {},
  onClick,
  isNewModal = true,
}: UserAvatarGroupProps) {
  const [showUserModal, setShowUserModal] = useState(false);
  const { i18n } = useLingui();

  const deletedUsers = useSelector(getDeletedUsers);
  const activeUsers = useSelector(getUsers);
  const inactiveUsers = useSelector(getInactiveUsers);
  const populatedUsers = users.map((user) => {
    const deletedUser = deletedUsers?.[user.userId ?? user.fullUser.id];
    const activeUser = activeUsers?.[user.userId ?? user.fullUser.id];
    const inactiveUser = inactiveUsers?.[user.userId ?? user.fullUser.id];
    return {
      id: user.userId,
      isDeleted: user.isDeleted ?? (!!deletedUser && !activeUser),
      isDeactivated: user.isDeactivated ?? !!inactiveUser,
      isAnonymous: user.isAnonymous,
      ...(activeUser ?? inactiveUser ?? deletedUser),
      ...user.fullUser,
    };
  });

  if (!options.maxUsers || options.maxUsers < 3) {
    options.maxUsers = 3;
  }

  return (
    <>
      <MultiContainer>
        <AvatarsContainer>
          <Avatars>
            {populatedUsers.slice(0, options.maxUsers).map((user) => (
              <ToolTip key={user.id} tooltip={getUserFullName(user)}>
                <StyledAvatar
                  imgUrl={user?.avatarUrl}
                  isAnonymous={user.isAnonymous}
                  isDeleted={user.isDeleted}
                  onClick={onClick ? () => onClick(user.id) : undefined}
                />
              </ToolTip>
            ))}
          </Avatars>
        </AvatarsContainer>
        {users.length > options.maxUsers && (
          <Counter
            onClick={options?.isCounterClickable ? () => setShowUserModal(true) : undefined}
            $isCounterClickable={options?.isCounterClickable}
          >
            +{users.length - options.maxUsers}
          </Counter>
        )}
      </MultiContainer>
      {showUserModal && isNewModal && (
        <UserModal
          onClose={() => setShowUserModal(false)}
          users={users}
          title={i18n._(t`Members`)}
        />
      )}
      {showUserModal && !isNewModal && (
        <UserDashboardUsersModal
          onClose={() => setShowUserModal(false)}
          users={users}
          title={i18n._(t`Users`)}
          onClick={({ userId }: { userId: string }) => onClick?.(userId)}
        />
      )}
    </>
  );
}

export { UserAvatarGroup };
export type { UserAvatarGroupProps };
