import { ButtonHTMLAttributes, ReactNode } from 'react';

import { ICONS, ICON_SIZES } from '../Icon';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: ReactNode;
  variant: ButtonVariant;
  size: ButtonSize;
  icon?: ICONS;
  iconSize?: ICON_SIZES;
  isLoading?: boolean;
  counter?: number;
  dropDownIcon?: DropdownIconState;
}

export interface IStyledButtonProps {
  size: ButtonSize;
  isLoading?: boolean;
}

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SQUARE = 'square',
  TEXT_PRIMARY = 'text-primary',
  TEXT_DELETE = 'text-delete',
  NAVIGATION_PRIMARY = 'navigation_primary',
  NAVIGATION_SECONDARY = 'navigation_secondary',
  CLOSE = 'close',
  ICON = 'icon',
  ICON_DELETE = 'icon_delete',
}

export enum ButtonSize {
  BIG = 'big',
  MEDIUM = 'medium',
}

export enum DropdownIconState {
  UP = 'up',
  DOWN = 'down',
  NONE = 'none',
}
