import React, { Component } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  BarController,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import PropTypes from 'prop-types';
import { Chart } from 'react-chartjs-2';

ChartJS.register(CategoryScale, BarController, LinearScale, BarElement, Title, Tooltip, Legend);

class HorizontalBarChart extends Component {
  static propTypes = {
    height: PropTypes.number,
    data: PropTypes.object,
    options: PropTypes.object,
    onClickToSkill: PropTypes.func,
  };

  static defaultProps = {
    height: 30,
    data: {},
    options: {},
    onClickToSkill: () => {},
  };
  render() {
    const { data, options, height } = this.props;

    return (
      <Chart
        type="bar"
        height={height}
        data={data}
        options={{
          ...options,
          ...{ indexAxis: 'y' },
        }}
      />
    );
  }
}

export default HorizontalBarChart;
