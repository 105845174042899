import { QUESTION_TYPES, REVIEW_SECTION_THEMES, KPI_TYPES, KPI_NONE } from '@learned/constants';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import mergeWith from 'lodash/mergeWith';
import nanoId from 'nanoid';

import { REVIEW_OPTIONS } from '~/constants';
import createQuestionName from '~/utils/createQuestionName';
import getDuplicatesInArray from '~/utils/getDuplicatesInArray';
import reorderArray from '~/utils/reorderArray';

export function fillSectionsByIds(sections) {
  return (sections || []).map((s) => ({
    ...s,
    id: nanoId(),
    questions: (s.questions || []).map((q) => ({
      ...q,
      id: nanoId(),
    })),
  }));
}

export function prepareSections(sections) {
  return sections.map(({ id: _id, isSkippable, scaleLabels, ...s }) => {
    const isNoneKpi = s.type === KPI_NONE.type && s.kpi === KPI_NONE.id;
    const isSkillsKpi = s.type === KPI_TYPES.SKILLS;

    const section = {
      ...s,
      questions: (s.questions || []).map(({ id: _id, ...q }) => q),
      kpi: isNoneKpi ? null : s.kpi, // replace 'none' with null for DB validation
    };

    if (!isSkillsKpi && !isNoneKpi) {
      section.scaleLabels = scaleLabels;
      section.isSkippable = isSkippable;
    }

    return section;
  });
}

export function isQuestionTypeExist(sections, type) {
  if (!type) {
    return false;
  }

  return (sections || []).some((section) =>
    (section.questions || []).some((question) => question.type === type),
  );
}

export function removeQuestionFromSection(section, questionId) {
  return {
    ...section,
    questions: (section.questions || []).filter((q) => q.id !== questionId),
  };
}

export function patchSectionQuestion(section, questionId, patchFn) {
  return {
    ...section,
    questions: section.questions.map((q) => {
      if (q.id === questionId) {
        return patchFn(q);
      }
      return q;
    }),
  };
}

export function insertQuestionIntoSection(section, question, insertIndex) {
  const questions = section.questions || [];
  const index = typeof insertIndex === 'undefined' ? questions.length : insertIndex;
  const newArr = [].concat(questions);
  newArr.splice(index, 0, question);

  return {
    ...section,
    questions: newArr,
  };
}

export function reorderQuestionsInSection(
  sections,
  sectionId,
  sourceQuestionIndex,
  destinationQuestionIndex,
) {
  return sections.map((s) => {
    if (s.id === sectionId) {
      return {
        ...s,
        questions: reorderArray(s.questions || [], sourceQuestionIndex, destinationQuestionIndex),
      };
    }
    return s;
  });
}

export function changeQuestionOrderInSections(
  sections,
  sectionSourceId,
  sectionDestinationId,
  sourceQuestionIndex,
  destinationQuestionIndex,
) {
  if (
    sectionSourceId === sectionDestinationId &&
    sourceQuestionIndex === destinationQuestionIndex
  ) {
    return sections;
  }

  // action in one section
  if (sectionSourceId === sectionDestinationId) {
    return reorderQuestionsInSection(
      sections,
      sectionSourceId,
      sourceQuestionIndex,
      destinationQuestionIndex,
    );
  } else {
    const sourceSection = find(sections, (s) => s.id === sectionSourceId);
    const question = sourceSection.questions[sourceQuestionIndex];

    return sections.map((s) => {
      if (s.id === sectionDestinationId) {
        return insertQuestionIntoSection(s, question, destinationQuestionIndex);
      }

      if (s.id === sectionSourceId) {
        return removeQuestionFromSection(s, question.id);
      }

      return s;
    });
  }
}

export function createNewSection() {
  return {
    id: nanoId(),
    name: '',
    questions: [],
    type: KPI_NONE.type,
    kpi: KPI_NONE.id,
    [REVIEW_OPTIONS.IS_SELF_REVIEW.key]: true,
    [REVIEW_OPTIONS.IS_USERS_REVIEW.key]: true,
    [REVIEW_OPTIONS.IS_COACHES_REVIEW.key]: true,
    theme: REVIEW_SECTION_THEMES.DEFAULT,
  };
}

export function createJobProfileQuestion(i18n) {
  return {
    id: nanoId(),
    type: QUESTION_TYPES.JOB_PROFILE,
    name: createQuestionName({
      type: QUESTION_TYPES.JOB_PROFILE,
      i18n,
    }),
    isSkippable: true,
    isCommentObligated: true,
    skillCategories: [],
  };
}

function mergeFlags(flags) {
  function customizer(first, second) {
    return first || second;
  }
  return flags.reduce((com, current) => mergeWith(com, current, customizer), {});
}

function validateQuestion(question) {
  const isNoTitle = !question.name;
  const isHasDuplicates = getDuplicatesInArray(question.scaleLabels).length > 0;
  const isSomeLabelEmpty =
    (question.scaleLabels || []).length > 0 && question.scaleLabels.some((sl) => isEmpty(sl));

  return {
    isInvalid: isNoTitle || isHasDuplicates || isSomeLabelEmpty,
    isNoTitle,
    isHasDuplicates,
    isSomeLabelEmpty,
  };
}

function validateSection(section) {
  const isNoTitle = !section.name;
  const isNoQuestions = isEmpty(section.questions);
  const isWithoutReviewType =
    !section[REVIEW_OPTIONS.IS_SELF_REVIEW.key] &&
    !section[REVIEW_OPTIONS.IS_USERS_REVIEW.key] &&
    !section[REVIEW_OPTIONS.IS_COACHES_REVIEW.key];

  const questionsValidation = mergeFlags(
    (section.questions || []).map(validateQuestion).filter((v) => v.isInvalid),
  );

  return {
    isNoTitle,
    isNoQuestions,
    isWithoutReviewType,
    isQuestionWithoutTitle: questionsValidation.isNoTitle,
    isQuestionWithDuplicateLabels: questionsValidation.isHasDuplicates,
    isQuestionWithEmptyLabels: questionsValidation.isSomeLabelEmpty,
  };
}

export function validateTemplate(template) {
  const isEmptySections = isEmpty(template.sections);
  const sectionsValidation = mergeFlags(template.sections.map(validateSection));
  const isFieldsFilled =
    template.templateName &&
    template.templateDescription &&
    !sectionsValidation.isNoTitle &&
    !sectionsValidation.isQuestionWithoutTitle;

  return {
    isFieldsFilled,
    isEmptySections,
    isSectionWithoutQuestions: sectionsValidation.isNoQuestions,
    isQuestionWithDuplicateLabels: sectionsValidation.isQuestionWithDuplicateLabels,
    isQuestionWithEmptyLabels: sectionsValidation.isQuestionWithEmptyLabels,
    isSectionWithoutReviewType: sectionsValidation.isWithoutReviewType,
    isQuestionWithoutTitle: sectionsValidation.isQuestionWithoutTitle,
    isValid: ![
      isEmptySections,
      !isFieldsFilled,
      sectionsValidation.isQuestionWithEmptyLabels,
      sectionsValidation.isNoQuestions,
      sectionsValidation.isQuestionWithDuplicateLabels,
      sectionsValidation.isWithoutReviewType,
    ].some(Boolean),
  };
}
