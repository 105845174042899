export const mapCompanyDefaultFocusAreas = (defaultFocusAreas, skillName) => {
  const focusAreas = [];

  defaultFocusAreas.forEach((defaultFALevel, level) => {
    defaultFALevel.forEach((defaultFA, index) => {
      focusAreas.push({
        key: `${skillName}_${level}_${index}`,
        level: level + 1,
        name: defaultFA.replace('%SKILLNAME%', skillName),
      });
    });
  });

  return focusAreas;
};

export const mapLevelsEnabled = (focusAreas) => {
  const levelsEnabled = new Array(Math.max(...focusAreas.map((fa) => fa.level))).fill(false);
  focusAreas.forEach((fa) => {
    levelsEnabled[fa.level - 1] = true;
  });

  return levelsEnabled;
};
