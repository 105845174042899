import React, { useEffect, useState } from 'react';

import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';

import RickTextView from '~/components/RickTextView';
import SkillsView from '~/components/SkillsView';
import SvgIcon from '~/components/SvgIcon';

import briefcaseIcon from '~/assets/briefcase.svg';
import graduationHat from '~/assets/graduation-hat.svg';

import { SKILL_SOURCE, SKILL_TYPE } from '~/constants/skills';
import useBoolState from '~/hooks/useBoolState';
import { getCompanySkillCategories } from '~/services/companySettings';
import { getSkillCategories } from '~/services/skillCategories';
import { COLOR_PALETTE } from '~/styles';

const InfoTabContainer = styled.div`
  padding: 24px;
`;

const Skills = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableCardHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  min-height: 64px;
  align-items: center;
`;

const DescriptionTitle = styled.div`
  margin: 8px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: #000000;
`;

const TableCardTitle = styled.div`
  margin-top: 44px;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
`;

const SubHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: #000000;
  margin-top: 16px;
`;

const SkillHeader = styled.div`
  text-transform: uppercase;
  margin: 12px 0 12px 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const Text = styled(RickTextView)`
  font-size: 16px;
  line-height: 1.75;
  font-weight: normal;
`;

const ExperienceWrapper = styled.div`
  margin-top: 32px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: #000000;
  display: flex;
  flex-direction: row;
`;

const WorkExperienceWrapper = styled.div`
  margin-right: 56px;
  align-content: left;
`;

const ExperienceText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.53px;
  color: #000000;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin-right: 8px;
`;

const EducationExperienceWrapper = styled.div`
  align-content: left;
`;

const JobProfileRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const JobProfileDescriptionRow = styled(JobProfileRow)``;

const SkillsViewWrapper = styled.div``;

const InfoTab = ({ jobProfile, i18n, experience, education, onUpdateSkill, onRemoveSkill }) => {
  const [categories, setCategories] = useState([]);
  const $isLoading = useBoolState(true);

  useEffect(() => {
    const fetchData = async () => {
      const [skillCategories, categoryOrder] = await Promise.all([
        getSkillCategories(),
        getCompanySkillCategories(jobProfile.company),
      ]);
      const orderedCategories = categoryOrder.map((c) => skillCategories[c]);
      setCategories(orderedCategories);
      $isLoading.off();
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const renderSkillsBlock = ({ skills, skillsLevels }) => {
    skills.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <SkillsViewWrapper>
        <SkillsView
          skills={skills}
          skillsLevels={skillsLevels}
          onUpdateSkill={onUpdateSkill}
          onRemoveSkill={onRemoveSkill}
        />
      </SkillsViewWrapper>
    );
  };

  const renderSkills = () => {
    const skillsLevels = (jobProfile && jobProfile.skillsLevels) || [];
    return (
      <Skills>
        <TableCardHeader>
          <TableCardTitle>
            <Trans>Required skills</Trans>
          </TableCardTitle>
        </TableCardHeader>

        {categories.map((category) => {
          const skills = jobProfile.skills.filter(
            (skill) =>
              (skill.categories && skill.categories.includes(category.id)) ||
              (category.type &&
                category.type === SKILL_TYPE.soft &&
                (skill.source === SKILL_SOURCE.learned || skill.source === SKILL_SOURCE.learned2)),
          );
          return skills.length ? (
            <>
              <SkillHeader>
                <Trans>{category.name}</Trans>
              </SkillHeader>
              {renderSkillsBlock({
                skills,
                skillsLevels,
              })}
            </>
          ) : null;
        })}
      </Skills>
    );
  };

  return (
    <InfoTabContainer>
      <JobProfileDescriptionRow>
        <DescriptionTitle>Description</DescriptionTitle>
        <Text
          html={
            jobProfile.description
              ? jobProfile.description
              : i18n._(
                  t`Example: What are the main responsibilities for this job? (the required skills you can add later).`,
                )
          }
        />
      </JobProfileDescriptionRow>
      <ExperienceWrapper>
        <WorkExperienceWrapper>
          <SubHeader>
            <Trans>Work experience</Trans>
          </SubHeader>
          <ExperienceText>
            <StyledSvgIcon
              width="20px"
              height="20px"
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              url={briefcaseIcon}
            />
            {experience}
          </ExperienceText>
        </WorkExperienceWrapper>
        <EducationExperienceWrapper>
          <SubHeader>
            <Trans>Educational level</Trans>
          </SubHeader>
          <ExperienceText>
            <StyledSvgIcon
              styles={{
                marginRight: 8,
              }}
              width="20px"
              height="20px"
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
              url={graduationHat}
            />
            {education}
          </ExperienceText>
        </EducationExperienceWrapper>
      </ExperienceWrapper>
      {renderSkills()}
    </InfoTabContainer>
  );
};

export default InfoTab;
