import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, RESPONSE_STATUSES } from '@learned/constants';
import PropTypes from 'prop-types';

import AddActivitiesModal from '~/components/Modals/AddActivitiesModal';

import useBoolState from '~/hooks/useBoolState';
import { getCompanyActivities } from '~/services/activities';

const PAGE_SIZE = 23;
const defaultPagination = { skip: 0, limit: PAGE_SIZE, index: 1 };
const defaultFilters = {};
const defaultResponseData = {
  [API_RETURN_FIELDS.ACTIVITIES]: [],
  [API_RETURN_FIELDS.TOTAL]: 0,
  [API_RETURN_FIELDS.TABS_ITEMS_TOTAL]: {
    recommended: 0,
    all: 0,
  },
};

const AddActivitiesFromCompanyLibraryModal = ({
  onSubmit,
  onClose,
  recommendedForRoles,
  hideActivities,
}) => {
  const $loading = useBoolState(true);
  const [responseData, setResponseData] = useState(defaultResponseData);
  const [filters, setFilters] = useState(defaultFilters);
  const [pagination, setPagination] = useState(defaultPagination);

  // init fetch
  // filters change fetch
  // pagination
  // on tab change
  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      const response = await getCompanyActivities({
        filters: {
          search: filters?.search || '',
          types: filters?.activityTypes || [],
          categories: (filters?.activityCategories || []).map((category) => category.id),
          skills: (filters?.skills || []).map((skill) => skill.id),
        },
        options: {
          skip: pagination.skip,
          limit: pagination.limit,
        },
        isRecommended: false, // display only all activities
        recommendedForRoles,
        hideActivities,
      });

      if (response?.status === RESPONSE_STATUSES.SUCCESS && isMounted) {
        setResponseData(response.data);
      }

      if (isMounted) {
        $loading.off();
      }
    };

    fetch();

    return () => (isMounted = false);

    // eslint-disable-next-line
  }, [filters, pagination]);

  const resetPagination = () => {
    setPagination(defaultPagination);
  };

  const updateFilters = (obj) => {
    setFilters({ ...filters, ...obj });

    // reset pagination
    resetPagination();
  };

  return (
    <AddActivitiesModal
      loading={$loading.value}
      activities={responseData.activities}
      onSubmit={onSubmit}
      onClose={onClose}
      filters={filters}
      updateFilters={updateFilters}
      pagination={pagination}
      setPagination={setPagination}
      total={responseData.total}
    />
  );
};

AddActivitiesFromCompanyLibraryModal.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  recommendedForRoles: PropTypes.arrayOf(PropTypes.string),
  hideActivities: PropTypes.arrayOf(PropTypes.string),
};

AddActivitiesFromCompanyLibraryModal.propTypes = {
  hideActivities: [],
  recommendedForRoles: [],
};

export default AddActivitiesFromCompanyLibraryModal;
