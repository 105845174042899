import React, { useEffect, useState } from 'react';

import {
  ROLES,
  REPORT_TYPES,
  ENGAGEMENT_REPORT_CHART_TYPES,
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ConversationModuleBox from '~/components/AdminDashboardBoxes/ConversationModuleBox';
import PerformanceModuleBox from '~/components/AdminDashboardBoxes/PerformanceModuleBox';
import SkillCoverageModuleBox from '~/components/AdminDashboardBoxes/SkillCoverageModuleBox';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import OverviewHeading from '~/components/OverviewHeading';
import SelectDropDown from '~/components/SelectDropDown';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import Shadow from '~/components/UI/Shadow';
import BaseLayout from '~/layouts/BaseLayout';

import Card, { ETypes } from './Card';
import {
  Wrapper,
  ModuleRow,
  CardWrapper,
  ViewReport,
  CustomIcon,
  PageEndCtr,
} from './DashboardStyles';

import routes from '~/constants/routes';
import { checkModuleSurvey } from '~/selectors/baseGetters';
import { getEngagementCharts } from '~/services/reports';
import { COLORS, MENU } from '~/styles';
import { toFixed } from '~/utils/math';
import * as reportsUtil from '~/utils/reports';

const ModuleBox = styled(Shadow)`
  ${(props) => !props.isWithoutPadding && 'padding: 16px 24px 24px 24px;'};
  width: ${(props) => props.width || '100%'};
  box-sizing: border-box;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  background-color: ${COLORS.WHITE};
`;

const PADDINGS = 48; // 24px + 24px left and right in BOX
const MARGINS = 24; // 12px + 12px
const LAYOUTPADDING = 68; // 2 * 34px

const MONTH_OPTIONS = [
  { key: '12', title: (i18n) => i18n._(t`Last 12 months`) },
  { key: '24', title: (i18n) => i18n._(t`Last 24 months`) },
  { key: '36', title: (i18n) => i18n._(t`Last 36 months`) },
];

const AdminDashboard = ({ i18n }) => {
  const getWidth = (graphsInRow) =>
    `min(100%, calc((100vw - (${MENU.width}px + ${LAYOUTPADDING}px)) / ${graphsInRow} - ${
      (2 - graphsInRow) * PADDINGS
    }px - ${(graphsInRow - 1) * MARGINS}px));`;

  const graphsInThirdRow = 1;

  const [monthSelected, setMonthSelected] = useState();
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const [isLoading, setIsLoading] = useState(true);
  const [timePrimaryData, setTimePrimaryData] = useState([]);
  const [average, setAverage] = useState(0);
  const [deviation, setDeviation] = useState(0);
  const [scoreData, setScoreData] = useState({
    bestScore: [],
    lowestScore: [],
  });
  const { addToast } = useToasts();

  const onMonthChange = (value) => {
    const selectedOption = MONTH_OPTIONS.find((option) => option.key === value);
    if (selectedOption) {
      setMonthSelected(selectedOption);
      localStorage.setItem('reports-SelectedTimeFrameOfDashboard', value);
    }
  };

  const getTrend = (value) => {
    if (value === 'DOWN' || value === 'down') {
      return 'down';
    }
    if (value === 'UP' || value === 'up') {
      return 'up';
    }
    return 'noChange';
  };

  const getChartData = async () => {
    try {
      setIsLoading(true);
      const [chartData, themeData] = await Promise.all([
        getEngagementCharts({
          viewAs: ROLES.ADMIN,
          reportType: REPORT_TYPES.ENGAGEMENT,
          chartType: ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_BY_MONTH,
          dateRange: reportsUtil.getDateForTimeFrame(monthSelected.key || MONTH_OPTIONS[0].key),
        }),
        getEngagementCharts({
          viewAs: ROLES.ADMIN,
          reportType: REPORT_TYPES.ENGAGEMENT,
          chartType: ENGAGEMENT_REPORT_CHART_TYPES.ENGAGEMENT_THEME_RANK,
          primaryDimension: ENGAGEMENT_REPORT_CHART_DIMENSIONS.THEME,
          dateRange: reportsUtil.getDateForTimeFrame(monthSelected.key || MONTH_OPTIONS[0].key),
        }),
      ]);
      setScoreData({
        bestScore: themeData?.data?.themeRank?.top.map((item) => ({
          title: item.name,
          value: toFixed(item.value || 0, 2),
          trend: getTrend(item.trend),
          deviation: item.deviation,
        })),
        lowestScore: themeData?.data?.themeRank?.bottom.map((item) => ({
          title: item.name,
          value: toFixed(item.value || 0, 2),
          trend: getTrend(item.trend),
          deviation: item.deviation,
        })),
      });
      const series = chartData?.data?.averageEngagement?.series || [];
      setAverage(chartData?.data?.averageEngagement?.average || 0);
      setDeviation(chartData?.data?.averageEngagement?.deviation || 0);
      const tempSeries = series.map((item) => ({
        key: moment(item.month).format('MMM'),
        value: toFixed(item.value || 0, 2),
        deviation: item.deviation || 0,
      }));
      const filteredValues = series.filter((item) => item.value);
      setTimePrimaryData(filteredValues.length > 0 ? tempSeries : []);
    } catch (_error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timeFrameSelected = localStorage.getItem('reports-SelectedTimeFrameOfDashboard');
    const selectedOption = MONTH_OPTIONS.find((option) => option.key === timeFrameSelected);
    setMonthSelected(selectedOption || MONTH_OPTIONS[0]);
  }, []);

  useEffect(() => {
    if (isModuleSurveyEnabled && monthSelected) {
      getChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModuleSurveyEnabled, monthSelected]);

  return (
    <div>
      <OverviewHeading
        title={i18n._(t`Dashboard`)}
        description={i18n._(t`An overview of your organisation`)}
        useSearch={false}
      >
        {isModuleSurveyEnabled && (
          <PageEndCtr>
            <SelectDropDown
              options={MONTH_OPTIONS}
              value={monthSelected?.key}
              onChange={onMonthChange}
              keyName="key"
              borderColor={COLORS.BORDERS}
              listMarginTop="8px"
              small
              labelStyle={{
                fontSize: '12px',
              }}
              optionStyles={{
                fontSize: '12px',
              }}
              label={i18n._(t`Select`)}
              renderLabel={(item) => <div>{item.title(i18n)}</div>}
              width="200px"
              listHeight="226px"
            />

            <ViewReport
              onClick={() => {
                routes.REPORTS_ADMIN_ENGAGEMENT.go(
                  // @ts-ignore
                  { role: ROLES.ADMIN },
                );
              }}
            >
              {i18n._(t`View Report`)}
              <CustomIcon icon={ICONS.NEXT} size={ICON_SIZES.SMALL} />
            </ViewReport>
          </PageEndCtr>
        )}
      </OverviewHeading>

      <BaseLayout noMaxWidth>
        <Wrapper>
          {isModuleSurveyEnabled && (
            <CardWrapper>
              <Card
                title={i18n._(t`Average engagement`)}
                chartData={timePrimaryData}
                deviation={deviation}
                average={average}
                isLoading={isLoading}
              />
              <Card
                title={i18n._(t`Best scoring themes`)}
                type={ETypes.BAR_CHART}
                data={scoreData.bestScore}
                isLoading={isLoading}
              />
              <Card
                title={i18n._(t`Lowest scoring themes`)}
                type={ETypes.BAR_CHART}
                data={scoreData.lowestScore}
                oppositeGradient
                bColor={COLORS.CONFIRMATION_MODAL_DELETE}
                isLoading={isLoading}
              />
            </CardWrapper>
          )}
          <ModuleRow>
            <ModuleBox width="49%">
              <SkillCoverageModuleBox i18n={i18n} />
            </ModuleBox>
            <ModuleBox width="49%">
              <PerformanceModuleBox i18n={i18n} />
            </ModuleBox>
          </ModuleRow>
          <ModuleRow>
            <ModuleBox>
              <ConversationModuleBox i18n={i18n} width={getWidth(graphsInThirdRow)} />
            </ModuleBox>
          </ModuleRow>
        </Wrapper>
      </BaseLayout>
    </div>
  );
};

export default withI18n()(AdminDashboard);
