import { createAction } from 'redux-act';

import { ROLES } from '~/constants';

export const updateSelectedCompany = createAction('Update selected company');
export const updateSelectedTeam = createAction('Update selected team');
export const updateSelectedRole = createAction('Update selected role');

export const initializeSelection = (clear = false) => {
  return function (dispatch, getStore) {
    if (clear) {
      dispatch(updateSelectedRole());
      dispatch(updateSelectedCompany());
      dispatch(updateSelectedTeam());
      return;
    }
    const { selected } = getStore();
    const selectedRole = selected.role || localStorage.getItem('selectedRole') || ROLES.USER;
    dispatch(updateSelectedRole(selectedRole));

    const selectedCompany = selected.company || localStorage.getItem('selectedCompany');
    if (selectedCompany) {
      dispatch(updateSelectedCompany(selectedCompany));
    }

    const selectedTeam = selected.team || localStorage.getItem('selectedTeam');

    if (selectedTeam && selectedRole !== ROLES.USER) {
      dispatch(updateSelectedTeam(selectedTeam));
    }
  };
};
