import { GOAL_TYPES } from '@learned/constants';
import { IGoalCycle, IGoal } from '@learned/types';
import map from 'lodash/map';

import { filterGoalsByType } from '~/utils/goalUtils';

export function GoalCycleIDMapper(goalCycle: IGoalCycle) {
  return goalCycle.id;
}

export function getGoalCyclesIDs(goalCycles: IGoalCycle[] | Record<string, IGoalCycle>) {
  return map(goalCycles, GoalCycleIDMapper);
}

export function getGoalCyclesWithoutGoals(goalCycles: IGoalCycle[], goals: IGoal[]) {
  return goalCycles.filter(
    (cycle) => !goals.some((g) => (g.goalCycles || []).indexOf(GoalCycleIDMapper(cycle)) !== -1),
  );
}

export function getGoalCyclesWithoutGoalsOfSomeType(
  goalCycles: IGoalCycle[],
  goals: IGoal[],
  goalType: GOAL_TYPES,
) {
  const goalsFilteredByType = filterGoalsByType(goals, goalType);
  return getGoalCyclesWithoutGoals(goalCycles, goalsFilteredByType);
}

export function joinGoalCyclesNames(goalCycles: IGoalCycle[] | Record<string, IGoalCycle>): string {
  return map(goalCycles, (c: IGoalCycle) => c.name).join(', ');
}
