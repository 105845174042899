export enum COMPANIES_SORT_FILTER_OPTIONS {
  A_Z = 'a-z',
  Z_A = 'z-a',
}

export enum ProductStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  TRIAL_ACTIVE = 'trialActive',
  TRIAL_NOT_STARTED = 'trialNotStarted',
  TRIAL_ENDED = 'trialEnded',
}

export enum ProductName {
  JOB_MATRIX = 'jobMatrix',
  CAREER = 'career',
  PERFORMANCE = 'performance',
  ENGAGEMENT = 'engagement',
  TALENT = 'talent',
  LEARNING_AND_ONBOARDING = 'learningAndOnboarding',
  INTEGRATIONS = 'integrations',
}
