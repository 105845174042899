import React, { useCallback, useEffect, useState } from 'react';

import { CAREER_PLAN_STATUSES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';

import { getColumns } from './columns';
import { Header, Footer, StyledButton, StyledTableList } from './design';

import routes from '~/constants/routes';
import { usePagination } from '~/hooks/usePagination';
import { checkModuleColleaguesSearch, getInactiveUsers, getUsers } from '~/selectors/baseGetters';
import { getCareerPlans } from '~/services/careerPlans';
import getUserFullName from '~/utils/getUserFullName';

import type { ICareerPlan, IUser } from '@learned/types';

export type User = IUser & { role: string; fullName: string };

export enum SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  ROLE_A_Z = 'ROLE_A_Z',
  ROLE_Z_A = 'ROLE_Z_A',
}

type Props = {
  users: ({
    isDeactivated?: boolean;
    isDeleted?: boolean;
    isAnonymous?: boolean;
  } & ({ fullUser: IUser; userId?: never } | { fullUser?: never; userId: IUser['id'] }))[];
  onClose: () => void;
  title: string;
  role?: ROLES;
};

type CareerPlan = Omit<ICareerPlan, 'createdFor'> & {
  createdFor: IUser;
};

const UserModal = ({ users = [], onClose, title, role = ROLES.USER }: Props) => {
  const [search, setSearch] = useState<string>('');
  const { pagination, changePagination, resetPagination } = usePagination(5);
  const [sortBy, setSortBy] = useState<string>(SORT_OPTIONS.NAME_A_Z);
  const [totalUsers, setTotalUsers] = useState<Array<User>>([]);
  const [filteredUsers, setFilteredUsers] = useState<Array<User>>([]);
  const [usersNotPaginated, setUserNotPaginated] = useState<Array<User>>([]);
  const allUsers = { ...useSelector(getUsers), ...useSelector(getInactiveUsers) };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isModuleColleaguesSearchEnabled = useSelector(checkModuleColleaguesSearch);

  const history = useHistory();
  const { i18n } = useLingui();

  const mapUsers = useCallback(
    (careerPlans: Array<CareerPlan>) => {
      return users.map((user) => {
        const careerPlan = careerPlans.find((plan) => plan.createdFor.id === user.userId);
        if (careerPlan) {
          return {
            ...careerPlan.createdFor,
            role: careerPlan.name,
            fullName: getUserFullName(careerPlan.createdFor),
          };
        }
        return {
          ...allUsers[user.userId!],
          role: '',
          fullName: getUserFullName(allUsers[user.userId!]),
        };
      });
    },
    // eslint-disable-next-line
    [JSON.stringify(users)],
  );

  const fetchCareerPlans = useCallback(async () => {
    setIsLoading(true);
    const data = await getCareerPlans(
      { status: CAREER_PLAN_STATUSES.CURRENT },
      { populate: ['createdFor'] },
    );
    const usersWithRoles = mapUsers(Object.values(data));
    setTotalUsers(usersWithRoles);
    setFilteredUsers(usersWithRoles);
    setIsLoading(false);

    // eslint-disable-next-line
  }, [JSON.stringify(users)]);

  const sortAndPaginateUsers = useCallback((): void => {
    let sortedUsers = totalUsers;
    switch (sortBy) {
      case SORT_OPTIONS.NAME_A_Z:
        sortedUsers = _sortBy(sortedUsers, (obj) => obj?.fullName?.toLowerCase());
        break;
      case SORT_OPTIONS.NAME_Z_A:
        sortedUsers = _sortBy(sortedUsers, (obj) => obj?.fullName?.toLowerCase()).reverse();
        break;
      case SORT_OPTIONS.ROLE_A_Z:
        sortedUsers = _sortBy(sortedUsers, (obj) => obj?.role?.toLowerCase());
        break;
      case SORT_OPTIONS.ROLE_Z_A:
        sortedUsers = _sortBy(sortedUsers, (obj) => obj?.role?.toLowerCase()).reverse();
    }

    if (search) {
      sortedUsers = sortedUsers.filter((user) =>
        user?.fullName?.toLowerCase().includes(search.toLowerCase()),
      );
    }

    setUserNotPaginated(sortedUsers);

    const startIndex = (pagination.index - 1) * pagination.limit;
    sortedUsers = slice(sortedUsers, startIndex, startIndex + pagination.limit);

    setFilteredUsers(sortedUsers);
  }, [pagination, search, sortBy, totalUsers]);

  const handleResetFilter = (): void => {
    setSearch('');
    resetPagination();
  };

  const navigateToUserProfile = (user: User) => {
    history.push(
      // @ts-ignore
      routes.USER_PUBLIC_SKILL_PASSPORT.build({ role: ROLES.USER }, { userId: user.id }),
    );
  };

  useEffect(() => {
    fetchCareerPlans();
  }, [fetchCareerPlans]);

  useEffect(() => {
    sortAndPaginateUsers();
  }, [sortBy, isLoading, pagination, totalUsers, search, sortAndPaginateUsers]);

  return (
    <Modal
      onClose={onClose}
      width={500}
      minWidth={'500px'}
      title={title}
      minHeight={'448px'}
      contentStyles={{ padding: '25px 25px 32px 25px', margin: '0' }}
      isHideHeader
      hideFooter
      showDivider={false}
    >
      <Header>{title}</Header>
      <StyledTableList
        columns={getColumns(role)}
        data={filteredUsers}
        filtersProps={{
          filters: {
            search,
            setSearch,
          },
          isFiltered: !!search,
          resetFilters: handleResetFilter,
        }}
        paginationProps={{
          pagination,
          changePagination,
          totalCount: usersNotPaginated.length,
        }}
        sortProps={{ sortBy, setSortBy }}
        isLoading={isLoading}
        onRowClick={isModuleColleaguesSearchEnabled ? navigateToUserProfile : undefined}
      />
      <Footer>
        <StyledButton
          onClick={onClose}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          label={i18n._(t`Close`)}
        />
      </Footer>
    </Modal>
  );
};

export { UserModal };
