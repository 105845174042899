import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';
import { Header6 } from '~/components/UI/Typographics/headers';

const BoardItem = styled(BoxWithBorder)`
  max-width: 200px;
  padding: 12px 20px;
  box-sizing: border-box;
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${Header6} {
    margin: 0;
  }
`;

export default BoardItem;
