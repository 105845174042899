import React, { Fragment, ReactNode } from 'react';

import styled from 'styled-components';

import Button from '~/components/Button';

import { SectionInfo } from './SectionInfo';
import { SectionNameAndDescription } from './SectionNameAndDescription';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { COLORS } from '~/styles';

import type { IUserSurveyPopulated } from '@learned/types';

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 31px;
`;

const StyledButton = styled(Button)`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  min-width: 131px;
  padding: 0 12px;
`;

export const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  max-width: 750px;
  border-radius: 6px;
  padding: 26px 40px 38px;
  margin: 32px auto 0;
  box-sizing: border-box;
`;

const InfoWrapper = styled(Wrapper)`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 24px;
`;

interface ISetupProps {
  userSurvey: IUserSurveyPopulated;
  buttonLabel: ReactNode;
  isShowRedirectToAnswersButton: boolean | undefined;
}

const Setup = ({ userSurvey, buttonLabel, isShowRedirectToAnswersButton }: ISetupProps) => {
  return (
    <Fragment>
      <Wrapper>
        <SectionNameAndDescription
          name={userSurvey.survey.local?.name as string}
          description={userSurvey.survey.local?.description}
        />
      </Wrapper>
      <InfoWrapper>
        <SectionInfo
          deadline={userSurvey.survey.dates.endDate}
          isAnonymous={userSurvey.isAnonymous as boolean}
          amountOfQuestions={userSurvey.amountOfQuestions as number}
        />
      </InfoWrapper>
      {isShowRedirectToAnswersButton && (
        <ButtonRow>
          <StyledButton
            // @ts-ignore
            type={'primary-shadow'}
            label={buttonLabel}
            onClick={() => {
              routes.SURVEY_TASK.go(
                { role: ROLES.USER },
                { surveyTaskId: userSurvey.surveyTaskId, isBackPath: true },
              );
            }}
            height={40}
          />
        </ButtonRow>
      )}
    </Fragment>
  );
};

export { Setup };
