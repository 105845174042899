import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import emptyFn from 'emptyfunction';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import HeadingNavigation from '~/components/HeadingNavigation';

import { ROLES } from '~/constants';
import { getSelectedRole } from '~/selectors/baseGetters';

const StyledNavigation = styled(HeadingNavigation)`
  width: 100%;
`;

function getTitle(currentRole) {
  switch (currentRole) {
    case ROLES.ADMIN:
      return t`Organisation`;
    case ROLES.COACH: {
      return t`Team members`;
    }
    case ROLES.USER:
      return t`Personal`;
  }
}

const OverviewHeading = ({
  children,
  title,
  instructions,
  instructionsUrl,
  description,
  backPath,
  onBack,
  filters,
  minimal,
  smallWidth,
  maxWidth,
  className,
  logoUrl,
  isDisabledSticky,
  noPadding,
}) => {
  const { i18n } = useLingui();
  const currentRole = useSelector(getSelectedRole);

  return (
    <StyledNavigation
      label={title || i18n._(getTitle(currentRole))}
      description={description}
      actions={children}
      backPath={backPath}
      onBack={onBack}
      instructions={instructions}
      instructionsUrl={instructionsUrl}
      minimal={minimal}
      smallWidth={smallWidth}
      maxWidth={maxWidth}
      className={className}
      logoUrl={logoUrl}
      isDisabledSticky={isDisabledSticky}
      noPadding={noPadding}
    >
      {filters && filters}
    </StyledNavigation>
  );
};

OverviewHeading.defaultProps = {
  onChange: emptyFn,
};

export default React.memo(OverviewHeading);
