import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import CloseIcon from '~/assets/ic-close-18-px.svg';
import DefaultAvatar from '~/assets/user-default.svg';

import { useAuth0 } from '~/auth0';
import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { getIsMenuCollapsed } from '~/selectors/auth';
import { checkModuleSkillPassport, getUser } from '~/selectors/baseGetters';
import { COLORS, COLOR_PALETTE } from '~/styles';

import './Account.scss';

const AccountContainer = styled.div``;

const BadgeAvatar = styled.div`
  width: 40px;
  height: 40px;
  outline-color: transparent;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => `url("${props.avatarUrl}")`};

  &:focus {
    outline-color: transparent;
  }
`;

const DropdownItem = styled.li`
  height: 50px;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  color: ${COLOR_PALETTE.BLACK};
  background-color: ${COLOR_PALETTE.WHITE};
  border-bottom: 1px solid ${COLORS.BG_PAGE};
  padding-left: 16px;

  &:hover {
    color: var(--company-color);
    background-color: ${COLORS.BG_PAGE};
  }

  a {
    color: ${COLOR_PALETTE.DARK_GRAY};
    text-decoration: inherit;
  }

  a:hover {
    color: #3b3b3b;
    text-decoration: inherit;
  }
`;

const DropDownHeader = styled.li`
  position: relative;
  padding: 16px 0 8px 16px;
  box-sizing: border-box;
  background-color: ${COLORS.BG_PAGE};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  font-size: 20px;
  font-weight: bold;
  display: grid;
  grid-template-columns: 10fr 1fr;
`;

const DropdownMenu = styled.ul`
  width: 300px;
  position: absolute;
  border-radius: 10px;
  box-shadow: 3px 3px 2px 0 rgba(25, 42, 70, 0.08);
  background-color: ${COLOR_PALETTE.WHITE};
  list-style: none;
  padding: 0;
  left: ${(props) => (props.$isMenuCollapsed ? '80px' : '259px')};
  bottom: 10px;
  z-index: 9999;

  & * {
    text-decoration: none;
  }

  li {
    &:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
`;

const AccountUserName = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  margin: 12px 0 0 0;
  color: ${COLOR_PALETTE.BLACK};
`;

const CloseIconWrapper = styled.div`
  margin-right: 8px;
  cursor: pointer;
  z-index: 100;
`;

const Account = ({ onClose, openNotebook }) => {
  const user = useSelector(getUser);
  const isModuleSkillPassportEnabled = useSelector(checkModuleSkillPassport);
  const isMenuCollapsed = useSelector(getIsMenuCollapsed);
  const { logout } = useAuth0();

  const hideMenu = () => {
    setTimeout(onClose, 50);
  };

  const onOpenNotebook = () => {
    openNotebook();
    hideMenu();
  };

  return user ? (
    <AccountContainer>
      <DropdownMenu $isMenuCollapsed={isMenuCollapsed}>
        <DropDownHeader>
          <div>
            <BadgeAvatar avatarUrl={user.avatarUrl || DefaultAvatar} />
            <AccountUserName>
              {user.firstName} {user.lastName}
            </AccountUserName>
          </div>
          <CloseIconWrapper onClick={hideMenu}>
            <SvgIcon width="16px" height="16px" url={CloseIcon} />
          </CloseIconWrapper>
        </DropDownHeader>
        {user.isSuperAdmin && (
          <Link to={routes.SUPERADMIN_COMPANY_DASHBOARD.url}>
            <DropdownItem onMouseDown={(e) => e.preventDefault()} onMouseUp={hideMenu}>
              <Trans>Super Admin Dashboard</Trans>
            </DropdownItem>
          </Link>
        )}
        {isModuleSkillPassportEnabled && (
          <Link to={routes.PROFILE.build({ role: ROLES.USER })}>
            <DropdownItem onMouseDown={(e) => e.preventDefault()} onMouseUp={hideMenu}>
              <Trans>Profile</Trans>
            </DropdownItem>
          </Link>
        )}
        <DropdownItem onMouseDown={(e) => e.preventDefault()} onMouseUp={onOpenNotebook}>
          <Trans>Notes</Trans>
        </DropdownItem>
        <Link to={routes.INVITES.build({ role: ROLES.USER })}>
          <DropdownItem onMouseDown={(e) => e.preventDefault()} onMouseUp={hideMenu}>
            <Trans>Invites</Trans>
          </DropdownItem>
        </Link>
        <Link to={routes.SETTINGS.build({ role: ROLES.USER })}>
          <DropdownItem onMouseDown={(e) => e.preventDefault()} onMouseUp={hideMenu}>
            <Trans>Settings</Trans>
          </DropdownItem>
        </Link>
        <Link to={routes.ROADMAP.build({ role: ROLES.USER })}>
          <DropdownItem onMouseDown={(e) => e.preventDefault()} onMouseUp={hideMenu}>
            <Trans>Product roadmap</Trans>
          </DropdownItem>
        </Link>
        <a href="https://intercom.help/learned" target="_blank" rel="noopener noreferrer">
          <DropdownItem onMouseDown={(e) => e.preventDefault()} onMouseUp={hideMenu}>
            <Trans>Help center</Trans>
          </DropdownItem>
        </a>
        <DropdownItem onMouseDown={(e) => e.preventDefault()} onMouseUp={() => logout()}>
          <Trans>Logout</Trans>
        </DropdownItem>
      </DropdownMenu>
    </AccountContainer>
  ) : null;
};

Account.propTypes = {
  onClose: PropTypes.func,
  openNotebook: PropTypes.func,
};

export default Account;
