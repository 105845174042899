import styled from 'styled-components';

export const FlexRow = styled.div<{
  $isHidePointer?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${({ $isHidePointer }) => (!$isHidePointer ? 'pointer' : 'default')};
`;

export const NameRow = styled.div<{ isCentered?: boolean; padding?: string }>`
  background-color: inherit;
  padding: ${({ padding }) => padding || 'unset'};
`;
