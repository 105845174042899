import React, { Component } from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import HeadingNavigation from '~/components/HeadingNavigation';
import NewReviewReport from '~/components/NewReviewReport';
import RickTextView from '~/components/RickTextView';
import AvatarCard from '~/components/UI/AvatarCard';
import Divider from '~/components/UI/Divider';
import BaseLayout from '~/layouts/BaseLayout';
import LoadingPage from '~/pages/LoadingPage';

import { REQUEST_TYPES } from '~/constants';
import routes from '~/constants/routes';
import { getRequest } from '~/services/requests';
import { getReview } from '~/services/reviews';
import { COLOR_PALETTE, COLORS } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';

const HeaderWrapper = styled.div`
  margin-bottom: 24px;
  border-radius: 6px;
  box-shadow: 1px 1px 2px 0 rgba(25, 42, 70, 0.08);
  border: solid 1px #e4e8ef;
  background-color: #fff;
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;

const ReviewType = styled.div`
  margin-right: 16px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  color: ${COLOR_PALETTE.BLACK};
`;

const ReviewName = styled.div`
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  letter-spacing: 0.29px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ReviewCompletedContainer = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: 0.25px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const HeaderAvatarContainer = styled.div`
  align-items: flex-start;
  padding: 20px;
  display: flex;
  flex-direction: row;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 36px;
  flex: 1;
`;

const Message = styled.div`
  padding: 14px 20px;
  margin-top: 8px;
  border-radius: 4px;
  background-color: ${COLORS.BG_PAGE};
`;

const feedbackTypes = {
  [REQUEST_TYPES.FEEDBACK_FROM_PEER]: (i18n) => i18n._(t`Peer review`),
  [REQUEST_TYPES.FEEDBACK_FROM_COACH]: (i18n) => i18n._(t`Coach review`),
};

export class ReviewRequestReport extends Component {
  constructor() {
    super();
    this.state = {
      request: {},
      review: {},
      loading: false,
    };
  }

  componentDidMount = async () => {
    const { match } = this.props;
    const { requestId } = match.params;
    const request = await getRequest(requestId);
    const review = await getReview(request.target, {
      isQuestions: true,
      join: ['requests', 'ratings'],
      isInterviewee: true,
    });
    this.setState({
      request,
      review,
    });
  };

  handleOnRatingUpdated = async (updatedRating) => {
    const { review } = this.state;
    this.setState({
      review: {
        ...review,
        ratings: (review.ratings || []).map((r) => (r.id === updatedRating.id ? updatedRating : r)),
      },
    });
  };

  goBack = () => {
    const { history } = this.props;
    let href = window.location.href;
    let url = new URL(href);
    const backPath = url.searchParams.get('from') || routes.REVIEWS_INCOMING_REQUESTS.build();
    history.push(backPath);
  };

  render() {
    const { request, review } = this.state;
    const { users, i18n } = this.props;
    if (isEmpty(request) || isEmpty(review)) {
      return <LoadingPage />;
    }

    const userFirstName =
      users[review.createdFor]?.firstName || users[review.createdFor]?.email || '';

    return (
      <>
        <HeadingNavigation label={i18n._(t`Review report`)} onBack={this.goBack} smallWidth />
        <BaseLayout smallWidth>
          <HeaderWrapper>
            <HeaderTitle>
              <ReviewType>{feedbackTypes[request.type](i18n)}</ReviewType>
              <ReviewName>{review.name}</ReviewName>
              <ReviewCompletedContainer>
                {review && review.meta && convertToTimeString(review.meta.lastModifiedDate)}
              </ReviewCompletedContainer>
            </HeaderTitle>
            <Divider />
            <HeaderAvatarContainer>
              <AvatarCard userId={review.createdFor} />
              <MessageContainer>
                <Trans>Message</Trans>
                <Message>
                  <RickTextView html={request.description} />
                </Message>
              </MessageContainer>
            </HeaderAvatarContainer>
          </HeaderWrapper>
          <NewReviewReport
            review={review}
            isReadOnly={true}
            handleOnRatingUpdated={this.handleOnRatingUpdated}
            users={users}
            requestType={request.type}
            userFirstName={userFirstName}
            isShowDigitalSignSection={request.type !== REQUEST_TYPES.FEEDBACK_FROM_PEER} // hide digitalSign section for peers
          />
        </BaseLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users.data,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(ReviewRequestReport)));
