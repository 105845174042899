import React from 'react';

import { withI18n } from '@lingui/react';
import find from 'lodash/find';
import YouTube from 'react-youtube';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';
import SvgIcon from '~/components/SvgIcon';

import { ACTIVITY_TYPES } from '~/constants';
import { COLOR_PALETTE, COLORS } from '~/styles';
import abbr from '~/utils/abbr';
import getVideoId from '~/utils/getVideoId';

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 64px;
  border-radius: 6px;
  border: solid 1px #e4e8ef;
  width: 100%;
  padding: 8px 15px 8px 8px;
  box-sizing: border-box;
  margin-bottom: 4px;
  color: ${COLORS.TEXT_PRIMARY_2};
  &:hover {
    background: ${COLORS.BG_PAGE};
  }
`;

const CoverImg = styled.div`
  width: 96px;
  height: 48px;
  background-color: #edf0f7;
  border-radius: 5.1px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--company-color);
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ItemName = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  margin-bottom: 5px;
  white-space: nowrap;
  max-width: 550px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemType = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-left: 6px;
`;

const ActivityItem = ({ i18n, checked, activity, onCheckActivity, onClick }) => {
  // isVideo
  let isYoutubeVideo = false;
  let videoId = null;

  if (activity.link) {
    videoId = getVideoId(activity.link);
    if (videoId && videoId !== '') {
      isYoutubeVideo = true;
    }
  }

  const shortName = abbr(activity.name);
  const type = find(ACTIVITY_TYPES, (a) => a.key === activity.type) || ACTIVITY_TYPES.OTHER;

  const handleChange = (e, isSelected) => {
    e.stopPropagation();
    onCheckActivity(isSelected, activity);
  };

  return (
    <Card key={activity.id} onClick={() => onClick(activity)}>
      <Row>
        {!isYoutubeVideo && (
          <CoverImg
            style={{
              backgroundImage: activity.coverUrl ? 'url(' + activity.coverUrl + ')' : 'none',
            }}
          >
            {!activity.coverUrl && shortName}
          </CoverImg>
        )}

        {/* YOUTUBE */}
        {isYoutubeVideo && (
          <CoverImg>
            <YouTube
              style={{ marginBottom: '24px' }}
              className="youtube-video-cover"
              videoId={videoId}
              opts={{
                width: '100%',
                height: '48px',
              }}
            />
          </CoverImg>
        )}
        <div>
          <ItemName>{activity.name}</ItemName>
          <Row>
            <SvgIcon
              url={type.icon}
              width={'16px'}
              height={'16px'}
              isDefaultColor
              defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
            />
            <ItemType>{type.translated(i18n)}</ItemType>
          </Row>
        </div>
      </Row>
      <CheckBox checked={checked} onChange={handleChange} size="24px" />
    </Card>
  );
};

export default React.memo(withI18n()(ActivityItem));
