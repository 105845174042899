import React, { useEffect } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import BoxWithBorder from '~/components/UI/BoxWithBorder';

import TabHeader from './components/TabHeader';
import { Conversations } from './Conversations';

import routes from '~/constants/routes';
import { checkModuleReviews } from '~/selectors/baseGetters';
import getUserFullName from '~/utils/getUserFullName';

const TabContainer = styled.div`
  margin: 16px 24px;

  .user-public-conversations_header {
    margin-bottom: 24px;
  }
`;

const ConversationsTab = ({ user, i18n }) => {
  const userName = getUserFullName(user);
  const history = useHistory();
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);

  useEffect(() => {
    if (!isModuleReviewsEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleReviewsEnabled, history]);

  return (
    <BoxWithBorder>
      <TabContainer>
        <TabHeader
          title={i18n._(t`Conversations`)}
          description={i18n._(t`An overview of all ${userName} conversations`)}
          className="user-public-conversations_header"
        />
        <Conversations userTempId={user.id} />
      </TabContainer>
    </BoxWithBorder>
  );
};

ConversationsTab.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withI18n()(ConversationsTab);
