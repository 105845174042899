import moment from 'moment';

export const getDateForTimeFrame = (amount = '12', format = 'YYYY-MM-DD') => {
  return {
    // Range from Current month to amount of month forward
    // Current Date 10th Feb 2024
    // Amount 12, Start: 2023-03-01; End: 2024-02-29
    // Including current month; therefore -1 from the amount
    start: moment()
      .subtract(parseInt(amount, 10) - 1, 'month')
      .startOf('month')
      .format(format),
    end: moment().endOf('month').format(format),
  };
};
