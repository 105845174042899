import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import getNotificationPath from '~/components/Notifications/getNotificationPath';
import SvgIcon from '~/components/SvgIcon';

import crossIcon from '~/assets/close-icn.svg';

import { NOTIFICATION_TYPES } from '~/constants';
import { getUsers } from '~/selectors/baseGetters';
import { deleteNotification } from '~/services/notifications';
import { COLORS, COLOR_PALETTE } from '~/styles';
import { ListItemStyles } from '~/styles/styles';
import getUserFullName from '~/utils/getUserFullName';

const NotificationRow = styled.div`
  width: 100%;
  min-height: 72px;
  display: flex;
  ${ListItemStyles};
  background: ${(props) => (props.highlight ? COLORS.BG_PAGE : COLOR_PALETTE.WHITE)};
  align-items: center;
  padding: 16px 16px 24px;
  box-sizing: border-box;
  justify-content: space-between;
`;

const NotificationTitle = styled.span`
  width: 90%;
`;

const NotificationTime = styled.div`
  width: 5%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

const NotificationCross = styled.div`
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
`;

const NotificationText = styled.div`
  font-size: 14px;
  font-weight: 600;
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 350px;
  color: ${COLORS.TEXT_BLACK};
`;

const Notification = ({ notification, onClose, onDeleted, highlighted }) => {
  const { i18n } = useLingui();
  const history = useHistory();
  const users = useSelector(getUsers);
  const daysSinceNotification = moment().diff(moment(notification.meta.createdDate), 'days');

  // "Populates" a notification's options property by replacing ids with values,
  // so they can be used within the NOTIFICATION_TYPES enum in constants.
  const populateNotificationOptions = (notification) => {
    let populatedNotification = cloneDeep(notification);

    if (notification.options.fromUser) {
      if (users[notification.options.fromUser]) {
        // If we have a user id, show the user's full name
        populatedNotification.options.fromUser = getUserFullName(
          users[notification.options.fromUser],
        );
      } else {
        // Else, show the ID of the user that is in the fromUser field
        populatedNotification.options.fromUser = notification.options.fromUser;
      }
    } else if (notification.options.fromEmail) {
      // If we have an email address, use this for the user name
      populatedNotification.options.fromUser = notification.options.fromEmail;
    }
    return populatedNotification;
  };

  // Uses the NOTIFICATION_TYPES enum to retrieve the (translated) text of the body of the notification
  const getNotificationText = () => {
    const notificationType = find(NOTIFICATION_TYPES, (n) => n.key === notification.type) || {
      text: () => {},
    };

    if (!notification.options) {
      return notificationType.text_alt
        ? notificationType.text_alt(i18n)
        : i18n._(t`Unknown message`);
    }

    const populatedNotification = populateNotificationOptions(notification);
    return notificationType.text(populatedNotification, i18n);
  };

  // When clicking the notification, an appropriate action is selected based on the type
  // of the notification.
  const onNotificationClick = () => {
    // Based on the type of notification we have clicked on, choose an action to carry out.
    const path = getNotificationPath(notification);
    if (path) {
      history.push(path);
      onClose();
    }
  };

  const deleteThisNotification = () => {
    deleteNotification(notification.id);

    if (onDeleted) {
      onDeleted(notification.id);
    }
  };

  return (
    <NotificationRow highlight={highlighted}>
      <NotificationTitle onClick={onNotificationClick}>
        <NotificationText>{getNotificationText()}</NotificationText>
      </NotificationTitle>
      <NotificationTime>
        {daysSinceNotification > 0 && daysSinceNotification + 'd'}
      </NotificationTime>
      <NotificationCross onClick={deleteThisNotification}>
        <SvgIcon
          width="12px"
          height="12px"
          url={crossIcon}
          isDefaultColor
          defaultColor={!highlighted ? COLORS.BORDER_HARD : COLOR_PALETTE.BLACK}
        />
      </NotificationCross>
    </NotificationRow>
  );
};

export default React.memo(Notification);
