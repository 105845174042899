import styled, { css } from 'styled-components';

import { Button } from '~/components/Buttons/Button';

import { COLORS } from '~/styles';

export const Base = styled.div`
  position: relative;
  display: flex;
  width: 32px;
  height: 32px;
`;

export const ContextContainer = styled.div<{ $showContainer?: boolean; $height: number }>`
  display: flex;
  position: absolute;
  right: 0;
  top: 32px;
  max-height: 0;
  box-sizing: border-box;
  transition: max-height 0.2s ease-out, border 0s ease-out 0.2s;
  overflow: hidden;
  border-radius: 6px;
  border: solid 1px transparent;
  ${(props) =>
    props.$showContainer &&
    css`
      border: solid 1px ${COLORS.BORDERS};
      box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
      transition: max-height 0.2s ease-out, border 0s ease-out 0s;
      max-height: ${props.$height}px;
    `}
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  box-sizing: border-box;
  background-color: ${COLORS.WHITE};
  overflow: hidden;
  min-width: 166px;
  z-index: 9999;
`;

export const Option = styled.div<{ $isWarning?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  white-space: nowrap; // in one row
  cursor: pointer;
  gap: 16px;
  padding: 13px 16px;
  min-height: 40px;
  box-sizing: border-box;
  font-size: 12px;
  color: ${COLORS.TEXT_MAIN};

  :hover {
    background-color: ${COLORS.HOVER};
    color: ${COLORS.TEXT_HOVER};
  }

  ${(props) =>
    props.$isWarning &&
    css`
      &:hover .icon {
        background-color: ${COLORS.ERROR_LIGHT};
        color: ${COLORS.ACCENT_ERROR};
      }

      &:hover {
        background-color: ${COLORS.ERROR_LIGHT};
        color: ${COLORS.ACCENT_ERROR};
      }
    `}
`;

export const StyledButton = styled(Button)`
  min-width: 24px;
  min-height: 24px;

  &:not(:disabled) {
    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: ${COLORS.TEXT_MAIN};
    }
    &:active {
      background-color: transparent;
      border-color: transparent;
      color: ${COLORS.TEXT_MAIN};
    }
  }

  svg {
    min-width: 14px;
    min-height: 14px;
    height: 14px;
    width: 14px;
  }
`;
