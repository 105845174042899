import React, { ChangeEvent, useState } from 'react';

import { IUser } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import UserItem from '~/components/SelectUsers/components/UserItem';
import { SearchField } from '~/components/Text';

import { getUsers } from '~/selectors/baseGetters';

const FooterActions = styled.div`
  display: flex;
`;

const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 400px;
`;

const SearchWrapper = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
`;

const StyledSearchField = styled(SearchField)`
  flex: 1;
  margin-right: 8px;
  height: 40px;
`;

type props = {
  selectedUser: IUser;
  onClose: () => void;
  setNewParticipants: (arg0: string[]) => void;
  selectedParticipants: string[];
};
const NominatePeopleAsParticipantModal = ({
  selectedUser,
  onClose,
  setNewParticipants,
  selectedParticipants,
}: props) => {
  const users: { [key: string]: IUser } = useSelector(getUsers);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const { i18n } = useLingui();
  return (
    <Modal
      onClose={onClose}
      title={i18n._(t`Select people to nominate as participants`)}
      width={600}
      footerRight={
        <FooterActions>
          <Button
            styles={{
              height: '40px',
              marginLeft: '10px',
              textTransform: 'uppercase',
              padding: '0px 15px',
              fontSize: '12px',
              minWidth: '120px',
            }}
            label={i18n._(t`Add ${selectedUsers.length || 0} users`)}
            disabled={!(selectedUsers.length || 0)}
            type="white"
            onClick={() => {
              setNewParticipants(selectedUsers);
              onClose();
            }}
          />
        </FooterActions>
      }
    >
      <SearchWrapper>
        <StyledSearchField
          placeholder={i18n._(t`Search`)}
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        />
      </SearchWrapper>
      {/* @ts-ignore */}
      <UsersContainer>
        {Object.values(users)
          .filter((user: IUser) => {
            if (selectedParticipants.includes(user.id) || user.id === selectedUser.id) {
              return false;
            }
            if (!search) {
              return true;
            }
            const name = `${user.firstName} ${user.lastName}`;
            return name.toLowerCase().includes(search.toLowerCase());
          })
          .map((user: IUser) => {
            const checked = selectedUsers.find((u: string) => u === user.id);
            return (
              <UserItem
                key={user.id}
                user={user}
                selected={checked}
                onSelect={() => {
                  setSelectedUsers(
                    checked
                      ? selectedUsers.filter((u: string) => u !== user.id)
                      : [...selectedUsers, user.id],
                  );
                }}
                isSelectMode={true}
                disabled={false}
                singleChoice={false}
              />
            );
          })}
      </UsersContainer>
    </Modal>
  );
};

export { NominatePeopleAsParticipantModal };
