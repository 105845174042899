import styled from 'styled-components';

const ToolboxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .rc-switch {
    margin: 0;
  }
`;

export default ToolboxRow;
