import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import routes from '~/constants/routes';
import { getNextScheduledConversation } from '~/services/reviews';

import { SIDEBAR_ITEM_TYPES } from '../../constants';
import { SidebarRow } from '../SidebarRow';

type Review = {
  id: string;
  name: string;
  dateOfConversation: string;
};

const ConversationCard = () => {
  const [conversation, setConversation] = useState<Review>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await getNextScheduledConversation();
      setConversation(data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const onCardClick = () => {
    if (conversation) {
      // @ts-ignore
      routes.REVIEW.go({}, { reviewId: conversation.id, isBackPath: true });
    }
  };

  return (
    <>
      <SidebarRow
        type={SIDEBAR_ITEM_TYPES.CONVERSATION}
        onClick={onCardClick}
        isShowPlaceholder={isEmpty(conversation)}
        isLoading={isLoading}
        name={conversation && conversation.name}
        date={conversation && new Date(conversation.dateOfConversation)}
      />
    </>
  );
};

export { ConversationCard };
