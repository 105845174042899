import { ProductName } from '@learned/constants';

import type { LocalProductName } from '../types';

export const disableProductCheck = (key: LocalProductName, products: LocalProductName[]) => {
  switch (key) {
    case ProductName.JOB_MATRIX:
      // if Career product enabled, not allow to disable jobMatrix
      return products.includes(ProductName.CAREER) && products.includes(ProductName.JOB_MATRIX);
    default:
      return false;
  }
};
