import React from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FeedbackIcon from '~/components/Icons/Feedback';
import Placeholder from '~/components/Placeholder';
import Spider from '~/components/Spider';

import getLang from '~/selectors/getLang';
import { COLOR_SET } from '~/styles';
import getSkillName from '~/utils/getSkillName';
import {
  getAverageSkillsList,
  getAverageCustomList,
  getPeerRations,
  getSelfRatings,
  getCoachRations,
  filterLastRates,
} from '~/utils/ratingUtils';

// icons

const SpiderRow = styled.div`
  display: flex;
`;

const ITEM_TYPE = {
  SKILL: 'skill',
  QUESTION: 'question',
};

function prepareRadioData({ review, i18n, lang, rates, isCustom = false }) {
  const selfRatings = getSelfRatings(rates);
  const peerRatings = getPeerRations(rates);
  const coachRatings = getCoachRations(rates);
  // questions with skills
  const avgSelfSkills = getAverageSkillsList(selfRatings);
  const avgPeerSkills = getAverageSkillsList(peerRatings);
  const avgCoachSkills = getAverageSkillsList(coachRatings);

  // questions custom
  const avgSelfCustom = getAverageCustomList(selfRatings);
  const avgPeerCustom = getAverageCustomList(peerRatings);
  const avgCoachCustom = getAverageCustomList(coachRatings);

  const items = {};
  // add item with ratings per skill
  if (!isEmpty(review.skills) && !isCustom) {
    review.skills.forEach((s) => (items[s.id] = { type: ITEM_TYPE.SKILL, id: s.id }));
  }

  // add item with ratings per skillJobProfile
  if (!isEmpty(review.skillsJobProfile) && !isCustom) {
    review.skillsJobProfile.forEach((s) => (items[s.id] = { type: ITEM_TYPE.SKILL, id: s.id }));
  }

  // add item with ratings per custom question
  if (!isEmpty(review.questions) && isCustom) {
    review.questions.forEach((q) => {
      if (q.type === QUESTION_TYPES.CUSTOM) {
        items[q.id] = { type: ITEM_TYPE.QUESTION, id: q.id };
      }
    });
  }

  // add avg rating per skill/question for self/peer/coach to item
  Object.keys(items).forEach((key) => {
    const self = find([...avgSelfSkills, ...avgSelfCustom], (r) => r.id === key);
    const peer = find([...avgPeerSkills, ...avgPeerCustom], (r) => r.id === key);
    const coach = find([...avgCoachSkills, ...avgCoachCustom], (r) => r.id === key);
    items[key].self = self;
    items[key].peer = peer;
    items[key].coach = coach;
  });

  const itemsToDisplay = Object.values(items).filter((_item, index) => index <= 9);
  // prepare labels: skillName/custom question name
  const labels = Object.values(itemsToDisplay).map((item) => {
    return item.type === ITEM_TYPE.SKILL
      ? [
          getSkillName(
            find(
              [...(review.skills || []), ...(review.skillsJobProfile || [])],
              (s) => s.id === item.id,
            ),
            lang,
          ),
        ]
      : (find(review.questions, (q) => q.id === item.id) || {}).name;
  });

  function prepareData(type) {
    // type could be self/peer/coach
    return itemsToDisplay.map((item) => {
      const r = item[type];
      if (!r) {
        return 0;
      }
      return r.avg;
    });
  }

  const data = {
    labels,
    datasets: [],
  };

  const isSelfRatingsExist = review.isSelfReview ? !isEmpty(selfRatings) : false;
  const isPeerRatingsExist = review.isUsersReview ? !isEmpty(peerRatings) : false;
  const isCoachRatingsExist = review.isCoachesReview ? !isEmpty(coachRatings) : false;

  // add self
  if (isSelfRatingsExist) {
    data.datasets.push({
      fill: false,
      borderColor: COLOR_SET.CYAN_GREEN,
      backgroundColor: COLOR_SET.CYAN_GREEN,
      data: prepareData('self'),
      label: i18n._(t`Self review`),
    });
  }

  // add peer
  if (isPeerRatingsExist) {
    data.datasets.push({
      fill: false,
      borderColor: COLOR_SET.BLUE,
      backgroundColor: COLOR_SET.BLUE,
      data: prepareData('peer'),
      label: i18n._(t`Peer review`),
    });
  }

  // add coach
  if (isCoachRatingsExist) {
    data.datasets.push({
      fill: false,
      borderColor: COLOR_SET.ORANGE3,
      backgroundColor: COLOR_SET.ORANGE3,
      data: prepareData('coach'),
      label: i18n._(t`Coach review`),
    });
  }

  return data;
}

const SpiderReview = ({ review, i18n }) => {
  const lang = useSelector(getLang);

  const skillRates = prepareRadioData({
    i18n,
    lang,
    review,
    rates: filterLastRates((review.ratings || []).filter((r) => r.skill)),
    isCustom: false,
  });
  const questionsRates = prepareRadioData({
    i18n,
    lang,
    review,
    rates: filterLastRates((review.ratings || []).filter((r) => r.question)),
    isCustom: true,
  });
  const noQuestionsRates = questionsRates.datasets.length === 0;
  const noSkillRates = skillRates.datasets.length === 0;

  // define max Radar scale value
  const skillsQuestions = (review.questions || []).filter((q) =>
    [QUESTION_TYPES.SKILL, QUESTION_TYPES.JOB_PROFILE].includes(q.type),
  );
  const customQuestions = (review.questions || []).filter((q) =>
    [QUESTION_TYPES.CUSTOM].includes(q.type),
  );

  // for skills maxValue equal to length scaleLabels in question with skills or skillsJobProfile
  const maxValueForSkills = get(skillsQuestions, '[0]scaleLabels.length', 0);

  // for skills maxValue equal to max length scaleLabels in custom questions
  const customQuestionsScaleSize = customQuestions.map((q) => get(q, 'scaleLabels.length', 0));
  const maxValueForCustom = max(customQuestionsScaleSize);

  return (
    <SpiderRow>
      {(!noSkillRates || noQuestionsRates) && (
        <Spider
          title={<Trans>Skill ratings</Trans>}
          data={skillRates}
          maxWidth={400}
          maxValue={maxValueForSkills}
          showPlaceholder={noSkillRates}
          placeholder={
            <Placeholder
              title={i18n._(t`No feedback`)}
              className="tp-placeholder"
              Icon={FeedbackIcon}
              subTitle={i18n._(
                t`The results from your self review and the reviews from peers and coaches will be summarised here.`,
              )}
            />
          }
        />
      )}
      {!noQuestionsRates && (
        <Spider
          title={<Trans>Custom ratings</Trans>}
          data={questionsRates}
          maxWidth={400}
          maxValue={maxValueForCustom}
          showPlaceholder={noQuestionsRates}
          placeholder={
            <Placeholder
              title={i18n._(t`No feedback`)}
              className="tp-placeholder"
              Icon={FeedbackIcon}
              subTitle={i18n._(
                t`The results from your self review and the reviews from peers and coaches will be summarised here.`,
              )}
            />
          }
        />
      )}
    </SpiderRow>
  );
};

export default withI18n()(SpiderReview);
