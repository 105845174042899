import React, { Component, Fragment } from 'react';

import { QUESTION_TYPES, RATING_STATUSES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import FocusAreaName from '~/components/FocusAreaName';
import Modal from '~/components/Modal';
import ProgressBar from '~/components/ProgressBar';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import Slider from '~/components/Slider';
import SvgIcon from '~/components/SvgIcon';
import UserGroupCard from '~/components/UserGroupCard';

import InfoIcon from '~/assets/ic-info-24-px.svg';

import { RATING_TYPES, FOCUSAREA_LEVELS } from '~/constants';
import { SKILL_TYPE } from '~/constants/skills';
import { createOrUpdateFeedbackRatings } from '~/services/ratings';
import { getSkillsById } from '~/services/skills';
import { COLOR_PALETTE, COLORS } from '~/styles';
import disableActionOnLoading from '~/utils/disableActionOnLoading';
import getUserFullName from '~/utils/getUserFullName';
import { getReviewQuestionsWithSkills } from '~/utils/helper';
import sanitizeHtml from '~/utils/sanitize';
import zipArray from '~/utils/zipArray';

import './styles.scss';

const AverageRate = styled.div`
  width: 115px;
  height: 40px;
  opacity: 0.9;
  border-radius: 20px;
  border-color: var(--company-color);
  border-style: solid;
  border-width: 1px;
  color: var(--company-color);
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
`;

const Content = styled.div`
  .textarea {
    height: 120px;
    margin-bottom: 20px;
    border-radius: 6px;
    border: solid 1px #ccced5;
    box-sizing: border-box;
    width: 100%;
  }

  .textarea-full {
    height: 120px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    color: #3b3b3b;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Description = styled.div`
  p {
    margin: 0;
  }

  display: none;

  &:hover {
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    box-shadow: 1px 4px 8px 0 rgba(25, 42, 70, 0.08);
    border: solid 0.5px #ecedf0;
    background-color: ${COLOR_PALETTE.WHITE};
    display: block;
    z-index: 1000;
    margin: 10px;
    position: absolute;
    text-decoration: none;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: #3b3b3b;
  }
`;

const FocusArea = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #3b3b3b;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;
const Level = styled.div`
  margin-bottom: 40px;
`;

const LevelTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  color: #3b3b3b;
  margin-bottom: 8px;
`;

const SkillNameBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 32px;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #3b3b3b;
`;

const StepSlider = styled.div`
  display: flex;
  align-items: center;
  margin: 0 25px;
`;

const StepScaling = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 48px;
  height: 99px;
  box-sizing: border-box;
`;

const StepScalingText = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  text-align: center;
  color: #3b3b3b;
`;

const TabRow = styled.div`
  display: flex;
  width: 100%;
`;

const TabTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  color: ${(props) => (!props.isActive ? COLORS.SUBTEXT : '#3b3b3b')};
  margin-bottom: 30px;
  margin-right: 24px;
  cursor: ${(props) => props.isClick && 'pointer'};
`;

const UsersRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ACTIVE_TAB = {
  YOU: 'you',
  SELF: 'self',
  OTHER: 'other',
};

const INITIAL_STATE = {
  loading: true,
  fetching: true,
  step: 1,
  levels: [],
  maxStep: 1,
  reviewRatings: {},
  activeTab: ACTIVE_TAB.YOU,
  selectedUser: null,
  skills: {},
};

const maxValue = 4;

function getSteps(review, skills) {
  const steps = [];
  const q = getReviewQuestionsWithSkills(review);
  q.forEach((q) => {
    if (q.type === QUESTION_TYPES.CUSTOM) {
      steps.push({
        question: q,
      });
    } else {
      q.skills.forEach((skill) => {
        if (skill && !isEmpty(skills)) {
          steps.push({
            question: q,
            skill: skill.id ? skills[skill.id] : skills[skill],
          });
        }
      });
    }
  });

  return steps;
}

class SkillsRateModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static propTypes = {
    type: PropTypes.oneOf([
      RATING_TYPES.SELF_RATING,
      RATING_TYPES.OTHER_RATING,
      RATING_TYPES.COACH_RATING,
      RATING_TYPES.OUTSIDE_RATING,
    ]),
    review: PropTypes.object,
    activity: PropTypes.object,
    onClose: PropTypes.func,
    onRated: PropTypes.func,
    forUser: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isReadOnly: PropTypes.bool,
    email: PropTypes.string,
    onShared: PropTypes.func,
  };

  static defaultProps = {
    onClose: () => {},
    onRated: () => {},
    onShared: () => {},
    forUser: '',
  };

  componentDidMount = async () => {
    const { review, user, type, email, isRealTime } = this.props;
    if (!isEmpty(review)) {
      const newState = {};
      let skillsIdsArr = [];
      if (review.skills || review.skillsJobProfile) {
        const skillsIds = (review.skills || []).map((s) => s.id || s);
        const skillsJobProfileIds = (review.skillsJobProfile || []).map((s) => s.id || s);
        skillsIdsArr = [...(skillsIds || []), ...(skillsJobProfileIds || [])];
      }

      // Retrieve all relevant skills
      const skills = !isEmpty(skillsIdsArr)
        ? zipArray(await getSkillsById(skillsIdsArr, ['focusAreas']))
        : {};
      newState.skills = skills;

      const steps = getSteps({ ...review, questions: review.questions }, skills);

      // maxStep
      const maxStep = steps.length;
      if (maxStep !== 0) {
        newState.maxStep = maxStep;
      }

      // get Ratings;
      let reviewRatings = [];
      if (!isEmpty(review.ratings)) {
        reviewRatings = review.ratings;

        // filter only other rating rates
        reviewRatings = reviewRatings.filter((r) => {
          if (r.type === type && type === RATING_TYPES.OUTSIDE_RATING) {
            return r.email === email;
          }
          return r.type === type && String(r.user) === String(user.id);
        });
      }

      // create reviewRatingsObj
      const reviewRatingsObj = {};
      let step = 1;
      let isDefineStartStep = false;

      if (maxStep) {
        steps.forEach((s, index) => {
          let rating = {};
          let id;
          if (s.skill) {
            id = s.skill.id;
            rating = find(
              reviewRatings,
              (reviewRating) => String(id) === String(reviewRating.skill),
            );
          } else {
            id = s.question.id;
            rating = find(
              reviewRatings,
              (reviewRating) => String(id) === String(reviewRating.question),
            );
          }

          if (isEmpty(rating) || rating.rate === 0) {
            if (!isDefineStartStep) {
              isDefineStartStep = true;
              step = index + 1;
            }
          }

          if (!isEmpty(rating)) {
            reviewRatingsObj[id] = rating;
          } else {
            reviewRatingsObj[id] = {
              rate: 0,
              explanation: '',
              [isRealTime ? 'RTFeedback' : 'review']: review.id,
              type,
              company: review.company,
              user: type === RATING_TYPES.OUTSIDE_RATING ? '' : user.id,
              email: type === RATING_TYPES.OUTSIDE_RATING ? email : '',
              createdFor: isRealTime
                ? get(review, 'createdBy.id', review.createdBy)
                : review.createdFor,
              status: RATING_STATUSES.TODO,
            };

            if (s.skill) {
              reviewRatingsObj[id].skill = id;
              reviewRatingsObj[id].skillLevel =
                get(review, `skillsLevels[${id}]`) || get(review, `skillsJobProfileLevels[${id}]`);
            } else {
              reviewRatingsObj[id].question = id;
            }
          }
        });
      }

      newState.step = step;
      newState.reviewRatings = reviewRatingsObj;

      if (type === RATING_TYPES.COACH_RATING) {
        newState.activeTab = ACTIVE_TAB.YOU;
      }

      this.setState({
        ...newState,
        fetching: false,
        loading: false,
      });
    }
  };

  closeModal = () => {
    this.setState({ ...INITIAL_STATE });
    this.props.onClose();
  };

  onChangeRating = (newRating) => {
    const { reviewRatings } = this.state;
    if (newRating.skill) {
      const id = String(newRating.skill);
      this.setState({
        reviewRatings: {
          ...reviewRatings,
          [id]: newRating,
        },
      });
    }
    if (!isEmpty(newRating.question)) {
      this.setState({
        reviewRatings: {
          ...reviewRatings,
          [newRating.question]: newRating,
        },
      });
    }
  };

  saveRatings = async (_, isShared = false) => {
    const { reviewRatings, loading } = this.state;
    const { type, onRated, onShared, review } = this.props;

    if (disableActionOnLoading(loading)) {
      return;
    }

    this.setState({ loading: true });
    if (!isEmpty(reviewRatings)) {
      await createOrUpdateFeedbackRatings({
        ratings: Object.values(reviewRatings),
        reviewId: review.id,
        share: isShared,
      });
    }

    if (
      type === RATING_TYPES.SELF_RATING ||
      type === RATING_TYPES.OTHER_RATING ||
      type === RATING_TYPES.COACH_RATING ||
      type === RATING_TYPES.OUTSIDE_RATING
    ) {
      await onRated(isShared);
    }

    if (isShared) {
      await onShared();
    }

    this.setState({ loading: false });
    if (!isShared) {
      this.closeModal();
    }
  };

  nextStep = () => {
    const { step, maxStep } = this.state;
    if (step < maxStep) {
      this.setState({
        loading: false,
        step: step + 1,
      });
    }
  };

  prevStep = () => {
    const { step } = this.state;
    if (step > 1) {
      this.setState({
        loading: false,
        step: step - 1,
      });
    }
  };

  getPlaceholder = (selectedRate, stepRating, skillType) => {
    const { i18n, type } = this.props;
    let placeholders = [];
    if (stepRating.question || (stepRating.skill && skillType === SKILL_TYPE.hard)) {
      placeholders = [
        {
          min: 1,
          max: 1,
          text: i18n._(t`Inadequate`),
        },
        {
          min: 2,
          max: 2,
          text: i18n._(t`Mediocre`),
        },
        {
          min: 3,
          max: 3,
          text: i18n._(t`Sufficient`),
        },
        {
          min: 4,
          max: 4,
          text: i18n._(t`Good`),
        },
      ];
    }

    if (stepRating.skill && skillType === SKILL_TYPE.soft && type === RATING_TYPES.SELF_RATING) {
      placeholders = [
        {
          min: 1,
          max: 1,
          text: i18n._(t`I rarely show this behavior`),
        },
        {
          min: 2,
          max: 2,
          text: i18n._(t`I sometimes show this behavior`),
        },
        {
          min: 3,
          max: 3,
          text: i18n._(t`I show this behavior regularly`),
        },
        {
          min: 4,
          max: 4,
          text: i18n._(t`I always show this behavior`),
        },
      ];
    }

    if (stepRating.skill && skillType === SKILL_TYPE.soft && type !== RATING_TYPES.SELF_RATING) {
      placeholders = [
        {
          min: 1,
          max: 1,
          text: i18n._(t`I never see this behavior`),
        },
        {
          min: 2,
          max: 2,
          text: i18n._(t`I sometimes see this behavior`),
        },
        {
          min: 3,
          max: 3,
          text: i18n._(t`I see this behavior regularly`),
        },
        {
          min: 4,
          max: 4,
          text: i18n._(t`I always see this behavior`),
        },
      ];
    }

    let currentPlaceholder = {};
    placeholders.forEach((item) => {
      if (item.min <= selectedRate && selectedRate <= item.max) {
        currentPlaceholder = item;
      }
    });
    return currentPlaceholder;
  };

  switchTab = (tabState) => {
    this.setState({ activeTab: tabState });
  };

  selectUser = (id) => {
    this.setState({ selectedUser: id });
  };

  renderRatingBlock(entity, entityType) {
    const { i18n, isReadOnly, type, users, review } = this.props;
    const { reviewRatings, selectedUser, activeTab } = this.state;

    const stepRating = reviewRatings[entity.id] || {};
    const selectedRate = stepRating.rate;
    const explanation = stepRating.explanation;

    const currentPlaceholder = this.getPlaceholder(selectedRate, stepRating, entity.type);
    const userName = getUserFullName(users[review.createdFor]);
    const reviewRatingsList = review.ratings || [];
    const selfUserRate = reviewRatingsList.find(
      (r) => r.type === RATING_TYPES.SELF_RATING && r[entityType] === entity.id,
    );
    const currentSelfPlaceholder = selfUserRate
      ? this.getPlaceholder(selfUserRate.rate, stepRating, entity.type)
      : '';

    let peers = [];
    const othersRates = [];
    const othersRatings = reviewRatingsList.filter((r) => {
      let isOther = false;
      if (
        r.type === RATING_TYPES.OTHER_RATING &&
        r.status === RATING_STATUSES.RATED &&
        r[entityType] === entity.id
      ) {
        isOther = true;
        othersRates.push(r.rate);
        const user = r.user ? users[r.user] : r.email;
        if (user) {
          peers.push(user);
        }
      }
      return isOther;
    });

    const selectedUserRate = othersRatings.find(
      (r) => r.email === selectedUser || r.user === selectedUser,
    );
    const currentUserPlaceholder = selectedUserRate
      ? this.getPlaceholder(selectedUserRate.rate, stepRating)
      : '';

    const othersRatesSum = othersRates.reduce((a, b) => a + b, 0);
    const othersRatesRange = othersRatesSum / othersRates.length;

    peers = peers.filter((u) => u);
    const isCoachRequest = type === RATING_TYPES.COACH_RATING;

    return (
      <Fragment>
        {/* SLIDER */}
        <TabRow>
          <TabTitle
            isClick={isCoachRequest}
            isActive={activeTab === ACTIVE_TAB.YOU}
            onClick={() => this.switchTab(ACTIVE_TAB.YOU)}
          >
            <Trans>Your rating</Trans>
          </TabTitle>
          {isCoachRequest && !isEmpty(selfUserRate) && (
            <TabTitle
              isClick={!selfUserRate}
              isActive={activeTab === ACTIVE_TAB.SELF}
              onClick={() => this.switchTab(ACTIVE_TAB.SELF)}
            >
              <Trans>{userName} self review</Trans>
            </TabTitle>
          )}
          {isCoachRequest && !isEmpty(peers) && (
            <TabTitle
              isClick
              isActive={activeTab === ACTIVE_TAB.OTHER}
              onClick={() => this.switchTab(ACTIVE_TAB.OTHER)}
            >
              <Trans>Peer reviews</Trans>
            </TabTitle>
          )}
        </TabRow>
        {(!isCoachRequest || activeTab === ACTIVE_TAB.YOU) && (
          <Fragment>
            <StepSlider>
              <Slider
                value={selectedRate}
                onChange={(value) =>
                  this.onChangeRating({ ...stepRating, rate: value, status: RATING_STATUSES.RATED })
                }
                disabled={isReadOnly}
                min={0}
                max={4}
                step={1}
              />
            </StepSlider>

            {/* Scaling */}
            <StepScaling>
              {!isEmpty(currentPlaceholder) && (
                <StepScalingText>{currentPlaceholder.text}</StepScalingText>
              )}
            </StepScaling>

            {/* COMMENTS */}
            <LevelTitle>
              <Trans>What are your tips and tops?</Trans>
            </LevelTitle>
            <textarea
              className="textarea"
              name="explanation"
              placeholder={i18n._(
                t`Try to provide clear examples. This will make your feedback more valuable.`,
              )}
              value={explanation}
              onChange={(e) => this.onChangeRating({ ...stepRating, explanation: e.target.value })}
              disabled={isReadOnly}
            />
          </Fragment>
        )}
        {activeTab === ACTIVE_TAB.SELF && selfUserRate && (
          <Fragment>
            <StepSlider>
              <Slider value={selfUserRate.rate} disabled={true} min={0} max={4} step={1} />
            </StepSlider>

            <StepScaling>
              {!isEmpty(currentSelfPlaceholder) && (
                <StepScalingText>{currentSelfPlaceholder.text}</StepScalingText>
              )}
            </StepScaling>

            <LevelTitle>
              <Trans>Comment from {userName}</Trans>
            </LevelTitle>
            <textarea
              className="textarea-full"
              name="explanation"
              placeholder={''}
              value={selfUserRate.explanation}
              disabled={true}
            />
          </Fragment>
        )}
        {activeTab === ACTIVE_TAB.OTHER && (
          <Fragment>
            <UsersRow>
              <UserGroupCard
                users={peers}
                selectedUser={selectedUser}
                maxUsersToShow={3}
                onClick={this.selectUser}
              />
              <AverageRate>
                <Trans>Average:</Trans> {`${othersRatesRange.toFixed(1)}/${maxValue}`}
              </AverageRate>
            </UsersRow>
            {selectedUserRate && (
              <Fragment>
                <StepSlider>
                  <Slider value={selectedUserRate.rate} disabled={true} min={0} max={4} step={1} />
                </StepSlider>
                <StepScaling>
                  {!isEmpty(currentUserPlaceholder) && (
                    <StepScalingText>{currentUserPlaceholder.text}</StepScalingText>
                  )}
                </StepScaling>
                <LevelTitle>
                  <Trans>Comment</Trans>
                </LevelTitle>
                <textarea
                  className="textarea-full"
                  name="explanation"
                  placeholder={''}
                  value={selectedUserRate.explanation}
                  disabled={true}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderQuestionStep = (question, index) => {
    const { step } = this.state;
    const currentStep = index + 1;
    if (step !== currentStep) {
      return null;
    }

    return (
      <Content key={question.id}>
        <SkillNameBlock>{question.name}</SkillNameBlock>

        {this.renderRatingBlock(question, 'question')}
      </Content>
    );
  };

  renderStep = (skill, index) => {
    const { i18n, type, users, forUser, lang } = this.props;
    const { step, reviewRatings } = this.state;
    const currentStep = index + 1;
    if (step !== currentStep) {
      return null;
    }

    let stepRating = reviewRatings[skill.id] || {};
    const skillFocusAreas = (skill.focusAreas || []).filter(
      (f) => f && f.level === stepRating.skillLevel,
    );
    const skillName = lang === 'nl' && skill.nameNL ? skill.nameNL : skill.name;
    const userName = forUser.id ? forUser.name : users[forUser].name;
    return (
      <Content key={skill.id}>
        <SkillNameBlock>
          <div>
            {type === RATING_TYPES.SELF_RATING
              ? i18n._(t`How did you perform on the skill: ${skillName}?`)
              : i18n._(t`How did ${userName} perform on the skill: ${skillName}?`)}
          </div>
          <IconWrapper>
            <SvgIcon
              width="24px"
              height="24px"
              url={InfoIcon}
              isDefaultColor
              defaultColor="#ecedf0"
            />
            <Description dangerouslySetInnerHTML={{ __html: sanitizeHtml(skill.definition) }} />
          </IconWrapper>
        </SkillNameBlock>
        {stepRating.skillLevel && (
          <Level>
            <LevelTitle>
              <Trans>The relevant behavior for -</Trans>{' '}
              {FOCUSAREA_LEVELS[String(stepRating.skillLevel)].label(i18n)}
            </LevelTitle>
            {!isEmpty(skillFocusAreas) &&
              skillFocusAreas.map((focusArea) => (
                <FocusArea key={focusArea.id}>
                  <FocusAreaName focusArea={focusArea} />
                </FocusArea>
              ))}
          </Level>
        )}
        {this.renderRatingBlock(skill, 'skill')}
      </Content>
    );
  };

  renderFooterLeft = () => {
    const { i18n } = this.props;
    const { step, loading } = this.state;

    return (
      <div style={{ display: 'flex' }}>
        {step > 1 && (
          <Button
            type="white"
            label={i18n._(t`Back`)}
            styles={{
              height: '40px',
              textTransform: 'uppercase',
              padding: '0px 15px',
              fontSize: '12px',
              minWidth: '60px',
            }}
            onClick={this.prevStep}
            loading={loading}
          />
        )}
      </div>
    );
  };

  share = async (e) => {
    const { type, i18n } = this.props;
    const isSelf = String(RATING_TYPES.SELF_RATING) === String(type);
    const text = isSelf
      ? i18n._(
          t`Are you sure you want to complete and share your self review. This will make the feedback available in your feedback report. You can no longer update your review.`,
        )
      : i18n._(
          t`Are you sure you want to complete and share your review. This will make your feedback available for the receiver. After sharing your feedback you can no longer update the review.`,
        );
    if (await confirm(i18n, text)) {
      this.saveRatings(e, true);
    }
  };

  renderFooterRight = () => {
    const { i18n, isReadOnly, type } = this.props;
    const { step, maxStep, loading } = this.state;

    return (
      <div style={{ display: 'flex' }}>
        {!isReadOnly &&
          step !== maxStep &&
          String(type) !== String(RATING_TYPES.OUTSIDE_RATING) && (
            <Button
              type="white"
              label={i18n._(t`Save and close`)}
              onClick={this.saveRatings}
              loading={loading}
              styles={{
                height: '40px',
                textTransform: 'uppercase',
                padding: '0px 15px',
                fontSize: '12px',
                minWidth: '120px',
              }}
            />
          )}
        {!isReadOnly && step === maxStep && (
          <Button
            type="white"
            label={i18n._(t`Complete and share feedback`)}
            onClick={this.share}
            loading={loading}
            styles={{
              height: '40px',
              textTransform: 'uppercase',
              padding: '0px 15px',
              fontSize: '12px',
              minWidth: '120px',
            }}
          />
        )}
        {step !== maxStep && (
          <Button
            type="white"
            label={i18n._(t`Next`)}
            onClick={this.nextStep}
            loading={loading}
            styles={{
              marginLeft: '15px',
              height: '40px',
              textTransform: 'uppercase',
              padding: '0px 15px',
              fontSize: '12px',
              minWidth: '60px',
            }}
          />
        )}
      </div>
    );
  };

  renderFooterCenter = () => {
    return this.state.error && <p className="error">{this.state.error.message}</p>;
  };

  render() {
    const { i18n, type, review } = this.props;
    const { questions, maxStep, skills, step, fetching } = this.state;
    let steps = [];
    let title = i18n._(t`Your review`);
    switch (type) {
      case RATING_TYPES.SELF_RATING:
        title = i18n._(t`Your self review`);
        break;
      default:
        break;
    }

    steps = getSteps(
      {
        ...review,
        questions: (review.questions || []).map((q) => (q.id ? q : questions[q])).filter((q) => q),
      },
      skills,
    );

    return (
      <Modal
        title={title}
        width={625}
        minWidth={625}
        minHeight="670px"
        onClose={this.closeModal}
        hideHeaderClose={String(type) === String(RATING_TYPES.OUTSIDE_RATING)}
        headerSteps={
          <ProgressBar
            color="var(--company-color)"
            width={50}
            value={Math.floor((100 * step) / maxStep)}
          />
        }
        footerLeft={this.renderFooterLeft()}
        footerRight={this.renderFooterRight()}
        footerCenter={this.renderFooterCenter()}
      >
        <ShowSpinnerIfLoading loading={fetching}>
          {steps.map((step, idx) => {
            if (step.question.type === QUESTION_TYPES.CUSTOM) {
              return this.renderQuestionStep(step.question, idx);
            }
            return this.renderStep(step.skill, idx);
          })}
        </ShowSpinnerIfLoading>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.users.data,
    lang: state.locale.lang,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(SkillsRateModal)));
