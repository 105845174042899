import styled from 'styled-components';

import { Button } from '~/components/Buttons';
import Modal, { ModalContent } from '~/components/Modal';
import { TableList } from '~/components/TableList';
import { TD } from '~/components/TableList/design';

import { COLORS } from '~/styles';

export const StyledTableList = styled(TableList)`
  ${TD} {
    height: 48px;
  }
  padding: 0;

  margin: 0 -10px;

  .select-modal-table-top {
    padding: 20px 10px;
    margin-top: -10px;
  }
`;

export const StyledCloseButton = styled(Button)`
  color: ${COLORS.TOOLTIP_BACKGROUND};
  height: 34px;
  padding-right: 0;
  padding-left: 0;
  width: 34px;
`;

export const StyledModal = styled(Modal)`
  padding: 32px 35px 32px 38px;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  background-color: #fff;
  width: 750px;

  ${ModalContent} {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    gap: 20px;
    overflow-y: unset;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 26px;
  line-height: 1;
  letter-spacing: -0.29px;
  color: #000;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: #8389a0;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 10px;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: -0.16px;
`;
