import React from 'react';

import { QUESTION_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import Button from '~/components/Button';
import RatingRow from '~/components/GiveFeedback/components/Section/components/RatingRow';
import RickTextView from '~/components/RickTextView';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import Divider from '~/components/UI/Divider';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Container = styled(BoxWithBorder)`
  margin-top: 24px;
`;

const QuestionTitle = styled.div`
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLOR_PALETTE.BLACK};
`;

const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QuestionDescription = styled.div`
  padding: 16px;
`;

const StyledRickTextView = styled(RickTextView)`
  margin-bottom: 16px;
`;

const InputPlaceholder = styled.div`
  margin: 16px 0;
  padding: 12px 16px;
  border-radius: 3px;
  border: solid 1px #c7cfe0;
  background-color: #fff;
  font-size: 16px;
  line-height: 1.5;
  color: ${COLOR_PALETTE.GRAY_MIDDLE};
  cursor: default;
`;

const Label = styled.span`
  font-size: 14px;
  height: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  color: ${COLOR_PALETTE.DARK_GRAY};
  background-color: ${COLORS.BG_PAGE};
  padding: 2px 12px;
  white-space: nowrap;
  position: relative;
  border-radius: 3px;
  margin-left: 8px;
  width: fit-content;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  &:before {
    position: absolute;
    content: '';
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent ${COLORS.BG_PAGE} transparent transparent;
    left: -6px;
    top: calc(50% - 8px);
    width: 0;
    height: 0;
  }
`;

const ButtonContainer = styled.div``;

const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: 8px;
  cursor: default !important;
`;

const TopBanner = styled.div`
  background-color: ${COLOR_PALETTE.GRAY_MIDDLE};
  padding: 16px;
  font-size: 14px;
  line-height: 1.57;
  border-radius: 6px 6px 0px 0px;
  color: ${COLOR_PALETTE.WHITE};
`;

const TopBannerTitle = styled.div`
  float: left;
  font-weight: bold;
  padding-right: 4px;
`;

const QuestionPreview = ({ question, i18n, section, categories }) => {
  let returnString = '';
  let returnTitle = '';
  const getTopBanner = () => {
    switch (question.type) {
      case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
      case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
        const isLearning = question.type === QUESTION_TYPES.GOAL_LEARNING_EVAL;
        let typeLabel = isLearning
          ? i18n._(t`LearningKeyForPreview`)
          : i18n._(t`BusinessKeyForPreview`);
        typeLabel = typeLabel.charAt(0).toUpperCase() + typeLabel.slice(1);
        returnTitle = i18n._(t`${typeLabel} evaluation: `);
        returnString = i18n._(
          t`A separate question will be generated for the ${typeLabel.toLowerCase()} for the selected goal cycle of each participant. The quantity of questions depend on the quantity of business goals.`,
        );
        break;
      }
      case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
      case QUESTION_TYPES.GOAL_LEARNING_PLAN: {
        const isLearning = question.type === QUESTION_TYPES.GOAL_LEARNING_PLAN;
        let typeLabel = isLearning
          ? i18n._(t`LearningKeyForPreview`)
          : i18n._(t`BusinessKeyForPreview`);
        typeLabel = typeLabel.charAt(0).toUpperCase() + typeLabel.slice(1);
        returnString = i18n._(
          t`The participant will be asked to select the goals for the selected goal cycle.`,
        );
        returnTitle = i18n._(t`${typeLabel} planning: `);
        break;
      }
      case QUESTION_TYPES.JOB_PROFILE: {
        returnTitle = i18n._(t`Role profile skills: `);
        returnString = i18n._(
          t`Each participant will see the relevant skills & competenties for their role for the enabled skill categories. In the review set-up roles can be enabled or disabled per participant.`,
        );
      }
    }

    return returnString ? (
      <TopBanner>
        <TopBannerTitle>{returnTitle}</TopBannerTitle>
        {returnString}
      </TopBanner>
    ) : undefined;
  };

  const getHeader = () => {
    switch (question.type) {
      case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
      case QUESTION_TYPES.GOAL_LEARNING_PLAN:
      case QUESTION_TYPES.CUSTOM: {
        return question.name;
      }
      case QUESTION_TYPES.SKILL: {
        return i18n._(t`How did [name] perform on the skill: Skill A`);
      }
      case QUESTION_TYPES.JOB_PROFILE: {
        return i18n._(t`How did [name] perform on the skill: [Role profile skill]`);
      }
      case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
      case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
        return i18n._(t`How did [name] perform on the goal: [Goal]`);
      }
      default: {
        return '';
      }
    }
  };

  const getLabel = () => {
    switch (question.type) {
      case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
      case QUESTION_TYPES.GOAL_LEARNING_PLAN:
      case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
      case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
        const isLearning = [
          QUESTION_TYPES.GOAL_LEARNING_EVAL,
          QUESTION_TYPES.GOAL_LEARNING_PLAN,
        ].includes(question.type);
        let typeLabel = isLearning
          ? i18n._(t`LearningKeyForPreview`)
          : i18n._(t`BusinessKeyForPreview`);
        typeLabel = typeLabel.charAt(0).toUpperCase() + typeLabel.slice(1);
        return `[${typeLabel}]`;
      }
      case QUESTION_TYPES.SKILL: {
        return `[${i18n._(t`Skill category`)}]`;
      }
      case QUESTION_TYPES.JOB_PROFILE: {
        const availableSkillCategories = !isEmpty(categories)
          ? isEmpty(question.skillCategories)
            ? categories
            : categories.filter((c) => question.skillCategories.includes(c.id))
          : [];
        const categoryNames = availableSkillCategories.map((c) => c.name).join(', ');
        return `[${categoryNames}]`;
      }
      default: {
        return '';
      }
    }
  };

  const getBody = () => {
    switch (question.type) {
      case QUESTION_TYPES.GOAL_BUSINESS_EVAL:
      case QUESTION_TYPES.GOAL_LEARNING_EVAL:
      case QUESTION_TYPES.CUSTOM: {
        return (
          <>
            {!question.hideRating && (
              <RatingRow
                rate={0}
                scale={
                  section.scaleLabels
                    ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      section.scaleLabels.map((label, index) => index)
                    : question.scaleLabels
                    ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      question.scaleLabels.map((label, index) => index)
                    : [0, 1, 2, 3, 4]
                }
                scaleLabels={
                  section.scaleLabels ||
                  question.scaleLabels || ['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5']
                }
                isSkipped={false}
                isSkippable={!!(section.isSkippable || question.isSkippable)}
                disabled={true}
              />
            )}
            <InputPlaceholder>
              {question.isCommentObligated
                ? i18n._(t`Explain your (mandatory) answer here...`)
                : `${i18n._(t`Explain your answer here`)}…`}
            </InputPlaceholder>
          </>
        );
      }
      case QUESTION_TYPES.SKILL:
      case QUESTION_TYPES.JOB_PROFILE: {
        return (
          <div style={!question.hideRating && { marginTop: 24 }}>
            {!question.hideRating && (
              <RatingRow
                rate={0}
                scale={[0, 1, 2, 3, 4]}
                scaleLabels={['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5']}
                isSkipped={false}
                isSkippable={!!(section.isSkippable || question.isSkippable)}
                disabled={true}
                expectedLevel={3}
                skillFocusAreas={[
                  {
                    name: `[${i18n._(t`Focus areas for this skill`)}]`,
                    level: 1,
                  },
                ]}
                hideFocusAreas={false}
              />
            )}
            <InputPlaceholder>
              {question.isCommentObligated
                ? i18n._(t`Explain your (mandatory) answer here...`)
                : `${i18n._(t`Explain your answer here`)}…`}
            </InputPlaceholder>
          </div>
        );
      }
      case QUESTION_TYPES.GOAL_BUSINESS_PLAN:
      case QUESTION_TYPES.GOAL_LEARNING_PLAN: {
        const isLearning = question.type === QUESTION_TYPES.GOAL_LEARNING_PLAN;
        let typeLabel = isLearning
          ? i18n._(t`LearningKeyForPreview`)
          : i18n._(t`BusinessKeyForPreview`);
        typeLabel = typeLabel.charAt(0).toUpperCase() + typeLabel.slice(1);
        return (
          <ButtonContainer>
            <StyledButton
              type="primary-border"
              disabled={true}
              label={i18n._(t`Add new ${typeLabel}`)}
              height={48}
            />
            <StyledButton
              type="primary-border"
              disabled={true}
              label={i18n._(t`Import existing ${typeLabel}`)}
              height={48}
            />
          </ButtonContainer>
        );
      }
      default: {
        return '';
      }
    }
  };

  const labelString = getLabel();
  return (
    <Container>
      {getTopBanner()}
      <TitleHeader>
        <QuestionTitle>
          {getHeader()}
          {labelString && <Label>{labelString}</Label>}
        </QuestionTitle>
      </TitleHeader>
      <Divider />
      <QuestionDescription>
        <StyledRickTextView html={question.description || ''} />
        {getBody()}
      </QuestionDescription>
    </Container>
  );
};

export default withI18n()(QuestionPreview);
