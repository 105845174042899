import React from 'react';

import styled from 'styled-components';

import CompanyAvatar from '~/components/CompanyAvatar';
import SvgIcon from '~/components/SvgIcon';
import Avatar from '~/components/UI/Avatar';

import mailIcon from '~/assets/mail.svg';
import phoneIcon from '~/assets/phone_iphone-24px.svg';

import { COLOR_PALETTE, COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const CompanyAvatarWrapper = styled(CompanyAvatar)`
  margin-left: 14px;
`;

const Content = styled.div`
  display: flex;
  padding: ${(props) => (props.$isPDF ? '0px 24px 14px' : '20px 24px 14px')};
`;

const ContentLeft = styled.div`
  display: flex;
  flex: 1;
`;
const ContentRight = styled.div`
  display: flex;
  align-items: flex-start;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: flex-start;
`;

const UserTitles = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const UserName = styled.div`
  color: ${COLOR_PALETTE.BLACK};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin-left: 14px;
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 470px;
`;

const UserInfoText = styled.div`
  color: ${COLORS.TEXT_SECONDARY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  margin-left: 7px;
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 250px;
`;

const RowWithIcon = styled.div`
  display: flex;
  align-items: center;

  ${(props) => props.$isMarginLeft && 'margin-left: 23px'}
`;

const PassportHeader = ({
  user = {},
  readOnly = false,
  children,
  companyLogo,
  isPDF = false,
  cvSettings = {},
}) => {
  return (
    <>
      <Content $isPDF={isPDF}>
        <ContentLeft>
          {(!isPDF || (isPDF && cvSettings.isMainInfo)) && (
            <Avatar
              user={user}
              size={64}
              showTooltip={false}
              noBorder={true}
              isPrintAvatar={isPDF}
            />
          )}
          {(!isPDF || (isPDF && cvSettings.isMainInfo)) && (
            <UserInfo>
              <UserTitles>
                <UserName>{getUserFullName(user)}</UserName>
              </UserTitles>
            </UserInfo>
          )}
        </ContentLeft>
        <ContentRight>
          {user.email && (!isPDF || (isPDF && cvSettings.isEmail)) && (
            <RowWithIcon>
              <SvgIcon
                url={mailIcon}
                width={'18px'}
                height={'18px'}
                isDefaultColor
                defaultColor={COLOR_PALETTE.DARK_GRAY}
              />
              <UserInfoText>{user.email}</UserInfoText>
            </RowWithIcon>
          )}
          {user.phone && (!isPDF || (isPDF && cvSettings.isPhone)) && (
            <RowWithIcon $isMarginLeft>
              <SvgIcon
                url={phoneIcon}
                width={'20px'}
                height={'20px'}
                isDefaultColor
                defaultColor={COLOR_PALETTE.DARK_GRAY}
              />
              <UserInfoText>{user.phone}</UserInfoText>
            </RowWithIcon>
          )}
          {!readOnly && <CompanyAvatarWrapper logoUrl={companyLogo} size={64} />}
        </ContentRight>
      </Content>
      {children}
    </>
  );
};

export default PassportHeader;
