import React from 'react';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';
import ColleagueItem from '~/components/Modals/ColleaguesModal/components/ColleagueItem';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { COLORS } from '~/styles';

const UsersTable = styled.div`
  margin-left: 8px;
  margin-right: 8px;
  min-height: 345px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: Poppins, serif;
  margin-bottom: 11px;
`;

const Title = styled.div`
  font-weight: 600;
  color: ${COLORS.TEXT_BLACK};
  margin-bottom: 5px;
`;

const SubTitle = styled.div`
  font-weight: normal;
  color: ${COLORS.TEXT_SECONDARY};
`;

const Items = styled.div`
  padding-bottom: 20px;
  padding-right: 5px;
  overflow: auto;
`;

const ColleaguesList = ({ users, totalUsers, $usersLoading, onClose, emptyFilters }) => {
  const { i18n } = useLingui();
  const isUsers = users && users.length > 0;

  return (
    <UsersTable items={users}>
      <Header>
        <Title>
          {totalUsers} {totalUsers === 1 ? i18n._('Result') : i18n._('Results')}
        </Title>
        <SubTitle>{i18n._('Results match all search criteria')}</SubTitle>
      </Header>
      <Items>
        <ShowSpinnerIfLoading loading={$usersLoading.value}>
          {!isUsers && (
            <Placeholder
              Icon={() => <Icon icon={ICONS.USERS} width={50} height={50} color={COLORS.BG_PAGE} />}
              title={
                emptyFilters ? (
                  <Trans>Type something or filter to find colleagues</Trans>
                ) : (
                  <Trans>No matches</Trans>
                )
              }
              subTitle={<Trans>No user matches your search or filter criteria</Trans>}
            />
          )}
          {isUsers &&
            users.map((user) => <ColleagueItem key={user.id} user={user} onClose={onClose} />)}
        </ShowSpinnerIfLoading>
      </Items>
    </UsersTable>
  );
};

export default ColleaguesList;
