import React from 'react';

import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';

import arrowIcon from '~/assets/mdi-arrow-left-copy.svg';

import { COLOR_PALETTE } from '~/styles';

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MainTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  color: black;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const StyledSvgIcon = styled(SvgIcon)`
  cursor: pointer;
`;

const DashboardBoxHeader = ({ title, subtitle, onClick }) => {
  return (
    <HeaderRow>
      <Titles>
        <MainTitle>{title}</MainTitle>
        <SubTitle>{subtitle}</SubTitle>
      </Titles>
      <StyledSvgIcon
        onClick={onClick}
        width="24px"
        height="24px"
        url={arrowIcon}
        isDefaultColor={true}
        defaultColor="var(--company-color)"
      />
    </HeaderRow>
  );
};

export default DashboardBoxHeader;
