import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { union } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import AddPeopleAutoCompleteModal from '~/components/AddPeopleAutoCompleteModal';
import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import { Icon, ICONS, ICON_SIZES } from '~/components/Icon';
import IconButton from '~/components/IconButton';
import { FieldGroup, FieldTitle } from '~/components/Text';
import AvatarCard from '~/components/UI/AvatarCard';

import { CONVERSATION_STATUSES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getCreator, getId, getParticipants, getStatus } from '~/selectors/currentConversation';
import { setParticipants } from '~/store/currentConversation/actions';
import { COLOR_PALETTE } from '~/styles';

const StyledFieldGroup = styled(FieldGroup)`
  margin-bottom: 40px;
`;

const UserSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ParticipantsWrapper = styled.div`
  overflow: auto;
  max-height: 300px;
  background: inherit;
`;

const AvatarRow = styled.div`
  padding: 10px 12px 10px 9px;
  border-radius: 6px;
  border: solid 1px #dbe1ef;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StyledFieldTitle = styled(FieldTitle)`
  margin-bottom: 8px;
`;

const WideButton = styled(Button)`
  border: 1px solid #dbe1ef;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
`;

const ParticipantsSection = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const participants = useSelector(getParticipants);
  const conversationId = useSelector(getId);
  const status = useSelector(getStatus);
  const creator = useSelector(getCreator);
  const $isAddParticipantsModal = useBoolState(false);

  const addCoaches = (newCoaches) => {
    const newCoachesIds = newCoaches.map((c) => c.id);
    dispatch(setParticipants(union(participants, newCoachesIds)));
  };

  const removeCoach = (coachId) => {
    const newParticipants = participants.filter((c) => c !== coachId);
    dispatch(setParticipants(newParticipants));
  };

  const askToRemoveCoach = async (coachId) => {
    if (conversationId && status !== CONVERSATION_STATUSES.DRAFT.key) {
      const confirmOptions = {
        title: i18n._(t`Are you sure?`),
        confirmText: i18n._(t`Delete`),
        cancelText: i18n._(t`Cancel`),
      };
      if (
        await confirm(
          i18n,
          i18n._(
            t`You are about to delete a participant from the conversation. All talking points and or comments made by this participant will be deleted. This cannot be undone.`,
            i18n,
          ),
          confirmOptions,
        )
      ) {
        removeCoach(coachId);
      }
    } else {
      removeCoach(coachId);
    }
  };

  return (
    <StyledFieldGroup>
      <StyledFieldTitle>
        <Trans>Participants</Trans>*
      </StyledFieldTitle>
      <UserSection>
        <ParticipantsWrapper>
          {!isEmpty(participants) &&
            participants
              .filter((pId) => pId !== creator)
              .map((pId) => (
                <AvatarRow key={pId}>
                  <AvatarCard type={AvatarCard.TYPES.SMALL} userId={pId} size={28} />
                  <IconButton size={24} color="none" noBorder onClick={() => askToRemoveCoach(pId)}>
                    <Icon
                      icon={ICONS.DELETE_BIN}
                      size={ICON_SIZES.LARGE}
                      color={COLOR_PALETTE.GRAY_MIDDLE}
                    />
                  </IconButton>
                </AvatarRow>
              ))}
        </ParticipantsWrapper>
        <WideButton
          label={`+ ${i18n._(t`Participants`)}`}
          type="primary-border-wide"
          onClick={$isAddParticipantsModal.on}
        />
      </UserSection>
      {$isAddParticipantsModal.value && (
        <AddPeopleAutoCompleteModal
          title={i18n._(t`Add participants`)}
          usersToHide={participants}
          onModalClose={$isAddParticipantsModal.off}
          onSubmit={addCoaches}
        />
      )}
    </StyledFieldGroup>
  );
};

export default React.memo(ParticipantsSection);
