import React, { memo } from 'react';

import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import SkillName from '~/components/Skill/SkillName';
import SkillProgressBar from '~/components/SkillProgressBar';
import { Header7Dark } from '~/components/UI/Typographics/headers';

import { SKILL_CATEGORY_TYPE, SKILL_SOURCE } from '~/constants/skills';
import { COLORS, COLOR_PALETTE } from '~/styles';

import { prepareItemsForSkillsProgressBar } from '../utils';

const CategoryTitle = styled(Header7Dark)`
  margin: 12px 0;
  text-transform: uppercase;
`;

const SkillContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
`;

const SkillItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 314px;
  padding: 10px 15px 20px;
  margin: 0 0 4px;
  border-radius: 6px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  & .skillName {
    width: 90%;
    font-weight: bold;
    font-size: 14px;
    color: ${COLOR_PALETTE.BLACK};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  transition: all ease 0.3s;

  &:hover {
    transition: all ease 0.3s;
    border-color: ${COLOR_PALETTE.GRAY_SEMI_SOFT};
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12);
    background-color: ${COLOR_PALETTE.WHITE};
  }
`;

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const SkillCards = memo(({ rates, jobProfileSkills, category, onSkillClick }) => {
  const skills = jobProfileSkills
    .filter(
      (skill) =>
        skill.categories.includes(category.id) ||
        (category.type &&
          category.type === SKILL_CATEGORY_TYPE.soft &&
          (skill.source === SKILL_SOURCE.learned || skill.source === SKILL_SOURCE.learned2)),
    )
    .filter((s) => s);
  const { i18n } = useLingui();

  return (
    skills.length > 0 && (
      <Wrapper key={category.id}>
        <CategoryTitle>{category.name}</CategoryTitle>
        <SkillContainer>
          {skills.map((skill) => {
            return (
              <SkillItemContainer onClick={() => onSkillClick(skill)} key={skill.id}>
                <span className="skillName">
                  <SkillName skill={skill} />
                </span>
                <SkillProgressBar
                  title={<SkillName skill={skill} />}
                  $backgroundAllProgress={COLORS.BG_PAGE}
                  items={prepareItemsForSkillsProgressBar({
                    i18n,
                    rates,
                    skill,
                  })}
                  isSmall
                />
              </SkillItemContainer>
            );
          })}
        </SkillContainer>
      </Wrapper>
    )
  );
});

export { SkillCards };
