import React, { useState, useEffect } from 'react';

import { ProductName, RESPONSE_STATUSES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import upperFirst from 'lodash/upperFirst';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import ButtonDeprecated from '~/components/Button';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { confirm } from '~/components/ConfirmDialog';
import { Dropdown } from '~/components/Dropdown';
import { ICONS } from '~/components/Icon';
import IconMenu from '~/components/IconMenu';
import OverviewHeading from '~/components/OverviewHeading';
import ProgressBarWide from '~/components/ProgressBarWide';
import { SearchField } from '~/components/Text';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import ActionsContainer from '~/components/UI/ActionsContainer';
import Divider from '~/components/UI/Divider';
import Table, { TableCol, TableRow } from '~/components/UI/Table';
import BaseLayout from '~/layouts/BaseLayout';
import CompanyModulesModal from '~/pages/SuperAdminDashboard/CompanyModulesModal';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { getUser } from '~/selectors/baseGetters';
import {
  downloadCompaniesCSVSuperAdmin,
  downloadCompanyModulesCSVSuperAdmin,
  getCompaniesSuperAdmin,
  getCompanySuperAdmin,
  setCompanyEnabledSuperAdmin,
} from '~/services/companies';
import { createCompanyDeleteRequest } from '~/services/companyDeleteRequests';
import { getQuickStartsSuperAdmin } from '~/services/quickStarts';
import { downloadUsersCSVSuperAdmin } from '~/services/users';
import { COLOR_PALETTE } from '~/styles';
import { isProductEnabled, isProductSettingEnabled } from '~/utils/company';
import convertToTimeString from '~/utils/convertToTimeString';
import getQuickStartTotalProgress from '~/utils/getQuickStartTotalProgress';

const ActionsContainerWrapper = styled(ActionsContainer)`
  height: 48px;
  align-items: center;
  border: unset;
`;

const Wrapper = styled.div`
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const StyledSearch = styled(SearchField)`
  height: 32px;
  width: 248px;
  & > input {
    border-radius: 6px;
    font-size: 14px;
    height: 32px;
  }
`;

const StyledTable = styled(Table)`
  background-color: white;
`;

const StyledRow = styled(TableRow)`
  padding: 0 16px;
`;

const CompanyName = styled(TableCol)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLOR_PALETTE.BLACK};
  cursor: pointer;
  word-break: break-word;
`;

const CompanyText = styled(TableCol)`
  font-size: 14px;
  line-height: 1.57;
  cursor: pointer;
  color: ${COLOR_PALETTE.DARK_GRAY};
  word-break: break-word;
`;

const ContextMenuContainer = styled.div`
  width: min-content;
`;

const CompanyEnabled = styled(TableCol)`
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #${(props) => (props.$enabled ? '29ccab' : 'e93c3c')};
`;

const CompanyQuickStart = styled(TableCol)`
  display: flex;
  width: 140px;
  align-items: center;
`;

const Progress = styled.div`
  font-size: 14px;

  color: ${COLOR_PALETTE.DARK_GRAY};
  line-height: 1.57;
  margin: 0 0 0 8px;
`;

const FilterWrapper = styled.div`
  margin-left: 10px;
`;

const LS_KEY = LS_KEYS.LS_SUPER_ADMIN_COMPANIES;
const COLUMNS_KEYS = {
  NAME: 'name',
  EMAIL: 'email',
  CREATED_AT: 'createdAt',
  ENABLED: 'enabled',
  MEMBERS: 'members',
  AVG_LOG_LM: 'avgLogLm',
  QUICK_START: 'quickStart',
  PRODUCT_JOB_MATRIX: 'productJobMatrix',
  PRODUCT_CAREER: 'productCareer',
  PRODUCT_PERFORMANCE: 'productPerformance',
  PRODUCT_TALENT: 'productTalent',
  PRODUCT_ENGAGEMENT: 'productEngagement',
  PRODUCT_INTEGRATIONS: 'productIntegrations',
  PRODUCT_L_AND_O: 'productLearningAndOnboarding',
  SETTINGS_GOALS: 'settingsGoals',
};
const DEFAULT_SELECTED_COLUMNS = [
  COLUMNS_KEYS.EMAIL,
  COLUMNS_KEYS.CREATED_AT,
  COLUMNS_KEYS.ENABLED,
  COLUMNS_KEYS.MEMBERS,
  COLUMNS_KEYS.AVG_LOG_LM,
  COLUMNS_KEYS.QUICK_START,
];

const DEFAULT_FILTERS = {
  search: '',
  selectedColumns: DEFAULT_SELECTED_COLUMNS,
};

const SuperAdminCompanies = ({ i18n }) => {
  const [allCompanies, setAllCompanies] = useState({});
  const [modalCompany, setModalCompany] = useState({});
  const [quickStartProgress, setQuickStartProgress] = useState({});
  const preventNavigation = useBoolState(false);
  const history = useHistory();
  const user = useSelector(getUser);
  const { addToast } = useToasts();
  const [filters, setFilters] = useLocalStorage(LS_KEY, DEFAULT_FILTERS);
  const isColSelected = (key) => key === undefined || filters.selectedColumns.includes(key);
  const getProductStatus = (company, product) => company.products?.[product]?.status;

  const cols = [
    {
      title: i18n._(t`Name`),
      width: '196px',
      render: (company) => <CompanyName>{company.name}</CompanyName>,
    },
    {
      key: COLUMNS_KEYS.EMAIL,
      title: i18n._(t`E-mail`),
      width: '166px',
      render: (company) => <CompanyText>{company.email}</CompanyText>,
    },
    {
      key: COLUMNS_KEYS.CREATED_AT,
      title: i18n._(t`Created at`),
      width: '136px',
      render: (company) => (
        <CompanyText>{convertToTimeString(company?.meta?.createdDate)}</CompanyText>
      ),
    },
    {
      key: COLUMNS_KEYS.ENABLED,
      title: i18n._(t`Enabled`),
      width: '96px',
      render: (company) => (
        <CompanyEnabled $enabled={company.enabled}>
          {upperFirst(company.enabled.toString())}
        </CompanyEnabled>
      ),
    },
    {
      key: COLUMNS_KEYS.MEMBERS,
      title: i18n._(t`Members`),
      width: '102px',
      render: (company) => <CompanyText>{Object.values(company.connections).length}</CompanyText>,
    },
    {
      key: COLUMNS_KEYS.AVG_LOG_LM,
      title: i18n._(t`avg. log. lm`),
      width: '102px',
      render: (company) => (
        <CompanyText>
          {company.averageLoginsLastMonth === undefined || company.averageLoginsLastMonth === null
            ? i18n._(t`N/A`)
            : round(company.averageLoginsLastMonth, 1)}
        </CompanyText>
      ),
    },
    {
      key: COLUMNS_KEYS.QUICK_START,
      title: i18n._(t`Quick Start`),
      width: '180px',
      render: (company) => (
        <>
          {company.quickStartProgress >= 0 ? (
            <CompanyQuickStart>
              <ProgressBarWide
                height="8px"
                value={company.quickStartProgress}
                isActive
                style={{ borderRadius: '6px' }}
              />
              <Progress>{company.quickStartProgress}%</Progress>
            </CompanyQuickStart>
          ) : (
            <CompanyText>ERROR</CompanyText>
          )}
        </>
      ),
    },
    {
      key: COLUMNS_KEYS.PRODUCT_JOB_MATRIX,
      title: i18n._(t`Product: Job matrix`),
      width: '180px',
      render: (company) => {
        const isJobMatrixProductEnabled = isProductEnabled(company, ProductName.JOB_MATRIX);
        const productStatus = getProductStatus(company, ProductName.JOB_MATRIX);
        return (
          <CompanyEnabled $enabled={isJobMatrixProductEnabled}>
            {upperFirst(productStatus.toString())}
          </CompanyEnabled>
        );
      },
    },
    {
      key: COLUMNS_KEYS.PRODUCT_CAREER,
      title: i18n._(t`Product: Career`),
      width: '180px',
      render: (company) => {
        const isCareerProductEnabled = isProductEnabled(company, ProductName.CAREER);
        const productStatus = getProductStatus(company, ProductName.CAREER);
        return (
          <CompanyEnabled $enabled={isCareerProductEnabled}>
            {upperFirst(productStatus.toString())}
          </CompanyEnabled>
        );
      },
    },
    {
      key: COLUMNS_KEYS.PRODUCT_PERFORMANCE,
      title: i18n._(t`Product: Performance`),
      width: '180px',
      render: (company) => {
        const isPerformanceProductEnabled = isProductEnabled(company, ProductName.PERFORMANCE);
        const productStatus = getProductStatus(company, ProductName.PERFORMANCE);
        return (
          <CompanyEnabled $enabled={isPerformanceProductEnabled}>
            {upperFirst(productStatus.toString())}
          </CompanyEnabled>
        );
      },
    },
    {
      key: COLUMNS_KEYS.PRODUCT_ENGAGEMENT,
      title: i18n._(t`Product: Engagement`),
      width: '180px',
      render: (company) => {
        const isEngagementProductEnabled = isProductEnabled(company, ProductName.ENGAGEMENT);
        const productStatus = getProductStatus(company, ProductName.ENGAGEMENT);
        return (
          <CompanyEnabled $enabled={isEngagementProductEnabled}>
            {upperFirst(productStatus.toString())}
          </CompanyEnabled>
        );
      },
    },
    {
      key: COLUMNS_KEYS.PRODUCT_TALENT,
      title: i18n._(t`Product: Talent`),
      width: '180px',
      render: (company) => {
        const isTalentProductEnabled = isProductEnabled(company, ProductName.TALENT);
        const productStatus = getProductStatus(company, ProductName.TALENT);
        return (
          <CompanyEnabled $enabled={isTalentProductEnabled}>
            {upperFirst(productStatus.toString())}
          </CompanyEnabled>
        );
      },
    },
    {
      key: COLUMNS_KEYS.PRODUCT_INTEGRATIONS,
      title: i18n._(t`Product: Integrations`),
      width: '180px',
      render: (company) => {
        const isIntegrationsProductEnabled = isProductEnabled(company, ProductName.INTEGRATIONS);
        const productStatus = getProductStatus(company, ProductName.INTEGRATIONS);
        return (
          <CompanyEnabled $enabled={isIntegrationsProductEnabled}>
            {upperFirst(productStatus.toString())}
          </CompanyEnabled>
        );
      },
    },
    {
      key: COLUMNS_KEYS.PRODUCT_L_AND_O,
      title: i18n._(t`Product: Learning & Onboarding`),
      width: '180px',
      render: (company) => {
        const isLearningAndOnboardingProductEnabled = isProductEnabled(
          company,
          ProductName.LEARNING_AND_ONBOARDING,
        );
        const productStatus = getProductStatus(company, ProductName.LEARNING_AND_ONBOARDING);
        return (
          <CompanyEnabled $enabled={isLearningAndOnboardingProductEnabled}>
            {upperFirst(productStatus.toString())}
          </CompanyEnabled>
        );
      },
    },
    {
      key: COLUMNS_KEYS.SETTINGS_GOALS,
      title: i18n._(t`Settings: Goals`),
      width: '180px',
      render: (company) => {
        const isGoalsSettingEnabled = isProductSettingEnabled(
          company,
          ProductName.PERFORMANCE,
          'goals',
        );
        return (
          <CompanyEnabled $enabled={isGoalsSettingEnabled}>
            {upperFirst(isGoalsSettingEnabled.toString())}
          </CompanyEnabled>
        );
      },
    },
    { width: '40px', render: () => <></> },
  ];

  const colsFiltered = cols.filter(({ key }) => isColSelected(key));

  useEffect(() => {
    const fetch = async () => {
      const [companies, quickStartCompanies] = await Promise.all([
        getCompaniesSuperAdmin(),
        getQuickStartsSuperAdmin(),
      ]);

      // Add quick start progress data to each company
      Object.values(quickStartCompanies).forEach((quickStart) => {
        const { company } = quickStart;

        const progress = getQuickStartTotalProgress(quickStart);

        if (companies[company]) {
          companies[company].quickStartProgress = progress;
        }
      });

      setAllCompanies(companies);
      setQuickStartProgress(quickStartCompanies);
    };
    fetch();
  }, []);

  const renderRow = (company) => {
    const menuItems = [
      {
        label: i18n._(company.enabled ? t`Disable` : t`Enable`),
        action: async () => {
          await setCompanyEnabledSuperAdmin(company.id, !company.enabled);
          const updatedCompany = await getCompanySuperAdmin(company.id);

          setAllCompanies({ ...allCompanies, [company.id]: updatedCompany });
        },
      },
      {
        label: i18n._(t`Delete`),
        action: async () => {
          if (
            await confirm(
              i18n,
              i18n._(
                t`Are you sure you want to PERMANENTLY delete the company ${company.name} and ALL OF ITS USERDATA with email address ${company.email} from the database? THIS ACTION CANNOT BE UNDONE!`,
              ),
            )
          ) {
            if (
              await confirm(
                i18n,
                i18n._(
                  t`Are you ENTIRELY SURE of PERMANENTLY DELETING company ${company.name} with all of its userdata?`,
                ),
              )
            ) {
              const { status } = await createCompanyDeleteRequest({
                name: company.name,
                company: company.id,
              });

              // show success toast
              if (status === RESPONSE_STATUSES.SUCCESS) {
                addToast({
                  title: i18n._(t`The DELETE company request is created!`),
                  subtitle: i18n._(t`You can find it in Delete company requests page.`),
                  type: TOAST_TYPES.INFO,
                });
              }
            } else {
              alert(i18n._(t`Company ${company.name} will be retained. Phew!`));
            }
          } else {
            alert(i18n._(t`Company ${company.name} will be retained.`));
          }
        },
      },
      {
        label: i18n._(t`Manage modules`),
        action: () => {
          setModalCompany(company);
        },
      },
    ];

    return (
      <StyledRow
        onClick={() => {
          if (preventNavigation.value) {
            return false;
          }
          history.push(routes.SUPERADMIN_COMPANY_MEMBERS.url, { companyId: company.id });
        }}
        key={company.id}
      >
        {colsFiltered.map((item) => item.render(company))}
        <TableCol>
          <ContextMenuContainer
            onMouseEnter={preventNavigation.on}
            onMouseLeave={preventNavigation.off}
          >
            <IconMenu items={menuItems} />
          </ContextMenuContainer>
        </TableCol>
      </StyledRow>
    );
  };

  const handleFilters = (key, value) => setFilters((state) => ({ ...state, [key]: value }));

  const searchCompany = (company) => {
    return (
      !filters.search ||
      company.name?.toLowerCase().includes(filters.search.toLowerCase()) ||
      company.email?.toLowerCase().includes(filters.search.toLowerCase())
    );
  };

  const resetFilters = () => setFilters(DEFAULT_FILTERS);

  return (
    <>
      <OverviewHeading
        title={i18n._(t`Companies`)}
        description={i18n._(
          t`Keep track of the adoption, performance and development of your workforce`,
        )}
      >
        {user?.superAdminLevel !== undefined && user?.superAdminLevel >= 1 && (
          <ButtonDeprecated
            label={i18n._(t`Export users as CSV`)}
            onClick={() => downloadUsersCSVSuperAdmin()}
          />
        )}
        <ButtonDeprecated
          label={i18n._(t`Export modules CSV`)}
          onClick={downloadCompanyModulesCSVSuperAdmin}
        />
        <ButtonDeprecated label={i18n._(t`Export CSV`)} onClick={downloadCompaniesCSVSuperAdmin} />
      </OverviewHeading>
      <BaseLayout>
        <Wrapper>
          <ActionsContainerWrapper noBottomBorder>
            <StyledSearch
              placeholder={i18n._(t`Search`)}
              value={filters.search}
              onChange={(e) => handleFilters('search', e.target.value)}
            />
            <FilterWrapper>
              <Dropdown
                items={cols.filter((item) => item.key).sort((a, b) => (b.name > a.name ? -1 : 1))} // sort a-z
                selectedItems={cols.filter((item) => item.key && isColSelected(item.key))}
                placeholder={i18n._(t`Columns`)}
                stringifyItem={(item) => item.title}
                onChange={(selectedItems) =>
                  handleFilters(
                    'selectedColumns',
                    selectedItems.map((item) => item.key),
                  )
                }
                isSingleSelect={false}
              />
            </FilterWrapper>

            <FilterWrapper>
              <Button
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.MEDIUM}
                label={i18n._(t`Reset all columns`)}
                onClick={resetFilters}
                icon={ICONS.CLOSE}
              />
            </FilterWrapper>
          </ActionsContainerWrapper>
          <Divider />
          <StyledTable
            cols={colsFiltered.map(({ render: _render, ...other }) => other)}
            items={Object.values(allCompanies).filter(searchCompany)}
            renderRow={renderRow}
            loading={isEmpty(allCompanies)}
          />
        </Wrapper>
      </BaseLayout>
      {!isEmpty(modalCompany) && (
        <CompanyModulesModal
          onClose={() => setModalCompany({})}
          modalCompany={modalCompany}
          setAllCompanies={setAllCompanies}
          allCompanies={allCompanies}
          quickStartProgress={quickStartProgress}
        />
      )}
    </>
  );
};

export default withI18n()(SuperAdminCompanies);
