import React, { useState, useEffect } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SearchSelectButton from '~/components/SearchSelectButton';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import Spider from '~/components/Spider';
import { Header4 } from '~/components/UI/Typographics/headers';

import useBoolState from '~/hooks/useBoolState';
import getLang from '~/selectors/getLang';
import { getSkillsRatings } from '~/services/ratings';
import { COLORS } from '~/styles';
import getSkillName from '~/utils/getSkillName';

import { prepareRadioData } from '../utils';

const Content = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  box-sizing: border-box;
`;

const FiltersRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-bottom: 12px;
  box-sizing: border-box;

  .filter-button:not(:first-child) {
    margin-left: 8px;
  }
`;

const Section = styled.div`
  padding: 16px 20px;
  box-sizing: border-box;
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDER_HARD};
  margin: 24px;
`;

const StyledHeader4 = styled(Header4)`
  margin: 0 0 4px;
`;

function SpiderSection({ jobProfile, scaleLabels, skillCategories, userId }) {
  const { i18n } = useLingui();

  const [rates, setRates] = useState({});
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const lang = useSelector(getLang);
  const $loading = useBoolState();

  useEffect(() => {
    async function fetchData() {
      $loading.on();
      const skillsIds = jobProfile.skills.map((s) => s.id);
      const skillsRates = await getSkillsRatings({ skills: skillsIds, createdFor: userId });
      const ratesRevies = uniq(
        [...skillsRates.selfRatings, ...skillsRates.coachRatings, ...skillsRates.peerRatings]
          .map((rate) => (rate.review ? rate.review.id : null))
          .filter((r) => r),
      );
      const skillCategoriesIds = skillCategories.map((c) => c.id);
      setSelectedCategories(skillCategoriesIds);
      setSelectedReviews(ratesRevies);
      setSelectedSkills(skillsIds);
      setRates(skillsRates);
      $loading.off();
    }
    fetchData();
    // eslint-disable-next-line
  }, [jobProfile]);

  const preparedRatings = prepareRadioData({
    i18n,
    lang,
    rates,
    jobProfile,
    selectedSkills,
    selectedReviews,
    selectedCategories,
    skillCategories,
  });

  // for skills maxValue equal to length scaleLabels in question with skills or skillsJobProfile
  const maxValueForSkills = scaleLabels.length;

  const skillsOptions = jobProfile.skills.map((s) => ({
    label: getSkillName(s, lang),
    id: s.id,
  }));

  const categoriesOptions = skillCategories.map((c) => ({
    label: c.name,
    id: c.id,
  }));

  const reviewsOptions = !isEmpty(rates)
    ? uniqBy(
        [...rates.selfRatings, ...rates.coachRatings, ...rates.peerRatings]
          .map((rate) => {
            if (rate.review) {
              return {
                label: rate.review.name,
                id: rate.review.id,
              };
            }
          })
          .filter((i) => i.id),
        'id',
      )
    : [];

  return (
    <Section>
      <StyledHeader4>
        <Trans>{i18n._(t`Per skill`)}</Trans>
      </StyledHeader4>

      <Content>
        <FiltersRow>
          <SearchSelectButton
            checkedList={selectedReviews}
            title={i18n._(t`Review`)}
            options={reviewsOptions}
            handleChange={(items) => setSelectedReviews(items)}
            width="187px"
            className="filter-button"
            isScroll
            isSearchAll
          />
          <SearchSelectButton
            checkedList={selectedCategories}
            title={i18n._(t`Skill category`)}
            options={categoriesOptions}
            handleChange={(items) => setSelectedCategories(items)}
            width="187px"
            className="filter-button"
            isScroll
            isSearchAll
          />
          <SearchSelectButton
            checkedList={selectedSkills}
            title={i18n._(t`Skills`)}
            options={skillsOptions}
            handleChange={(items) => setSelectedSkills(items)}
            width="187px"
            className="filter-button"
            isScroll
            isSearchAll
          />
        </FiltersRow>
        <ShowSpinnerIfLoading loading={$loading.value}>
          <Spider data={preparedRatings} maxValue={maxValueForSkills} />
        </ShowSpinnerIfLoading>
      </Content>
    </Section>
  );
}

SpiderSection.propTypes = {
  jobProfile: PropTypes.object.isRequired,
  scaleLabels: PropTypes.array.isRequired,
};

export { SpiderSection };
