import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getLikes(filters = {}) {
  const data = {
    ...filters,
  };
  return cloudRequest(serverRoutes.likes.getLikes, { ...data });
}

export function getCompanyLikes() {
  return cloudRequest(serverRoutes.likes.getCompanyLikes);
}

export function getUserLikes(userId, filters = {}) {
  return cloudRequest(serverRoutes.likes.getUserLikes(userId), { ...filters });
}

// @deprecated - use createLikes instead
export function createLike(jobProfileId, value) {
  const data = { jobProfileId, value };
  return cloudRequest(serverRoutes.likes.createLike, {}, data);
}

export function createLikes(likes) {
  const data = { likes };
  return cloudRequest(serverRoutes.likes.createLikes, {}, data);
}
