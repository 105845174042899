import React, { MouseEventHandler } from 'react';

import { REVIEW_STATUSES } from '@learned/types';
import { t } from '@lingui/macro';
import styled, { css } from 'styled-components';

import { NameWithQuestions } from '~/pages/Surveys/NameWithQuestions';

import type { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';

import { FlexRow, NameRow } from '../../../../design';

import type { IReviewTemplate } from '@learned/types';
import type { I18n } from '@lingui/core';

const DateRow = styled.div<{ unplanned?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  width: 170px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  ${({ unplanned }) =>
    unplanned &&
    css`
      color: ${COLORS.CONFIRMATION_MODAL_DELETE};
    `}
`;

export enum SORT_OPTIONS {
  NAME_A_Z = 'name_asc',
  NAME_Z_A = 'name_desc',
  CREATED_SOON_LATER = 'created_date_asc',
  CREATED_LATER_SOON = 'created_date_desc',
  UPDATED_SOON_LATER = 'updated_date_asc',
  UPDATED_LATER_SOON = 'updated_date_desc',
}

const COLUMNS: IColumnTable<IReviewTemplate>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    renderCell: (item, onClick: MouseEventHandler<HTMLDivElement> | undefined) => {
      const numberOfQuestions = item.sections
        .map((i) => i.questions.length)
        .reduce((partialSum: number, a: number) => partialSum + a, 0);

      return (
        <NameRow>
          {/* @ts-ignore */}
          <FlexRow onClick={() => onClick(item)}>
            <NameWithQuestions
              name={item.name as string}
              questionsTotal={numberOfQuestions}
              isGrayColor={item?.status === REVIEW_STATUSES.DRAFT}
              isLeftMargin={false}
            />
          </FlexRow>
        </NameRow>
      );
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Created`),
    accessor: 'created',
    minWidth: '162px',
    sortBy: {
      asc: {
        key: SORT_OPTIONS.CREATED_SOON_LATER,

        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: SORT_OPTIONS.CREATED_LATER_SOON,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
    renderCell: (item: any) => {
      return <DateRow>{convertToTimeString(item.meta.createdDate)}</DateRow>;
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Updated`),
    accessor: 'updated',
    minWidth: '162px',
    sortBy: {
      asc: {
        key: SORT_OPTIONS.UPDATED_SOON_LATER,

        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: SORT_OPTIONS.UPDATED_LATER_SOON,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
    renderCell: (item: any) => {
      return (
        <DateRow>
          {convertToTimeString(item.meta.lastModifiedDate || item.meta.createdDate)}
        </DateRow>
      );
    },
  },
];

export { COLUMNS };
