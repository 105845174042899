import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

/**
 * @param options
 * @param [options.search]
 * @param [options.status]
 * @param [options.limit]
 * @param {string[]} populate
 * @param {string[]} [fields]
 * @returns {Promise<AxiosResponse<*>>}
 */
export function getJobProfiles(options = {}, populate = [], fields = []) {
  return cloudRequest(serverRoutes.jobProfiles.getJobProfiles, { ...options, populate, fields });
}

export function createJobProfiles(jobProfiles) {
  return cloudRequest(serverRoutes.jobProfiles.createJobProfiles, {}, jobProfiles);
}

export function updateJobProfile(id, data) {
  return cloudRequest(serverRoutes.jobProfiles.updateJobProfile, { id }, data);
}

export function copyJobProfile(jobProfileId) {
  return cloudRequest(serverRoutes.jobProfiles.copyJobProfile(jobProfileId));
}

export function updateSkillsOfJobProfile(id, skills) {
  return cloudRequest(serverRoutes.jobProfiles.updateSkillsOfJobProfile, { id }, skills);
}

export function getJobProfile(id, populate = []) {
  return cloudRequest(serverRoutes.jobProfiles.getJobProfile, { id, populate });
}

/*
 * jobProfileIds array of ids
 * for instance:
 * ['jobProfile1Id', 'jobProfile2Id', ...]
 * */
export function deleteJobProfiles(jobProfileIds) {
  return cloudRequest(serverRoutes.jobProfiles.deleteJobProfiles, {}, jobProfileIds);
}

/*
 * data example [[JobProfile1Id, updateData1{}], [JobProfile2Id, updateData2{}], ...]
 * */
export function updateJobProfiles(data) {
  return cloudRequest(serverRoutes.jobProfiles.updateJobProfiles, {}, data);
}

export function getSuggestedJobTitles({ search, language }) {
  return cloudRequest(serverRoutes.jobProfiles.getSuggestedJobTitles, { search, language });
}

export function getJobProfileRelevanciesProgress(jobProfileId, userId) {
  return cloudRequest(
    serverRoutes.jobProfiles.getJobProfileRelevanciesProgress(jobProfileId, userId),
  );
}
