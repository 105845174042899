import React, { useState } from 'react';

import styled from 'styled-components';

import ExpandMoreIcon from '~/components/Icons/ExpandMore';
import BoxWithBorder from '~/components/UI/BoxWithBorder';

import { COLOR_SET } from '~/styles';

const HeaderContainer = styled(BoxWithBorder)`
  display: flex;
  justify-content: space-between;
  height: 81px;
  padding: 16px 24px;
`;

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 7px;
`;

const Title = styled.div`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '600')};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${(props) => (props.color ? props.color : COLOR_SET.BLACK)};
`;

const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  transform: ${(props) => !props.rotate && 'rotate(180deg)'};
  overflow: hidden;
  &:disabled {
    cursor: default;
  }
  & > svg {
    margin: -10px 0;
  }
`;

const Header = ({ title, subtitle, onHideButtonClick, hidden }) => {
  const [isHidden, setIsHidden] = useState(hidden === true);

  const onButtonClick = async () => {
    await onHideButtonClick(!isHidden);
    setIsHidden(!isHidden);
  };

  return (
    <HeaderContainer $noBorder>
      <TitlesContainer>
        {title ? (
          <Title fontSize="16px" fontWeight="bold">
            {title}
          </Title>
        ) : null}
        {subtitle ? <Title color={COLOR_SET.GRAY}>{subtitle}</Title> : null}
      </TitlesContainer>

      {onHideButtonClick ? (
        <ArrowButton rotate={isHidden} onClick={onButtonClick}>
          <ExpandMoreIcon size={30} />
        </ArrowButton>
      ) : null}
    </HeaderContainer>
  );
};

export default Header;
