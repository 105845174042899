import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { COLOR_SET } from '~/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: 600;
  min-width: 31px;
  margin-right: 16px;
  line-height: 1.33;
  color: #000;
`;

const All = styled.div`
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  border-radius: 8px;
  background-color: #fff;
  border: solid 1px #dae4f7;
  position: relative;
  overflow: hidden;
`;

const Done = styled.div`
  position: absolute;
  left: 0;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  background-color: var(--company-color);
`;

const ProgressBar = ({
  value,
  width,
  height,
  isHideValue,
  maxValue,
  valueAtEnd,
  styles,
  color,
}) => {
  let valueValid = value || 0;

  return (
    <Wrapper style={styles}>
      {!isHideValue && !valueAtEnd && <Value>{`${valueValid}%`}</Value>}
      <All $width={width} $height={height}>
        <Done
          $height={height}
          $width={(width / maxValue) * valueValid}
          $background={color ? color : COLOR_SET.BLUE}
        />
      </All>
      {!isHideValue && valueAtEnd && <Value>{`${valueValid}%`}</Value>}
    </Wrapper>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  isHideValue: PropTypes.bool,
  maxValue: PropTypes.number,
  valueAtEnd: PropTypes.bool,
  color: PropTypes.string,
  styled: PropTypes.object,
};

ProgressBar.defaultProps = {
  width: 150,
  height: 12,
  isHideValue: false,
  maxValue: 100,
  valueAtEnd: false,
};

export default ProgressBar;
