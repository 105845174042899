import { useEffect } from 'react';

function useKeyPress(targetKey: string, callback: () => void) {
  useEffect(() => {
    const downHandler = (event: KeyboardEvent) => {
      if (event.key !== targetKey && event.target) {
        return;
      }
      if ((event.target as HTMLDivElement)?.classList?.contains('ql-editor')) {
        return;
      }
      callback();
    };
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [callback, targetKey]);
}

export { useKeyPress };
