import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const RoundCheckBoxSelected = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst>
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx="12" cy="12" r="8" />
      <path
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm-1 12.502-4.5-4.5 1.414-1.414L11 13.674l5.586-5.586L18 9.502l-7 7z"
        fill="#1E0863"
      />
    </g>
  </InlineSvg>
);

RoundCheckBoxSelected.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

RoundCheckBoxSelected.defaultProps = {
  size: 24,
  className: '',
};

export default RoundCheckBoxSelected;
