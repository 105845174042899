import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import IconMenu from '~/components/IconMenu';
import PaginationBar from '~/components/PaginationBar';
import { TablePlaceholder } from '~/components/TablePlaceholder';

import { FixedTable } from './components/FixedTable';
import { Header } from './components/Header';
import { Placeholder } from './components/Placeholder';
import { Top } from './components/Top';
import {
  TD,
  TR,
  TableWrapper,
  IconMenuWrapper,
  MainTable,
  Wrapper,
  TableContainer,
} from './design';

import { ColumnPosition, IColumnTable } from '~/@types/table';

import type { ITableGridProps } from './types';

const TableGrid = ({
  columns,
  data,
  onRowClick,
  sortBy,
  setSortBy,
  isLoading,
  isMenuVisible,
  createMenuItems,
  onColClick,
  isScrollbarVisible,
  isLeftColumnsStriped,
  isRightColumnsStriped,
  leftMinWidth,
  rightMinWidth,
  visibleColumn,
  showSortBy,
  actionButton,
  filtersProps: { filters, filterComponents, isToggleHideFilterVisible, isFiltered, resetFilters },
  paginationProps: { pagination, onPaginationChange, changePagination, totalCount },
  placeholderProps,
  showTopArea = true,
}: ITableGridProps) => {
  const [hovered, setHovered] = useState(-1);

  const handleSortBy = (key?: string) => {
    setSortBy && setSortBy(key || '');
  };

  const sortProps = {
    sortBy,
    handleSortBy,
  };

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    const newPagination = {
      ...pagination,
      limit,
    };

    changePagination(newPagination);
    onPaginationChange?.(newPagination);
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    const newPagination = {
      ...pagination,
      skip,
      index,
    };

    changePagination(newPagination);
    onPaginationChange?.(newPagination);
  };

  const isFixedFilter = (column: IColumnTable) => !column.isFixed;

  return (
    <Wrapper>
      {showTopArea && (
        <Top
          filters={filters}
          actionButton={actionButton}
          filterComponents={filterComponents}
          isToggleHideFilterVisible={isToggleHideFilterVisible}
          resetFilters={resetFilters}
        />
      )}
      <TableContainer>
        <FixedTable
          columns={columns.filter(
            (column) => column.isFixed && column.position === ColumnPosition.LEFT,
          )}
          data={data}
          isStriped={isLeftColumnsStriped}
          minWidth={leftMinWidth}
          sortBy={sortBy}
          setSortBy={setSortBy}
          showSortBy={showSortBy}
          onColClick={onColClick}
        />
        <MainTable isScrollbarVisible={isScrollbarVisible}>
          <TableWrapper isScrollbarVisible={isScrollbarVisible} $isPointer={Boolean(onRowClick)}>
            <Header
              columns={columns.filter(isFixedFilter)}
              sortProps={sortProps}
              isMenuVisible={isMenuVisible}
              visibleColumn={visibleColumn}
              showSortBy={showSortBy}
            />
            <tbody>
              {data.map((item, i) => {
                return (
                  <TR
                    onMouseEnter={() => setHovered(i)}
                    onMouseLeave={() => setHovered(-1)}
                    key={JSON.stringify({ item, i })}
                    onClick={() => onRowClick && onRowClick(item)}
                    role="link"
                  >
                    {columns.filter(isFixedFilter).map((column, index) => {
                      const isCallWithClick = !!onColClick && onColClick.column === column.accessor;

                      const isFirstCell = index === 0 && i === 0;

                      if (placeholderProps?.mainTable?.isVisible) {
                        if (isFirstCell) {
                          return (
                            <TD
                              key={`td-${column.accessor}`}
                              colSpan={columns.filter(isFixedFilter).length}
                              rowSpan={data.length}
                            >
                              <Placeholder
                                icon={placeholderProps?.mainTable.icon}
                                title={placeholderProps?.mainTable.title}
                              />
                            </TD>
                          );
                        } else {
                          if (columns.filter(isFixedFilter).length - 1 === index) {
                            return <td key={`td-${column.accessor}`} />;
                          } else {
                            return <></>;
                          }
                        }
                      } else {
                        return column.renderCell ? (
                          <TD
                            key={`td-${column.accessor}`}
                            maxWidth={column.maxWidth}
                            minWidth={column.minWidth}
                            padding={column.padding}
                          >
                            {column.renderCell(
                              item,
                              isCallWithClick ? onColClick.onClick : undefined,
                              hovered === i,
                            )}
                          </TD>
                        ) : (
                          <TD
                            key={`td-${column.accessor}`}
                            minWidth={column.minWidth}
                            maxWidth={column.maxWidth}
                          >
                            {/* @ts-ignore */}
                            {item?.[column.accessor]}
                          </TD>
                        );
                      }
                    })}
                    {isMenuVisible && (
                      <TD minWidth="26px" padding="16px 26px 16px 0">
                        <IconMenuWrapper>
                          {isMenuVisible && Boolean(createMenuItems) && (
                            <IconMenu
                              // @ts-ignore
                              items={createMenuItems(item)}
                            />
                          )}
                        </IconMenuWrapper>
                      </TD>
                    )}
                  </TR>
                );
              })}
            </tbody>
          </TableWrapper>
        </MainTable>
        {!placeholderProps?.mainTable?.isVisible && (
          <FixedTable
            columns={columns.filter(
              (column) => column.isFixed && column.position === ColumnPosition.RIGHT,
            )}
            data={data}
            isStriped={isRightColumnsStriped}
            minWidth={rightMinWidth}
            sortBy={sortBy}
            setSortBy={setSortBy}
            showSortBy={showSortBy}
          />
        )}
      </TableContainer>
      {isEmpty(data) && (
        <TablePlaceholder
          isLoading={isLoading}
          isFiltered={isFiltered}
          noResultText={placeholderProps?.noResultText}
          emptyStateText={placeholderProps?.emptyStateText}
          onResetSearch={() => filters.setSearch('')}
        />
      )}
      <PaginationBar
        pagination={pagination}
        changePagination={onPageChangeClick}
        changePageSize={handleChangeItemsPerPage}
        count={totalCount}
        noShadow
        noBorder
        noTopBorder
        showCount
      />
    </Wrapper>
  );
};

export { TableGrid };
