import React from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Button from '~/components/Button';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Switch from '~/components/Switch';
import { TextArea } from '~/components/Text';
import { Block, Title } from '~/pages/ReviewTemplateSetup/components/StyledComponents';

import { COLORS, COLOR_PALETTE } from '~/styles';

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  color: ${COLORS.TEXT_SECONDARY};

  span {
    min-width: 30px;
    margin-left: 5px;
  }
`;

const Row = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  width: 406px;

  .rc-switch {
    margin-right: 0;
  }
`;

const StyledTextArea = styled(TextArea)`
  height: 48px;
  width: 323px;
  border-color: ${COLORS.BORDER_LIGHT};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  padding: 11px 14px;
  color: ${COLORS.TEXT_SECONDARY};
  font-weight: 600;
`;

const Text = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  color: ${COLORS.TEXT_BLACK};
  padding-left: 1px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.48px;
`;

const LabelRow = ({
  label,
  labelIndex,
  onChange,
  onDelete,
  isHideDelete,
  scaleLabels,
  isShowErrors,
}) => (
  <Row>
    <Label>
      <span>{labelIndex + 1}</span>
      <StyledTextArea
        value={label || ''}
        onChange={(e) => onChange(e.target.value, labelIndex)}
        $accent
        resize={true}
        rows="1"
        error={
          isShowErrors && (isEmpty(label) || scaleLabels.filter((sl) => sl === label).length > 1)
        }
      />
    </Label>
    {!isHideDelete && (
      <div
        onClick={onDelete}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e && e.key === 'Tab') {
            return;
          }
          onDelete(labelIndex);
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Icon icon={ICONS.DELETE_BIN} size={ICON_SIZES.LARGE} color={COLOR_PALETTE.GRAY_MIDDLE} />
      </div>
    )}
  </Row>
);

const AdjustRatingScaleBlock = ({
  scaleLabels,
  i18n,
  onDelete,
  addScaleLabel,
  onChange,
  isSkippable,
  toggleIsSkippable,
  isShowErrors,
}) => {
  return (
    <Block>
      <Title>
        <Trans>Rating</Trans>
      </Title>
      {scaleLabels.map((label, i) => (
        <LabelRow
          label={label}
          key={i}
          labelIndex={i}
          onChange={onChange}
          onDelete={() => onDelete(i)}
          isHideDelete={scaleLabels.length === 1}
          scaleLabels={scaleLabels}
          isShowErrors={isShowErrors}
        />
      ))}
      <Row>
        <Label>
          <span>{(scaleLabels?.length ?? 0) + 1}</span>
          <Text>
            <Trans>Not applicable</Trans>
          </Text>
        </Label>
        <Switch onChange={toggleIsSkippable} checked={isSkippable} />
      </Row>
      {scaleLabels.length < 10 && (
        <Button
          type="link-primary"
          label={i18n._(t`Add level`)}
          iconLeft={<Icon icon={ICONS.ADD_PLUS} size={ICON_SIZES.LARGE} />}
          onClick={addScaleLabel}
          styles={{
            paddingLeft: 0,
            fontSize: '16px',
            lineHeight: '1.38',
            marginBottom: '34px',
          }}
        />
      )}
    </Block>
  );
};

export default withI18n()(AdjustRatingScaleBlock);
