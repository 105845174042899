import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

// @deprecated Use getTemplates instead.
export function getTemplatesOld(superAdminTemplates = false) {
  return cloudRequest(serverRoutes.reviewTemplates.getTemplatesDepricated, {
    superAdminTemplates,
  });
}

export function getTemplates(superAdminTemplates = false, params = {}) {
  return cloudRequest(serverRoutes.reviewTemplates.getTemplates, {
    superAdminTemplates,
    ...params,
  });
}

export function getTemplate(id) {
  return cloudRequest(serverRoutes.reviewTemplates.getTemplate(id));
}

export function createTemplate(body) {
  return cloudRequest(serverRoutes.reviewTemplates.createTemplate, {}, body);
}

export function updateTemplate(id, body) {
  return cloudRequest(serverRoutes.reviewTemplates.updateTemplate(id), {}, body);
}

export function deleteTemplate(id) {
  return cloudRequest(serverRoutes.reviewTemplates.deleteTemplate(id));
}

export function createTemplateSuperAdmin(body) {
  return cloudRequest(serverRoutes.reviewTemplates.createTemplateSuperAdmin, {}, body);
}

export function getTemplatesSuperAdmin() {
  return cloudRequest(serverRoutes.reviewTemplates.getTemplatesSuperAdmin);
}

export function getTemplateSuperAdmin(id) {
  return cloudRequest(serverRoutes.reviewTemplates.getTemplateSuperAdmin(id));
}

export function deleteTemplatesSuperAdmin(id) {
  return cloudRequest(serverRoutes.reviewTemplates.deleteTemplateSuperAdmin(id));
}

export function updateTemplateSuperAdmin(id, body) {
  return cloudRequest(serverRoutes.reviewTemplates.updateTemplateSuperAdmin(id), {}, body);
}

export function deleteMultipleTemplates(templateIds) {
  return cloudRequest(serverRoutes.reviewTemplates.deleteMultipleTemplates, { templateIds });
}
