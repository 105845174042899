import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import Modal from '~/components/Modal';

import SkillLevelsList from './SkillLevelsList';

const SaveButton = styled(Button)`
  display: flex;
  align-self: flex-end;
`;

const SkillLevelsModal = ({
  onClose,
  onChange,
  i18n,
  onSubmit,
  skillLevels,
  defaultFocusAreas,
  isShowErrors,
}) => {
  return (
    <Modal
      title={i18n._(t`Edit skill levels`)}
      onClose={onClose}
      width={680}
      minWidth={680}
      minHeight="200px"
      contentStyles={{
        background: '#fff',
        height: '100%',
        padding: '12px 23px 24px',
        minHeight: '440px',
      }}
      headerStyles={{ padding: '0 23px' }}
      hideFooter={true}
    >
      <SkillLevelsList
        skillLevels={skillLevels}
        defaultFocusAreas={defaultFocusAreas}
        onChange={onChange}
        isShowErrors={isShowErrors}
      />
      <SaveButton
        width={83}
        height={48}
        label={i18n._(t`Save`)}
        type="primary"
        onClick={onSubmit}
      />
    </Modal>
  );
};

export default withI18n()(SkillLevelsModal);
