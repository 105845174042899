import React from 'react';

import { KPI_TYPES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import TableCard from '~/components/UI/TableCard';
import UserReviewReportItem from '~/pages/ReviewAdminPage/components/Reports/UserReviewReportItem';

import PotentialIcon from '~/assets/icons-mountain.svg';
import PerformanceIcon from '~/assets/icons-performance.svg';

import FiltersBar from '../components/FiltersBar';
import InfoBoard, { BoardProgress, BoardValue } from '../components/InfoBoard';

const ParticipantsSection = styled(BoxWithBorder)``;

const StatisticTemplate = styled.div`
  display: flex;
  align-items: flex-end;
`;

const BigText = styled.div`
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  margin-left: 14px;
`;

const SmallText = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
`;

const REPORT_COLS = {
  PARTICIPANT: {
    title: (i18n) => i18n._(t`Participant`),
    label: (i18n) => i18n._(t`Participant`),
    id: 'name',
  },
  COACH: {
    title: (i18n) => i18n._(t`Coach`),
    label: (i18n) => i18n._(t`Coach`),
    id: 'coachName',
  },
};

const getCols = (i18n) => {
  const { PARTICIPANT, COACH } = REPORT_COLS;
  return [
    {
      title: PARTICIPANT.title(i18n),
      id: PARTICIPANT.id,
      width: '330px',
      dataType: 'string',
    },
    {
      title: COACH.title(i18n),
      width: '150px',
      id: COACH.id,
      dataType: 'string',
    },
  ];
};

const ReportsTab = ({ review, filters, statistics, applyFilters, kpis }) => {
  const { i18n } = useLingui();
  const reviewKpis = Object.keys(statistics.avg)
    .map((kpiId) => kpis.find((kpi) => kpi.id === kpiId))
    .filter((k) => k); // filter our undefined kpi
  const isKpis = !isEmpty(reviewKpis);
  const cols = getCols(i18n);
  const sortItems = Object.values(REPORT_COLS);

  // add kpi cols
  // add kpi sort
  if (!isEmpty(reviewKpis)) {
    reviewKpis.forEach((kpi) => {
      cols.push({
        title: kpi.name,
        id: kpi.id,
        key: kpi.type,
        width: '150px',
        hint:
          i18n._(t`Average coach review for the ${kpi.name} KPI for this employee.`) +
          ' ' +
          i18n._(t`For the self review and average peer review download the CSV file.`),
      });

      sortItems.push({
        title: kpi.name,
        label: kpi.name,
        id: kpi.id,
      });
    });
  }

  // add col for menu in the end
  cols.push({
    width: '150px',
    name: 'key-for-unnamed-menu-in-table',
  });

  const renderAvgKpis = (statistics) => {
    const template = ({ value, max, icon }) => {
      return (
        <StatisticTemplate>
          {icon}
          <BigText>{value}</BigText>
          <SmallText>{`/${max}`}</SmallText>
        </StatisticTemplate>
      );
    };

    return reviewKpis.map((kpi) => {
      const values = statistics.avg[kpi.id];
      const isSkills = kpi.type === KPI_TYPES.SKILLS;
      const getKpiIcons = (kpiType) => {
        const icons = {
          [KPI_TYPES.POTENTIAL]: PotentialIcon,
          [KPI_TYPES.PERFORMANCE]: PerformanceIcon,
        };

        return icons[kpiType] || PerformanceIcon; // for other custom kpi temp PerformanceIcon
      };

      return isSkills ? (
        <BoardProgress progress={values[0] || 0} title={<Trans>Skill coverage average</Trans>} />
      ) : (
        <BoardValue
          value={template({
            value: values[0] || 0,
            max: values[1] || 0,
            icon: <SvgIcon width="32px" height="32px" url={getKpiIcons(kpi.type)} />, // PotentialIcon
          })}
          title={kpi.name}
        />
      );
    });
  };

  return (
    <>
      {isKpis && !isEmpty(reviewKpis) && <InfoBoard>{renderAvgKpis(statistics)}</InfoBoard>}
      <ParticipantsSection>
        <FiltersBar filters={filters} sortOptions={sortItems} applyFilters={applyFilters} />
        <TableCard
          hideHeader
          noBorder
          noMarginBottom
          noBottomBorder
          items={review.subReviews}
          cols={cols}
          renderRow={(subReview) => (
            <UserReviewReportItem
              key={subReview.id}
              reviewName={review.name}
              subReview={subReview}
              reviewKpis={reviewKpis}
            />
          )}
        />
      </ParticipantsSection>
    </>
  );
};

export default React.memo(ReportsTab);
