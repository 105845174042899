import React, { memo } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import FeedbackDeadline from '~/components/FeedbackDeadline';
import PencilIcon from '~/components/Icons/Pencil';
import UserAvatar from '~/components/UI/Avatar';
import AvatarGroup from '~/components/UI/AvatarGroup';
import TaskStatus from '~/pages/ReviewUserPage/components/TaskStatus';

import { Item, ItemNameText } from './CommonStyledComponents';

import { REQUEST_TYPES, REQUEST_STATUSES } from '~/constants';
import { COLORS, COLOR_PALETTE } from '~/styles';

const Actions = styled.div`
  display: flex;
  align-items: center;
  min-width: 110px;
  justify-content: flex-end;
`;

const AvatarGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 120px;
  cursor: pointer;
`;

const Deadlines = styled.div`
  display: flex;
  width: 160px;
  align-items: center;
`;

const ItemWrapper = styled(Item)`
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 0;
`;

const PeersPlaceholder = styled.button`
  display: flex;
  align-items: center;
  padding: 6px 7px 6px 11px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  font-size: 12px;
  white-space: nowrap;
  font-weight: 600;
  color: ${COLOR_PALETTE.DARK_GRAY};
  cursor: pointer;
  & .icon {
    margin-left: 4px;
  }
`;

const UserNominatePeerItem = ({
  review,
  requestsPeers,
  isCoach,
  isDisabled,
  isLocked,
  lockedHint,
  openNominatePeersModal,
  actionTooltip,
  isActionDisabled,
}) => {
  const { i18n } = useLingui();
  const peerUsers = requestsPeers
    .filter(
      (r) =>
        r.type === REQUEST_TYPES.FEEDBACK_FROM_PEER && r.status !== REQUEST_STATUSES.CANCELLED.key,
    )
    .map((r) => r.toUser);
  const peerUsersOutside = requestsPeers
    .filter(
      (r) =>
        r.type === REQUEST_TYPES.FEEDBACK_FROM_OUTSIDE_PEER &&
        r.status !== REQUEST_STATUSES.CANCELLED.key,
    )
    .map((r) => r.toEmail);

  return (
    <ItemWrapper>
      <Row>
        <UserAvatar size={28} userId={review.createdFor} isGray={isDisabled} />
        <ItemNameText $completed={!isEmpty(requestsPeers)} $disabled={isDisabled}>
          <Trans>Ask peers for input for your review</Trans>
        </ItemNameText>
        {isLocked && lockedHint}
      </Row>

      <Row>
        {(!isEmpty(peerUsers) || !isEmpty(peerUsersOutside)) && (
          <>
            <AvatarGroupWrapper onClick={openNominatePeersModal}>
              <AvatarGroup maxUsersToShow={5} users={peerUsers} emails={peerUsersOutside} />
            </AvatarGroupWrapper>
            {isCoach && !isLocked && (
              <IconButton onClick={openNominatePeersModal}>
                <PencilIcon size={24} />
              </IconButton>
            )}
          </>
        )}

        {isCoach && !isLocked && isEmpty(peerUsers) && isEmpty(peerUsersOutside) && (
          <PeersPlaceholder onClick={openNominatePeersModal}>
            <Trans>No peers selected</Trans>
            <PencilIcon className="icon" size={24} />
          </PeersPlaceholder>
        )}
      </Row>

      <Row $width={20}>
        <Deadlines>
          {!isLocked && isEmpty(requestsPeers) && (
            <FeedbackDeadline deadline={review.deadlineNominate} width="29px" height="28px" />
          )}
        </Deadlines>
        <Actions>
          {isLocked ? (
            <TaskStatus
              status={
                isEmpty(requestsPeers) ? TaskStatus.STATUSES.EXPIRED : TaskStatus.STATUSES.COMPLETED
              }
            />
          ) : (
            !isDisabled && (
              <Button
                type={isEmpty(requestsPeers) ? Button.types.primary : Button.types.linkPrimary}
                label={isEmpty(requestsPeers) ? i18n._(t`Start`) : i18n._(t`Update`)}
                onClick={openNominatePeersModal}
                styles={{ minWidth: '83px', padding: 0 }}
                disabled={isLocked || isActionDisabled}
                tooltip={actionTooltip}
              />
            )
          )}
        </Actions>
      </Row>
    </ItemWrapper>
  );
};

UserNominatePeerItem.propTypes = {
  review: PropTypes.object.isRequired,
  requestsPeers: PropTypes.array,
  onReviewUpdated: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  lockedHint: PropTypes.node,
  actionTooltip: PropTypes.string,
  isActionDisabled: PropTypes.bool,
};

export default memo(UserNominatePeerItem);
