import React, { useEffect } from 'react';

import { Trans } from '@lingui/macro';

import { Icon, ICONS } from '~/components/Icon';
import CheckIcon from '~/components/Icons/Check';
import { SECTION_TYPE } from '~/components/SideBar';
import {
  Wrapper,
  Card,
  Dot,
  Title,
  AnimatedTitle,
  Animated,
  Minus,
  ErrorCircle,
  CompletedCircle,
  LockedCircle,
  ActiveCircle,
  NotCompletedFutureCircle,
  NotCompletedPastCircle,
  SubTextSpan,
  MainSpan,
  PrimarySpan,
} from '~/components/TableOfContents/design';
import type { ISectionProps } from '~/components/TableOfContents/types';

import { useScrollEffect } from '~/hooks/useScrollEffect';
import { COLOR_PALETTE } from '~/styles';

const Section = ({
  title,
  type,
  index,
  currentSection,
  setCurrentSection,
  parentHeight,
  total,
  isCollapsed,
  hideErrorState,
}: ISectionProps) => {
  const { ref, scrollToRef } = useScrollEffect<HTMLDivElement>({ behavior: 'smooth' });
  type = currentSection === index ? SECTION_TYPE.CURRENT : type;
  if (hideErrorState === true && type === SECTION_TYPE.ERROR) {
    type = undefined;
  }

  useEffect(() => {
    if (currentSection === index) {
      scrollToRef();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection]);

  return (
    <Wrapper parentHeight={parentHeight} ref={ref}>
      <Card isCollapsed={isCollapsed} type={type} onClick={() => void setCurrentSection(index)}>
        {type === SECTION_TYPE.DONE && (
          <CompletedCircle>
            <CheckIcon fill={COLOR_PALETTE.WHITE} size={9} />
          </CompletedCircle>
        )}
        {type === SECTION_TYPE.CURRENT && (
          <ActiveCircle>
            <Dot />
          </ActiveCircle>
        )}
        {type === SECTION_TYPE.ERROR && (
          <ErrorCircle>
            <Minus />
          </ErrorCircle>
        )}
        {type === SECTION_TYPE.LOCKED && (
          <LockedCircle>
            <Icon icon={ICONS.LOCK} width={14} height={14} />
          </LockedCircle>
        )}
        {!type && index < currentSection && <NotCompletedPastCircle />}
        {!type && index > currentSection && <NotCompletedFutureCircle />}
        <Animated>
          <AnimatedTitle shouldAnimate={isCollapsed}>
            <Title>
              {currentSection === index ? (
                <>
                  <PrimarySpan>{index + 1}</PrimarySpan>
                  <SubTextSpan>/{total}</SubTextSpan>
                </>
              ) : (
                <MainSpan>{index + 1}</MainSpan>
              )}
            </Title>
          </AnimatedTitle>
          <AnimatedTitle shouldAnimate={!isCollapsed}>
            {currentSection === index ? (
              <span>
                <Trans>Question</Trans> <PrimarySpan>{index + 1}</PrimarySpan>/{total}
              </span>
            ) : (
              <>
                <Title>{index + 1}</Title>
                <Title>{title}</Title>
              </>
            )}
          </AnimatedTitle>
        </Animated>
      </Card>
    </Wrapper>
  );
};

export { Section };
