import React, { useState } from 'react';

import { ENGAGEMENT_REPORT_CHART_TYPES, ROLES } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { MultiSelectDropdown } from '~/components/Dropdown/components/MultiSelectDropdown';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import SelectDropDown from '~/components/SelectDropDown';
import Tooltip from '~/components/Tooltip';
import { EOptions, TDropDownValues, TOptions } from '~/pages/Reports/types';

import {
  Container,
  Header,
  Inner,
  Tabs,
  InnerTab,
  TabBody,
  Section,
  SectionHead,
  DropDownOuter,
  SectionBody,
  SectionBodySingleLine,
  ResetFilters,
  DropDownCtr,
  CheckBoxOutCtr,
  CheckBoxCtr,
  Label,
  Separator,
  VerticalTab,
} from './ControlPanelStyles';

import { INSTRUCTIONS } from '~/constants/instructions';
import { COLORS } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';

import { RIGHT_MENU_TABS } from '../../../components/SideMenu/constants';
import { MONTH_OPTIONS } from '../options';

type TRenderedTableItem = {
  title: (item: I18n) => string;
};

type TProps = {
  showMenu: boolean;
  onShow: (val: boolean) => void;
  companyAverageSelected: boolean;
  industryBenchMarkSelected?: boolean;
  primaryOptions: TOptions[];
  secondaryOptions: TOptions[];
  measureOptions: TOptions[];
  themesOptions: TOptions[];
  themesOptionSelected: TOptions[];
  teamsOptions: TOptions[];
  teamsOptionSelected: TOptions[];
  surveysOptions: TOptions[];
  surveysOptionSelected: TOptions[];
  optionChangeHandler: (type: EOptions, val?: TDropDownValues) => void;
  dimensionSelected: {
    primary: TOptions | null;
    secondary: TOptions | null;
    measure: TOptions | null;
  };
  filterCount: number;
  isHeatmapSelected: boolean;
  monthSelected: string;
  enabledOptions: EOptions[];
  enabledOptionKeys: EOptions[];
  isLoading: boolean;
  currentTab: ENGAGEMENT_REPORT_CHART_TYPES | null;
  teamAverageSelected: boolean;
  viewRole: ROLES;
};

function ControlPanel({
  showMenu,
  onShow,
  companyAverageSelected,
  industryBenchMarkSelected,
  primaryOptions,
  secondaryOptions,
  measureOptions,
  themesOptions,
  themesOptionSelected,
  teamsOptions,
  teamsOptionSelected,
  surveysOptions,
  surveysOptionSelected,
  optionChangeHandler,
  dimensionSelected,
  filterCount,
  isHeatmapSelected,
  monthSelected,
  enabledOptions,
  enabledOptionKeys,
  isLoading,
  teamAverageSelected,
  viewRole,
}: TProps): JSX.Element {
  const { i18n } = useLingui();
  const [tab, setTab] = useState<RIGHT_MENU_TABS>(RIGHT_MENU_TABS.SETTINGS);

  const IndustryBenchMarkForceToEnable =
    dimensionSelected?.primary?.key === 'theme' &&
    (dimensionSelected?.secondary?.key === 'secondary_none' ||
      dimensionSelected?.secondary?.key === 'primary_none' ||
      !dimensionSelected?.secondary?.key) &&
    (dimensionSelected?.measure?.key === 'month' ||
      dimensionSelected?.measure?.key === 'quarter' ||
      dimensionSelected?.measure?.key === 'year');

  const showDimensionSec =
    enabledOptionKeys.includes(EOptions.primaryDimension) ||
    enabledOptionKeys.includes(EOptions.secondaryDimension) ||
    enabledOptionKeys.includes(EOptions.measure) ||
    enabledOptionKeys.includes(EOptions.isHeatmapColored) ||
    enabledOptionKeys.includes(EOptions.includeCompanyAverage) ||
    enabledOptionKeys.includes(EOptions.includeBenchmark);

  const showFilterSec =
    enabledOptionKeys.includes(EOptions.timeFrame) ||
    enabledOptionKeys.includes(EOptions.themes) ||
    enabledOptionKeys.includes(EOptions.teams) ||
    enabledOptionKeys.includes(EOptions.surveys);

  const getTooltipData = (enabled: boolean) => {
    return (
      <CheckBoxCtr
        onClick={() =>
          !enabled &&
          enabledOptions.includes(EOptions.includeBenchmark) &&
          optionChangeHandler(EOptions.includeBenchmark)
        }
        className={
          !enabled && enabledOptions.includes(EOptions.includeBenchmark)
            ? 'enabledOptions'
            : 'disabled'
        }
      >
        <Icon
          className="checkbox"
          size={ICON_SIZES.MEDIUM}
          icon={
            !enabled &&
            enabledOptions.includes(EOptions.includeBenchmark) &&
            industryBenchMarkSelected
              ? ICONS.CHECKBOX_CHECKED
              : ICONS.CHECKBOX
          }
        />

        <span>{i18n._(t`Industry benchmark`)}</span>
      </CheckBoxCtr>
    );
  };

  const getPrimaryTab = (from: EOptions, wrapWithTooltip: boolean, ele: JSX.Element) => {
    let message = '';
    if (from === EOptions.primaryDimension) {
      message = i18n._(t`Switch to Custom to apply primary dimension`);
    } else if (from === EOptions.secondaryDimension) {
      message = i18n._(t`Switch to Custom to apply secondary dimension`);
    } else if (from === EOptions.measure) {
      message = i18n._(t`Switch to Custom to apply measurement`);
    }

    if (wrapWithTooltip) {
      return <Tooltip tooltip={message}>{ele}</Tooltip>;
    }
    return ele;
  };
  const getTab = () => {
    if (tab === RIGHT_MENU_TABS.SETTINGS) {
      return (
        <>
          {showDimensionSec && (
            <>
              <Section>
                <SectionHead>{`${i18n._(t`Dimensions`)}`}</SectionHead>
                <SectionBody gap={'10px'}>
                  {enabledOptionKeys.includes(EOptions.primaryDimension) && (
                    <>
                      {getPrimaryTab(
                        EOptions.primaryDimension,
                        !enabledOptions.includes(EOptions.primaryDimension),
                        <DropDownCtr
                          className={
                            enabledOptions.includes(EOptions.primaryDimension)
                              ? 'enabledOptions.'
                              : 'disabled'
                          }
                        >
                          <Label>{`${i18n._(t`Primary dimension`)}`}</Label>
                          <SelectDropDown
                            options={primaryOptions}
                            value={dimensionSelected.primary?.key}
                            onChange={(val: string) =>
                              optionChangeHandler(EOptions.primaryDimension, val)
                            }
                            keyName="key"
                            disabled={!enabledOptions.includes(EOptions.primaryDimension)}
                            borderColor={COLORS.BORDERS}
                            listMarginTop="8px"
                            small
                            labelStyle={{
                              fontSize: '12px',
                            }}
                            optionStyles={{
                              fontSize: '12px',
                            }}
                            label={i18n._(t`Select`)}
                            renderLabel={(item: TRenderedTableItem) => (
                              <div>{item.title(i18n)}</div>
                            )}
                            width="195px"
                            listHeight="200px"
                          />
                        </DropDownCtr>,
                      )}
                    </>
                  )}
                  {enabledOptionKeys.includes(EOptions.secondaryDimension) && (
                    <>
                      {getPrimaryTab(
                        EOptions.secondaryDimension,
                        !enabledOptions.includes(EOptions.secondaryDimension),
                        <DropDownCtr
                          className={
                            enabledOptions.includes(EOptions.secondaryDimension)
                              ? 'enabledOptions.'
                              : 'disabled'
                          }
                        >
                          <Label>{`${i18n._(t`Secondary dimension`)}`}</Label>
                          <SelectDropDown
                            options={secondaryOptions}
                            value={dimensionSelected.secondary?.key}
                            onChange={(val: string) =>
                              optionChangeHandler(EOptions.secondaryDimension, val)
                            }
                            keyName="key"
                            borderColor={COLORS.BORDERS}
                            disabled={
                              !enabledOptions.includes(EOptions.secondaryDimension) ||
                              !(dimensionSelected.primary && dimensionSelected.measure)
                            }
                            listMarginTop="8px"
                            small
                            labelStyle={{
                              fontSize: '12px',
                            }}
                            optionStyles={{
                              fontSize: '12px',
                            }}
                            label={i18n._(t`Select`)}
                            renderLabel={(item: TRenderedTableItem) => (
                              <div>{item.title(i18n)}</div>
                            )}
                            width="195px"
                            listHeight="200px"
                          />
                        </DropDownCtr>,
                      )}
                    </>
                  )}
                  {enabledOptionKeys.includes(EOptions.measure) && (
                    <>
                      {getPrimaryTab(
                        EOptions.measure,
                        !enabledOptions.includes(EOptions.measure),
                        <DropDownCtr
                          className={
                            enabledOptions.includes(EOptions.measure)
                              ? 'enabledOptions.'
                              : 'disabled'
                          }
                        >
                          <Label>{`${i18n._(t`Measurement`)}`}</Label>
                          <SelectDropDown
                            options={measureOptions}
                            value={dimensionSelected.measure?.key}
                            onChange={(val: string) => optionChangeHandler(EOptions.measure, val)}
                            keyName="key"
                            disabled={
                              !enabledOptions.includes(EOptions.measure) ||
                              !dimensionSelected.primary
                            }
                            borderColor={COLORS.BORDERS}
                            listMarginTop="8px"
                            small
                            labelStyle={{
                              fontSize: '12px',
                            }}
                            optionStyles={{
                              fontSize: '12px',
                            }}
                            label={i18n._(t`Select`)}
                            renderLabel={(item: TRenderedTableItem) => (
                              <div>{item.title(i18n)}</div>
                            )}
                            width="195px"
                            listHeight="200px"
                          />
                        </DropDownCtr>,
                      )}
                    </>
                  )}
                  <CheckBoxOutCtr>
                    {enabledOptionKeys.includes(EOptions.isHeatmapColored) && (
                      <CheckBoxCtr
                        onClick={() =>
                          enabledOptions.includes(EOptions.isHeatmapColored) &&
                          optionChangeHandler(EOptions.isHeatmapColored)
                        }
                        className={
                          enabledOptions.includes(EOptions.isHeatmapColored)
                            ? 'enabledOptions.'
                            : 'disabled'
                        }
                      >
                        <Icon
                          className="checkbox"
                          size={ICON_SIZES.MEDIUM}
                          icon={
                            enabledOptions.includes(EOptions.isHeatmapColored) && isHeatmapSelected
                              ? ICONS.CHECKBOX_CHECKED
                              : ICONS.CHECKBOX
                          }
                        />
                        <span>{i18n._(t`Heatmap colors`)}</span>
                      </CheckBoxCtr>
                    )}
                    {enabledOptionKeys.includes(EOptions.includeCompanyAverage) && (
                      <CheckBoxCtr
                        onClick={() =>
                          enabledOptions.includes(EOptions.includeCompanyAverage) &&
                          optionChangeHandler(EOptions.includeCompanyAverage)
                        }
                        className={
                          enabledOptions.includes(EOptions.includeCompanyAverage)
                            ? 'enabledOptions.'
                            : 'disabled'
                        }
                      >
                        <Icon
                          className="checkbox"
                          size={ICON_SIZES.MEDIUM}
                          icon={
                            enabledOptions.includes(EOptions.includeCompanyAverage) &&
                            companyAverageSelected
                              ? ICONS.CHECKBOX_CHECKED
                              : ICONS.CHECKBOX
                          }
                        />
                        <span>{i18n._(t`Company average`)}</span>
                      </CheckBoxCtr>
                    )}
                    {enabledOptionKeys.includes(EOptions.includeTeamAverage) && (
                      <CheckBoxCtr
                        onClick={() =>
                          enabledOptions.includes(EOptions.includeTeamAverage) &&
                          optionChangeHandler(EOptions.includeTeamAverage)
                        }
                        className={
                          enabledOptions.includes(EOptions.includeTeamAverage)
                            ? 'enabledOptions.'
                            : 'disabled'
                        }
                      >
                        <Icon
                          className="checkbox"
                          size={ICON_SIZES.MEDIUM}
                          icon={
                            enabledOptions.includes(EOptions.includeTeamAverage) &&
                            teamAverageSelected
                              ? ICONS.CHECKBOX_CHECKED
                              : ICONS.CHECKBOX
                          }
                        />
                        <span>{i18n._(t`Team average`)}</span>
                      </CheckBoxCtr>
                    )}
                    {enabledOptionKeys.includes(EOptions.includeBenchmark) && (
                      <>
                        {isLoading || IndustryBenchMarkForceToEnable ? (
                          <>{getTooltipData(!IndustryBenchMarkForceToEnable)}</>
                        ) : (
                          <Tooltip
                            tooltip={i18n._(
                              t`Industry benchmarks are only available for Learned themes. Please select "themes" in primary dimension and do not select a secondary row dimension, and select month, quarter or year in measurement.`,
                            )}
                          >
                            {getTooltipData(true)}
                          </Tooltip>
                        )}
                      </>
                    )}
                  </CheckBoxOutCtr>
                </SectionBody>
              </Section>
              <Separator />
            </>
          )}
          {/* Filter Section --> */}
          {showFilterSec && (
            <Section>
              <SectionHead>{`${i18n._(t`Filters`)}`}</SectionHead>
              <SectionBody gap={'10px'}>
                {enabledOptionKeys.includes(EOptions.timeFrame) && (
                  <DropDownOuter enabled={enabledOptions.includes(EOptions.timeFrame)}>
                    <SelectDropDown
                      options={MONTH_OPTIONS}
                      value={monthSelected}
                      onChange={(val: string) => optionChangeHandler(EOptions.timeFrame, val)}
                      keyName="key"
                      borderColor={COLORS.BORDERS}
                      listMarginTop="8px"
                      small
                      labelStyle={{
                        fontSize: '12px',
                      }}
                      optionStyles={{
                        fontSize: '12px',
                      }}
                      label={i18n._(t`Select`)}
                      renderLabel={(item: TRenderedTableItem) => <div>{item.title(i18n)}</div>}
                      width="195px"
                      listHeight="200px"
                    />
                  </DropDownOuter>
                )}
                {enabledOptionKeys.includes(EOptions.themes) && (
                  <DropDownOuter enabled={enabledOptions.includes(EOptions.themes)}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Themes`)}
                      items={[...themesOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...themesOptionSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.themes, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {enabledOptionKeys.includes(EOptions.teams) && (
                  <DropDownOuter enabled={enabledOptions.includes(EOptions.teams)}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Teams`)}
                      items={[...teamsOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...teamsOptionSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.teams, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {enabledOptionKeys.includes(EOptions.surveys) && (
                  <DropDownOuter enabled={enabledOptions.includes(EOptions.surveys)}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Surveys`)}
                      items={[...surveysOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...surveysOptionSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.surveys, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {filterCount > 0 && (
                  <ResetFilters
                    onClick={() => optionChangeHandler(EOptions.reset, null)}
                    enabled={
                      enabledOptions.includes(EOptions.themes) ||
                      enabledOptions.includes(EOptions.teams) ||
                      enabledOptions.includes(EOptions.surveys) ||
                      enabledOptions.includes(EOptions.timeFrame)
                    }
                  >
                    {i18n._(t`Reset filters`)}
                  </ResetFilters>
                )}
              </SectionBody>
            </Section>
          )}
        </>
      );
    } else if (tab === RIGHT_MENU_TABS.ABOUT) {
      return (
        <>
          <Section>
            <SectionHead>{`${i18n._(t`The report:`)}`}</SectionHead>
            {viewRole === ROLES.ADMIN || viewRole === ROLES.COACH ? (
              <SectionBodySingleLine>{`${i18n._(
                t`This report shows the employee engagement on the themes measured in the engagement surveys. Admins have access to all teams, for coaches the data is limited to teams where they are coach.`,
              )}`}</SectionBodySingleLine>
            ) : (
              <SectionBodySingleLine>{`${i18n._(
                t`This report shows your engagement based on the answers you provided in the surveys. You have the option to compare your engagement with the average engagement of your team(s) and the company.`,
              )}`}</SectionBodySingleLine>
            )}
          </Section>
          <Separator />

          <Section>
            <SectionHead>{`${i18n._(t`Tab: Graph`)}`}</SectionHead>
            {viewRole === ROLES.ADMIN || viewRole === ROLES.COACH ? (
              <SectionBodySingleLine>{`${i18n._(
                t`In this tab you can find the average employee engagement over time. You can adjust the timeframe in the sidebar - settings tab.`,
              )}`}</SectionBodySingleLine>
            ) : (
              <SectionBodySingleLine>{`${i18n._(
                t`In this tab you can find your average engagement compared to your teams' and company engagement over time. `,
              )}`}</SectionBodySingleLine>
            )}
          </Section>
          <Separator />

          <Section>
            <SectionHead>{`${i18n._(t`Tab: Bar chart`)}`}</SectionHead>
            {viewRole === ROLES.ADMIN || viewRole === ROLES.COACH ? (
              <SectionBodySingleLine>{`${i18n._(
                t`In this tab you can find the average engagement per theme and many other variables displayed in a bar chart. Use the sidebar - settings tab to customise the graph.`,
              )}`}</SectionBodySingleLine>
            ) : (
              <SectionBodySingleLine>{`${i18n._(
                t`In this tab you can find your average engagement per theme compared to the engagement of your team and the company. `,
              )}`}</SectionBodySingleLine>
            )}
          </Section>
          <Separator />

          <Section>
            <SectionHead>{`${i18n._(t`Tab: Table`)}`}</SectionHead>
            {viewRole === ROLES.ADMIN || viewRole === ROLES.COACH ? (
              <SectionBodySingleLine>{`${i18n._(
                t`In this tab you can make any analyses you want. Use the sidebar - settings tab to customise the table. For example: analyse the engagement met theme over time, or analyse the engagement per team per theme.`,
              )}`}</SectionBodySingleLine>
            ) : (
              <SectionBodySingleLine>{`${i18n._(
                t`In this table you can compare your average engagement per theme compared to the engagement of your team and the company.`,
              )}`}</SectionBodySingleLine>
            )}
          </Section>

          {(viewRole === ROLES.ADMIN || viewRole === ROLES.COACH) && (
            <>
              <Separator />
              <Section>
                <SectionHead>{`${i18n._(t`The dimensions & measurement`)}`}</SectionHead>
                <SectionBodySingleLine>
                  {`${i18n._(
                    t`By changing the primary dimension, secondary dimension or measurement you will change the structure of the graph or table. To learn more about all the different dimensions go to our helpdesk article `,
                  )}`}
                  <a
                    href={getInstructionUrl(INSTRUCTIONS.REPORTS_ABOUT_SECTION_DIMENSIONS) || '/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {i18n._(t`here`)}
                  </a>
                </SectionBodySingleLine>
              </Section>
              <Separator />
            </>
          )}

          {(viewRole === ROLES.ADMIN || viewRole === ROLES.COACH) && (
            <>
              <Section>
                <SectionHead>{`${i18n._(t`The filters`)}`}</SectionHead>
                <SectionBodySingleLine>
                  {`${i18n._(
                    t`To limit the data used in the graph or table use any of the filters. For example. Select a team to only use the data generated by people in this team. To learn more about all the different filters go to our helpdesk article `,
                  )}`}
                  <a
                    href={getInstructionUrl(INSTRUCTIONS.REPORTS_ABOUT_SECTION_FILTERS) || '/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {i18n._(t`here`)}
                  </a>
                </SectionBodySingleLine>
              </Section>
            </>
          )}
        </>
      );
    }
  };
  return (
    <Container showMenu={showMenu}>
      <Header onClick={() => onShow(!showMenu)}>
        <Icon
          className={showMenu ? 'checkbox-open' : 'checkbox'}
          size={ICON_SIZES.SMALL}
          icon={ICONS.COLLAPSE_MENU}
        />
        {showMenu && `${i18n._(t`Close`)}`}
      </Header>
      {showMenu ? (
        <Inner isDisabled={isLoading}>
          <Tabs>
            <InnerTab
              isSelected={tab === RIGHT_MENU_TABS.SETTINGS}
              onClick={() => setTab(RIGHT_MENU_TABS.SETTINGS)}
            >{`${i18n._(t`Settings`)}`}</InnerTab>
            <InnerTab
              isSelected={tab === RIGHT_MENU_TABS.ABOUT}
              onClick={() => setTab(RIGHT_MENU_TABS.ABOUT)}
            >{`${i18n._(t`About`)}`}</InnerTab>
          </Tabs>
          <TabBody>{getTab()}</TabBody>
        </Inner>
      ) : (
        <VerticalTab onClick={() => onShow(!showMenu)}>{`${i18n._(
          t`Settings & About`,
        )}`}</VerticalTab>
      )}
    </Container>
  );
}

export default ControlPanel;
