import React, { MouseEventHandler } from 'react';

import { t } from '@lingui/macro';
import Tippy from '@tippyjs/react';
import moment from 'moment/moment';
import styled, { css } from 'styled-components';

import { UserAvatarGroup } from '~/components/UserAvatar/UserAvatarGroup';

import type { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

import { FlexRow, NameRow } from '../../../../design';

import type { IReview } from '@learned/types';
import type { I18n } from '@lingui/core';

const Name = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
  }
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
`;

const Text = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 250px;
`;

const DateRow = styled.div<{ unplanned?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  width: 170px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  ${({ unplanned }) =>
    unplanned &&
    css`
      color: ${COLORS.CONFIRMATION_MODAL_DELETE};
    `}
`;

const tippyOpts = {
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
      },
    },
  ],
};

export enum SORT_OPTIONS {
  NAME_A_Z = 'name_asc',
  NAME_Z_A = 'name_desc',
  DATE_SOON_LATER = 'date_asc',
  DATE_LATER_SOON = 'date_desc',
}

const WithColumn = ({ item }: { item: any }) => {
  return (
    <UserAvatarGroup
      users={item.users.map((item: string) => ({ userId: item }))}
      options={{ isCounterClickable: true }}
    />
  );
};

const COLUMNS: IColumnTable<IReview>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    maxWidth: '500px',
    renderCell: (item, onClick: MouseEventHandler<HTMLDivElement> | undefined) => {
      const conversationName = item.name;
      return (
        <NameRow>
          {/* @ts-ignore */}
          <FlexRow onClick={() => onClick(item)}>
            <Tippy
              trigger="mouseenter"
              theme="light"
              popperOptions={tippyOpts}
              content={<Text>{conversationName}</Text>}
            >
              <Name>{conversationName}</Name>
            </Tippy>
          </FlexRow>
        </NameRow>
      );
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`With`),
    accessor: 'with',
    minWidth: '162px',
    renderCell: (item: IReview) => <WithColumn item={item} />,
  },
  {
    name: (i18n: I18n) => i18n._(t`Date`),
    accessor: 'date',
    minWidth: '162px',
    sortBy: {
      asc: {
        key: SORT_OPTIONS.DATE_SOON_LATER,
        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: SORT_OPTIONS.DATE_LATER_SOON,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
    renderCell: (item: any) => {
      return (
        <DateRow>
          {item?.reviewStartDate ? moment(item.reviewStartDate).format('DD-MM-YYYY') : ''}
        </DateRow>
      );
    },
  },
];

export { COLUMNS };
