import styled from 'styled-components';

import BoardItem from './BoardItem';
import BoardProgress from './BoardProgress';
import BoardValue from './BoardValue';

const InfoBoard = styled.div`
  display: flex;
  margin-bottom: 16px;
  overfrow: auto;
  ${BoardItem} {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
  min-height: 180px;
`;

export { BoardValue, BoardProgress };
export default InfoBoard;
