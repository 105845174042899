import React from 'react';

import { CATEGORY_SKILL_RATING_KEYS } from '@learned/constants';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import Legend from '~/components/Legend';
import { Header3 } from '~/components/UI/Typographics/headers';

import { SkillCoverageBar } from './SkillCoverageBar';

import type { ILegend } from '~/@types/legend';
import { COLORS, COLOR_SET } from '~/styles';

import type { ISkillCategory } from '@learned/types';

export type TRatingsData = {
  [key in CATEGORY_SKILL_RATING_KEYS]: {
    label?: string;
    progress: number;
    position: number;
    scale?: number[];
    $left?: number;
    color?: string;
  };
};

export interface ICategoriesSectionProps {
  title: string;
  description: string;
  legend: ILegend[];
  categories?: ISkillCategory[];
  total?: TRatingsData;
}

const CategoriesColumn = styled.div`
  width: 100%;
  margin-bottom: 30px;
  box-sizing: border-box;
`;

const CategoriesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const LegendWrapper = styled.div``;

const SectionDescription = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${COLORS.TEXT_SECONDARY};
  margin-bottom: 25px;
`;

const Wrapper = styled.div`
  padding: 16px 24px;
  border-radius: 6px;
  border: solid 1px ${COLORS.BG_PAGE};
  background-color: ${COLOR_SET.TURQUOISE_LIGHT};
  box-sizing: border-box;
  margin-bottom: 30px;

  ${Header3} {
    line-height: 1.13;
    margin: 0 0 8px;
  }
`;

const CategoriesSection = ({
  title,
  description,
  legend,
  categories,
  total,
}: ICategoriesSectionProps) => {
  return (
    <Wrapper>
      <Header3>
        <Trans>{title}</Trans>
      </Header3>
      <SectionDescription>
        <Trans>{description}</Trans>
      </SectionDescription>
      {total && (
        <CategoriesColumn>
          <Header3>
            <Trans>Overall</Trans>
          </Header3>
          <SkillCoverageBar title="Overall" items={total} isSmall $maxWidth="79%" />
        </CategoriesColumn>
      )}
      <CategoriesWrapper>
        {categories?.map(({ id, name, ratings }) => (
          <CategoriesColumn key={id}>
            <Header3>{name}</Header3>
            {!isEmpty(ratings) && <SkillCoverageBar title={name} items={ratings} isSmall />}
          </CategoriesColumn>
        ))}
      </CategoriesWrapper>
      <LegendWrapper>
        <Legend items={legend} />
      </LegendWrapper>
    </Wrapper>
  );
};

export default CategoriesSection;
