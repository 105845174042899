import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getCareerPlans(filters = {}, populate) {
  const params = {
    ...filters,
    ...populate,
  };
  return cloudRequest(serverRoutes.careerPlans.getCareerPlans, params);
}

export function getUserCareerPlans(userId, filters = {}, populate = []) {
  const params = {
    ...filters,
    populate,
  };
  return cloudRequest(serverRoutes.careerPlans.getUserCareerPlans(userId), params);
}

export function getCareerPlan(id, join) {
  const params = { join };
  return cloudRequest(serverRoutes.careerPlans.getCareerPlan(id), params);
}

export function createCareerPlan(jobProfileId, status, userId, deadline) {
  const data = {
    jobProfileId,
    status,
    ...(userId && { userId }),
    ...(deadline && { deadline }),
  };
  return cloudRequest(serverRoutes.careerPlans.createCareerPlan, {}, data);
}

export function deleteCareerPlan(id) {
  return cloudRequest(serverRoutes.careerPlans.deleteCareerPlan(id));
}
export function updateCareerPlanDeadline(id, deadline) {
  return cloudRequest(serverRoutes.careerPlans.updateCareerPlanDeadline(id), {}, { deadline });
}

export function createCareerPlansForUser(jobProfileIds) {
  return cloudRequest(serverRoutes.careerPlans.createCareerPlansForUsers, {}, { jobProfileIds });
}
