import styled from 'styled-components';

import { COLORS } from '~/styles';

const HeaderCtr = styled.div<{ isFull: boolean }>`
  gap: 10px;
  margin: ${({ isFull }) => (isFull ? '40px 100px 0 60px' : '40px 270px 0 45px')};
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${COLORS.TEXT_HOVER};
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TabBody = styled.div<{ isFull: boolean }>`
  border-radius: 0 0 6px 6px;
  background: ${COLORS.WHITE};
  padding: 32px;
  min-height: 580px;
  box-sizing: border-box;
  margin: ${({ isFull }) => (isFull ? '0 100px 0 60px' : '0 270px 0 45px')};
`;

const HeaderPlaceHolder = styled.div`
  height: 120px;
`;

const NewReportModal = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.TEXT_HOVER};
  z-index: 999;
`;

const ModalInner = styled.div`
  max-width: 600px;
  min-width: 400px;
  padding: 32px;
  background: ${COLORS.WHITE};
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  border-radius: 6px;
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 24px;
  gap: 10px;
`;

const CrossButtonCtr = styled.div`
  cursor: pointer;
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

const ModalBody = styled.div`
  padding: 32px 0;
`;

const HeadingMenuCtr = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const LoaderCtr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  box-sizing: border-box;
`;

const AIInsightsModal = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.TEXT_HOVER};
  z-index: 1000;
`;

const AIInsightsModalInner = styled.div`
  max-width: 750px;
  min-width: 750px;
  max-height: 80%;
  overflow: scroll;
  padding: 32px;
  background: ${COLORS.WHITE};
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  border-radius: 6px;
`;

const AIInsightsModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  gap: 10px;
`;

const AIInsightsModalTitleLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 26px;
  font-weight: normal;
  gap: 10px;
`;

const AIInsightsModalTitleRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const AIInsightsModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

const AIInsightsModalBody = styled.div`
  padding: 10px 0;
`;

export {
  HeaderCtr,
  Heading,
  TabBody,
  HeaderPlaceHolder,
  NewReportModal,
  ModalInner,
  ModalTitle,
  CrossButtonCtr,
  ModalFooter,
  ModalBody,
  HeadingMenuCtr,
  LoaderCtr,
  AIInsightsModal,
  AIInsightsModalInner,
  AIInsightsModalTitle,
  AIInsightsModalFooter,
  AIInsightsModalBody,
  AIInsightsModalTitleLeft,
  AIInsightsModalTitleRight,
};
