import React from 'react';

import { ITaskWithId, IUser } from '@learned/types';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { StatusLabel } from './statusLabel';

import DefaultAvatar from '~/assets/user-default.svg';

import { COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const SubTextContianer = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: -0.13px;
  margin-top: 14px;
  color: ${COLORS.TEXT_SECONDARY};
  align-items: center;
`;
const RepeatSectionDiv = styled.div`
  margin-left: 13.7px;
`;

const TargetSectionDiv = styled.div`
  margin-left: 13.7px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserSectionDiv = styled.div`
  display: flex;
  margin-left: 13.7px;
`;

const UserNameDiv = styled.div`
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
`;

const BadgeAvatar = styled.div<{
  avatarUrl: string;
}>`
  width: 20px;
  height: 20px;
  outline-color: transparent;
  border-radius: 24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => `url("${props.avatarUrl}")`};

  &:focus {
    outline-color: transparent;
  }
`;

type Props = {
  task: ITaskWithId;
  user: IUser;
};

const SubText = ({ task, user }: Props) => {
  const RepeatSection = () => {
    if (task.repeat) {
      return (
        <RepeatSectionDiv>
          <Trans>Repeats every</Trans> {task.repeat} <Trans>week(s)</Trans>
        </RepeatSectionDiv>
      );
    }
    return <></>;
  };

  const TargetName = () => {
    if (task.target.name) {
      return <TargetSectionDiv>{task.target.name}</TargetSectionDiv>;
    }
    return <></>;
  };

  const UserSection = () => {
    if (user && user.avatarUrl && user.firstName) {
      return (
        <UserSectionDiv>
          <BadgeAvatar avatarUrl={user.avatarUrl || DefaultAvatar} />
          <UserNameDiv>{getUserFullName(user)}</UserNameDiv>
        </UserSectionDiv>
      );
    }
    return <></>;
  };

  return (
    <SubTextContianer>
      <StatusLabel status={task.status} />
      <RepeatSection />
      <TargetName />
      <UserSection />
    </SubTextContianer>
  );
};

export { SubText };
