import React, { useState, useEffect } from 'react';

import { KPI_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import DashboardBoxHeader from '~/components/AdminDashboardBoxes/DashboardBoxHeader';
import DashboardBoxPlaceholder from '~/components/AdminDashboardBoxes/DashboardBoxPlaceholder';
import ChartLine from '~/components/ChartLine';

import SkillsIcon from '~/assets/mdi-tag-multiple.svg';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getReviewsReport } from '~/services/reviews';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 398px;
`;

const ChartLineWrapper = styled.div`
  margin-top: 16px;
  position: relative;
  height: 100%;
  width: ${(props) => props.$width};
`;

const PerformanceModuleBox = ({ i18n, width = '100%' }) => {
  const history = useHistory();
  const [graphData, setGraphData] = useState([]);
  const $fetching = useBoolState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getReviewsReport({
        pagination: { limit: 6 },
        sectionTypes: [KPI_TYPES.PERFORMANCE],
      });

      let noData = true;

      setGraphData(
        data
          .map((item) => {
            if (noData && !item.coach && !item.peer && !item.self) {
              return undefined;
            }
            noData = false;
            return item;
          })
          .filter((i) => i),
      );
      $fetching.off();
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  const labels = graphData.map((i) => moment(i.date).format('DD MMM YYYY'));
  const dataSets = [
    {
      label: i18n._(t`Self review`),
      data: graphData.map((i) => i.self),
      names: graphData.map((i) => i.name),
      maxValues: graphData.map((i) => i.selfMax),
    },
    {
      label: i18n._(t`Peer review`),
      data: graphData.map((i) => i.peer),
      names: graphData.map((i) => i.name),
      maxValues: graphData.map((i) => i.peerMax),
    },
    {
      label: i18n._(t`Coach review`),
      data: graphData.map((i) => i.coach),
      names: graphData.map((i) => i.name),
      maxValues: graphData.map((i) => i.coachMax),
    },
  ];

  const onClick = () => {
    history.push(routes.REPORT_PERFORMANCE.build({ role: ROLES.ADMIN }));
  };

  return (
    <Wrapper>
      <DashboardBoxHeader
        title={i18n._(t`Performance`)}
        subtitle={i18n._(t`Analyse the performance of your organisation over time`)}
        onClick={onClick}
      />
      {!isEmpty(labels) ? (
        <ChartLineWrapper $width={width}>
          <ChartLine
            labels={labels}
            dataSets={dataSets}
            isPercent={false}
            spanGaps
            responsive={false}
          />
        </ChartLineWrapper>
      ) : (
        <ChartLineWrapper $width={width}>
          <DashboardBoxPlaceholder
            title={i18n._(t`No data available yet`)}
            subtitle={i18n._(
              t`Start with measuring performance in your company by linking performance to questions in a review template. `,
            )}
            icon={SkillsIcon}
          />
        </ChartLineWrapper>
      )}
    </Wrapper>
  );
};

export default PerformanceModuleBox;
