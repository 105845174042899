import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import { useSelector, connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import GoalsBlock from '~/components/GoalsBlock';
import { ExportPDFOptionsModal } from '~/components/Modals/ExportPDFOptionsModal';
import OverviewHeading from '~/components/OverviewHeading';
import Tabs from '~/components/Tabs';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import Shadow from '~/components/UI/Shadow';
import TabsContainer from '~/components/UI/TabsContainer';
import BaseLayout from '~/layouts/BaseLayout';
import { ActivitiesModal } from '~/pages/PassportPage/components/ActivitiesModal';

import PassportHeader from './components/PassportHeader';
import CareerTab from './tabs/CareerTab';
import ConversationsTab from './tabs/ConversationsTab';
import LearningTab from './tabs/LearningTab';
import MeetingsTab from './tabs/MeetingsTab';
import NotesTab from './tabs/NotesTab';
import ProfileTab from './tabs/ProfileTab';
import SettingsTab from './tabs/SettingsTab';
import tabBuilder from './utils/tabBuilder';

import { ROLES } from '~/constants';
import { INSTRUCTIONS } from '~/constants/instructions';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import {
  getUser,
  getUsers,
  checkModuleGoals,
  checkModuleLearning,
  checkModuleCareer,
  checkModuleSkillPassport,
  checkLearningCreateActivityCoachModule,
  checkLearningCreatePathCoachModule,
  getAppTheme,
  getInactiveUsers,
  checkModuleReviews,
  checkModuleConversations,
} from '~/selectors/baseGetters';
import getCurrentTeam from '~/selectors/getCurrentTeam';
import { downloadUserProfileCvPDF } from '~/services/users';
import { COLOR_PALETTE } from '~/styles';
import getInstructionUrl from '~/utils/getInstructionUrl';
import getUserFullName from '~/utils/getUserFullName';

const Container = styled(Shadow)`
  background: ${COLOR_PALETTE.WHITE};
`;

const TabsWrapper = styled(Tabs)`
  margin-bottom: 0;
  border: none;
`;

const NoDataPlaceholder = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

export const TABS_ENUM = {
  CONVERSATIONS: 'conversations',
  MEETINGS: 'meetings',
  GOALS: 'goals',
  LEARNING: 'learning',
  CAREER: 'career',
  PROFILE: 'profile',
  NOTES: 'notes',
  SETTINGS: 'settings',
};

const TABS = [
  {
    label: t`Conversations`,
    key: TABS_ENUM.CONVERSATIONS,
  },
  {
    label: t`Meetings`,
    key: TABS_ENUM.MEETINGS,
  },
  {
    label: t`Goals`,
    key: TABS_ENUM.GOALS,
  },
  {
    label: t`Learning`,
    key: TABS_ENUM.LEARNING,
  },
  {
    label: t`Career`,
    key: TABS_ENUM.CAREER,
  },
  {
    label: t`Profile`,
    key: TABS_ENUM.PROFILE,
  },
  {
    label: t`Notes`,
    key: TABS_ENUM.NOTES,
  },
  {
    label: t`Settings`,
    key: TABS_ENUM.SETTINGS,
  },
];

function PassportPage({
  i18n,
  currentTeam,
  selectedRole,
  isModuleCareerEnabled,
  isModuleGoalsEnabled,
  isModuleLearningEnabled,
  isModuleSkillPassportEnabled,
  isLearningCreateActivityCoachModuleEnabled,
  isLearningCreatePathCoachModuleEnabled,
  isModuleReviewsEnabled,
  isModuleMeetingsEnabled,
}) {
  // Global state hooks
  const users = useSelector(getUsers);
  const usersInactive = useSelector(getInactiveUsers);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const viewerType = searchParams.get('view');
  const viewer = useSelector(getUser);
  const match = useRouteMatch();
  const memberId = searchParams.get('memberId');
  const userIdFromUrl = memberId || get(match, 'params.userId');
  const $openActivitiesModal = useBoolState(false);
  const $openExportCVModal = useBoolState(false);
  const { addToast } = useToasts();
  const appTheme = useSelector(getAppTheme);
  const $isFetchAgain = useBoolState(false);

  const readOnly = !!userIdFromUrl;
  const watchUserProfile =
    viewerType === 'public' ||
    (userIdFromUrl && userIdFromUrl !== viewer.id && ROLES.USER === selectedRole);
  const currentUserId = userIdFromUrl || viewer.id;
  const user = users[currentUserId];
  const inactiveUser = usersInactive[currentUserId];

  const [currentTab, setCurrentTab] = useState(
    TABS_ENUM[window.location.hash.substr(1).toUpperCase()] || TABS_ENUM.CONVERSATIONS,
  );
  const PASSPORT_TABS = tabBuilder({
    isModuleCareerEnabled,
    isModuleGoalsEnabled,
    isModuleLearningEnabled,
    isModuleSkillPassportEnabled,
    selectedRole,
    TABS,
    TABS_ENUM,
    isModuleReviewsEnabled,
    isModuleMeetingsEnabled,
  });

  const handleChangeTab = (key) => {
    window.location.hash = key;
    setCurrentTab(key);
  };

  const downloadCV = () => {
    const userName = user.firstName ? `${user.firstName} ${user?.lastName}` : user.email;
    downloadUserProfileCvPDF(currentUserId, userName);
    addToast({
      title: i18n._(t`Downloading user profile PDF`),
      subtitle: i18n._(t`Generating the user profile in PDF. Please wait a few seconds.`),
      type: TOAST_TYPES.INFO,
    });
  };

  const onOpenCreatePathFlow = () => {
    routes.PATH_CREATE.go(
      // @ts-ignore // TODO remove when LinkConstructor rewrited with TS
      {},
      {
        query: { users: [currentUserId] },
        isBackPath: true,
        noHash: true,
        hash: TABS_ENUM.LEARNING,
      },
    );
  };

  const getPageTitle = () => {
    if (selectedRole === ROLES.ADMIN) {
      return i18n._(t`Members`);
    }
    if (selectedRole === ROLES.COACH) {
      return currentTeam.name || i18n._(t`Team`);
    }
    if (selectedRole === ROLES.USER) {
      let url = new URL(window.location.href);
      const from = url.searchParams.get('from');
      if (from) {
        return i18n._(t`Dashboard`);
      }
    }
    return i18n._(t`Profile`);
  };

  const pageTitle = getPageTitle();

  const getBackPath = () => {
    let url = new URL(window.location.href);
    return url.searchParams.get('from');
  };

  const backPath = getBackPath();

  const onCloseActivityModal = (isSubmit) => {
    if (isSubmit) {
      window.location.hash = TABS_ENUM.LEARNING;
      setCurrentTab(TABS_ENUM.LEARNING);
      $isFetchAgain.toggle();
      $openActivitiesModal.off();
    } else {
      $openActivitiesModal.off();
    }
  };

  return (
    <>
      <OverviewHeading
        title={pageTitle}
        instructions={
          selectedRole !== ROLES.USER
            ? i18n._(t`How to coach with Learned`)
            : !watchUserProfile
            ? i18n._(t`How your profile works`)
            : null
        }
        instructionsUrl={
          selectedRole !== ROLES.USER
            ? getInstructionUrl(INSTRUCTIONS.HOW_TO_COACH)
            : !watchUserProfile
            ? getInstructionUrl(INSTRUCTIONS.HOW_TO_PROFILE_WORKS)
            : null
        }
        backPath={backPath}
        smallWidth
      >
        {user && selectedRole === ROLES.USER && !watchUserProfile && (
          <Button label={i18n._(t`Export PDF`)} onClick={() => $openExportCVModal.on()} />
        )}
      </OverviewHeading>
      {user ? (
        <BaseLayout smallWidth>
          <Container>
            <PassportHeader user={user} readOnly={readOnly} companyLogo={appTheme.url}>
              <TabsContainer noBorder noShadow={!readOnly || watchUserProfile}>
                {readOnly && !watchUserProfile ? (
                  <TabsWrapper
                    items={PASSPORT_TABS}
                    isSmall
                    currentItem={currentTab}
                    handleChangeTab={handleChangeTab}
                  />
                ) : (
                  <ProfileTab user={user} readOnly={readOnly} watchUserProfile={watchUserProfile} />
                )}
              </TabsContainer>
            </PassportHeader>
          </Container>
          {readOnly && !watchUserProfile && (
            <div style={{ marginTop: '24px' }}>
              {currentTab === TABS_ENUM.CONVERSATIONS && (
                <ConversationsTab user={user} readOnly={readOnly} />
              )}
              {currentTab === TABS_ENUM.MEETINGS && <MeetingsTab user={user} readOnly={readOnly} />}
              {currentTab === TABS_ENUM.GOALS && (
                <GoalsBlock isPublicPage={true} forUser={user} currentUserId={currentUserId} />
              )}
              {currentTab === TABS_ENUM.LEARNING && (
                <LearningTab
                  user={user}
                  readOnly={readOnly}
                  isFetchAgain={$isFetchAgain.value}
                  showLearningActivityModal={
                    isModuleLearningEnabled && isLearningCreateActivityCoachModuleEnabled
                      ? $openActivitiesModal.on
                      : undefined
                  }
                  createLearningPath={
                    isModuleLearningEnabled &&
                    isLearningCreateActivityCoachModuleEnabled &&
                    isLearningCreatePathCoachModuleEnabled
                      ? onOpenCreatePathFlow
                      : undefined
                  }
                />
              )}
              {currentTab === TABS_ENUM.CAREER && <CareerTab readOnly={readOnly} user={user} />}
              {currentTab === TABS_ENUM.PROFILE && (
                <ProfileTab
                  user={user}
                  readOnly={readOnly}
                  exportCsvModal={$openExportCVModal.on}
                />
              )}
              {currentTab === TABS_ENUM.NOTES && <NotesTab user={user} readOnly={readOnly} />}
              {currentTab === TABS_ENUM.SETTINGS && <SettingsTab readOnly={readOnly} />}
            </div>
          )}
        </BaseLayout>
      ) : (
        <NoDataPlaceholder>
          {inactiveUser
            ? i18n._(t`User ${getUserFullName(inactiveUser)} is disabled!`)
            : i18n._(t`User does not exist in the company!`)}
        </NoDataPlaceholder>
      )}

      {$openActivitiesModal.value && (
        <ActivitiesModal userId={currentUserId} onClose={onCloseActivityModal} />
      )}
      {$openExportCVModal.value && (
        <ExportPDFOptionsModal
          onClose={() => $openExportCVModal.off()}
          downloadCV={downloadCV}
          user={user}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    currentTeam: getCurrentTeam(state),
    selectedRole: state.selected.role,
    isModuleCareerEnabled: checkModuleCareer(state),
    isModuleSkillPassportEnabled: checkModuleSkillPassport(state),
    isModuleGoalsEnabled: checkModuleGoals(state),
    isModuleLearningEnabled: checkModuleLearning(state),
    isLearningCreateActivityCoachModuleEnabled: checkLearningCreateActivityCoachModule(state),
    isLearningCreatePathCoachModuleEnabled: checkLearningCreatePathCoachModule(state),
    isModuleReviewsEnabled: checkModuleReviews(state),
    isModuleMeetingsEnabled: checkModuleConversations(state),
  };
};

export default withI18n()(connect(mapStateToProps)(PassportPage));
