import { ProductName, ROLES } from '@learned/constants';
import { createSelector } from 'reselect';

import { isProductEnabled } from '~/utils/company';

export const getUser = (state) => state.auth.user;
export const getUsers = (state) => state.users.data;
export const getInactiveUsers = (state) => state.users.inactive;
export const getDeletedUsers = (state) => state.users.deleted;
export const getCompanies = (state) => state.companies.data;
export const getCompanyFocusAreas = (state) => state.companySettings.focusAreas;
export const checkUserIsAllowedToSetNotificationPreferences = (state) =>
  state.companySettings?.notificationSettings?.userIsAllowedToSetNotificationPreferences;
export const getSkillSets = (state) => state.skillSets;
export const getGoals = (state) => state.goals.data;
export const getCurrentReview = (state) => state.currentReview;
export const getCurrentRequest = (state) => state.currentRequest;
export const getCurrentRatings = (state) => state.currentRatings;
export const getCompanyConnections = (state) => state.companyConnections;
export const getJobProfiles = (state) => state.jobProfiles?.data;

export const getSelectedTeam = (state) => state.selected.team;
export const getCurrentCompanyId = (state) => state.selected.company;

/**
 * @deprecated Should use getCurrentProducts
 */
export const getSelectedCompanyProducts = (state) =>
  state.companies.data[state.selected.company]?.products || {};
export const getSelectedRole = (state) => state.selected.role;

export const getSettingsRole = createSelector([getUser], (user) =>
  user.isAdmin ? ROLES.ADMIN : user.isCoach ? ROLES.COACH : ROLES.USER,
);

export const getTeams = (state) => state.teams.data;
export const getLocale = (state) => state.locale;
export const getAppState = (state) => state.app;

export const getAppTheme = (state) => state.appTheme.data;

export const getInvitesSet = (state) => state.invites;
export const getNotificationsSet = (state) => state.notifications;

// products
export const getCurrentProducts = (state) => state.products;

// Job matrix
export const checkModuleJobMatrix = (state) => isProductEnabled(state, ProductName.JOB_MATRIX);

// Career
export const checkModuleCareer = (state) => isProductEnabled(state, ProductName.CAREER);
export const isModuleAmbitionsEnabled = (state) =>
  checkModuleCareer(state) && state.products?.career.settings.employeeCanSetAmbitions.isEnabled;
export const isUserRoleAssignModuleEnabled = (state) =>
  checkModuleCareer(state) && state.products?.career.settings.userCanAssignRoles.isEnabled;

// pagination
export const getNumberOfItemsPerPage = (state) => state.pagination;

// Performance
export const checkModuleReviews = (state) => isProductEnabled(state, ProductName.PERFORMANCE);
export const checkModuleCoachCreateReview = (state) =>
  checkModuleReviews(state) && state.products?.performance.settings.coachCanCreateReviews.isEnabled;
export const checkModuleIsShowRolesInReview = (state) =>
  checkModuleReviews(state) &&
  state.products?.performance.settings.showEmployeeRolesInReviews.isEnabled;
export const checkModulePreviewDevelopmentCycle = (state) =>
  checkModuleReviews(state) &&
  state.products?.performance.settings.previewDevelopmentCycle.isEnabled;
export const checkModuleConversations = (state) =>
  checkModuleReviews(state) &&
  state.products?.performance.settings.conversationsAndTeamMeetings.isEnabled;
export const checkModuleRTFeedbacks = (state) =>
  checkModuleReviews(state) && state.products?.performance.settings.realtimeFeedback.isEnabled;
export const checkModuleReviewQuickSelect = (state) =>
  checkModuleReviews(state) &&
  state.products?.performance.settings.usersCanQuickSelectOtherUsersInReviews.isEnabled;
export const isUserConversationsModuleEnabled = (state) =>
  checkModuleReviews(state) && state.products?.performance.settings.userCanCreateReviews.isEnabled;
export const checkModuleGoals = (state) =>
  checkModuleReviews(state) && state.products?.performance.settings?.goals?.isEnabled;
export const checkModuleGoalsInTeam = (state) =>
  checkModuleReviews(state) &&
  checkModuleGoals(state) &&
  state.products?.performance.settings?.goalsInTeam?.isEnabled;

export const checkModuleGoalsInOrganisation = (state) =>
  checkModuleReviews(state) &&
  checkModuleGoals(state) &&
  state.products?.performance.settings?.goalsInOrganisation?.isEnabled;

// Talent
export const checkModuleTalent = (state) => isProductEnabled(state, ProductName.TALENT);
export const checkModuleRoleSearch = (state) =>
  checkModuleTalent(state) &&
  state.products?.talent.settings.employeeCanSearchBasedOnRoles.isEnabled;
export const checkModuleColleaguesSearch = (state) =>
  checkModuleTalent(state) && state.products?.talent.settings.employeeCanLookupProfile.isEnabled;
export const checkModuleSkillPassport = (state) =>
  checkModuleTalent(state) &&
  state.products?.talent.settings.employeeCanSetUpSkillPassport.isEnabled;

// Learning and onboarding
export const checkModuleLearning = (state) =>
  isProductEnabled(state, ProductName.LEARNING_AND_ONBOARDING);
export const isLearningActivitiesModuleEnabled = (state) =>
  checkModuleLearning(state) &&
  state.products?.learningAndOnboarding.settings.freeLearnedActivities.isEnabled;
export const checkLearningCreateActivityUserModule = (state) =>
  checkModuleLearning(state) &&
  state.products?.learningAndOnboarding.settings.employeeCanCreateActivity.isEnabled;
export const checkLearningCreateActivityCoachModule = (state) =>
  checkModuleLearning(state) &&
  state.products?.learningAndOnboarding.settings.coachCanCreateActivity.isEnabled;
export const checkLearningCreatePathUserModule = (state) =>
  checkModuleLearning(state) &&
  state.products?.learningAndOnboarding.settings.employeeCanCreatePath.isEnabled;
export const checkLearningCreatePathCoachModule = (state) =>
  checkModuleLearning(state) &&
  state.products?.learningAndOnboarding.settings.coachCanCreatePath.isEnabled;
export const isLearningCareerModuleEnabled = (state) =>
  checkModuleLearning(state) &&
  state.products?.learningAndOnboarding.settings.showActivityProgressForRole.isEnabled;

// Surveys
export const checkModuleSurvey = (state) => isProductEnabled(state, ProductName.ENGAGEMENT);

// Integrations
export const checkModuleIntegrations = (state) => isProductEnabled(state, ProductName.INTEGRATIONS);

// Custom Reports of the reports module
export const getCustomReports = (state) => state.customReports?.data;
