import React, { useState, useEffect } from 'react';

import { QUESTION_TYPES, KPI_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FeedbackIcon from '~/components/Icons/Feedback';
import Placeholder from '~/components/Placeholder';
import SearchSelectButton from '~/components/SearchSelectButton';
import Spider from '~/components/Spider';

import { getSpiderItems } from './utils';

import getLang from '~/selectors/getLang';
import { COLORS, COLOR_SET } from '~/styles';
import getSkillName from '~/utils/getSkillName';
import {
  getAverageSkillsList,
  getAverageCustomList,
  getAverageGoalList,
  getPeerRations,
  getSelfRatings,
  getCoachRations,
  filterLastRates,
  getExpectedLevels,
} from '~/utils/ratingUtils';

const Content = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: ${COLORS.TEXT_PRIMARY};
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
`;

const ITEM_TYPE = {
  SKILL: 'skill',
  QUESTION: 'question',
  GOAL: 'goal',
};

function prepareRadioData({
  review,
  i18n,
  lang,
  rates,
  selectedItems,
  isCustom = false,
  activeSectionIndex,
  section,
  role,
}) {
  const selfRatings = getSelfRatings(rates);
  const peerRatings = getPeerRations(rates);
  const coachRatings = getCoachRations(rates);

  // questions with skills
  const avgSelfSkills = getAverageSkillsList(selfRatings);
  const avgPeerSkills = getAverageSkillsList(peerRatings);
  const avgCoachSkills = getAverageSkillsList(coachRatings);
  const expectedLevels = getExpectedLevels(review.skillsJobProfileLevels || []);

  // questions custom
  const avgSelfCustom = getAverageCustomList(selfRatings);
  const avgPeerCustom = getAverageCustomList(peerRatings);
  const avgCoachCustom = getAverageCustomList(coachRatings);

  // questions goals
  const avgSelfGoal = getAverageGoalList(selfRatings);
  const avgPeerGoal = getAverageGoalList(peerRatings);
  const avgCoachGoal = getAverageGoalList(coachRatings);

  const items = {};
  // add item with ratings per skillJobProfile
  if (!isEmpty(review.skillsJobProfile) && (!isCustom || role)) {
    review.skillsJobProfile.forEach((s) => {
      if (selectedItems.includes(s.id)) {
        items[s.id] = { type: ITEM_TYPE.SKILL, id: s.id };
      }
    });
  }

  // add item with ratings per custom question
  if ((!isEmpty(review.questions) && isCustom) || !role) {
    review.questions.forEach((q) => {
      if (
        q.type === QUESTION_TYPES.CUSTOM &&
        q.sectionIndex === activeSectionIndex &&
        selectedItems.includes(q.id)
      ) {
        items[q.id] = { type: ITEM_TYPE.QUESTION, id: q.id };
      }
    });
  }

  // add item with goal
  if (!isEmpty(review.questions) || !role) {
    review.questions.forEach((q) => {
      if (q.sectionIndex === activeSectionIndex) {
        switch (q.type) {
          case QUESTION_TYPES.GOAL_BUSINESS_EVAL: {
            review.goalsBusinessEval.forEach((g) => {
              const key = g.id;
              if (selectedItems.includes(key)) {
                items[key] = { type: ITEM_TYPE.GOAL, id: key };
              }
            });
            break;
          }
          case QUESTION_TYPES.GOAL_LEARNING_EVAL: {
            review.goalsLearningEval.forEach((g) => {
              const key = g.id;
              if (selectedItems.includes(key)) {
                items[key] = { type: ITEM_TYPE.GOAL, id: key };
              }
            });
            break;
          }
          default:
            break;
        }
      }
    });
  }

  // add avg rating per skill/question for self/peer/coach to item
  Object.keys(items).forEach((key) => {
    const self = find([...avgSelfSkills, ...avgSelfCustom, ...avgSelfGoal], (r) => r.id === key);
    const peer = find([...avgPeerSkills, ...avgPeerCustom, ...avgPeerGoal], (r) => r.id === key);
    const coach = find(
      [...avgCoachSkills, ...avgCoachCustom, ...avgCoachGoal],
      (r) => r.id === key,
    );
    const expected = find([...expectedLevels], (r) => r.id === key);
    items[key].self = self;
    items[key].peer = peer;
    items[key].coach = coach;
    items[key].expected = expected;
  });

  const itemsToDisplay = Object.values(items);
  // prepare labels: skillName/custom question name
  const labels = itemsToDisplay.map((item) => {
    switch (item.type) {
      case ITEM_TYPE.SKILL:
        return [
          getSkillName(
            find(
              [...(review.skills || []), ...(review.skillsJobProfile || [])],
              (s) => s.id === item.id,
            ),
            lang,
          ),
        ];
      case ITEM_TYPE.QUESTION:
        return (find(review.questions, (q) => q.id === item.id) || {}).name;
      case ITEM_TYPE.GOAL: {
        const goal = find(
          [...(review.goalsBusinessEval || []), ...(review.goalsLearningEval || [])],
          (g) => g.id === item.id,
        );
        return goal && goal.name;
      }
      default:
        return;
    }
  });

  function prepareData(type) {
    // type could be self/peer/coach
    return itemsToDisplay.map((item) => {
      const r = item[type];
      if (!r) {
        return null;
      }
      return r.avg;
    });
  }

  const data = {
    labels,
    datasets: [],
  };

  const isSelfRatingsExist = section.isSelfReview ? !isEmpty(selfRatings) : false;
  const isPeerRatingsExist = section.isUsersReview ? !isEmpty(peerRatings) : false;
  const isCoachRatingsExist = section.isCoachesReview ? !isEmpty(coachRatings) : false;
  const isExpectedLevelExist = (!isCustom || role) && !isEmpty(expectedLevels);

  // add self
  if (isSelfRatingsExist) {
    data.datasets.push({
      fill: false,
      borderColor: COLOR_SET.BLUE,
      backgroundColor: COLOR_SET.BLUE,
      data: prepareData('self'),
      label: i18n._(t`Self assessment`),
    });
  }

  // add peer
  if (isPeerRatingsExist) {
    data.datasets.push({
      fill: false,
      borderColor: COLOR_SET.AMBER_YELLOW,
      backgroundColor: COLOR_SET.AMBER_YELLOW,
      data: prepareData('peer'),
      label: i18n._(t`Peer assessment`),
    });
  }

  // add coach
  if (isCoachRatingsExist) {
    data.datasets.push({
      fill: false,
      borderColor: COLOR_SET.PINK,
      backgroundColor: COLOR_SET.PINK,
      data: prepareData('coach'),
      label: i18n._(t`Coach assessment`),
    });
  }

  if (isExpectedLevelExist) {
    data.datasets.push({
      fill: false,
      borderColor: COLOR_SET.CYAN_GREEN,
      backgroundColor: COLOR_SET.CYAN_GREEN,
      data: prepareData('expected'),
      label: i18n._(t`Expected level`),
    });
  }

  return data;
}

function NewSpiderReview({ review, sectionType, sectionIndex, i18n, pdfView, role }) {
  const [selectedItems, setSelectedItems] = useState([]);
  const lang = useSelector(getLang);
  const activeSectionIndex =
    sectionIndex || get(review, 'template.sections', []).findIndex((s) => s.type === sectionType);
  const section = get(review, 'template.sections', []).find(
    (s, i) => s.type === sectionType || i === sectionIndex,
  );

  // update selected items if user changes active tab
  useEffect(() => {
    const items = getSpiderItems(review, { sectionType, activeSectionIndex, role });

    // first 10
    const selectedItems = items.map((i) => i.id).slice(0, 10);

    setSelectedItems(selectedItems);
  }, [sectionType, review, activeSectionIndex, role]);

  const preparedRatings = prepareRadioData({
    i18n,
    lang,
    review,
    selectedItems,
    role,
    rates: role
      ? filterLastRates(
          (review.ratings || []).filter(
            (r) => r.skill && r.rate !== 0 && role?.skills.includes(r.skill),
          ),
        )
      : sectionType === KPI_TYPES.SKILLS
      ? filterLastRates((review.ratings || []).filter((r) => r.skill && r.rate !== 0))
      : filterLastRates(
          (review.ratings || []).filter((r) => (r.question || r.goal) && r.rate !== 0),
        ),
    isCustom: sectionType !== KPI_TYPES.SKILLS,
    activeSectionIndex,
    section,
  });

  const noRates = preparedRatings.datasets.length === 0;

  // define max Radar scale value
  const skillsQuestions = (review.questions || []).filter(
    (q) => q.type === QUESTION_TYPES.JOB_PROFILE,
  );
  const customQuestions = (review.questions || []).filter(
    (q) =>
      [
        QUESTION_TYPES.CUSTOM,
        QUESTION_TYPES.GOAL_BUSINESS_EVAL,
        QUESTION_TYPES.GOAL_LEARNING_EVAL,
      ].includes(q.type) && q.sectionIndex === activeSectionIndex,
  );

  // for skills maxValue equal to length scaleLabels in question with skills or skillsJobProfile
  const maxValueForSkills = get(skillsQuestions, '[0]scaleLabels.length', 0);

  // for skills maxValue equal to max length scaleLabels in custom questions
  const customQuestionsScaleSize = customQuestions.map((q) => get(q, 'scaleLabels.length', 0));
  const maxValueForCustom = max(customQuestionsScaleSize);

  // prepare options for select items
  const reviewItems = getSpiderItems(review, { sectionType, activeSectionIndex, role });

  // check name or nameNL key
  const options = reviewItems.map((i) => ({
    label: getSkillName(i, lang),
    id: i.id,
  }));

  return (
    <Wrapper>
      <Content>
        <Title>
          <Trans>Summary</Trans>
        </Title>
        {!pdfView && (
          <SearchSelectButton
            checkedList={selectedItems}
            title={i18n._(t`Questions`)}
            options={options}
            handleChange={(items) => setSelectedItems(items)}
            width="348px"
          />
        )}

        <Spider
          data={preparedRatings}
          maxValue={
            // spider inside JP question doesn't have sectionType
            !sectionType || sectionType === KPI_TYPES.SKILLS ? maxValueForSkills : maxValueForCustom
          }
          showPlaceholder={noRates}
          placeholder={
            <Placeholder
              title={i18n._(t`No feedback`)}
              className="tp-placeholder"
              Icon={FeedbackIcon}
              subTitle={i18n._(
                t`The results from your self review and the reviews from peers and coaches will be summarised here.`,
              )}
            />
          }
        />
      </Content>
    </Wrapper>
  );
}

NewSpiderReview.propTypes = {
  review: PropTypes.object.isRequired,
  sectionType: PropTypes.string,
};

export default withI18n()(NewSpiderReview);
