import React, { useEffect, useState } from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import OverviewHeading from '~/components/OverviewHeading';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import BaseLayout from '~/layouts/BaseLayout';
import LoadingPage from '~/pages/LoadingPage';
import TemplatePreviewBody from '~/pages/ReviewTemplatePreview/components/TemplatePreviewBody';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { createTemplate, getTemplate, getTemplatesOld } from '~/services/reviewTemplates';
import { COLOR_PALETTE } from '~/styles';
import { getTemplateCopyName } from '~/utils/templateUtils';

const Wrapper = styled.div`
  border: 1px solid ${COLOR_PALETTE.GRAY_MIDDLE};
  background-color: ${COLOR_PALETTE.WHITE};
  padding: 8px 16px;
  border-radius: 6px;
`;

const ReviewTemplatePreview = ({ i18n }) => {
  const match = useRouteMatch();
  const { addToast } = useToasts();
  const templateId = get(match, 'params.templateId');
  const [template, setTemplate] = useState();
  const $isLearnedTemplate = useBoolState(false);
  const [companyTemplates, setCompanyTemplates] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const [fetchedTemplate, fetchedCompanyTemplates] = await Promise.all([
        getTemplate(templateId),
        getTemplatesOld(),
      ]);
      setTemplate(fetchedTemplate);
      setCompanyTemplates(Object.values(fetchedCompanyTemplates));
      if (fetchedTemplate.company === 'superadmin') {
        $isLearnedTemplate.on();
      }
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  if (!template) {
    return <LoadingPage />;
  }

  const copyTemplate = async () => {
    await createTemplate({
      ...template,
      name: getTemplateCopyName(template, companyTemplates),
    });
    addToast({
      title: i18n._(t`Template duplicated`),
      subtitle: i18n._(t`You can find your template in the company templates.`),
      type: TOAST_TYPES.INFO,
    });
    goBack();
  };

  const goBack = () =>
    routes.CONVERSATIONS.go({ role: ROLES.USER }, { hash: 'development-templates' });

  return (
    <>
      <OverviewHeading onBack={goBack} title={i18n._(t`Templates`)} smallWidth={true}>
        <Button label={i18n._(t`Copy template`)} onClick={copyTemplate} />
      </OverviewHeading>
      <BaseLayout smallWidth={true}>
        <Wrapper>
          <TemplatePreviewBody isLearnedTemplate={$isLearnedTemplate.value} template={template} />
        </Wrapper>
      </BaseLayout>
    </>
  );
};

export default withI18n()(ReviewTemplatePreview);
