import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui, withI18n } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { CareerBlock } from '~/components/ProfileSettingsComponents/MainDashboard/Sections/CareerBlock';

import SectionAdminRights from './components/UserSettings/SectionAdminRights';
import { SectionCoachTeams } from './components/UserSettings/SectionCoachTeams';
import { SectionDetails } from './components/UserSettings/SectionDetails';
import { SectionRoles } from './components/UserSettings/SectionRoles';
import { SectionUserTeams } from './components/UserSettings/SectionUserTeams';

import { getTeams } from '~/store/teams/actions';
import { COLORS } from '~/styles';

const Container = styled.div`
  margin: 0px;
`;

const SectionWrapper = styled.div`
  margin-bottom: 20px;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  padding: 20px 40px 30px 40px;

  & > div:first-child {
    padding: 0px;
    margin-bottom: 0px;
  }
`;

const SettingsTab = ({ teams, dispatch }) => {
  const [teamsState, setTeamsState] = useState({});
  const [fetching, setFetching] = useState(true);
  const match = useRouteMatch();
  const userId = get(match, 'params.userId');
  const { i18n } = useLingui();

  useEffect(() => {
    setFetching(true);
    dispatch(getTeams());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(teams)) {
      setTeamsState(teams);
    }
    setFetching(false);
  }, [teams]);

  return (
    <Container>
      <SectionWrapper>
        <SectionDetails userId={userId} />
      </SectionWrapper>
      <SectionWrapper>
        <CareerBlock
          title={i18n._(t`Company information`)}
          userId={userId}
          isMemberProfile={true}
          layout="1fr 1fr 1fr"
        />
      </SectionWrapper>
      <SectionWrapper>
        <SectionRoles userId={userId} />
      </SectionWrapper>
      <SectionWrapper>
        <SectionUserTeams userId={userId} teamsState={teamsState} fetching={fetching} />
      </SectionWrapper>
      <SectionWrapper>
        <SectionCoachTeams userId={userId} teamsState={teamsState} fetching={fetching} />
      </SectionWrapper>
      <SectionWrapper>
        <SectionAdminRights userId={userId} />
      </SectionWrapper>
    </Container>
  );
};

const mapStateToProps = (state) => ({ teams: state.teams.data || {} });

export default withI18n()(connect(mapStateToProps)(SettingsTab));
