import React, { ReactNode, useEffect, useState } from 'react';

import { ITeam } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  AutocompleteFilterConversationStatus,
  AutocompleteFilterMembers,
} from '~/components/AutocompleteFilters';
import { TableList } from '~/components/TableList';
import UserDashboardUsersModal from '~/components/UserDashboardUsersModal';
import { CalendarDropdown } from '~/pages/Conversations/components/CalendarDropdown';
import type { IFilterType } from '~/pages/OnboardAndLearn/tabs/AllLearningsTab/types';

import { COLUMNS, SORT_OPTIONS } from './columns';

import { CONVERSATION_COLLECTION_TYPES, CONVERSATION_FILTER_STATUSES, ROLES } from '~/constants';
import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import {
  checkModuleCoachCreateReview,
  getSelectedRole,
  getTeams,
  getUser,
  getUsers,
} from '~/selectors/baseGetters';
import { getConversationsAndReviews } from '~/services/userConversations';
import { getConversationPath } from '~/utils/userConversationsUtils';

import type { IReview } from '@learned/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

const StyledAutocompleteMembers = styled(AutocompleteFilterMembers)`
  width: 140px;
  max-width: 140px;
`;

const StyledAutocompleteStatus = styled(AutocompleteFilterConversationStatus)`
  width: 140px;
  max-width: 140px;
`;

interface ICoachUsers {
  userId: string;
  teamId: string;
}

export interface IType {
  id: string;
  key: string;
  name: string;
  translated: (i18n: any) => ReactNode;
}

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_DEVELOPMENT_PARTICIPATION;

const initialFilters = {
  isShowFilters: false,
  search: '',
  statuses: [],
  types: [],
  sortBy: SORT_OPTIONS.NAME_A_Z,
  selectedDateOption: undefined,
  selectedStatus: CONVERSATION_FILTER_STATUSES.ALL,
  selectedCoaches: [],
  pagination: DEFAULT_PAGINATION,
};

const ParticipationTab = () => {
  const { i18n } = useLingui();
  const history = useHistory();
  const user = useSelector(getUser);
  const users = useSelector(getUsers);
  const selectedRole = useSelector(getSelectedRole);
  const isModuleCoachCreateConversation = useSelector(checkModuleCoachCreateReview);
  const [items, setItems] = useState<IReview[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateConversationModal, setIsCreateConversationModal] = useState(false);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch

  // define coach members
  const teams: { [key: string]: ITeam } = useSelector(getTeams);
  let coachUsers: ICoachUsers[] = [];
  Object.values(teams).forEach((team) => {
    if (team.coaches.includes(user.id)) {
      coachUsers = coachUsers.concat(
        team.members
          .map((uid) => ({ userId: uid, teamId: team.id }))
          .filter((u) => !coachUsers.find((c) => c.userId === u.userId)),
      );
    }
  });

  const fetchData = async () => {
    setIsLoading(true);

    const { conversations: items, total } = await getConversationsAndReviews({
      search: currentFilters.search || null,

      // filters
      coaches: currentFilters.selectedCoaches.length
        ? currentFilters.selectedCoaches.map((i: { id: string }) => i.id)
        : null,
      status:
        currentFilters.selectedStatus.key === CONVERSATION_FILTER_STATUSES.ALL.key
          ? null
          : currentFilters.selectedStatus.key,
      ...(currentFilters.selectedDateOption && {
        startDate: currentFilters.selectedDateOption.fromDate,
      }),
      ...(currentFilters.selectedDateOption && {
        endDate: currentFilters.selectedDateOption.toDate,
      }),

      // requirements
      tabsType: 'all',
      collectionType: CONVERSATION_COLLECTION_TYPES.REVIEW.key,
      userId: user.id,
      conversationTab: 'participated',

      // options
      skip: currentFilters.pagination.skip,
      limit: currentFilters.pagination.limit,
      order: currentFilters.sortBy,
    });

    // populate createdFor
    const itemsPopulated = map(items, (item) => {
      item.createdForPopulated = users[item.createdFor];
      return item;
    });

    setItems(itemsPopulated);
    setTotalCount(total);
    setIsLoading(false);
  };

  // change filters fetch
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters)]);

  // only display is option in module is enabled
  // and is user has COACH role
  const actionButton =
    isModuleCoachCreateConversation && user.isCoach
      ? {
          label: t`Create conversation`,
          onClick: () => setIsCreateConversationModal(true),
        }
      : undefined;

  const onCreateConversation = (userForConversation?: ICoachUsers) => {
    routes.REVIEW_CREATE.go(
      userForConversation
        ? { role: ROLES.COACH, teamId: userForConversation.teamId }
        : { role: ROLES.USER },
      {
        isBackPath: true,
        query: { users: [userForConversation ? userForConversation.userId : user.id] },
      },
    );
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) =>
      setCurrentFilters((prevState: IFilterType) => ({
        ...prevState,
        search: value,
        pagination: DEFAULT_PAGINATION, // reset pagination
      })),

    // @ts-ignore
    onChangeFilter: (key, value) =>
      setCurrentFilters({ ...currentFilters, pagination: DEFAULT_PAGINATION, [key]: value }),
    resetFilters: () => setCurrentFilters(initialFilters),
    selectedStatus: currentFilters.selectedStatus,
    selectedDateOption: currentFilters.selectedDateOption,
    selectedCoaches: currentFilters.selectedCoaches,
  };

  const onItemClick = {
    column: 'name',
    onClick: (conversation: any) => {
      const path = getConversationPath({
        conversation,
        selectedRole,
        userId: user.id,
        user,
        teams,
      });
      history.push(path as string);
    },
  };

  return (
    <Wrapper>
      <TableList
        data={items}
        columns={COLUMNS}
        onColClick={onItemClick}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: SORT_OPTIONS) => setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        isDraftStatusVisible
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={isLoading}
        placeholderProps={{
          noResultText: i18n._(t`No conversations found`),
          emptyStateText: i18n._(t`No conversations yet… Let’s create one!`),
        }}
        actionButton={actionButton}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length,
          isToggleHideFilterVisible: true,
          resetFilters: filters.resetFilters,
          filterComponents: (
            <>
              {selectedRole === ROLES.USER && (
                <StyledAutocompleteMembers
                  checkedList={currentFilters.selectedCoaches}
                  onChange={(value: IType[]) => filters.onChangeFilter('selectedCoaches', value)}
                />
              )}
              <StyledAutocompleteStatus
                checkedList={[filters.selectedStatus]}
                onChange={(value: IType[]) => {
                  filters.onChangeFilter('selectedStatus', value);
                }}
                isSingleSelect
                isDeleteDisabled
              />
              <CalendarDropdown
                disabled={false}
                selectedItem={filters.selectedDateOption}
                // @ts-ignore
                setSelectedItem={(value: IType[]) =>
                  filters.onChangeFilter('selectedDateOption', value)
                }
                filterOptions={[2, 1, 5, 6, 9]}
              />
            </>
          ),
        }}
      />

      {isCreateConversationModal && (
        <UserDashboardUsersModal
          users={coachUsers}
          title={i18n._('Select participant')}
          onClose={() => setIsCreateConversationModal(false)}
          onClick={onCreateConversation}
          isTeamsSelector={true}
        />
      )}
    </Wrapper>
  );
};

export { ParticipationTab };
