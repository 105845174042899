import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import styled from 'styled-components';

import IconMenu from '~/components/IconMenu';
import RickTextView from '~/components/RickTextView';

import { COLORS, COLOR_PALETTE } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

const Wrapper = styled.div`
  border-radius: 6px;
  border: solid 1px ${COLORS.BORDER_HARD};
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  margin-bottom: 12px;
`;

const RichTextViewWrapper = styled(RickTextView)`
  color: ${COLOR_PALETTE.DARK_GRAY};
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const NoteDate = styled.div`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin-top: 16px;
`;

const Top = styled.div`
  display: flex;
`;

const NoteItem = ({ note, editNote, removeNote, i18n }) => {
  const menuItems = [
    {
      label: i18n._(t`Update`),
      action: () => editNote(note),
    },
    {
      label: i18n._(t`Remove`),
      action: () => removeNote(note),
    },
  ];
  return (
    <Wrapper>
      <Top>
        <RichTextViewWrapper html={note.value} />
        <IconMenu items={menuItems} />
      </Top>
      <NoteDate>{convertToTimeString(note?.meta?.createdDate, TIME_FORMATS.CLASSIC_FULL)}</NoteDate>
    </Wrapper>
  );
};

export default withI18n()(NoteItem);
