import React from 'react';

import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import PeopleIcon from '~/components/Icons/People';
import Modal from '~/components/Modal';
import Placeholder from '~/components/Placeholder';

import RoleItem from './RoleItem';

const Wrapper = styled.div`
  max-height: 400px;
`;

function JobProfilesToggleModal({ items, itemsSelected, onClose, onToggle, isReadOnly }) {
  return (
    <Modal
      title={<Trans>Enabled roles</Trans>}
      onClose={onClose}
      width={625}
      contentStyles={{ padding: 0 }}
      hideFooter
    >
      <Wrapper>
        {isEmpty(items) ? (
          <Placeholder
            title={<Trans>No roles</Trans>}
            subTitle={<Trans>No active roles</Trans>}
            Icon={PeopleIcon}
          />
        ) : (
          items.map((item) => (
            <RoleItem
              key={item.id}
              name={item.name}
              onToggle={onToggle && ((enable) => onToggle(item.id, enable))}
              value={itemsSelected.includes(item.id)}
              isDisabled={isReadOnly}
            />
          ))
        )}
      </Wrapper>
    </Modal>
  );
}

JobProfilesToggleModal.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  itemsSelected: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  onToggle: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default React.memo(JobProfilesToggleModal);
