import { GOAL_TYPES } from '@learned/constants';
import { IGoal } from '@learned/types';
import filter from 'lodash/filter';

export function goalIDMapper(goal: IGoal) {
  return goal.id;
}

export function goalByTypeFilter(goal: IGoal, goalType: GOAL_TYPES) {
  return goal.type === goalType;
}

export function filterGoalsByType(goals: IGoal[], goalType: GOAL_TYPES) {
  return filter(goals, (g) => goalByTypeFilter(g, goalType));
}
