import { ROLES } from '@learned/constants';

import { TASKS_TYPES, TASKS_ACTIONS } from '~/constants';
import routes from '~/constants/routes';

export default (item) => {
  switch (item.type) {
    case TASKS_TYPES.RT_FEEDBACK.key:
      return item.status === TASKS_ACTIONS.START.key
        ? routes.RTFEEDBACK_PROVIDED.build(
            {},
            {
              rtfeedbackId: item.target.targetId,
              isBackPath: true,
            },
          )
        : routes.RTFEEDBACKS.build({}, { isBackPath: true });
    case TASKS_TYPES.SELF_REVIEW.key:
      return [
        TASKS_ACTIONS.START.key,
        TASKS_ACTIONS.CONTINUE.key,
        TASKS_ACTIONS.UPDATE.key,
      ].includes(item.status)
        ? routes.REVIEW_GIVE_FEEDBACK_SELF.build(
            {},
            {
              reviewId: item.target.targetId,
              isBackPath: true,
            },
          )
        : routes.REVIEW.build(
            {},
            {
              reviewId: item.target.targetId,
              isBackPath: true,
            },
          );
    case TASKS_TYPES.NOMINATE_PEERS.key: {
      const isActive = [TASKS_ACTIONS.START.key, TASKS_ACTIONS.UPDATE.key].includes(item.status);
      return routes.REVIEW.build(
        {},
        {
          reviewId: item.target.targetId,
          isBackPath: true,
          query: {
            ...(isActive && { isNominateModal: true }),
          },
        },
      );
    }
    case TASKS_TYPES.COACH_REVIEW.key:
    case TASKS_TYPES.PEER_REVIEW.key:
      return [
        TASKS_ACTIONS.START.key,
        TASKS_ACTIONS.CONTINUE.key,
        TASKS_ACTIONS.UPDATE.key,
        TASKS_ACTIONS.SHARE.key,
      ].includes(item.status)
        ? routes.REVIEW_GIVE_FEEDBACK.build(
            {},
            {
              reviewId: item.target.targetId,
              requestId: item.target.requestId,
              isBackPath: true,
            },
          )
        : routes.REVIEWS_INCOMING_REPORT.build(
            {},
            {
              requestId: item.target.requestId,
              isBackPath: true,
            },
          );
    case TASKS_TYPES.PLAN_CONVERSATION.key: {
      const isActive = [
        TASKS_ACTIONS.START.key,
        TASKS_ACTIONS.CONTINUE.key,
        TASKS_ACTIONS.UPDATE.key,
      ].includes(item.status);
      return routes.REVIEW.build(
        {},
        {
          reviewId: item.target.targetId,
          isBackPath: true,
          query: {
            ...(isActive && { isPlanConversationModal: true }),
          },
        },
      );
    }
    case TASKS_TYPES.DIGITAL_SIGN_SELF.key: {
      const isActive = [
        TASKS_ACTIONS.START.key,
        TASKS_ACTIONS.CONTINUE.key,
        TASKS_ACTIONS.UPDATE.key,
      ].includes(item.status);
      return routes.REVIEW.build(
        {},
        {
          reviewId: item.target.targetId,
          isBackPath: true,
          query: {
            ...(isActive && { isDigitalSignModal: true }), // open modal on report tab
          },
        },
      );
    }
    case TASKS_TYPES.DIGITAL_SIGN_COACH.key: {
      const isActive = [
        TASKS_ACTIONS.START.key,
        TASKS_ACTIONS.CONTINUE.key,
        TASKS_ACTIONS.UPDATE.key,
      ].includes(item.status);
      return routes.REVIEW.build(
        {},
        {
          reviewId: item.target.targetId,
          isBackPath: true,
          query: {
            ...(isActive && { isDigitalSignModal: true }), // open modal on report tab
          },
        },
      );
    }
    case TASKS_TYPES.GOAL_TASK.key: {
      return routes.GOAL_TASK.build(
        { role: ROLES.USER },
        {
          goalTaskId: item.target.targetId,
          isBackPath: true,
        },
      );
    }
    case TASKS_TYPES.ASK_FEEDBACK.key:
    case TASKS_TYPES.GIVE_FEEDBACK.key:
      return item.status === TASKS_ACTIONS.START.key
        ? routes.RTFEEDBACKS.build(
            { role: ROLES.USER },
            { query: { type: item.type, isAskRTFeedbackModal: true }, isBackPath: true },
          )
        : routes.RTFEEDBACKS.build({}, { isBackPath: true });
    case TASKS_TYPES.ANSWER_SURVEY_TRADITIONAL.key:
    case TASKS_TYPES.ANSWER_SURVEY_PULSE.key:
      return routes.SURVEY_TASK.build(
        { role: ROLES.USER },
        {
          surveyTaskId: item.target.taskId,
          isBackPath: true,
        },
      );
    default:
      return;
  }
};
