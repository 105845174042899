import React from 'react';

import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import Switch from '~/components/Switch';

import { COLOR_PALETTE } from '~/styles';

const RequestBox = styled.div`
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px ${COLOR_PALETTE.GRAY_SOFT};
  padding: 0 16px;
`;

const RoleName = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const ActionColumns = styled.div`
  flex: 0 0 24px;
`;

const RoleItem = ({ name, onToggle, value, isDisabled } = {}) => {
  return (
    <RequestBox key={name}>
      <RoleName>{name}</RoleName>
      <ActionColumns>
        {onToggle && <Switch onChange={onToggle} checked={value} disabled={isDisabled} />}
      </ActionColumns>
    </RequestBox>
  );
};

RoleItem.propTypes = {
  onToggle: PropTypes.func,
};

export default React.memo(RoleItem);
