import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  background: ${COLORS.WHITE};
  height: 64px;
  box-sizing: border-box;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  box-sizing: border-box;
  height: 100%;
  max-width: 100%;
  min-width: 0;
`;

export const BoxRight = styled(Box)`
  align-items: center;
  flex: none;
  justify-content: flex-end;
`;

export const BoxRightContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const NavBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 69px;
`;

export const Row = styled.div<{ $width?: string }>`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 64px;
  column-gap: 100px;
  width: 100%;
  max-width: ${(props) => props.$width || '100%'};
`;

export const Actions = styled.div`
  display: flex;
`;

export const StickContainer = styled.div<{ height?: string; $isSticky: boolean }>`
  z-index: 99;
  transition: 0.2s;
  height: ${({ height }) => height || '64px'};

  ${(props) =>
    props.$isSticky &&
    css`
      &:after {
        content: '';
        background-color: ${COLORS.WHITE};
        position: absolute;
        width: 100%;
        top: 0;
        height: 64px;
        z-index: -1;
      }
    `};
`;

export const TitleWrapper = styled.div`
  min-width: 0;
  display: flex;
  border-left: 1px solid ${COLORS.BORDERS};
  height: 100%;
  align-items: center;
`;

export const Title = styled.span`
  display: flex;
  color: ${COLORS.SUBTEXT};
  padding: 0 10px;
  letter-spacing: -0.18px;
  border-radius: 6px;
  margin-left: 16px;
  min-width: 0;

  &:hover {
    margin-left: 15px;
    border: solid 1px ${COLORS.BORDERS};
    color: ${COLORS.TEXT_HOVER};
    background-color: ${COLORS.HOVER};
  }
`;
