// @ts-ignore
import React, { useEffect, useMemo, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { every } from 'lodash';

import CheckBox from '~/components/CheckBox';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import { IconOld } from '~/components/IconOld';
import PaginationBar from '~/components/PaginationBar';
import { IconPreview } from '~/pages/SurveyTemplateUpdate/design';

import {
  IconWrapper,
  BoxNameWrapper,
  IconNameWrapper,
  ItemRow,
  ItemTitle,
  Items,
  ItemsSubTitle,
  Rows,
  SelectAllBar,
  ShowItemButton,
  SubRow,
  SubItems,
} from './design';

import { COLORS } from '~/styles';

import type { IMultiLayerListProps, ListItem } from './types';

const MultiLayerList = ({
  toggles,
  counterLabel,
  selectedCounterLabel,
  selectedCounterParentLabel,
  columnName,
  data,
  selectedItems,
  setSelectedItems,
  selectedParentItems,
  setSelectedParentItems,
  paginationProps,
  subItemsFontSize,
}: IMultiLayerListProps) => {
  const { i18n } = useLingui();
  const [items, setItems]: [ListItem[], any] = useState<ListItem[]>(data);

  useEffect(() => {
    setItems(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  const hideItems = (itemId: string) => {
    setItems((items: ListItem[]) => {
      const index = items.findIndex((theme) => itemId === theme.id);
      items[index] = { ...items[index], showSubItems: !items[index].showSubItems };
      return [...items];
    });
  };

  const handleSelectItem = (parentId: string, itemId: string) => {
    const item = items.find(({ id }) => id === parentId);
    const subItemIds = item?.subItems?.map((i) => i.id);
    const isItemSelected = selectedItems.includes(itemId);

    if (isItemSelected) {
      // Remove all theme itemIds from selected items
      setSelectedItems((currentItems: string[]) => currentItems.filter((q) => q !== itemId));
      // // Remove parent id from selected parent items
      setSelectedParentItems?.((currentParentItems: string[]) =>
        currentParentItems.filter((id) => parentId !== id),
      );
    } else {
      const newSelectedItems = [...selectedItems, itemId];
      // Add all theme itemIds to selected items
      setSelectedItems(newSelectedItems);

      // add parent id to selected parent items if all sub items are selected
      const allSubItemsSelected = every(subItemIds, (itemId) => newSelectedItems.includes(itemId));
      if (allSubItemsSelected) {
        setSelectedParentItems?.((currentParentItems: string[]) => [
          ...currentParentItems,
          parentId,
        ]);
      }
    }
  };

  const handleSelectTheme = (parentId: string, itemIds: string[]) => {
    const allSelected = every(itemIds, (itemId) => selectedItems.includes(itemId));
    const isParentSelected = selectedParentItems?.includes(parentId);

    if ((allSelected && !!itemIds.length) || isParentSelected) {
      // Remove all theme itemIds from selected items
      setSelectedItems((currentItems: string[]) =>
        currentItems.filter((q) => !itemIds.includes(q)),
      );
      // Remove parent id from selected parent items
      setSelectedParentItems?.((currentParentItems: string[]) =>
        currentParentItems.filter((id) => parentId !== id),
      );
    } else {
      // Add all theme itemIds to selected items
      setSelectedItems((currentItems: string[]) => [...currentItems, ...itemIds]);
      // Add parent id to selected parent items
      setSelectedParentItems?.((currentParentItems: string[]) => [...currentParentItems, parentId]);
    }
  };

  const { itemIds, parentIds } = useMemo(() => {
    const ids: string[] = [];
    const parentIds: string[] = [];

    items.forEach((item) => {
      parentIds.push(item.id);
      item?.subItems?.forEach((q) => {
        ids.push(q.id);
      });
    });

    return { itemIds: ids, parentIds };
  }, [items]);

  const allItemsAreSelected = selectedItems.length
    ? every(itemIds, (itemId) => selectedItems.includes(itemId))
    : true;

  const allParentItemsAreSelected = selectedParentItems?.length
    ? every(parentIds, (itemId) => selectedParentItems?.includes(itemId))
    : true;

  const itemsOrParentItemsAreExist = !!selectedItems.length || !!selectedParentItems?.length;

  const selectEverything = () => {
    const allSelected = every(itemIds, (itemId) => selectedItems.includes(itemId));

    if (allSelected && (!!selectedItems.length || !!selectedParentItems?.length)) {
      setSelectedItems([]);
      setSelectedParentItems?.([]);
    } else {
      setSelectedItems(itemIds);
      setSelectedParentItems?.(parentIds);
    }
  };

  const hasItems = useMemo(() => {
    return !!selectedItems.length || !!selectedParentItems?.length;
  }, [selectedItems.length, selectedParentItems?.length]);

  return (
    <>
      <SelectAllBar hasItems={hasItems}>
        {/* @ts-ignore */}
        <CheckBox
          onChange={selectEverything}
          checked={allItemsAreSelected && allParentItemsAreSelected && itemsOrParentItemsAreExist}
          size={29}
          defaultColor={hasItems ? COLORS.WHITE : COLORS.COMPANY}
          isDefaultColor
        />
        {selectedItems.length === 0 &&
          (selectedParentItems?.length === 0 || !selectedParentItems) &&
          columnName}

        {selectedItems.length
          ? `${selectedCounterLabel}: ${selectedItems.length}`
          : selectedParentItems?.length
          ? `${selectedCounterParentLabel}: ${selectedParentItems.length}`
          : ''}
      </SelectAllBar>
      <Items loading={!items?.length}>
        {items &&
          items.map((item) => {
            const icon = item.icon;
            const colors = (item.iconColor ?? '#f7f9ff-#ebf1fe').split('-');
            const allSubItemsChecked =
              !!item.subItems?.length && every(item.subItems, (q) => selectedItems.includes(q.id));

            const isParentItemChecked =
              !!selectedParentItems?.length && selectedParentItems.includes(item.id);

            const isChecked = allSubItemsChecked || isParentItemChecked;

            return (
              <Rows key={item.id}>
                <ItemRow isChecked={isChecked}>
                  <BoxNameWrapper>
                    {/* @ts-ignore */}
                    <CheckBox
                      size={29}
                      checked={isChecked}
                      className="checkbox"
                      onChange={() => {
                        handleSelectTheme(item.id, item?.subItems?.map((i) => i.id) || []);
                      }}
                      defaultColor={COLORS.COMPANY}
                    />
                    <IconNameWrapper>
                      {icon && (
                        <IconPreview colors={colors}>
                          {/* @ts-ignore */}
                          <IconOld name={icon} width={23} height={23} />
                        </IconPreview>
                      )}
                      <ItemTitle>
                        {/* @ts-ignore */}
                        <span>{item.name}</span>
                        <ItemsSubTitle>
                          {i18n._(t`Includes ${item?.subItems?.length || 0} ${counterLabel}`)}
                        </ItemsSubTitle>
                      </ItemTitle>
                    </IconNameWrapper>
                  </BoxNameWrapper>
                  <ShowItemButton onClick={() => hideItems(item.id)} showItems={item.showSubItems}>
                    {item.showSubItems ? toggles.hideItems : toggles.showItems}
                    <Icon
                      icon={ICONS.SORT_DOWN}
                      width={11}
                      height={6}
                      color={COLORS.ICONS_PRIMARY}
                    />
                  </ShowItemButton>
                </ItemRow>
                {item.showSubItems && (
                  <SubItems>
                    {item?.subItems?.map((i) => {
                      const checked = selectedItems.includes(i.id);
                      return (
                        <SubRow selected={checked} key={i.id}>
                          <BoxNameWrapper>
                            {/* @ts-ignore */}
                            <CheckBox
                              size={29}
                              checked={checked}
                              className="checkbox"
                              onChange={() => {
                                handleSelectItem(item.id, i.id);
                              }}
                              defaultColor={COLORS.COMPANY}
                            />
                            <ItemTitle fontSize={subItemsFontSize}>{i.name}</ItemTitle>
                          </BoxNameWrapper>

                          {i.icon && (
                            <IconWrapper>
                              <Icon icon={i.icon} size={ICON_SIZES.LARGE} />
                            </IconWrapper>
                          )}
                        </SubRow>
                      );
                    })}
                  </SubItems>
                )}
              </Rows>
            );
          })}
      </Items>
      {paginationProps?.pagination && (
        <PaginationBar
          pagination={paginationProps?.pagination}
          changePagination={paginationProps?.changePagination}
          changePageSize={paginationProps?.changePageSize}
          count={paginationProps?.totalCount}
          noShadow
          noBorder
          noTopBorder
          showCount
        />
      )}
    </>
  );
};

export { MultiLayerList };
