import React, { Component } from 'react';

import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Footer from '~/components/Footer';

class GuestLayout extends Component {
  render() {
    return (
      <div>
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app: state.app,
  };
};

export default withI18n()(connect(mapStateToProps)(withRouter(GuestLayout)));
