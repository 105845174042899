import React, { useState } from 'react';

import {
  SYMBOLS,
  GOAL_TYPES,
  VIRTUAL_GOAL_TYPES,
  CONFIRMATION_MODAL_TYPE,
  GOAL_STATUSES_NEW,
  GOAL_PROGRESS_TYPES,
} from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import get from 'lodash/get';
import round from 'lodash/round';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import AddTalkingPointInConversationModal from '~/components/AddTalkingPointInConversationModal';
import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import GoalProgressModal from '~/components/GoalDashboardCardOld/components/GoalProgressModal';
import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { SelectUsersModal } from '~/components/Modals/SelectUsersModal';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { Avatar } from '~/components/UserAvatar/components/Avatar';
import { UserAvatarGroup } from '~/components/UserAvatar/UserAvatarGroup';

import { GoalRow } from './GoalRow';
import { StatusLabel } from './StatusLabel';

import { GOAL_STATUSES } from '~/constants';
import { GOALS_STYLES } from '~/constants/goals';
import routes from '~/constants/routes';
import { getUser, getTeams } from '~/selectors/baseGetters';
import { copyGoal, duplicateGoal } from '~/services/goals';
import * as goalsServices from '~/services/goals';
import { COLORS } from '~/styles';
import getGoalPageURL from '~/utils/getGoalPageURL';
import { getGoalProgressWithDecimals } from '~/utils/getGoalProgressWithDecimals';
import { isUserCanEditGoal } from '~/utils/isUserCanEditGoal';

import {
  AvatarGroupWrapper,
  Column,
  Content,
  Progress,
  AvatarCtr,
  ColumnProgress,
  ColumnGrow,
} from '../design';

import type { IGoal } from '@learned/types';

interface IProps {
  item: IGoal;
  isShowCalcProgress: boolean;
  refreshGoals: () => void;
  isIndividualTab?: boolean;
  onGoalClick: (id: string) => void;
  handleCollapse?: () => void;
  isCollapsed?: boolean;
  menuItems?: string[];
  level?: number;
  isDashboardItem?: boolean;
  isHideMenuItems?: boolean;
}

const MENU_ITEMS = {
  TALKING_POINT: 'talkingPoint',
  COPY: 'copy',
  DUPLICATE: 'duplicate',
};

enum WARNING_MODAL_TYPES {
  DELETE = 'delete',
  COPY = 'copy',
}

const GoalItem = ({
  item,
  isShowCalcProgress,
  refreshGoals,
  onGoalClick,
  menuItems: menuItemsEnabled = [],
  isCollapsed,
  handleCollapse,
  isDashboardItem = false,
  isHideMenuItems = false,
}: IProps) => {
  const [isShowCopyModal, setIsShowCopyModal] = useState(false);
  const [isShowAddTalkingPointModal, setIsShowAddTalkingPointModal] = useState(false);
  const [isShowWarningModal, setIsShowWarningModal] = useState(false);
  const history = useHistory();
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const currentUser = useSelector(getUser);
  const teams = useSelector(getTeams);
  const [updateProgressModal, setUpdateProgressModal] = useState(false);
  const [isSetAsEditor, setIsSetAsEditor] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>();
  const [warningType, setWarningType] = useState<WARNING_MODAL_TYPES>();

  // coach, without admin role
  const isOnlyCoach = currentUser.isCoach && !currentUser.isAdmin;

  // coach or admin role
  const isCoachOrAdmin = currentUser.isCoach || currentUser.isAdmin;

  // @ts-ignore
  const goalStyles = GOALS_STYLES[item.type || GOAL_TYPES.PERSONAL];
  const currency = get(item, 'progressDetails.currency');
  // @ts-ignore
  const symbol = isShowCalcProgress ? '%' : SYMBOLS[currency] || '';

  const onItemClick = (item: IGoal) => {
    onGoalClick ? onGoalClick(item.id) : history.push(getGoalPageURL(item));
  };

  const openCopyModal = () => setIsShowCopyModal(true);
  const openAddGoalAsTalkingPointModal = () => setIsShowAddTalkingPointModal(true);

  const isGoalOwner = item.owners?.includes(currentUser.id);
  const isDraft = item.status === GOAL_STATUSES.DRAFT.key;

  const isEditor = isUserCanEditGoal({ goal: item, user: currentUser, teams });
  const isIndividualGoal = [GOAL_TYPES.PERSONAL, GOAL_TYPES.BUSINESS].includes(
    item.type as GOAL_TYPES,
  );

  const onUpdateProgress = async (data = {}) => {
    try {
      await goalsServices.updateGoalProgress(item.id, data);
      await refreshGoals();
      setUpdateProgressModal(false);
    } catch (e) {
      // do nothing
    }
  };

  const duplicateActiion = async () => {
    await duplicateGoal(item.id);
    await refreshGoals();
    addToast({
      title: i18n._(t`Goal duplicated`),
      subtitle: i18n._(t`You can find the goal copy in the goal dashboard.`),
      type: TOAST_TYPES.SUCCESS,
    });
  };

  const editGoal = () => {
    history.push(
      routes.GOAL_UPDATE.build(
        // @ts-ignore
        {},
        {
          goalId: item.id,
          isBackPath: true,
        },
      ),
    );
  };

  const menuItems = [
    // @ts-ignore
    isEditor && item.status !== GOAL_STATUSES_NEW.ARCHIVED && (
      <ContextOption action={editGoal} key="edit" icon={ICONS.EDIT_PENCIL}>
        <Trans>Edit</Trans>
      </ContextOption>
    ),
    isGoalOwner && isIndividualGoal && !isDraft && isEditor && (
      <ContextOption action={duplicateActiion} key="duplicate" icon={ICONS.DUPLICATE}>
        <Trans>Duplicate</Trans>
      </ContextOption>
    ),

    isIndividualGoal && isCoachOrAdmin && !isDraft && isEditor && (
      <ContextOption action={openCopyModal} key="copy" icon={ICONS.USERS}>
        <Trans>Copy and assign</Trans>
      </ContextOption>
    ),

    // add goal as talking point in 1-1
    menuItemsEnabled.includes(GoalItem.menuItems.TALKING_POINT) &&
      !isDraft &&
      isIndividualGoal &&
      isGoalOwner && (
        <ContextOption action={openAddGoalAsTalkingPointModal} key="talking">
          <Trans>Add goal as Talking point to 1-1</Trans>
        </ContextOption>
      ),
    isEditor && (
      <ContextOption
        action={() => {
          setWarningType(WARNING_MODAL_TYPES.DELETE);
          setIsShowWarningModal(true);
        }}
        key="delete"
        icon={ICONS.DELETE_BIN}
        isWarning
      >
        <Trans>Delete</Trans>
      </ContextOption>
    ),
  ].filter(Boolean);

  const onCopySubmit = async (selectedUsers: string[]) => {
    // Request the creation of a goal per user
    if (!isEmpty(selectedUsers)) {
      await copyGoal(item.id, selectedUsers, isSetAsEditor);
      // @ts-ignore
      await refreshGoals({});

      addToast({
        title: i18n._(t`Goal assigned`),
        subtitle: i18n._(t`You can find the goal copies in the goal dashboard.`),
        type: TOAST_TYPES.SUCCESS,
      });
    }

    setIsShowCopyModal(false);
  };

  const handleCopyAction = async (selectedUsers: string[]) => {
    if (isEmpty(item.skills)) {
      await onCopySubmit(selectedUsers);
      return;
    }
    setIsShowWarningModal(true);
    setWarningType(WARNING_MODAL_TYPES.COPY);
    setSelectedUsers(selectedUsers);
  };

  const handleOnConfirmCopy = async () => {
    setIsShowCopyModal(false);
    await onCopySubmit(selectedUsers || []);
    setIsShowWarningModal(false);
    setSelectedUsers([]);
  };

  const handleDeleteAction = async () => {
    await goalsServices.deleteGoal(item.id);
    await refreshGoals();
    setIsShowWarningModal(false);
    addToast({
      title: i18n._(t`Goal deleted`),
      type: TOAST_TYPES.INFO,
    });
  };

  const progressValue = isShowCalcProgress ? item.calculatedProgress : item.progress;

  return (
    <GoalRow
      name={item.name}
      rowIcon={goalStyles?.icon}
      iconColor={goalStyles?.iconColor}
      handleCollapse={handleCollapse}
      onClick={() => onItemClick(item)}
      isCollapsed={isCollapsed}
      item={item}
      isDashboardItem={isDashboardItem}
    >
      <Content>
        <AvatarCtr isDashboardItem={isDashboardItem}>
          <Column>
            <AvatarGroupWrapper>
              {item.type === VIRTUAL_GOAL_TYPES.HIDDEN ? (
                <Avatar isAnonymous />
              ) : (
                <UserAvatarGroup
                  users={item.owners?.map((item: string) => ({ userId: item })) || []}
                />
              )}
            </AvatarGroupWrapper>
          </Column>
        </AvatarCtr>

        <ColumnProgress isDashboardItem={isDashboardItem}>
          <Progress>{`${getGoalProgressWithDecimals(
            String(progressValue),
            GOAL_PROGRESS_TYPES.AVG,
          )}${symbol}`}</Progress>
        </ColumnProgress>

        <ColumnGrow isDashboardItem={isDashboardItem}>
          <StatusLabel
            // @ts-ignore
            status={item.status}
            progress={isShowCalcProgress ? round(item.calculatedProgress!, 2) : item.progress}
          />
        </ColumnGrow>
        {item.type !== VIRTUAL_GOAL_TYPES.HIDDEN && !isDashboardItem && (
          <Column>
            <div className="goal-row-context-menu">
              {menuItems.length > 0 && !isHideMenuItems && (
                <ContextMenu menuHeight={300}>{menuItems.map((item) => item)}</ContextMenu>
              )}
            </div>
          </Column>
        )}
      </Content>
      {updateProgressModal && (
        <GoalProgressModal
          item={item}
          onClose={() => setUpdateProgressModal(false)}
          onSubmit={onUpdateProgress}
        />
      )}
      {isShowCopyModal && (
        <SelectUsersModal
          onSave={handleCopyAction}
          title={i18n._(t`Copy and assign goal`)}
          onSaveLabelFactory={() => i18n._(t`Assign`)}
          onClose={() => setIsShowCopyModal(false)}
          isOnlyCoachTeamMembers={isOnlyCoach}
          usersToHide={item.owners}
          isCheckboxVisible
          checkboxProps={{
            label: i18n._(t`Make selected member(s) editor of the goal`),
            onChange: (): void => {
              setIsSetAsEditor(!isSetAsEditor);
            },
            checked: isSetAsEditor,
          }}
          isHideSkillsFilter
        />
      )}
      {isShowAddTalkingPointModal && (
        <AddTalkingPointInConversationModal
          targetId={item.id}
          targetName={item.name}
          // @ts-ignore
          targetUser={item.owners[0]} // individual goals have only one owner
          onClose={() => setIsShowAddTalkingPointModal(false)}
        />
      )}
      {isShowWarningModal && (
        <ConfirmationModal
          type={
            warningType === WARNING_MODAL_TYPES.COPY
              ? CONFIRMATION_MODAL_TYPE.WARNING
              : CONFIRMATION_MODAL_TYPE.DELETE
          }
          description={
            warningType === WARNING_MODAL_TYPES.COPY
              ? i18n._(t`Are you sure you want to change the goal owner?`)
              : i18n._(t`Are you sure you want to delete this goal?`)
          }
          onClose={() => {
            setIsShowWarningModal(false);
          }}
          onSubmit={
            warningType === WARNING_MODAL_TYPES.COPY ? handleOnConfirmCopy : handleDeleteAction
          }
          cancelButtonTextColor={COLORS.TEXT_MAIN}
        />
      )}
    </GoalRow>
  );
};

GoalItem.menuItems = MENU_ITEMS;

export default GoalItem;
