import React from 'react';

import styled from 'styled-components';

import { Icon, ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{ color: string | undefined; isVisible?: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'inline-flex' : 'none')};
  align-items: center;
  cursor: pointer;

  &:hover {
    display: inline-flex;
  }

  svg {
    min-width: 20px;
    min-height: 20px;
    stroke: ${(props) => (props.color ? props.color : COLORS.GRAY_MIDDLE)};
  }
`;

interface RadioButtonProps {
  isSelected?: boolean;
  onChange?: () => void;
  color?: string;
  isHovered?: boolean;
}

export const RadioButton = ({
  onChange,
  isSelected = false,
  color,
  isHovered = false,
}: RadioButtonProps) => {
  return (
    <Wrapper onClick={onChange} color={color} isVisible={isSelected || isHovered}>
      <Icon
        icon={isSelected ? ICONS.RADIO_BUTTON_SELECTED : ICONS.RADIO_BUTTON}
        stroke={COLORS.BORDERS}
        fill={COLORS.COMPANY}
      />
    </Wrapper>
  );
};
