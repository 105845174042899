import React from 'react';

import { ROLES } from '@learned/constants';
import { useLingui } from '@lingui/react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import SecondaryMenuItem from './SecondaryMenuItem';

import routes from '~/constants/routes';
import * as customReportActions from '~/store/customReports/actions';

import {
  SecondaryMenuWrapper,
  SecondaryMenuTitle,
  SecondaryMenuSubTitle,
  MenuDivider,
} from '../styles/design';

import type { IMenuItem } from '../types';

interface ISecondaryMenuProps {
  items: IMenuItem[];
  title: string;
  pathname: string;
  isAdmin: boolean;
  isMenuWithIcons?: boolean;
}

const ReportMenu = ({ items, title, pathname, isAdmin }: ISecondaryMenuProps) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();

  const deleteCustomReport = async (id: string, isActiveRoute: boolean) => {
    dispatch(customReportActions.deleteCustomReport(id.split('|')[1]));
    if (isActiveRoute) {
      routes.REPORTS_ADMIN_ENGAGEMENT.go(
        // @ts-ignore
        { role: ROLES.ADMIN },
      );
    }
  };

  const duplicateCustomReport = async (id: string) => {
    dispatch(customReportActions.duplicateCustomReport(id.split('|')[1]));
  };

  // TODO
  const sortCustomReport = async (id: any) => {
    // eslint-disable-next-line
    console.log(id);
  };

  const isDefaultReport = (key: string) => {
    return key && key.split('|')[0] === 'default';
  };

  return (
    <SecondaryMenuWrapper>
      <SecondaryMenuTitle>{title}</SecondaryMenuTitle>
      <div>
        {items.map((menuItem) => {
          return (
            <div key={menuItem.key}>
              {menuItem.title && (
                <>
                  <SecondaryMenuItem menuItem={menuItem} pathname={pathname} />
                  <MenuDivider />
                </>
              )}
              {
                // eslint-disable-next-line
                // @ts-ignore
                menuItem.children?.map((subMenuGroup: IMenuItem) => {
                  return (
                    <div key={subMenuGroup.key}>
                      <SecondaryMenuSubTitle>{subMenuGroup.title?.(i18n)}</SecondaryMenuSubTitle>
                      {isAdmin && subMenuGroup.key === 'reports_custom' ? (
                        <>
                          <DragDropContext
                            onDragEnd={(e) => {
                              sortCustomReport(e);
                            }}
                          >
                            <Droppable droppableId="TP-droppable">
                              {(provided) => {
                                return (
                                  <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {subMenuGroup.children?.map((subMenuItem, index) => {
                                      return (
                                        <Draggable // eslint-disable-next-line
                                          isDragDisabled={true}
                                          // @ts-ignore
                                          key={subMenuItem.key as string} // eslint-disable-next-line
                                          // @ts-ignore
                                          draggableId={subMenuItem.key as string}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                            >
                                              <SecondaryMenuItem
                                                menuItem={subMenuItem as IMenuItem}
                                                showContextMenu
                                                pathname={pathname}
                                                onDeleteItem={
                                                  isDefaultReport((subMenuItem as IMenuItem).key)
                                                    ? undefined
                                                    : deleteCustomReport
                                                }
                                                onDuplicateItem={duplicateCustomReport}
                                                draggableProvided={provided}
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                    {provided.placeholder}
                                  </div>
                                );
                              }}
                            </Droppable>
                          </DragDropContext>
                        </>
                      ) : (
                        <>
                          {subMenuGroup.children?.map((subMenuItem, idx) => {
                            return (
                              <div key={idx}>
                                <SecondaryMenuItem
                                  menuItem={subMenuItem as IMenuItem}
                                  pathname={pathname}
                                />
                                {menuItem.isDivider && <MenuDivider />}
                              </div>
                            );
                          })}
                        </>
                      )}
                      <MenuDivider />
                    </div>
                  );
                })
              }
            </div>
          );
        })}
      </div>
    </SecondaryMenuWrapper>
  );
};

export default ReportMenu;
