import React from 'react';

import { ACTIVITY_STATUSES } from '@learned/constants';
import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import IconMenu from '~/components/IconMenu';
import ToolTip from '~/components/Tooltip';
import { UserAvatarGroup } from '~/components/UserAvatar/UserAvatarGroup';

import { ACTIVITY_STATUS_STYLES } from '~/constants/activities';
import { COLORS } from '~/styles';

import {
  ActivityColumn,
  ActivityIconWrapper,
  ActivityInfo,
  ActivityName,
  ActivityProgress,
  ActivityRow,
  ActivityTippyWrapper,
  AvatarGroupWrapper,
  LeftActivityColumn,
  RightColumnCtr,
  AvatarCtr,
  ColumnProgressActivity,
  ColumnGrowActivity,
} from '../../design';
import { StatusLabel } from '../StatusLabel';

import type { IActivity } from '@learned/types';

interface IProps {
  activity: IActivity;
  isStatusSection: boolean;
  isMenuSection: boolean;
  onClick: () => void;
  onEdit: (activity: IActivity) => void;
  onRemove: (activity: IActivity) => void;
}

const GoalActivityItem = ({
  activity,
  onEdit,
  onRemove,
  onClick,
  isStatusSection,
  isMenuSection,
}: IProps) => {
  const menu = [];

  if (!isEmpty(activity) && activity.root !== 'library') {
    menu.push({
      action: () => onEdit(activity),
      label: <Trans>Update</Trans>,
    });
  }

  menu.push({
    action: () => onRemove(activity),
    label: <Trans>Delete</Trans>,
  });

  const activityStatusStyles = ACTIVITY_STATUS_STYLES[activity.status];

  return (
    <ActivityRow
      $isHover={Boolean(onClick)}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick();
      }}
    >
      <LeftActivityColumn>
        <ActivityIconWrapper>
          <Icon icon={ICONS.SURVEYS} size={ICON_SIZES.MEDIUM} color={COLORS.ENGAGEMENT} />
        </ActivityIconWrapper>
        <ActivityInfo>
          <ActivityName>
            <ActivityTippyWrapper>
              <ToolTip tooltip={activity.name}>
                <span>{activity.name}</span>
              </ToolTip>
            </ActivityTippyWrapper>
          </ActivityName>
        </ActivityInfo>
      </LeftActivityColumn>
      <RightColumnCtr>
        <AvatarCtr>
          <ActivityColumn>
            <AvatarGroupWrapper>
              {/* @ts-ignore */}
              <UserAvatarGroup
                users={activity.owners?.map((item: string) => ({ userId: item })) || []}
              />
            </AvatarGroupWrapper>
          </ActivityColumn>
        </AvatarCtr>

        <ColumnProgressActivity>
          <ActivityProgress>{`${round(activity.calculatedProgress || 0, 2)}%`}</ActivityProgress>
        </ColumnProgressActivity>

        <ColumnGrowActivity>
          {isStatusSection && (
            <StatusLabel
              // @ts-ignore
              status={activityStatusStyles.key as ACTIVITY_STATUSES}
              progress={activity.calculatedProgress}
            />
          )}
        </ColumnGrowActivity>
        <ActivityColumn>{isMenuSection && <IconMenu items={menu} />}</ActivityColumn>
      </RightColumnCtr>
    </ActivityRow>
  );
};

GoalActivityItem.defaultProps = {
  isStatusSection: false,
  isMenuSection: false,
  onClick: () => {},
  onEdit: () => {},
  onRemove: () => {},
};

export default React.memo(GoalActivityItem);
