import { REPORT_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';

import { ICONS } from '~/components/Icon';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { getCoachTeamsForUser, isUserWithCoachRole } from '../utils';

import type { IMenuItem } from '../types';
import type { UserWithConnections } from '../utils';
import type { ITeam, IUser, IReportCustomReport } from '@learned/types';
import type { I18n } from '@lingui/core';

const getBasicReportMenuItems = (isAdmin: boolean, isModuleLearningEnabled: boolean) => {
  if (!isAdmin) {
    return false as const;
  }

  const menu = {
    title: (i18n: I18n) => i18n._(t`Basic Reports`),
    key: 'reports_basic',
    children: [
      {
        title: (i18n: I18n) => i18n._(t`Skills I`),
        // @ts-ignore
        url: routes.SKILLS_REPORT.build({ role: ROLES.ADMIN }),
        role: ROLES.ADMIN,
      },
      {
        title: (i18n: I18n) => i18n._(t`Performance`),
        // @ts-ignore
        url: routes.REPORT_PERFORMANCE.build({ role: ROLES.ADMIN }),
        role: ROLES.ADMIN,
      },
      isModuleLearningEnabled && {
        title: (i18n: I18n) => i18n._(t`Learning report`),
        // @ts-ignore
        url: routes.LEARNINGS_REPORT.build({ role: ROLES.ADMIN }),
        role: ROLES.ADMIN,
      },
      {
        title: (i18n: I18n) => i18n._(t`Employee report`),
        // @ts-ignore
        url: routes.MEMBERS_REPORT.build({ role: ROLES.ADMIN }),
        role: ROLES.ADMIN,
      },
      {
        title: (i18n: I18n) => i18n._(t`9-grid`),
        // @ts-ignore
        url: routes.NINE_GRID_REPORT.build({ role: ROLES.ADMIN }),
        role: ROLES.ADMIN,
      },
      {
        title: (i18n: I18n) => i18n._(t`1:1's`),
        // @ts-ignore
        url: routes.CONVERSATION_REPORT.build({ role: ROLES.ADMIN }),
        role: ROLES.ADMIN,
      },
    ].filter((c) => c),
  };

  if (menu.children.length) {
    return menu;
  }

  return false as const;
};

const getPersonalReportMenuItem = (isEngagementModuleEnabled: boolean) => {
  const menu = {
    title: (i18n: I18n) => i18n._(t`Personal Reports`),
    key: 'reports_personal',
    children: [
      isEngagementModuleEnabled && {
        title: (i18n: I18n) => i18n._(t`Your engagement`),
        // @ts-ignore
        url: routes.REPORTS_USER_ENGAGEMENT.build({ role: ROLES.USER }),
        role: ROLES.USER,
      },
    ].filter((c) => c),
  };

  if (menu.children.length) {
    return menu;
  }

  return false as const;
};

const getCustomReportMenuItemUrl = (customReport: IReportCustomReport) => {
  if (customReport.isDefault) {
    if (customReport.reportType === REPORT_TYPES.ENGAGEMENT) {
      return routes.REPORTS_ADMIN_ENGAGEMENT.build(
        // @ts-ignore
        { role: ROLES.ADMIN },
      );
    }
  } else {
    return routes.REPORTS_ADMIN_CUSTOM_REPORT.build(
      // @ts-ignore
      { role: ROLES.ADMIN },
      { reportId: customReport.id },
    );
  }
};

const getAdminCustomReportMenuItem = (
  isAdmin: boolean,
  languageState: ILanguageStateReturn,
  customReports: IReportCustomReport[],
) => {
  if (!isAdmin || !customReports.length) {
    return false as const;
  }

  const menu = {
    title: (i18n: I18n) => i18n._(t`Reports`),
    key: 'reports_custom',
    children: [
      ...customReports.map((report) => ({
        title: (_i18n: I18n) => report.name[languageState.companyPrimaryLanguage.locale],
        url: getCustomReportMenuItemUrl(report),
        key: `${report.isDefault ? 'default' : 'custom'}|${report.id}`,
        role: ROLES.ADMIN,
      })),
    ].filter((c) => c),
  };

  if (menu.children.length) {
    return menu;
  }

  return false as const;
};

const getCoachCustomReportMenuItem = (isAdmin: boolean, isCoach: boolean) => {
  if (!isAdmin && isCoach) {
    return {
      title: (i18n: I18n) => i18n._(t`Reports`),
      key: 'reports_custom',
      children: [
        {
          title: (i18n: I18n) => i18n._(t`Engagement`),
          url: routes.REPORTS_COACH_TEAMS_ENGAGEMENT.build(
            // @ts-ignore
            { role: ROLES.USER },
          ),
          key: 'default|engagement_report',
          role: ROLES.USER,
        },
      ],
    };
  }

  return false as const;
};

const getCoachTeamMenuItem = (
  user: UserWithConnections,
  teams: Record<string, ITeam>,
  selectedCompany: string,
  isEngagementModuleEnabled: boolean,
) => {
  const storageItems = localStorage.getItem('OpenMenuItems');
  const previousOpenTeams = JSON.parse(storageItems as string) || [];
  const teamsForUser = getCoachTeamsForUser(user, teams, selectedCompany);
  const sortedTeams = teamsForUser
    .map((team) => ({ team: team.name, members: team.members, id: team.id }))
    .sort((a, b) => {
      const nameA = a.team.toLowerCase();
      const nameB = b.team.toLowerCase();
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }

      return 0;
    });
  let selectedTeam = sortedTeams.find((team) => previousOpenTeams.indexOf(team.id) !== -1);
  selectedTeam = selectedTeam ? selectedTeam : sortedTeams.length ? sortedTeams[0] : undefined;

  if (!selectedTeam || !selectedTeam.members.length) {
    return {
      title: (i18n: I18n) => i18n._(t`Teams`),
      icon: ICONS.TEAM,
      key: 'coach-menu',
      children: teamsForUser
        .map((team) => {
          return {
            title: () => team.name,
            // @ts-ignore
            url: routes.TEAM_COACH.build({
              role: ROLES.COACH,
              teamId: team?.id,
              companyId: selectedCompany,
            }),
            aliases: [
              routes.TEAM_COACH.build({
                role: ROLES.COACH,
                teamId: team.id,
                companyId: selectedCompany,
              }),
            ],
            role: ROLES.COACH,
            team: team.id,
          };
        })
        .filter((c) => c),
    };
  } else {
    const menu = {
      children: teamsForUser
        .map((team) => {
          return {
            title: () => team.name,
            // @ts-ignore
            url: routes.TEAM_COACH.build(
              { role: ROLES.COACH, teamId: selectedTeam?.id, companyId: selectedCompany },
              ...(isEngagementModuleEnabled
                ? []
                : [{ query: { memberId: selectedTeam?.members[0] } }]),
            ),
            aliases: [
              routes.TEAM_COACH.build({
                role: ROLES.COACH,
                teamId: team.id,
                companyId: selectedCompany,
              }),
            ],
            role: ROLES.COACH,
            team: team.id,
          };
        })
        .filter((c) => c),
      title: (i18n: I18n) => i18n._(t`Teams`),
      icon: ICONS.TEAM,
      key: 'coach-menu',
    };

    return menu;
  }
};

const footerMenuStructure = ({
  user,
  teams,
  languageState,
  selectedCompany,
  isModuleLearningEnabled,
  isUserHasAdminRole,
  isModuleEngagementEnabled,
  isModuleJobMatrixEnabled,
  customReports,
}: {
  user: IUser;
  teams: Record<string, ITeam>;
  languageState: ILanguageStateReturn;
  selectedCompany: string;
  isModuleLearningEnabled: boolean;
  isUserHasAdminRole: boolean;
  isModuleEngagementEnabled: boolean;
  isModuleJobMatrixEnabled: boolean;
  customReports: IReportCustomReport[];
}): IMenuItem[] =>
  [
    (isUserHasAdminRole && isModuleJobMatrixEnabled
      ? {
          title: (i18n: I18n) => i18n._(t`Job matrix`),
          key: 'jobMatrix',
          icon: ICONS.JOB_MATRIX,
          children: [
            {
              title: (i18n: I18n) => i18n._(t`Job library`),
              // @ts-ignore
              url: routes.JOB_PROFILES_ADMIN.build({ role: ROLES.ADMIN }),
              role: ROLES.ADMIN,
              // @ts-ignore
              aliases: [routes.JOB_PROFILES_ADMIN.build({ role: ROLES.ADMIN })],
            },
            {
              title: (i18n: I18n) => i18n._(t`Skill matrix`),
              // @ts-ignore
              url: routes.SKILLS.build({ role: ROLES.ADMIN }),
              role: ROLES.ADMIN,
              // @ts-ignore
              aliases: [routes.SKILLS.build({ role: ROLES.ADMIN })],
            },
          ],
        }
      : null) as unknown as IMenuItem,

    // as of now: don't show report menu for coach and users when engagement module is not available
    ...(isUserHasAdminRole || isModuleEngagementEnabled
      ? [
          {
            title: (i18n: I18n) => i18n._(t`Reports`),
            key: 'reports',
            icon: ICONS.REPORTS,
            children: [
              {
                ...(isUserHasAdminRole
                  ? {
                      title: (i18n: I18n) => i18n._(t`Dashboard`),
                      // @ts-ignore
                      url: routes.REPORTS_ADMIN_DASHBOARD.build({ role: ROLES.ADMIN }), // This is the route of reports icon on main menu for admin
                      role: ROLES.ADMIN,
                      key: 'reports_admin_dashboard',
                    }
                  : isUserWithCoachRole(user, selectedCompany)
                  ? {
                      // @ts-ignore
                      url: routes.REPORTS_COACH_TEAMS_ENGAGEMENT.build({ role: ROLES.USER }), // This is the route of reports icon on main menu for coach
                      role: ROLES.USER,
                      key: 'reports_coach_teams_engagement',
                    }
                  : {
                      // @ts-ignore
                      url: routes.REPORTS_USER_ENGAGEMENT.build({ role: ROLES.USER }), // This is the route of reports icon on main menu for user
                      role: ROLES.USER,
                      key: 'reports_user_engagement',
                    }),
                children: [
                  getAdminCustomReportMenuItem(isUserHasAdminRole, languageState, customReports),
                  getCoachCustomReportMenuItem(
                    isUserHasAdminRole,
                    isUserWithCoachRole(user, selectedCompany),
                  ),
                  getPersonalReportMenuItem(isModuleEngagementEnabled),
                  getBasicReportMenuItems(isUserHasAdminRole, isModuleLearningEnabled),
                ].filter((c) => c),
              },
            ].filter((c) => c),
            isDivider: true,
          },
        ]
      : []),
    ...(getCoachTeamsForUser(user, teams, selectedCompany).length > 0
      ? [
          {
            ...getCoachTeamMenuItem(user, teams, selectedCompany, isModuleEngagementEnabled),
          },
        ]
      : []
    ).filter((c) => c.children.length),
  ].filter((c) => c);

export default footerMenuStructure;
