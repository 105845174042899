import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import SettingBlock from '~/components/ProfileSettingsComponents/MainDashboard/SettingBlock';
import { TextField } from '~/components/Text';

import { BlockRow, RowName, RowDescription, RowAction } from '../design';

const CompanyData = ({
  companyName,
  handleChange,
  saveCompany,
}: {
  companyName: string;
  handleChange: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  saveCompany: (_e: React.FormEvent) => Promise<void>;
}) => {
  const { i18n } = useLingui();
  const fields = [
    {
      key: 'name',
      title: i18n._(t`Company name`),
      description: i18n._(t`Visible for employees`),
    },
  ];

  return (
    // @ts-ignore
    <SettingBlock title={i18n._(t`Company data`)} subTitle={i18n._(t`Set your company details.`)}>
      {fields.map(({ key, title, description }) => (
        <BlockRow key={key}>
          <RowName>{title}</RowName>
          <RowDescription>
            <Trans>{description}</Trans>
          </RowDescription>
          <RowAction>
            <TextField
              type="text"
              name={key}
              // @ts-ignore
              value={companyName}
              onChange={handleChange}
              onBlur={saveCompany}
            />
          </RowAction>
        </BlockRow>
      ))}
    </SettingBlock>
  );
};

export { CompanyData };
