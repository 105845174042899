import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { confirm } from '~/components/ConfirmDialog';
import SelectModal from '~/components/SelectModal';

import Step from './Step';
import StepEditor from './StepEditor';

import { CONVERSATION_TOAST_TYPES } from '~/constants/nextSteps';
import { getUser, getUsers } from '~/selectors/baseGetters';
import {
  copyToNextConversations,
  createStep,
  deleteStep,
  switchCompletedState,
} from '~/services/nextSteps';
import { COLOR_PALETTE } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

const NotesWrapper = styled.div`
  width: 100%;
  max-width: 412px;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  background-color: ${COLOR_PALETTE.WHITE};
  width: 100%;
  max-width: 412px;
  max-height: calc(100vh - 190px);
`;

const StepContainer = styled.div`
  overflow: ${(props) => (props.$numberOfChildren < 4 ? 'unset' : 'auto')};
  max-height: calc(100vh - 182px);
`;

const OldStepsDivider = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
`;

const OldStepsDividerText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: ${({ isInteractive }) =>
    isInteractive ? 'var(--company-color)' : COLOR_PALETTE.DARK_GRAY};
  ${({ isInteractive }) => isInteractive && 'margin-left: 4px;'}
  ${({ isInteractive }) => isInteractive && 'cursor: pointer;'}
`;

const NextSteps = ({
  targetId,
  type,
  currentSteps,
  isLocked,
  previousUncompletedSteps,
  fetchSteps,
  participants = [],
  repeatedConversations = [],
  conversationCreatedById,
  toast,
  viewOnly = false,
}) => {
  const user = useSelector(getUser);
  const allUsers = useSelector(getUsers);
  const { i18n } = useLingui();

  const [isShowOldSteps, setIsShowOldSteps] = useState(false);
  const [isShowNextConversationsModal, setIsShowNextConversationsModal] = useState(false);
  const [stepToCopy, setStepToCopy] = useState(null);
  const [futureConversations, setFutureConversations] = useState([]);

  useEffect(() => {
    fetchSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetId]);

  useEffect(() => {
    // Map and sort repeated conversations dates
    const events = repeatedConversations.map((conv) => ({
      id: conv.id,
      label: convertToTimeString(conv.startDate, TIME_FORMATS.DAY_FULL_MONTH_YEAR),
      startDate: conv.startDate,
    }));
    const sortedEvents = events.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

    // Leave only future conversations
    const upcomingEvents = [];
    let upcoming = false;
    sortedEvents.forEach((event) => {
      if (upcoming) {
        upcomingEvents.push(event);
      } else if (event.id === targetId) {
        upcoming = true;
      }
    });
    setFutureConversations(upcomingEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetId]);

  const onStepDelete = async (stepId) => {
    if (await confirm(i18n, i18n._(t`Are you sure you want to delete this step?`))) {
      // Delete selected step
      await deleteStep(stepId);

      // Fetch updated steps
      await fetchSteps();
    }
  };

  const onCopyToNextConversations = async (targetConversation) => {
    if (stepToCopy) {
      // Copy step to the selected conversation
      await copyToNextConversations({
        stepId: stepToCopy,
        nextConversationId: targetConversation[0].id,
      });

      // Fetch updated steps
      await fetchSteps();

      setIsShowNextConversationsModal(false);
      setStepToCopy(null);
      toast(CONVERSATION_TOAST_TYPES.STEP_COPY_TO_NEXT);
    }
  };

  const getMenu = (step) => {
    const options = [];

    // The option to copy to next conversations is only showed if there are next conversations
    if (futureConversations.length) {
      options.push({
        label: t`Copy to next event`,
        action: () => {
          setStepToCopy(step.id);
          setIsShowNextConversationsModal(true);
        },
      });
    }

    // Only the creator can delete the step
    if ([conversationCreatedById, step.createdBy].includes(user.id) && !isLocked) {
      options.push({ label: t`Delete`, action: () => onStepDelete(step.id) });
    }

    return options;
  };

  const onChangeCheckbox = async (stepId, checked) => {
    // Invert the value of this step checkbox
    await switchCompletedState({
      stepId,
      isCompleted: checked,
    });

    // Fetch updated steps
    await fetchSteps();
  };

  const renderSteps = (steps = []) => {
    return steps.map((step) => {
      const menu = getMenu ? getMenu(step) : [];
      const createdBy = allUsers[step.createdBy];

      return (
        <Step
          key={step.id}
          step={step}
          menu={menu}
          showMenu={menu.length > 0}
          createdBy={createdBy}
          onChangeCheckbox={onChangeCheckbox}
          isCanCheck={!viewOnly}
        />
      );
    });
  };

  const onAddStep = async (participant, comment) => {
    // Add new step for this participant
    await createStep({ targetId, type, comment, participant });

    // Fetch updated steps
    await fetchSteps();
  };

  return (
    <NotesWrapper>
      <Container>
        {!isLocked && <StepEditor onAddStep={onAddStep} participants={participants} />}
        <StepContainer $numberOfChildren={currentSteps.length + previousUncompletedSteps.length}>
          {renderSteps(currentSteps)}
          {previousUncompletedSteps.length > 0 && (
            <OldStepsDivider>
              <OldStepsDividerText>
                {previousUncompletedSteps.length}{' '}
                {i18n._(t`open next steps from previous conversations.`)}
              </OldStepsDividerText>
              <OldStepsDividerText onClick={() => setIsShowOldSteps(!isShowOldSteps)} isInteractive>
                {isShowOldSteps ? i18n._(t`Hide`) : i18n._(t`Show`)}
              </OldStepsDividerText>
            </OldStepsDivider>
          )}
          {isShowOldSteps && renderSteps(previousUncompletedSteps)}
          {isShowNextConversationsModal && (
            <SelectModal
              singleChoice
              onModalClose={() => setIsShowNextConversationsModal(false)}
              onSubmit={onCopyToNextConversations}
              title={i18n._(t`Copy to next 1:1`)}
              buttonLabel={i18n._(t`Save`)}
              emptySetPlaceholder={i18n._(t`There are no next events`)}
              items={futureConversations}
              width={325}
              minHeight={450}
            />
          )}
        </StepContainer>
      </Container>
    </NotesWrapper>
  );
};

export { NextSteps };
