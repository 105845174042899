import { ROLES } from '~/constants';
import { pageOverview, pageUpdate, pageDashboard } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'surveys';
const defaultSurveyId = ':surveyId';

// overview
export const SURVEYS_OVERVIEW = new LinkConstructor(
  [ROLES.USER],
  () => `${pageOverview(routeName)}`,
);

export const SURVEY_DASHBOARD = new LinkConstructor(
  [ROLES.USER],
  ({ surveyId = defaultSurveyId }) => pageDashboard(routeName, { id: surveyId }),
);

export const SURVEY_UPDATE = new LinkConstructor([ROLES.USER], ({ surveyId = defaultSurveyId }) =>
  pageUpdate(routeName, { id: surveyId }),
);
