import { useReducer } from 'react';

import { ISection, SECTION_TYPE } from '~/components/SideBar';

enum SectionStateActionTypes {
  SET_CURRENT_SECTION = 'SET_CURRENT_SECTION',
  SET_SECTION_ERROR = 'SET_SECTION_ERROR',
  SET_TRIED_TO_SUBMIT = 'SET_TRIED_TO_SUBMIT',
  SET_TYPE_SECTION = 'SET_TYPE_SECTION',
}

interface SetCurrentSection {
  type: SectionStateActionTypes.SET_CURRENT_SECTION;
  payload: number;
}

interface SetErrorSection {
  type: SectionStateActionTypes.SET_SECTION_ERROR;
  payload: {
    index: number;
    hasError: boolean;
  };
}

interface SetTriedToSubmit {
  type: SectionStateActionTypes.SET_TRIED_TO_SUBMIT;
  payload: boolean;
}

interface SetTypeSection {
  type: SectionStateActionTypes.SET_TYPE_SECTION;
  payload: {
    index: number;
    type?: SECTION_TYPE;
  };
}

function reducer(
  state: { sections: ISection[]; currentSection: number; triedToSubmit: boolean },
  action: SetCurrentSection | SetErrorSection | SetTriedToSubmit | SetTypeSection,
) {
  switch (action.type) {
    case SectionStateActionTypes.SET_CURRENT_SECTION: {
      const sections = [...state.sections];

      // set new section to touched
      sections[action.payload] = { ...sections[action.payload], isTouched: true };

      // Update previous section to done status if no errors
      if (sections[state.currentSection].type !== SECTION_TYPE.ERROR) {
        sections[state.currentSection] = {
          ...sections[state.currentSection],
          type: SECTION_TYPE.DONE,
        };
      }

      // update currentSection and sections
      return { ...state, sections, currentSection: action.payload };
    }
    case SectionStateActionTypes.SET_SECTION_ERROR: {
      const sections = state.sections;
      let newType = sections[action.payload.index].type;
      if (action.payload.hasError) {
        newType = SECTION_TYPE.ERROR;
      } else if (sections[action.payload.index].isTouched) {
        newType = SECTION_TYPE.DONE;
      }

      sections[action.payload.index] = {
        ...sections[action.payload.index],
        type: newType,
      };

      return { ...state };
    }
    case SectionStateActionTypes.SET_TRIED_TO_SUBMIT: {
      return { ...state, triedToSubmit: action.payload };
    }
    case SectionStateActionTypes.SET_TYPE_SECTION: {
      const sections = [...state.sections];

      // set new section to touched
      sections[action.payload.index] = {
        ...sections[action.payload.index],
        type: action.payload.type,
      };

      // update currentSection and sections
      return { ...state, sections };
    }
  }
}

interface ISectionState {
  triedToSubmit: boolean;
  currentSection: number;
  sections: ISection[];
  setTriedToSubmit: () => void;
  setErrorSection: (index: number, hasError: boolean) => void;
  setCurrentSection: (index: number) => void;
  goToFirstErrorSection: () => void;
  setTypeSection: (index: number, type?: SECTION_TYPE) => void;
}

function useSectionState(
  initialSections: ISection[],
  options: { currentSection?: number } = {},
): ISectionState {
  const [sectionState, dispatch] = useReducer(reducer, {
    sections: initialSections.map((section, index) => ({
      ...section,
      isTouched: (options.currentSection ?? 0) === index,
    })),
    currentSection: options.currentSection ?? 0,
    triedToSubmit: false,
  });

  function setCurrentSection(index: number) {
    dispatch({ type: SectionStateActionTypes.SET_CURRENT_SECTION, payload: index });
  }

  function goToFirstErrorSection() {
    const index = sectionState.sections.findIndex((section) => section.type === SECTION_TYPE.ERROR);

    if (index >= 0) {
      dispatch({ type: SectionStateActionTypes.SET_CURRENT_SECTION, payload: index });
    }
  }

  function setErrorSection(index: number, hasError: boolean) {
    dispatch({ type: SectionStateActionTypes.SET_SECTION_ERROR, payload: { index, hasError } });
  }

  function setTriedToSubmit() {
    dispatch({ type: SectionStateActionTypes.SET_TRIED_TO_SUBMIT, payload: true });
  }

  function setTypeSection(index: number, type?: SECTION_TYPE) {
    dispatch({ type: SectionStateActionTypes.SET_TYPE_SECTION, payload: { index, type } });
  }

  return {
    triedToSubmit: sectionState.triedToSubmit,
    currentSection: sectionState.currentSection,
    sections: sectionState.sections,
    setCurrentSection,
    setErrorSection,
    setTriedToSubmit,
    goToFirstErrorSection,
    setTypeSection,
  };
}

export { useSectionState };
export type { ISectionState };
