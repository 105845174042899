import { createReducer } from 'redux-act';

import * as actions from './actions';

const INITIAL_STATE = {
  companySkills: {},
  learnedSkills: {},
};
export default createReducer(
  {
    [actions.setSkillSets]: (state, payload) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  INITIAL_STATE,
);
