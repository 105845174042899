import { ROLES } from '@learned/constants';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';

import { checkHasReviewCompleteLogic } from '~/utils/checkHasReviewCompleteLogic';

const DEFAULT_PLAN_CONVERSATION_ADD = 28;
const DEFAULT_SIGN = DEFAULT_PLAN_CONVERSATION_ADD + 14;

export const getDefaultDeadlineToSign = () => moment().add(DEFAULT_SIGN, 'days').format();

export const prepareDefaultDeadlines = (review, { currentRole } = {}) => {
  const defaultDates = {};

  const isCompleteLogic = checkHasReviewCompleteLogic(
    review?.templateItem?.digitalSign,
    get(review, 'meta.createdDate'),
  );

  if (currentRole === ROLES.COACH) {
    // set default dateOfConversation, if it's empty
    if (!get(review, 'dateOfConversation')) {
      defaultDates.dateOfConversation = moment()
        .add(45, 'days')
        .set({ hour: 12, minute: 0, seconds: 0 })
        .format();
    }

    if (!get(review, 'dateOfConversationEnd')) {
      defaultDates.dateOfConversationEnd = moment()
        .add(45, 'days')
        .set({ hour: 12, minute: 15, seconds: 0 })
        .format();
    }
  }

  // by default startDate = currentDate
  const startDates = [
    'startDateNominate',
    'startDateSelfReview',
    'startDateUsersRequest',
    'startDateCoachesRequest',
    'startDateOfPlanConversation',
  ];

  startDates.forEach((key) => {
    // add startDate if it's empty
    if (!get(review, key)) {
      defaultDates[key] = moment().format();
    }
  });

  if (!get(review, 'deadlineNominate')) {
    defaultDates.deadlineNominate = moment().add(7, 'days').format();
  }

  if (!get(review, 'deadlineSelfReview')) {
    defaultDates.deadlineSelfReview = moment().add(14, 'days').format();
  }

  if (!get(review, 'deadlineUsersRequest')) {
    defaultDates.deadlineUsersRequest = moment().add(21, 'days').format();
  }

  if (!get(review, 'deadlineCoachesRequest')) {
    defaultDates.deadlineCoachesRequest = moment()
      .add(DEFAULT_PLAN_CONVERSATION_ADD, 'days')
      .format();
  }

  if (!get(review, 'deadlineOfPlanConversation')) {
    defaultDates.deadlineOfPlanConversation = moment()
      .add(DEFAULT_PLAN_CONVERSATION_ADD, 'days')
      .format();
  }

  if (isEmpty(review) || isCompleteLogic) {
    if (!get(review, 'startDateToSign')) {
      defaultDates.startDateToSign = moment().format();
    }

    if (!get(review, 'deadlineToSign')) {
      defaultDates.deadlineToSign = getDefaultDeadlineToSign();
    }
  }

  return defaultDates;
};
