import { createAction } from 'redux-act';

import { getCurrentCompanyId } from '~/selectors/baseGetters';
import {
  getCompanySkillLabels as getCompanySkillLabelsService,
  updateCompanySkillLabels as updateCompanySkillLabelsService,
  getCompanyDefaultFocusAreas as getCompanyDefaultFocusAreasService,
  updateCompanyDefaultFocusAreas as updateCompanyDefaultFocusAreasService,
} from '~/services/companySettings';

import type { Dispatch } from 'redux';

export const setCompanySettings = createAction<unknown>('Set company settings');
export const updateCompanySettings = createAction('Update company settings');
export const updateCompanySettingsSkillLabels = createAction<unknown>(
  'Update company settings skill labels',
);
export const updateCompanySettingsNotificationSettings = createAction<{
  userIsAllowedToSetNotificationPreferences: boolean;
}>('Update company settings notification settings');
export const updateCompanySettingsRoleRelevancies = createAction('Update company role relevancies');
export const updateCompanySettingsDefaultFocusAreas = createAction<unknown>(
  'Update company settings default focus areas',
);

export function getCompanySkillLabels() {
  return async (dispatch: Dispatch, getState: () => unknown) => {
    const companyId = getCurrentCompanyId(getState());
    const skillLabels = await getCompanySkillLabelsService(companyId);
    dispatch(updateCompanySettingsSkillLabels(skillLabels));
  };
}

export function updateCompanySkillLabels(newSkillLabels: unknown) {
  return async (dispatch: Dispatch, getState: () => unknown) => {
    const companyId = getCurrentCompanyId(getState());
    await updateCompanySkillLabelsService(companyId, newSkillLabels);
    dispatch(updateCompanySettingsSkillLabels(newSkillLabels));
  };
}

export function getCompanyDefaultFocusAreas() {
  return async (dispatch: Dispatch, getState: () => unknown) => {
    const companyId = getCurrentCompanyId(getState());
    const defaultFocusAreas = await getCompanyDefaultFocusAreasService(companyId);
    dispatch(updateCompanySettingsDefaultFocusAreas(defaultFocusAreas));
  };
}

export function updateCompanyDefaultFocusAreas(newDefaultFocusAreas: unknown) {
  return async (dispatch: Dispatch, getState: () => unknown) => {
    const companyId = getCurrentCompanyId(getState());
    await updateCompanyDefaultFocusAreasService(companyId, newDefaultFocusAreas);
    dispatch(updateCompanySettingsDefaultFocusAreas(newDefaultFocusAreas));
  };
}
