import React, { useState } from 'react';

import {
  ACTIVITY_PROGRESS_TYPES,
  ACTIVITY_ROOT,
  CURRENCY,
  GOAL_PROGRESS_TYPES,
  GOAL_STATUSES,
  SYMBOLS,
} from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { StatusLabel } from '~/components/GoalsBlock/components/StatusLabel';
import { ActivityModal } from '~/components/Modals/ActivityModal';
import ToolTip from '~/components/Tooltip';

import { ActivityProgressModal } from './ActivityProgressModal';
import {
  ActityStatusLabelContainer,
  ActivityCurrentProgressContainer,
  ActivityDetailsContainer,
  ActivityItemContainer,
  ActivityItemInnerContainer,
  CurrentValue,
  GoalValue,
  NameContainer,
  ProgressDetailsContainer,
  StartValue,
  StyledUpdateButton,
} from './design';

import useBoolState from '~/hooks/useBoolState';
import { getUser, getTeams } from '~/selectors/baseGetters';
import * as currentGoalActivities from '~/store/currentGoal/actions';
import { getGoalProgressWithDecimals } from '~/utils/getGoalProgressWithDecimals';
import { isUserCanEditGoal } from '~/utils/isUserCanEditGoal';

import type { IActivity, IGoal } from '@learned/types';

const ActivityProgressSection = ({ goal }: { goal: IGoal }) => {
  const dispatch = useDispatch();
  const $isShowDetailsModal = useBoolState(false);
  const [selectedActivity, setSelectedActivity] = useState<IActivity>();
  const [isHovered, setIsHovered] = React.useState(false);
  const [hoveredActivity, setHoveredActivity] = React.useState<string>();
  const { i18n } = useLingui();
  const [activities, setActivities] = React.useState<IActivity[]>(
    (goal.activities as IActivity[]) || [],
  );
  const currentUser = useSelector(getUser);
  const teams = useSelector(getTeams);

  const isEditor = isUserCanEditGoal({ goal, user: currentUser, teams });
  const isOwner = goal.owners?.includes(currentUser?.id as string);

  const isProgressEditable = goal.status !== GOAL_STATUSES.ARCHIVED && (isEditor || isOwner);

  React.useEffect(() => {
    setActivities((goal.activities as IActivity[]) || []);
  }, [goal.activities]);

  const handleMouseEnter = (id: string) => {
    setIsHovered(true);
    setHoveredActivity(id);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
    setHoveredActivity(undefined);
  };

  const onActivityClick = (activity: IActivity) => {
    setSelectedActivity(activity);
    $isShowDetailsModal.on();
  };

  const updateActivity = async (id: string, data: IActivity) => {
    await dispatch(currentGoalActivities.updateActivity(id, data, true));
  };

  return (
    <div>
      {activities.map((activity) => {
        const isPercentage = activity.progressType === ACTIVITY_PROGRESS_TYPES.PERCENTAGE;
        const isDone = activity.progressType === ACTIVITY_PROGRESS_TYPES.IS_DONE;
        const isCurrency = activity.progressType === ACTIVITY_PROGRESS_TYPES.CURRENCY;

        // adding currency and percentage symbols
        const getValuesWithSymbols = (value: number) => {
          let result = `${value.toFixed(2)}`;
          if (isPercentage) {
            result = `${value.toFixed(2)}%`;
          }
          if (isCurrency) {
            result = `${SYMBOLS[activity.progressDetails.currency as CURRENCY]}${value.toFixed(2)}`;
          }
          return result;
        };

        const currentProgress = Number(activity.progress) || 0;

        const isHoveredItem = isHovered && hoveredActivity === activity.id;

        const isShowUpdateButton = isHoveredItem && isProgressEditable;

        return (
          <ActivityItemContainer
            key={activity.id}
            onMouseEnter={() => handleMouseEnter(activity.id)}
            onMouseLeave={handleMouseLeave}
            isHovered={isHoveredItem}
            onClick={() => onActivityClick(activity)}
            isMetric={!isDone}
          >
            <ActivityItemInnerContainer>
              <ActivityDetailsContainer>
                <NameContainer>
                  <ToolTip tooltip={activity.name}>
                    <span>{activity.name}</span>
                  </ToolTip>
                </NameContainer>
                {!isDone && (
                  <ProgressDetailsContainer>
                    <StartValue>
                      <Trans>Start</Trans>
                      &nbsp;
                      {getValuesWithSymbols(activity.progressDetails.min || 0)}
                    </StartValue>
                    <CurrentValue>
                      <Trans>Current</Trans>
                      &nbsp;
                      {getValuesWithSymbols(Number(currentProgress) || 0)}
                    </CurrentValue>
                    <GoalValue>
                      <Trans>Goal</Trans>
                      &nbsp;
                      {getValuesWithSymbols(activity.progressDetails.max || 0)}
                    </GoalValue>
                  </ProgressDetailsContainer>
                )}
              </ActivityDetailsContainer>

              <ActivityCurrentProgressContainer>
                {getGoalProgressWithDecimals(
                  String(activity?.calculatedProgress) || '0',
                  GOAL_PROGRESS_TYPES.AVG,
                )}
                %
              </ActivityCurrentProgressContainer>
              <ActityStatusLabelContainer>
                {isShowUpdateButton ? (
                  <StyledUpdateButton
                    label={i18n._(t`Update`)}
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.MEDIUM}
                  />
                ) : (
                  <StatusLabel status={activity.status} progress={activity.calculatedProgress} />
                )}
              </ActityStatusLabelContainer>
            </ActivityItemInnerContainer>
          </ActivityItemContainer>
        );
      })}
      {$isShowDetailsModal.value &&
        selectedActivity?.root !== ACTIVITY_ROOT.LIBRARY &&
        selectedActivity && (
          <ActivityProgressModal
            activityId={selectedActivity.id}
            onClose={$isShowDetailsModal.off}
            isProgressEditable={!!isProgressEditable}
            updateActivity={updateActivity}
          />
        )}
      {$isShowDetailsModal.value &&
        selectedActivity?.root === ACTIVITY_ROOT.LIBRARY &&
        selectedActivity && (
          <ActivityModal
            // @ts-ignore
            activityId={selectedActivity?.id}
            onSaveActivity={() => {}}
            onClose={$isShowDetailsModal.off}
            isUpdateProgress={!!isProgressEditable}
            isUserActivity
            isGoalActivity={true}
          />
        )}
    </div>
  );
};

export { ActivityProgressSection };
