import React, { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import DatePicker from '~/components/DatePickerDeprecated';
import RickTextView from '~/components/RickTextView';
import SvgIcon from '~/components/SvgIcon';
import AvatarCard from '~/components/UI/AvatarCard';

import feedbackIcon from '~/assets/main-menu-icons/chat-1.svg';

import { RATING_TYPES } from '~/constants';
import { getUser, getUsers } from '~/selectors/baseGetters';
import { getAskedRTFeedbacks, getProvidedRTFeedbacks } from '~/services/RTFeedbacks';
import { COLOR_PALETTE, COLORS, COLOR_SET } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';
import { sortFeedbacksByDate } from '~/utils/feedbacks';
import getUserFullName from '~/utils/getUserFullName';

const Wrapper = styled.div`
  overflow: hidden;
`;

const FilterWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  color: ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const RTFeedbacksWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 163px);
`;

const RTFeedbackWrapper = styled.div`
  border-bottom: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
  padding: 16px 0;
  &:last-child {
    border-bottom: none;
  }
`;

const QuestionWrapper = styled.div`
  margin: 8px 0 16px;
  padding: 16px;
  border-radius: 3px;
  background-color: ${COLORS.BG_PAGE};
  border: 1px solid ${COLOR_PALETTE.GRAY_SEMI_SOFT};
`;

const StyledRickTextView = styled(RickTextView)`
  color: ${COLOR_PALETTE.DARK_GRAY};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
`;

const StyledAvatarCard = styled(AvatarCard)`
  margin-top: 8px;
  flex-direction: row-reverse;
  gap: 8px;
`;

const AnswerWrapper = styled.div`
  padding: 16px;
  border-radius: 3px;
  background-color: ${COLOR_SET.CYAN_LIGHT};
`;

const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  text-align: center;
`;

const PlaceholderSvgIcon = styled(SvgIcon)`
  margin-bottom: 4px;
`;

const PlaceholderTitle = styled.h1`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const PlaceholderText = styled.div`
  font-size: 14px;
  line-height: 1.67;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const DateWrapper = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ReviewRTFeedback = ({ i18n, ratingType, requestedBy }) => {
  const [RTFeedbacks, setRTFeedbacks] = useState([]);
  const currentDate = new Date();
  const [startFilterDate, setStartFilterDate] = useState(
    new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      currentDate.getDate(),
    ).toISOString(),
  );
  const [endFilterDate, setEndFilterDate] = useState(currentDate.toISOString());
  const users = useSelector(getUsers);
  const currentUser = useSelector(getUser);

  useEffect(() => {
    const fetch = async () => {
      switch (ratingType) {
        case RATING_TYPES.OTHER_RATING:
        case RATING_TYPES.COACH_RATING: {
          const asked = await getAskedRTFeedbacks(
            ['goal', 'skills'],
            startFilterDate,
            endFilterDate,
          );
          const provided = await getProvidedRTFeedbacks(
            ['goal', 'skills'],
            startFilterDate,
            endFilterDate,
          );

          let fetchedRTFeedbacks = [];
          [...Object.values(asked), ...Object.values(provided)].map((i) => {
            if (
              (i.createdBy === currentUser.id &&
                (i.createdFor === requestedBy.id || i.users?.includes(requestedBy.id))) ||
              (i.createdBy === requestedBy.id &&
                (i.createdFor === currentUser.id || i.users?.includes(currentUser.id)))
            ) {
              fetchedRTFeedbacks.push(i);
            }
          });
          setRTFeedbacks(sortFeedbacksByDate(fetchedRTFeedbacks));
          break;
        }
        case RATING_TYPES.SELF_RATING: {
          const fetchedRTFeedbacks = await getAskedRTFeedbacks(
            ['goal', 'skills'],
            startFilterDate,
            endFilterDate,
          );
          setRTFeedbacks(sortFeedbacksByDate(Object.values(fetchedRTFeedbacks)));
          break;
        }
        default:
          break;
      }
    };
    fetch();
  }, [currentUser.id, ratingType, requestedBy.id, startFilterDate, endFilterDate]);

  const renderRTFeedbackAnswer = (answer, sub) => {
    return (
      <div key={answer.id}>
        <AnswerWrapper>
          <StyledRickTextView html={answer.feedback || i18n._(t`Waiting for response...`)} />
          {answer.feedback && (
            <DateWrapper>
              {convertToTimeString(
                answer.feedback && (answer.meta.lastModifiedDate || answer.meta.createdDate),
              )}
            </DateWrapper>
          )}
        </AnswerWrapper>
        <StyledAvatarCard
          type="small"
          size={28}
          userFullname={getUserFullName(users[sub ? answer.createdFor : answer.createdBy])}
          userId={sub ? answer.createdFor : answer.createdBy}
          email={answer.createdForEmail}
        />
      </div>
    );
  };

  const renderPlaceholder = () => {
    return (
      <PlaceholderContainer>
        <PlaceholderSvgIcon
          url={feedbackIcon}
          width="24px"
          height="24px"
          isDefaultColor
          defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
        />
        <PlaceholderTitle>
          <Trans>No feedback available</Trans>
        </PlaceholderTitle>
        <PlaceholderText>
          <Trans>There is no feedback received or given yet within this period</Trans>
        </PlaceholderText>
      </PlaceholderContainer>
    );
  };

  const renderRTFeedback = (RTFeedback) => {
    return RTFeedback.description ? (
      <RTFeedbackWrapper key={RTFeedback.id}>
        <AvatarCard
          type="small"
          size={28}
          userId={RTFeedback.createdBy || RTFeedback.createdForEmail}
        />
        <QuestionWrapper>
          <StyledRickTextView html={RTFeedback.description} />
          <DateWrapper>
            {convertToTimeString(RTFeedback.meta.lastModifiedDate || RTFeedback.meta.createdDate)}
          </DateWrapper>
        </QuestionWrapper>
        {RTFeedback.subRTFeedbacks
          ? RTFeedback.subRTFeedbacks.map((sub) => renderRTFeedbackAnswer(sub, true))
          : renderRTFeedbackAnswer(RTFeedback, true)}
      </RTFeedbackWrapper>
    ) : (
      <RTFeedbackWrapper key={RTFeedback.id}>
        {renderRTFeedbackAnswer(RTFeedback)}
      </RTFeedbackWrapper>
    );
  };

  return (
    <Wrapper>
      <FilterWrapper>
        <FilterContainer key="start-date">
          <Trans>From</Trans>
          <DatePicker
            width={150}
            selected={startFilterDate}
            onChange={setStartFilterDate}
            popperPlacement="bottom-start"
          />
        </FilterContainer>
        <FilterContainer key="end-date">
          <Trans>To</Trans>
          <DatePicker
            width={150}
            selected={endFilterDate}
            onChange={setEndFilterDate}
            popperPlacement="bottom-start"
          />
        </FilterContainer>
      </FilterWrapper>
      {isEmpty(RTFeedbacks) ? (
        renderPlaceholder()
      ) : (
        <RTFeedbacksWrapper>{RTFeedbacks.map(renderRTFeedback)}</RTFeedbacksWrapper>
      )}
    </Wrapper>
  );
};

export default withI18n()(ReviewRTFeedback);
