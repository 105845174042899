import React, { useState, useEffect, useRef, useContext } from 'react';

import { LUCA_INSIGHT_STATUS, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import Label from '~/components/Label';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { EngagementReportContext } from '~/pages/Reports/engagement/Layout';

import {
  ModalWrapper,
  ModalFooter,
  ModalInner,
  ModalTitle,
  ModalTitleLeft,
  ModalTitleRight,
  ModalBody,
  CrossButtonCtr,
  LoadingContainer,
  LoadingContainerIcon,
  LoadingContainerGreeting,
  TimeFrameWrapper,
  AverageContainer,
  AverageContainerBar,
  AverageContainerDetails,
  ThemeDescription,
  InsightRequestContainer,
  InsightResponseContainer,
} from './design';

import * as reportsService from '~/services/reports';
import { TTotalGraphData } from '~/services/reports';
import { COLORS } from '~/styles';
import { toFixed } from '~/utils/math';
import * as reportsUtil from '~/utils/reports';
import sanitizeHtml from '~/utils/sanitize';
import { stripHtml } from '~/utils/stripHtml';

const InformationModal = ({
  baseRef,
  data,
  onClose,
}: {
  baseRef: React.RefObject<HTMLDivElement>;
  data: TTotalGraphData;
  onClose(v: boolean): void;
}) => {
  const { i18n } = useLingui();
  const {
    primaryRowSelected,
    monthSelected,
    themesOptions,
    viewAs,
    reportConfiguration,
    learnedThemesOptions,
  } = useContext(EngagementReportContext);

  const timeFrame = reportsUtil.getDateForTimeFrame(monthSelected, 'DD-MM-YYYY');
  const refreshInterval = useRef<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [insight, setInsight] = useState({
    status: LUCA_INSIGHT_STATUS.PENDING,
    responses: { content1: '' },
  });

  const isLearnedTheme = learnedThemesOptions.some(({ key }) => key === data.id);
  const shouldShowAISection =
    isLearnedTheme &&
    reportConfiguration?.isDefault &&
    !!data.value &&
    (viewAs === ROLES.ADMIN || viewAs === ROLES.COACH);

  const dimensionName = primaryRowSelected?.key || '';

  useEffect(() => {
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }

    // retry fetching while the insight status is PENDING
    if (isLoading) {
      refreshInterval.current = window.setInterval(async () => {
        const res = await reportsService.getAIInsight(
          'engagement',
          'admin',
          `ENGAGEMENT_LEARNED_THEME_${data.id}`,
        );

        if (
          res.data.status === LUCA_INSIGHT_STATUS.READY ||
          res.data.status === LUCA_INSIGHT_STATUS.FAILED ||
          res.data.status === LUCA_INSIGHT_STATUS.NO_DATA
        ) {
          setIsLoading(false);

          if (res.data.responses.content1) {
            res.data.responses.content1 = res.data.responses.content1
              .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
              .replace(/.\d+†source./g, '');
          }
          setInsight(res.data);
        }
      }, 5000);
    }

    return () => {
      clearInterval(refreshInterval.current);
    };
    // eslint-disable-next-line
  }, [isLoading]);

  const askLucaInsight = () => {
    setIsLoading(true);
  };

  const disclaimerText = i18n._(
    t`Legal Disclaimer: The information provided herein has been produced by an independently developed Generative Pre-trained Transformer (GPT) model. While efforts have been made to ensure the accuracy of the data generated by this artificial intelligence (AI) system, it is important to acknowledge that inaccuracies may occur. Please be advised that the personal data submitted for processing by this AI model is not utilized for the purpose of training or enhancing the capabilities of the GPT model.`,
  );
  const lucaIntroductionText = i18n._(
    t`My name is Luca. I am your AI powered assistant. I am here to help you crunch the numbers, analyze your results and provide you with tips based on best practices.`,
  );

  return (
    <ModalWrapper>
      <ModalInner ref={baseRef}>
        <ModalTitle>
          <ModalTitleLeft>{i18n._(t`${data.name}`)} </ModalTitleLeft>
          <ModalTitleRight>
            <CrossButtonCtr
              onClick={() => {
                onClose(false);
              }}
            >
              <Icon size={ICON_SIZES.LARGE} icon={ICONS.CLOSE} />
            </CrossButtonCtr>
          </ModalTitleRight>
        </ModalTitle>
        <ModalBody>
          <TimeFrameWrapper>
            [{timeFrame.start} - {timeFrame.end}]
          </TimeFrameWrapper>

          {data.value ? (
            <AverageContainer>
              <AverageContainerBar />
              <AverageContainerDetails>
                {(viewAs === ROLES.ADMIN || viewAs === ROLES.COACH) && (
                  <div className="admin-average">{toFixed(data.value || 0, 2)}%</div>
                )}
                {viewAs === ROLES.USER && (
                  <>
                    <div className="user-average">{toFixed(data.value || 0, 2)}%</div>
                    {data.team ? (
                      <div className="user-team-average">
                        {i18n._(t`Team`)} {toFixed(data.team || 0, 2)}%
                      </div>
                    ) : (
                      <></>
                    )}
                    {data.benchmark ? (
                      <div className="user-company-average">
                        {i18n._(t`Company`)} {toFixed(data.benchmark || 0, 2)}%
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}

                <div className="trend-indicator">
                  <Tooltip
                    disabled={!data.deviation}
                    tooltip={`The deviation is the difference between the engagement from the start to todays engagement for this ${dimensionName}.`}
                    size={TOOLTIP_SIZES.BIG}
                  >
                    <div>
                      {data.deviation ? (
                        <Label
                          color={data.deviation < 0 ? COLORS.ACCENT_ERROR : COLORS.ACCENT_SUCCESS}
                          backgroundColor={
                            data.deviation < 0 ? COLORS.ERROR_LIGHT : COLORS.SUCCESS_LIGHT
                          }
                        >
                          {toFixed(data.deviation || 0, 2)}% {i18n._(t`compared to`)} [
                          {timeFrame.start}]
                        </Label>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Tooltip>
                </div>
              </AverageContainerDetails>
            </AverageContainer>
          ) : (
            <></>
          )}
          {dimensionName === 'theme' && (
            <>
              <ThemeDescription>
                {stripHtml(
                  themesOptions.find(({ key }) => key === data.id)?.description?.(i18n) || '',
                )}
              </ThemeDescription>
              {shouldShowAISection && (
                <>
                  {insight.status === LUCA_INSIGHT_STATUS.PENDING && (
                    <InsightRequestContainer>
                      <div className="title">{`${i18n._(t`Looking for recommendations ?`)}`}</div>
                      <div className="body">
                        {!isLoading ? (
                          <Tooltip tooltip={lucaIntroductionText} size={TOOLTIP_SIZES.BIG}>
                            <div>
                              <Button
                                label={`${i18n._(t`Ask Luca`)}`}
                                icon={ICONS.LUCA}
                                variant={ButtonVariant.PRIMARY}
                                onClick={askLucaInsight}
                                size={ButtonSize.MEDIUM}
                              />
                            </div>
                          </Tooltip>
                        ) : (
                          <LoadingContainer>
                            <LoadingContainerIcon>
                              <Icon
                                icon={ICONS.LUCA}
                                color={COLORS.COMPANY}
                                size={ICON_SIZES.LARGE}
                              />
                            </LoadingContainerIcon>
                            <LoadingContainerGreeting>
                              <p>{i18n._(t`Analysing ...`)}</p>
                            </LoadingContainerGreeting>
                          </LoadingContainer>
                        )}
                      </div>
                    </InsightRequestContainer>
                  )}
                  {insight.status === LUCA_INSIGHT_STATUS.READY && (
                    <InsightResponseContainer>
                      <div className="title">{`${i18n._(t`Recommended improvements`)}`}</div>
                      <div className="body">
                        <div>
                          <Icon icon={ICONS.LUCA} color={COLORS.COMPANY} size={ICON_SIZES.LARGE} />
                        </div>
                        <div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHtml(insight.responses.content1),
                            }}
                          />
                          <Tooltip tooltip={disclaimerText} size={TOOLTIP_SIZES.BIG}>
                            <div className="disclaimer">{i18n._(t`Legal Disclaimer`)}</div>
                          </Tooltip>
                        </div>
                      </div>
                    </InsightResponseContainer>
                  )}
                </>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            label={`${i18n._(t`Close`)}`}
            variant={ButtonVariant.SECONDARY}
            onClick={() => onClose(false)}
            size={ButtonSize.MEDIUM}
          />
        </ModalFooter>
      </ModalInner>
    </ModalWrapper>
  );
};

export { InformationModal };
