export enum SKILL_CATEGORY_TYPE {
  SOFT = 'soft',
  HARD = 'hard',
}

export enum SKILL_SOURCE {
  LEARNED = 'learned',
  LEARNED2 = 'learned2',
  COMPANY = 'company',
}

export enum CATEGORY_SKILL_RATING_KEYS {
  AVG_EXPECTED_LEVEL = 'avgExpectedLevel',
  AVG_SELF_RATING = 'avgSelfRating',
  AVG_PEERS_RATING = 'avgPeersRating',
  AVG_COACHES_RATING = 'avgCoachesRating',
}
