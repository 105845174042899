import {
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
  ENGAGEMENT_REPORT_CHART_TYPES,
  REPORT_TYPES,
  ROLES,
} from '@learned/constants';
import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';

import type { ICreateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export type TSortingOrder = 'asc' | 'desc';

export type TGetEngagementDetailsPayload = {
  viewAs: ROLES.ADMIN | ROLES.COACH | ROLES.USER;
  reportType: string;
  primaryDimension: string;
  secondaryDimension: string;
  measure: string;
  dateRange: {
    start: string;
    end: string;
  };
  filters: {
    themes: string[];
    teams: string[];
    surveys: string[];
    search?: string;
  };
  sorting: {
    order: TSortingOrder;
    orderBy: string;
  };
  options: Record<string, number | boolean | string>;
  pagination: {
    limit: number;
    skip: number;
  };
};

export interface IChartReportRequest {
  viewAs: ROLES.ADMIN | ROLES.COACH | ROLES.USER;
  reportType: REPORT_TYPES;
  chartType: ENGAGEMENT_REPORT_CHART_TYPES;
  primaryDimension?: ENGAGEMENT_REPORT_CHART_DIMENSIONS;
  companyId?: string;
  options?: Record<string, string | boolean | number>;
  filters?: {
    userId?: string;
    themes?: string[];
    teams?: string[];
    surveys?: string[];
  };
  dateRange: {
    start: string;
    end: string;
  };
  pagination?: {
    limit: number;
    skip: number;
  };
}

export type TTotalGraphData = {
  id: string;
  name: string;
  value: number | null;
  team?: number | null;
  benchmark?: number | null;
  deviation?: number | null;
};

type TChartSeries = {
  benchmark: number;
  month: string;
  value: number;
  deviation: number;
  team: number;
};

export type TEngagementReportResponse = {
  chartType: string;
  averageEngagement: {
    average: number;
    deviation: number;
    series: TChartSeries[];
  };
  total: number;
  dimensionAverage: TTotalGraphData[];
  themeRank: {
    top: {
      name: string;
      value: number;
      trend: string;
      deviation: number;
    }[];
    bottom: {
      name: string;
      value: number;
      trend: string;
      deviation: number;
    }[];
  };
};

export type TTabResponse = {
  company: string;
  reportType: REPORT_TYPES;
  name: {
    nl_NL: string;
    en_GB: string;
  };
  sourceReport: string;
  selectedTab: ENGAGEMENT_REPORT_CHART_TYPES;
  isDefault: true;
  isCustomizable: false;
  deleted: false;
  sortOrder: 8;
  tabs: {
    key: ENGAGEMENT_REPORT_CHART_TYPES;
    primaryDimension?: {
      isEnabled: boolean;
      value: string;
    };
    secondaryDimension?: {
      isEnabled: boolean;
      value: null | string;
    };
    filters: {
      timeFrame?: {
        isEnabled: boolean;
        value: string;
      };
      themes?: {
        isEnabled: boolean;
        value: string[];
      };
      teams?: {
        isEnabled: boolean;
        value: string[];
      };
      surveys?: {
        isEnabled: boolean;
        value: string[];
      };
    };
    options: {
      isHeatmapColored?: {
        isEnabled: boolean;
        value: boolean;
      };
      includeCompanyAverage?: {
        isEnabled: boolean;
        value: boolean;
      };
      includeBenchmark?: {
        isEnabled: boolean;
        value: boolean;
      };
      includeTeamAverage?: {
        isEnabled: boolean;
        value: boolean;
      };
      exportOptions?: {
        isEnabled: boolean;
        value: string[];
      };
    };
    measure?: {
      isEnabled: boolean;
      value: string;
    };
  }[];
  id: string;
};

export function getEngagementDetails(payload: TGetEngagementDetailsPayload) {
  return cloudRequest(
    (serverRoutes.reports.getDetails as ICreateServerRoute)(),
    undefined,
    payload,
  );
}

export function getEngagementCharts(
  payload: IChartReportRequest,
): AxiosResponse<TEngagementReportResponse> {
  return cloudRequest(
    (serverRoutes.reports.getChart as ICreateServerRoute)(),
    undefined,
    payload,
  ) as unknown as AxiosResponse<TEngagementReportResponse>;
}

export function getTabDetails(id: string, viewAs: string): AxiosResponse<TTabResponse> {
  return cloudRequest((serverRoutes.reports.getTabs as ICreateServerRoute)(id), {
    viewAs,
  }) as unknown as AxiosResponse<TTabResponse>;
}

export function downloadMatrixReport(
  payload: TGetEngagementDetailsPayload,
  type: 'csv' | 'pdf',
  fileName: string,
) {
  return cloudRequest(
    (serverRoutes.reports.getMatrixReport as ICreateServerRoute)(),
    { type },
    payload,
  ).then((response) => {
    FileSaver.saveAs(response, `engagement_${fileName}.${type}`);
  });
}

export function downloadChartReport(
  payload: IChartReportRequest,
  type: 'csv' | 'pdf',
  fileName: string,
) {
  return cloudRequest(
    (serverRoutes.reports.getChartReport as ICreateServerRoute)(),
    { type },
    payload,
  ).then((response) => {
    FileSaver.saveAs(response, `engagement_${fileName}.${type}`);
  });
}

export function getCustomReports() {
  return cloudRequest((serverRoutes.reports.getCustomReports as ICreateServerRoute)());
}

export function deleteCustomReport(id: string) {
  return cloudRequest((serverRoutes.reports.deleteCustomReport as ICreateServerRoute)(id));
}

export function duplicateCustomReport(id: string) {
  return cloudRequest((serverRoutes.reports.duplicateCustomReport as ICreateServerRoute)(id));
}

export function createCustomReport(payload: TGetEngagementDetailsPayload) {
  return cloudRequest(
    (serverRoutes.reports.createCustomReport as ICreateServerRoute)(),
    undefined,
    payload,
  );
}

export function updateCustomReport(payload: TGetEngagementDetailsPayload, id: string) {
  return cloudRequest(
    (serverRoutes.reports.updateCustomReport as ICreateServerRoute)(id),
    undefined,
    payload,
  );
}

export function getFacets(include: string[], viewAs: string) {
  return cloudRequest((serverRoutes.reports.getFacets as ICreateServerRoute)(), undefined, {
    include,
    viewAs,
  });
}

export function getAIInsight(reportType: string, viewAs: string, insightTarget?: string) {
  return cloudRequest((serverRoutes.reports.getAIInsights as ICreateServerRoute)(), undefined, {
    reportType,
    insightTarget,
    viewAs,
  });
}
