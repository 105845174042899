import * as conversations from './conversations';
import * as conversationTemplates from './conversationTemplates';
import * as dashboards from './dashboards';
import * as engagement from './engagement';
import * as feedbackCycles from './feedbackCycles';
import general from './general';
import * as goals from './goals';
import * as goalTasks from './goalTasks';
import * as invites from './invites';
import * as jobprofiles from './jobprofiles';
import * as learnings from './learnings';
import * as logs from './logs';
import * as members from './members';
import * as nextSteps from './nextSteps';
import * as nineGrid from './nineGrid';
import * as paths from './paths';
import * as profile from './profile';
import * as reports from './reports';
import * as reviews from './reviews';
import * as reviewTemplates from './reviewTemplates';
import * as routesPublic from './routesPublic';
import * as rtfeedbacks from './rtfeedbacks';
import * as settings from './settings';
import * as skills from './skills';
import * as superadmin from './superadmin';
import * as surveys from './surveys';
import * as surveyTasks from './surveyTasks';
import * as surveyTemplates from './surveyTemplates';
import * as tasks from './tasks';
import * as teams from './teams';
import * as themes from './themes';
import * as userPaths from './userPaths';
import * as userPublic from './userPublic';
import * as userSurveys from './userSurveys';

export default {
  ...routesPublic,
  ...reviews,
  ...reviewTemplates,
  ...goals,
  ...logs,
  ...conversations,
  ...conversationTemplates,
  ...learnings,
  ...rtfeedbacks,
  ...dashboards,
  ...teams,
  ...settings,
  ...skills,
  ...jobprofiles,
  ...members,
  ...invites,
  ...tasks,
  ...superadmin,
  ...paths,
  ...userPublic,
  ...nineGrid,
  ...engagement,
  ...reports,
  ...goalTasks,
  ...feedbackCycles,
  ...userPaths,
  ...surveys,
  ...surveyTemplates,
  ...themes,
  ...general,
  ...surveyTasks,
  ...userSurveys,
  ...profile,
  ...nextSteps,
};
