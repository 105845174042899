import { createAction } from 'redux-act';

import { getCustomReports as getCustomReportsFromStore } from '~/selectors/baseGetters';
import * as reportsService from '~/services/reports';
import { TGetEngagementDetailsPayload, TTabResponse } from '~/services/reports';

import type { Dispatch } from 'redux';

export const startGetCustomReports = createAction('start get custom reports');
export const finishGetCustomReports = createAction<object>('finish get custom reports');

export const startGetCustomReport = createAction('start get custom report');
export const finishGetCustomReport = createAction('finish get custom report');

export const resetCustomReportsStore = createAction('start clear custom reports');

export const startDeleteCustomReport = createAction<string>('start delete custom report');
export const finishDeleteCustomReport = createAction('finish delete custom report');

export const startDuplicateCustomReport = createAction<string>('start duplicate custom report');
export const finishDuplicateCustomReport = createAction('finish duplicate custom report');

export const startSortCustomReport = createAction('start sort custom report');
export const finishSortCustomReport = createAction('finish sort custom report');

export function getCustomReports(force: boolean) {
  return async (dispatch: Dispatch, getState: () => unknown) => {
    const customReports = getCustomReportsFromStore(getState());

    if (!customReports || force) {
      dispatch(startGetCustomReports());
      try {
        const { data } = await reportsService.getCustomReports();
        dispatch(finishGetCustomReports(data.reports));
        return data.reports;
      } catch (ex) {
        console.error(ex);
        return [];
      }
    }
  };
}

export function clearCustomReports() {
  return async (dispatch: Dispatch) => {
    dispatch(resetCustomReportsStore());
  };
}

export function deleteCustomReport(reportId: string) {
  return async (dispatch: Dispatch) => {
    try {
      await reportsService.deleteCustomReport(reportId);
      const { data } = await reportsService.getCustomReports();
      dispatch(finishGetCustomReports(data.reports));
      return data.reports;
    } catch (ex) {
      console.error(ex);
      return [];
    }
  };
}

export function duplicateCustomReport(reportId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startGetCustomReports());
      await reportsService.duplicateCustomReport(reportId);
      const { data } = await reportsService.getCustomReports();
      dispatch(finishGetCustomReports(data.reports));
      return data.reports;
    } catch (ex) {
      console.error(ex);
      return [];
    }
  };
}

export function createCustomReport(payload: TTabResponse) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startGetCustomReports());
      // TODO: fix the type
      await reportsService.createCustomReport(payload as unknown as TGetEngagementDetailsPayload);
      const { data } = await reportsService.getCustomReports();
      dispatch(finishGetCustomReports(data.reports));
      return data.reports;
    } catch (ex) {
      console.error(ex);
      return [];
    }
  };
}

export function updateCustomReport(payload: TTabResponse, id: string) {
  return async (dispatch: Dispatch) => {
    try {
      // TODO: fix the type
      await reportsService.updateCustomReport(
        payload as unknown as TGetEngagementDetailsPayload,
        id,
      );
      const { data } = await reportsService.getCustomReports();
      dispatch(finishGetCustomReports(data.reports));
      return data.reports;
    } catch (ex) {
      console.error(ex);
      return [];
    }
  };
}

export function sortCustomReports() {
  return async (dispatch: Dispatch) => {
    dispatch(startSortCustomReport());
    dispatch(finishSortCustomReport());
  };
}
