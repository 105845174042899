import React, { useState, useEffect } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import SkillFocusAreas from '~/components/GiveFeedback/components/Section/components/SkillFocusAreas';
import Modal from '~/components/Modal';
import RatingScale from '~/components/RatingScale';
import RickTextView from '~/components/RickTextView';

import { ROLES } from '~/constants';
import { getSelectedRole } from '~/selectors/baseGetters';
import getLang from '~/selectors/getLang';
import { getSkill } from '~/services/skills';
import { COLOR_PALETTE } from '~/styles';
import { getSkillName, getSkillDefinition } from '~/utils/skillUtils';

const FocusAreaWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: -16px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  box-sizing: border-box;
`;

const SkillDefinitionModal = ({
  onModalClose,
  skill,
  i18n,
  scaleLabels,
  expectedLevel,
  isUserCareer,
  goToSkillPassport,
  isHideViewFeedback,
}) => {
  const [hover, setHover] = useState(expectedLevel);
  const lang = useSelector(getLang);
  const selectedRole = useSelector(getSelectedRole);
  const [activeSkill, setActiveSkill] = useState(skill);

  useEffect(() => {
    async function fetchData() {
      const selectedSkill = await getSkill(skill.id, ['focusAreas']);
      setActiveSkill({ ...selectedSkill, expectedLevel: skill.expectedLevel });
    }
    if (isUserCareer) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [isUserCareer]);

  const renderSkill = () => {
    const scale = [];
    const labels = [];
    for (let i = 0; i < activeSkill.levelsEnabled.length; i += 1) {
      if (activeSkill.levelsEnabled[i]) {
        scale.push(i + 1);
        labels.push(scaleLabels[i]);
      }
    }
    return (
      <div
        style={{
          marginTop: expectedLevel ? 44 : 10,
        }}
      >
        <RatingScale
          selectedRate={expectedLevel}
          scale={scale}
          scaleLabels={labels}
          isBig
          onChangeHoverLevel={setHover}
          isSingleHover={true}
          expectedRate={expectedLevel}
        />
      </div>
    );
  };

  const getFocusAreas = () => {
    const FA = activeSkill.focusAreas.filter((skill) => {
      return skill.level === hover;
    });

    return (
      <FocusAreaWrapper>
        <SkillFocusAreas focusAreas={FA} />
      </FocusAreaWrapper>
    );
  };

  return (
    <Modal
      onClose={onModalClose}
      title={
        isUserCareer
          ? getSkillName(activeSkill, lang)
          : `${i18n._(t`Skill levels`)}: ${getSkillName(activeSkill, lang)}`
      }
      contentStyles={{ background: COLOR_PALETTE.WHITE, padding: '6px 22px 26px 22px' }}
      headerStyles={{ padding: '10px 22px', height: '100%' }}
      hideFooter
      width={772}
    >
      <RickTextView html={getSkillDefinition(activeSkill, lang)} />
      {renderSkill()}
      {!!hover && activeSkill.focusAreas && getFocusAreas()}
      {!isHideViewFeedback && isUserCareer && selectedRole === ROLES.USER && (
        <Footer>
          <Button label={i18n._(t`View feedback`)} onClick={goToSkillPassport} />
        </Footer>
      )}
    </Modal>
  );
};

SkillDefinitionModal.propTypes = {
  isHideViewFeedback: PropTypes.bool,
};

SkillDefinitionModal.defaultProps = {
  isHideViewFeedback: true,
};

export default withI18n()(SkillDefinitionModal);
