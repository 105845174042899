import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Card, { ETypes, TScores, TChartData } from '~/components/Reports/DashboardCharts/Card';
import { CardWrapper } from '~/components/Reports/DashboardCharts/CardStyles';
import BaseLayout from '~/layouts/BaseLayout';

import { QuestionsTab } from './QuestionsTab';

import routes from '~/constants/routes';
import { checkModuleSurvey } from '~/selectors/baseGetters';
import { getUserSurveyResultsThemesChart } from '~/services/userSurveys';
import { COLORS } from '~/styles';

import type { IUserSurveyPopulated } from '@learned/types';

interface UserSurveyResultProps {
  userSurvey: IUserSurveyPopulated;
}

const HeaderCardWrapper = styled.div`
  margin-bottom: 20px;
`;

const HeaderViewReportWrapper = styled.div`
  margin-bottom: 20px;
  button {
    margin-left: auto;
  }
`;

export const TABS_ENUM = {
  TEAMS: 'teams',
  THEMES: 'themes',
  QUESTIONS: 'questions',
};

const UserSurveyResults = ({ userSurvey }: UserSurveyResultProps) => {
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const { i18n } = useLingui();
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState<{
    average: number;
    series: TChartData[];
    themeRank: TScores;
  }>();

  const fetchData = async () => {
    if (userSurvey.id && isModuleSurveyEnabled) {
      setIsLoading(true);
      const { data } = await getUserSurveyResultsThemesChart(userSurvey.id);

      if (data && data[API_RETURN_FIELDS.SURVEY_RESULTS_THEMES]) {
        setChartData({
          themeRank: {
            bestScore: data[API_RETURN_FIELDS.SURVEY_RESULTS_THEMES].themeRank.top,
            lowestScore: data[API_RETURN_FIELDS.SURVEY_RESULTS_THEMES].themeRank.bottom,
          },
          average: data[API_RETURN_FIELDS.SURVEY_RESULTS_THEMES].averageSeries.average,
          series: data[API_RETURN_FIELDS.SURVEY_RESULTS_THEMES].averageSeries.series.map(
            (s: { date: string; value: number }) => ({ key: s.date, value: s.value }),
          ),
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <BaseLayout smallWidth>
      <HeaderCardWrapper>
        <HeaderViewReportWrapper>
          <Button
            variant={ButtonVariant.NAVIGATION_PRIMARY}
            label={i18n._(t`View Report`)}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              routes.REPORTS_USER_ENGAGEMENT.go(
                // @ts-ignore
                { role: ROLES.USER },
                { query: { survey: userSurvey.survey.id } },
              );
            }}
          />
        </HeaderViewReportWrapper>
        <CardWrapper>
          <Card
            title={i18n._(t`Your Average engagement`)}
            chartData={chartData?.series}
            average={chartData?.average}
            isLoading={isLoading}
          />
          <Card
            title={i18n._(t`Best scoring themes`)}
            type={ETypes.BAR_CHART}
            data={chartData?.themeRank?.bestScore}
            isLoading={isLoading}
          />
          <Card
            title={i18n._(t`Lowest scoring themes`)}
            type={ETypes.BAR_CHART}
            data={chartData?.themeRank?.lowestScore}
            oppositeGradient
            bColor={COLORS.CONFIRMATION_MODAL_DELETE}
            isLoading={isLoading}
          />
        </CardWrapper>
      </HeaderCardWrapper>
      <QuestionsTab />
    </BaseLayout>
  );
};

export { UserSurveyResults };
