import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import IconMenu from '~/components/IconMenu';
import OverviewHeading from '~/components/OverviewHeading';
import { SearchField } from '~/components/Text';
import ActionsContainer from '~/components/UI/ActionsContainer';
import Divider from '~/components/UI/Divider';
import Table, { TableCol, TableRow } from '~/components/UI/Table';
import BaseLayout from '~/layouts/BaseLayout';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import {
  createTemplateSuperAdmin,
  deleteTemplatesSuperAdmin,
  getTemplatesSuperAdmin,
} from '~/services/reviewTemplates';
import { COLOR_PALETTE } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';
import { getTemplateCopyName } from '~/utils/templateUtils';

const ActionsContainerWrapper = styled(ActionsContainer)`
  height: 48px;
  align-items: center;
  border: unset;
`;

const Wrapper = styled.div`
  border-radius: 6px;
  border: solid 1px ${COLOR_PALETTE.GRAY_MIDDLE};
`;

const StyledSearch = styled(SearchField)`
  height: 32px;
  width: 248px;
  & > input {
    border-radius: 6px;
    font-size: 14px;
    height: 32px;
  }
`;

const StyledTable = styled(Table)`
  background-color: white;
`;

const StyledRow = styled(TableRow)`
  padding: 0 16px;
  &:hover {
    cursor: unset;
  }
`;

const TemplateName = styled(TableCol)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${COLOR_PALETTE.BLACK};
`;

const TemplateText = styled(TableCol)`
  font-size: 14px;
  line-height: 1.57;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const ContextMenuContainer = styled.div`
  width: min-content;
`;

const SuperAdminTemplateReview = ({ i18n }) => {
  const history = useHistory();
  const $loading = useBoolState(true);
  const [search, setSearch] = useState('');
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const templates = await getTemplatesSuperAdmin();
      setTemplates(Object.values(templates));
      $loading.off();
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  const cols = [
    {
      title: i18n._(t`Created at`),
    },
    {
      title: i18n._(t`Last updated`),
      width: '98px',
    },
    {
      width: '40px',
    },
  ];

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const createTemplate = () => {
    history.push(routes.SUPERADMIN_TEMPLATE_REVIEW_CREATE.url, { isSuperAdmin: true });
  };

  const searchTemplate = (template) => {
    return template.name.includes(search);
  };

  const renderRow = (template) => {
    const menuItems = [
      {
        label: i18n._(t`Update`),
        action: async () =>
          history.push(
            routes.SUPERADMIN_TEMPLATE_REVIEW_UPDATE.url({ reviewTemplateId: template.id }),
            { isSuperAdmin: true },
          ),
      },
      {
        label: i18n._(t`Delete`),
        action: async () => {
          if (
            await confirm(
              i18n,
              i18n._(
                t`Are you sure you want to PERMANENTLY delete the template "${template.name}" from the database? THIS ACTION CANNOT BE UNDONE!`,
              ),
            )
          ) {
            if (
              await confirm(
                i18n,
                i18n._(
                  t`Are you ENTIRELY SURE of PERMANENTLY DELETING the template "${template.name}"?`,
                ),
              )
            ) {
              await deleteTemplatesSuperAdmin(template.id);
              setTemplates(templates.filter((t) => t.id !== template.id));
            } else {
              alert(`Template ${template.name} will be retained.`);
            }
          } else {
            alert(`Template ${template.name} will be retained.`);
          }
        },
      },
      {
        label: i18n._(t`Duplicate`),
        action: async () => {
          const copy = await createTemplateSuperAdmin({
            ...template,
            name: getTemplateCopyName(template, templates),
          });
          setTemplates([...templates, copy]);
        },
      },
    ];
    return (
      <StyledRow key={template.id}>
        <TemplateName>{template.name}</TemplateName>
        <TemplateText>
          {convertToTimeString(template.meta.lastModifiedDate || template.meta.createdDate)}
        </TemplateText>
        <TableCol>
          <ContextMenuContainer>
            <IconMenu items={menuItems} />
          </ContextMenuContainer>
        </TableCol>
      </StyledRow>
    );
  };

  return (
    <>
      <OverviewHeading
        title={i18n._(t`Review templates`)}
        description={i18n._(t`Create default templates for all Learned admins to use`)}
      >
        <Button label={`+ ${i18n._(t`Template`)}`} onClick={createTemplate} />
      </OverviewHeading>
      <BaseLayout>
        <Wrapper>
          <ActionsContainerWrapper noBottomBorder>
            <StyledSearch
              placeholder={i18n._(t`Search`)}
              value={search}
              onChange={handleChangeSearch}
            />
          </ActionsContainerWrapper>
          <Divider />
          <StyledTable
            cols={cols}
            items={templates.filter(searchTemplate)}
            renderRow={renderRow}
            loading={$loading.value}
          />
        </Wrapper>
      </BaseLayout>
    </>
  );
};

export default withI18n()(SuperAdminTemplateReview);
