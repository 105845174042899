import React from 'react';

import * as PropTypes from 'prop-types';

import InlineSvg from '~/components/InlineSvg';

/**
 * @deprecated since version 3.0 {@see Icon}
 */
const ConversationForTimeline = ({ size, ...props }) => (
  <InlineSvg height={size} width={size} {...props} childrenFirst>
    <defs>
      <filter colorInterpolationFilters="auto" id="vcjwvpmzqa">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.117647 0 0 0 0 0.031373 0 0 0 0 0.388235 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M7.2 1.211h7.2v16.947H7.2z" />
      <g filter="url(#vcjwvpmzqa)">
        <g fill="#000" fillRule="nonzero">
          <path d="M21.89 2.875h-2.109v-.719C19.781.967 18.835 0 17.671 0H2.11C.946 0 0 .967 0 2.156v11.5c0 1.189.946 2.156 2.11 2.156h2.109v3.594c0 .641.76.958 1.2.508l1.2-1.227h7.95l4.012 4.102c.44.45 1.2.134 1.2-.508v-3.594h2.11c1.163 0 2.109-.967 2.109-2.156V5.03c0-1.189-.946-2.156-2.11-2.156zM5.626 17.67v-2.578a.711.711 0 0 0-.703-.718H2.109a.712.712 0 0 1-.703-.72V2.157c0-.396.316-.719.703-.719h15.563c.388 0 .703.323.703.72v11.499a.712.712 0 0 1-.703.719H9.14a.695.695 0 0 0-.498.21l-3.018 3.086zm16.969-1.14a.712.712 0 0 1-.703.719h-2.813a.711.711 0 0 0-.703.718v2.577l-3.018-3.085a.695.695 0 0 0-.498-.21H8.026l1.406-1.438h8.24c1.163 0 2.11-.967 2.11-2.156V4.312h2.109c.387 0 .703.323.703.72V16.53z" />
          <path d="M13.453 4.312H3.516a.711.711 0 0 0-.703.72c0 .396.314.718.703.718h9.937a.711.711 0 0 0 .703-.719.711.711 0 0 0-.703-.719zM16.266 7.187H3.516a.711.711 0 0 0-.703.719c0 .397.314.719.703.719h12.75a.711.711 0 0 0 .703-.719.711.711 0 0 0-.703-.719zM10.64 10.062H3.517a.711.711 0 0 0-.703.719c0 .397.314.719.703.719h7.125a.711.711 0 0 0 .703-.72.711.711 0 0 0-.703-.718z" />
        </g>
      </g>
    </g>
  </InlineSvg>
);

ConversationForTimeline.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

ConversationForTimeline.defaultProps = {
  size: 24,
  className: '',
};

export default ConversationForTimeline;
