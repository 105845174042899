import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  SURVEY_SORT_OPTIONS,
  SURVEY_STATUS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { FilterSurveyStatus } from '~/components/AutocompleteFilters/Survey';
import { Dropdown } from '~/components/Dropdown';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { CreateSurveyModal } from '~/components/Modals/CreateSurveyModal';
import { TableList } from '~/components/TableList';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { CreationInProcessModal } from '~/pages/Surveys/creationInProcessModal';
import { SURVEYS_COLUMNS } from '~/pages/Surveys/SurveysOverview/surveyColumns';

import routes from '~/constants/routes';
import { SURVEY_TYPE_WITH_NAMES } from '~/constants/survey';
import type { ISurveyType } from '~/constants/survey';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { createSurvey, deleteSurveys, getSurveys } from '~/services/surveys';

import type { ISurvey } from '@learned/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_SURVEYS_ORGANISATION;

const initialFilters = {
  isShowFilters: false,
  search: '',
  statuses: [],
  types: [],
  sortBy: SURVEY_SORT_OPTIONS.END_DATE_SOON_LATER,
  pagination: DEFAULT_PAGINATION,
};

const SurveysOverview = () => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();

  const [surveys, setSurveys] = useState<ISurvey[] | []>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateSurveyLoading, setIsCreateSurveyLoading] = useState(false);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300);
  const [isCreateSurveyModal, setIsCreateSurveyModal] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);

    const { data } = await getSurveys(
      {
        search: currentFilters.search,
        statuses: currentFilters.statuses.map((s: { key: string }) => s.key),
        types: currentFilters.types.map((s: { key: string }) => s.key),
      },
      {
        sortBy: currentFilters.sortBy,
        skip: currentFilters.pagination.skip,
        limit: currentFilters.pagination.limit,
      },
    );

    setSurveys(data[API_RETURN_FIELDS.SURVEYS]);
    setTotalCount(data[API_RETURN_FIELDS.TOTAL]);
    setIsLoading(false);
  };

  const forceFetch = fetchData;

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters)]);

  const onDelete = async (surveyId: string) => {
    const isConfirmed = (await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Are you sure you want to delete survey?`),
      description: i18n._(
        t`This would mean, that removing a survey will effect historic reporting and cannot be undone.`,
      ),
    })) as unknown as boolean;

    if (isConfirmed) {
      await deleteSurveys([surveyId]);
    }
  };

  const createMenuItems = (item: ISurvey) => {
    return [
      {
        label: i18n._(t`Edit`),
        action: () => {
          routes.SURVEY_UPDATE.go({}, { surveyId: item.id, isBackPath: true });
        },
        icon: ICONS.EDIT_PENCIL,
      },
      {
        label: i18n._(t`Delete`),
        action: async () => {
          await onDelete(item.id);
          await forceFetch();
          addToast({ title: 'Survey deleted', type: TOAST_TYPES.INFO });
        },
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ];
  };

  const onCreateSurvey = async (type: ISurvey['type']) => {
    try {
      setIsCreateSurveyLoading(true);
      const { data } = await createSurvey({ type });

      // redirect to update survey flow after creation
      if (!!data[API_RETURN_FIELDS.SURVEY] && data[API_RETURN_FIELDS.SURVEY]?.id) {
        routes.SURVEY_UPDATE.go(
          {},
          { surveyId: data[API_RETURN_FIELDS.SURVEY].id, isBackPath: true },
        );
      }
    } finally {
      setIsCreateSurveyLoading(false);
    }
  };

  const actionButton = {
    label: t`Create survey`,
    onClick: () => setIsCreateSurveyModal(true),
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value, pagination: DEFAULT_PAGINATION });
    },

    // @ts-ignore
    onChangeFilter: (key, value) => setCurrentFilters({ ...currentFilters, [key]: value }),
    resetFilters: () => setCurrentFilters(initialFilters),
    statuses: currentFilters.statuses,
    types: currentFilters.types,
  };

  const onSurveyClick = {
    column: 'name',
    onClick: (survey: ISurvey) => {
      survey.status === SURVEY_STATUS.DRAFT
        ? routes.SURVEY_UPDATE.go({}, { surveyId: survey.id, isBackPath: true })
        : routes.SURVEY_DASHBOARD.go({}, { surveyId: survey.id, isBackPath: true });
    },
  };

  return (
    <Wrapper>
      {isCreateSurveyLoading && (
        <CreationInProcessModal title={i18n._(t`Creating a new survey…`)} />
      )}
      <TableList
        data={surveys}
        columns={SURVEYS_COLUMNS}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: SURVEY_SORT_OPTIONS) =>
            setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        onColClick={onSurveyClick}
        isDraftStatusVisible
        menuProps={{
          createMenuItems,
          isMenuVisible: true,
        }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={isLoading}
        placeholderProps={{
          noResultText: i18n._(t`No surveys found`),
          emptyStateText: i18n._(t`No surveys yet… Let’s create one!`),
        }}
        actionButton={actionButton}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length || !!currentFilters.statuses.length,
          isToggleHideFilterVisible: true,
          resetFilters: filters.resetFilters,
          filterComponents: (
            <>
              <FilterSurveyStatus
                selectedItems={filters.statuses}
                onChange={(value) => filters.onChangeFilter('statuses', value)}
                type="survey"
              />
              <Dropdown
                placeholder={i18n._(t`Type`)}
                items={Object.values(SURVEY_TYPE_WITH_NAMES)}
                selectedItems={filters.types}
                onChange={(value: ISurveyType[]) => filters.onChangeFilter('types', value)}
                stringifyItem={(item) => item.translated(i18n)}
              />
            </>
          ),
        }}
      />
      {isCreateSurveyModal && (
        <CreateSurveyModal
          onClose={() => setIsCreateSurveyModal(false)}
          onSubmit={onCreateSurvey}
        />
      )}
    </Wrapper>
  );
};

export { SurveysOverview };
