import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class FocusAreaName extends Component {
  render = () => {
    const { focusArea, lang } = this.props;
    let name = focusArea.name;
    if (lang === 'nl' && focusArea.nameNL && focusArea.nameNL !== '') {
      name = focusArea.nameNL;
    }
    return <span>{name}</span>;
  };
}

const mapStateToProps = (state) => {
  return {
    lang: state.locale.lang,
  };
};

FocusAreaName.propTypes = {
  focusArea: PropTypes.object,
};

export default connect(mapStateToProps)(FocusAreaName);
