import React, { memo } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import FeedbackDeadline from '~/components/FeedbackDeadline';
import UserAvatar from '~/components/UI/Avatar';
import TaskStatus from '~/pages/ReviewUserPage/components/TaskStatus';

import { Item, ItemNameText } from './CommonStyledComponents';

import routes from '~/constants/routes';
import history from '~/utils/history';

const ItemWrapper = styled(Item)`
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  min-width: 110px;
  justify-content: flex-end;
`;

const Deadlines = styled.div`
  display: flex;
  width: 160px;
  align-items: center;
`;

const SELF_REVIEW_STATUSES = {
  OPEN: {
    key: 'open',
    label: (i18n) => i18n._(t`Start`),
    buttonType: Button.types.primary,
  },
  ACTIVE: {
    key: 'active',
    label: (i18n) => i18n._(t`Continue`),
    buttonType: Button.types.primaryBorder,
  },
  DONE: {
    key: 'done',
    label: (i18n) => i18n._(t`Update`),
    buttonType: Button.types.linkPrimary,
  },
};

const UserSelfReviewItem = ({
  review,
  isDisabled,
  isLocked,
  lockedHint,
  isActionDisabled,
  actionTooltip,
}) => {
  const { i18n } = useLingui();

  const getSelfReviewStatus = () => {
    if (review.isSelfFeedbackProvided) {
      return SELF_REVIEW_STATUSES.DONE;
    } else if ((review.ratings || []).find((x) => x.type === 1)) {
      return SELF_REVIEW_STATUSES.ACTIVE;
    } else {
      return SELF_REVIEW_STATUSES.OPEN;
    }
  };

  const selfReviewStatus = getSelfReviewStatus();

  const openReviewSelfFeedback = () => {
    history.push(
      routes.REVIEW_GIVE_FEEDBACK_SELF.build(
        {},
        {
          reviewId: review.id,
          isBackPath: true,
        },
      ),
    );
  };

  const isTaskCompleted = selfReviewStatus.key === SELF_REVIEW_STATUSES.DONE.key;

  return (
    <ItemWrapper>
      <Row>
        <UserAvatar size={28} userId={review.createdFor} isGray={isDisabled} />
        <ItemNameText $disabled={isDisabled} $completed={isTaskCompleted}>
          <Trans>Prepare for your review</Trans>
        </ItemNameText>
        {isLocked && lockedHint}
      </Row>
      <Row>
        {!isLocked && !isTaskCompleted && (
          <Deadlines>
            <FeedbackDeadline deadline={review.deadlineSelfReview} width="29px" height="28px" />
          </Deadlines>
        )}
        <Actions>
          {isLocked ? (
            <TaskStatus
              status={isTaskCompleted ? TaskStatus.STATUSES.COMPLETED : TaskStatus.STATUSES.EXPIRED}
            />
          ) : (
            !isDisabled && (
              <Button
                type={selfReviewStatus.buttonType}
                label={selfReviewStatus.label(i18n)}
                onClick={openReviewSelfFeedback}
                styles={{ minWidth: '83px', padding: 0 }}
                disabled={isLocked || isActionDisabled}
                tooltip={actionTooltip}
              />
            )
          )}
          {}
        </Actions>
      </Row>
    </ItemWrapper>
  );
};

UserSelfReviewItem.propTypes = {
  currentRole: PropTypes.string,
  review: PropTypes.object,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  lockedHint: PropTypes.node,
  isActionDisabled: PropTypes.bool,
  actionTooltip: PropTypes.string,
};

export default memo(UserSelfReviewItem);
