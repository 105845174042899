import React, { useRef } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import Button from '~/components/Button';
import DropdownButton from '~/components/DropdownButton';
import ExpandMoreIcon from '~/components/Icons/ExpandMore';

import { COLOR_PALETTE, COLORS } from '~/styles';

const Options = styled.div`
  display: flex;
  flex-direction: column;
`;

const Option = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLOR_PALETTE.BLACK};
  cursor: pointer;
  padding: 12px 24px;
  border-bottom: solid 1px ${COLOR_PALETTE.GRAY_MIDDLE};

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: ${COLORS.BG_PAGE};
  }

  &:active {
    background-color: ${COLORS.HOVER_ACTIVE};
  }
`;

const OptionsMenu = ({ options, loading }) => {
  const { i18n } = useLingui();
  const popoverRef = useRef();

  const closeOptions = () => {
    popoverRef.current._tippy.hide();
  };

  const onOptionClick = (option) => {
    // apply action
    option.onClick();

    // close menu
    closeOptions();
  };

  return (
    <DropdownButton
      maxWidth={526}
      popoverRef={popoverRef}
      disabled={loading}
      content={
        <div>
          <Options>
            {options.map((option, i) => {
              return (
                <Option onClick={() => onOptionClick(option)} key={i}>
                  {option.label(i18n)}
                </Option>
              );
            })}
          </Options>
        </div>
      }
    >
      <Button
        label={
          <>
            {i18n._(t`Options`)}
            <ExpandMoreIcon fill="#fff" />
          </>
        }
        loading={loading}
      />
    </DropdownButton>
  );
};

export default OptionsMenu;
