import React from 'react';

import { Trans } from '@lingui/macro';
import size from 'lodash/size';
import styled from 'styled-components';

import CheckBox from '~/components/CheckBox';

import { COLOR_PALETTE } from '~/styles';

const SelectAllWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px 16px 0;
  cursor: pointer;
  height: 20px;
`;

const SelectAllLabel = styled.div`
  font-size: 14px;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const SelectAll = ({ items, itemsSelected, updateSelectedItems }) => {
  const isChecked = size(itemsSelected) > 0 && size(items) === size(itemsSelected);
  const selectAll = (allItems) => {
    // transform array to object
    // selectedActivities - has type object
    const allItemsObject = allItems.reduce((obj, item) => ({ ...obj, [item.id]: item }), {});
    updateSelectedItems(allItemsObject);
  };
  const deselectAll = () => updateSelectedItems({});

  return (
    <SelectAllWrapper onClick={() => (isChecked ? deselectAll() : selectAll(items))}>
      <SelectAllLabel>
        <Trans>Select all</Trans>
      </SelectAllLabel>
      <CheckBox checked={isChecked} size={'24px'} />
    </SelectAllWrapper>
  );
};

export default SelectAll;
