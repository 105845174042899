import React, { useState } from 'react';

import { IReviewTemplate } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import CheckBox from '~/components/CheckBox';
import Modal from '~/components/Modal';

import {
  ModalHeader,
  ModalSubHeader,
  StyledSearch,
  ButtonContainer,
  CloseButton,
  AddButton,
  CycleOrderWrapper,
  TemplateTitle,
  TemplateHeader,
  TemplatesWrapper,
} from './styling';
import { TemplateRow } from './TemplateRow';

import { COLORS } from '~/styles';

type LearnedTemplatesModalProps = {
  onClose: () => void;
  templates: any[];
  duplicateTemplate: (arg0: IReviewTemplate) => void;
};

const LearnedTemplatesModal = ({
  onClose,
  templates,
  duplicateTemplate,
}: LearnedTemplatesModalProps) => {
  const { i18n } = useLingui();
  const [checkedTemplates, setCheckedTemplates] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const checkBoxChecked = checkedTemplates.length === templates.length;

  const addTemplate = async () => {
    await Promise.all(
      checkedTemplates.map((i) => {
        const template = templates.find((t) => t.id === i);
        if (template) {
          return duplicateTemplate(template);
        }
      }),
    );
    onClose();
  };

  return (
    <Modal
      title={i18n._(t`Add conversation template`)}
      isHideHeader
      contentStyles={{ padding: '22px 40px 32px 40px' }}
      hideFooter
      onClose={onClose}
      centerModal
      minWidth={750}
      width={750}
    >
      <ModalHeader>{i18n._(t`Import template`)}</ModalHeader>
      <ModalSubHeader>{i18n._(t`Select the template(s) you want to import`)}</ModalSubHeader>
      <StyledSearch
        placeholder={i18n._(t`Search`)}
        value={search}
        onChange={(e: any) => setSearch(e.target.value)}
      />
      <TemplateHeader>
        <CycleOrderWrapper>
          <CheckBox
            size={20}
            checked={checkBoxChecked}
            defaultColor={
              checkedTemplates.length && checkedTemplates.length === templates.length
                ? 'var(--company-color)'
                : COLORS.MIDDLE_GRAY
            }
            isDefaultColor={true}
            onChange={() =>
              setCheckedTemplates(checkBoxChecked ? [] : templates.map((i) => i.id) || [])
            }
          />
          <TemplateTitle>{i18n._(t`Templates`)}</TemplateTitle>
        </CycleOrderWrapper>
      </TemplateHeader>
      <TemplatesWrapper>
        {templates
          .filter((i) => i.name.toLowerCase().includes(search.toLowerCase()))
          .map((i) => {
            const isChecked = checkedTemplates.includes(i.id);
            return (
              <TemplateRow
                key={i.id}
                hoverstate
                isInModal
                template={i}
                disableClick
                isConversationTemplate={true}
                isChecked={isChecked}
                checkTemplate={() =>
                  setCheckedTemplates(
                    isChecked
                      ? checkedTemplates.filter((checked) => checked !== i.id)
                      : [...checkedTemplates, i.id],
                  )
                }
              />
            );
          })}
      </TemplatesWrapper>
      <ButtonContainer>
        <CloseButton onClick={onClose}>{i18n._(t`Close`)}</CloseButton>
        <AddButton onClick={addTemplate}>{i18n._(t`Import template(s)`)}</AddButton>
      </ButtonContainer>
    </Modal>
  );
};

export { LearnedTemplatesModal };
