import React from 'react';

import { Trans } from '@lingui/macro';

import GoogleMeetIcon from '~/assets/google-meet.svg';

function GoogleMeet() {
  return (
    <>
      <img src={GoogleMeetIcon} alt="Google Meet" />
      <Trans>Google Meet</Trans>
    </>
  );
}

export { GoogleMeet };
