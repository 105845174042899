import React from 'react';

import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import { Header6Dark } from '~/components/UI/Typographics/headers';

import BoardItem from './BoardItem';

const ValueRow = styled.div`
  font-size: 30px;
  font-weight: 600;
  color: var(--company-color);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const BoardValue = ({ title, value, icon }) => {
  return (
    <BoardItem>
      <ValueRow>
        {icon}
        {value}
      </ValueRow>
      <Header6Dark>{title}</Header6Dark>
    </BoardItem>
  );
};

BoardValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default React.memo(BoardValue);
