import { createReducer } from 'redux-act';

import * as companyActions from '~/store/companies/actions';

const INITIAL_STATE = {
  data: {},
  loading: false,
};

export const companies = createReducer(
  {
    [companyActions.finishGetCompanies]: (state, payload) => {
      return {
        ...state,
        data: payload,
        loading: false,
      };
    },

    [companyActions.finishGetCompany]: (state, payload) => {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: payload,
        },
        loading: false,
      };
    },
    [companyActions.setCompanyNinegridLabels]: (state, payload) => {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.companyId]: {
            ...state.data[payload.companyId],
            ninegridLabels: payload.ninegridLabels,
          },
        },
      };
    },
    [companyActions.setCompanyPrimaryLang]: (state, payload) => {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.companyId]: {
            ...state.data[payload.companyId],
            primaryLang: payload.lang,
          },
        },
      };
    },
    [companyActions.removeLang]: (state, payload) => {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.companyId]: {
            ...state.data[payload.companyId],
            languages: state.data[payload.companyId].languages.filter(
              (i) => i.locale !== payload.lang.locale,
            ),
          },
        },
      };
    },
    [companyActions.addLangs]: (state, payload) => {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.companyId]: {
            ...state.data[payload.companyId],
            languages: [...state.data[payload.companyId].languages, ...payload.langs],
          },
        },
      };
    },
    [companyActions.addPrimaryLang]: (state, payload) => {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.companyId]: {
            ...state.data[payload.companyId],
            languages: [...state.data[payload.companyId].languages, payload.lang],
            primaryLang: payload.lang,
          },
        },
      };
    },
    [companyActions.updateProductSettings]: (state, payload) => {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.companyId]: {
            ...state.data[payload.companyId],
            products: {
              ...state.data[payload.companyId].products,
              [payload.name]: {
                ...state.data[payload.companyId].products[payload.name],
                settings: payload.settings,
              },
            },
          },
        },
      };
    },
    [companyActions.updateProducts]: (state, payload) => {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.companyId]: {
            ...state.data[payload.companyId],
            products: payload.products,
          },
        },
      };
    },
  },
  INITIAL_STATE,
);
