interface ILanguage {
  language: string;
  country: string;
  locale: string;
}

const SYSTEM_LANG = {
  en: {
    key: 'en',
    language: 'English',
    country: 'Great Britain',
    locale: 'en_GB',
  },
  nl: {
    key: 'nl',
    language: 'Dutch',
    country: 'Netherlands',
    locale: 'nl_NL',
  },
};

const SUPER_ADMIN_LANGUAGES = [
  {
    language: 'English',
    country: 'Great Britain',
    locale: 'en_GB',
  },
  {
    language: 'Dutch',
    country: 'Netherlands',
    locale: 'nl_NL',
  },
  {
    language: 'German',
    country: 'Germany',
    locale: 'de_DE',
  },
];

const languages: ILanguage[] = [
  {
    language: 'Bulgarian',
    country: 'Bulgaria',
    locale: 'bg_BG',
  },
  {
    language: 'Croatian',
    country: 'Croatia',
    locale: 'hr_HR',
  },
  {
    language: 'Czech',
    country: 'Czechia',
    locale: 'cs_CZ',
  },
  {
    language: 'Danish',
    country: 'Denmark',
    locale: 'da_DK',
  },
  {
    language: 'Dutch',
    country: 'Netherlands',
    locale: 'nl_NL',
  },
  {
    language: 'English',
    country: 'Great Britain',
    locale: 'en_GB',
  },
  {
    language: 'Finnish',
    country: 'Finland',
    locale: 'fi_FI',
  },
  {
    language: 'French',
    country: 'France',
    locale: 'fr_FR',
  },
  {
    language: 'German',
    country: 'Germany',
    locale: 'de_DE',
  },
  {
    language: 'Greek',
    country: 'Greece',
    locale: 'el_GR',
  },
  {
    language: 'Hungarian',
    country: 'Hungary',
    locale: 'hu_HU',
  },
  {
    language: 'Italian',
    country: 'Italy',
    locale: 'it_IT',
  },
  {
    language: 'Latvian',
    country: 'Latvia',
    locale: 'lv_LV',
  },
  {
    language: 'Lithuanian',
    country: 'Lithuania',
    locale: 'lt_LT',
  },
  {
    language: 'Norwegian',
    country: 'Norway',
    locale: 'nb_NO',
  },
  {
    language: 'Polish',
    country: 'Poland',
    locale: 'pl_PL',
  },
  {
    language: 'Portuguese',
    country: 'Portugal',
    locale: 'pt_PT',
  },
  {
    language: 'Romanian',
    country: 'Romania',
    locale: 'ro_RO',
  },
  {
    language: 'Russian',
    country: 'Russia',
    locale: 'ru_RU',
  },
  {
    language: 'Serbian',
    country: 'Serbia',
    locale: 'sr_RS',
  },
  {
    language: 'Slovak',
    country: 'Slovakia',
    locale: 'sk_SK',
  },
  {
    language: 'Spanish',
    country: 'Spain',
    locale: 'es_ES',
  },
  {
    language: 'Swedish',
    country: 'Sweden',
    locale: 'sv_SE',
  },
  {
    language: 'Turkish',
    country: 'Turkey',
    locale: 'tr_TR',
  },
  {
    language: 'Ukrainian',
    country: 'Ukraine',
    locale: 'uk_UA',
  },
];

export type { ILanguage };
export { languages, SYSTEM_LANG, SUPER_ADMIN_LANGUAGES };
