import React from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { connect, useDispatch } from 'react-redux';

import { confirm } from '~/components/ConfirmDialog';

import { SectionHeader } from './SectionHeader';
import { SectionList } from './SectionList';

import { ROLES } from '~/constants';
import { updateAdminStatus } from '~/services/users';
import { updateCompanyConnection } from '~/store/companyConnections/actions';

function SectionAdminRights({ i18n, userId, user, companyConnections }) {
  const dispatch = useDispatch();
  const userConnectionWithCompany =
    Object.values(companyConnections).find((conn) => conn.user === userId) || {};

  let roles = [];
  if (!isEmpty(userConnectionWithCompany) && !isEmpty(userConnectionWithCompany.roles)) {
    roles = [...userConnectionWithCompany.roles];
  }
  const isAdmin = roles.includes(ROLES.ADMIN);

  const toggleAdminRole = async () => {
    const setIsAdmin = !isAdmin;
    // confirm
    const confirmMessageMakeAdmin = i18n._(t`Are you sure you want to make this user admin?`);
    const confirmMessageRevokeAdmin = i18n._(
      t`Are you sure want to remove this user as admin of ALL his teams?`,
    );
    if (!(await confirm(i18n, setIsAdmin ? confirmMessageMakeAdmin : confirmMessageRevokeAdmin))) {
      return;
    }

    const connection = {
      ...userConnectionWithCompany,
    };
    if (!connection) {
      return;
    }

    await updateAdminStatus(userId, setIsAdmin);

    // Local update for speed
    if (setIsAdmin) {
      connection.roles = uniq(connection.roles.concat([ROLES.ADMIN]));
    } else {
      connection.roles = connection.roles.filter((role) => role !== ROLES.ADMIN);
    }
    dispatch(updateCompanyConnection(connection));
  };

  const items = [
    {
      id: 0,
      name: isAdmin
        ? i18n._(
            t`Make this user an admin. This user will gain access to all Learned admin functionalities.`,
          )
        : i18n._(
            t`Make this user an admin. This user will gain access to all Learned admin functionalities.`,
          ),
    },
  ];

  return (
    <div>
      <SectionHeader title={i18n._(t`Admin rights`)} description={''} />
      <SectionList
        items={items}
        itemActionLabel={isAdmin ? i18n._(t`Disabled`) : i18n._(t`Enabled`)}
        itemActionOnClick={toggleAdminRole}
        itemActionDisabled={user.id === userId}
        isAdmin={isAdmin}
        displaySwitch={true}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    currentRole: state.selected.role,
    companyConnections: state.companyConnections,
  };
};

export default withI18n()(connect(mapStateToProps)(SectionAdminRights));
