import React, { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router';

import HeadingNavigation from '~/components/HeadingNavigation';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import BaseLayout from '~/layouts/BaseLayout';

import Content from './Content';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getCareerPlans, getUserCareerPlans } from '~/services/careerPlans';
import { getJobProfile } from '~/services/jobProfiles';

function RolePage({ match }) {
  const { i18n } = useLingui();
  const params = useParams();
  const [jobProfile, setJobProfile] = useState();
  const userId = get(params, 'userId');
  const readOnly = Boolean(userId);

  const loading = useBoolState(true);
  const isCurrentRole = useBoolState(false);

  useEffect(() => {
    async function retrieveData() {
      // Download job profile, focus areas and like for this job profile.
      const [jp] = await Promise.all([getJobProfile(match.params.roleId, ['skills'])]);

      // Check if job profile is part of current career plans.
      const careerPlans = userId ? await getUserCareerPlans(userId) : await getCareerPlans();
      isCurrentRole.set(
        Object.values(careerPlans)
          .map((careerPlan) => careerPlan.jobProfile)
          .includes(jp.id),
      );

      // Determine highest level for each skill
      if (!isEmpty(jp.skills)) {
        jp.skills.forEach((skill) => {
          skill.maxLevel = skill.levelsEnabled.filter((l) => l).length;
          skill.numberOfEnabledLevels = [];
          skill.levelsEnabled.forEach((l, i) => {
            if (l) {
              skill.numberOfEnabledLevels = [...skill.numberOfEnabledLevels, i + 1];
            }
          });
        });
      }

      // Save data in local state
      setJobProfile(jp);

      // Done!
      loading.off();
    }
    if (!jobProfile) {
      retrieveData();
    }
  }, [isCurrentRole, loading, match.params.roleId, userId, jobProfile]);

  let backPath;
  let href = window.location.href;
  let url = new URL(href);
  let from = url.searchParams.get('from');

  if (from) {
    backPath = window.location.hash.substr(1) ? from + '#' + window.location.hash.substr(1) : from;
  }

  const onBack = () => {
    routes.USER_PUBLIC_SKILL_PASSPORT.go(
      {},
      { userId, isBackPath: true, query: { jobProfileId: match.params.roleId }, hash: 'career' },
    );
  };

  return (
    <>
      {jobProfile && (
        <HeadingNavigation
          label={i18n._(t`Role library`)}
          backPath={backPath}
          onBack={readOnly && onBack}
          smallWidth
        />
      )}
      <BaseLayout loading={loading.value} smallWidth>
        {jobProfile && (
          <BoxWithBorder>
            <Content userId={userId} jobProfile={jobProfile} />
          </BoxWithBorder>
        )}
      </BaseLayout>
    </>
  );
}

export default RolePage;
