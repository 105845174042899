import React, { useState, useEffect, useRef } from 'react';

import { CAREER_PLAN_STATUSES, CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import DropdownButton from '~/components/DropdownButton';
import ExpandMoreIcon from '~/components/Icons/ExpandMore';
import AddRoleModal from '~/components/Modals/AddRoleModal';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { SkillFullInfoModal } from '~/components/Modals/SkillFullInfoModal';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import SkillDefinitionModal from '~/components/SkillDefinitionModal';
import SvgIcon from '~/components/SvgIcon';
import Shadow from '~/components/UI/Shadow';

import LearningSection from './components/LearningSection';
import { SkillsSection } from './components/SkillsSection';

import MarketplaceIcon from '~/assets/main-menu-icons/path.svg';

import { JOB_PROFILE_STATUSES, ROLES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import {
  checkModuleSkillPassport,
  getCurrentCompanyId,
  getSelectedRole,
  getUser,
  isLearningCareerModuleEnabled,
  isModuleAmbitionsEnabled,
  isUserRoleAssignModuleEnabled,
  checkModuleLearning,
} from '~/selectors/baseGetters';
import { createCareerPlansForUser, deleteCareerPlan, getCareerPlans } from '~/services/careerPlans';
import { getCompanySkillCategories } from '~/services/companySettings';
import { getJobProfiles } from '~/services/jobProfiles';
import { createLike, createLikes, getLikes, getUserLikes } from '~/services/likes';
import { getSkillCoverage } from '~/services/ratings';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkill } from '~/services/skills';
import { COLORS, COLOR_PALETTE, COLOR_SET } from '~/styles';

const CareerBoard = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 50px;
`;

const CareerBoardHeader = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  min-height: 56px;
  padding: 16px 20px 12px;
`;

const Content = styled(Shadow)`
  display: flex;
  width: 100%;
`;

const JobHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;

  a {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.25px;
  }
`;

function mapLikes(likes) {
  const result = {};
  Object.values(likes).forEach((like) => {
    result[like.jobProfile] = like.value;
  });
  return result;
}

const StyledButton = styled(Button)`
  width: 400px;
  height: 40px;
  margin: 0 13px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  border: solid 1px #dae4f7;
  background-color: #fff;
  &:hover {
    background-color: #fff;
  }
`;
const ButtonLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  color: ${COLOR_SET.TOTAL_BLACK};
`;

const ExpandContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonText = styled.div`
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CurrentRoleLabel = styled.div`
  width: 82px;
  height: 22px;
  margin: 3px 4px 2px 7px;
  padding: 2px 5px 3px;
  border-radius: 3px;
  background-color: ${COLORS.BG_PAGE};
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const CurrentRoleDropdown = styled.div`
  width: 400px;
  margin: 0 0 0 1px;
  border-radius: 6px;
  box-shadow: 0 2px 30px 0 rgba(107, 120, 164, 0.3);
  border: solid 1px #dae4f7;
  background-color: #fff;
  overflow: hidden;
`;

const RoleRow = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
  background-color: ${(props) => (props.active ? COLORS.BG_PAGE : '#fff')};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;

const ActiveRoleRowLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--company-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const RoleRowLabel = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLOR_SET.TOTAL_BLACK};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const CurrentRolesLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: ${COLOR_PALETTE.DARK_GRAY};
`;

const AmbitionsMenu = styled.div`
  margin-top: 8px;
`;

const AmbitionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  cursor: default;
  & button {
    font-weight: 600;
    padding: 8px;
    width: 100%;
  }
`;

const RemoveAmbitionsContainer = styled.div`
  border-top: 1px solid #ebf1fe;
  padding: 16px 40px 24px 40px;
`;

const IS_ADD_ROLE_MODAL_VISIBLE = 'isAddRoleModalVisible';
const IS_ADD_AMBITION_MODAL_VISIBLE = 'isAddAmbitionModalVisible';

function UserCareer({ i18n, userId }) {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [currentJobRoles, setCurrentJobRoles] = useState([]);
  const [allCareerPlans, setAllCareerPlans] = useState({});
  const [savedJobRoles, setSavedJobRoles] = useState([]);
  const [skillCategories, setSkillCategories] = useState([]);
  const [activeJobRole, setActiveJobRole] = useState(null);
  const [activeSkill, setActiveSkill] = useState(null);
  const [skillFromUrlShown, setSkillFromUrlShown] = useState(false);
  const [skillCoverage, setSkillCoverage] = useState(null);
  const $loading = useBoolState();
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const selectedRole = useSelector(getSelectedRole);
  const isUserRoleModule = useSelector(isUserRoleAssignModuleEnabled);
  const selectedUser = useSelector(getUser);
  const skillLabels = useSelector((state) => state.companySettings.skillLabels);
  const isAmbitionsEnabled = useSelector(isModuleAmbitionsEnabled);
  const isModuleSkillPassportEnabled = useSelector(checkModuleSkillPassport);
  const isLearningCareerEnabled = useSelector(isLearningCareerModuleEnabled);
  const isModuleLearningEnabled = useSelector(checkModuleLearning);
  const $showSkillModal = useBoolState();
  const popoverRef = useRef();
  const readOnly = Boolean(userId);
  const $isAddRoleShowing = useBoolState(query[IS_ADD_ROLE_MODAL_VISIBLE]);
  const $isAddAmbitionShowing = useBoolState(query[IS_ADD_AMBITION_MODAL_VISIBLE]);
  const [fetch, setFetch] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async (jobProfileId) =>
      await getSkillCoverage({
        jobProfileId,
        userId: userId || activeJobRole?.createdFor || selectedUser?.id,
      });
    if (activeJobRole?.jobProfile?.id) {
      fetchData(activeJobRole.jobProfile.id).then((res) => {
        setSkillCoverage(res.data);
      });
    } else {
      if (activeJobRole?.id) {
        fetchData(activeJobRole.id).then((res) => {
          setSkillCoverage(res.data);
        });
      }
    }
  }, [activeJobRole, selectedUser?.id, userId]);

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      if (!fetch) {
        $loading.on();
      }
      const [careerPlans, likes, jobProfiles, skillCategories, categoryOrder] = await Promise.all([
        selectedRole === ROLES.USER ? getCareerPlans() : getCareerPlans({ createdFor: userId }),
        selectedRole === ROLES.USER ? getLikes() : getUserLikes(userId),
        getJobProfiles({ status: JOB_PROFILE_STATUSES.ACTIVE.key }, ['skills']),
        getSkillCategories(),
        getCompanySkillCategories(currentCompanyId),
      ]);

      const currentRoles = filter(careerPlans, (role) => {
        return (
          role.status === CAREER_PLAN_STATUSES.CURRENT &&
          role.jobProfile &&
          jobProfiles[role.jobProfile]
        );
      })
        .filter((r) => r)
        .map((r) => {
          r.jobProfile = jobProfiles[r.jobProfile];
          return r;
        });

      currentRoles.sort((a, b) => a.name.localeCompare(b.name));

      const mapedLikes = mapLikes(likes);
      const savedRoles = filter(jobProfiles, (jobProfile) => {
        return (
          mapedLikes[jobProfile.id] &&
          mapedLikes[jobProfile.id] === 1 &&
          !currentRoles.find((r) => r.jobProfile.id === jobProfile.id)
        );
      }).sort((a, b) => a.name.localeCompare(b.name));

      let activeJob = currentRoles[0] || savedRoles[0];
      const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (selectedRole !== ROLES.USER && query.jobProfileId) {
        activeJob =
          currentRoles.find((r) => r.jobProfile.id === query.jobProfileId) ||
          savedRoles.find((r) => r.id === query.jobProfileId);
      }
      if (window.location.hash.substr(1) && selectedRole === ROLES.USER) {
        const jobId = window.location.hash.substr(1);
        activeJob =
          currentRoles.find((r) => r.jobProfile.id === jobId) ||
          savedRoles.find((r) => r.id === jobId);
      }

      const orderedCategories = categoryOrder.map((c) => skillCategories[c]);

      const skillIdFromUrl = new URL(window.location).searchParams.get('skill');
      let skill;
      if (skillIdFromUrl) {
        skill = await getSkill(skillIdFromUrl);
      }

      if (isMounted) {
        if (skill && !skillFromUrlShown) {
          setActiveSkill(skill);
          $showSkillModal.on();
          setSkillFromUrlShown(true);
        }
        setSkillCategories(orderedCategories);
        if (!fetch || !activeJobRole) {
          setActiveJobRole(activeJob);
        }
        setCurrentJobRoles(currentRoles);
        setSavedJobRoles(savedRoles);
        setAllCareerPlans(jobProfiles);
        $loading.off();
      }
    }

    fetchData();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [fetch, userId]);

  const onSkillClick = (skill) => {
    const skillToSelect = { ...skill };
    const jobSkillLevels = activeJobRole.jobProfile
      ? activeJobRole.jobProfile.skillsLevels
      : activeJobRole.skillsLevels;
    if (!isEmpty(jobSkillLevels) && jobSkillLevels[skill.id]) {
      skillToSelect.expectedLevel = jobSkillLevels[skill.id];
    }
    setActiveSkill(skillToSelect);
    $showSkillModal.on();
  };

  const goToSkillPassport = () => {
    $showSkillModal.off();
    routes.PROFILE.go({}, { isBackPath: true, query: { skill: activeSkill.id } });
  };

  const updateUrlQuery = (query) => {
    history.replace({
      search: location.search ? `${location.search}&${query}` : `?${query}`,
      hash: location.hash,
    });
  };

  const careerPlans = () => {
    $isAddRoleShowing.on();
    popoverRef.current._tippy.hide();
    updateUrlQuery(`${IS_ADD_ROLE_MODAL_VISIBLE}=true`);
  };

  const addAmbitionModal = () => {
    $isAddAmbitionShowing.on();
    popoverRef.current._tippy.hide();
    updateUrlQuery(`${IS_ADD_AMBITION_MODAL_VISIBLE}=true`);
  };

  const onCloseRoleModal = () => {
    $isAddRoleShowing.off();
    $isAddAmbitionShowing.off();
    // remove IS_ADD_ROLE_MODAL_VISIBLE from url query
    // remove IS_ADD_AMBITION_MODAL_VISIBLE from url query
    const regex = new RegExp(
      `(&|\\?)${IS_ADD_ROLE_MODAL_VISIBLE}=true|(&|\\?)${IS_ADD_AMBITION_MODAL_VISIBLE}=true`,
      'i',
    );
    history.replace({
      search: location.search.replace(regex, ''),
      hash: location.hash,
    });
  };

  const renderDropdown = () => {
    return (
      <CurrentRoleDropdown>
        {!isEmpty(currentJobRoles) && (
          <>
            <RoleRow>
              <CurrentRolesLabel>
                <Trans>Current role(s)</Trans>
              </CurrentRolesLabel>
            </RoleRow>
            {currentJobRoles.map((role) => {
              return (
                <RoleRow
                  onClick={
                    activeJobRole?.id === role.id
                      ? undefined
                      : () => {
                          popoverRef.current._tippy.hide();
                          setActiveJobRole(role);
                        }
                  }
                  key={role.id}
                  active={activeJobRole?.id === role.id}
                >
                  {activeJobRole?.id === role.id ? (
                    <ActiveRoleRowLabel>{role.name}</ActiveRoleRowLabel>
                  ) : (
                    <RoleRowLabel>{role.name}</RoleRowLabel>
                  )}
                </RoleRow>
              );
            })}
          </>
        )}
        {selectedRole === ROLES.USER && isUserRoleModule && (
          <AmbitionsRow>
            <Button
              label={<>+ {i18n._(t`Current role`)}</>}
              height={40}
              type="primary-border"
              onClick={careerPlans}
            />
          </AmbitionsRow>
        )}
        {isAmbitionsEnabled && (
          <AmbitionsMenu>
            {!!savedJobRoles.length && (
              <RoleRow>
                <CurrentRolesLabel>
                  <Trans>Ambition(s)</Trans>
                </CurrentRolesLabel>
              </RoleRow>
            )}
            {savedJobRoles.map((role) => {
              return (
                <RoleRow
                  onClick={
                    activeJobRole?.id === role.id
                      ? undefined
                      : () => {
                          popoverRef.current._tippy.hide();
                          setActiveJobRole(role);
                        }
                  }
                  key={role.id}
                  active={activeJobRole?.id === role.id}
                >
                  <RoleRowLabel>{role.name}</RoleRowLabel>
                </RoleRow>
              );
            })}
            {selectedRole === ROLES.USER && (
              <AmbitionsRow>
                <Button
                  label={<>+ {i18n._(t`Ambition`)}</>}
                  height={40}
                  type="primary-border"
                  onClick={addAmbitionModal}
                />
              </AmbitionsRow>
            )}
          </AmbitionsMenu>
        )}
      </CurrentRoleDropdown>
    );
  };

  const addRoles = async (jobProfiles) => {
    await createCareerPlansForUser(jobProfiles);
    setFetch(fetch + 1);
  };

  const addAmbitions = async (ids) => {
    await createLikes(ids.map((i) => ({ jobProfileId: i, value: 1 })));
    setFetch(fetch + 1);
  };

  const removeAmbition = async (id) => {
    await createLike(id, -1);
    const newSavedJobRoles = savedJobRoles.filter((i) => i.id !== id);
    setSavedJobRoles(newSavedJobRoles);
    setActiveJobRole(currentJobRoles[0] || newSavedJobRoles[0]);
  };

  const removeCareer = async (id) => {
    await deleteCareerPlan(id);
    const newJobRoles = currentJobRoles.filter((i) => i.id !== id);
    setCurrentJobRoles(newJobRoles);
    setActiveJobRole(newJobRoles[0] || savedJobRoles[0]);
  };

  const jobProfilesForAddRoleModal = {};
  Object.values(allCareerPlans).map((jobProfile) => {
    const existingRole = currentJobRoles.find((i) => i.jobProfile.id === jobProfile.id);
    const existingAmbition = savedJobRoles.find((i) => i.id === jobProfile.id);
    if (!existingRole && (!$isAddAmbitionShowing.value || !existingAmbition)) {
      jobProfilesForAddRoleModal[jobProfile.id] = jobProfile;
    }
  });

  return (
    <Content>
      <ShowSpinnerIfLoading loading={$loading.value}>
        <CareerBoard>
          <CareerBoardHeader>
            {(!isEmpty(activeJobRole) || isAmbitionsEnabled) && (
              <JobHeader>
                <DropdownButton
                  minWidth={400}
                  content={renderDropdown()}
                  popoverRef={popoverRef}
                  placement="bottom"
                >
                  <StyledButton
                    buttonContentStyles={{ width: '100%' }}
                    label={
                      <ButtonLabel>
                        <ButtonText>
                          {!isEmpty(activeJobRole) ? (
                            activeJobRole.name
                          ) : (
                            <Trans>Select a role</Trans>
                          )}
                        </ButtonText>
                        {currentJobRoles.find((i) => i.id === activeJobRole?.id) && (
                          <CurrentRoleLabel>
                            <Trans>Current role</Trans>
                          </CurrentRoleLabel>
                        )}
                        {savedJobRoles?.find((i) => i.id === activeJobRole?.id) && (
                          <CurrentRoleLabel>
                            <Trans>Ambition</Trans>
                          </CurrentRoleLabel>
                        )}
                        <ExpandContainer>
                          <ExpandMoreIcon fill="#000000" />
                        </ExpandContainer>
                      </ButtonLabel>
                    }
                  />
                </DropdownButton>
                {!isEmpty(activeJobRole) && (
                  <Link
                    to={
                      selectedRole === ROLES.USER
                        ? routes.JOB_PROFILE.build(
                            {},
                            {
                              roleId:
                                (activeJobRole.jobProfile && activeJobRole.jobProfile.id) ||
                                activeJobRole.id,
                              isBackPath: true,
                            },
                          )
                        : routes.JOB_PROFILE_SUPERVISION_USER.build(
                            {},
                            {
                              roleId:
                                (activeJobRole.jobProfile && activeJobRole.jobProfile.id) ||
                                activeJobRole.id,
                              userId,
                              isBackPath: true,
                            },
                          )
                    }
                    style={{ color: 'var(--company-color)', textDecoration: 'none' }}
                  >
                    <Trans>View description</Trans>
                  </Link>
                )}
              </JobHeader>
            )}
          </CareerBoardHeader>

          {isModuleLearningEnabled &&
            isLearningCareerEnabled &&
            (!!currentJobRoles.length || !!savedJobRoles.length) && (
              <LearningSection
                jobProfileId={
                  activeJobRole?.jobProfile
                    ? activeJobRole?.jobProfile?.id // jobProfileId in careerPlan
                    : activeJobRole?.id // jobProfileId in ambition:
                }
                userId={userId || selectedUser.id} // userId - for User Public Career, user.id -> for Career
              />
            )}

          {!isEmpty(activeJobRole) &&
          ((activeJobRole.jobProfile && !isEmpty(activeJobRole.jobProfile.skills)) ||
            !isEmpty(activeJobRole.skills)) ? (
            <SkillsSection
              jobProfile={activeJobRole.jobProfile || activeJobRole}
              scaleLabels={skillLabels}
              skillCoverage={skillCoverage}
              skillCategories={skillCategories}
              userId={userId}
              onSkillClick={onSkillClick}
            />
          ) : (
            <Placeholder
              title={i18n._(t`No data available`)}
              subTitle={
                selectedRole === ROLES.USER
                  ? i18n._(t`Your skill review for the selected role will show here`)
                  : i18n._(t`The skill reviews for the required skills will show here`)
              }
              Icon={() => (
                <SvgIcon
                  url={MarketplaceIcon}
                  width="48px"
                  height="48px"
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
            />
          )}
          {savedJobRoles.find((i) => i.id === activeJobRole?.id) ? (
            <RemoveAmbitionsContainer>
              <Button
                label={i18n._(t`Remove as ambition`)}
                height={48}
                type={Button.types.linkPrimary}
                onClick={async () => {
                  if (
                    await confirm({
                      type: CONFIRMATION_MODAL_TYPE.DELETE,
                      title: i18n._(t`Delete?`),
                      description: i18n._(
                        t`Are you sure you want to delete this role as your ambition?`,
                      ),
                    })
                  ) {
                    await removeAmbition(activeJobRole.id);
                  }
                }}
              />
            </RemoveAmbitionsContainer>
          ) : (
            activeJobRole &&
            isUserRoleModule &&
            (!selectedUser.isCoach || (selectedUser.isCoach && !userId)) && ( // coach not allowed to change role of the team members
              <RemoveAmbitionsContainer>
                <Button
                  label={i18n._(t`Remove as role`)}
                  height={48}
                  type={Button.types.linkPrimary}
                  onClick={async () => {
                    if (
                      await confirm({
                        type: CONFIRMATION_MODAL_TYPE.DELETE,
                        title: i18n._(t`Delete?`),
                        description: i18n._(
                          t`Are you sure you want to delete this role as your current role?`,
                        ),
                      })
                    ) {
                      await removeCareer(activeJobRole.id);
                    }
                  }}
                />
              </RemoveAmbitionsContainer>
            )
          )}
        </CareerBoard>
        {$showSkillModal.value &&
          (readOnly ? (
            <SkillDefinitionModal
              userId={userId}
              onModalClose={$showSkillModal.off}
              skill={activeSkill}
              scaleLabels={skillLabels}
              expectedLevel={activeSkill.expectedLevel || null}
              goToSkillPassport={goToSkillPassport}
              isUserCareer
              isHideViewFeedback={!isModuleSkillPassportEnabled}
            />
          ) : (
            <SkillFullInfoModal
              userId={userId}
              onClose={$showSkillModal.off}
              skillId={activeSkill.id}
              expectedLevel={activeSkill.expectedLevel || null}
            />
          ))}
      </ShowSpinnerIfLoading>
      {($isAddRoleShowing.value || $isAddAmbitionShowing.value) && (
        <AddRoleModal
          jobProfiles={jobProfilesForAddRoleModal}
          onClose={onCloseRoleModal}
          save={$isAddRoleShowing.value ? addRoles : addAmbitions}
        />
      )}
    </Content>
  );
}

export default withI18n()(UserCareer);
