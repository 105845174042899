import React from 'react';

import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '~/components/Button';
import PeopleIcon from '~/components/Icons/People';
import Modal from '~/components/Modal';
import Placeholder from '~/components/Placeholder';

import getUserFullName from '~/utils/getUserFullName';

import NomineesCoachItem from '../NomineesCoachItem';

const AddParticipants = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-top: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--company-color);
  cursor: pointer;
`;

const CoachesContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

function NomineesCoachesListModal({
  user,
  coaches = [],
  coachesSelected = [],
  onClose,
  onSave,
  i18n,
  title,
  disabledCoach,
  isParticipants,
  changeModal,
  setUsers,
  setCoachArray,
  isEditMode = true,
}) {
  const userName = getUserFullName(user);

  return (
    <Modal
      title={
        title ||
        (isParticipants ? (
          <Trans>Update participants for {userName}</Trans>
        ) : (
          <Trans>Update nominated coaches for {userName}</Trans>
        ))
      }
      onClose={onClose}
      width={625}
      hideFooter={!onSave || !isEditMode}
      footerRight={
        isEditMode && (
          <Button
            label={i18n._(t`Save`)}
            type="primary"
            onClick={() => setUsers(coachesSelected)}
          />
        )
      }
    >
      {isEmpty(coaches) ? (
        <Placeholder
          title={<Trans>No people nominated</Trans>}
          subTitle={<Trans>There is no person nominated</Trans>}
          Icon={PeopleIcon}
        />
      ) : (
        <CoachesContainer>
          {(isParticipants ? coachesSelected : coaches).map((coachId) => (
            <NomineesCoachItem
              key={coachId}
              coachId={coachId}
              onToggle={() =>
                setCoachArray(
                  coachesSelected.includes(coachId)
                    ? coachesSelected.filter((i) => i !== coachId)
                    : [...coachesSelected, coachId],
                )
              }
              value={coachesSelected.includes(coachId)}
              isDisabled={disabledCoach && disabledCoach === coachId}
              isParticipants={isParticipants}
              isDeleteVisible={isEditMode}
            />
          ))}
        </CoachesContainer>
      )}
      {isParticipants && isEditMode && (
        <AddParticipants
          onClick={() => {
            changeModal();
          }}
        >
          {i18n._(t`+ Add participants`)}
        </AddParticipants>
      )}
    </Modal>
  );
}

NomineesCoachesListModal.propTypes = {
  user: PropTypes.object,
  coaches: PropTypes.array,
  coachesSelected: PropTypes.array,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

const Nominate = withI18n()(React.memo(NomineesCoachesListModal));
export default Nominate;
