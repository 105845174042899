import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { confirm } from '~/components/ConfirmDialog';
import IconButton from '~/components/IconButton';
import TrashIcon from '~/components/Icons/Delete';
import TextField from '~/components/TextField';

import { COLORS } from '~/styles';

const RelevancyRowWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RelevancyLabelWrapper = styled(TextField)`
  flex: 1;
  height: 48px;
  margin-right: 16px;
  border-radius: 3px;
  border: solid 1px ${(props) => (props.error ? COLORS.ERROR : '#ccced5')};
  padding: 0 12px;
`;

const RoleRelevancyRow = ({ relevancy, roleRelevancies, relevancyIndex, onChange, error }) => {
  const { i18n } = useLingui();
  const updateRelevancies = (name) => {
    const newSkillCategories = (roleRelevancies || []).map((item, index) => {
      if (relevancyIndex === index) {
        item.name = name;
      }
      return item;
    });
    onChange(newSkillCategories);
  };

  const deleteRelevancy = async () => {
    const textConfirm = i18n._(
      t`This will delete this relevancy category from all activities in all roles. This cannot be undone?`,
    );
    if (await confirm(i18n, textConfirm)) {
      onChange(
        (roleRelevancies || []).filter(
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
          (i, index) => index !== relevancyIndex,
        ),
      );
    }
  };

  return (
    <RelevancyRowWrapper>
      <RelevancyLabelWrapper
        error={error}
        value={relevancy.name}
        type="text"
        onChange={(e) => updateRelevancies(e.target.value)}
      />
      <IconButton onClick={deleteRelevancy} size={24} noBorder>
        <TrashIcon />
      </IconButton>
    </RelevancyRowWrapper>
  );
};

export default RoleRelevancyRow;
